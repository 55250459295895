import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { VehicleMasterDataType } from "types/vehicleMasterType";
import { useLoading } from "Context/LoadingContext";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const VehicleRegAddForm = (props: Props) => {
  const nav = useNavigate();
  const [vehicleRegList, setVehicleRegList] = useState<VehicleMasterDataType[]>(
    []
  );
  const [vehRegId, setVehRegId] = useState("default");
  const [border, setBorder] = useState<boolean>(false);
  const { setLoading } = useLoading();

  const vehicleList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/dropdown/getVehicleType`);
      const vehRegList = res.data.vehicleTypes;
      setVehicleRegList(vehRegList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    vehicleList();
  }, [vehicleList]);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      registrationNo: "",
      registrationDate: "",
      licensePlatNo: "",
      totalSeats: "",
      ownerName: "",
      contactNo: "",
      description: "",
      active: true,
      seatNoRequired: true,
      note: "",
      details: "",
    },

    validationSchema: Yup.object().shape({
      registrationNo: Yup.string()
        .min(1, "Registration Number is Too Short")
        .max(30, "Registration Number is Too Long")
        .required("Registration Number is Required"),
      registrationDate: Yup.string().required("Registration Date is Required"),
      licensePlatNo: Yup.string()
        .matches(
          /^[A-Z]{2}-\d{1,2}-[A-Z]{1,2}-\d{1,4}$/,
          "Please Enter a Valid License Plate Number"
        )
        .min(1, "License Plate Number is Too Short")
        .max(15, "License Plate Number is Too Long")
        .required("License Plate Number is Required"),
      totalSeats: Yup.string()
        .matches(/^[0-9]\d*$/, "enter a valid seat number")
        .max(10, "Total Seats is Too Long")
        .when("SeatNoRequired", {
          is: true,
          then: Yup.string().required("Seat No is Required"),
        }),
      contactNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!"),
      ownerName: Yup.string().max(30, "Owner Name is Too Long"),
      description: Yup.string().max(300, "Description is Too Long"),
      isActive: Yup.boolean().default(true),
    }),

    onSubmit: async ({
      description,
      active,
      registrationDate,
      registrationNo,
      licensePlatNo,
      ownerName,
      contactNo,
      totalSeats,
      seatNoRequired,
    }) => {
      try {
        if (vehRegId === "default") return;

        setLoading(true);
        const formData = {
          model: vehRegId,
          registrationNo: registrationNo,
          registrationDate: registrationDate,
          licensePlatNo: licensePlatNo,
          totalSeats: String(totalSeats),
          ownerName: ownerName,
          contactNo: String(contactNo),
          description: description,
          note: "",
          details: "",
          isActive: active,
          seatNoRequired,
        };
        const res = await axiosInstance.post(
          "/master/addVehicleRegistration",
          formData
        );
        const responseMessage = res.data.message;
        if (responseMessage === "Created") {
          successToast();
          setLoading(false);
          const item = res.data?.VehicleReg;
          nav("/vehicleRegistration/addForm/pucAdd", {
            state: item,
          });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  return (
    <div className="formBg">
      <form className="row align-items-center " onSubmit={formik.handleSubmit}>
        <label htmlFor="" className="col-md-6 mb-3">
          <span className="top-placeholder">Vehicle Type*</span>

          <select
            className={`form-control ${
              vehRegId === "default" && border ? "empty-form" : ""
            }`}
            id="department"
            onChange={(e) => {
              setVehRegId(e.target.value);
            }}
          >
            {vehRegId === "default" && (
              <option value="">Select Vehicle Type</option>
            )}
            {vehicleRegList.map(({ _id, name }) => {
              return (
                <option key={_id} value={_id}>
                  {name}
                </option>
              );
            })}
          </select>
        </label>
        <label className="col-md-6 mb-3" htmlFor="registrationNo">
          <input
            className={`form-control ${
              formik.errors.registrationNo && border ? "empty-form" : ""
            }`}
            id="registrationNo"
            name="registrationNo"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.registrationNo}
            placeholder="Registration Number"
          />

          <span className="top-placeholder">Registration Number*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="registrationDate">
          <input
            className={`form-control session-date ${
              formik.errors.registrationDate && border ? "empty-form" : ""
            }`}
            id="registrationDate"
            name="registrationDate"
            type="date"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.registrationDate}
            // onFocus={(e) => {
            //   e.target.type = "date";
            // }}
            placeholder="Registration Date"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />

          <span className="top-placeholder">Registration Date*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="licensePlatNo">
          <input
            className={`form-control  ${
              formik.errors.licensePlatNo && border ? "empty-form" : ""
            }`}
            id="licensePlatNo"
            name="licensePlatNo"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.licensePlatNo}
            placeholder="License Plate Number (eg.GJ-01-AA-5050)"
          />

          <span className="top-placeholder">
            License Plate Number (eg.GJ-01-AA-5050)*
          </span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="totalSeats">
          <input
            className={`form-control  ${
              formik.errors.totalSeats && border ? "empty-form" : ""
            }`}
            id="totalSeats"
            name="totalSeats"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.totalSeats}
            placeholder="Total Seat"
          />

          <span className="top-placeholder">Total Seat*</span>
        </label>
        <label htmlFor="seatNoRequired" className="custCheck col-md-6 mb-3">
          Seat No Required?
          <input
            type="checkbox"
            id="seatNoRequired"
            name="seatNoRequired"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            checked={formik.values.seatNoRequired}
          />
          <span className="checkmark"></span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="contactNo">
          <input
            className="form-control"
            id="contactNo"
            name="contactNo"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.contactNo}
            placeholder="Contact Number"
          />

          <span className="top-placeholder">Contact Number</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="ownerName">
          <input
            className="form-control"
            id="ownerName"
            name="ownerName"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.ownerName}
            placeholder="Owner Name"
          />

          <span className="top-placeholder">Owner Name</span>
        </label>
        <label className="col-md-12 mb-3" htmlFor="description">
          <textarea
            className="form-control"
            id="description"
            name="description"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.description}
            placeholder="Description"
          />

          <span className="top-placeholder">Description</span>
        </label>
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn
            errors={formik.errors}
            clickFunc={() => {
              setBorder(true);
              if (vehRegId === "default") warnToast("Select Vehicle Type");
              toastValidation(formik.errors);
            }}
          />
          <CancelBtn path="../" />
        </div>
      </form>
    </div>
  );
};

export default memo(VehicleRegAddForm);
