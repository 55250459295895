import { useLoading } from "Context/LoadingContext";
import axiosInstance from "axiosInstance";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import toastValidation, { successToast } from "constants/toastConfig";
import { useLocation, useNavigate } from "react-router-dom";
import CancelBtn from "components/Buttons/Cancel";

const PaymentConfigEditForm = () => {
  const nav = useNavigate();
  const { state } = useLocation();
  const [accountInfo, setAccountInfo] = useState<any>();
  const [primary, setPrimary] = useState(false);
  const { setLoading } = useLoading();
  const [show, setShow] = useState(false);

  const accountDetails = async (settingType: string, id: string) => {
    if (settingType === "Company") {
      try {
        const resDetails = await axiosInstance.get(
          `/master/getOneAccountDetail?settingType=editCompanySetting&accountId=${id}`
        );
        const data = resDetails.data.result.AccountInformation[0];
        setAccountInfo(data);
      } catch (error) {
        console.log(error);
      }
    } else if (settingType === "Personal") {
      try {
        const resDetails = await axiosInstance.get(
          `/master/getOneAccountDetail?settingType=editPersonalSetting&accountId=${id}`
        );
        const data = resDetails.data.result.AccountInformation[0];
        setAccountInfo(data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleClose = () => {
    setShow(false);
    if (accountInfo.isPrimary) {
      if (state.settingType === "Personal") {
        formikPersonal.values.isPrimaryPer = true;
      } else {
        formikPersonal.values.isPrimaryPer = true;
      }
    } else {
      if (state.settingType === "Personal") {
        formikPersonal.values.isPrimaryPer = false;
      } else {
        formikPersonal.values.isPrimaryPer = false;
      }
    }
  };

  const onSubmitModal = async () => {
    try {
      await axiosInstance.put(`/master/deleteOnePaymentConfig`);

      if (accountInfo.isPrimary === true) {
        if (state.settingType === "Personal") {
          formikPersonal.values.isPrimaryPer = false;
        } else {
          formikCompany.values.isPrimaryCom = false;
        }
      } else {
        if (state.settingType === "Personal") {
          formikPersonal.values.isPrimaryPer = true;
        } else {
          formikCompany.values.isPrimaryCom = true;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formikPersonal = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      name: state.settingType === "Personal" ? accountInfo?.name : "",
      email: state.settingType === "Personal" ? accountInfo?.email : "",
      contact: state.settingType === "Personal" ? accountInfo?.contact : "",
      isActivePer:
        state.settingType === "Personal" ? accountInfo?.isActive : true,
      isPrimaryPer:
        state.settingType === "Personal" ? accountInfo?.isPrimary : false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Organizer Name is Too Short!")
        .max(30, "Organizer Name is Too Long!")
        .required("Organizer Name is Required"),
      email: Yup.string().email().max(50).required("Email Address is Required"),
      contact: Yup.string()
        .min(10, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!")
        .required(" Contact Number is Required"),
      isActivePer: Yup.boolean(),
      isPrimaryPer: Yup.boolean(),
    }),
    onSubmit: async ({ email, contact, isActivePer, name, isPrimaryPer }) => {
      setLoading(true);
      try {
        const formData = {
          name: name,
          email: email,
          contact: String(contact),
          description: "",
          note: "",
          details: "",
          isPrimary: isPrimaryPer,
          isActive: isActivePer,
          isDeleted: false,
        };
        const res = await axiosInstance.put(
          `/master/updateAccountDetail?settingType=editPersonalSetting&accountId=${accountInfo._id}`,
          formData
        );
        if (res.data.statusCode === 200) {
          setLoading(false);
          nav("..");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
  });
  const validateFormPer = () => {
    toastValidation(formikPersonal.errors);
  };
  const formikCompany = useFormik({
    validateOnMount: true,

    enableReinitialize: state.settingType === "Company" ? true : false,
    initialValues: {
      companyName:
        state.settingType === "Company" ? accountInfo?.companyName : "",
      contact: state.settingType === "Company" ? accountInfo?.contact : "",
      email: state.settingType === "Company" ? accountInfo?.email : "",
      description:
        state.settingType === "Company" ? accountInfo?.description : "",
      note: "",
      details: "",
      isActiveCom:
        state.settingType === "Company" ? accountInfo?.isActive : true,
      isPrimaryCom:
        state.settingType === "Company" ? accountInfo?.isPrimary : false,
    },
    validationSchema: Yup.object().shape({
      companyName: Yup.string()
        .min(1, "Company Name is Too Short!")
        .max(30, "Company Name is Too Long!")
        .required("Company Name is Required"),
      email: Yup.string().email().max(50).required("Email Address is Required"),
      contact: Yup.string()
        .min(10, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!")
        .required("Contact Number is Required"),
      description: Yup.string(),
      isActiveCom: Yup.boolean(),
      isPrimaryCom: Yup.boolean(),
    }),
    onSubmit: async ({
      email,
      contact,
      description,
      companyName,
      isActiveCom,
      isPrimaryCom,
    }) => {
      setLoading(true);
      try {
        const formData = {
          companyName: companyName,
          email: email,
          contact: String(contact),
          description: description,
          note: "",
          details: "",
          isActive: isActiveCom,
          isPrimary: isPrimaryCom,
          isDeleted: false,
        };

        const res = await axiosInstance.put(
          `/master/updateAccountDetail?settingType=editCompanySetting&accountId=${accountInfo._id}`,
          formData
        );
        if (res.data.statusCode === 200) {
          successToast();
          setLoading(false);
          nav("..");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  const validateFormCom = () => {
    toastValidation(formikCompany.errors);
  };
  function toggleCheckbox(extSetting: string) {
    const type = `${extSetting?.toLowerCase()}Setting`;
    const checkbox: any = document.getElementById(type);
    checkbox.checked = true;
  }
  const primaryExist = async () => {
    try {
      const res = await axiosInstance.get("/master/getAccountDetails");
      const data: any = res.data.result.AccountInformation;
      const isPrimaryExist = data.find((x: any) => {
        return x.isPrimary === true;
      })?.isPrimary;
      if (isPrimaryExist) {
        setPrimary(isPrimaryExist);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    toggleCheckbox(state.settingType);
  }, []);

  useEffect(() => {
    accountDetails(state.settingType, state.id);
    primaryExist();
  }, []);
  return (
    <>
      <div className="d-flex flex-column">
        <div className="formBg">
          <label
            className="col-md-12 mb-3 d-flex gap-5 py-3"
            htmlFor="feesType"
          >
            <span>Configure your payment Setting :</span>
            <div>
              <Form.Check
                type="radio"
                label={
                  state.settingType === "Personal"
                    ? "Personal account setting"
                    : "Company account setting"
                }
                id={
                  state.settingType === "Personal"
                    ? "personalSetting"
                    : "companySetting"
                }
                className="pb-3"
                style={{
                  cursor: "pointer",
                }}
                name="paymentType"
              />
            </div>
          </label>
          {state.settingType === "Personal" && (
            <form onSubmit={formikPersonal.handleSubmit}>
              <div className="row align-items-center">
                <label className="col-md-6 mb-3" htmlFor="name">
                  <span className="top-placeholder">Name*</span>

                  <input
                    className={`form-control ${
                      formikPersonal.errors.name &&
                      formikPersonal.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="name"
                    name="name"
                    type="text"
                    onBlur={formikPersonal.handleBlur}
                    onChange={formikPersonal.handleChange}
                    value={formikPersonal.values.name}
                    placeholder="Organizer Name"
                  />
                </label>
                <label className="col-md-6 mb-3" htmlFor="email">
                  <span className="top-placeholder">Email*</span>

                  <input
                    className={`form-control ${
                      formikPersonal.errors.email &&
                      formikPersonal.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="email"
                    name="email"
                    type="text"
                    onBlur={formikPersonal.handleBlur}
                    onChange={formikPersonal.handleChange}
                    value={formikPersonal.values.email}
                    placeholder="Email"
                  />
                </label>
                <label className="col-md-6 mb-3" htmlFor="contact">
                  <span className="top-placeholder">Contact*</span>

                  <input
                    className={`form-control ${
                      formikPersonal.errors.contact &&
                      formikPersonal.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="contact"
                    name="contact"
                    type="number"
                    onBlur={formikPersonal.handleBlur}
                    onChange={formikPersonal.handleChange}
                    value={formikPersonal.values.contact}
                    placeholder="Contact"
                  />
                </label>
                <div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="isActivePer" className="custCheck">
                      Active
                      <input
                        type="checkbox"
                        id="isActivePer"
                        name="isActivePer"
                        onBlur={formikPersonal.handleBlur}
                        onChange={formikPersonal.handleChange}
                        checked={formikPersonal.values.isActivePer}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="isPrimaryPer" className="custCheck">
                      Primary
                      <input
                        type="checkbox"
                        id="isPrimaryPer"
                        name="isPrimaryPer"
                        onBlur={formikPersonal.handleBlur}
                        onChange={(e) => {
                          formikPersonal.handleChange(e);
                          setShow(true);
                        }}
                        checked={formikPersonal.values.isPrimaryPer}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3 text-center">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={validateFormPer}
                >
                  Submit
                </button>
                <CancelBtn path=".." />
              </div>
            </form>
          )}
          {state.settingType === "Company" && (
            <form onSubmit={formikCompany.handleSubmit}>
              <div className="row align-items-center">
                <label className="col-md-6 mb-3" htmlFor="companyName">
                  <span className="top-placeholder">Name*</span>

                  <input
                    className={`form-control ${
                      formikCompany.errors.companyName &&
                      formikCompany.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="companyName"
                    name="companyName"
                    type="text"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    value={formikCompany.values.companyName}
                    placeholder="Company Name"
                  />
                </label>
                <label className="col-md-6 mb-3" htmlFor="email">
                  <span className="top-placeholder">Email*</span>

                  <input
                    className={`form-control ${
                      formikCompany.errors.email &&
                      formikCompany.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="email"
                    name="email"
                    type="text"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    value={formikCompany.values.email}
                    placeholder="Email"
                  />
                </label>
                <label className="col-md-6 mb-3" htmlFor="contact">
                  <span className="top-placeholder">Contact*</span>

                  <input
                    className={`form-control ${
                      formikCompany.errors.contact &&
                      formikCompany.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="contact"
                    name="contact"
                    type="number"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    value={formikCompany.values.contact}
                    placeholder="Contact"
                  />
                </label>
                <label className="col-md-12 mb-3" htmlFor="description">
                  <span className="top-placeholder">Description</span>

                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    value={formikCompany.values.description}
                    placeholder="Description"
                  ></textarea>
                </label>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="isActiveCom" className="custCheck">
                  Active
                  <input
                    type="checkbox"
                    id="isActiveCom"
                    name="isActiveCom"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    checked={formikCompany.values.isActiveCom}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="isPrimaryCom" className="custCheck">
                  Primary
                  <input
                    type="checkbox"
                    id="isPrimaryCom"
                    name="isPrimaryCom"
                    onBlur={formikCompany.handleBlur}
                    onChange={(e) => {
                      formikCompany.handleChange(e);

                      setShow(true);
                    }}
                    checked={formikCompany.values.isPrimaryCom}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              <div className="col-md-12 mb-3 text-center">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={validateFormCom}
                >
                  Submit
                </button>
                <CancelBtn path=".." />
              </div>
            </form>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        {primary ? (
          <Modal.Body>
            {accountInfo?.isPrimary
              ? "Are you sure you want to deactivate this primary account?"
              : "Are you sure you want to deactivate previous primary account?"}
          </Modal.Body>
        ) : (
          <Modal.Body>
            Are you sure you want to set this to primmary account?
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              onSubmitModal();
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentConfigEditForm;
