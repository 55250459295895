import { memo } from "react";
import { AdminExpirePlanDataType } from "types/adminExpirePlanTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { dateFormatForAdmin, formatDate } from "constants/Constants";

type Props = {
  x: AdminExpirePlanDataType;
  reminder: (item: AdminExpirePlanDataType) => void;
};

const AdminExpirePlanListItem = ({ x, reminder }: Props): JSX.Element => {
  const RemindToolTip = <Tooltip id={`tooltip-${x._id}`}>Remind</Tooltip>;
  return (
    <tr key={x._id}>
      <td>{x.name}</td>
      <td>{x.bussinessName}</td>
      <td>{x.planName}</td>
      <td>{dateFormatForAdmin(x.planStartDate)}</td>
      <td>{dateFormatForAdmin(x.planExpired)}</td>
      <td>{x.day}</td>
      <td className="text-center">{x.reminder}</td>
      <td className="text-center">
        {x.lastRemindDate === null ? "__" : formatDate(x.lastRemindDate)}
      </td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={RemindToolTip}>
          <Edit
            className="pointer"
            onClick={() => {
              reminder(x);
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(AdminExpirePlanListItem);
