import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect } from "react";
import { MemberDataType } from "types/MemberMaster.types";
import MemberListItems from "./MemberListItems";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { DepartmentDDLType } from "types/departmentMasterType";
import AddSearch from "components/AddSearchComponent";
import { useNotFound } from "Context/NotFound";
import NotFound from "components/NotFound";

type Props = {};

const MemberMasterList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();

  const [department, setDepartment] = useState<DepartmentDDLType[]>([]);
  const [member, setMember] = useState<MemberDataType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { notfound, setNotFound } = useNotFound();

  const loadMemberList = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      setLoading(true);
      try {
        await loadDepartments();

        const res = await axiosInstance.get(
          `/master/getAllMember?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newmemberlist: MemberDataType[] = [
          ...res.data.member[0].paginatedResults,
        ];
        if (searchKeyword !== "" && pageNum === 1) {
          if (newmemberlist.length === 0) {
            setNotFound(true);
            setMember([]);
            setSearchCount(0);
          }
        }
        if (newmemberlist.length > 0) {
          setSearchCount(res.data.member[0].totalCount[0].count);
          if (pageNum === 1) {
            setMember(newmemberlist);
          } else {
            setMember((prevList) => [...prevList, ...newmemberlist]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [member]
  );
  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const departmentList = res.data.Departments;
      setDepartment(departmentList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    loadMemberList(1, sort, search);
  };

  useEffect(() => {
    loadMemberList(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(member, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        addLink="/memberMaster/addForm"
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Member Name or Member ID or Route"
      />

      <table className="table">
        <thead>
          <tr>
            <th>Photo</th>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Member Name
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="memberId"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Member ID
              <span className="px-2">
                {sort === "memberId" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="depName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Department
              <span className="px-2">
                {sort === "depName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="catName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Category
              <span className="px-2">
                {sort === "catName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="routeName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Route
              <span className="px-2">
                {sort === "routeName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>

            <th
              id="locationName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Pickup Location
              <span className="px-2">
                {sort === "locationName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {member?.map((item) => {
              return (
                <MemberListItems
                  item={item}
                  key={item._id}
                  department={department}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default MemberMasterList;
