import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { VehicleMasterDataType } from "../../../types/vehicleMasterType";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { descSlice } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: VehicleMasterDataType;
};

const VehicleMasterListItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit </Tooltip>;
  return (
    <tr key={x._id}>
      <td>{x.name}</td>
      <td>{x.code}</td>
      <td>{descSlice(x.description)}</td>
      <td className="text-center">
        {x.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            id={x._id}
            onClick={() => {
              nav("vehicleMasterEdit", {
                state: x._id,
              });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(VehicleMasterListItem);
