import React, { useState, memo, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import toastValidation, { warnToast } from "constants/toastConfig";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};
type DriverDDLType = {
  _id: string;
  driverName: string;
};

const EditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const { state } = useLocation();

  const nav = useNavigate();
  const [driverId, setDriverId] = useState("default");
  const [drivers, setDrivers] = useState<DriverDDLType[]>([]);
  const [border, setBorder] = useState<boolean>(false);

  const loadDrivers = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getUnConfiguredDriverDDL  `
      );
      const x = res.data.result.UnConfiguredDriver;

      const resDri = await axiosInstance.get(`/dropDown/getDriverDDL  `);
      const driversList = resDri.data.Driver;

      const y = driversList.find((x: DriverDDLType) => {
        return x._id === state.item.driverId;
      });
      setDrivers([y, ...x]);
      setDriverId(y._id);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadDrivers();
  }, [loadDrivers]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: state.item.email,
      contact: state.item.contact,
      code: "",
      description: "",
      active: state.item.isActive,
      note: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("Email Address is Required"),
      contact: Yup.string()
        .min(10, "Driver Contact Number Too Short!")
        .max(10, "Driver Contact Number Too Long!")
        .required("Driver Contact Number is Required"),
    }),
    onSubmit: async ({ email, contact, active }) => {
      setLoading(true);
      try {
        const formData = {
          driverId: driverId,
          email: email,
          contact: String(contact),
          code: "",
          description: "",
          note: "",
          details: "",
          isActive: active,
        };
        const res = await axiosInstance.put(
          `/master/updateDriverConfig/${state.item._id}`,
          formData
        );
        const status = res.status === 200;
        if (status) {
          setLoading(false);
          nav("/driverConfig");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <div className="formBg">
      <form className="row align-items-center" onSubmit={formik.handleSubmit}>
        <label className="col-md-6 mb-3" htmlFor="driver">
          <span className="top-placeholder">Driver*</span>

          <select
            className={`form-control ${
              driverId === "default" && border ? "empty-form" : ""
            }`}
            id="driver"
            name="driver"
            onChange={(e) => {
              setDriverId(e.target.value);
            }}
          >
            {drivers.map(({ _id, driverName }) => {
              return (
                <option key={_id} value={_id} id={_id}>
                  {driverName}
                </option>
              );
            })}
          </select>
        </label>
        <label className="col-md-6 mb-3" htmlFor="email">
          <input
            className={`form-control ${
              formik.errors.email && border ? "empty-form" : ""
            }`}
            id="email"
            name="email"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Email Address"
          />

          <span className="top-placeholder">Email*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="contact">
          <input
            className={`form-control ${
              formik.errors.contact && border ? "empty-form" : ""
            }`}
            id="contact"
            name="contact"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.contact}
            placeholder="Contact Number"
          />

          <span className="top-placeholder">Contact*</span>
        </label>
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn
            errors={formik.errors}
            clickFunc={() => {
              setBorder(true);
              if (driverId === "default") warnToast("Select Driver");
              toastValidation(formik.errors);
            }}
          />
          <CancelBtn path="/driverConfig" />
        </div>
      </form>
    </div>
  );
};

export default memo(EditForm);
