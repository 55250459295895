import React, { memo, useState } from "react";
import { RouteVehicleDataType } from "../../../types/routeVehicleTypes";
import ShiftTypeMasterType from "../../../types/shiftType.types";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as DeleteImg } from "assets/images/delete-18.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import RouteMasterType from "types/route.types";
import { VehicleMasterDataType } from "types/vehicleMasterType";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import axiosInstance from "axiosInstance";

export type DriverDataType = {
  _id: string;
  organizerId: string;
  name: string;
};

type Props = {
  x: any;
  loadRouteVehicle: any;
};

const RouteVehicleListItem = ({ x, loadRouteVehicle }: Props): JSX.Element => {
  const [show, setShow] = useState(false);
  const [routeVehicle, setRouteVehicleId] = useState(false);

  const handleClose = () => setShow(false);

  const onSubmit = async () => {
    try {
      const formData = {
        isActive: false,
        isDeleted: true,
      };
      const res = await axiosInstance.put(
        `/master/getRouteVehicleDelete/${routeVehicle}`,
        formData
      );
      if (res.data.message === "Ok") {
        loadRouteVehicle(1, "shiftName", routeVehicle);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClick = () => {
    setRouteVehicleId(x._id);
    setShow(true);
  };

  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Delete </Tooltip>;

  return (
    <>
      <tr key={x._id}>
        <td>{x.shiftName}</td>
        <td>{x.vehicleNo}</td>
        <td>{x.driverName}</td>
        <td>{x.contactNo}</td>
        <td>{x.attendantName}</td>
        <td className="text-center">
          {x.isActive === true ? <Eye /> : <EyeOff />}
        </td>
        <td className="text-center">
          {/* <Edit
            id={x._id}
            type="button"
            onClick={(e) => {
              setRouteVehicleId(x._id);
              setShow(true);
            }}
          /> */}
          <OverlayTrigger placement="right" overlay={tooltip}>
            <span
              id={x._id}
              onClick={handleEditClick}
              style={{ cursor: "pointer" }}
            >
              <DeleteImg className="Approved_Size" />
            </span>
          </OverlayTrigger>
        </td>
      </tr>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Are You Sure For Delete Route Vehicle?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              onSubmit();
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(RouteVehicleListItem);
