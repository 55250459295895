import { PayloadAction } from "@reduxjs/toolkit";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as xlsx from "xlsx";
import "jspdf-autotable";
import { bucketBaseUrl } from "axiosInstance";
import { parseISO, format } from "date-fns";
import { enUS } from "date-fns/locale";

const descSlice = (value: string, length?: number) => {
  if (value.length >= (length || 30)) {
    return value.slice(0, length || 30).concat("...");
  }
  return value;
};

const updateReduxData = (state: any, action: PayloadAction<any>) => {
  const formData = action.payload.data;
  const index = state.data.findIndex(
    (result: any) => result._id === action.payload.id
  );
  state.data = [
    ...state.data.slice(0, index),
    { ...state.data[index], ...formData },
    ...state.data.slice(index + 1),
  ];
};

const infiniteScroll = (
  list: any,
  updatePage: (page: number) => void,
  pageNum: number,
  srhCount: number
) => {
  if (
    window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight &&
    list.length < srhCount
  ) {
    updatePage(pageNum + 1);
  } else {
    return "No More to fetch";
  }
};

const sortData = (
  acenDcen: boolean,
  updateAcenDcen: (acenDcen: boolean) => void,
  e: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
  updateSort: (sort: string) => void,
  updateList: (List: []) => void,
  updatePage: (page: number) => void
) => {
  updateAcenDcen(!acenDcen);
  if (!acenDcen) {
    updateSort(`-${e.currentTarget.id}`);
  } else {
    updateSort(`${e.currentTarget.id}`);
  }
  updatePage(1);
  updateList([]);
};

const exportPDF = () => {
  const table = document.getElementById("report-table");
  if (!table) {
    console.error("Could not find report table element");
    return;
  }

  const pdf = new jsPDF({
    format: "a4",
  });

  const totalPages = Math.ceil(
    table.clientHeight / pdf.internal.pageSize.getHeight()
  );

  let currentPosition = 0;

  const renderPage = () => {
    const container = document.createElement("div");
    container.appendChild(table.cloneNode(true));

    const canvas = document.createElement("canvas");
    const scale = 2;
    const viewport = container.getBoundingClientRect();

    canvas.width = viewport.width * scale;
    canvas.height = viewport.height * scale;

    const canvasContext = canvas.getContext("2d")!;
    // Add a non-null assertion operator to ensure canvasContext is not null

    canvasContext.scale(scale, scale);

    const renderContext = {
      canvasContext,
      viewport,
    };

    pdf.addPage();
    pdf.setPage(currentPosition + 1);

    return new Promise<void>((resolve) => {
      html2canvas(container, {
        useCORS: true,
        scale,
        scrollY: -viewport.height * currentPosition,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        canvasContext.drawImage(canvas, 0, 0);

        const imgWidth =
          (pdf.internal.pageSize.getWidth() * scale) / viewport.width;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(
          imgData,
          "PNG",
          0,
          30,
          pdf.internal.pageSize.getWidth(),
          imgHeight
        );
        resolve(); // Resolve the promise with a void value
      });
    });
  };

  const renderAllPages = async () => {
    for (let i = 0; i < totalPages; i++) {
      await renderPage();
      currentPosition++;
    }

    pdf.save("report.pdf");
  };

  renderAllPages();
};

const exportToExcel = (item: any) => {
  const workbook = xlsx.utils.book_new();
  const worksheet = xlsx.utils.json_to_sheet(item);
  xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  xlsx.writeFile(workbook, "data.xlsx");
};

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

const current_month = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
  return currentYear + "-" + currentMonth;
};

const formatTime = (originalDate: string) => {
  const createdAt = new Date(originalDate);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  } as const;
  const formattedDateTime = createdAt.toLocaleString("en-GB", options);
  const [time, period] = formattedDateTime.split(" ");
  const [hours, minutes] = time.split(":");
  const formattedHours = (parseInt(hours) % 12 || 12)
    .toString()
    .padStart(2, "0");
  const formattedTime = `${formattedHours}:${minutes} ${period.toUpperCase()}`;

  return formattedTime;
};

const todayDate = new Date().toISOString().slice(0, 10);
const libraries = ["places"];

const convertTo12HourFormat = (timeStr: string): string => {
  const [hour, minute] = timeStr.split(":").map(Number);

  let adjustedHour = hour;
  let period = "AM";

  if (adjustedHour >= 12) {
    period = "PM";
    adjustedHour = adjustedHour % 12 || 12;
  }

  return `${adjustedHour}:${minute.toString().padStart(2, "0")} ${period}`;
};

const formatDateToAdd = (dateString: any) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

function convertToMonthYearFormat(dateString: any) {
  const [year, month] = dateString.split("-");
  const formattedDate = `${new Date(year, month - 1).toLocaleString("default", {
    month: "long",
  })}, ${year}`;
  return formattedDate;
}

function convertToYYYYMMFormat(dateString: any) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${year}-${month}`;
}
function addLineBreaks(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }

  let result = "";
  let currentLength = 0;

  for (let i = 0; i < str.length; i++) {
    result += str[i];
    currentLength++;

    if (currentLength === maxLength && i !== str.length - 1) {
      result += "\n";
      currentLength = 0;
    }
  }

  return result;
}

const downloadTableAsPDF = (columns: any, data: any, pdfName?: string) => {
  const doc: any = new jsPDF("l", "px", "a4");
  doc.autoTable({
    head: [columns.map((column: any) => column.Header)],
    body: data.map((row: any) =>
      columns.map((column: any) =>
        typeof column.accessor === "function"
          ? column.accessor(row)
          : row[column.accessor]
      )
    ),
    startY: 40,
  });

  doc.save(pdfName || "report.pdf");
};

const downloadTableAsPrint = (columns: any, data: any) => {
  const doc: any = new jsPDF("l", "px", "a4");

  doc.autoTable({
    head: [columns.map((column: any) => column.Header)],
    body: data.map((row: any) =>
      columns.map((column: any) =>
        typeof column.accessor === "function"
          ? column.accessor(row)
          : row[column.accessor]
      )
    ),
    startY: 40,
  });

  doc.autoPrint();

  const blob = doc.output("bloburl");
  const iframe = document.createElement("iframe");
  iframe.src = blob;
  iframe.style.display = "none";
  document.body.appendChild(iframe);
};
const dateFormatForAdmin = (date: string) => {
  const dateObject = new Date(date);
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const fullYear = dateObject.getFullYear();
  return `${day}-${month}-${fullYear}`;
};

const formatDateWithNull = (dateString: any) => {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return ""; // Return an empty string for invalid dates
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

export const formatDateForFile = (dateString: string): string => {
  const parsedDate: Date = parseISO(dateString);
  const formattedDate: string = format(parsedDate, "dd'-'MM'-'yyyy", {
    locale: enUS,
  });
  return formattedDate;
};

export const formatMongoDBDateToTime = (mongoDBDate: string) => {
  const date = new Date(mongoDBDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Ensure single digits have leading zero, e.g., 5:05 PM
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const timeString = `${formattedHours}:${formattedMinutes} ${amOrPm}`;

  return timeString;
};

export const formatDates = (dateString: string) => {
  if (typeof dateString === "string") {
    const parsedDate = parseISO(dateString);
    const formattedDate = format(parsedDate, "dd MMMM',' yyyy", {
      locale: enUS,
    });
    return formattedDate;
  } else {
    return "";
  }
};
export const memImage = `${bucketBaseUrl}member/images/`;
export const memUserImage = `${bucketBaseUrl}memberUser/images/`;
export const drvImage = `${bucketBaseUrl}driver/images/`;
export const drvDoc = `${bucketBaseUrl}driver/docs/`;
export const orgDoc = `${bucketBaseUrl}organizer/docs/`;
export const orgImage = `${bucketBaseUrl}organizer/images/`;

export const currencySymbol = "₹";

export const LatLongObj = {
  latitude: "23.0225",
  longitude: "72.5714",
};

export {
  descSlice,
  updateReduxData,
  infiniteScroll,
  sortData,
  exportPDF,
  exportToExcel,
  formatDate,
  formatDateToAdd,
  formatTime,
  current_month,
  todayDate,
  libraries,
  convertTo12HourFormat,
  convertToMonthYearFormat,
  convertToYYYYMMFormat,
  addLineBreaks,
  downloadTableAsPDF,
  downloadTableAsPrint,
  dateFormatForAdmin,
  formatDateWithNull,
};
