import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";

type Config = {
  planType: string;
};

const RenewUi = () => {
  const [plans, setPlans] = useState<any[]>([]); // Initialize plans as an empty array
  const [activePlan, setActivePlan] = useState<number | null>(null); // Set the initial activePlan to null
  const [show, setShow] = useState(false);
  const [idd, setIdd] = useState<any>();
  const handleClose = () => setShow(false);

  const nav = useNavigate();
  const PlanType: Config | null = JSON.parse(
    localStorage.getItem("config") || "null"
  );
  const initialPlanTypeValue = PlanType?.planType;

  const fetchPlans = useCallback(async () => {
    try {
      const res = await axiosInstance.get("/admin/getAllAdminPlan");
      const x = res.data.AdminPlan;

      setPlans([
        x?.find((i: any) => i?.planTypeId?.code === "BAC"),
        x?.find((i: any) => i?.planTypeId?.code === "ADV"),
        x?.find((i: any) => i?.planTypeId?.code === "PRM"),
      ]);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  useEffect(() => {
    const initialPlanIndex = plans.findIndex(
      (plan: any) => plan?.planTypeId?.code === initialPlanTypeValue
    );
    if (initialPlanIndex !== -1) {
      setActivePlan(initialPlanIndex);
    }
  }, [initialPlanTypeValue, plans]);

  const handlePlanClick = async (id: number) => {
    setActivePlan(id === activePlan ? null : id);
    // console.log(id);
    // console.log(activePlan, "sss");
  };

  const reNewData = async (id: any) => {
    try {
      const selectedPlan = plans[id]; // Get the selected plan from the plans array

      console.log(selectedPlan, "selectP");
      if (!selectedPlan) return; //

      const formData = {
        planId: selectedPlan._id,
        amount: selectedPlan.price,
        isActive: false,
        isDeleted: true,
      };
      const res = await axiosInstance.post(
        `/Admin/addReqForRenewPlan`,
        formData
      );

      const responseMessage = res.data.message;
      if (responseMessage === "Ok") {
        nav("/profile");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Renew Plan</span>
      </div>
      <div className="container-md text-center">
        <div className="d-flex ">
          {plans.length > 0 && // Check if plans array is not empty before rendering
            plans.map((x: any, id: number) => {
              return (
                <div
                  className={`box-Plan ${activePlan === id ? "active" : ""}`}
                  key={x?._id}
                  id={x?.planTypeId?.code}
                  onClick={() => handlePlanClick(id)} // Change to onClick event
                >
                  <div className="typee">
                    <h5>{x?.planTypeId?.planType}</h5>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <ul>
                      <li>Real time Live Tracking</li>
                      {x?.planTypeId?.code !== "BAC" && (
                        <li>Real time Attendance</li>
                      )}
                      {x?.planTypeId?.code !== "BAC" && <li>Routing</li>}
                      <li>Driver App</li>
                      <li>Member App</li>
                      <li>Notification</li>
                      <li>SOS Alert</li>
                      <li>Payment</li>
                      {x?.planTypeId?.code === "PRM" && <li>Multi Shift</li>}
                    </ul>
                    {x?.planTypeId?.code === "BAC" && (
                      <>
                        <br />
                        <br />
                        <br />
                        <br className="hidden-br" />
                        <br className="hidden-br" />
                      </>
                    )}
                    {x?.planTypeId?.code === "ADV" && (
                      <>
                        <br className="hidden-br" />
                        <br className="hidden-br" />
                      </>
                    )}

                    <Button
                      className="get-started"
                      id={x.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShow(true);
                        setIdd(id);
                      }}
                    >
                      Get Renew
                    </Button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Confirm Your Renewal Plan</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              reNewData(idd);
            }}
          >
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RenewUi;
