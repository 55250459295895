import axiosInstance from "axiosInstance";
import React, { useEffect, useState } from "react";
import StopDriverList from "./stopDriverList";
import { overSpeedData } from "types/alltrackingTypes";
import overspeed from "../../../assets/images/highSpeed.png";

const MapLeftInfo = () => {
  const [ovrspeedData, setOvrSpeedData] = useState<overSpeedData[]>([]);

  const ovrspdData = async () => {
    try {
      const res = await axiosInstance.get(`/dropdown/getOvrSpdDriver`);
      const spdData = res.data.result;
      setOvrSpeedData(spdData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ovrspdData(); // Initial call to fetch data

    const intervalId = setInterval(() => {
      ovrspdData(); // Call the function every 1 minute
    }, 60000); // 60000 milliseconds = 1 minute

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, []);

  return (
    <div className="col-lg-2">
      {ovrspeedData.map((item, index) => {
        return (
          <div
            className="infoBox mb-4"
            key={index}
            style={{
              backgroundImage: `url(${overspeed})`,
              backgroundSize: "contain",
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="infoLeft">
              <span>{item.driverName}</span>
              {item.driverSpeed}
              <span>{item.routeName}</span>
            </div>
            {/* <i className="infoRgt"><img src={image} alt="Logo" /></i> */}
          </div>
        );
      })}
      <StopDriverList />
    </div>
  );
};
export default MapLeftInfo;
