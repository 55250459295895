import { MemberUserType } from "types/memberUserTypes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { formatDate, memUserImage } from "constants/Constants";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
  item: MemberUserType;
  deleteUser: (item: MemberUserType) => void;
};

const MemberUserListItem = ({ item, deleteUser }: Props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  return (
    <>
      <tr key={Math.random()}>
        {item.photo ? (
          <td>
            <i className="userImg">
              <img
                src={`${memUserImage}${
                  item.photo !== "" ? item.photo : "Default.png"
                }`}
                alt="MemberUserPic"
              />
            </i>
          </td>
        ) : (
          <td>Not Upload</td>
        )}
        <td>{item.name}</td>
        <td>{item.mobileNo}</td>
        <td>{item.relationName} </td>
        <td>{formatDate(item.createdAt)}</td>
        <td className="text-center">{item.isVerified ? "Yes" : "No"}</td>
        <td className="text-center">{item.isActive ? <Eye /> : <EyeOff />}</td>
        <td className="text-center">{item.isDeleted ? "Yes" : "No"}</td>
        <td className="text-center pointer">
          <OverlayTrigger placement="right" overlay={tooltip}>
            <span id={item._id} style={{ cursor: "pointer" }}>
              <Edit onClick={() => setShow(true)} />
            </span>
          </OverlayTrigger>
        </td>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>Are You Sure You want to Delete?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setShow(false);
                deleteUser(item);
              }}
            >
              Submit
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </tr>
    </>
  );
};

export default MemberUserListItem;
