import React from "react";
import Footer from "../Footer";
import SideBar from "../SidebarMenu/sideBar";
import { Outlet } from "react-router-dom";

const SupLayout = () => {
  return (
    <>
      <section id="wrapper">
        <SideBar />
        <section id="content">
          <section className="content">
            <Outlet />
          </section>
        </section>
      </section>
      <Footer />
    </>
  );
};

export default SupLayout;
