import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type AreaEditContextType = {
  area: any;
  setArea: Dispatch<SetStateAction<any>>;
  setEditIndex: Dispatch<SetStateAction<any>>;
  editIndex: number | undefined;
  division: any;
  setDivision: Dispatch<SetStateAction<any>>;
  // disabled: any;
  // setDisabled: any;
};

type Props = {
  children: ReactNode;
};

const AreaEditContext = createContext<AreaEditContextType>({
  area: null,
  setArea: () => {},
  editIndex: undefined,
  setEditIndex: () => {},
  division: null,
  setDivision: () => {},
  // disabled: [],
  // setDisabled: () => {},
});

export function AreaEditProvider({ children }: Props) {
  const [area, setArea] = useState(null);
  const [editIndex, setEditIndex] = useState(undefined);
  const [division, setDivision] = useState<any>();
  // const [disabled, setDisabled] = useState();

  const value = {
    area,
    setArea,
    editIndex,
    setEditIndex,
    division,
    setDivision,
    // disabled,
    // setDisabled,
  };
  return (
    <AreaEditContext.Provider value={value}>
      {children}
    </AreaEditContext.Provider>
  );
}

export function useAreaEdit() {
  const context = useContext(AreaEditContext);
  if (!context) {
    throw new Error("useAreaEdit must be used within AreaEditProvider");
  }
  return context;
}
