import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import {
  downloadTableAsPDF,
  exportToExcel,
  infiniteScroll,
  sortData,
} from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";
import { MemberFeesType } from "types/memberFeesTypes";
import MemberFeesListItems from "./listItem";
import jsPDF from "jspdf";
import "jspdf-autotable";

type Props = {
  type: number;
  printRef: any;
  pdfRef: any;
  excelRef: any;
};

const PaymentList = ({
  type,
  pdfRef,
  printRef,
  excelRef,
}: Props): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("memberName");
  const [searchCount, setSearchCount] = useState(0);
  const [memberFees, setMemberFees] = useState<MemberFeesType[]>([]);
  const { setLoading } = useLoading();
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const loadMemberFeesList = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      setLoading(true);
      try {
        let res: any;
        if (type === 1) {
          res = await axiosInstance.get(
            `/master/getAllDueMemberFeesManagement?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
          );
        } else if (type === 2) {
          res = await axiosInstance.get(
            `/master/getAllPendingMemberFeesManagement?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
          );
        } else if (type === 3) {
          res = await axiosInstance.get(
            `/master/getAllMemberFeesManagement?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
          );
        }

        const newMemberFeesList: MemberFeesType[] = [
          ...res.data.result.allMemberFees[0].paginatedResults,
        ];
        if (newMemberFeesList.length === 0) {
          setNotFound(true);
          if (searchKeyword !== "" && pageNum === 1) {
            setMemberFees([]);
            setSearchCount(0);
          }
        }
        if (newMemberFeesList.length > 0) {
          setSearchCount(res.data.result.allMemberFees[0].totalCount[0].count);
          if (pageNum === 1) {
            setMemberFees(newMemberFeesList);
          } else {
            setMemberFees((prevList) => [...prevList, ...newMemberFeesList]);
          }
          setNotFound(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
    [memberFees]
  );

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    loadMemberFeesList(1, sort, search);
  };

  useEffect(() => {
    loadMemberFeesList(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(memberFees, setPage, page, searchCount);
  };

  const downloadPDF = () => {
    const doc: any = new jsPDF();

    const headers = [
      { id: "memberName", name: "Member Name" },
      { id: "memId", name: "Member ID" },
      { id: "routeName", name: "Route Name" },
      { id: "pendingFees", name: "Fees" },
      { id: "paidFees", name: "Paid Fees" },
    ];

    const data = memberFees.map((item: any) => ({
      memberName: item.memberName,
      memId: item.memId,
      routeName: item.routeName,
      pendingFees: item.dueFees.toFixed(2),
      paidFees: item.totalPaidFees.toFixed(2),
    }));

    doc.autoTable({
      head: [headers.map((header: any) => header.name)],
      body: data.map((row: any) => headers.map((header) => row[header.id])),
    });

    doc.save("table_data.pdf");
  };

  const downloadPDFPrint = () => {
    const doc: any = new jsPDF();

    const headers = [
      { id: "memberName", name: "Member Name" },
      { id: "memId", name: "Member ID" },
      { id: "routeName", name: "Route Name" },
      { id: "pendingFees", name: "Fees" },
      { id: "paidFees", name: "Paid Fees" },
    ];

    const data = memberFees.map((item: any) => ({
      memberName: item.memberName,
      memId: item.memId,
      routeName: item.routeName,
      pendingFees: item.dueFees.toFixed(2),
      paidFees: item.totalPaidFees.toFixed(2),
    }));

    doc.autoTable({
      head: [headers.map((header: any) => header.name)],
      body: data.map((row: any) => headers.map((header) => row[header.id])),
    });

    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Member Name or Member ID"
      />
      <div className="d-flex justify-content-end">
        <button
          ref={pdfRef}
          onClick={downloadPDF}
          className="btn btn-primary mb-4"
          style={{ display: "none" }}
        >
          Export to PDF
        </button>
        <button
          ref={excelRef}
          onClick={() => {
            exportToExcel(memberFees);
          }}
          className="btn btn-primary mb-4"
          style={{ display: "none" }}
        >
          Export to Excel
        </button>
        <button
          ref={printRef}
          onClick={downloadPDFPrint}
          className="btn btn-primary mb-4"
          style={{ display: "none" }}
        >
          Print
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th id="memberPhoto">Photo</th>
            <th
              id="memberName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMemberFees, setPage);
              }}
            >
              Member Name
              <span className="px-2">
                {sort === "memberName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="memId"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMemberFees, setPage);
              }}
            >
              Member ID
              <span className="px-2">
                {sort === "memId" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th>Route Name</th>
            <th>{type === 3 || type === 2 ? "Pending Fees" : "Due Fees"}</th>
            <th>Paid Fees</th>
            <th>Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {memberFees?.map((item) => {
              return (
                <MemberFeesListItems
                  item={item}
                  key={Math.random()}
                  type={type}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(PaymentList);
