import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DriverDataType } from "./DriverList";
import { useLoading } from "Context/LoadingContext";
import { successToast, warnToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { Button, Modal } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Password from "../Password";
import { drvDoc, drvImage } from "constants/Constants";

type Props = {};

const DriverEdit = (props: Props) => {
  const { setLoading } = useLoading();
  const { state } = useLocation();
  const nav = useNavigate();
  const [driver, setDriver] = useState<DriverDataType>();
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  const [param] = useSearchParams();

  const findOneDriver = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getSingleDriverById/${state.x._id}`
      );
      if (res.data === "Ok") {
        setLoading(false);
        nav("/driver");
      }
      setDriver(res.data.driver);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [state.x._id]);

  useEffect(() => {
    findOneDriver();
  }, [findOneDriver]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: driver?.name,
      address: driver?.address,
      email: driver?.email,
      contactNo: driver?.contactNo,
      description: driver?.description,
      active: driver?.isActive,
      isDeleted: false,
      pinCode: driver?.pinCode,
      photo: driver?.photo,
      drivingLicenceDoc: driver?.drivingLicenceDoc,
      adharCardDoc: driver?.adharCardDoc,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Driver Name is too Short!")
        .max(30, "Driver Name is too Long!")
        .required("Driver Name is Required"),
      contactNo: Yup.string()
        .min(10, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!")
        .required("Contact Number is Required"),
      address: Yup.string()
        .min(1, "Address is too Short!")
        .max(300, "Address is too Long!")
        .required("Address is Required"),
      email: Yup.string().email().max(50),
      pinCode: Yup.string()
        .min(6, "PIN Code is Not Valid!")
        .max(6, "PIN Code is Not Valid!")
        .required("PIN Code is Required"),
      description: Yup.string().max(300, "Description is too Long!"),
      photo: Yup.mixed()
        .test("fileSize", "Photo size is too high", (value) => {
          if (typeof value === "string") return true; // Skip the test for default file string
          if (!value) return true; // Skip the test if no file is selected
          return value.size <= 52428800; // 50MB in bytes
        })
        .test(
          "fileType",
          "Please enter a photo with jpg, jpeg, or png extension",
          (value) => {
            if (typeof value === "string") return true; // Skip the test for default file string
            if (!value) return true; // Skip the test if no file is selected
            return /(\.jpg|\.jpeg|\.png)$/i.test(value.name);
          }
        ),

      drivingLicenceDoc: Yup.mixed()
        .test(
          "fileSize",
          "Driving Licence Document size is too high",
          (value) => {
            if (typeof value === "string") return true; // Skip the test for default file string
            if (!value) return true; // Skip the test if no file is selected
            return value.size <= 209715200; // 200MB in bytes
          }
        )
        .test(
          "fileType",
          "Please enter a Driving Licence Document with jpg, jpeg, png, pdf, or docx extension",
          (value) => {
            if (typeof value === "string") return true; // Skip the test for default file string
            if (!value) return true; // Skip the test if no file is selected
            return /\.(jpg|jpeg|png|pdf|docx?)$/i.test(value.name);
          }
        ),

      adharCardDoc: Yup.mixed()
        .test("fileSize", "AdharCard Document size is too high", (value) => {
          if (typeof value === "string") return true; // Skip the test for default file string
          if (!value) return true; // Skip the test if no file is selected
          return value.size <= 209715200; // 200MB in bytes
        })
        .test(
          "fileType",
          "Please enter an AdharCard Document with jpg, jpeg, png, pdf, or docx extension",
          (value) => {
            if (typeof value === "string") return true; // Skip the test for default file string
            if (!value) return true; // Skip the test if no file is selected
            return /\.(jpg|jpeg|png|pdf|docx?)$/i.test(value.name);
          }
        ),

      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      name,
      address,
      email,
      contactNo,
      description,
      active,
      pinCode,
      photo,
      isDeleted,
      adharCardDoc,
      drivingLicenceDoc,
    }) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name || "");
        formData.append("address", address || "");
        formData.append("email", email || "");
        formData.append("contactNo", String(contactNo) || "");
        formData.append("description", description || "");
        formData.append("isActive", String(active));
        formData.append("isDeleted", String(isDeleted));
        formData.append("pinCode", String(pinCode) || "");
        formData.append("photo", photo || ""); // Assuming 'photo' is the selected file object
        formData.append("adharCardDoc", adharCardDoc || "");
        formData.append("drivingLicenceDoc", drivingLicenceDoc || "");
        formData.append("bloodGroup", "");
        formData.append("dob", "");
        formData.append("note", "");
        formData.append("details", "");
        const res = await axiosInstance.put(
          `/master/updateDriver/${state.x._id}`,
          formData
        );
        const responseMessage = res.data.message;
        if (res.status === 202) {
          setLoading(false);
          warnToast(res.data);
        }
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("/driver");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        setLoading(false);
      }
    },
  });
  return (
    <>
      {pathname !== "/driver" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/driver" />
        </div>
      )}
      <Tabs defaultIndex={Number(param.get("index"))}>
        <TabList>
          <Tab>Driver</Tab>
          <Tab>Password</Tab>
        </TabList>
        <TabPanel>
          <div className="formBg">
            <form onSubmit={formik.handleSubmit}>
              <div className=" row align-items-center">
                <label className="col-md-6 mb-3" htmlFor="name">
                  <input
                    className={`form-control ${
                      formik.errors.name && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="name"
                    name="name"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    placeholder="Driver Name"
                  />
                  <span className="top-placeholder">Driver Name*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="contactNo">
                  <input
                    className={`form-control ${
                      formik.errors.contactNo && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="contactNo"
                    name="contactNo"
                    type="number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.contactNo}
                    placeholder="Contact Number"
                  />
                  <span className="top-placeholder">Contact Number*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="address">
                  <input
                    className={`form-control ${
                      formik.errors.address && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="address"
                    name="address"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.address}
                    placeholder="Address"
                  />
                  <span className="top-placeholder">Address*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="email">
                  <input
                    className="form-control"
                    id="email"
                    name="email"
                    type="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="Email Address"
                  />
                  <span className="top-placeholder">Email</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="pinCode">
                  <input
                    className={`form-control ${
                      formik.errors.pinCode && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="pinCode"
                    name="pinCode"
                    type="number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.pinCode}
                    placeholder="PIN Code"
                  />
                  <span className="top-placeholder">PIN Code*</span>
                </label>
                <div className="col-md-1 text-center">
                  <i className="userImg">
                    <img
                      src={`${
                        driver?.photo !== ""
                          ? drvImage + driver?.photo
                          : drvImage + "Default.png"
                      }`}
                      alt="DriverPic"
                    />
                  </i>
                </div>
                <label className="col-md-5 mb-3" htmlFor="photo">
                  <input
                    className="form-control uploadPhoto form-control-lg"
                    id="photo"
                    name="photo"
                    type="file"
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      const file =
                        event.currentTarget.files &&
                        event.currentTarget.files[0];
                      formik.setFieldValue("photo", file || ""); // If file is null, set an empty string
                    }}
                    placeholder="Enter photo"
                  />
                </label>
                {(driver?.drivingLicenceDoc as string) !== "" && (
                  <div className="col-md-1 text-center">
                    <a
                      href={`${drvDoc}${driver?.drivingLicenceDoc}`}
                      download={`${drvDoc}${driver?.drivingLicenceDoc}`}
                      rel="noopener noreferrer"
                    >
                      Licence
                    </a>
                  </div>
                )}
                <label
                  className={`${
                    (driver?.drivingLicenceDoc as string) !== ""
                      ? "col-md-5"
                      : "col-md-6"
                  } mb-3`}
                  htmlFor="drivingLicenceDoc"
                >
                  <input
                    className="form-control uploadDrivingLicence form-control-lg"
                    id="drivingLicenceDoc"
                    name="drivingLicenceDoc"
                    type="file"
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      const file =
                        event.currentTarget.files &&
                        event.currentTarget.files[0];
                      formik.setFieldValue("drivingLicenceDoc", file || ""); // If file is null, set an empty string
                    }}
                    placeholder="Upload Driving Licence Document"
                  />
                </label>
                {(driver?.adharCardDoc as string) !== "" && (
                  <div className="col-md-1 text-center">
                    <a
                      href={`${drvDoc}${driver?.adharCardDoc}`}
                      download={`${drvDoc}${driver?.adharCardDoc}`}
                      rel="noopener noreferrer"
                    >
                      Adhar Card
                    </a>
                  </div>
                )}
                <label
                  className={`${
                    (driver?.adharCardDoc as string) !== ""
                      ? "col-md-5"
                      : "col-md-6"
                  } mb-3`}
                  htmlFor="adharCardDoc"
                >
                  <input
                    className="form-control adharCard form-control-lg"
                    id="adharCardDoc"
                    name="adharCardDoc"
                    type="file"
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      const file =
                        event.currentTarget.files &&
                        event.currentTarget.files[0];
                      formik.setFieldValue("adharCardDoc", file || ""); // If file is null, set an empty string
                    }}
                    placeholder="Upload AdharCard Document"
                  />
                </label>
                <label className="col-md-12 mb-3" htmlFor="description">
                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    placeholder="Driver Description"
                  ></textarea>

                  <span className="top-placeholder">Description</span>
                </label>
                <div className="col-md-3 mb-3">
                  <label className="custCheck">
                    Active
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      checked={formik.values.active}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="custCheck mt-3">
                    Delete
                    <input
                      type="checkbox"
                      id="isDeleted"
                      name="isDeleted"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked === true) {
                          setShow(true);
                        }
                      }}
                      checked={formik.values.isDeleted}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>

              <div className="col-md-12 mb-3 text-center">
                <SubmitBtn errors={formik.errors} />
                <CancelBtn path=".." />
              </div>
            </form>
          </div>
          <Modal show={show}>
            <Modal.Body>
              Are you sure you want to delete this record?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setShow(false);
                }}
              >
                Ok
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  formik.setFieldValue("isDeleted", false);
                  setShow(false);
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </TabPanel>
        <TabPanel>
          <Password
            state={state.x}
            link={`/master/updateDriver/${state.x._id}`}
          />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default memo(DriverEdit);
