import axiosInstance from "axiosInstance";
import React, { useCallback, useEffect, useState } from "react";
import socket from "socket";
import { driverVehicleDetails } from "types/driverVehicleTypes";
import { useNavigate } from "react-router-dom";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import mediumSpeed from "../../assets/images/lowSpeed.png";
import highSpeed from "../../assets/images/highSpeed.png";
import { drvImage } from "constants/Constants";
type Props = {
  pickup: boolean;
};
const MemberVehicleDetails = (props: Props) => {
  const [memberVehicleDetails, setMemberVehicleDetails] = useState<
    driverVehicleDetails[]
  >([]);
  const [overSpeed, setOverSpeed] = useState<string>("");
  const [overTemp, setOverTemp] = useState<string>("");
  const nav = useNavigate();
  const { notfound, setNotFound } = useNotFound();
  const [rfidCardRequired, setRfidCardRequired] = useState(true);

  const configDataSpeed = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getOneStaticConfig?key=Overspeed`
      );
      const conData = res.data.result.Config.value;
      setOverSpeed(conData);
    } catch (error) {
      console.log(error);
    }
  }, [overSpeed]);

  const configDataTemp = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getOneStaticConfig?key=Overtemperature`
      );
      const conData = res.data.result.Config.value;
      setOverTemp(conData);
    } catch (error) {
      console.log(error);
    }
  }, [overTemp]);

  const loadMemberVehicleDetails = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getAllVehicleMembersDetails?pickup=${props.pickup}`
      );
      const data = res.data.vehicleMemberToday;
      setMemberVehicleDetails(data);
      setNotFound(false);
    } catch (error) {
      setNotFound(true);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    configDataSpeed();
    configDataTemp();
    loadMemberVehicleDetails();

    socket.on("update-count", (data) => {
      if (
        data === "inMember" ||
        data === "outMember" ||
        data === "waitMember" ||
        data === "memberPick"
      ) {
        loadMemberVehicleDetails();
      }
    });
  }, []);

  useEffect(() => {
    const x = localStorage.getItem("config") as string;
    const config: any = JSON.parse(x);
    setRfidCardRequired(config.rfidCardCheck);
  }, []);

  return (
    <>
      <div className="container">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "50" }}>&nbsp;</th>
              <th>Name</th>
              <th>Vehicle No</th>
              <th>Route</th>
              <th>Shift</th>
              <th>{props.pickup ? "Pickup" : "Drop"}</th>
              {rfidCardRequired && (
                <>
                  <th>In</th>
                  <th>Out</th>
                </>
              )}
              <th>Wait</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          {!notfound && (
            <tbody>
              {memberVehicleDetails.map((item, index) => (
                <tr
                  key={index}
                  className="pointer"
                  onClick={() => {
                    nav("/dashboard/vehicleLatLog", {
                      state: { item },
                    });
                  }}
                >
                  <td>
                    <i className="userImg">
                      <img
                        src={`${drvImage}${
                          item.photo !== "" ? item.photo : "Default.png"
                        }`}
                        alt="DriverPic"
                      />
                    </i>
                  </td>
                  <td>{item.name}</td>
                  <td>{item.driverVehicleNo}</td>
                  <td>{item.driverRouteName}</td>
                  <td>{item.memberShiftName[0]?.name}</td>
                  <td>{item.memberPickupInVehicle}</td>
                  {rfidCardRequired && (
                    <>
                      <td>{item.inMember}</td>
                      <td>{item.outMember}</td>
                    </>
                  )}
                  <td>{item.waitMember}</td>
                  <td className="text-right">
                    {item.DriverSpeed <= overSpeed ? (
                      <img
                        src={mediumSpeed}
                        alt="CurrentSpeed"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={highSpeed}
                        alt="CurrentSpeed"
                        className="img-fluid"
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {notfound && <NotFound />}
      </div>
    </>
  );
};

export default MemberVehicleDetails;
