import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { AreaDDLType } from "types/areaMasterTypes";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import { LocationDataTypeDDL } from "types/locationMasterType";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";

type Props = {
  isFromEdit?: boolean;
};

const AssignmentAddForm = ({ isFromEdit }: Props) => {
  const { setLoading } = useLoading();

  const state = useLocation().state?.member;
  const pathState = useLocation();
  const nav = useNavigate();
  const [memberId, setMemberId] = useState("");

  /*pickUp states */
  const [area, setArea] = useState<AreaDDLType[]>([]);
  const [pin, setPin] = useState("");

  const [division, setDivision] = useState<AreaDivisionDDL[]>([]);
  const [divId, setDivId] = useState("");

  const [location, setLocation] = useState<LocationDataTypeDDL[]>([]);
  const [locId, setLocId] = useState("");

  const [border, setBorder] = useState<boolean>(false);

  const loadAreaCode = useCallback(async (pinCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getAreaDivision?pinCode=${pinCode}`
      );
      setLoading(false);
      return res.data.division;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadLocation = useCallback(async (areaCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getLocationByDivision?areaCode=${areaCode}`
      );
      setLoading(false);
      return res.data.locationNames;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  /* pickUp Dropdowns */
  const loadAreas = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getArea`);
      setArea(res.data.Areas);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadAreas();
    if (state === null) {
      nav("..");
    } else {
      setMemberId(state._id);
    }
  }, [loadAreas, state]);

  const formik = useFormik({
    initialValues: {
      note: "",
      details: "",
      active: true,
      isSame: true,
    },
    onSubmit: async ({ active, isSame }) => {
      try {
        // if (isSame) {
        if (locId === "" || pin === "" || divId === "") {
          return;
        }
        setLoading(true);
        const formData = {
          memberId: memberId,
          pickUpId: locId,
          pickUpPin: pin,
          pickUpDiv: divId,
          dropId: locId,
          dropPin: pin,
          dropDiv: divId,
          description: "",
          note: "",
          details: "",
          isSame: true,
          isActive: active,
        };
        const res = await axiosInstance.post(
          "/master/addMemberAssignment",
          formData
        );
        const responseMessage = res.data.message;
        if (responseMessage === "Created") {
          successToast();
          setLoading(false);
          if (isFromEdit) {
            pathState.state.memAssign = res.data.result;
            return;
          } else
            nav("../routeAdd", {
              state: { memberAssign: res.data.result, member: state },
            });
        }
        // } else {
        //   if (
        //     // memberId === "" ||
        //     locId === "" ||
        //     pin === "" ||
        //     divId === "" ||
        //     dropLocId === "" ||
        //     dropPin === "" ||
        //     dropDivId === ""
        //   ) {
        //     return;
        //   }
        //   setLoading(true);
        //   const formData = {
        //     memberId: memberId,
        //     pickUpId: locId,
        //     pickUpPin: pin,
        //     pickUpDiv: divId,
        //     dropId: dropLocId,
        //     dropPin: dropPin,
        //     dropDiv: dropDivId,
        //     description: "",
        //     note: "",
        //     details: "",
        //     isSame: isSame,
        //     isActive: active,
        //   };
        //   const res = await axiosInstance.post(
        //     "/master/addMemberAssignment",
        //     formData
        //   );
        //   const responseMessage = res.data;
        //   if (responseMessage === "Created") {
        //     successToast();
        //     setLoading(false);
        //     nav("../../");
        //   }
        // }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  const areaDropDown = async (m: string) => {
    if (m !== "") {
      setPin(m);
      const div = await loadAreaCode(m);
      if (div !== undefined) {
        setDivision(div);
      } else {
        setDivision([]);
      }
    } else {
      setDivision([]);
      setLocation([]);
    }
  };
  const divisionDropDown = async (n: string) => {
    if (n !== "") {
      setDivId(n);
      const loc = await loadLocation(n);
      if (loc !== undefined) {
        setLocation(loc);
      } else {
        setLocation([]);
      }
    } else {
      setLocation([]);
    }
  };

  /* Drop location dropdowns */
  // const dropAreaDropDown = async (m: string) => {
  //   if (m !== "") {
  //     setDropPin(m);
  //     const div = await loadAreaCode(m);
  //     if (div !== undefined) {
  //       setDropDivision(div);
  //     } else {
  //       setDropDivision([]);
  //     }
  //   } else {
  //     setDropDivision([]);
  //     setDropLocation([]);
  //   }
  // };
  // const dropDivisionDropDown = async (n: string) => {
  //   if (n !== "") {
  //     setDropDivId(n);
  //     const loc = await loadLocation(n);
  //     if (loc !== undefined) {
  //       setDropLocation(loc);
  //     } else {
  //       setDropLocation([]);
  //     }
  //   } else {
  //     setDropLocation([]);
  //   }
  // };

  return (
    <div className="formBg">
      <form className="row align-items-center " onSubmit={formik.handleSubmit}>
        <ul className="profileList">
          <li>
            <span>Member :</span>
            {state?.name}
          </li>
          <li>
            <span>Area :</span>
            {state?.area}
          </li>
          <li>
            <span>PIN Code :</span>
            {state?.pinCode}
          </li>
          <li>
            <span>Address :</span>
            {state?.address}
          </li>
        </ul>
        <div className="justify-content-between align-items-center d-flex my-3">
          <span>Pickup Details</span>
        </div>
        <div className="mb-2 row align-items-center">
          <label htmlFor="area" className="col-md-6 mb-3">
            <span className="top-placeholder">Area*</span>

            <select
              className={`form-control ${
                pin === "" && border ? "empty-form" : ""
              }`}
              id="area"
              onChange={async (e) => {
                areaDropDown(e.target.value);
              }}
            >
              <option value="">Select Area</option>
              {area.map(({ _id, areaName, pinCode }) => {
                return (
                  <option key={_id} value={pinCode}>
                    {`${areaName} (${pinCode})`}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="areaCode">
            <span className="top-placeholder">Division*</span>

            <select
              className={`form-control ${
                divId === "" && border ? "empty-form" : ""
              }`}
              id="areaCode"
              name="areaCode"
              onChange={async (e) => {
                divisionDropDown(e.target.value);
              }}
            >
              <option value="">Select Division</option>
              {division.map(({ _id, areaCode }) => {
                return (
                  <option key={_id} value={_id}>
                    {areaCode}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="location">
            <span className="top-placeholder">Location*</span>

            <select
              className={`form-control ${
                locId === "" && border ? "empty-form" : ""
              }`}
              id="location"
              name="location"
              onChange={async (e) => {
                setLocId(e.target.value);
              }}
            >
              <option value="">Select Location</option>
              {location.map(({ locationId, locationName }) => {
                return (
                  <option key={locationId} value={locationId}>
                    {locationName}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
        {/* <div className="col-md-12 mb-3 d-flex">
          <span className="mx-2">pickUp and drop location are same.</span>
          <div className="col-md-3 mb-3">
            <label className="custCheck">
              Yes
              <input
                type="checkbox"
                id="isSame"
                name="isSame"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                checked={formik.values.isSame}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        {!formik.values.isSame && (
          <div className="justify-content-between align-items-center d-flex my-3">
            <span>Drop Details</span>
          </div>
        )}
        {!formik.values.isSame && (
          <div className="mb-2 row align-items-center">
            <label htmlFor="area" className="col-md-6 mb-3">
              <select
                className="form-control"
                id="area"
                onChange={async (e) => {
                  dropAreaDropDown(e.target.value);
                }}
              >
                <option value="">Select Area</option>
                {dropArea.map(({ _id, areaName, pinCode }) => {
                  return (
                    <option key={_id} value={pinCode}>
                      {`${areaName} (${pinCode})`}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="areaCode">
              <select
                className="form-control"
                id="areaCode"
                name="areaCode"
                onChange={async (e) => {
                  dropDivisionDropDown(e.target.value);
                }}
              >
                <option value="">Select Division</option>
                {dropDivision.map(({ _id, areaCode }) => {
                  return (
                    <option key={_id} value={_id}>
                      {areaCode}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="location">
              <select
                className="form-control"
                id="location"
                name="location"
                onChange={async (e) => {
                  setDropLocId(e.target.value);
                }}
              >
                <option value="">Select Location</option>
                {dropLocation.map(({ locationId, locationName }) => {
                  return (
                    <option key={locationId} value={locationId}>
                      {locationName}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        )} */}
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn
            errors={formik.errors}
            clickFunc={() => {
              // if (!formik.values.isSame) {
              //   // if (memberId === "") {
              //   //   warnToast("Select member");
              //   // }
              //   if (pin === "") {
              //     warnToast("Select Area");
              //   }
              //   if (divId === "") {
              //     warnToast("Select division");
              //   }
              //   if (locId === "") {
              //     warnToast("Select location");
              //   }
              //   if (dropPin === "") {
              //     warnToast("Select Drop area");
              //   }
              //   if (dropDivId === "") {
              //     warnToast("Select Drop division");
              //   }
              //   if (dropLocId === "" || dropPin === "" || dropDivId === "") {
              //     warnToast("Select Drop area");
              //   }
              // } else {
              // if (memberId === "") {
              //   warnToast("Select member");
              // }
              setBorder(true);
              if (pin === "") {
                warnToast("Select Area");
              }
              if (divId === "") {
                warnToast("Select Division");
              }
              if (locId === "") {
                warnToast("Select Location");
              }
              // }
              toastValidation(formik.errors);
            }}
          />
          {/* <CancelBtn path=".." /> */}
        </div>
      </form>
    </div>
  );
};

export default AssignmentAddForm;
