import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};
const RFDICardForm = (props: Props) => {
  const { setLoading } = useLoading();
  const location = useLocation();

  const nav = useNavigate();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      cardNumber: "",
      cardValue: "",
      cardCode: "",
      cardDate: "",
      cardExpiredDate: "",
      active: true,
    },
    validationSchema: Yup.object().shape({
      cardNumber: Yup.string().max(30).required("Card Number is Required"),
      cardValue: Yup.string().min(1).max(30).required("Card Value is Required"),
      cardDate: Yup.string().required("Card Date is Required"),
      cardExpiredDate: Yup.string().required("Card Expire Date is Required"),
      cardCode: Yup.string().max(30).required("Card Code is Required"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      cardNumber,
      cardValue,
      cardCode,
      cardDate,
      cardExpiredDate,
      active,
    }) => {
      setLoading(true);
      try {
        const formData = {
          cardNumber: cardNumber,
          cardValue: cardValue,
          cardCode: cardCode,
          cardDate: cardDate,
          cardExpiredDate: cardExpiredDate,
          isActive: active,
        };
        const res = await axiosInstance.post("/master/addRFIDCard", formData);
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("/rfdiCard");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="cardNumber">
              <input
                className={`form-control ${
                  formik.errors.cardNumber && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="cardNumber"
                name="cardNumber"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.cardNumber}
                placeholder="Card Number"
              />

              <span className="top-placeholder">Card Number*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="cardValue">
              <input
                className={`form-control ${
                  formik.errors.cardValue && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="cardValue"
                name="cardValue"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.cardValue}
                placeholder="Card Value"
              />

              <span className="top-placeholder">Card Value*</span>
            </label>

            <label className="col-md-6 mb-3" htmlFor="cardDate">
              <input
                className={`form-control session-date ${
                  formik.errors.cardDate && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="cardDate"
                name="cardDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.cardDate}
                placeholder="Card Date"
                // onFocus={(e) => (e.target.type = "date")}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />

              <span className="top-placeholder">Date*</span>
            </label>

            <label className="col-md-6 mb-3" htmlFor="cardExpiredDate">
              <input
                className={`form-control session-date ${
                  formik.errors.cardExpiredDate && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="cardExpiredDate"
                name="cardExpiredDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.cardExpiredDate}
                placeholder="Card Expired Date"
                // onFocus={(e) => (e.target.type = "date")}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />

              <span className="top-placeholder">Expired Date*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="cardCode">
              <input
                className={`form-control ${
                  formik.errors.cardCode && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="cardCode"
                name="cardCode"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.cardCode}
                placeholder="Card Code"
              />

              <span className="top-placeholder">Code*</span>
            </label>

            <div className="col-md-12 mb-3">
              <label className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            {(location.pathname === "/rfdiCard/addForm" ||
              location.pathname === "/rfdiCard/editForm") && (
              <CancelBtn path="/rfdiCard" />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(RFDICardForm);
