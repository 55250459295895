import { ReactComponent as MembersIcon } from "../../assets/images/member-01.svg";

import { ReactComponent as ConfigurationIcon } from "../../assets/images/Configration.svg";

import { ReactComponent as NotificationIcon } from "../../assets/images/notification-08.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout-03.svg";
import { ReactComponent as VehicleIcon } from "../../assets/images/vehicle-06.svg";
import { ReactComponent as Ico16 } from "../../assets/images/reports-04.svg";
import { ReactComponent as Ico12 } from "../../assets/images/leave-02.svg";
import { ReactComponent as Ico13 } from "../../assets/images/complaint-09.svg";
import CustomNavLink from "components/NavLink";
import { BasicreportItems, basicSideBarItems } from "./data";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
const icons = [
  0,
  <MembersIcon className="Approved_Size" />,
  <Ico12 className="Approved_Size" />,
  <Ico13 className="Approved_Size" />,
  <VehicleIcon className="Approved_Size" />,
  <Ico16 className="Approved_Size" />,
  <MembersIcon className="Approved_Size" />,

  <NotificationIcon className="Approved_Size" />,
  <LogoutIcon className="Approved_Size" />,
];
type Props = {
  logout: () => void;
};

const BasicSidebar = ({ logout }: Props) => {
  const [optionName, setOptionName] = useState("");
  const [openNav, setOpenNav] = useState(false);
  const location = useLocation();
  const x = location.pathname.split("/");
  const isEdit =
    x[x.length - 1].includes("dit") ||
    x[x.length - 1].includes("vehicleLatLog");
  return (
    <ul className="mainNav">
      {basicSideBarItems.map(({ icon, text, to }) => (
        <li key={Math.random()}>
          <CustomNavLink to={to} text={text}>
            {icons[icon]}
          </CustomNavLink>
        </li>
      ))}
      <li>
        <Link
          data-id="report"
          onClick={(e) => {
            const navOp = e.currentTarget.dataset.id;
            setOptionName(navOp as string);
            if (openNav === true && optionName === "report") {
              setOpenNav(false);
            } else {
              setOpenNav(true);
            }
          }}
          to={isEdit ? ".." : "#"}
          className={
            optionName === "report" && openNav ? "ddNav active" : "ddNav"
          }
        >
          <i>
            <Ico16 style={{ width: "20px", height: "20px" }} />
          </i>
          Reports
        </Link>
        <ul
          className={`${
            optionName === "report" && openNav ? "d-block" : "d-none  "
          } `}
        >
          <li>
            {BasicreportItems.map(({ to, text }) => (
              <CustomNavLink key={Math.random()} to={to} text={text} padding />
            ))}
          </li>
        </ul>
      </li>
      <li onClick={logout} className="logout">
        <Link to="/">
          <i>
            <LogoutIcon style={{ width: "20px", height: "20px" }} />
          </i>
          Logout
        </Link>
      </li>
    </ul>
  );
};

export default BasicSidebar;
