import { useLoading } from "Context/LoadingContext";
import axiosInstance from "axiosInstance";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import toastValidation, { successToast } from "constants/toastConfig";
import CancelBtn from "components/Buttons/Cancel";
import { useNavigate } from "react-router-dom";

const PaymentConfigAddForm = () => {
  const { setLoading } = useLoading();
  const [show, setShow] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [primary, setPrimary] = useState(false);

  const nav = useNavigate();

  const handleClose = () => {
    setShow(false);
    formikPersonal.values.isPrimaryPer = false;
  };
  const primaryExist = async () => {
    try {
      const res = await axiosInstance.get("/master/getAccountDetails");
      const data: any = res.data.result.AccountInformation;
      const isPrimaryExist = data.find((x: any) => {
        return x.isPrimary === true;
      })?.isPrimary;
      if (isPrimaryExist) {
        setPrimary(isPrimaryExist);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitModal = async () => {
    try {
      await axiosInstance.put(`/master/deleteOnePaymentConfig`);
      if (paymentType === "personalSetting") {
        formikPersonal.values.isPrimaryPer = true;
      } else {
        formikCompany.values.isPrimaryCom = true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formikPersonal = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      email: "",
      contact: "",
      isActivePer: true,
      isPrimaryPer: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Organizer Name is Too Short!")
        .max(30, "Organizer Name is Too Long!")
        .required("Organizer Name is Required"),
      email: Yup.string().email().max(50).required("Email Address is Required"),
      contact: Yup.string()
        .min(10, "Contact Number is Not Valid!!")
        .max(10, "Contact Number is Not Valid!")
        .required("Contact Number is Required"),
      isActivePer: Yup.boolean(),
      isPrimaryPer: Yup.boolean(),
    }),
    onSubmit: async ({ email, contact, isActivePer, name, isPrimaryPer }) => {
      setLoading(true);
      try {
        const formData = {
          settingType: "Personal",
          name: name,
          email: email,
          contact: String(contact),
          description: "",
          note: "",
          details: "",
          isPrimary: isPrimaryPer,
          isActive: isActivePer,
          isDeleted: false,
        };
        const res = await axiosInstance.post(
          "/master/addPersonalAccount",
          formData
        );
        const statusCode = res.data.statusCode;
        if (statusCode === 200) {
          setLoading(false);
          nav("../");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
  });
  const validateFormPer = () => {
    toastValidation(formikPersonal.errors);
  };

  const formikCompany = useFormik({
    validateOnMount: true,
    initialValues: {
      companyName: "",
      contact: "",
      email: "",
      description: "",
      note: "",
      details: "",
      isActiveCom: true,
      isPrimaryCom: false,
    },
    validationSchema: Yup.object().shape({
      companyName: Yup.string()
        .min(1, "Company Name is Too Short!")
        .max(30, "Company Name is Too Long!")
        .required("Company Name is Required"),
      email: Yup.string().email().max(50).required("Email Address is Required"),
      contact: Yup.string()
        .min(10, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!")
        .required("Contact Number is Required"),
      description: Yup.string(),
      isActiveCom: Yup.boolean(),
      isPrimaryCom: Yup.boolean(),
    }),
    onSubmit: async ({
      email,
      contact,
      description,
      companyName,
      isActiveCom,
      isPrimaryCom,
    }) => {
      setLoading(true);
      try {
        const formData = {
          settingType: "Company",
          companyName: companyName,
          email: email,
          contact: String(contact),
          description: description,
          note: "",
          details: "",
          isPrimary: isPrimaryCom,
          isActive: isActiveCom,
          isDeleted: false,
        };
        const res = await axiosInstance.post(
          "/master/addCompanyAccount",
          formData
        );
        const statusCode = res.data.statusCode;
        if (statusCode === 200) {
          successToast();
          setLoading(false);
          // window.location.reload();
          nav("../");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  const validateFormCom = () => {
    toastValidation(formikCompany.errors);
  };
  useEffect(() => {
    primaryExist();
  }, []);
  return (
    <>
      <div className="d-flex flex-column">
        <div className="formBg">
          <label
            className="col-md-12 mb-3 d-flex gap-5 py-3"
            htmlFor="feesType"
          >
            <span>Configure your payment Setting :</span>
            <div>
              <>
                <Form.Check
                  type="radio"
                  label="Personal account setting"
                  id="personalSetting"
                  className="pb-3"
                  style={{
                    cursor: "pointer",
                  }}
                  name="paymentType"
                  onChange={(e: any) => {
                    setPaymentType(e.target.id);
                  }}
                />
                <Form.Check
                  type="radio"
                  label="Company account setting"
                  id="companySetting"
                  className="pb-3"
                  style={{
                    cursor: "pointer",
                  }}
                  name="paymentType"
                  onChange={(e: any) => {
                    setPaymentType(e.target.id);
                  }}
                />
              </>
            </div>
          </label>
          {paymentType === "personalSetting" && (
            <form onSubmit={formikPersonal.handleSubmit}>
              <div className="row align-items-center">
                <label className="col-md-6 mb-3" htmlFor="name">
                  <input
                    className={`form-control ${
                      formikPersonal.errors.name &&
                      formikPersonal.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="name"
                    name="name"
                    type="text"
                    onBlur={formikPersonal.handleBlur}
                    onChange={formikPersonal.handleChange}
                    value={formikPersonal.values.name}
                    placeholder="Organizer Name"
                  />

                  <span className="top-placeholder">Name*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="email">
                  <input
                    className={`form-control ${
                      formikPersonal.errors.email &&
                      formikPersonal.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="email"
                    name="email"
                    type="text"
                    onBlur={formikPersonal.handleBlur}
                    onChange={formikPersonal.handleChange}
                    value={formikPersonal.values.email}
                    placeholder="Email"
                  />

                  <span className="top-placeholder">Email*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="contact">
                  <input
                    className={`form-control ${
                      formikPersonal.errors.contact &&
                      formikPersonal.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="contact"
                    name="contact"
                    type="number"
                    onBlur={formikPersonal.handleBlur}
                    onChange={formikPersonal.handleChange}
                    value={formikPersonal.values.contact}
                    placeholder="Contact"
                  />

                  <span className="top-placeholder">Contact No*</span>
                </label>
                <div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="isActivePer" className="custCheck">
                      Active
                      <input
                        type="checkbox"
                        id="isActivePer"
                        name="isActivePer"
                        onBlur={formikPersonal.handleBlur}
                        onChange={formikPersonal.handleChange}
                        checked={formikPersonal.values.isActivePer}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="isPrimaryPer" className="custCheck">
                      Primary
                      <input
                        type="checkbox"
                        id="isPrimaryPer"
                        name="isPrimaryPer"
                        onBlur={formikPersonal.handleBlur}
                        onChange={(e) => {
                          formikPersonal.handleChange(e);
                          if (
                            formikPersonal.values.isPrimaryPer === false &&
                            paymentType === "personalSetting"
                          ) {
                            setShow(true);
                          }
                        }}
                        checked={formikPersonal.values.isPrimaryPer}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3 text-center">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={validateFormPer}
                >
                  Submit
                </button>
                <CancelBtn path=".." />
              </div>
            </form>
          )}
          {paymentType === "companySetting" && (
            <form onSubmit={formikCompany.handleSubmit}>
              <div className="row align-items-center">
                <label className="col-md-6 mb-3" htmlFor="companyName">
                  <input
                    className={`form-control ${
                      formikCompany.errors.companyName &&
                      formikCompany.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="companyName"
                    name="companyName"
                    type="text"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    value={formikCompany.values.companyName}
                    placeholder="Company Name"
                  />
                  <span className="top-placeholder">Name*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="email">
                  <input
                    className={`form-control ${
                      formikCompany.errors.email &&
                      formikCompany.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="email"
                    name="email"
                    type="text"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    value={formikCompany.values.email}
                    placeholder="Email"
                  />

                  <span className="top-placeholder">Email*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="contact">
                  <input
                    className={`form-control ${
                      formikCompany.errors.contact &&
                      formikCompany.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="contact"
                    name="contact"
                    type="number"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    value={formikCompany.values.contact}
                    placeholder="Contact"
                  />

                  <span className="top-placeholder">Contact*</span>
                </label>
                <label className="col-md-12 mb-3" htmlFor="description">
                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    value={formikCompany.values.description}
                    placeholder="Description"
                  ></textarea>

                  <span className="top-placeholder">Description</span>
                </label>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="isActiveCom" className="custCheck">
                  Active
                  <input
                    type="checkbox"
                    id="isActiveCom"
                    name="isActiveCom"
                    onBlur={formikCompany.handleBlur}
                    onChange={formikCompany.handleChange}
                    checked={formikCompany.values.isActiveCom}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="isPrimaryCom" className="custCheck">
                  Primary
                  <input
                    type="checkbox"
                    id="isPrimaryCom"
                    name="isPrimaryCom"
                    onBlur={formikCompany.handleBlur}
                    onChange={(e) => {
                      formikCompany.handleChange(e);
                      if (formikCompany.values.isPrimaryCom === false) {
                        setShow(true);
                      }
                    }}
                    checked={formikCompany.values.isPrimaryCom}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              <div className="col-md-12 mb-3 text-center">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={validateFormCom}
                >
                  Submit
                </button>
                <CancelBtn path=".." />
              </div>
            </form>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          {primary
            ? "Are you sure you want to deactivate the previous primary account?"
            : "Are you sure you want to set this as primary account?"}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              onSubmitModal();
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentConfigAddForm;
