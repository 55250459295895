import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo, useMemo } from "react";
import { useLoading } from "Context/LoadingContext";
import { useTable, usePagination } from "react-table";
import "../../../assets/styles/style.css";
import { ShiftDDL } from "types/shiftType.types";
import {
  downloadTableAsPrint,
  exportPDF,
  exportToExcel,
} from "constants/Constants";
import { DepartmentDDLType } from "types/departmentMasterType";
import CategoryDataType from "types/categoryTypes";
import { TypeMasterDataType } from "types/typeMasterTypes";

type Props = {};

const MemberPickUpDropLocationTimeList = (props: Props): JSX.Element => {
  const [data, setData] = useState([]);
  const [shiftTypeId, setShiftTypeId] = useState("");
  const [shiftData, setShiftData] = useState<ShiftDDL[]>();
  const [department, setDepartment] = useState<DepartmentDDLType[]>([]);
  const [depId, setDepId] = useState("");
  const [category, setCategory] = useState<CategoryDataType[]>([]);
  const [catId, setCatId] = useState("");
  const [type, setType] = useState<TypeMasterDataType[]>([]);
  const [typeId, setTypeId] = useState("");
  const { setLoading } = useLoading();

  const loadReports = useCallback(
    async (
      shiftTypeId: string,
      departmentId: string,
      categoryId: string,
      typeId: string
    ) => {
      try {
        if (
          shiftTypeId !== "" &&
          departmentId !== "" &&
          categoryId !== "" &&
          typeId !== ""
        ) {
          const res = await axiosInstance.get(
            `/report/memberPickUpDropLocTime?shiftTypeId=${shiftTypeId}&departmentId=${depId}&categoryId=${catId}&typeId=${typeId}`
          );
          console.log(res.data, "hhhh");
          setData(res.data.resp);
        } else if (
          shiftTypeId !== "" &&
          departmentId !== "" &&
          categoryId !== ""
        ) {
          const res = await axiosInstance.get(
            `/report/memberPickUpDropLocTime??shiftTypeId=${shiftTypeId}&depId=${depId}&catId=${catId}`
          );
          setData(res.data.resp);
        } else if (shiftTypeId !== "" && departmentId !== "") {
          const res = await axiosInstance.get(
            `/report/memberPickUpDropLocTime?shiftTypeId=${shiftTypeId}&depId=${depId}`
          );
          setData(res.data.resp);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [data]
  );

  const loadShift = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropdown/getShiftType`);
      setShiftData(res.data.shiftTypes);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const departmentList = res.data.Departments;
      setDepartment(departmentList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadCategories = useCallback(async (depId: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getCategory?depId=${depId}`
      );
      const categories = res.data.categories;
      setCategory(categories);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadTypes = useCallback(async (catId: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getType?catId=${catId}`);
      const typeList = res.data.types;
      setType(typeList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadReports(shiftTypeId, depId, catId, typeId);
    loadShift();
    loadDepartments();
  }, [
    loadDepartments,
    loadCategories,
    loadTypes,
    shiftTypeId,
    depId,
    catId,
    typeId,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "MemberName",
        accessor: "memberName",
      },
      {
        Header: "PickUpRoute",
        accessor: "pickUpRoute",
      },
      {
        Header: "DropRoute",
        accessor: "dropRoute",
      },
      {
        Header: "Shift",
        accessor: "shiftType",
      },
      {
        Header: "Department",
        accessor: "departmentName",
      },
      {
        Header: "PickUpLocation",
        accessor: "memberPickUpLocation",
      },
      {
        Header: "DropLocation",
        accessor: "memberDropLocation",
      },
      {
        Header: "PickUpTime",
        accessor: "memberPickupTime",
      },
      {
        Header: "DropTime",
        accessor: "memberDropTime",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as any,
    },
    usePagination
  );

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Member PickUpDrop Location-Time Report</span>
      </div>
      <button onClick={exportPDF} className="btn btn-primary mb-4">
        Export to PDF
      </button>
      <button
        onClick={() => {
          exportToExcel(data);
        }}
        className="btn btn-primary mb-4"
      >
        Export to Excel
      </button>
      <button
        onClick={() => downloadTableAsPrint(columns, data)}
        className="btn btn-primary mb-4"
      >
        Print
      </button>
      <div className="formBg mb-3">
        <div className="mb-2 row align-items-center">
          <label className="col-md-6 mb-3" htmlFor="">
            <select
              className="form-control"
              id="shift"
              name="shift"
              onChange={async (e) => {
                setShiftTypeId(e.target.value);
                setData([]);
              }}
            >
              <option value="">Select Shift</option>
              {shiftData?.map((item) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </label>
          <label htmlFor="" className="col-md-6 mb-3">
            <select
              className="form-control"
              id="department"
              onChange={(e) => {
                setDepId(e.target.value);
                loadCategories(e.target.value);
                setData([]);
                setCategory([]);
                setCatId("");
                setType([]);
                setTypeId("");
                // if (shiftTypeId !== "" && depId !== "") {
                //   loadReports(shiftTypeId, e.target.value, "", "");
                // }
              }}
            >
              <option value="">Select Department</option>
              {department.map(({ _id, departmentName }) => {
                return (
                  <option key={_id} value={_id}>
                    {departmentName}
                  </option>
                );
              })}
            </select>
          </label>
          <label htmlFor="" className="col-md-6 mb-3">
            <select
              className="form-control mt-3"
              id="department"
              onChange={(e) => {
                setCatId(e.target.value);
                loadTypes(e.target.value);
                setData([]);
                setType([]);
                setTypeId("");
                // if (shiftTypeId !== "" && depId !== "" && catId !== "") {
                //   loadReports(shiftTypeId, depId, e.target.value, "");
                // }
              }}
            >
              <option value="">Select Category</option>
              {category.map(({ _id, name }) => {
                return (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                );
              })}
            </select>
          </label>
          <label htmlFor="" className="col-md-6 mb-3">
            <select
              className="form-control mt-3"
              id="department"
              onChange={(e) => {
                setTypeId(e.target.value);
                setData([]);
                // if (
                //   shiftTypeId !== "" &&
                //   depId !== "" &&
                //   catId !== "" &&
                //   typeId !== ""
                // ) {
                //   loadReports(shiftTypeId, depId, catId, e.target.value);
                // }
              }}
            >
              <option value="">Select Type</option>
              {type.map(({ _id, name }) => {
                return (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>
      <table {...getTableProps()} id="report-table" className="tableForReport">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="trForReport">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="thForReport">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="trForReport">
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()} className="tdForReport">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination m-4 gap-2">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">>"}
        </button>{" "}
        <span className="mx-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(MemberPickUpDropLocationTimeList);
