import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo, useMemo } from "react";
import { useLoading } from "Context/LoadingContext";
import { useTable, usePagination } from "react-table";
import "../../../assets/styles/style.css";
import { ShiftDDL } from "types/shiftType.types";
import {
  downloadTableAsPrint,
  exportPDF,
  exportToExcel,
} from "constants/Constants";

type Props = {};

const DriverPickupDropCountReportList = (props: Props): JSX.Element => {
  const [data, setData] = useState([]);
  const [shiftTypeId, setShiftTypeId] = useState("");
  const [shiftData, setShiftData] = useState<ShiftDDL[]>();
  const [driverId, setDriverId] = useState("");
  const [driverData, setDriverData] = useState<any>();
  const [date, setDate] = useState("");
  const { setLoading } = useLoading();

  const loadShift = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropdown/getShiftType`);
      setShiftData(res.data.shiftTypes);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadDriver = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropdown/getDriverDDL`);
      setDriverData(res.data.Driver);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadReports = useCallback(
    async (shiftTypeId: string, driverId: string, date: string) => {
      try {
        setLoading(true);

        if (shiftTypeId !== "" && driverId !== "" && date !== "") {
          const res = await axiosInstance.get(
            `/report/driverPickDrop?shift=${shiftTypeId}&driver=${driverId}&date=${date}`
          );
          setData(res.data.resp);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [data]
  );

  useEffect(() => {
    loadShift();
    loadDriver();
    // loadReports(shiftTypeId);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "VehicleNo",
        accessor: "vehicleNo",
      },
      {
        Header: "RouteName",
        accessor: "routeName",
      },
      {
        Header: "LocationName",
        accessor: "locationName",
      },
      {
        Header: "PickUpCount",
        accessor: "pickUpCount",
      },
      {
        Header: "DropCount",
        accessor: "dropCount",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as any,
    },
    usePagination
  );

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Driver PickUp-Drop Count</span>
      </div>
      <button onClick={exportPDF} className="btn btn-primary mb-4">
        Export to PDF
      </button>
      <button
        onClick={() => {
          exportToExcel(data);
        }}
        className="btn btn-primary mb-4"
      >
        Export to Excel
      </button>
      <button
        onClick={() => downloadTableAsPrint(columns, data)}
        className="btn btn-primary mb-4"
      >
        Print
      </button>
      <div className="formBg mb-3">
        <div className="mb-2 row align-items-center">
          <label className="col-md-6 mb-3" htmlFor="shift">
            <select
              className="form-control"
              id="shift"
              name="shift"
              onChange={async (e) => {
                setShiftTypeId(e.target.value);
                setData([]);
              }}
            >
              <option value="">Select Shift</option>
              {shiftData?.map((item) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="driver">
            <select
              className="form-control"
              id="driver"
              name="driver"
              onChange={async (e) => {
                setDriverId(e.target.value);
                setData([]);
              }}
            >
              <option value="">Select Driver</option>
              {driverData?.map((item: any) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.driverName}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="code">
            <input
              className="form-control"
              id="date"
              name="date"
              type="text"
              placeholder="Date"
              onFocus={(e) => (e.target.type = "date")}
              onChange={(e) => {
                setDate(e.target.value);
                loadReports(shiftTypeId, driverId, e.target.value);
              }}
            />
          </label>
        </div>
      </div>
      <table {...getTableProps()} id="report-table" className="tableForReport">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="trForReport">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="thForReport">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="trForReport">
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()} className="tdForReport">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination m-4 gap-2">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">>"}
        </button>{" "}
        <span className="mx-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(DriverPickupDropCountReportList);
