import axiosInstance from "axiosInstance";
import { useCallback, useState, useEffect, memo } from "react";
import { FeesByVehicleList } from "types/feesAreaTypes";
import { exportToExcel, infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import FeesByVehicleListItems from "./ListItems";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";
import NotFound from "components/NotFound";
import { Button, Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";

type Props = {};

const FeesVehicleList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state, pathname } = useLocation();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("area");
  const [searchCount, setSearchCount] = useState(0);
  const [feesVehicle, setFeesVehicle] = useState<FeesByVehicleList[]>([]);
  const { notfound, setNotFound } = useNotFound();
  const segments = pathname.split("/");
  const segment = segments[segments.length - 1];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const loadFeesVehicle = useCallback(
    async (pageNum: number, sortParam: string, reload?: boolean) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllVehiclePrices?page=${pageNum}&sort=${sortParam}&configId=${state._id}`
        );
        const newFeesByVehicleList: FeesByVehicleList[] = [
          ...res.data.feesVehicle[0].paginatedResults,
        ];
        if (!reload) {
          setSearchCount(res.data.feesVehicle[0].totalCount[0]?.count);
          setFeesVehicle((prevList) => [...prevList, ...newFeesByVehicleList]);
          setNotFound(false);
          setLoading(false);
        } else {
          setFeesVehicle(newFeesByVehicleList);
        }
      } catch (error) {
        setNotFound(true);
        setLoading(false);
        console.log(error);
      }
    },
    [feesVehicle]
  );
  const cloneFees = async () => {
    try {
      const res = await axiosInstance.put(
        `/master/cloneFees?configId=${state.configId}&prevConfigId=${state.prevConfigId}`
      );
      await loadFeesVehicle(1, "area", true);
    } catch (error) {}
  };
  useEffect(() => {
    loadFeesVehicle(page, sort);
  }, [page, sort]);

  window.onscroll = () => {
    infiniteScroll(feesVehicle, setPage, page, searchCount);
  };

  const downloadPDF = () => {
    const doc: any = new jsPDF();

    const headers = [
      { id: "vehicleTyp", name: "Vehicle Type" },
      { id: "licensePlatNo", name: "Vehicle No" },
      { id: "shift", name: "Shift" },
      { id: "area", name: "Area" },
      { id: "division", name: "Division" },
      { id: "amount", name: "Amount" },
    ];

    const data = feesVehicle.map((item: any) => ({
      vehicleTyp: item.vehicleTyp,
      licensePlatNo: item.licensePlatNo,
      shift: item.shift,
      area: item.area,
      division: item.division,
      amount: item.amount,
    }));

    doc.autoTable({
      head: [headers.map((header: any) => header.name)],
      body: data.map((row: any) => headers.map((header) => row[header.id])),
    });

    doc.save("table_data.pdf");
  };

  const downloadPDFPrint = () => {
    const doc: any = new jsPDF();

    const headers = [
      { id: "vehicleTyp", name: "Vehicle Type" },
      { id: "licensePlatNo", name: "Vehicle No" },
      { id: "shift", name: "Shift" },
      { id: "area", name: "Area" },
      { id: "division", name: "Division" },
      { id: "amount", name: "Amount" },
    ];

    const data = feesVehicle.map((item: any) => ({
      vehicleTyp: item.vehicleTyp,
      licensePlatNo: item.licensePlatNo,
      shift: item.shift,
      area: item.area,
      division: item.division,
      amount: item.amount,
    }));

    doc.autoTable({
      head: [headers.map((header: any) => header.name)],
      body: data.map((row: any) => headers.map((header) => row[header.id])),
    });

    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  return (
    <>
      {state.configId === state._id && (
        <div className="d-flex align-items-end justify-content-end my-3">
          {state.isClone && !state.isPaymentMade && (
            <button className="btn btn-primary" onClick={() => setShow(true)}>
              Clone
            </button>
          )}
          <button onClick={downloadPDF} className="btn btn-primary">
            Export to PDF
          </button>
          <button
            onClick={() => {
              exportToExcel(feesVehicle);
            }}
            className="btn btn-primary"
          >
            Export to Excel
          </button>
          <button onClick={downloadPDFPrint} className="btn btn-primary">
            Print
          </button>
          <button
            className="btn btn-primary "
            type="submit"
            onClick={() => {
              const item = state;
              if (segment === "feesSectionEdit") {
                nav(
                  {
                    pathname:
                      "/transportationFeesMain/feesSection/vehicleAddForm",
                    search: "?index=2&fromEdit=1",
                  },
                  { state: item }
                );
              } else {
                nav(
                  {
                    pathname:
                      "/transportationFeesMain/feesSection/vehicleAddForm",
                    search: "?index=2",
                  },
                  { state: item }
                );
              }
            }}
          >
            Add
          </button>
        </div>
      )}
      {state.configId !== state._id && (
        <div className="d-flex align-items-end justify-content-end my-3">
          <button onClick={downloadPDF} className="btn btn-primary mb-4">
            Export to PDF
          </button>
          <button
            onClick={() => {
              exportToExcel(feesVehicle);
            }}
            className="btn btn-primary mb-4"
          >
            Export to Excel
          </button>
          <button onClick={downloadPDFPrint} className="btn btn-primary mb-4">
            Print
          </button>
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            <th
              id="vehicleTyp"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setFeesVehicle,
                  setPage
                );
              }}
            >
              Vehicle Type
              <span className="px-2">
                {sort === "vehicleTyp" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="licensePlatNo"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setFeesVehicle,
                  setPage
                );
              }}
            >
              Vehicle No
              <span className="px-2">
                {sort === "licensePlatNo" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="shift"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setFeesVehicle,
                  setPage
                );
              }}
            >
              Shift
              <span className="px-2">
                {sort === "shift" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="area"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setFeesVehicle,
                  setPage
                );
              }}
            >
              Area
              <span className="px-2">
                {sort === "area" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="division"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setFeesVehicle,
                  setPage
                );
              }}
            >
              Division
              <span className="px-2">
                {sort === "division" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="amount"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setFeesVehicle,
                  setPage
                );
              }}
            >
              Amount
              <span className="px-2">
                {sort === "amount" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            {state.configId === state._id && (
              <th className="text-center">Active</th>
            )}
            {state.configId === state._id && state.isPaymentMade === false && (
              <th className="text-center">Action</th>
            )}
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {feesVehicle?.map((x) => {
              return (
                <FeesByVehicleListItems
                  x={x}
                  key={x._id}
                  state={state}
                  segment={segment}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          Are you sure you want to clone previous year's fees?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={async () => {
              setShow(false);
              cloneFees();
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(FeesVehicleList);
