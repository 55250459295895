import React, { memo, useCallback, useEffect, useState } from "react";
import axiosInstance from "axiosInstance";
import socket from "socket";
import CardComponent from "../Dashboard/CardComponent";
import memberWait from "../../../assets/images/memberWait.png";
type Props = {};

const LoginOrganizers: React.FC<{}> = (props: Props) => {
  const [organization, setOrganization] = useState(0);
  const loadOrganization = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/master/getorganizerscount`);
      setOrganization(res.data.NoOfOrganizersLogins);
    } catch (error) {
      console.log(error);
    }
  }, [organization]);
  useEffect(() => {
    loadOrganization();
    socket.on("admin-count", (data) => {
      if (data === "login-org") {
        loadOrganization();
      }
    });
  }, []);
  return (
    <>
      <CardComponent
        count={organization}
        text="Login Organizers"
        background={memberWait}
      />
    </>
  );
};
export default memo(LoginOrganizers);
