import React from "react";
import { Link } from "react-router-dom";

type Props = {
  search: string;
  setSearch: (val: string) => void;
  handleSearch: () => void;
  addLink?: any;
  placeholder: string;
  state?: any;
  changeName?: string;
};

const AddSearch = ({
  search,
  setSearch,
  handleSearch,
  addLink,
  placeholder,
  state,
}: Props) => {
  return (
    <div>
      <div className="formBg mb-3 ">
        <form
          action=""
          onSubmit={(e: any) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <div className="d-flex">
            <label className="flex-fill">
              <input
                className="form-control  "
                type="text"
                placeholder={placeholder}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </label>
            <div className="">
              <button className="btn btn-primary" type="submit">
                Search
              </button>
              {addLink !== undefined && addLink !== "" && (
                <Link to={addLink} state={state}>
                  <button className="btn btn-primary" type="button">
                    Add
                  </button>
                </Link>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSearch;
