import React, { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

type Props = {};
type ContextType = {
  shiftId: string;
  setShiftId: (shiftId: string) => void;
};

const ShiftTypeMaster = (props: Props) => {
  const [shiftId, setShiftId] = useState("");
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Shift Type</span>
      </div>
      <Outlet context={{ shiftId, setShiftId }} />
    </>
  );
};

export default ShiftTypeMaster;
export function useShiftType() {
  return useOutletContext<ContextType>();
}
