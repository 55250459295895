import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CategoryDataType from "../../../types/shiftType.types";
import RouteMasterType from "../../../types/route.types";
import { VehicleMasterDataType } from "../../../types/vehicleMasterType";
import { DriverDataType } from "../DriverMaster/DriverList";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import toastValidation, { warnToast } from "constants/toastConfig";

type Props = {};

type VehicleNoByModelDataType = {
  vehicleId: string;
  licensePlatNo: string;
};

const RouteVehicleAddForm = (props: Props) => {
  const nav = useNavigate();
  const { state } = useLocation();
  const [param] = useSearchParams();
  const { setLoading } = useLoading();
  const [statusCodeMessage, setstatusCodeMessage] = useState<any>();
  const [shift, setShift] = useState<CategoryDataType[]>([]);
  const [vehicle, setVehicle] = useState<VehicleMasterDataType[]>([]);
  const [vehicleNoByModel, setVehicleNoByModel] = useState<
    VehicleNoByModelDataType[]
  >([]);
  const [driver, setDriver] = useState<DriverDataType[]>([]);
  const [attendant, setAttendant] = useState<DriverDataType[]>([]);

  const [shiftCount, setShiftCount] = useState<boolean>();
  const [shiftId, setShiftId] = useState("");
  const [vehicleId, setVehicleId] = useState("default");
  const [vehicleNoByModelId, setVehicleNoByModelId] = useState("default");
  const [driverId, setDriverId] = useState("default");
  const [vehicleRegId, setVehicleRegId] = useState("");
  const [border, setBorder] = useState<boolean>(false);
  const [attendantId, setAttendantId] = useState("");

  const shiftList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropdown/getUnassignedShiftType?routeId=${state._id}`
      );
      if (res.data.statusCode === 202) {
        setstatusCodeMessage({
          statusCode: res.data.statusCode,
          message: res.data.message,
        });
        // warnToast(res.data.message);
        // nav("../..");
      } else {
        const data = res.data.result;
        const shiftCount = res.data.result.length === 1;
        setShift(data);
        if (shiftCount) {
          setShiftId(data[0]._id);
        }
        setShiftCount(shiftCount);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const vehicleList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropdown/getVehicleType`);
      const data = res.data.vehicleTypes;
      setVehicle(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [shiftId]);

  const vehicleNoByModelList = useCallback(
    async (id: string, shiftId: string) => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `/dropDown/getVehicleNoByShift?modelId=${id}&sId=${shiftId}`
        );
        const vehicleNoByModel = res.data.VehicleNo;
        setVehicleNoByModel(vehicleNoByModel);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [shiftId]
  );

  const driverList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropdown/getDriver?sId=${shiftId}`);
      const data = res.data.drivers;
      setDriver(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [shiftId]);

  const attendantList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropdown/getAttendant?sId=${shiftId}`
      );
      const data = res.data.attendant;
      setAttendant(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [shiftId]);

  useEffect(() => {
    shiftList();
    vehicleList();
    driverList();
    attendantList();
  }, [shiftList, vehicleList, driverList, attendantList]);

  const formik = useFormik({
    initialValues: {
      code: "",
      active: true,
      note: "",
      description: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().max(10, "Code is too long!"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({ code, active }) => {
      try {
        setLoading(true);
        const formData = {
          shiftTypeId: shiftId,
          routeId: state._id,
          vehicleId: vehicleId,
          code: code,
          vehicleNo: vehicleNoByModelId,
          driverId: driverId,
          vehicleRegId: vehicleRegId,
          isActive: active,
          note: "",
          description: "",
          details: "",
          attendantId: attendantId,
        };

        const res = await axiosInstance.post(
          "/master/addRouteVehicle",
          formData
        );

        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          setLoading(false);
          const item = state;
          if (Number(param.get("fromEdit")) !== 1) {
            nav("../routeLocationAdd", { state: item });
          } else {
            nav(
              {
                pathname: "/routeMaster/editForm",
                search: "?index=1",
              },
              { state: item }
            );
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      setLoading(false);
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="shift">
              <span className="top-placeholder">Shift</span>
              <select
                className={`form-control ${
                  shiftId === "" && border ? "empty-form" : ""
                }`}
                id="shift"
                name="shift"
                onChange={(e) => {
                  setVehicleNoByModel([]);
                  setVehicle([]);
                  setShiftId(e.target.value);
                }}
              >
                {!shiftCount && <option value="">Select Shift</option>}
                {shift?.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="vehicle">
              <span className="top-placeholder">Vehicle Type*</span>

              <select
                className={`form-control ${
                  vehicleId === "default" && border ? "empty-form" : ""
                }`}
                id="vehicle"
                onChange={(e) => {
                  setVehicleId(e.target.value);
                  setVehicleNoByModel([]);
                  vehicleNoByModelList(e.target.value, shiftId);
                }}
              >
                <option value="default">Select Vehicle Type</option>
                {vehicle?.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="vehicleNoByModel">
              <span className="top-placeholder">Vehicle Number*</span>

              <select
                className={`form-control ${
                  vehicleNoByModelId === "default" && border ? "empty-form" : ""
                }`}
                onChange={(e) => {
                  setVehicleNoByModelId(e.target.value);
                  setVehicleRegId(e.target[e.target.selectedIndex].id);
                }}
              >
                <option value="default">Select Vehicle No</option>
                {vehicleNoByModel?.map(({ licensePlatNo, vehicleId }) => {
                  return (
                    <option
                      key={licensePlatNo}
                      value={licensePlatNo}
                      id={vehicleId}
                    >
                      {licensePlatNo}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="driver">
              <span className="top-placeholder">Driver*</span>

              <select
                className={`form-control ${
                  driverId === "default" && border ? "empty-form" : ""
                }`}
                id="driver"
                onChange={(e) => {
                  setDriverId(e.target.value);
                }}
              >
                <option value="default">Select Driver</option>
                {driver?.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="driver">
              <span className="top-placeholder">Attendant</span>

              <select
                className={`form-control ${
                  attendantId === "default" && border ? "empty-form" : ""
                }`}
                id="driver"
                onChange={(e) => {
                  setAttendantId(e.target.value);
                }}
              >
                <option value="">Select Attendant</option>
                {attendant?.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                setBorder(true);
                if (shiftId === "") warnToast("Select Shift");
                if (vehicleId === "default") warnToast("Select Vehicle Type");
                if (vehicleNoByModelId === "default")
                  warnToast("Select Vehicle No");
                if (driverId === "default") warnToast("Select Driver");
                // if (attendantId === "default") warnToast("Select Attendant");
                toastValidation(formik.errors);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                if (Number(param.get("fromEdit")) !== 1) {
                  nav("../..");
                } else {
                  const item = state;
                  nav(
                    {
                      pathname: "/routeMaster/editForm",
                      search: "?index=1",
                    },
                    { state: item }
                  );
                }
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(RouteVehicleAddForm);
