import axiosInstance from "axiosInstance";
import * as Yup from "yup";
import React, { useCallback, useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { RFDICardDataType } from "types/rfdiCardTypes";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { formatDate } from "constants/Constants";
import { Button, Modal } from "react-bootstrap";

type Props = {
  update?: any;
  memAssign?: any;
};

const CardMemberMappingAddForm = ({ update }: Props): JSX.Element => {
  const [isAddForm, setIsAddForm] = useState(false);
  const nav = useNavigate();
  const { setLoading } = useLoading();
  const location = useLocation();

  const state = update ?? location.state;
  const [card, setCard] = useState<RFDICardDataType[]>([]);
  const [cardId, setCardId] = useState("default");
  const [cardMember, setCardMember] = useState<any>(undefined);
  const [show, setShow] = useState(false);
  const [border, setBorder] = useState<boolean>(false);
  const handleClose = () => setShow(false);

  const RFDICardList = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/dropDown/getCard`);
      const rList = res.data.Card;
      setCard(rList);
      return rList;
    } catch (error) {
      console.log(error);
    }
  }, []);
  const loadCardMember = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/master/getOneByMem/${state?._id}`);
      setCardMember(res.data.result);
    } catch (error) {
      setIsAddForm(true);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    RFDICardList();

    if (state && !isAddForm) {
      loadCardMember();
    }
  }, [RFDICardList, isAddForm]);

  const deleteCardMember = async (id: string) => {
    try {
      const formData = {
        isActive: false,
        isDeleted: true,
      };
      axiosInstance
        .put(`/master/getCardMemberDelete/${id}`, formData)
        .then(() => {
          RFDICardList();
          loadCardMember();
          setIsAddForm(true);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      active: true,
    },

    validationSchema: Yup.object().shape({
      isActive: Yup.boolean().default(true),
    }),

    onSubmit: async ({ active }) => {
      try {
        if (cardId === "default") {
          return;
        }

        setLoading(true);
        if (!isAddForm && state) {
          const formData = {
            cardId: cardId,
          };
          const res = await axiosInstance.put(
            `/master/getCardMemberDelete/${cardMember._id}`,
            formData
          );
          if (res.data.message === "Ok") {
            successToast();
            loadCardMember();
            RFDICardList();
            // if (!update) {
            //   nav("../..");
            // } else {
            //   loadCardMember();
            //   RFDICardList();
            // }
          }
        } else {
          const formData = {
            cardId: cardId,
            departmentId: state?.departmentId,
            categoryId: state?.categoryId,
            typeId: state?.typeId,
            memberId: state?._id,
            isActive: active,
          };
          const res = await axiosInstance.post(
            "/master/addCardMember",
            formData
          );

          const responseMessage = res.data.message;
          if (responseMessage === "Ok") {
            const list: RFDICardDataType[] = await RFDICardList();
            setCard(list);
            successToast();
            setIsAddForm(false);

            loadCardMember();
            if (location.pathname === "/cardAdd") nav("/memberMaster");
          }
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  console.log(isAddForm);
  return (
    <>
      <div className="formBg">
        <form
          className="row align-items-center "
          onSubmit={formik.handleSubmit}
        >
          <ul className="profileList">
            <li>
              <span>Member :</span>
              {state?.name}
            </li>
            <li>
              <span>Member ID :</span>
              {state?.memberId}
            </li>
            {!isAddForm && state && (
              <>
                <li>
                  <span>Card number :</span>
                  {cardMember?.cardId?.cardNumber}
                </li>

                <li>
                  <span>Expiry date :</span>
                  {formatDate(cardMember?.cardId?.cardExpiredDate)}
                </li>
              </>
            )}
          </ul>
          <label className="col-md-6 mb-3" htmlFor="cardId">
            {cardId !== "default" && (
              <span className="top-placeholder">Card</span>
            )}
            <select
              className={`form-control ${
                cardId === "default" && border ? "empty-form" : ""
              }`}
              id="cardId"
              onChange={(e) => {
                setCardId(e.target.value);
              }}
            >
              <option value="default" selected>
                Select Card
              </option>
              {card?.map(({ _id, cardNumber }) => {
                return (
                  <option key={_id} value={_id}>
                    {cardNumber}
                  </option>
                );
              })}
            </select>
          </label>
          {!isAddForm && state && (
            <div className="col-md-3 mb-3">
              <button
                className="badge"
                style={{
                  backgroundColor: "#3a8396",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
                onClick={() => {
                  setShow(true);
                }}
              >
                Unasssign
              </button>
            </div>
          )}

          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              type="submit"
              errors={formik.errors}
              clickFunc={() => {
                if (cardId === "default") {
                  warnToast("Select Card");
                  setBorder(true);
                }
                // if (update && cardMember?._id) {
                //   setShow(true);
                // }
                toastValidation(formik.errors);
              }}
            />
            <CancelBtn path={"/memberMaster"} />
          </div>
        </form>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            Past entries of this member card will be deleted.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                setShow(false);
                deleteCardMember(cardMember._id);
              }}
            >
              Submit
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CardMemberMappingAddForm;
