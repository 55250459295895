import { memo, useCallback, useEffect, useState } from "react";
import RenewPlanRequestsListItem from "./ListItem";
import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import { useLoading } from "Context/LoadingContext";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import AddSearch from "components/AddSearchComponent";
import { ReqForRenewPlan } from "types/renewPlanTypes";
type Props = {};

const RenewPlanRequestList = (props: Props): JSX.Element => {
  const [requests, setRequests] = useState<ReqForRenewPlan[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("-createdAt");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const LoadRequests = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `admin/getAllRenewPlan?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newReq = res.data.response[0].paginatedResults;
        if (searchKeyword !== "" && pageNum === 1) {
          if (newReq.length === 0) {
            setRequests([]);
            setSearchCount(0);
          }
        }
        if (newReq.length > 0) {
          setSearchCount(res.data.response[0].totalCount[0].count);
          if (pageNum === 1) {
            setRequests(newReq);
          } else {
            setRequests((prevPln) => [...prevPln, ...newReq]);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [requests]
  );
  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    LoadRequests(1, sort, search); // Trigger API call with updated search keyword
  };

  useEffect(() => {
    LoadRequests(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(requests, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Name or Plan Name"
      />
      <table className="table">
        <thead>
          <tr>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Requested Date
              <span className="px-2">
                {sort === "createdAt" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="organizerName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Name
              <span className="px-2">
                {sort === "organizerName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="planType"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Plan Type
              <span className="px-2">
                {sort === "planType" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="planName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Plan Name
              <span className="px-2">
                {sort === "planName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th id="Payment">Amount</th>
            <th id="Payment">Payment</th>
            <th id="Payment">Multi Shift</th>
            <th id="Payment">RFID Card</th>
            <th id="Payment">Verified</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {requests?.map((item: ReqForRenewPlan) => {
            return <RenewPlanRequestsListItem item={item} key={item._id} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(RenewPlanRequestList);
