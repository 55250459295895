import { memo, useCallback, useEffect, useState } from "react";
import ListItem from "./listItem";
import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { useConfig } from "Context/ConfigContext";
import { basicLocationMaster } from "types/basicLocationMasterTypes";
import { useNotFound } from "Context/NotFound";
import NotFound from "components/NotFound";

type Props = {};
const BasicLocationList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("order");
  const [searchCount, setSearchCount] = useState(0);
  const [listData, setListData] = useState<basicLocationMaster[]>([]);
  const { notfound, setNotFound } = useNotFound();

  const LoadData = useCallback(
    async (pageNum: number, sortParam: string) => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `/master/getAllBasicLocationMaster?page=${pageNum}&sort=${sortParam}`
        );
        const vehicleList: any[] = [...res.data.result];
        if (vehicleList.length === 0) {
          setNotFound(true);
          setListData([]);
          setSearchCount(0);
        }
        if (vehicleList.length > 0) {
          setSearchCount(res.data.count);
          if (pageNum === 1) {
            setListData(vehicleList);
          } else {
            setListData((prevList) => [...prevList, ...vehicleList]);
          }
          setNotFound(false);
        }
        setLoading(false);

        // setSearchCount(res.data.count);
        // const data: any[] = [...listData, ...res.data.result];
        // setListData(data);
        // setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [listData]
  );

  useEffect(() => {
    LoadData(page, sort);
  }, [page, sort, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(listData, setPage, page, searchCount);
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Location Name
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="address"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Address
              <span className="px-2">
                {sort === "address" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="order"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Order
              <span className="px-2">
                {sort === "order" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="pickUpTime"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Pickup Time
              <span className="px-2">
                {sort === "pickUpTime" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="dropTime"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Drop Time
              <span className="px-2">
                {sort === "dropTime" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              className="text-center"
              id="isActive"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Active
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {listData?.map((item: basicLocationMaster) => {
              return (
                <ListItem item={item} key={item._id} LoadData={LoadData} />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(BasicLocationList);
