import React, { useState, memo } from "react";
import { useFormik } from "formik";
import axiosInstance from "axiosInstance";
import ErrorToast from "components/ValidationToast/errorToast";
import InfoToast from "components/ValidationToast/infoToast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
type Props = {};

const ClientEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [isValid, setIsValid] = useState("");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isActive: state.isActive,
      isVerified: state.isVerified,
      isDeleted: state.isDeleted,
      amount: state.paymentAmount,
      startDate: state.planStart,
      endDate: state.planExpired,
    },
    onSubmit: async ({
      isActive,
      isDeleted,
      isVerified,
      amount,
      startDate,
      endDate,
    }) => {
      setLoading(true);
      try {
        const formData = {
          isActive,
          isDeleted,
          isVerified,
        };
        const updateData = {
          amount,
          startDate,
          endDate,
        };
        const res = await axiosInstance.put(
          `/master/updateOrganizationById/${state.organizationId}`,
          formData
        );
        const res2 = await axiosInstance.put(
          `/admin/updateOrgPayment/${state.organizationPaymentId}`,
          updateData
        );
        setIsValid("submitted");
        if (res.data === "Ok" && res2.data === "Ok") {
          nav("/adminClient");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Client Edit Form</span>
      </div>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <ul className="profileList">
              <li>
                <span>Client :</span>
                {state.name}
              </li>
              <li>
                <span>Email :</span>
                {state.email}
              </li>
              <li>
                <span>Contact Number:</span>
                {state.contactNo}
              </li>
            </ul>
          </div>
          <div className=" row align-items-center mb-5 ">
            <label className="col-md-6 mb-3 px-2" htmlFor="startDate">
              <span className="top-placeholder">Start Date*</span>
              <input
                className={`form-control ${
                  formik.errors.startDate && formik.submitCount > 1
                    ? "empty-form"
                    : ""
                }`}
                id="startDate"
                name="startDate"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.startDate}
                placeholder="StartDate"
                onFocus={(e) => (e.target.type = "date")}
              />
            </label>
            <label className="col-md-6 mb-3 px-2" htmlFor="endDate">
              <span className="top-placeholder">End Date*</span>
              <input
                className={`form-control ${
                  formik.errors.endDate && formik.submitCount > 1
                    ? "empty-form"
                    : ""
                }`}
                id="endDate"
                name="endDate"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.endDate}
                placeholder="EndDate"
                onFocus={(e) => (e.target.type = "date")}
              />
            </label>
            <label className="col-md-6 mb-3 px-2" htmlFor="amount">
              <span className="top-placeholder">Amount*</span>
              <input
                className={`form-control ${
                  formik.values.amount === "" ? "empty-form" : ""
                }`}
                id="amount"
                name="amount"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.amount}
                placeholder="Amount"
              />
            </label>
            <div>
              <div className="col-md-3 mb-3">
                <label className="custCheck" htmlFor="isActive">
                  isActive
                  <input
                    type="checkbox"
                    id="isActive"
                    name="isActive"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={formik.values.isActive}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="col-md-3 mb-3">
                <label className="custCheck" htmlFor="isVerified">
                  isVerified
                  <input
                    type="checkbox"
                    id="isVerified"
                    name="isVerified"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={formik.values.isVerified}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="col-md-3 mb-3">
                <label className="custCheck" htmlFor="isDeleted">
                  isDeleted
                  <input
                    type="checkbox"
                    id="isDeleted"
                    name="isDeleted"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={formik.values.isDeleted}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          {isValid === "amount" && <ErrorToast error="Enter Amount!!" />}
          <div className="col-md-12 mb-3 text-center">
            <button type="submit" className="btn btn-primary inline-block">
              Submit
            </button>
            <Link to={".."}>
              <button className="btn btn-primary" type="submit">
                Cancel
              </button>
            </Link>
          </div>
          {isValid === "submitted" && (
            <InfoToast infoMsg="Response Submitted" />
          )}
        </form>
      </div>
    </>
  );
};

export default memo(ClientEditForm);
