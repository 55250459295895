/* eslint-disable array-callback-return */
import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { VehicleMasterDataType } from "types/vehicleMasterType";
import { VehiclePucMasterDataType } from "types/vehiclePucTypes";
import { useLoading } from "Context/LoadingContext";
import { formatDate, formatDateToAdd, orgDoc } from "constants/Constants";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";

type Props = {};
type VehicleNoByModelDataType = {
  vehicleId: string;
  licensePlatNo: string;
};

const VehiclePucMasterEditForm = (props: Props) => {
  const nav = useNavigate();
  const { state } = useLocation();
  const [vehiclePuc, setVehiclePuc] = useState<VehiclePucMasterDataType>();
  const { setLoading } = useLoading();

  const dropdown = useCallback(async () => {
    try {
      setLoading(true);
      const getOneVehiclePuc = await axiosInstance.get(
        `/master/getSingleVehiclePuc/${state.pucId}`
      );
      setVehiclePuc(getOneVehiclePuc?.data?.vehicles);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    dropdown();
  }, [dropdown]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: vehiclePuc?.name,
      pucDate: formatDateToAdd(vehiclePuc?.pucDate),
      validUptoDate: formatDateToAdd(vehiclePuc?.validUptoDate),
      pucDoc: vehiclePuc?.document,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1)
        .max(30)
        .required("PUC Company Name is Required"),
      pucDate: Yup.string().required("PUC Date is Required"),
      validUptoDate: Yup.string().required("Valid Upto Date is Required"),
      pucDoc: Yup.mixed()
        .test("fileSize", "PUC Document size is too high", (value) => {
          if (typeof value === "string") return true; // Skip the test for default file string
          if (!value) return true; // Skip the test if no file is selected
          return value.size <= 209715200; // 200MB in bytes
        })
        .test(
          "fileType",
          "Please enter a PUC Document with jpg, jpeg, png, pdf, or docx extension",
          (value) => {
            if (typeof value === "string") return true; // Skip the test for default file string
            if (!value) return true; // Skip the test if no file is selected
            return /\.(jpg|jpeg|png|pdf|docx?)$/i.test(value.name);
          }
        ),
    }),
    onSubmit: async ({ name, pucDate, validUptoDate, pucDoc }) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name || "");
        formData.append(
          "pucDate",
          formatDate(vehiclePuc?.pucDate) === formik.values.pucDate
            ? formatDateToAdd(vehiclePuc?.pucDate)
            : pucDate
        );
        formData.append(
          "validUptoDate",
          formatDate(vehiclePuc?.validUptoDate) === formik.values.validUptoDate
            ? formatDateToAdd(vehiclePuc?.validUptoDate)
            : validUptoDate
        );
        formData.append("pucDoc", pucDoc || "");
        formData.append("description", "");
        formData.append("note", "");
        formData.append("details", "");
        const res = await axiosInstance.put(
          `/master/updateVehiclePuc/${state.pucId}`,
          formData
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          const item = state;
          nav(
            {
              pathname: "/vehicleRegistration/editForm",
              search: "?index=1",
            },
            { state: item }
          );
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form
          className="row align-items-center "
          onSubmit={formik.handleSubmit}
        >
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name ? "empty-form" : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Company Name"
              />

              <span className="top-placeholder">Company Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="pucDate">
              <input
                className={`form-control session-date ${
                  formik.errors.pucDate ? "empty-form" : ""
                }`}
                id="pucDate"
                name="pucDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.pucDate}
                // onFocus={(e) => (e.target.type = "date")}
                placeholder="PUC Date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />

              <span className="top-placeholder">PUC Date*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="validUptoDate">
              <input
                className={`form-control session-date ${
                  formik.errors.validUptoDate ? "empty-form" : ""
                }`}
                id="validUptoDate"
                name="validUptoDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.validUptoDate}
                // onFocus={(e) => (e.target.type = "date")}
                placeholder="Valid Upto Date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />

              <span className="top-placeholder">Valid Upto Date*</span>
            </label>
            {(vehiclePuc?.document as string) !== "" && (
              <div className="col-md-1 text-center">
                <a
                  href={`${orgDoc}${vehiclePuc?.document}`}
                  download={`${orgDoc}${vehiclePuc?.document}`}
                  rel="noopener noreferrer"
                >
                  PUC
                </a>
              </div>
            )}
            <label
              className={`${
                vehiclePuc?.document !== "" ? "col-md-5" : "col-md-6"
              } mb-3`}
              htmlFor="pucDoc"
            >
              <input
                className="form-control pucDoc form-control-lg"
                id="pucDoc"
                name="pucDoc"
                type="file"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const file =
                    event.currentTarget.files && event.currentTarget.files[0];
                  formik.setFieldValue("pucDoc", file || "");
                }}
                placeholder="Upload PUC Document"
              />
            </label>
          </div>
          <div className="text-center col-md-12 mb-3">
            <SubmitBtn errors={formik.errors} />
            <button
              className="btn btn-primary"
              onClick={() => {
                const item = state;
                nav(
                  {
                    pathname: "/vehicleRegistration/editForm",
                    search: "?index=1",
                  },
                  { state: item }
                );
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(VehiclePucMasterEditForm);
