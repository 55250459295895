import React from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import VehicleRegType from "types/VehicleRegTypes";
import { VehicleMasterDataType } from "types/vehicleMasterType";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = { item: VehicleRegType; vehicles: VehicleMasterDataType[] };

const VehicleRegListItems = ({ item, vehicles }: Props) => {
  const nav = useNavigate();

  const handleEditClick = () => {
    nav(
      {
        pathname: "/vehicleRegistration/editForm",
        search: `?index=0`,
      },
      {
        state: item,
      }
    );
  };

  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;

  return (
    <tr key={item._id}>
      <td>{item.result.name}</td>
      <td>{item.licensePlatNo}</td>
      <td>{item.totalSeats}</td>
      <td>{item.ownerName}</td>
      <td>{item.contactNo}</td>
      <td>
        {item.seatNoRequired !== undefined
          ? item.seatNoRequired
            ? "Yes"
            : "No"
          : "Yes"}
      </td>
      <td className="text-center">{item.isActive ? <Eye /> : <EyeOff />}</td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          {/* <Edit
          onClick={() => {
            nav(
              {
                pathname: "/vehicleRegistration/editForm",
                search: `?index=0`,
              },
              {
                state: item,
              }
            );
          }}
        /> */}
          <span
            id={item._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default VehicleRegListItems;
