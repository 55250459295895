import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";

import { DepartmentMasterDataType } from "../../../types/departmentMasterType";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
type Props = {};

export type BasicVehicleMaster = {
  _id: string;
  organizerId: string;
  VehicleType: string;
  LPNumber: string;
  isActive: boolean;
  isDelete: boolean;
};
const BasicVehicleEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [vehicle, setVehicle] = useState<BasicVehicleMaster>();
  console.log(state);
  const getOneBasicVehicle = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getOneBasicVehicleMaster/${state._id}`
      );
      setVehicle(res.data.BasicVehicle);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [state._id]);

  useEffect(() => {
    getOneBasicVehicle();
  }, [getOneBasicVehicle]);

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      VehicleType: vehicle?.VehicleType,
      LPNumber: vehicle?.LPNumber,

      active: vehicle?.isActive,
    },
    validationSchema: Yup.object().shape({
      VehicleType: Yup.string()
        .min(1, "Vehicle Type Name is too short!")
        .max(30, "Vehicle Type Name is too long!")
        .required("Vehicle Type Name is required"),
      LPNumber: Yup.string()
        .matches(
          /^[A-Z]{2}-\d{1,2}-[A-Z]{1,2}-\d{1,4}$/,
          "Please Enter a Valid License Plate Number"
        )
        .min(1, "License Plate Number is Too Short")
        .max(15, "License Plate Number is Too Long")
        .required("License Plate Number is Required"),
    }),

    onSubmit: async ({ VehicleType, LPNumber, active }) => {
      setLoading(true);
      try {
        const formData = {
          VehicleType: VehicleType,
          LPNumber: LPNumber,

          isActive: active,
        };
        const res = await axiosInstance.put(
          `/master/updateBasicVehicleMaster/${state._id}`,
          formData
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          setTimeout(() => {
            setLoading(false);
            nav("..");
          }, 1500);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="VehicleType">
              <input
                className={`form-control ${
                  formik.errors.VehicleType && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="VehicleType"
                name="VehicleType"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.VehicleType}
                placeholder="Vehicle Type Name"
              />

              <span className="top-placeholder">Vehicle Type*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="LPNumber">
              <input
                className={`form-control ${
                  formik.errors.LPNumber && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="LPNumber"
                name="LPNumber"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.LPNumber}
                placeholder="License Plate Number (eg.GJ-01-AA-5050)"
              />

              <span className="top-placeholder">LP Number*</span>
            </label>
            <div className="col-md-3 mb-3">
              <label className="custCheck" htmlFor="active">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            <CancelBtn path="/basicvehicle " />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(BasicVehicleEditForm);
