import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect } from "react";
import VehicleInsuranceDataType from "types/vehicleInsuranceDataTypes";
import VehicleInsuranceListItems from "./VehicleInsuranceMasterListItems";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNavigate } from "react-router-dom";
import { useNotFound } from "Context/NotFound";

type Props = {
  state: any;
};

const VehicleInsuranceMasterList = ({ state }: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [vehicleInsurance, setVehicleInsurance] = useState<
    VehicleInsuranceDataType[]
  >([]);
  const { notfound, setNotFound } = useNotFound();

  const loadVehicleInsurances = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllVehicleInsurance?page=${pageNum}&sort=${sortParam}&vehicleRegId=${state._id}`
        );
        setSearchCount(res.data.SearchCount);
        const vehicleInsurances: VehicleInsuranceDataType[] = [
          ...vehicleInsurance,
          ...res.data.insurance,
        ];

        setVehicleInsurance(vehicleInsurances);
        setNotFound(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
      }
      setLoading(false);
    },
    []
  );

  useEffect(() => {
    loadVehicleInsurances(page, sort);
  }, [page, sort, ascDsc]);
  window.onscroll = () => {
    infiniteScroll(vehicleInsurance, setPage, page, searchCount);
  };

  return (
    <>
      <div className="d-flex align-items-end justify-content-end my-3">
        <button
          className="btn btn-primary "
          type="submit"
          onClick={() => {
            const item = state;
            nav(
              {
                pathname: "/vehicleRegistration/addForm/incAdd",
                search: "?fromEdit=1",
              },
              {
                state: item,
              }
            );
          }}
        >
          Add
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setVehicleInsurance,
                  setPage
                );
              }}
            >
              Insurance Name
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="vehicleNo"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setVehicleInsurance,
                  setPage
                );
              }}
            >
              Vehicel No
              <span className="px-2">
                {sort === "vehicleNo" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="insuranceNo"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setVehicleInsurance,
                  setPage
                );
              }}
            >
              Insurance No
              <span className="px-2">
                {sort === "insuranceNo" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="validUptoDate"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setVehicleInsurance,
                  setPage
                );
              }}
            >
              Valid Date
              <span className="px-2">
                {sort === "validUptoDate" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {vehicleInsurance?.map((item) => {
              return (
                <VehicleInsuranceListItems
                  x={item}
                  key={item._id}
                  state={state}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default VehicleInsuranceMasterList;
