import { memo, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
const containerStyle = {
  width: "100%",
  height: "100%",
};
type Props = {};

const ConfigAddForm = (props: Props) => {
  const { setLoading } = useLoading();
  // const center: google.maps.LatLng | google.maps.LatLngLiteral | undefined = {
  //   lat: 23.0204737,
  //   lng: 72.4145878,
  // };
  // const MY_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  // const [coordinates, setCoordinates] = useState<
  //   google.maps.LatLng | google.maps.LatLngLiteral
  // >(center);

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: `${MY_API_KEY}`,
  // });
  const nav = useNavigate();
  const [locId, setLocId] = useState("default");
  const [border, setBorder] = useState<boolean>(false);
  const [loc, setLoc] = useState<any[]>([]);

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/master/getAllBasicLocationDDL`);
      const department = res.data.location;
      setLoc(department);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadDepartments();
  }, [loadDepartments]);

  const formik = useFormik({
    validateOnMount: true,

    initialValues: {
      memberName: "",
      contactNo: "",
      memberFees: "",
      address: "",
      description: "",
      note: "",
      isActive: true,
      photo: "",
    },
    validationSchema: Yup.object().shape({
      memberName: Yup.string()
        .min(1, "Member Name is too short!")
        .max(30, "Member Name is too long!")
        .required("Member Name is required"),
      contactNo: Yup.string()
        .min(10, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!")
        .required("Contact Number is Required"),
      address: Yup.string()
        .min(1, "Address is too short!")
        .max(300, "Address is too long!")
        .required("Address is required"),
      // latitude: Yup.string().required("Latitude is required"),
      // longitude: Yup.string().required("Longitude is required"),
      // pickUpTime: Yup.string().required("Pickup Time is required"),
      // dropTime: Yup.string().required("Drop Time is required"),
      // memberFees: Yup.string().required(" Member Fees is required"),
    }),
    onSubmit: async (values) => {
      const {
        memberName,
        contactNo,
        address,
        // pickupLocation,
        // pickUpTime,
        // latitude,
        // longitude,
        // dropTime,
        description,
        isActive,
        memberFees,
        photo,
      } = values;
      // if (paymentCheck && (memberFees === "" || memberFees === undefined))
      //   return;
      setLoading(true);
      try {
        const formData = {
          locationId: locId,
          address,
          memberName,
          memberFees,
          contactNo: String(contactNo),
          // pickupLocation,
          // latitude,
          // longitude,
          // pickUpTime,
          // dropTime,
          description,
          isActive,
          note: "",
          photo: "",
        };
        const res = await axiosInstance.post(
          "/master/addOrgBasicSetup",
          formData
        );
        if (res.status === 202) {
          warnToast(res.data);
          setLoading(false);
          return;
        }
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("..");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
  });
  // const handleSearch = useCallback(
  //   async (loc: string) => {
  //     try {
  //       const response = await fetch(
  //         `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
  //           loc
  //         )}&key=${MY_API_KEY}`
  //       );
  //       const data = await response.json();
  //       if (data.results && data.results.length > 0) {
  //         const { lat, lng } = data.results[0].geometry.location;
  //         formik.setFieldValue("latitude", lat.toString());
  //         formik.setFieldValue("longitude", lng.toString());
  //         setCoordinates({ lat, lng });
  //       }
  //     } catch (error) {
  //       console.error("Error geocoding location:", error);
  //     }
  //   },
  //   [formik.values.pickupLocation]
  // );

  // useEffect(() => {
  //   handleSearch(formik.values.pickupLocation);
  // }, [formik.values.pickupLocation]);

  // const handleMarkerDragEnd = async (e: any) => {
  //   const lat = e.latLng.lat();
  //   const lng = e.latLng.lng();
  //   formik.setFieldValue("latitude", lat.toString());
  //   formik.setFieldValue("longitude", lng.toString());
  //   setCoordinates({ lat, lng });
  //   try {
  //     const response = await fetch(
  //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MY_API_KEY}`
  //     );
  //     const data = await response.json();
  //     if (data.results && data.results.length > 0) {
  //       const address = data.results[0].formatted_address;
  //       formik.setFieldValue("pickupLocation", address);
  //     }
  //   } catch (error) {
  //     console.error("Error reverse geocoding:", error);
  //   }
  // };

  // if (loadError) {
  //   return <div>Error loading Google Maps</div>;
  // }
  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="memberName">
              <input
                className={`form-control ${
                  formik.errors.memberName && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="memberName"
                name="memberName"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.memberName}
                placeholder="Member Name"
              />
              <span className="top-placeholder">Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="contactNo">
              <input
                className={`form-control ${
                  formik.errors.contactNo && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="contactNo"
                name="contactNo"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contactNo}
                placeholder="Contact Number"
              />
              <span className="top-placeholder">Contact*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="address">
              <input
                className={`form-control ${
                  formik.errors.address && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="address"
                name="address"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address}
                placeholder="Address"
              />
              <span className="top-placeholder">Address*</span>
            </label>
            <label htmlFor="location" className="col-md-6 mb-3">
              <span className="top-placeholder">Location*</span>

              <select
                className={`form-control ${
                  locId === "default" && border ? "empty-form" : ""
                }`}
                id="location"
                name="location"
                onChange={(e) => {
                  setLocId(e.target.value);
                }}
              >
                <option value="default">Select Location</option>
                {loc?.map(({ _id, locationName }) => {
                  return (
                    <option key={_id} value={_id}>
                      {locationName}
                    </option>
                  );
                })}
              </select>
            </label>
            {/* <label className="col-md-6 mb-3" htmlFor="pickupLocation">
              <input
                className={`form-control ${
                  formik.errors.pickupLocation && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="pickupLocation"
                name="pickupLocation"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.pickupLocation}
                placeholder="Pickup Location"
              />
              <span className="top-placeholder">Location*</span>
            </label>
            <label className="col-md-3 mb-3" htmlFor="pickUpTime">
              <input
                className={`form-control session-time ${
                  formik.errors.pickUpTime && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="pickUpTime"
                name="pickUpTime"
                type="time"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.pickUpTime}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <span className="top-placeholder">Pickup Time*</span>
            </label>
            <label className="col-md-3 mb-3" htmlFor="dropTime">
              <input
                className={`form-control session-time ${
                  formik.errors.dropTime && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="dropTime"
                name="dropTime"
                type="time"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.dropTime}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <span className="top-placeholder">Drop Time*</span>
            </label> */}
            <div className="col-md-3 mb-3">
              <label className="custCheck" htmlFor="isActive">
                Active
                <input
                  type="checkbox"
                  id="isActive"
                  name="isActive"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.isActive}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          {/* {isLoaded ? (
            <div
              style={{ width: "100%", height: "80vh", marginBottom: "20px" }}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={coordinates}
                zoom={10}
              >
                {formik.values.longitude !== "" &&
                  formik.values.latitude !== "" && (
                    <Marker
                      position={coordinates}
                      draggable={true}
                      onDragEnd={handleMarkerDragEnd}
                    />
                  )}
              </GoogleMap>
            </div>
          ) : (
            <div>Loading....</div>
          )} */}
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                setBorder(true);
                toastValidation(formik.errors);
                if (locId === "default") warnToast("Select Location");
              }}
            />
            <CancelBtn path=".." />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(ConfigAddForm);
