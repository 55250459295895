import React, { memo, useState } from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import {
  descSlice,
  formatDate,
  memImage,
  drvImage,
  formatMongoDBDateToTime,
} from "constants/Constants";
import axiosInstance from "axiosInstance";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoading } from "Context/LoadingContext";
import { successToast, warnToast } from "constants/toastConfig";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export type ComplaintsType = {
  _id: string;
  organizerId: string;
  driverPhoto: string;
  memberPhoto: string;
  // driverId: string;
  // memberId: string;
  // vehicleId: string;
  complaintMessage: string;
  complaintAnswer: string;
  complaintDate: string;
  complaintStatus: string;
  memberName: string;
  driverName: string;
  createdBy: string;
  updatedBy: string;
  isClear: boolean;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: any;
};

type Props = {
  item: ComplaintsType;
};

const ComplaintsListItem = ({ item }: Props) => {
  const [show, setShow] = useState(false);
  const { setLoading } = useLoading();

  const handleClose = () => setShow(false);

  const { handleBlur, handleChange, handleSubmit, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      isActive: item?.isActive,
      complaintAnswer: item?.complaintAnswer,
    },
    validationSchema: Yup.object().shape({
      complaintAnswer: Yup.string(),
    }),
    onSubmit: async ({ complaintAnswer }) => {
      try {
        if (complaintAnswer === undefined || complaintAnswer === "") {
          warnToast("Please provide an answer!");
          return;
        } else {
          setLoading(true);
          const formData = {
            complaintAnswer,
            complaintStatus: 1,
          };
          const res = await axiosInstance.put(
            `/master/updateDriverComplaint/${item._id}`,
            formData
          );
          if (res.data?.message === "Ok") {
            successToast();
            setShow(false);
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
  });

  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Replay</Tooltip>;
  let memberImage = item.memberPhoto
    ? `${memImage}${item.memberPhoto}`
    : `${memImage}Default.png`;

  let driverImage = item.driverPhoto
    ? `${drvImage}${item.driverPhoto}`
    : `${drvImage}Default.png`;
  return (
    <>
      <tr key={item._id}>
        <td>
          <i className="userImg">
            <img src={memberImage} alt="MemberPic" />
          </i>
        </td>
        <td>{item.memberName}</td>
        <td>
          <i className="userImg">
            <img src={driverImage} alt="DriverPic" />
          </i>
        </td>

        <td>{item.driverName}</td>
        <td>{`${formatDate(item.createdAt)} | ${formatMongoDBDateToTime(
          item?.createdAt
        )}`}</td>
        <td>{descSlice(item.complaintMessage || "")}</td>
        <td>{descSlice(item.complaintAnswer || "")}</td>
        <td>{item.complaintStatus}</td>
        {/* <td className="text-center">
          {item.isActive === true ? <Eye /> : <EyeOff />}
        </td> */}
        <td className="text-center">
          <OverlayTrigger placement="right" overlay={tooltip}>
            <Edit
              style={{ cursor: "pointer" }}
              id={item._id}
              onClick={() => {
                setShow(true);
              }}
            />
          </OverlayTrigger>
        </td>
      </tr>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>Reply to the complaint</Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <p>
              <strong>Message :</strong> {item.complaintMessage}
            </p>

            <textarea
              className="form-control"
              id="complaintAnswer"
              name="complaintAnswer"
              placeholder="Answer complaint"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.complaintAnswer}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Submit
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default memo(ComplaintsListItem);
