import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const VehicleMasterForm = (props: Props) => {
  const location = useLocation();
  const nav = useNavigate();
  const { setLoading } = useLoading();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      code: "",
      description: "",
      active: true,
      note: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Vehicle Name is too Short!")
        .max(30, "Vehicle Name is too Long!")
        .required("Vehicle Name is Required"),
      code: Yup.string().max(10, "Vehicle Code is too long"),
      description: Yup.string().max(300, "Vehicle Description is too long"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({ name, code, description, active }) => {
      try {
        setLoading(true);
        const formData = {
          name: name,
          code: code,
          description: description,
          isActive: active,
          note: "",
          details: "",
        };
        const res = await axiosInstance.post("/master/addVehicle", formData);
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("/vehicleMaster");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Vehicle Name"
              />

              <span className="top-placeholder">Vehicle Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="code">
              <input
                className="form-control"
                id="code"
                name="code"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                placeholder="Vehicle Code"
              />

              <span className="top-placeholder">Vehicle Code</span>
            </label>
            <label className="col-md-12 mb-3" htmlFor="description">
              <textarea
                className="form-control"
                id="description"
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                placeholder="Vehicle Description"
              ></textarea>

              <span className="top-placeholder">Vehicle Description</span>
            </label>
            <div className="col-md-6 mb-3">
              <label htmlFor="active" className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            {(location.pathname === "/vehicleMaster/vehicleMasterForm" ||
              location.pathname === "/vehicleMaster/vehicleMasterEdit") && (
              <CancelBtn path="/vehicleMaster" />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(VehicleMasterForm);
