import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useFormik } from "formik";
import { useLoading } from "Context/LoadingContext";
import axiosInstance from "axiosInstance";
import { successToast, warnToast } from "constants/toastConfig";
const containerStyle = {
  width: "100%",
  height: "100%",
};

type ProfileMapProps = {
  orgId: string;
  address: string;
  lat: string;
  lng: string;
};

const ProfileMap = ({ orgId, address, lat, lng }: ProfileMapProps) => {
  const { setLoading } = useLoading();
  const center: google.maps.LatLng | google.maps.LatLngLiteral | undefined = {
    lat: 23.0204737,
    lng: 72.4145878,
  };
  const MY_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const [coordinates, setCoordinates] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >(center);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${MY_API_KEY}`,
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: address,
      latitude: lat,
      longitude: lng,
    },
    onSubmit: async ({ address, latitude, longitude }) => {
      if (address === "") {
        warnToast("Enter the address");
      } else {
        try {
          setLoading(true);
          const formData = {
            address: address,
            latitude: latitude,
            longitude: longitude,
          };
          const res = await axiosInstance.put(
            `/master/updateOrganizationById/${orgId}`,
            formData
          );
          const responseMessage = res.data;
          if (responseMessage === "Ok") {
            successToast();
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    },
  });
  const handleSearch = useCallback(
    async (loc: string) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            loc
          )}&key=${MY_API_KEY}`
        );
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          formik.setFieldValue("latitude", lat.toString());
          formik.setFieldValue("longitude", lng.toString());
          setCoordinates({ lat, lng });
        }
      } catch (error) {
        console.error("Error geocoding location:", error);
      }
    },
    [formik.values.address]
  );

  useEffect(() => {
    handleSearch(formik.values.address);
  }, [formik.values.address]);

  const handleMarkerDragEnd = async (e: any) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    formik.setFieldValue("latitude", lat.toString());
    formik.setFieldValue("longitude", lng.toString());
    setCoordinates({ lat, lng });
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MY_API_KEY}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const address = data.results[0].formatted_address;
        formik.setFieldValue("address", address);
      }
    } catch (error) {
      console.error("Error reverse geocoding:", error);
    }
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <div>
      <form className="d-flex" onSubmit={(e) => formik.handleSubmit(e)}>
        <label className="col-md-9 mb-3" htmlFor="address">
          <span className="top-placeholder">Address*</span>
          <input
            className={`form-control ${
              formik.values.address !== "" ? "" : "empty-form"
            }`}
            id="address"
            name="address"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address}
            placeholder="address"
          />
        </label>
        <div>
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </div>
      </form>
      {isLoaded ? (
        <div style={{ width: "100%", height: "80vh", marginBottom: "20px" }}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={coordinates}
            zoom={10}
          >
            {formik.values.longitude !== "" &&
              formik.values.latitude !== "" && (
                <Marker
                  position={coordinates}
                  draggable={true}
                  onDragEnd={handleMarkerDragEnd}
                />
              )}
          </GoogleMap>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default ProfileMap;
