import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { AdminMessageDataType } from "types/adminMessageTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReactComponent as View } from "assets/images/view-16.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatDate } from "constants/Constants";

type Props = {
  x: AdminMessageDataType;
};

const AdminMessageListItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();
  const ViewToolTip = <Tooltip id={`tooltip-${x._id}`}>View</Tooltip>;

  return (
    <tr key={x._id}>
      <td>{x.name}</td>
      <td>{x.email}</td>
      <td>{x.contactNo}</td>
      <td>{x.message.slice(0, 20)}</td>
      <td>{formatDate(x.createdAt)}</td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={ViewToolTip}>
          <View
            id={x._id}
            className="pointer Approved_Size"
            onClick={() => {
              nav("messageView", {
                state: x,
              });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(AdminMessageListItem);
