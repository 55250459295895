import axiosInstance from "axiosInstance";
import React, { useState, useEffect, memo } from "react";
import { OrganizationType } from "types/organizationTypes";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { Link } from "react-router-dom";

type Props = {};
const ProfileHeading = (props: Props): JSX.Element => {
  const [organization, setOrganization] = useState<OrganizationType>();
  const dataFetch = async () => {
    const res = await axiosInstance.get("/master/getOneOrganization");
    const org = res.data.Organization as OrganizationType;
    setOrganization(org);
  };

  useEffect(() => {
    dataFetch();
  }, []);
  return (
    <div className="profile_header d-flex align-items-center gap-2">
      <b> Welcome! </b>
      {/* <span className="message"></span> */}
      <span className="message">
        {organization?.roleCode !== "SUP" && (
          <Link to={"/profile"}>
            {organization?.name}
            {/* <Eye /> */}
          </Link>
        )}
      </span>
    </div>
  );
};

export default memo(ProfileHeading);
