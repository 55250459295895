import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { TypeMasterDataType } from "../../../types/typeMasterTypes";
import { CategoryDDL } from "../../../types/categoryTypes";
import TypeMasterListItem from "./typeMasterListItem";
import { DepartmentDDLType } from "types/departmentMasterType";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";

type Props = {};

const TypeMasterList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [types, setTypes] = useState<TypeMasterDataType[]>([]);
  const [categories, setCategories] = useState<CategoryDDL[]>([]);
  const [departments, setDepartments] = useState<DepartmentDDLType[]>([]);
  // const [categoryId, setCategoryId] = useState("");
  // const [departmentId, setDepartmentId] = useState("");
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("dName");
  const [searchCount, setSearchCount] = useState(0);
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const department = res.data.Departments;
      setDepartments(department);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadCategories = useCallback(async (depId: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getCategory?depId=${depId}`
      );
      const categories = res.data.categories;
      setCategories(categories);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadTypes = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllType?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newTypeMasterList: TypeMasterDataType[] = [
          ...res.data.typeMasters[0].paginatedResults,
        ];
        if (searchKeyword !== "" && pageNum === 1) {
          if (newTypeMasterList.length === 0) {
            setNotFound(true);
            setTypes([]);
            setSearchCount(0);
          }
        }
        if (newTypeMasterList.length > 0) {
          setSearchCount(res.data.typeMasters[0].totalCount[0].count);
          if (pageNum === 1) {
            setTypes(newTypeMasterList);
          } else {
            setTypes((prevList) => [...prevList, ...newTypeMasterList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [types, setLoading]
  );

  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadTypes(1, sort, search); // Trigger API call with updated search keyword
  };

  useEffect(() => {
    loadDepartments();
    loadTypes(page, sort, search);
  }, [page, sort, loadCategories, loadDepartments]);

  window.onscroll = () => {
    infiniteScroll(types, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        addLink="/typeMaster/typeMasterForm"
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Department or Category or Sub Category"
      />

      <table className="table">
        <thead>
          <tr>
            <th
              id="dName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setTypes, setPage);
              }}
            >
              Department
              <span className="px-2">
                {sort === "dName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="cName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setTypes, setPage);
              }}
            >
              Category
              <span className="px-2">
                {sort === "cName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setTypes, setPage);
              }}
            >
              Sub Category
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="code"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setTypes, setPage);
              }}
            >
              Code
              <span className="px-2">
                {sort === "code" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>

            <th>Description</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {types?.map((x) => {
              return (
                <TypeMasterListItem
                  x={x}
                  key={x._id}
                  departments={departments}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(TypeMasterList);
