import { dateFormatForAdmin } from "constants/Constants";
import { memo } from "react";
import { AdminExpiredPlanDataType } from "types/adminExpiredPlanTypes";

type Props = {
  x: AdminExpiredPlanDataType;
};

const AdminExpirePlanListItem = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td className="py-4">{x.name}</td>
      <td className="py-4">{x.bussinessName}</td>
      <td className="py-4">{x.planName}</td>
      <td className="py-4">{dateFormatForAdmin(x.planStartDate)}</td>
      <td className="py-4">{dateFormatForAdmin(x.planExpired)}</td>
    </tr>
  );
};

export default memo(AdminExpirePlanListItem);
