import React, { memo } from "react";
import { MemberLeaveDataType } from "../../../types/memberLeaveTypes";
import { formatDate, memImage } from "constants/Constants";

type Props = {
  x: MemberLeaveDataType;
};

const MemberLeaveListItem = ({ x }: Props): JSX.Element => {
  let userTypeImage = x.photo
    ? `${memImage}${x.photo}`
    : `${memImage}Default.png`;
  return (
    <>
      <tr key={x._id}>
        <td>
          <i className="userImg">
            <img src={userTypeImage} alt="MemberPic" />
          </i>
        </td>
        <td className="pb-4">{x.memberName}</td>
        <td className="pb-4">{x.memberRouteName}</td>
        <td className="pb-4">{formatDate(x.startDate)}</td>
        <td className="pb-4">
          {x.endDate !== null ? formatDate(x.endDate) : "-"}
        </td>
        <td className="pb-4">{formatDate(x.createdAt)}</td>
      </tr>
    </>
  );
};

export default memo(MemberLeaveListItem);
