import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import React, { useCallback, useState, useEffect, memo } from "react";
import { RouteVehicleDataType } from "../../../types/routeVehicleTypes";
import RouteVehicleListItem from "./routeVehicleListItem";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { useNavigate } from "react-router-dom";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";

type Props = {
  state: any;
};

const RouteVehicleList = ({ state }: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [routeVehicle, setRouteVehicle] = useState<RouteVehicleDataType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("shiftName");
  const [searchCount, setSearchCount] = useState(0);
  const { notfound, setNotFound } = useNotFound();

  const loadRouteVehicle = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllRouteVehicle?page=${pageNum}&sort=${sortParam}&routeId=${state._id}`
        );
        const routeVehicleList = [
          ...routeVehicle,
          ...res.data.routeVehicle[0].paginatedResults,
        ];

        if (routeVehicleList.length === 0) {
          setNotFound(true);
          if (pageNum === 1) {
            setRouteVehicle([]);
            setSearchCount(0);
          }
        }
        if (routeVehicleList.length > 0) {
          setSearchCount(res.data.routeVehicle[0].totalCount[0]?.count);
          if (pageNum === 1) {
            setRouteVehicle(routeVehicleList);
          } else {
            setRouteVehicle((prevList) => [...prevList, ...routeVehicleList]);
          }
          setNotFound(false);
        }

        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    loadRouteVehicle(page, sort);
  }, [page, sort, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(routeVehicle, setPage, page, searchCount);
  };

  return (
    <>
      <div className="d-flex align-items-end justify-content-end my-3">
        <button
          className="btn btn-primary "
          type="submit"
          onClick={() => {
            const item = state;
            nav(
              {
                pathname: "/routeMaster/addForm/routeVehicleAdd",
                search: "?fromEdit=1",
              },
              {
                state: item,
              }
            );
          }}
        >
          Add
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="shiftName"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteVehicle,
                  setPage
                );
              }}
            >
              Shift
              {!ascDsc ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="vehicleNo"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteVehicle,
                  setPage
                );
              }}
            >
              Vehicle No
              {!ascDsc ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="driverName"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteVehicle,
                  setPage
                );
              }}
            >
              Driver Name
              {!ascDsc ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th>Contact No</th>
            <th>Attendant Name</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {routeVehicle?.map((x) => {
              return (
                <RouteVehicleListItem
                  x={x}
                  key={Math.random()}
                  loadRouteVehicle={loadRouteVehicle}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(RouteVehicleList);
