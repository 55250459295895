import React, { memo } from "react";
import { Outlet } from "react-router-dom";

type Props = {};

const FeesConfig: React.FC<{}> = (props: Props) => {
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Fees</span>
      </div>
      <Outlet />
    </>
  );
};

export default memo(FeesConfig);
