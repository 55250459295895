import { toast } from "react-toastify";

const errorToastId = "unique-error-toast";
const warnToastId = "unique-warn-toast";
const infoToastId = "unique-info-toast";
const successToastId = "unique-success-toast";

const toastValidation = (errors: any) => {
  if (Object.keys(errors).length === 0) {
    return null;
  } else {
    toast.warn(errors[Object.keys(errors)[0] as keyof typeof errors], {
      position: toast.POSITION.TOP_RIGHT,
      toastId: warnToastId,
    });
  }
};

const errorToast = (msg: string) => toast.error(msg, { toastId: errorToastId });
const infoToast = (msg: string) => toast.info(msg, { toastId: infoToastId });
const warnToast = (msg: string) => toast.warn(msg, { toastId: warnToastId });
const successToast = (msg?: string) =>
  toast.success(msg ?? "Success", { toastId: successToastId });
const dismissToast = () => toast.dismiss();

export default toastValidation;
export { errorToast, successToast, infoToast, warnToast, dismissToast };
