import React, { memo } from "react";
import { ConfigurationDataType } from "../../../types/configurationTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";

type Props = {
  x: ConfigurationDataType;
};

const ClientCongItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();

  return (
    <tr key={x.id}>
      <td>{x.key}</td>
      <td>{x.value}</td>
      <td className="text-center">
        <Edit
          id={x.id}
          className="pointer"
          onClick={() => {
            nav("ClientConfigurationEdit", {
              state: x,
            });
          }}
        />
      </td>
      </tr>
  );
};

export default memo(ClientCongItem);
