import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";

type Props = {};

const AdminRequestForm = (props: Props) => {
  const nav = useNavigate();


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNo: "",
      details: "",
      demoDate: "",
      Time: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      email: Yup.string(),
      contactNo: Yup.string(),
      details: Yup.string(),
      demoDate: Yup.string(),
      Time: Yup.string(),
    }),
    onSubmit: async ({
      name,
      email,
      contactNo,
      details,
      demoDate,
      Time,
    }) => {
      try {
        const formData = {
          name: name,
          email: email,
          contactNo: contactNo,
          details: details,
          demoDate: demoDate,
          Time: Time,
        };


        const res = await axiosInstance.post(
          "/admin/addAdminRequest",
          formData,
          {}
        );
        const responseMessage = res.data;
        console.log("responseMessage", responseMessage);
        if (responseMessage === "Ok") {
          nav("/adminRequest");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });


  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className="form-control"
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="name"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="email">
              <input
                className="form-control"
                id="email"
                name="email"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="email"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="contactNo">
              <input
                className="form-control"
                placeholder="contactNo"
                id="contactNo"
                name="contactNo"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contactNo}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="details">
              <input
                className="form-control"
                placeholder="details"
                id="details"
                name="details"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.details}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="demoDate">
              <input
                className="form-control"
                id="demoDate"
                name="demoDate"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.demoDate}
                placeholder="demoDate"
                onFocus={(e) => (e.target.type = "date")}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="Time">
              <input
                className="form-control"
                id="Time"
                name="Time"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.Time}
                placeholder="Time"
                onFocus={(e) => (e.target.type = "date")}
              />
            </label>

          </div>

        </form>
      </div>
    </>
  );
};

export default memo(AdminRequestForm);
