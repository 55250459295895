import React, { memo } from "react";
import { AdminRequestDataType } from "types/adminRequestTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { convertTo12HourFormat, dateFormatForAdmin } from "constants/Constants";

type Props = {
  x: AdminRequestDataType;
};

const DemoReschedule = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();
  return (
    <tr key={x._id}>
      <td>{x.name}</td>
      <td>{x.email}</td>
      <td>{x.contactNo}</td>
      <td>{dateFormatForAdmin(x.demoDate)}</td>
      <td>{convertTo12HourFormat(x.Time)}</td>
      <td>Reschedule</td>
      <td className="text-center">
        {x.latestCreatedAt === null
          ? "__"
          : dateFormatForAdmin(x.latestCreatedAt)}
      </td>
      <td className="text-center">
        <Edit
          id={x._id}
          className="pointer"
          onClick={() => {
            nav("demoEditForm", {
              state: x,
            });
          }}
        />
      </td>
    </tr>
  );
};

export default memo(DemoReschedule);
