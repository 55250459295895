import { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { successToast, warnToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { OrgBasicSetup } from "./List";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button, Modal } from "react-bootstrap";
import PasswordBasic from "./PassIndex";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
const containerStyle = {
  width: "100%",
  height: "100%",
};
type Props = {};

const ConfigEditForm = (props: Props) => {
  // const center: google.maps.LatLng | google.maps.LatLngLiteral | undefined = {
  //   lat: 23.0204737,
  //   lng: 72.4145878,
  // };
  // const MY_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  // const [coordinates, setCoordinates] = useState<
  //   google.maps.LatLng | google.maps.LatLngLiteral
  // >(center);

  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: `${MY_API_KEY}`,
  // });
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [orgBasicSetup, setOrgBasicSetup] = useState<OrgBasicSetup>();
  const { pathname } = useLocation();
  const [param] = useSearchParams();
  const [show, setShow] = useState(false);
  const [loc, setLoc] = useState<any[]>([]);
  const [locId, setLocId] = useState<string | undefined>("");

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/master/getAllBasicLocationDDL`);
      const department = res.data.location;
      const catIdForDep = state.locationId;
      const newDepList = updateDlist(department, catIdForDep);
      setLoc(newDepList as any[]);
      setLocId(catIdForDep);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [state.locationId]);

  const getOneOrgBasicSetup = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getOrgBasicSetup/${state._id}`
      );
      setOrgBasicSetup(res.data.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [state._id]);

  useEffect(() => {
    loadDepartments();
    getOneOrgBasicSetup();
  }, [loadDepartments, getOneOrgBasicSetup]);

  const updateDlist = (list: any[], id: string) => {
    const newList = [...list];
    const record = newList?.find((x: any) => x?._id === id);
    const index = newList.indexOf(record as any);
    newList.splice(index, 1);
    newList.splice(0, 0, record as any);
    return newList;
  };

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      memberName: orgBasicSetup?.memberName,
      contactNo: orgBasicSetup?.contactNo,
      memberFees: orgBasicSetup?.memberFees,
      address: orgBasicSetup?.address,
      // pickupLocation: orgBasicSetup?.pickupLocation,
      // latitude: orgBasicSetup?.latitude,
      // longitude: orgBasicSetup?.longitude,
      // pickUpTime: orgBasicSetup?.pickUpTime,
      // dropTime: orgBasicSetup?.dropTime,
      isActive: orgBasicSetup?.isActive,
    },
    validationSchema: Yup.object().shape({
      memberName: Yup.string()
        .min(1, "Member Name is too short!")
        .max(30, "Member Name is too long!")
        .required("Member Name is required"),
      contactNo: Yup.string()
        .min(10, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!")
        .required("Contact Number is Required"),
      address: Yup.string()
        .min(1, "Address is too short!")
        .max(300, "Address is too long!")
        .required("Address is required"),
      // pickupLocation: Yup.string().required("Pickup location is required"),
      // pickUpTime: Yup.string().required("Pickup Time is required"),
      // dropTime: Yup.string().required("Drop Time is required"),
    }),
    onSubmit: async ({
      memberName,
      contactNo,
      address,
      // pickupLocation,
      // latitude,
      // longitude,
      // pickUpTime,
      // dropTime,
      isActive,
      memberFees,
    }) => {
      setLoading(true);
      try {
        const formData = {
          locationId: locId,
          memberName,
          contactNo,
          address,
          // pickupLocation,
          // latitude,
          // longitude,
          // pickUpTime,
          // dropTime,
          isActive,
          memberFees,
        };
        const res = await axiosInstance.put(
          `/master/updateOrgBasicSetup/${state._id}`,
          formData
        );
        if (res.status === 202) {
          warnToast(res.data);
          setLoading(false);
          return;
        }
        if (res.data === "Ok") {
          successToast();
          setTimeout(() => {
            setLoading(false);
            nav("..");
          }, 1500);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  // const handleSearch = useCallback(
  //   async (loc: string) => {
  //     try {
  //       const response = await fetch(
  //         `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
  //           loc
  //         )}&key=${MY_API_KEY}`
  //       );
  //       const data = await response.json();
  //       if (data.results && data.results.length > 0) {
  //         const { lat, lng } = data.results[0].geometry.location;
  //         formik.setFieldValue("latitude", lat.toString());
  //         formik.setFieldValue("longitude", lng.toString());
  //         setCoordinates({ lat, lng });
  //       }
  //     } catch (error) {
  //       console.error("Error geocoding location:", error);
  //     }
  //   },
  //   [formik.values.pickupLocation]
  // );

  // useEffect(() => {
  //   if (formik.values.pickupLocation !== undefined) {
  //     handleSearch(formik.values.pickupLocation);
  //   }
  // }, [formik.values.pickupLocation]);

  // const handleMarkerDragEnd = async (e: any) => {
  //   const lat = e.latLng.lat();
  //   const lng = e.latLng.lng();
  //   formik.setFieldValue("latitude", lat.toString());
  //   formik.setFieldValue("longitude", lng.toString());
  //   setCoordinates({ lat, lng });
  //   try {
  //     const response = await fetch(
  //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MY_API_KEY}`
  //     );
  //     const data = await response.json();
  //     if (data.results && data.results.length > 0) {
  //       const address = data.results[0].formatted_address;
  //       formik.setFieldValue("pickupLocation", address);
  //     }
  //   } catch (error) {
  //     console.error("Error reverse geocoding:", error);
  //   }
  // };

  // if (loadError) {
  //   return <div>Error loading Google Maps</div>;
  // }
  return (
    <>
      {pathname !== "/basicmember" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/basicmember" />
        </div>
      )}
      <Tabs defaultIndex={Number(param.get("index"))}>
        <TabList>
          <Tab>Member</Tab>
          <Tab>Password</Tab>
        </TabList>
        <TabPanel>
          <div className="formBg">
            <form onSubmit={formik.handleSubmit}>
              <div className="row align-items-center">
                <label className="col-md-6 mb-3" htmlFor="memberName">
                  <input
                    className={`form-control ${
                      formik.errors.memberName && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="memberName"
                    name="memberName"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.memberName}
                    placeholder="Member Name"
                  />
                  <span className="top-placeholder">Name*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="contactNo">
                  <input
                    className={`form-control ${
                      formik.errors.contactNo && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="contactNo"
                    name="contactNo"
                    type="number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.contactNo}
                    placeholder="Contact Number"
                  />
                  <span className="top-placeholder">Contact*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="address">
                  <input
                    className={`form-control ${
                      formik.errors.address && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="address"
                    name="address"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.address}
                    placeholder="Address"
                  />
                  <span className="top-placeholder">Address*</span>
                </label>
                <label htmlFor="location" className="col-md-6 mb-3">
                  <span className="top-placeholder">Location*</span>

                  <select
                    className="form-control"
                    id="location"
                    name="location"
                    onChange={(e) => {
                      setLocId(e.target.value);
                    }}
                  >
                    {loc?.map(({ _id, locationName }) => {
                      return (
                        <option key={_id} value={_id}>
                          {locationName}
                        </option>
                      );
                    })}
                  </select>
                </label>
                {/* <label className="col-md-6 mb-3" htmlFor="pickupLocation">
                  <input
                    className={`form-control ${
                      formik.errors.pickupLocation && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="pickupLocation"
                    name="pickupLocation"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.pickupLocation}
                    placeholder="Pickup Location"
                  />
                  <span className="top-placeholder">Location*</span>
                </label>
                <label className="col-md-3 mb-3" htmlFor="pickUpTime">
                  <input
                    className={`form-control session-time ${
                      formik.errors.pickUpTime && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="pickUpTime"
                    name="pickUpTime"
                    type="time"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.pickUpTime}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <span className="top-placeholder">Pickup Time*</span>
                </label>
                <label className="col-md-3 mb-3" htmlFor="dropTime">
                  <input
                    className={`form-control session-time ${
                      formik.errors.dropTime && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="dropTime"
                    name="dropTime"
                    type="time"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.dropTime}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <span className="top-placeholder">Drop Time*</span>
                </label> */}
                <div className="col-md-3 mb-3">
                  <label className="custCheck" htmlFor="isActive">
                    Active
                    <input
                      type="checkbox"
                      id="isActive"
                      name="isActive"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      checked={formik.values.isActive}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              {/* {isLoaded ? (
                <div
                  style={{
                    width: "100%",
                    height: "80vh",
                    marginBottom: "20px",
                  }}
                >
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={coordinates}
                    zoom={10}
                  >
                    {formik.values.longitude !== "" &&
                      formik.values.latitude !== "" && (
                        <Marker
                          position={coordinates}
                          draggable={true}
                          onDragEnd={handleMarkerDragEnd}
                        />
                      )}
                  </GoogleMap>
                </div>
              ) : (
                <div>Loading....</div>
              )} */}
              <div className="col-md-12 mb-3 text-center">
                <SubmitBtn errors={formik.errors} />
                <CancelBtn path=".." />
              </div>
            </form>
          </div>
          <Modal show={show}>
            <Modal.Body>
              Are you sure you want to delete this record?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setShow(false);
                }}
              >
                Ok
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  formik.setFieldValue("isDeleted", false);
                  setShow(false);
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </TabPanel>
        <TabPanel>
          <PasswordBasic state={state} />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default memo(ConfigEditForm);
