import React from "react";
import MapTry from "../../../components/MapTrial";

type Props = {};

const TrialMap = (props: Props) => {
  return <div>Hello</div>;
};

export default TrialMap;
