import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import AttendantMasterListItem from "./attendantMasterListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { useNotFound } from "Context/NotFound";
import NotFound from "components/NotFound";
import AddSearch from "components/AddSearchComponent";
import { AttendantMasterType } from "types/AttendantTypes";

type Props = {};

const AttendantList = (props: Props) => {
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [attendant, setAttendant] = useState<AttendantMasterType[]>([]);
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const loadAttendants = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllAttendantListById?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newAttendantList: AttendantMasterType[] = [
          ...res.data.attendant[0].paginatedResults,
        ];
        if (newAttendantList.length === 0) {
          setNotFound(true);
          if (searchKeyword !== "" && pageNum === 1) {
            setAttendant([]);
            setSearchCount(0);
          }
        }
        if (newAttendantList.length > 0) {
          setSearchCount(res.data.attendant[0].totalCount[0].count);
          if (pageNum === 1) {
            setAttendant(newAttendantList);
          } else {
            setAttendant((prevList) => [...prevList, ...newAttendantList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        setLoading(false);
        console.log(error);
      }
    },
    [attendant]
  );

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    loadAttendants(1, sort, search);
  };

  useEffect(() => {
    loadAttendants(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(attendant, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        addLink="/attendant/attendantForm"
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Attendant Name or Contact Number"
      />
      <table className="table">
        <thead>
          <tr>
            <th>Photo</th>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setAttendant, setPage);
              }}
            >
              Attendant Name
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th>Contact Number</th>
            <th>Description</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {attendant?.map((x) => {
              return <AttendantMasterListItem x={x} key={x._id} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(AttendantList);
