import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type NotFoundContextType = {
  notfound: boolean;
  setNotFound: Dispatch<SetStateAction<boolean>>;
};

type Props = {
  children: ReactNode;
};

const NotFoundContext = createContext<NotFoundContextType>({
  notfound: false,
  setNotFound: () => {},
});

export function NotFoundProvider({ children }: Props) {
  const [notfound, setNotFound] = useState(false);
  const value = { notfound, setNotFound };
  return (
    <NotFoundContext.Provider value={value}>
      {children}
    </NotFoundContext.Provider>
  );
}

export function useNotFound() {
  const context = useContext(NotFoundContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}
