import { memo, useState } from "react";
import { useFormik } from "formik";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import * as Yup from "yup";

type Props = {};

const ConfigAddForm = (props: Props) => {
  const { setLoading } = useLoading();

  const nav = useNavigate();

  const formik = useFormik({
    validateOnMount: true,

    initialValues: {
      VehicleType: "",
      LPNumber: "",
      isActive: true,
    },

    validationSchema: Yup.object().shape({
      VehicleType: Yup.string()
        .min(1, "Vehicle Type Name is too short!")
        .max(30, "Vehicle Type Name is too long!")
        .required("Vehicle Type Name is required"),
      LPNumber: Yup.string()
        .matches(
          /^[A-Z]{2}-\d{1,2}-[A-Z]{1,2}-\d{1,4}$/,
          "Please Enter a Valid License Plate Number"
        )
        .min(1, "License Plate Number is Too Short")
        .max(15, "License Plate Number is Too Long")
        .required("License Plate Number is Required"),
    }),

    onSubmit: async (values) => {
      const { VehicleType, LPNumber, isActive } = values;

      setLoading(true);
      try {
        const formData = {
          VehicleType,
          LPNumber,
          isActive,
        };
        const res = await axiosInstance.post(
          "/master/addBasicVehicleMaster",
          formData
        );
        if (res.status === 202) {
          warnToast(res.data);
          setLoading(false);
          return;
        }
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("..");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="memberName">
              <input
                className={`form-control ${
                  formik.errors.VehicleType && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="VehicleType"
                name="VehicleType"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.VehicleType}
                placeholder="Vehicle Type Name"
              />
              <span className="top-placeholder">Vehicle Type*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="contactNo">
              <input
                className={`form-control ${
                  formik.errors.LPNumber && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="LPNumber"
                name="LPNumber"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.LPNumber}
                placeholder="License Plate Number (eg.GJ-01-AA-5050)"
              />
              <span className="top-placeholder">LP Number*</span>
            </label>

            <div className="col-md-3 mb-3">
              <label className="custCheck" htmlFor="isActive">
                Active
                <input
                  type="checkbox"
                  id="isActive"
                  name="isActive"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.isActive}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            <CancelBtn path=".." />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(ConfigAddForm);
