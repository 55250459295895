import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DepartmentMasterDataType } from "../../../types/departmentMasterType";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { descSlice } from "constants/Constants";

type Props = {
  item: DepartmentMasterDataType;
};

const DepartmentMasterListItem = ({ item }: Props) => {
  const nav = useNavigate();

  const handleEditClick = () => {
    nav("editForm", {
      state: { item },
    });
  };

  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;

  return (
    <tr key={item._id}>
      <td>{item.name}</td>
      <td>{item.code}</td>
      <td>{descSlice(item.description)}</td>
      <td className="text-center">
        {item.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={item._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(DepartmentMasterListItem);
