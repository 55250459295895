import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { ConfigurationDataType } from "../../../types/configurationTypes";
import ConfigurationListItem from "./ConfigurationListItem";
import { useLoading } from "Context/LoadingContext";

type Props = {
  type?: string;
};

const ConfiguarationList = ({ type }: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [config, setConfig] = useState<ConfigurationDataType[]>([]);

  const configData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/master/getAllConfigurationDetail`);
      // console.log(res.data);

      const conData: ConfigurationDataType[] = [
        ...config,
        ...res.data.configuration,
      ];
      setConfig(conData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [config]);
  useEffect(() => {
    configData();
  }, []);
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Details</th>
            <th>Value</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {config.map((x, index) => {
            if (type === "basic") {
              return (
                (x.key === "waitTime" ||
                  x.key === "waitCount" ||
                  x.key === "manualAutoLocation") && (
                  <ConfigurationListItem x={x} key={index} />
                )
              );
            } else return <ConfigurationListItem x={x} key={index} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(ConfiguarationList);
