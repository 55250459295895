import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import { useLoading } from "Context/LoadingContext";
import { useCallback, useState, useEffect, memo } from "react";
import { RouteLocationListType } from "types/routeLocationTypes";
import RouteLocationListItem from "./RouteLocationListItem";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useNavigate } from "react-router-dom";
import { useNotFound } from "Context/NotFound";
import NotFound from "components/NotFound";
type Props = {
  state: any;
};

const RouteLocationList = ({ state }: Props): JSX.Element => {
  const nav = useNavigate();
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("routename");
  const [searchCount, setSearchCount] = useState(0);
  const [routeLocation, setRouteLocation] = useState<RouteLocationListType[]>(
    []
  );
  const { notfound, setNotFound } = useNotFound();

  const loadRouteLocation = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllRouteLocation?page=${pageNum}&sort=${sortParam}&routeId=${state._id}`
        );

        const routeLocList: RouteLocationListType[] = [
          ...routeLocation,
          ...res.data.routeLocation[0]?.paginatedResults,
        ];

        if (routeLocList.length === 0) {
          setNotFound(true);
          if (pageNum === 1) {
            setRouteLocation([]);
            setSearchCount(0);
          }
        }
        if (routeLocList.length > 0) {
          setSearchCount(res.data.routeLocation[0]?.totalCount[0]?.count);
          if (pageNum === 1) {
            setRouteLocation(routeLocList);
          } else {
            setRouteLocation((prevList) => [...prevList, ...routeLocList]);
          }
          setNotFound(false);
        }

        // setNotFound(true);
        // setRouteLocation(routeLocList);

        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [routeLocation]
  );

  useEffect(() => {
    loadRouteLocation(page, sort);
  }, [page, sort]);

  window.onscroll = () => {
    infiniteScroll(routeLocation, setPage, page, searchCount);
  };
  return (
    <>
      <div className="d-flex align-items-end justify-content-end my-3">
        <button
          className="btn btn-primary "
          type="submit"
          onClick={() => {
            const item = state;
            nav(
              {
                pathname: "/routeMaster/addForm/routeLocationAdd",
                search: "?fromEdit=1",
              },
              {
                state: item,
              }
            );
          }}
        >
          Add
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="area"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteLocation,
                  setPage
                );
              }}
            >
              Area
              {sort === "area" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="areadivision"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteLocation,
                  setPage
                );
              }}
            >
              Division
              {sort === "area" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="locationname"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteLocation,
                  setPage
                );
              }}
            >
              Location
              {sort === "locationname" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>

            <th>Pickup Time</th>
            <th>Drop Time</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {routeLocation.map((item) => {
              return (
                <RouteLocationListItem x={item} key={item._id} state={state} />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(RouteLocationList);
