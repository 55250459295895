import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { OrganizationType } from "types/organizationTypes";
import { infiniteScroll, sortData } from "constants/Constants";
import NewCustomerItem from './newCustpmerItem';
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";

type Props = {};

const NewCustomerDash = (props: Props): JSX.Element => {
  const [newCustomer, setNewCustomer] = useState<OrganizationType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("-createdAt");
  const [searchCount, setSearchCount] = useState(0);
  const { setLoading } = useLoading();

  const loadNewCustomer = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `master/getNewCustomerDetails?page=${pageNum}&sort=${sortParam}`
        );
        const newCustomerList: OrganizationType[] = [
          ...newCustomer,
          ...res.data.newcustomer[0].paginatedResults,
        ];
        setNewCustomer(newCustomerList);
        setSearchCount(res.data.newcustomer[0].totalCount[0].count);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [newCustomer]
  );

  useEffect(() => {
    loadNewCustomer(page, sort);
  }, [page, sort]);

  window.onscroll = () => {
    infiniteScroll(newCustomer, setPage, page, searchCount);
  };
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th
            className="pb-4"
              id="name"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setNewCustomer, setPage);
              }}
            >
              Name
              {sort === "name" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="ContactNo"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setNewCustomer, setPage);
              }}
            >
              Contact Number
              {sort === "ContactNo" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="Email"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setNewCustomer, setPage);
              }}
            >
              Email
              {sort === "Email" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="businessType"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setNewCustomer, setPage);
              }}
            >
              Business Type
              {sort === "businessType" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setNewCustomer, setPage);
              }}
            >
              Request Date
              {sort === "createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {newCustomer?.map((x, index) => {
            return <NewCustomerItem x={x} key={index} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(NewCustomerDash);
