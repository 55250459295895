import React, { memo, useCallback, useEffect, useState } from "react";
import axiosInstance from "axiosInstance";
import socket from "socket";
import CardComponent from "../Dashboard/CardComponent";
import memberWait from "../../../assets/images/memberWait.png";
type Props = {};

const LoginMembers: React.FC<{}> = (props: Props) => {
  const [member, setMember] = useState(0);
  const loadMembrs = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/mobile/getMemberCount`);
      setMember(res.data.NoOfMember);
    } catch (error) {
      console.log(error);
    }
  }, [member]);
  useEffect(() => {
    loadMembrs();
    socket.on("admin-count", (data) => {
      if (data === "member-login") {
        loadMembrs();
      }
    });
  }, []);
  return (
    <>
      <CardComponent
        count={member}
        text="Login Member"
        background={memberWait}
      />
    </>
  );
};
export default memo(LoginMembers);
