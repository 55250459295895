import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { LocationDataType } from "types/locationMasterType";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import MapForm from "components/MapTrial";
import { useFeesConfig } from "Context/FeesConfigurationContext";
import { useAreaEdit } from "Context/AreaEditContext";
import { LatLongObj } from "constants/Constants";

type Props = {};
type Config = {
  routeOptimization: boolean;
};
const LocationEditForm = (props: Props) => {
  const { feesConfigType } = useFeesConfig();

  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const { area } = useAreaEdit();
  const [location, setLocation] = useState<LocationDataType>();
  const [division, setDivision] = useState<AreaDivisionDDL[]>([]);
  const [divId, setDivId] = useState("");
  const RouteOptimizationValue: Config | null = JSON.parse(
    localStorage.getItem("config") || "null"
  );
  const isRouteOptimization = RouteOptimizationValue?.routeOptimization;
  const settingMarker = (
    nameX: string,
    areaX: string | undefined,
    addressX?: string
  ) => {
    if (
      (typeof areaX === "string" && areaX === "") ||
      (typeof areaX === "undefined" && nameX !== "")
    ) {
      const value = `${nameX},${addressX}`;
      return value;
    } else {
      const value = `${nameX},${addressX},${areaX}`;
      return value;
    }
  };

  const loadAreaCode = useCallback(async (pinCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getAreaDivision?pinCode=${pinCode}`
      );
      const div = res.data.division;
      setLoading(false);
      return div;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const getOneLocation = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getOneLocation/${state.x._id}`
      );
      const loc = res.data.location;
      setLocation(res.data.location);

      const division = await loadAreaCode(area?.pinCode);
      const updatedDivisionList = updateDivisionList(division, loc.areaCode);
      setDivision(updatedDivisionList);
      setDivId(loc.areaCode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getOneLocation();
  }, [getOneLocation]);

  const updateDivisionList = (list: AreaDivisionDDL[], id: string) => {
    const newList = [...list];
    const record = newList?.find((x: AreaDivisionDDL) => x?._id === id);
    const index = newList.indexOf(record as AreaDivisionDDL);
    newList.splice(index, 1);
    newList.splice(0, 0, record as AreaDivisionDDL);
    return newList;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: location?.name,
      code: location?.code,
      address1: location?.address1,
      address2: location?.address2,
      latitude: location?.latitude,
      longitude: location?.longitude,
      kilometer: location?.kilometer,
      description: location?.description,
      active: location?.isActive,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Location Name is too Short!")
        .max(30, "Location Name is too Long!")
        .required("Location Name is Required"),
      code: Yup.string()
        .min(1, "Code is too Short!")
        .max(10, "Code is too Long!"),
      address1: Yup.string()
        .min(1, "Address 1 is too Short!")
        .max(300, "Address 1 is too Long!")
        .required("Address1 is Required"),
      address2: Yup.string().max(300, "Address2 is too Long!"),
      // latitude: Yup.string()
      //   .min(1)
      //   // .matches(latitudeRegex, 'Invalid latitude.')
      //   .required("Latitude is Required"),
      // longitude: Yup.string()
      //   .min(1)
      //   // .matches(longitudeRegex, 'Invalid longitude.')
      //   .required("Longitude is Required"),
      kilometer:
        feesConfigType === "km"
          ? Yup.string()
              .min(1, "kilometer is too Short!")
              .max(30, "kilometer is too Long!")
              .required("kilometer is Required")
          : Yup.string().nullable(true),
      description: Yup.string().max(300, "Description is too long!"),
      note: Yup.string().max(300, "Note is too long!"),
      details: Yup.string().max(300, "Details is too long!"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      name,
      code,
      address1,
      address2,
      latitude,
      longitude,
      kilometer,
      description,
      active,
    }) => {
      try {
        setLoading(true);
        const formData = {
          name: name,
          code: code,
          areaCode: divId,
          address1: address1,
          address2: address2,
          pinCode: String(area?.pinCode),
          latitude: latitude || LatLongObj.latitude,
          longitude: longitude || LatLongObj.longitude,
          kilometer: String(kilometer),
          description: description,
          isActive: active,
          note: "",
          details: "",
        };
        const res = await axiosInstance.put(
          `/master/updateLocation/${location?._id}`,
          formData
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("..", { state: { item: area } });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="areaCode">
              <span className="top-placeholder">Division*</span>

              <select
                className="form-control"
                id="areaCode"
                name="areaCode"
                onChange={(e) => {
                  setDivId(e.target.value);
                }}
              >
                {divId === "" && <option value="">Select Division</option>}
                {division.map(({ _id, areaCode }) => {
                  return (
                    <option key={_id} value={_id}>
                      {areaCode}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="name">
              {formik.values.name && (
                <span className="top-placeholder">Location Name*</span>
              )}
              <input
                className={`form-control ${
                  formik.errors.name ? "empty-form" : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={(e) => {
                  formik.handleBlur(e);
                  const loc = settingMarker(
                    e.target.value,
                    area?.areaName,
                    formik.values.address1
                  );
                  formik.setFieldValue("searchLocation", loc);
                }}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Location Name"
              />
            </label>

            <label className="col-md-6 mb-3" htmlFor="code">
              <span className="top-placeholder">Code</span>

              <input
                className="form-control"
                id="code"
                name="code"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                placeholder="Code"
              />
            </label>

            <label className="col-md-6 mb-3" htmlFor="address1">
              <span className="top-placeholder">Address1*</span>

              <input
                className={`form-control ${
                  formik.errors.address1 ? "empty-form" : ""
                }`}
                id="address1"
                name="address1"
                type="text"
                onBlur={(e) => {
                  formik.handleBlur(e);
                  const loc = settingMarker(
                    formik.values.name as string,
                    area?.areaName,
                    e.target.value
                  );
                  formik.setFieldValue("searchLocation", loc);
                }}
                onChange={(e) => {
                  formik.handleChange(e);
                  // setDivId("");
                }}
                value={formik.values.address1}
                placeholder="Address1"
              />
            </label>

            <label className="col-md-6 mb-3" htmlFor="address2">
              <span className="top-placeholder">Address2</span>

              <input
                className="form-control"
                id="address2"
                name="address2"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address2}
                placeholder="Address2"
              />
            </label>

            <label className="col-md-6 mb-3" htmlFor="latitude">
              <span className="top-placeholder">Latitude</span>

              <input
                className="form-control"
                id="latitude"
                name="latitude"
                type="text"
                onBlur={formik.handleBlur}
                onChange={() => {}}
                value={formik.values.latitude}
                placeholder="Latitude"
              />
            </label>

            <label className="col-md-6 mb-3" htmlFor="longitude">
              <span className="top-placeholder">Longitude</span>

              <input
                className="form-control"
                id="longitude"
                name="longitude"
                type="text"
                onChange={() => {}}
                value={formik.values.longitude}
                placeholder="Longitude"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="kilometer">
              <span className="top-placeholder">Kilometer</span>

              <input
                className="form-control"
                id="kilometer"
                name="kilometer"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.kilometer}
                placeholder="Kilometer"
              />
            </label>

            <label className="col-md-12 mb-3" htmlFor="description">
              <textarea
                className="form-control"
                id="description"
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                placeholder="Description"
              ></textarea>
            </label>
            <div className="col-md-3 mb-3">
              <label className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          {isRouteOptimization && (
            <MapForm
              form={formik}
              latX={Number(state.x.latitude)}
              lngX={Number(state.x.longitude)}
            />
          )}
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            <CancelBtn path=".." />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(LocationEditForm);
