import React, { useEffect, useCallback, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { CategoryDDL } from "../../../types/categoryTypes";
import { useLocation, useSearchParams } from "react-router-dom";
import { MemberDataType } from "types/MemberMaster.types";
import { DepartmentDDLType } from "../../../types/departmentMasterType";
import { TypeDDL } from "../../../types/typeMasterTypes";
import { useLoading } from "Context/LoadingContext";
import { AreaDDLType } from "types/areaMasterTypes";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import { Button, Modal } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AssignmentEditForm from "../MemberAssignment/AssignmentEditForm";
import RouteMemberEditForm from "../RouteMember/EditForm";
import RouteMemberListType from "types/RouteMemberList";
import { MemberAssignmentDataType } from "types/memberAssignment";
import { formatDate, formatDateToAdd, memImage } from "constants/Constants";
import CardMemberMappingAddForm from "../CardMemberMapping/CardMemberMappingAddForm";
import { useConfig } from "Context/ConfigContext";
import MemberDetailsFees from "../PaymentManagement/detailsList";
import MemberUserList from "../memberUser/memberUserList";
import Password from "../Password";

type Props = {};

const MemberEditForm = (props: Props) => {
  const { rfidCardCheck } = useConfig();
  const { setLoading } = useLoading();
  const { pathname } = useLocation();
  const state = useLocation().state?.member;
  const pathState = useLocation().state;
  const [param] = useSearchParams();
  const [member, setMember] = useState<MemberDataType>();
  const [category, setCategory] = useState<CategoryDDL[]>([]);
  const [catId, setCatId] = useState("");
  const [department, setDepartment] = useState<DepartmentDDLType[]>([]);
  const [depId, setDepId] = useState("");
  const [type, setType] = useState<TypeDDL[]>([]);
  const [typeId, setTypeId] = useState("");
  const [area, setArea] = useState<AreaDDLType[]>([]);
  const [areaName, setAreaName] = useState("");
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(false);
  const [routeMember, setRouteMember] = useState<RouteMemberListType>();
  const [memAssign, setMemAssign] = useState<MemberAssignmentDataType>();

  const getDepList = useCallback(async () => {
    setLoading(true);
    try {
      const resDep = await axiosInstance.get(`/dropDown/getDepartment`);
      const depList = resDep.data.Departments as DepartmentDDLType[];
      setLoading(false);
      return depList;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const getCatList = useCallback(async (depId: string) => {
    setLoading(true);
    try {
      const resCat = await axiosInstance.get(
        `/dropDown/getCategory?depId=${depId}`
      );
      let catList: CategoryDDL[] = [];
      catList = resCat.data.categories as CategoryDDL[];
      setLoading(false);
      return catList;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const getTypeList = useCallback(
    async (catId: string) => {
      try {
        setLoading(true);
        const resType = await axiosInstance.get(
          `/dropDown/getType?catId=${catId}`
        );
        const typeList = resType.data.types as TypeDDL[];
        setLoading(false);
        return typeList;
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [state.typeId]
  );

  const loadAreas = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getArea`);
      const areas = res.data.Areas;
      setLoading(false);
      return areas;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const getOneMember = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/master/getOneMember/${state._id}`);

      setMember(res.data?.member[0]);

      const area = await loadAreas();
      const newAreaList = updateArealist(area, res.data?.member[0].pinCode);
      setArea(newAreaList);
      setPin(res.data?.member[0].pinCode);
      setAreaName(res.data?.member[0].area);

      const dep = (await getDepList()) as DepartmentDDLType[];
      const newDepList = updateList(dep, state.departmentId);
      setDepartment(newDepList as DepartmentDDLType[]);
      setDepId(newDepList[0]._id);

      const cat = (await getCatList(state.departmentId)) as CategoryDDL[];
      if (cat === undefined) {
        setCategory([]);
        setCatId("");
      } else {
        if (state.categoryId !== null) {
          const newCatList = updateList(cat, state.categoryId);
          setCategory(newCatList as CategoryDDL[]);
          setCatId(newCatList[0]._id);
        } else {
          setCategory([]);
          setCatId("");
        }
      }
      if (state.categoryId !== null) {
        const type = (await getTypeList(state.categoryId)) as TypeDDL[];
        if (type === undefined) {
          setType([]);
          setTypeId("");
        } else {
          if (state.typeId !== null) {
            const newTypeList = updateList(type, state.typeId);
            setType(newTypeList as TypeDDL[]);
            setTypeId(newTypeList[0]._id);
          } else {
            setType([]);
            setTypeId("");
          }
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getOneMember();
    loadRouteMember();
    getOneMemAssign();
  }, [getOneMember]);

  const updateList = (
    list: DepartmentDDLType[] | CategoryDDL[] | TypeDDL[],
    id: string | undefined
  ) => {
    const newList = [...list];
    const record = newList?.find(
      (x: DepartmentDDLType | CategoryDDL | TypeDDL) => x?._id === id
    );
    const index = newList.indexOf(
      record as DepartmentDDLType | CategoryDDL | TypeDDL
    );
    const newArr = [
      record,
      ...newList.slice(0, index),
      ...newList.slice(index + 1),
    ];
    // newList.splice(index, 1);
    // newList.splice(0, 0, record as DepartmentDDLType | CategoryDDL | TypeDDL);
    return newArr as any;
  };

  const updateArealist = (list: AreaDDLType[], id: string) => {
    const newList = [...list];
    const record = newList?.find((x: AreaDDLType) => x?.pinCode === id);
    const index = newList.indexOf(record as AreaDDLType);
    newList.splice(index, 1);
    newList.splice(0, 0, record as AreaDDLType);
    return newList;
  };

  const depDropDown = async (y: string) => {
    setDepId(y);
    setCatId("");
    setTypeId("");
    if (y !== "") {
      const cat = await getCatList(y);
      if (cat === undefined) {
        setCategory([]);
        setType([]);
      } else {
        setCategory(cat as CategoryDDL[]);
        setType([]);
      }
    } else {
      setCategory([]);
      setType([]);
    }
  };
  const catDropDown = async (x: string) => {
    setCatId(x);
    setTypeId("");
    if (x !== "") {
      const typ = await getTypeList(x);
      if (typ === undefined) {
        setType([]);
      } else {
        setType(typ as TypeDDL[]);
      }
    } else {
      setType([]);
    }
  };
  const settingAreaName = async (pincode: string) => {
    const name = area.find((x) => {
      return x.pinCode === pincode;
    })?.areaName;
    setAreaName(name as string);
  };

  const loadRouteMember = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getRouteMemberByMember/${state._id}`
      );
      pathState.routeMember = res.data.result;
      setRouteMember(res.data.result);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const getOneMemAssign = async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getOneMemberAssignmentByMem/${state._id}`
      );
      const memAssigned = res.data.AssignedMember;
      pathState.memAssign = memAssigned;

      setMemAssign(memAssigned);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: member?.name,
      memberId: member?.memberId,
      dob: formatDateToAdd(member?.dob),
      contactNo: member?.contactNo,
      emergencyNo: member?.emergencyNo,
      email: member?.email,
      address: member?.address,
      bloodGroup: member?.bloodGroup,
      note: "",
      details: "",
      description: "",
      active: member?.isActive,
      isDeleted: false,
      memberphoto: member?.photo,
      password: member?.password,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Name is Too Short!")
        .max(30, "Name is Too Long!")
        .required("Member Name is Required"),
      memberId: Yup.string().min(1).max(30).required("Member ID is Required"),
      dob: Yup.string().required("Date Of Birth is Required"),
      contactNo: Yup.string()
        .min(10, "Contact Number is not valid")
        .max(10, "Contact Number is not valid")
        .required("Contact Number is Required"),
      emergencyNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Please enter a valid Emergency number")
        .min(10, "Emergency Number is not valid")
        .max(10, "Emergency Number is too long"),
      email: Yup.string().email().max(50, "Email is too long"),
      bloodGroup: Yup.string().matches(
        /^(A|B|AB|O)[-+]$/,
        "Blood Group Must Be A Valid Blood Group"
      ),
      address: Yup.string()
        .min(1, "Address is too Short!")
        .max(300, "Address is too Long!")
        .required("Address is Required"),
      memberphoto: Yup.mixed()
        .test("fileSize", "photo size is too high", (value) => {
          if (!value) return true; // Skip the test if no file is selected
          if (typeof value === "string") return true; // Skip the test for default file string
          return value && value.size <= 52428800; // 50MB in bytes
        })
        .test(
          "fileType",
          "Please enter the photo in JPG, JPEG, PNG extension.",
          (value) => {
            if (!value) return true; // Skip the test if no file is selected
            if (typeof value === "string") return true; // Skip the test for default file string
            return value && /(\.jpg|\.jpeg|\.png)$/i.test(value.name); // Check if the file has a valid extension
          }
        ),
    }),
    onSubmit: async ({
      name,
      memberphoto,
      memberId,
      dob,
      contactNo,
      emergencyNo,
      email,
      address,
      bloodGroup,
      active,
      isDeleted,
    }) => {
      if (
        depId === "" ||
        (category.length !== 0 && catId === "") ||
        (type.length !== 0 && typeId === "") ||
        pin === ""
      ) {
        return;
      }
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("departmentId", depId);
        formData.append("categoryId", catId);
        formData.append("typeId", typeId);
        formData.append("name", name || "");
        formData.append("memberphoto", memberphoto || ""); // Assuming 'photo' is the selected file object
        formData.append("memberId", memberId || "");
        formData.append(
          "dob",
          formatDate(member?.dob) === formik.values.dob
            ? formatDateToAdd(member?.dob)
            : dob
        );
        formData.append("contactNo", String(contactNo) || "");
        formData.append("emergencyNo", String(emergencyNo) || "");
        formData.append("email", email || "");
        formData.append("address", address || "");
        formData.append("bloodGroup", bloodGroup || "");
        formData.append("isActive", String(active));
        formData.append("isDeleted", String(isDeleted));
        formData.append("pinCode", String(pin));
        formData.append("area", areaName);
        formData.append("description", "");
        formData.append("note", "");
        formData.append("details", "");
        const res = await axiosInstance.put(
          `/master/updateMember/${member?._id}`,
          formData
        );
        const responseMessage = res.data.message;
        if (res.status === 202) {
          setLoading(false);
          warnToast(res.data);
        }
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          getOneMember();
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  return (
    <>
      {pathname !== "/memberMaster" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/memberMaster" />
        </div>
      )}
      <Tabs defaultIndex={Number(param.get("index"))}>
        <TabList>
          <Tab>Member</Tab>
          <Tab>Pickup Assignment</Tab>
          <Tab>Route Assignment</Tab>
          {rfidCardCheck && <Tab>RFDI Card</Tab>}
          <Tab>Fees</Tab>
          <Tab>Password</Tab>
          <Tab>Member User</Tab>
        </TabList>
        <TabPanel>
          <div className="formBg">
            <form
              className="row align-items-center "
              onSubmit={formik.handleSubmit}
            >
              <label htmlFor="department" className="col-md-6 mb-3">
                <span className="top-placeholder">Department*</span>

                <select
                  className="form-control"
                  id="department"
                  onChange={async (e) => {
                    depDropDown(e.target.value);
                  }}
                >
                  {department.map((item) => {
                    return (
                      <option key={item._id} value={item._id}>
                        {item.departmentName}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label htmlFor="category" className="col-md-6 mb-3">
                <span className="top-placeholder">
                  {category.length !== 0 ? "Category*" : "Category"}
                </span>

                <select
                  className={`form-control ${
                    category.length !== 0 && catId === "" ? "empty-form" : ""
                  }`}
                  id="category"
                  onChange={async (e) => {
                    catDropDown(e.target.value);
                  }}
                >
                  {catId === "" && <option value="">Select Category</option>}
                  {category.map((item) => {
                    return (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label htmlFor="type" className="col-md-6 mb-3">
                <span className="top-placeholder">
                  {type.length !== 0 ? "Sub Category*" : "Sub Category"}
                </span>

                <select
                  className={`form-control ${
                    type.length !== 0 && typeId === "" ? "empty-form" : ""
                  }`}
                  id="type"
                  onChange={(e) => {
                    setTypeId(e.target.value);
                  }}
                >
                  {typeId === "" && <option value="">Select Type</option>}
                  {type?.map((item) => {
                    return (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label htmlFor="area" className="col-md-6 mb-3">
                <span className="top-placeholder">Area*</span>

                <select
                  className="form-control"
                  onChange={async (e) => {
                    setPin(e.target.value);
                    settingAreaName(e.target.value);
                  }}
                >
                  {area.map(({ _id, areaName, pinCode }) => {
                    return (
                      <option key={_id} value={pinCode} id={areaName}>
                        {`${areaName} (${pinCode})`}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="col-md-6 mb-3" htmlFor="name">
                <input
                  className={`form-control ${
                    formik.errors.name ? "empty-form" : ""
                  }`}
                  id="name"
                  name="name"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  placeholder="Member Name"
                />

                <span className="top-placeholder">Member Name*</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="memberId">
                <input
                  className={`form-control ${
                    formik.errors.memberId ? "empty-form" : ""
                  }`}
                  id="memberId"
                  name="memberId"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.memberId}
                  placeholder="Member ID"
                />

                <span className="top-placeholder">Member ID*</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="dob">
                <input
                  className={`form-control ${
                    formik.errors.dob ? "empty-form" : ""
                  }`}
                  id="dob"
                  name="dob"
                  type="date"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  // onFocus={(e) => {
                  //   e.target.type = "date";
                  // }}
                  value={formik.values.dob}
                  placeholder="Date of Birth"
                />

                <span className="top-placeholder">Date of Birth*</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="contactNo">
                <input
                  className={`form-control ${
                    formik.errors.contactNo ? "empty-form" : ""
                  }`}
                  id="contactNo"
                  name="contactNo"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.contactNo}
                  placeholder="Contact Number"
                />

                <span className="top-placeholder">Contact Number*</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="emergencyNo">
                <input
                  className="form-control"
                  id="emergencyNo"
                  name="emergencyNo"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.emergencyNo}
                  placeholder="Emergency Number"
                />

                <span className="top-placeholder">Emergency Number</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="email">
                <input
                  className="form-control"
                  id="email"
                  name="email"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Email"
                />

                <span className="top-placeholder">Email</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="bloodGroup">
                <input
                  className="form-control"
                  id="bloodGroup"
                  name="bloodGroup"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.bloodGroup}
                  placeholder="Blood Group"
                />

                <span className="top-placeholder">Blood Group</span>
              </label>
              <div className="col-md-1">
                <i className="userImg">
                  <img
                    src={`${
                      member?.photo !== ""
                        ? memImage + member?.photo
                        : memImage + "Default.png"
                    }`}
                    alt="MemberPic"
                  />
                </i>
              </div>
              <label className="col-md-5 mb-3" htmlFor="memberphoto">
                <input
                  className="form-control uploadPhoto form-control-lg"
                  id="memberphoto"
                  name="memberphoto"
                  type="file"
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    const file =
                      event.currentTarget.files && event.currentTarget.files[0];
                    formik.setFieldValue("memberphoto", file || ""); // If file is null, set an empty string
                  }}
                  placeholder="Enter photo"
                />
                {/* <span className="top-placeholder">Photo</span> */}
              </label>
              <label className="col-md-12 mb-3" htmlFor="address">
                <textarea
                  className={`form-control ${
                    formik.errors.address ? "empty-form" : ""
                  }`}
                  id="address"
                  name="address"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  placeholder="Address"
                />

                <span className="top-placeholder">Address*</span>
              </label>
              <div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="active" className="custCheck">
                    Active
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      checked={formik.values.active}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="isDeleted" className="custCheck mt-3">
                    Delete
                    <input
                      type="checkbox"
                      id="isDeleted"
                      name="isDeleted"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked === true) {
                          setShow(true);
                        }
                      }}
                      checked={formik.values.isDeleted}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>

              {/* {isValid === 'selectLoc' && <ErrorToast error="Location is required" />} */}
              <div className="col-md-12 mb-3 text-center">
                <SubmitBtn
                  errors={formik.errors}
                  clickFunc={() => {
                    if (category.length !== 0 && catId === "") {
                      warnToast("Select Category");
                    }
                    if (type.length !== 0 && typeId === "") {
                      warnToast("Select Sub category");
                    }
                    toastValidation(formik.errors);
                  }}
                />
                <CancelBtn path=".." />
              </div>
            </form>
          </div>
          <Modal show={show}>
            <Modal.Body>
              Are you sure you want to delete this record?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setShow(false);
                }}
              >
                Ok
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  formik.setFieldValue("isDeleted", false);
                  setShow(false);
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </TabPanel>
        <TabPanel>
          <AssignmentEditForm state={{ memAssign, member: member }} />
        </TabPanel>
        <TabPanel>
          <RouteMemberEditForm
            state={{ routeMember, member: member, memAssign }}
          />
        </TabPanel>
        {rfidCardCheck && (
          <TabPanel>
            <CardMemberMappingAddForm update={member} />
          </TabPanel>
        )}
        <TabPanel>
          {/* <MemberFeesForm
            state={{ routeMember, member: state.item, memAssign }}
          /> */}
          <MemberDetailsFees state={{ member: member }} />
        </TabPanel>
        <TabPanel>
          <Password state={member} link={`/master/updateMember/${state._id}`} />
        </TabPanel>
        <TabPanel>
          <MemberUserList state={{ member: member }} />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default MemberEditForm;
