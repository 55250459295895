import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { AdminRequestDataType } from "../../../types/adminRequestTypes";
import AdminRequestListItem from "./requestListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import AddSearch from "components/AddSearchComponent";

type Props = {};

const AdminRequestList = (props: Props): JSX.Element => {
  const [requests, setRequests] = useState<AdminRequestDataType[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const loadRequests = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/admin/getAllAdminRequest?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newExp = res.data.AdminRequest[0].paginatedResults;
        if (searchKeyword !== "" && pageNum === 1) {
          if (newExp.length === 0) {
            setRequests([]);
            setSearchCount(0);
          }
        }
        if (newExp.length > 0) {
          setSearchCount(res.data.AdminRequest[0].totalCount[0].count);
          if (pageNum === 1) {
            setRequests(newExp);
          } else {
            setRequests((prevPln) => [...prevPln, ...newExp]);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [requests]
  );
  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadRequests(1, sort, search); // Trigger API call with updated search keyword
  };
  useEffect(() => {
    loadRequests(page, sort, searchQuery);
  }, [page, sort, ascDsc, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(requests, setPage, page, searchCount);
  };
  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Name"
      />
      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Name
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="email"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Email
              <span className="px-2">
                {sort === "email" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="contactNo"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Contact
              <span className="px-2">
                {sort === "contactNo" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="demoDate"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Demo Date
              <span className="px-2">
                {sort === "demoDate" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="Time"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Time
              <span className="px-2">
                {sort === "Time" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="status"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Status
              <span className="px-2">
                {sort === "status" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="latestCreatedAt"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Last Change
              <span className="px-2">
                {sort === "latestCreatedAt" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((x, index) => {
            return <AdminRequestListItem x={x} key={index} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(AdminRequestList);
