import { convertTo12HourFormat, dateFormatForAdmin } from "constants/Constants";
import React, { memo } from "react";
import { AdminRequestDataType } from "types/adminRequestTypes";
type Props = {
  x: AdminRequestDataType;
};

const DemoDone = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td className="py-4">{x.name}</td>
      <td className="py-4">{x.email}</td>
      <td className="py-4">{x.contactNo}</td>
      <td className="py-4">{dateFormatForAdmin(x.demoDate)}</td>
      <td className="py-4">{convertTo12HourFormat(x.Time)}</td>
      <td className="py-4">Done</td>
    </tr>
  );
};

export default memo(DemoDone);
