import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { BasicVehicleMaster } from "./List";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  item: BasicVehicleMaster;
};

const ConfigListItem = ({ item }: Props) => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit</Tooltip>;
  return (
    <tr key={item._id}>
      <td>{item.VehicleType}</td>
      <td>{item.LPNumber}</td>

      <td className="text-center">
        {item.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            style={{ cursor: "pointer" }}
            id={item._id}
            onClick={() => {
              nav("edit", {
                state: item,
              });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(ConfigListItem);
