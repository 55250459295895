import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { FeesAreaType } from "types/feesAreaTypes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { AreaDDLType } from "types/areaMasterTypes";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";

type Props = {};

const LocationEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [param] = useSearchParams();

  const [feesArea, setFeesArea] = useState<FeesAreaType>();

  const getOneFeesArea = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getOneFeesArea/${state.areaEditId}`
      );
      setFeesArea(res.data.result);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getOneFeesArea();
  }, [getOneFeesArea]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: feesArea?.amount,
      active: feesArea?.isActive,
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required("Amount is Required"),
    }),
    onSubmit: async ({ amount, active }) => {
      try {
        setLoading(true);
        const formData = {
          amount: String(amount),
          isActive: active,
          description: "",
          note: "",
          details: "",
        };
        const res = await axiosInstance.put(
          `/master/updateFeesArea/${feesArea?._id}`,
          formData
        );
        if (res.data === "Ok") {
          successToast();
          setLoading(false);
          const item = state;
          if (Number(param.get("fromEdit")) === 1) {
            nav(
              {
                pathname: "/transportationFeesMain/feesSectionEdit",
                search: "?index=2",
              },
              { state: item }
            );
          } else {
            nav(
              {
                pathname: "/transportationFeesMain/feesSection/areaList",
                search: "?index=2",
              },
              { state: item }
            );
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <ul className="profileList">
              <li>
                <div className="d-flex align-items-center ">
                  <span>Area :</span>
                  <p className="m-0"> {state.area}</p>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center ">
                  <span>Division :</span>
                  <p className="m-0">{state.areaDiv}</p>
                </div>
              </li>
            </ul>
            <label className="col-md-6 mb-3" htmlFor="amount">
              {formik.values.amount && (
                <span className="top-placeholder">Amount</span>
              )}
              <input
                className="form-control"
                id="amount"
                name="amount"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.amount}
                placeholder="Amount"
              />
            </label>
            <div className="col-md-6 mb-3">
              <label htmlFor="active" className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                // if (divId === "") warnToast("Select area division");
                toastValidation(formik.errors);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                const item = state;
                if (Number(param.get("fromEdit")) === 1) {
                  nav(
                    {
                      pathname: "/transportationFeesMain/feesSectionEdit",
                      search: "?index=2",
                    },
                    { state: item }
                  );
                } else {
                  nav(
                    {
                      pathname: "/transportationFeesMain/feesSection/areaList",
                      search: "?index=2",
                    },
                    { state: item }
                  );
                }
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(LocationEditForm);
