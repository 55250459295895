/* eslint-disable no-restricted-globals */
import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import React, { useState, useEffect } from "react";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { AreaDivListType } from "types/areaDivisionTypes";
import DivisionListItems from "./DivisionListItems";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import { useAreaEdit } from "Context/AreaEditContext";
type Props = {};

const DivisionList = ({}: Props) => {
  const { area } = useAreaEdit();
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("areaCode");
  const [searchCount, setSearchCount] = useState(0);

  const [division, setDivision] = useState<AreaDivListType[]>([]);
  const { notfound, setNotFound } = useNotFound();

  const loadDivisions = async (pageNum: number, sortParam: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getAllAreaDivision?page=${pageNum}&sort=${sortParam}&pin=${area?.pinCode}`
      );
      setSearchCount(res.data.SearchCount);
      const divisionList: AreaDivListType[] = [
        ...division,
        ...res.data.divisions,
      ];
      setDivision(divisionList);
      setLoading(false);
      setNotFound(false);
    } catch (error) {
      setNotFound(true);
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDivisions(page, sort);
  }, [page, sort, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(division, setPage, page, searchCount);
  };

  return (
    <>
      {/* {location.pathname === "/areaMaster/editForm/divEdit" ? (
        <Outlet />
      ) : ( */}
      <>
        {/* <AddSearch
          addLink="/areaMaster/addForm"
          handleSearch={() => {}}
          placeholder=""
          search=""
          setSearch={() => {}}
          state={{ index: 1 }}
        /> */}
        <table className="table">
          <thead>
            <tr>
              <th
                id="areaCode"
                role="button"
                onClick={(e) => {
                  sortData(ascDsc, setAscDsc, e, setSort, setDivision, setPage);
                }}
              >
                Area Division
                {!ascDsc ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </th>
              <th
                id="area"
                role="button"
                onClick={(e) => {
                  sortData(ascDsc, setAscDsc, e, setSort, setDivision, setPage);
                }}
              >
                Area
                {!ascDsc ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </th>
              <th
                id="pinCode"
                role="button"
                onClick={(e) => {
                  sortData(ascDsc, setAscDsc, e, setSort, setDivision, setPage);
                }}
              >
                PIN Code
                {!ascDsc ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </th>
              <th className="text-center">Active</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          {!notfound && (
            <tbody>
              {division?.map((item) => {
                return (
                  <DivisionListItems item={item} key={item._id} area={area} />
                );
              })}
            </tbody>
          )}
        </table>
        {notfound && <NotFound />}
      </>
      {/* )} */}
    </>
  );
};

export default DivisionList;
