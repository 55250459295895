import { useLoading } from "Context/LoadingContext";
import React from "react";
import SweetAlert2 from "react-sweetalert2";

const Loading = () => {
  const { loading } = useLoading();

  const swalProps = {
    showConfirmButton: false,
    width: "18rem",
    allowOutsideClick: false,
    background: "transparent",
    imageUrl: "https://media.tenor.com/Nzkl6kzykuMAAAAi/animation-logo.gif",
  };

  return (
    <>
      {loading ? (
        <SweetAlert2 show={true} {...swalProps} />
      ) : (
        <SweetAlert2 show={false} {...swalProps} />
      )}
    </>
  );
};

export default Loading;
