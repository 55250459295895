import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  useLoadScript,
} from "@react-google-maps/api";

type center = {
  lat: number;
  lng: number;
};

const containerStyle = {
  width: "100%",
  height: "100vh",
};
const Map: React.FC = () => {
  const [response, setResponse] = useState<google.maps.DirectionsResult | null>(
    null
  );
  const mapRef = useRef<google.maps.Map | null>(null);
  const [directions, setDirections] =
    useState<google.maps.DirectionsRenderer | null>(null);
  const { REACT_APP_GOOGLE_API_KEY } = process.env;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_API_KEY as string,
    libraries: ["places"],
  });
  const LATITUDE_1 = 23.2128943;
  const LATITUDE_2 = 23.2128943;
  const LATITUDE_3 = 20.1639;
  const LATITUDE_4 = 19.1639;
  const LATITUDE_5 = 19.1639;

  const LONGITUDE_1 = 72.5853412;
  const LONGITUDE_2 = 72.6299;
  const LONGITUDE_3 = 73.1084;
  const LONGITUDE_4 = 73.6084;
  const LONGITUDE_5 = 74.1084;

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };
  useEffect(() => {
    if (!isLoaded) return;
    else if (!mapRef.current) return;

    const locations: google.maps.DirectionsWaypoint[] = [
      { location: { lat: 37.7749, lng: -122.4194 } }, // San Francisco, CA, USA
      { location: { lat: 34.0522, lng: -118.2437 } }, // Los Angeles, CA, USA
      { location: { lat: 32.7157, lng: -117.1611 } }, // San Diego, CA, USA
    ];
    // [
    //   { location: { lat: LATITUDE_1, lng: LONGITUDE_1 } },
    //   { location: { lat: LATITUDE_2, lng: LONGITUDE_2 } },
    //   { location: { lat: LATITUDE_3, lng: LONGITUDE_3 } },
    //   { location: { lat: LATITUDE_4, lng: LONGITUDE_4 } },
    //   { location: { lat: LATITUDE_5, lng: LONGITUDE_5 } },
    // ];
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        destination: locations[locations.length - 1]
          .location as google.maps.LatLng,
        origin: locations[0].location as google.maps.LatLng,
        waypoints: locations.slice(1, locations.length - 1),
        travelMode: "DRIVING" as google.maps.TravelMode,
      },
      (result, status) => {
        console.log(status);
        console.log(result);
        if (status === "OK") {
          setResponse(result);
        }
      }
    );
    new google.maps.Marker({
      position: locations[0].location as google.maps.LatLng,
      map: mapRef.current,
      title: "Start Location",
    });

    new google.maps.Marker({
      position: locations[locations.length - 1].location as google.maps.LatLng,
      map: mapRef.current,
      title: "End Location",
    });
  }, [isLoaded, mapRef.current]);

  useEffect(() => {
    if (response) {
      const directionsRenderer = new google.maps.DirectionsRenderer();
      directionsRenderer.setDirections(response);
      setDirections(directionsRenderer);
    }
  }, [response]);
  const center: center = {
    lat: 23.24,
    lng: 72.6381,
  };

  if (!isLoaded) return null;
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={12}
      center={center}
      onLoad={onLoad}
    >
      {mapRef.current && directions && (
        <DirectionsRenderer
          options={{ suppressMarkers: true, map: mapRef.current }}
          directions={response as google.maps.DirectionsResult}
        />
      )}
    </GoogleMap>
  );
};

export default Map;
