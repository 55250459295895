import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { axiosAuth } from "axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import ErrorToast from "components/ValidationToast/errorToast";
type Props = {};

const SignUp = (props: Props) => {
  const [resStatus, setResStatus] = useState<Number>();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().min(2, "Too Short!").email().required("Required"),
      name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      password: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async ({ name, password, email }) => {
      const formData = {
        email,
        name,
        password,
        contactNo: "",
        businessType: "",
        companyName: "",
        address: "",
        postCode: "",
        description: "",
        note: "",
        details: "",
        roleCode: "ORG",
      };
      await axiosAuth
        .post("/master/organizationSignup", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setResStatus(res.status);
          }
          if (res.status === 201) {
            navigate("/login");
            setResStatus(201);
          }
        })
        .catch((err) => {
          setResStatus(500);
        });
    },
  });

  return (
    <section className="login min-vh-100">
      <div className="loginBox">
        <Link to={"/"} className="mb-2 text-center logowrap">
          <Logo width={"140px"} />
        </Link>

        <form onSubmit={formik.handleSubmit}>
          <label htmlFor="name">
            Name
            <input
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              className="form-control"
              type="text"
              name="name"
            />
          </label>
          {formik.errors.name && formik.touched.name && (
            <ErrorToast error={formik.errors.name} />
          )}

          <label htmlFor="email">
            Email Address
            <input
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              className="form-control bg-transparent ps-4"
              style={{ height: "50px" }}
              type="email"
              name="email"
            />
          </label>
          {formik.errors.email && formik.touched.email && (
            <ErrorToast error={formik.errors.email} />
          )}

          <label htmlFor="password">
            Password
            <input
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              className="form-control bg-transparent ps-4"
              style={{ height: "50px" }}
              type="password"
              name="password"
            />
          </label>
          {formik.errors.password && formik.touched.password && (
            <ErrorToast error={formik.errors.password} />
          )}
          <label htmlFor="confirmPassword">
            Confirm Password
            <input
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              className="form-control bg-transparent ps-4"
              style={{ height: "50px" }}
              type="password"
              name="confirmPassword"
            />
          </label>
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <ErrorToast error={formik.errors.confirmPassword} />
          )}

          <label className="text-center">
            <button className="btn btn-primary " type="submit">
              SignUp
            </button>
          </label>
          <label className="text-center">
            <Link to={"/login"}>Login</Link>? if already have an account
          </label>
        </form>
        {resStatus === 200 && (
          <ErrorToast error={"Username already exists in our app"} />
        )}
        {resStatus === 500 && <ErrorToast error={"Server Error"} />}
      </div>
    </section>
  );
};

export default SignUp;
