import React, { memo } from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { DriverConfiguration } from "types/DriverConfigDataType";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
type Props = {
  item: DriverConfiguration;
};

const ConfigListItems = ({ item }: Props) => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  return (
    <tr key={item._id}>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.contact}</td>
      <td className="text-center">{item.isActive ? <Eye /> : <EyeOff />}</td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            id={item._id}
            onClick={() => {
              nav("editForm", {
                state: { item },
              });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(ConfigListItems);
