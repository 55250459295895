import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo, useMemo } from "react";
import { useLoading } from "Context/LoadingContext";
import { useTable, usePagination } from "react-table";
import "../../../assets/styles/style.css";
import {
  downloadTableAsPDF,
  downloadTableAsPrint,
  exportToExcel,
  formatDateForFile,
} from "constants/Constants";
import NotFound from "components/NotFound";
import { downloadExcel } from "constants/ReportFun";

type Props = {};

const VehiclePucReportList = (props: Props): JSX.Element => {
  const [data, setData] = useState([]);
  const [notFound, setNotFound] = useState<boolean>(false);
  const { setLoading } = useLoading();

  const loadReports = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/report/vehiclePucReport`);
      setData(res.data.resp);
      setLoading(false);
      setNotFound(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setNotFound(true);
    }
  }, [data]);

  useEffect(() => {
    loadReports();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "VehicleType",
        accessor: "vehicleType",
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
      },
      {
        Header: "PUC Valid From",
        accessor: "slicedDate1",
      },
      {
        Header: "PUC Valid To",
        accessor: "slicedDate",
      },
    ],
    []
  );

  const download = async () => {
    try {
      downloadExcel(
        [
          {
            id: "vehicleType",
            name: "VehicleType",
          },
          {
            id: "vehicleNo",
            name: "Vehicle No",
          },
          {
            id: "slicedDate1",
            name: "PUC Valid From",
          },
          {
            id: "slicedDate",
            name: "PUC Valid To",
          },
        ],
        data === undefined ? [] : data,
        "Expired_PUC",
        "expired_PUC"
      );
    } catch (error) {
      return {};
    }
  };
  const todayDate = new Date();
  const dateForFile = formatDateForFile(todayDate.toISOString());
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as any,
    },
    usePagination
  );

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Expired PUC Report</span>
      </div>
      <button
        onClick={() =>
          downloadTableAsPDF(columns, data, `Expired_PUC-${dateForFile}.pdf`)
        }
        className="btn btn-primary mb-4"
      >
        Export to PDF
      </button>
      <button
        onClick={() => {
          download();
        }}
        className="btn btn-primary mb-4"
      >
        Export to Excel
      </button>
      <button
        onClick={() => downloadTableAsPrint(columns, data)}
        className="btn btn-primary mb-4"
      >
        Print
      </button>
      <table {...getTableProps()} id="report-table" className="tableForReport">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="trForReport">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="thForReport">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="trForReport">
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()} className="tdForReport">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {notFound && (
        <div className="mt-4">
          {" "}
          <NotFound />
        </div>
      )}
      <div className="pagination m-4 gap-2">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">>"}
        </button>{" "}
        <span className="mx-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(VehiclePucReportList);
