import React, { memo, useEffect, useState } from "react";
import { useFormik } from "formik";
import axiosInstance from "axiosInstance";
import { Link } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { successToast } from "constants/toastConfig";

type PaymentSettingType = {
  _id: string;
  organizerId: string;
  payTypeId: {
    _id: string;
    name: string;
    value: string;
  };
  isActive: boolean;
  isDeleted: boolean;
};

const PaymentSetting: React.FC<{}> = (props) => {
  const { setLoading } = useLoading();
  const [cheque, setCheque] = useState<PaymentSettingType>();
  const [cash, setCash] = useState<PaymentSettingType>();
  const [online, setOnline] = useState<PaymentSettingType>();

  const fetchData = async () => {
    try {
      const resp = await axiosInstance.get("/master/getallPaymentSetting");
      const x = resp.data?.paymentSettings as PaymentSettingType[];
      const chequeData = x.find((y) => y.payTypeId?.name === "Cheque");
      setCheque(chequeData);
      const cashData = x.find((y) => y.payTypeId?.name === "Cash");
      setCash(cashData);
      const onlineData = x.find((y) => y.payTypeId?.name === "Online");
      setOnline(onlineData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Cheque: cheque?.isActive,
      Cash: cash?.isActive,
      Online: online?.isActive,
    },
    onSubmit: async ({ Cheque, Cash, Online }) => {
      try {
        setLoading(true);
        if (Cheque !== cheque?.isActive) {
          await axiosInstance.put(
            `/master/updatePaymentSetting/${cheque?._id}`,
            {
              isActive: Boolean(Cheque),
            }
          );
        }
        if (Cash !== cash?.isActive) {
          await axiosInstance.put(`/master/updatePaymentSetting/${cash?._id}`, {
            isActive: Boolean(Cash),
          });
        }
        if (Online !== online?.isActive) {
          await axiosInstance.put(
            `/master/updatePaymentSetting/${online?._id}`,
            {
              isActive: Boolean(Online),
            }
          );
        }
        successToast();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Payment Setting</span>
      </div>

      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column">
            <label
              className="col-md-6 mb-3 d-flex gap-5 p-3"
              htmlFor="paymentSetting"
            >
              <span>Select your preferred payment types:</span>

              <div className="d-flex flex-column gap-4">
                <label htmlFor={cheque?.payTypeId.name} className="custCheck">
                  {cheque ? cheque?.payTypeId.name : "Option"}
                  <input
                    type="checkbox"
                    id={cheque?.payTypeId.name}
                    name={cheque?.payTypeId.name}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    checked={formik.values.Cheque}
                  />
                  <span className="checkmark"></span>
                </label>
                <label htmlFor={cash?.payTypeId.name} className="custCheck">
                  {cash ? cash?.payTypeId.name : "Option"}
                  <input
                    type="checkbox"
                    id={cash?.payTypeId.name}
                    name={cash?.payTypeId.name}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    checked={formik.values.Cash}
                  />
                  <span className="checkmark"></span>
                </label>
                <label htmlFor={online?.payTypeId.name} className="custCheck">
                  {online ? online?.payTypeId.name : "Option"}
                  <input
                    type="checkbox"
                    id={online?.payTypeId.name}
                    name={online?.payTypeId.name}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    checked={formik.values.Online}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </label>
          </div>

          <div className="col-md-12 mb-3 text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <Link to={"../"}>
              <button className="btn btn-primary" type="submit">
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(PaymentSetting);
