import { useLoading } from "Context/LoadingContext";
import { useNotFound } from "Context/NotFound";
import axiosInstance from "axiosInstance";
import NotFound from "components/NotFound";
import { formatDate, currencySymbol } from "constants/Constants";
import { useState, useEffect, useCallback } from "react";
import toastValidation, { successToast } from "constants/toastConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import CancelBtn from "components/Buttons/Cancel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {};

const BasicMemberFeeList = (props: Props) => {
  const [feesConfig, setFeesConfig] = useState<any>([]);
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const { notfound, setNotFound } = useNotFound();
  const [payType, setPayType] = useState("cash");
  const [payDetails, setPayDetails] = useState({
    refId: "",
    // Bank: "",
  });
  const [showPay, setShowPay] = useState(false);
  const [pendingFees, setPendingFees] = useState(false);
  const [detailId, setDetailId] = useState("");
  const [detailFees, setDetailFees] = useState("");
  const [refNo, setRefNo] = useState("");
  const [border, setBorder] = useState<boolean>(false);

  const handleClosePay = () => setShowPay(false);

  const loadFeesConfig = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getMemberFeeDetails/${state.memberId}`
      );
      const result = res.data.result;
      setFeesConfig(result);
      setLoading(false);
      result[0].memberPaidFees.length === 0
        ? setNotFound(true)
        : setNotFound(false);
    } catch (error) {
      setLoading(false);
      setNotFound(true);
      console.log(error);
    }
  }, [feesConfig]);

  const validationObjCheque = {
    amount: Yup.string()
      // .matches(/^(0|[1-9][0-9]*)$/, "Only numbers are allowed")
      .required("Amount is required"),
    chequeRefId: Yup.string().required("please enter cheque reference id"),
  };
  const validationObjCash = {
    amount: Yup.string()
      // .matches(/^(0|[1-9][0-9]*)$/, "Only numbers are allowed")
      .required("Amount is required"),
  };

  const formikPay = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: detailFees,
      chequeRefId: refNo ? refNo : "",
    },
    validationSchema: Yup.object().shape(
      payType === "cheque" ? validationObjCheque : validationObjCash
    ),
    onSubmit: async ({ amount, chequeRefId }) => {
      setLoading(true);
      try {
        if (payType === "cash") {
          const formData = {
            // feesConfigId: state.feesConfigId,
            // memberId: state.memberId,
            paidFees: formikPay.values.amount,
            referenceNo: "",
            paymentMode: "cash",
          };
          const res = await axiosInstance.put(
            `/master/updateBasicPayment/${detailId}`,
            formData
          );
          const responseMessage = res.data.message;
          if (responseMessage === "Ok") {
            successToast();
            setShowPay(false);
            setLoading(false);
            loadFeesConfig();
          }
        } else {
          const formData = {
            // feesConfigId: state.feesConfigId,
            // memberId: state.memberId,
            paidFees: formikPay.values.amount,
            referenceNo: chequeRefId,
            paymentMode: "cheque",
          };
          const res = await axiosInstance.put(
            `/master/updateBasicPayment/${detailId}`,
            formData
          );
          const responseMessage = res.data.message;
          if (responseMessage === "Ok") {
            successToast();
            setShowPay(false);
            setLoading(false);
            loadFeesConfig();
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  function toggleCheckbox(extSetting: string) {
    if (extSetting !== null) {
      const type = extSetting.toLowerCase();
      const checkbox: any = document.getElementById(type);
      checkbox.checked = true;
    }
  }

  useEffect(() => {
    loadFeesConfig();
  }, []);

  const handleFeesChange = (e: any) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, "");
    value = value.replace(/(\..*?)\..*/g, "$1");
    value = value.replace(/^(\d*\.\d{0,2}).*/g, "$1");
    formikPay.setFieldValue("amount", value);
  };

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Payment</span>
      </div>
      <div className="text-end mb-3">
        <CancelBtn btnTxt="Back" path="/paymentMasterBasic" />
      </div>
      <table className="table">
        <thead>
          <tr>
            {/* <th id="memberName" role="button">
              Member Name
            </th> */}
            <th id="fee" role="button">
              Total Fees
            </th>
            <th>Pay Type</th>
            <th>Paid Fees</th>
            <th>Paid Date</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {feesConfig[0]?.memberPaidFees?.map((x: any, index: number) => {
              const isLastItem = index === 0;
              const isCashOrCheque =
                x.paymentMode.toLowerCase() === "cash" ||
                x.paymentMode.toLowerCase() === "cheque";
              const paid = feesConfig[0]?.pendingFees <= 0;

              let cond;

              if (isCashOrCheque && !paid) {
                cond = isCashOrCheque && !paid;
              } else {
                cond = isLastItem && isCashOrCheque;
              }

              return (
                <tr key={x._id}>
                  <td>
                    {currencySymbol}
                    {x?.memberFees}
                  </td>
                  <td>{x?.paymentMode}</td>
                  <td>
                    {currencySymbol}
                    {x?.paidFees}
                  </td>
                  <td>{x?.createdAt ? formatDate(x?.createdAt) : ""}</td>
                  {cond ? (
                    <td className={"text-center pointer"}>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id={`tooltip-${x._id}`}>Edit</Tooltip>
                        }
                      >
                        <Edit
                          id={x._id}
                          onClick={() => {
                            setDetailId(x._id);
                            setDetailFees(x.paidFees);
                            setPayType(x.paymentMode);
                            setRefNo(x.referenceNo);
                            setShowPay(true);
                          }}
                        />
                      </OverlayTrigger>
                    </td>
                  ) : (
                    <td className={"text-center"}>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id={`tooltip-${x._id}`}>Disable</Tooltip>
                        }
                      >
                        <Edit
                          id={x._id}
                          className="disabled"
                          style={{ cursor: "not-allowed" }}
                        />
                      </OverlayTrigger>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
      <Modal
        show={showPay}
        onHide={handleClosePay}
        onShow={() => toggleCheckbox(payType)}
      >
        <Modal.Body>
          {pendingFees
            ? "Fill the details for payment."
            : "Fill the details you want to update."}
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <form action="" onSubmit={formikPay.handleSubmit}>
            <label className="col-md-12 mb-3" htmlFor="amount">
              <input
                className={`form-control ${
                  formikPay.errors.amount && border ? "empty-form" : ""
                }`}
                id="amount"
                name="amount"
                type="text"
                onBlur={formikPay.handleBlur}
                onChange={handleFeesChange}
                value={formikPay.values.amount}
                placeholder="Enter Amount"
              />
              <span className="top-placeholder">Amount*</span>
            </label>

            {payType === "cheque" && (
              <label className="col-md-12 mb-3" htmlFor="chequeRefId">
                <input
                  className={`form-control ${
                    formikPay.errors.chequeRefId && border ? "empty-form" : ""
                  }`}
                  id="chequeRefId"
                  name="chequeRefId"
                  type="text"
                  onBlur={formikPay.handleBlur}
                  onChange={(e) => {
                    toastValidation(formikPay.errors);
                    formikPay.handleChange(e);
                  }}
                  value={formikPay.values.chequeRefId}
                  placeholder="Cheque Reference ID"
                />
                <span className="top-placeholder">Cheque Reference ID*</span>
              </label>
            )}
            <Form.Check
              type="radio"
              label="Cash"
              id="cash"
              className="mb-3"
              style={{
                cursor: "pointer",
              }}
              name="payType"
              onChange={(e: any) => {
                if (e.target.id === "cash") {
                  setPayDetails({
                    refId: "",
                    // Bank: "",
                  });
                }
                setPayType(e.target.id);
              }}
            />
            <Form.Check
              type="radio"
              label="Cheque"
              id="cheque"
              className="mb-3"
              style={{
                cursor: "pointer",
              }}
              name="payType"
              onChange={(e: any) => {
                setPayType(e.target.id);
              }}
            />
            <div className="text-center">
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  if (Object.keys(formikPay.errors).length !== 0) {
                    setBorder(true);
                    toastValidation(formikPay.errors);
                  } else {
                    setShowPay(false);
                  }
                }}
              >
                Submit
              </Button>
              <Button variant="secondary" onClick={handleClosePay}>
                Close
              </Button>
            </div>
          </form>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BasicMemberFeeList;
