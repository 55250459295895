import React, { memo } from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { AreaDivListType } from "types/areaDivisionTypes";
import { AreaDDLType } from "types/areaMasterTypes";
import { useAreaEdit } from "Context/AreaEditContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  item: AreaDivListType;
  area: AreaDDLType[];
};

const DivisionListItems = ({ item, area }: Props) => {
  const nav = useNavigate();
  const { setDivision } = useAreaEdit();

  const handleEditClick = () => {
    setDivision(item);
    nav("divEdit", {
      state: { item },
    });
  };

  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;

  return (
    <tr key={item._id}>
      <td>{item.areaCode}</td>
      <td>{item.area}</td>
      <td>{item.pinCode}</td>
      <td className="text-center">{item.isActive ? <Eye /> : <EyeOff />}</td>
      <td className="text-center pointer">
        {/* <Edit
          id={item._id}
          onClick={() => {
            setDivision(item);
            nav("divEdit", {
              state: { item },
            });
          }}
        /> */}
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={item._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(DivisionListItems);
