import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { useLoading } from "Context/LoadingContext";
import { MemberUserType } from "types/memberUserTypes";
import MemberUserListItem from "./memberUserListItem";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import { successToast } from "constants/toastConfig";

type Props = {
  state: any;
};

const MemberUserList = ({ state }: Props): JSX.Element => {
  const [memberUser, setMemberUser] = useState<MemberUserType[]>([]);
  const { notfound, setNotFound } = useNotFound();
  const { setLoading } = useLoading();

  const loadMemberUserList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getAllMemberUserslist/${state.member._id}`
      );
      setMemberUser(res.data.memberUsers);
      setNotFound(false);
    } catch (error) {
      setNotFound(true);
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  }, [memberUser]);

  useEffect(() => {
    loadMemberUserList();
  }, []);

  const deleteUser = async (x: MemberUserType) => {
    setLoading(true);
    try {
      const formData = {
        isActive: false,
        isDeleted: true,
      };
      const res = await axiosInstance.put(
        `/master/deleteOneMemberUser/${x._id}`,
        formData
      );
      const responseMessage = res.data;
      if (responseMessage === "Ok") {
        setLoading(false);
        successToast();
        loadMemberUserList();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Mobile No</th>
            <th>Relation Name</th>
            <th>Created Date</th>
            <th className="text-center">Verified</th>
            <th className="text-center">Active</th>
            <th className="text-center">Deleted</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {memberUser?.map((item) => {
              return (
                <MemberUserListItem
                  item={item}
                  key={Math.random()}
                  deleteUser={deleteUser}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(MemberUserList);
