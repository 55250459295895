import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import {
  MemberAssignmentDataType,
  MemberAssignmentListType,
} from "types/memberAssignment";
import { Button, Modal } from "react-bootstrap";
import axiosInstance from "axiosInstance";

type Props = {
  x: MemberAssignmentListType;
};

const AssignmentListItems = ({ x }: Props) => {
  const nav = useNavigate();
  // const [show, setShow] = useState(false)
  // const [memAssignId, setMemAssignId] = useState<
  //   MemberAssignmentDataType['_id']
  // >()
  // const handleClose = () => setShow(false)

  // const activeIt = async () => {
  //   try {
  //     const res = await axiosInstance.get(
  //       `/master/getOneMemberAssignment/${memAssignId}`,
  //     )
  //     const memAssign = res.data.AssignedMember
  //     if (memAssign?.isActive) {
  //       const formData = {
  //         isActive: false,
  //       }
  //       await axiosInstance.put(
  //         `/master/updateMemberAssignment/${memAssignId}`,
  //         formData,
  //       )
  //       window.location.reload()
  //     } else {
  //       const formData = {
  //         isActive: true,
  //       }
  //       await axiosInstance.put(
  //         `/master/updateMemberAssignment/${memAssignId}`,
  //         formData,
  //       )
  //       window.location.reload()
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  return (
    <>
      <tr key={x._id}>
        <td>{x.memberName}</td>
        <td>{x.memberId}</td>
        <td>{`${x.memLoc},${x.memArea},${x.memPin}`}</td>
        <td>{`${x.pickUpLoc},${x.pickUpDiv},${x.pickUpArea}`}</td>
        <td>{`${x.dropLoc},${x.dropDiv},${x.dropArea}`}</td>
        <td className="text-center">
          {x.isActive === true ? <Eye /> : <EyeOff />}
        </td>
        <td className="text-center pointer">
          <Edit
            id={x._id}
            onClick={() => {
              nav("editForm", {
                state: { x },
              });
            }}
          />
          {/* <button
            id={x._id}
            type="button"
            onClick={(e) => {
              setShow(true)
              setMemAssignId(e.currentTarget.id)
            }}
          >
            AC/DC
          </button> */}
        </td>
      </tr>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          {!x.isActive
            ? `Are you sure you want to activate this record ?`
            : `Are you sure you want to De-Activate this record ?`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false)
              activeIt()
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default memo(AssignmentListItems);
