import { memo } from "react";
import { AdminExpirePlanDataType } from "types/adminExpirePlanTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";

type Props = {
  x: AdminExpirePlanDataType;
  reminder: (item: AdminExpirePlanDataType) => void;
};

const AdminExpirePlanListItem = ({ x, reminder }: Props): JSX.Element => {
 
  return (
    <tr key={x._id}>
      <td>{x.name}</td>
      <td>{x.bussinessName}</td>
      <td>{x.planName}</td>
      <td>{x.planExpired}</td>
      <td>{x.day}</td>
      <td className="text-center">{x.reminder}</td>
      <td className="text-center">{x.lastRemindDate === null ? "__" : x.lastRemindDate}</td>
      <td className="text-center">
        <Edit className="pointer"
          onClick={()=>{reminder(x)
          }}
        />
      </td>
     </tr>
  );
};

export default memo(AdminExpirePlanListItem);
