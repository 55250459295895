import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { sosDataTypes } from "types/sosTypes";
import SosListItem from "./SosListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import AddSearch from "components/AddSearchComponent";

type Props = {};

const SosList = (props: Props): JSX.Element => {
  const [sosData, setSosData] = useState<sosDataTypes[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("memberName");
  const [searchCount, setSearchCount] = useState(0);
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const loadSos = useCallback(
    async (pageNum: number, sort: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `master/getAllSosList?page=${pageNum}&sort=${sort}&search=${searchKeyword}`
        );
        const sosList: sosDataTypes[] = [
          ...res.data.sosMemberList[0].paginatedResults,
        ];

        setNotFound(true);
        if (searchKeyword !== "" && pageNum === 1) {
          if (sosList.length === 0) {
            setSosData([]);
            setSearchCount(0);
          }
        }

        if (sosList.length > 0) {
          setSearchCount(res.data.sosMemberList[0].totalCount[0].count);
          if (pageNum === 1) {
            setSosData(sosList);
          } else {
            setSosData((prevList) => [...prevList, ...sosList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [sosData]
  );

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    loadSos(1, sort, search);
  };

  useEffect(() => {
    loadSos(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(sosData, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Member Name Member Driver Name"
      />

      <table className="table">
        <thead>
          <tr>
            <th
              id="memberName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setSosData, setPage);
              }}
            >
              Member Name
              {sort === "memberName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>

            <th
              id="memberUsersName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setSosData, setPage);
              }}
            >
              Member Users Name
              {sort === "memberUsersName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="memberDriverName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setSosData, setPage);
              }}
            >
              Member Driver Name
              {sort === "memberDriverName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="memberRouteName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setSosData, setPage);
              }}
            >
              Member Route Name
              {sort === "memberRouteName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setSosData, setPage);
              }}
            >
              Date
              {sort === "createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setSosData, setPage);
              }}
            >
              Time
              {sort === "createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {sosData?.map((x) => {
              return <SosListItem x={x} key={x._id} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(SosList);
