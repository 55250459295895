import ExcelJS from "exceljs";
import {
  formatDateForFile,
  formatMongoDBDateToTime,
  formatDates,
  convertTo12HourFormat,
  formatDate,
  formatTime,
  formatDateWithNull,
} from "./Constants";

export const downloadExcel = (
  headCells: {
    id: string;
    name: string;
    isTime?: boolean;
    isDate?: boolean;
    isLongDateWithTime?: boolean;
    isBooleanWait?: boolean;
    isComplaint?: boolean;
    isDriverLeaveCount?: boolean;
    isMapStatus?: boolean;
  }[],
  rows: any[],
  fileName: string,
  sheetName: string
) => {
  if (rows.length === 0) {
    // toast('info', 'Nothing to Export')
  } else {
    const data = rows.map((x, i) => {
      const dataX: any = {};

      for (let j = 0; j < headCells.length; j++) {
        const element = headCells[j];
        if (element.isDate && element.isTime) {
          dataX[element.id] = x[element.id]
            ? `${formatDates(x[element.id])} | ${formatMongoDBDateToTime(
                x[element.id]
              )}`
            : "";
        } else if (element.isDate) {
          dataX[element.id] = x[element.id] ? formatDate(x[element.id]) : "";
        } else if (element.isTime) {
          dataX[element.id] = x[element.id]
            ? convertTo12HourFormat(x[element.id])
            : "";
        } else if (element.isLongDateWithTime) {
          dataX[element.id] = x[element.id] ? formatTime(x[element.id]) : "";
        } else if (typeof element.isBooleanWait === "boolean") {
          dataX[element.id] = x[element.id] ? "Pickup Off" : "Drop Off";
        } else if (typeof element.isComplaint === "boolean") {
          dataX[element.id] = x[element.id] === 1 ? "Replied" : "New";
        } else if (typeof element.isMapStatus === "boolean") {
          dataX[element.id] = x[element.id] ? "ON" : "OFF";
        } else {
          if (typeof x[element.id] === "number") {
            dataX[element.id] = String(x[element.id]);
          } else {
            dataX[element.id] = x[element.id];
          }
        }
      }
      return dataX;
    });
    const groupedData = data.reduce((result, item, i, arr) => {
      for (const key in item) {
        if (!result[key]) {
          result[key] = [key.length];
        }
        const max = Math.max(...result[key], item[key]?.length);
        if (typeof max === "number") {
          result[key].push(max);
        }
        if (arr.length - 1 === i) {
          const max = Math.max(...result[key], item[key]?.length);
          const lastMax = result[key][result[key].length - 1];
          result[key] = [];
          result[key].push(lastMax);
        }
      }
      return result;
    }, {});
    const headersWithWidth = Object.keys(groupedData).map((x) => {
      return {
        width: groupedData[x][0] + 15,
        header: headCells.find((y) => y.id === x)?.name,
        key: x,
      };
    });
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(`${sheetName}`);
    const row1 = sheet.getRow(1);
    row1.height = 35;
    row1.border = {
      top: { style: "thin", color: { argb: "3441A3" } },
      left: { style: "thin", color: { argb: "3441A3" } },
      bottom: { style: "thin", color: { argb: "3441A3" } },
      right: { style: "thin", color: { argb: "3441A3" } },
    };
    row1.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "D3D7F2" },
    };
    row1.font = {
      family: 4,
      size: 18,
      bold: true,
    };
    row1.alignment = {
      vertical: "middle",
    };

    sheet.columns = headersWithWidth;
    data?.map((x) => {
      const r = sheet.addRow(x);
      r.font = {
        family: 4,
        size: 14,
      };
      r.height = 35;
      r.alignment = {
        vertical: "middle",
      };
      r.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F2F2F2" },
      };
      r.border = {
        top: { style: "thin", color: { argb: "3441A3" } },
        left: { style: "thin", color: { argb: "3441A3" } },
        bottom: { style: "thin", color: { argb: "3441A3" } },
        right: { style: "thin", color: { argb: "3441A3" } },
      };
    });

    const todayDate = new Date();
    const dateForFile = formatDateForFile(todayDate.toISOString());
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${fileName}-${dateForFile}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }
};
