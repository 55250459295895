import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance, { axiosAuth } from "axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { useLoading } from "Context/LoadingContext";
import toastValidation, {
  errorToast,
  successToast,
} from "constants/toastConfig";

type LoginHistory = {
  token: string;
  loginTime: string;
  isMobile: boolean;
  browserDetails: string;
  version: string;
};
const OrganizerLogin = () => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const browserDetails = window.navigator.userAgent;

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Username is Required"),
      password: Yup.string().required("Password is Required"),
    }),
    onSubmit: ({ username, password }) => {
      setLoading(true);
      const formData = {
        username,
        password,
      };
      axiosAuth
        .post("/master/organizationLogin", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(async (res) => {
          successToast("Login Successful!");
          localStorage.setItem("token", res.data.response.accessToken);
          localStorage.setItem("role", res.data.response.role);
          localStorage.setItem("isActive", res.data.response.isActive);
          if (res.data.response.role === "ORG") {
            const checkOrganizationPlan = async () => {
              try {
                const res = await axiosInstance.get(
                  "/admin/getOrganizationPlan"
                );
                const {
                  isMultiShift,
                  paymentCheck,
                  rfidCardCheck,
                  planId: {
                    planTypeId: { code: planType },
                  },
                  routeOptimization,
                } = res.data?.res;
                localStorage.setItem(
                  "config",
                  JSON.stringify({
                    isMultiShift,
                    paymentCheck,
                    rfidCardCheck,
                    planType,
                    routeOptimization,
                  })
                );
                const driverSetting = await axiosInstance.get(
                  "/master/getOneStaticConfig?key=driverAccountSetting"
                );
                const isDriverAccounts = driverSetting.data.result.Config.value;

                localStorage.setItem("DriverAccountSetting", isDriverAccounts);
              } catch (error) {
                const err: any = error;
                console.log(err);
              }
            };
            await checkOrganizationPlan();
          } else {
            localStorage.setItem(
              "config",
              JSON.stringify({
                isMultiShift: false,
                paymentCheck: false,
                rfidCardCheck: false,
                planType: "",
                routeOptimization: false,
              })
            );
          }

          return res;
        })
        .then((res) => {
          const loginTime = new Date().toLocaleTimeString();
          const data = {
            token: res.data.response.accessToken,
            loginTime,
            isMobile,
            browserDetails,
            version: browserDetails,
          };
          const isActive = res.data.response.isActive;
          loginHistory(data);
          setTimeout(() => {
            setLoading(false);
            const config = JSON.parse(localStorage.getItem("config") || "{}");
            if (isActive) {
              if (config.planType === "BAC") {
                nav("/basicmember");
              } else {
                nav("/dashboard");
              }
            } else {
              nav("/profile");
            }
          }, 2000);
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.response === undefined) {
            errorToast("Something's wrong!");
            return;
          }
          if (error.response.status !== undefined) {
            errorToast(error.response?.data);
          } else errorToast("Server Error");
        });
    },
  });

  const loginHistory = async (data: LoginHistory) => {
    try {
      await axiosInstance.post("/master/addLoginOrganizer", data);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <section className="login" style={{ height: "100vh" }}>
      <div className="loginBox">
        <Link to="/" className="logowrap mb-4">
          <Logo height={80} width={200} />
        </Link>
        <div className="login-text">Login</div>
        <div className="welcome-text">Welcome to the DropikMe</div>
        <form onSubmit={formik.handleSubmit}>
          <label htmlFor="username">
            <span className="sign-up-placeholder">Username*</span>

            <input
              className={`form-control ${
                formik.errors.username && formik.submitCount > 0
                  ? "empty-form"
                  : ""
              }`}
              id="username"
              name="username"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.username}
              placeholder="Enter Username"
            />
          </label>
          <label htmlFor="password">
            <span className="sign-up-placeholder">Password*</span>

            <input
              className={`form-control bg-transparent ps-4 ${
                formik.errors.password && formik.submitCount > 0
                  ? "empty-form"
                  : ""
              }`}
              // className="form-control bg-transparent ps-4"
              id="password"
              name="password"
              type="password"
              style={{ height: "50px" }}
              onChange={formik.handleChange}
              value={formik.values.password}
              placeholder="Enter Password"
            />
          </label>
          <label className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => toastValidation(formik.errors)}
            >
              Login
            </button>
          </label>
        </form>
        <Link to={"/forgotPass"}>Forgot Password</Link>
        <div className="signUpmessage">
          Don't have an account?
          <a
            href="https://www.dropikme.com/#pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp; Sign Up
          </a>
        </div>
        <span></span>
      </div>
    </section>
  );
};

export default OrganizerLogin;
