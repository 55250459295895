import { memo, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { successToast, warnToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { LatLongObj } from "constants/Constants";

const containerStyle = {
  width: "100%",
  height: "100%",
};
type Props = {};
type Config = {
  routeOptimization: boolean;
};
const BasicLocationAddForm = (props: Props) => {
  const { setLoading } = useLoading();
  const center: google.maps.LatLng | google.maps.LatLngLiteral | undefined = {
    lat: 23.0204737,
    lng: 72.4145878,
  };
  const MY_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const [coordinates, setCoordinates] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >(center);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${MY_API_KEY}`,
  });
  const nav = useNavigate();
  const RouteOptimizationValue: Config | null = JSON.parse(
    localStorage.getItem("config") || "null"
  );
  const isRouteOptimization = RouteOptimizationValue?.routeOptimization;
  const formik = useFormik({
    validateOnMount: true,

    initialValues: {
      name: "",
      order: "",
      address: "",
      latitude: "",
      longitude: "",
      pickUpTime: "",
      dropTime: "",
      note: "",
      details: "",
      isActive: true,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Location Name is too short!")
        .max(30, "Location Name is too long!")
        .required("Location Name is required"),
      address: Yup.string()
        .min(1, "Address is too short!")
        .max(300, "Address is too long!")
        .required("Address is required"),
      order: Yup.string()
        .matches(
          /^[1-9]\d{0,2}$/,
          "Order must be a positive number with at most 3 digits and not allowed decimal"
        )
        .min(1, "Please enter a value greater than 0")
        .max(999, "Please enter a value less than 999")
        .required("Order Number is Required"),
      pickUpTime: Yup.string().required("Pickup Time is required"),
      dropTime: Yup.string().required("Drop Time is required"),
      // latitude: Yup.string().required("Latitude is required"),
      // longitude: Yup.string().required("Longitude is required"),
    }),
    onSubmit: async (values) => {
      const {
        name,
        order,
        address,
        pickUpTime,
        latitude,
        longitude,
        dropTime,
        details,
        isActive,
      } = values;
      setLoading(true);
      try {
        const formData = {
          name,
          order,
          address,
          latitude: latitude || LatLongObj.latitude,
          longitude: longitude || LatLongObj.longitude,
          pickUpTime,
          dropTime,
          details,
          isActive,
          note: "",
          photo: "",
        };
        const res = await axiosInstance.post(
          "/master/addBasicLocationMaster",
          formData
        );
        if (res.status === 202) {
          warnToast(res.data);
          setLoading(false);
          return;
        }
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("..");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
  });

  const handleSearch = useCallback(
    async (loc: string) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            loc
          )}&key=${MY_API_KEY}`
        );
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          formik.setFieldValue("latitude", lat.toString());
          formik.setFieldValue("longitude", lng.toString());
          setCoordinates({ lat, lng });
        }
      } catch (error) {
        console.error("Error geocoding location:", error);
      }
    },
    [formik.values.address && isRouteOptimization]
  );
  console.log(isRouteOptimization);
  useEffect(() => {
    if (isRouteOptimization) {
      handleSearch(formik.values.address);
    }
  }, [formik.values.address]);

  const handleMarkerDragEnd = async (e: any) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    formik.setFieldValue("latitude", lat.toString());
    formik.setFieldValue("longitude", lng.toString());
    setCoordinates({ lat, lng });
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MY_API_KEY}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const address = data.results[0].formatted_address;
        formik.setFieldValue("address", address);
      }
    } catch (error) {
      console.error("Error reverse geocoding:", error);
    }
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }
  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Location Name"
              />
              <span className="top-placeholder">Location Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="address">
              <input
                className={`form-control ${
                  formik.errors.address && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="address"
                name="address"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address}
                placeholder="Address"
              />
              <span className="top-placeholder">Address*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="order">
              <input
                className={`form-control ${
                  formik.errors.order && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="order"
                name="order"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.order}
                placeholder="Order"
              />
              <span className="top-placeholder">Order*</span>
            </label>
            <label className="col-md-3 mb-3" htmlFor="pickUpTime">
              <input
                className={`form-control session-time ${
                  formik.errors.pickUpTime && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="pickUpTime"
                name="pickUpTime"
                type="time"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.pickUpTime}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <span className="top-placeholder">Pickup Time*</span>
            </label>
            <label className="col-md-3 mb-3" htmlFor="dropTime">
              <input
                className={`form-control session-time ${
                  formik.errors.dropTime && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="dropTime"
                name="dropTime"
                type="time"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.dropTime}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <span className="top-placeholder">Drop Time*</span>
            </label>
            {/* <label className="col-md-3 mb-3" htmlFor="latitude">
              <input
                className={`form-control session-time ${
                  formik.errors.latitude && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="latitude"
                name="latitude"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.latitude}
                placeholder="Latitude"
              />
              <span className="top-placeholder">Latitude*</span>
            </label>
            <label className="col-md-3 mb-3" htmlFor="longitude">
              <input
                className={`form-control session-time ${
                  formik.errors.longitude && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="longitude"
                name="longitude"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.longitude}
                placeholder="Longitude"
              />
              <span className="top-placeholder">Longitude*</span>
            </label> */}
            <div className="col-md-3 mb-3">
              <label className="custCheck" htmlFor="isActive">
                Active
                <input
                  type="checkbox"
                  id="isActive"
                  name="isActive"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.isActive}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          {isLoaded && isRouteOptimization && (
            <div
              style={{ width: "100%", height: "80vh", marginBottom: "20px" }}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={coordinates}
                zoom={10}
              >
                {formik.values.longitude !== "" &&
                  formik.values.latitude !== "" && (
                    <Marker
                      position={coordinates}
                      draggable={true}
                      onDragEnd={handleMarkerDragEnd}
                    />
                  )}
              </GoogleMap>
            </div>
          )}
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            <CancelBtn path=".." />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(BasicLocationAddForm);
