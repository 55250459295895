import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { AdminRequestDataType } from "../../../types/adminRequestTypes";
import DemoReschedule from './demoRescheduleItem'
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from 'Context/LoadingContext'
type Props = {};

const DemoRescheduleList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading()
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [requests, setRequests] = useState<AdminRequestDataType[]>([]);

  const loadRequests = useCallback(
    async (pageNum: number, sortParam: string) => {
      setLoading(true)
      try {
        const res = await axiosInstance.get(
          `/admin/getDemoReschdule?page=${pageNum}&sort=${sortParam}`
        );
        setSearchCount(res.data.SearchCount);
        setLoading(false)

        const adminRqs: AdminRequestDataType[] = [
          ...requests,
          ...res.data.AdminRequest,
        ];
        setRequests(adminRqs);
      } catch (error) {
        console.log(error);
        setLoading(false)

      }
    },
    [requests]
  );
  useEffect(() => {
    loadRequests(page, sort);
  }, [page, sort, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(requests, setPage, page, searchCount);
  };
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Name
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="email"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Email
              <span className="px-2">
                {sort === "email" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="contactNo"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Contact
              <span className="px-2">
                {sort === "contactNo" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="demoDate"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Demo Date
              <span className="px-2">
                {sort === "demoDate" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="Time"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Time
              <span className="px-2">
                {sort === "Time" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="status"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setRequests, setPage);
              }}
            >
              Status
              <span className="px-2">
                {sort === "status" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="latestCreatedAt"
              role="button"
              className="text-center"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRequests,
                  setPage
                );
              }}
            >
              Last Change
              <span className="px-2">
                {sort === "latestCreatedAt" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {requests?.map((x: AdminRequestDataType) => {
            return <DemoReschedule x={x} key={x._id} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(DemoRescheduleList);
