import React, { useState, useCallback, useEffect } from "react";
import * as Yup from "yup";
import { useLoading } from "Context/LoadingContext";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "axiosInstance";
import toastValidation, {
  errorToast,
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";

type Props = {
  // state: any;
};

const BasicMemberFee = (props: Props) => {
  const nav = useNavigate();
  const { state } = useLocation();
  const { setLoading } = useLoading();
  const [member, setMember] = useState<any[]>([]);
  const [memId, setMemId] = useState("");
  const [border, setBorder] = useState<boolean>(false);

  const loadMembers = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/master/getAllBasicMemberForFee`);
      const data = res.data.result;
      setMember(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  //Add Config
  const formik = useFormik({
    initialValues: {
      feesConfigId: state._id,
      fees: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      fees: Yup.string().required("Fees is Required"),
    }),
    onSubmit: async ({ fees }) => {
      setLoading(true);
      try {
        const formData = {
          memberId: memId,
          feesConfigId: state._id,
          fees: fees,
        };
        setLoading(true);
        const res = await axiosInstance.post(
          `/master/addBasicMemberFee`,
          formData
        );
        // console.log(res.data, "res");
        if (res.data.message === "Already Member Fees Paid") {
          errorToast("Already Member Fees Paid");
        }

        // await checkValidDateToupdate();
        if (res.data.message === "Created") {
          //   const isCloneX = await loadLatestFeesConfig();
          nav("/paymentMasterBasic");
          successToast();
          setLoading(false);
          //   setIndex(1);
          //   if (isCloneX?.isClone as boolean) {
          //     state.isClone = true;
          //     state.prevConfigId = isCloneX?.prevId;
          //     const item = { ...state, ...res.data.result };
          //     nav(
          //       {
          //         pathname: `/transportationFeesMain/feesSection/structureAdd`,
          //         search: `?index=1`,
          //       },
          //       { state: item }
          //     );
          //   } else {
          //     const item = { ...state, ...res.data.result };
          //     nav(
          //       {
          //         pathname: `/transportationFeesMain/feesSection/structureAdd`,
          //         search: `?index=1`,
          //       },
          //       { state: item }
          //     );
          //   }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);

  const handleFeesChange = (e: any) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, "");
    value = value.replace(/(\..*?)\..*/g, "$1");
    value = value.replace(/^(\d*\.\d{0,2}).*/g, "$1");
    formik.setFieldValue("fees", value);
  };

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Payment</span>
      </div>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label htmlFor="member" className="col-md-6 mb-3">
              <span className="top-placeholder">Member*</span>

              <select
                className={`form-control ${
                  memId === "" && border ? "empty-form" : ""
                }`}
                id="member"
                onChange={(e) => {
                  setMemId(e.target.value);
                }}
              >
                <option value="">Select Member</option>
                {member.map(({ _id, memberName }) => {
                  return (
                    <option key={_id} value={_id}>
                      {memberName}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="fees">
              <input
                className={`form-control ${
                  formik.errors.fees && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="fees"
                name="fees"
                type="number"
                onBlur={formik.handleBlur}
                onChange={handleFeesChange}
                value={formik.values.fees}
                placeholder="Fees"
              />
              <span className="top-placeholder">Fees*</span>
            </label>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                setBorder(true);
                if (memId === "") {
                  warnToast("Select Member");
                }
                toastValidation(formik.errors);
              }}
            />
            <CancelBtn btnTxt="Cancel" path="/paymentMasterBasic" />
          </div>
        </form>
      </div>
    </>
  );
};

export default BasicMemberFee;
