import { useLoading } from "Context/LoadingContext";
import axiosInstance from "axiosInstance";
import SubmitBtn from "components/Buttons/Submit";
import { formatDate } from "constants/Constants";
import { warnToast } from "constants/toastConfig";
import { useFormik } from "formik";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CancelBtn from "components/Buttons/Cancel";

type InvoiceProps = {
  //   x: any;
  //   amount: any;
};

function numberToWords(num: any): string {
  const onesWords = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const tensWords = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  const scales = ["", "Thousand", "Million", "Billion"];

  if (num === 0) return "Zero";

  const chunks = [];
  while (num) {
    chunks.push(num % 1000);
    num = Math.floor(num / 1000);
  }

  const resultChunks = [];

  for (let i = 0; i < chunks.length; i++) {
    const chunk = chunks[i];

    if (chunk) {
      const hundreds = Math.floor(chunk / 100);
      const tens = Math.floor((chunk % 100) / 10);
      const ones = chunk % 10;
      const scale = scales[i];

      const words = [];

      if (hundreds) {
        words.push(onesWords[hundreds] + " Hundred");
      }

      if (tens || ones) {
        if (tens === 1) {
          words.push(onesWords[tens * 10 + ones]);
        } else {
          if (tens) words.push(tensWords[tens]);
          if (ones) words.push(onesWords[ones]);
        }
      }

      if (scale) {
        words.push(scale);
      }

      resultChunks.unshift(words.join(" "));
    }
  }

  return resultChunks.join(" ");
}

type State = {
  memberName: any;
  memId: any;
  departmentName: any;
  category: any;
  subCategory: any;
  paidFees: any;
  termName: any;
  startDate: any;
  dueDate: any;
};

const InvGen: FC<InvoiceProps> = () => {
  const { state } = useLocation();

  const {
    memberName,
    memId,
    departmentName,
    category,
    subCategory,
    paidFees,
    termName,
    startDate,
    dueDate,
  } = state as State;

  const [invoice, setInvoice] = useState<any>({});
  const [data, setData] = useState<any>("");

  const { setLoading } = useLoading();

  const loadInvoice = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/master/getOneInvoice`);
      setInvoice(res.data.resp);

      if (res.data.resp) {
        const response = await axiosInstance.get(
          `/template/${res.data.resp.filename}`
        );
        const hasBodyTag = response.data.includes("<body>");
        if (hasBodyTag) {
          const start = response.data.indexOf("<body>");
          const end = response.data.indexOf("</body>");
          const bodyContent = response.data.slice(start + 6, end);

          const replacedData = bodyContent
            .replace("{memberName}", memberName)
            .replace("{memberId}", memId)
            .replace("{departmentName}", departmentName)
            .replace("{category}", category)
            .replace("{subCategory}", subCategory)
            .replace("{paidFees}", paidFees ?? "00.00")
            .replace("{total}", paidFees ?? "00.00")
            .replace("{invoiceNumber}", res.data.resp.invoiceNumber)
            .replace("{termName}", termName)
            .replace("{startDate}", formatDate(startDate))
            .replace("{dueDate}", formatDate(dueDate))
            .replace("{amountInWord}", numberToWords(paidFees ?? "00.00"))
            .replace(
              "{tDate}",
              new Date().toDateString()
              // + new Date().toLocaleTimeString()
            );

          setData(replacedData);
        } else {
          const replacedData = response.data
            .replace("{memberName}", memberName)
            .replace("{memberId}", memId)
            .replace("{departmentName}", departmentName)
            .replace("{category}", category)
            .replace("{subCategory}", subCategory)
            .replace("{paidFees}", paidFees ?? "00.00")
            .replace("{total}", paidFees ?? "00.00")
            .replace("{invoiceNumber}", res.data.resp.invoiceNumber)
            .replace("{termName}", termName)
            .replace("{startDate}", formatDate(startDate))
            .replace("{dueDate}", formatDate(dueDate))
            .replace("{amountInWord}", numberToWords(paidFees ?? "00.00"))
            .replace(
              "{tDate}",
              new Date().toDateString()
              // + new Date().toLocaleTimeString()
            );
          setData(replacedData);
        }
      } else {
        const temp: any = await axiosInstance.get(`/template/default.html`);
        const replacedData = temp.data
          .replace("{memberName}", memberName)
          .replace("{memberId}", memId)
          .replace("{departmentName}", departmentName)
          .replace("{category}", category)
          .replace("{subCategory}", subCategory)
          .replace("{paidFees}", paidFees ?? "00.00")
          .replace("{total}", paidFees ?? "00.00")
          .replace("{invoiceNumber}", "1")
          .replace("{termName}", termName)
          .replace("{startDate}", formatDate(startDate))
          .replace("{dueDate}", formatDate(dueDate))
          .replace("{amountInWord}", numberToWords(paidFees ?? "00.00"))
          .replace(
            "{tDate}",
            new Date().toDateString()
            // + new Date().toLocaleTimeString()
          );
        setData(replacedData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    loadInvoice();
  }, []);

  // const downloadHTMLAsPDF = (htmlData: any) => {
  //   const doc = new jsPDF({
  //     orientation: "landscape",
  //     unit: "px",
  //     format: "a4",
  //   });
  //   const element = document.createElement("div");
  //   element.innerHTML = htmlData;

  //   doc.html(element, {
  //     callback: function (doc) {
  //       doc.save("report.pdf");
  //     },
  //     x: 10,
  //     y: 10,
  //   });
  // };

  const downloadHTMLAsPDF = (htmlData: any) => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });

    const container = document.getElementById("pdfContainer");

    html2canvas(container as any, {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      const newImgWidth = imgWidth * ratio;
      const newImgHeight = imgHeight * ratio;

      const offsetX = (pdfWidth - newImgWidth) / 2;
      const offsetY = (pdfHeight - newImgHeight) / 2;

      doc.addImage(
        imgData,
        "JPEG",
        offsetX,
        offsetY,
        newImgWidth,
        newImgHeight
      );

      doc.save("report.pdf");
    });
  };

  const generatePrint = (htmlData: any) => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });

    const container = document.getElementById("pdfContainer");

    html2canvas(container as any, {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      const newImgWidth = imgWidth * ratio;
      const newImgHeight = imgHeight * ratio;

      const offsetX = (pdfWidth - newImgWidth) / 2;
      const offsetY = (pdfHeight - newImgHeight) / 2;

      doc.addImage(
        imgData,
        "JPEG",
        offsetX,
        offsetY,
        newImgWidth,
        newImgHeight
      );

      doc.autoPrint({ variant: "non-conform" });
      const printWindow = window.open("", "_blank");
      if (printWindow) {
        printWindow.document.write(
          '<iframe src="' +
            doc.output("datauristring") +
            '" style="width:100%;height:100%;" frameborder="0" allowfullscreen></iframe>'
        );
        printWindow.document.close();

        setTimeout(() => {
          printWindow.print();
        }, 1000);
      }
    });
  };

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Invoice</span>
      </div>
      <div className="justify-content-end align-items-center d-flex mb-5">
        <button
          onClick={() => downloadHTMLAsPDF(data)}
          className="btn btn-primary"
        >
          Download PDF
        </button>
        <button onClick={() => generatePrint(data)} className="btn btn-primary">
          Print
        </button>
        <CancelBtn btnTxt="Back" path="/paymentManagement" />
      </div>
      <div dangerouslySetInnerHTML={{ __html: data }} id="pdfContainer" />
    </>
  );
};

export default InvGen;
