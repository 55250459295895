import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MemberLeaveList from "../MemberLeave/memberLeaveList";
import DriverLeaveList from "../DriverLeave/driverLeaveList";
import { useSearchParams } from "react-router-dom";
import AttendantLeaveList from "../AttendantLeave/attendantLeaveList";
type Props = {};

const LeaveTabs = (props: Props) => {
  const [index, setIndex] = useState(0);
  const [param] = useSearchParams();

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Leave</span>
      </div>
      <Tabs
        selectedIndex={param.get("index") ? Number(param.get("index")) : index}
        onSelect={(e) => {
          setIndex(e);
        }}
      >
        <TabList>
          <Tab>Driver Leave</Tab>
          <Tab>Member Leave</Tab>
          <Tab>Attendant Leave</Tab>
        </TabList>

        <TabPanel>
          <DriverLeaveList setIndex={setIndex} />
        </TabPanel>
        <TabPanel>
          <MemberLeaveList />
        </TabPanel>
        <TabPanel>
          <AttendantLeaveList />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default LeaveTabs;
