import React, { useState, memo } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

type Props = {};
type ContextType = {
    setDriverLeaveId: (driverLeaveId: string) => void;
    driverLeaveId: string;
};
const DriverTracking: React.FC<{}> = (props: Props) => {
    const [driverTrackingId, setDriverTrackingId] = useState<string>("");
    return (
        <>
            <div className="pageTitle justify-content-between align-items-center d-flex">
                <span>Driver Tracking</span>
            </div>
            <Outlet context={{ driverTrackingId, setDriverTrackingId }} />
        </>
    )
}

export default memo(DriverTracking)
export function useDriverTrackingId() {
    return useOutletContext<ContextType>();
}