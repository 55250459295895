import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { VehiclePucMasterDataType } from "../../../types/vehiclePucTypes";
import VehiclePucMasterListItem from "./vehiclePucListItem";
import { VehicleMasterDataType } from "../../../types/vehicleMasterType";
import { useLoading } from "Context/LoadingContext";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import NotFound from "components/NotFound";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotFound } from "Context/NotFound";

type Props = {
  state: any;
};

const VehiclePucMasterList = ({ state }: Props): JSX.Element => {
  const nav = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("-pucDate");
  const [searchCount, setSearchCount] = useState(0);
  const [vehiclePuc, setVehiclePuc] = useState<VehiclePucMasterDataType[]>([]);
  const { notfound, setNotFound } = useNotFound();

  const { setLoading } = useLoading();

  const loadVehiclePuc = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        const res = await axiosInstance.get(
          `/master/getAllVehiclePucListByOrganizerId?page=${pageNum}&sort=${sortParam}&vehicleId=${state._id}`
        );
        if (res.data.vehicles.length !== 0) {
          const vehiclePucList: VehiclePucMasterDataType[] = [
            ...vehiclePuc,
            ...res.data.vehicles,
          ];
          setVehiclePuc(vehiclePucList);
          setSearchCount(res.data.SearchCount);
        }
        setLoading(false);
        setNotFound(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [vehiclePuc]
  );
  useEffect(() => {
    loadVehiclePuc(page, sort);
  }, [page, sort, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(vehiclePuc, setPage, page, searchCount);
  };

  return (
    <>
      <div className="d-flex align-items-end justify-content-end my-3">
        <button
          className="btn btn-primary mb-3"
          type="submit"
          onClick={() => {
            const item = state;
            nav(
              {
                pathname: "/vehicleRegistration/addForm/pucAdd",
                search: "?fromEdit=1",
              },
              {
                state: item,
              }
            );
          }}
        >
          Add
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="vehicleNo"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setVehiclePuc, setPage);
              }}
            >
              Vehicle No
              <span className="px-2">
                {sort === "vehicleNo" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="pucDate"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setVehiclePuc, setPage);
              }}
            >
              PUC Date
              <span className="px-2">
                {sort === "pucDate" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="validUptoDate"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setVehiclePuc, setPage);
              }}
            >
              ValidUpto Date
              <span className="px-2">
                {sort === "validUptoDate" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setVehiclePuc, setPage);
              }}
            >
              Company Name
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {vehiclePuc?.map((x) => {
              return (
                <VehiclePucMasterListItem x={x} key={x._id} state={state} />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(VehiclePucMasterList);
