import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { RFDICardDataType } from "types/rfdiCardTypes";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { formatDate } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: RFDICardDataType;
};

const RFDICardListItem = ({ x }: Props) => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit </Tooltip>;
  return (
    <tr key={x._id}>
      <td>{x.cardNumber}</td>
      <td>{x.cardCode}</td>
      <td>{x.cardValue}</td>
      <td>{formatDate(x.cardExpiredDate)}</td>
      <td className="text-center">
        {x.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            style={{ cursor: "pointer" }}
            id={x._id}
            onClick={(e) => {
              nav("editForm", {
                state: x,
              });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(RFDICardListItem);
