import React, { useEffect } from 'react'
import AppRoutes from './appRoutes'
import { useLoading } from 'Context/LoadingContext'
import Loading from 'components/SweetAlert/Loading'

type Props = {}

const App: React.FC = (props: Props) => {
  const { setLoading } = useLoading()

  useEffect(() => {
    setLoading(false)
  }, [setLoading])

  return (
    <>
      <Loading />
      <AppRoutes />
    </>
  )
}

export default App
