import axiosInstance, { API_BASE_URL } from "axiosInstance";
import { useCallback, useEffect, useState } from "react";
import MemberVehicleDetails from "./MemberVehicleDetails";
import socket from "socket";
import { drvImage, formatTime, memImage } from "constants/Constants";
import { Link } from "react-router-dom";
import CardComponent from "app/admin/Dashboard/CardComponent";
import memberPickupImg from "../../assets/images/pickupCount.svg";
import memberInImg from "../../assets/images/inCount-02.png";
import memberOut from "../../assets/images/outCount.svg";
import memberWait from "../../assets/images/waitCount-04.png";
import driverPending from "../../assets/images/pending-02.png";
import driverLeaveImg from "../../assets/images/DriverLeave.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import sosImg from "../../assets/images/sos.png";
import NotFound from "components/NotFound";

type Props = {};
type driverComplaint = {
  name: string;
  userType: string;
  message: string;
  title: string;
  photo: string;
  createdAt: string;
};

const Dashboard = (props: Props) => {
  const [driverLeaveOnTo, setDriverLeaveOnToday] = useState<any>(0);
  const [sosTo, setSosToday] = useState<any>(0);
  const [memberPickupInCount, setMemberPickupInCount] = useState<any>(0);
  const [memberPickupOutCount, setMemberPickupOutCount] = useState<any>(0);
  const [memberDropInCount, setMemberDropInCount] = useState<any>(0);
  const [memberDropOutCount, setMemberDropOutCount] = useState<any>(0);
  const [memberPickUp, setMemberPickUp] = useState<any>(0);
  const [memberDropOff, setMemberDropOff] = useState<any>(0);
  // const [memberLeaveOnTodays, setMemberLeaveOnToday] = useState<any>(0);
  const [driverCompalintsToday, setDriverComplaintsToday] = useState<any>(0);
  const [memberWaitToday, setMemberWaitToday] = useState<any>(0);
  const [memberDropWaitToday, setMemberDropWaitToday] = useState<any>(0);
  const [paymentOverdue, setPaymentOverdue] = useState<any>(0);
  const [driverLeaveApprovalPending, setDriverLeaveApprovalPending] =
    useState<number>(0);

  const [driverNotificationToday, setDriverNotificationToday] = useState<
    driverComplaint[]
  >([]);
  const [rfidCardRequired, setRfidCardRequired] = useState(true);
  const [index, setIndex] = useState(0);
  const loadMemberPickUpDashDetails = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/master/getAllDriverDetailsDash`);
      const data = res.data;

      setMemberPickUp(data.PickUpDetails);
      setMemberPickupInCount(data.pickupinMemberDetails);
      setMemberPickupOutCount(data.pickupoutMemberDetails);
      setMemberWaitToday(data.waitMemberDetails);
      setDriverLeaveOnToday(data.driverLeaveCount);
      setSosToday(data.sosDetails);
      setDriverLeaveApprovalPending(data.driverPendingLeaveCount);
      // setMemberLeaveOnToday(data.memberLeaveCount);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const loadMemberDropDashDetails = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getAllDriverDetailsDashForDropOff`
      );
      const data = res.data;
      setMemberDropOff(data.PickUpDetails);
      setMemberDropInCount(data.dropinMemberDetails);
      setMemberDropOutCount(data.dropoutMemberDetails);
      setMemberDropWaitToday(data.waitMemberDetails);
      setDriverComplaintsToday(data.complainDetails);
      setPaymentOverdue(data.duePaymentCount);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const loadDriverComplaintsDetails = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/master/getDashboardNotification`);
      const driverCompalintsToday = res.data.driverNotificationList;
      setDriverNotificationToday(driverCompalintsToday);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    loadDriverComplaintsDetails();
    loadMemberPickUpDashDetails();
    loadMemberDropDashDetails();

    socket.on("update-count", (data) => {
      if (
        data === "driverLeave" ||
        data === "inMember" ||
        data === "outMember" ||
        data === "waitMember" ||
        data === "sos" ||
        data === "memberLeave" ||
        data === "memberPick"
      ) {
        loadMemberPickUpDashDetails();
        loadMemberDropDashDetails();
      }
      if (data === "driverComplaints") {
        loadDriverComplaintsDetails();
      }
    });
  }, []);

  useEffect(() => {
    const x = localStorage.getItem("config") as string;
    const config: any = JSON.parse(x);
    setRfidCardRequired(config.rfidCardCheck);
  }, []);

  return (
    <>
      <div className="container-fluid">
        {rfidCardRequired ? (
          <>
            <div className="row">
              <CardComponent
                count={memberPickUp}
                text="Pickup"
                background={memberPickupImg}
              />
              <CardComponent
                count={memberPickupInCount}
                text="Pickup In"
                background={memberInImg}
              />
              <CardComponent
                count={memberPickupOutCount}
                text="Pickup Out"
                background={memberOut}
              />
              <CardComponent
                count={memberWaitToday}
                text="Pickup Wait"
                background={memberWait}
              />
              <CardComponent
                count={memberDropOff}
                text="Dropup"
                background={memberPickupImg}
              />
              <CardComponent
                count={memberDropInCount}
                text="Drop In"
                background={memberInImg}
              />
              <CardComponent
                count={memberDropOutCount}
                text="Drop Out"
                background={memberOut}
              />
              <CardComponent
                count={memberDropWaitToday}
                text="Drop Wait"
                background={memberWait}
              />
              <CardComponent
                count={driverLeaveApprovalPending}
                text="Need Approval"
                background={driverPending}
              />
              <CardComponent
                count={driverLeaveOnTo}
                text="Driver Leaves"
                background={driverLeaveImg}
              />
              <CardComponent
                count={driverCompalintsToday}
                text="Driver Complaints"
                background={driverLeaveImg}
              />
              {/* <CardComponent
                count={memberLeaveOnTodays}
                text="Member Leaves"
                background={memberLeaveImg}
              /> */}
              <CardComponent count={sosTo} text="Sos" background={sosImg} />
            </div>
          </>
        ) : (
          <div className="row">
            <CardComponent
              count={memberPickUp}
              text="Pickup"
              background={memberPickupImg}
              className="col-lg-4 mb-3"
            />
            <CardComponent
              count={memberWaitToday}
              text="Pickup Wait"
              background={memberWait}
              className="col-lg-4 mb-3"
            />
            <CardComponent
              count={sosTo}
              text="Sos"
              background={sosImg}
              className="col-lg-4 mb-3"
            />
            <CardComponent
              count={memberDropOff}
              text="Dropup"
              background={memberPickupImg}
              className="col-lg-4 mb-3"
            />
            <CardComponent
              count={memberDropWaitToday}
              text="Drop Wait"
              background={memberWait}
              className="col-lg-4 mb-3"
            />
            <CardComponent
              count={driverLeaveApprovalPending}
              text="Need Approval"
              background={driverPending}
              className="col-lg-4 mb-3"
            />
            <CardComponent
              count={driverLeaveOnTo}
              text="Driver Leaves"
              background={driverLeaveImg}
              className="col-lg-4 mb-3"
            />
            <CardComponent
              count={driverCompalintsToday}
              text="Driver Complaints"
              background={driverLeaveImg}
              className="col-lg-4 mb-3"
            />
            <CardComponent
              count={paymentOverdue}
              text="Payment Overdue"
              background={driverLeaveImg}
              className="col-lg-4 mb-3"
            />
            {/* <CardComponent
              count={memberLeaveOnTodays}
              text="Member Leaves"
              background={memberLeaveImg}
              className="col-lg-4 mb-3"
            /> */}
          </div>
        )}
        <div className="row boxOuter">
          <div className="col-lg-12">
            <div className="box">
              <h6 className="box-head">
                Vehicle Details{" "}
                <Link to="/trialMapRoute" style={{ marginLeft: "500px" }}>
                  All Tracking
                </Link>
              </h6>
              <Tabs selectedIndex={index} onSelect={(e) => setIndex(e)}>
                <TabList>
                  <Tab>PickUp</Tab>
                  <Tab>Drop</Tab>
                </TabList>

                <TabPanel>
                  <MemberVehicleDetails pickup={true} />
                </TabPanel>

                <TabPanel>
                  <MemberVehicleDetails pickup={false} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
          <div className="col-md-12 ">
            <div className="border border-1 rounded p-3 mt-3">
              <h6 className="box-head">Message Board</h6>
              {driverNotificationToday.length === 0 ? (
                <NotFound />
              ) : (
                driverNotificationToday.map((item, index) => {
                  let userTypeImage;
                  if (item.userType === "DRV") {
                    userTypeImage = item.photo
                      ? `${drvImage}${item.photo}`
                      : `${drvImage}Default.png`;
                  } else {
                    userTypeImage = item.photo
                      ? `${memImage}${item.photo}`
                      : `${memImage}Default.png`;
                  }

                  return (
                    <div className="card my-3" key={index}>
                      <div className="card-header">
                        <i className="userImg">
                          <img src={userTypeImage} alt="UserPic" />
                        </i>
                        <span className="message_name">{item.name}</span>
                        <span className="float-right">
                          {formatTime(item.createdAt)}
                        </span>
                      </div>
                      <div className="row my-3">
                        <div className="message ">
                          <span className="px-3 text-justify">
                            {item.message}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
