import React, { useState, memo } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";

type Props = {};
type ContextType = {
  setAdminClientId: (adminClientId: string) => void;
  adminClientId: string;
};

const AdminClient: React.FC<{}> = (props: Props) => {
  const location = useLocation();
  const [adminClientId, setAdminClientId] = useState<string>("");

  return (
    <>
      {location.pathname === '/adminClient' ? <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Clients</span>
        {location.pathname === "/adminClient"}
      </div> : ''}

      <Outlet context={{ adminClientId, setAdminClientId }} />
    </>
  );
};

export default memo(AdminClient);

export function useAdminClientId() {
  return useOutletContext<ContextType>();
}
