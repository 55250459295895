import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { OrgBasicSetup } from "./List";
import { convertTo12HourFormat, drvImage } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { memImage } from "constants/Constants";

type Props = {
  item: OrgBasicSetup;
};

const ConfigListItem = ({ item }: Props) => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  let userTypeImage = item.photo
    ? `${memImage}${item.photo}`
    : `${memImage}Default.png`;
  return (
    <tr key={item._id}>
      <td>
        <i className="userImg">
          <img src={userTypeImage} alt="MemberPic" />
        </i>
      </td>
      <td>{item.memberName}</td>
      <td>{item.contactNo}</td>
      <td>{item.lName}</td>
      {item.pickUpTime ? (
        <td>{convertTo12HourFormat(item.pickUpTime)}</td>
      ) : (
        <td></td>
      )}
      {item.dropTime ? (
        <td>{convertTo12HourFormat(item.dropTime)}</td>
      ) : (
        <td></td>
      )}
      <td className="text-center">
        {item.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            style={{ cursor: "pointer" }}
            id={item._id}
            onClick={() => {
              nav("edit", {
                state: item,
              });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(ConfigListItem);
