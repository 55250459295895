import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo, useMemo } from "react";
import { useLoading } from "Context/LoadingContext";
import { useTable, usePagination } from "react-table";
import "../../../assets/styles/style.css";
import {
  downloadTableAsPDF,
  downloadTableAsPrint,
  exportToExcel,
  formatDate,
  formatDateForFile,
} from "constants/Constants";
import NotFound from "components/NotFound";
import { downloadExcel } from "constants/ReportFun";

type Props = {};

const PaymentReportList = (props: Props): JSX.Element => {
  const [data, setData] = useState([]);
  const [ddlData, setDdlData] = useState([]);
  const [fdId, setFdId] = useState("");
  const [notFound, setNotFound] = useState<boolean>(false);
  const { setLoading } = useLoading();

  const loadDDL = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/report/BasicFeesDDL`);
      setDdlData(res.data.resp);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [ddlData]);

  const loadReports = useCallback(
    async (fdId: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/report/MemberPayment?fId=${fdId}`
        );
        // console.log(res.data.resp, "pay");

        setData(res.data.resp);
        setLoading(false);
        setNotFound(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setNotFound(true);
      }
    },
    [data]
  );

  useEffect(() => {
    if (fdId === "") {
      loadDDL();
    } else {
      loadReports(fdId);
    }
  }, [fdId]);

  const columns = useMemo(
    () => [
      {
        Header: "Member Name",
        accessor: "name",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Total Fees",
        accessor: "fees",
      },
      {
        Header: "Paid Fees",
        accessor: "paidFees",
      },
      {
        Header: "Payment Mode",
        accessor: "paymentMode",
      },
      {
        Header: "Paid Date",
        accessor: (row: any) => formatDate(row.paidDate),
      },
    ],
    []
  );

  const download = async () => {
    try {
      downloadExcel(
        [
          {
            id: "name",
            name: "Member Name",
          },
          {
            id: "location",
            name: "Location",
          },

          {
            id: "fees",
            name: "Total Fees",
          },
          {
            id: "paidFees",
            name: "Paid Fees",
          },
          {
            id: "paymentMode",
            name: "Payment Mode",
          },
          {
            id: "paidDate",
            name: "Paid Date",
            isDate: true,
          },
        ],
        data === undefined ? [] : data,
        "Payment_Report",
        "payment_Report"
      );
    } catch (error) {
      return {};
    }
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as any,
    },
    usePagination
  );
  const todayDate = new Date();
  const dateForFile = formatDateForFile(todayDate.toISOString());
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Member Payment Report</span>
      </div>
      <button
        onClick={() =>
          downloadTableAsPDF(columns, data, `Payment_Report-${dateForFile}.pdf`)
        }
        className="btn btn-primary mb-4"
      >
        Export to PDF
      </button>
      <button
        onClick={() => {
          download();
        }}
        className="btn btn-primary mb-4"
      >
        Export to Excel
      </button>
      <button
        onClick={() => downloadTableAsPrint(columns, data)}
        className="btn btn-primary mb-4"
      >
        Print
      </button>
      <div className="formBg mb-3 row align-items-center">
        <label className="col-md-6 mb-3" htmlFor="month-dropdown">
          <select
            className="form-control"
            id="month-dropdown"
            name="month-dropdown"
            value={fdId}
            onChange={async (e) => {
              setFdId(e.target.value);
            }}
          >
            <option value="">Select Financial Year </option>
            {ddlData.map((m: any) => {
              return (
                <option key={Math.random()} value={m._id}>
                  {m.sDate} To {m.eDate}
                </option>
              );
            })}
          </select>
        </label>
      </div>
      <table {...getTableProps()} id="report-table" className="tableForReport">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="trForReport">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="thForReport">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="trForReport">
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()} className="tdForReport">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {notFound && (
        <div className="mt-4">
          {" "}
          <NotFound />
        </div>
      )}
      <div className="pagination m-4 gap-2">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">>"}
        </button>{" "}
        <span className="mx-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(PaymentReportList);
