import React, { useState, useEffect, useCallback, memo } from "react";
import axiosInstance from "axiosInstance";
import OrganizationDashboard from "../OrganizationDashboard/index";
import NewCustomerWraper from "../OrganizationDashboard/NewCustomerWraper";
import CardComponent from "./CardComponent";
import memberWait from "../../../assets/images/memberWait.png";

import socket from "socket";
const AdminDashBoardItem: React.FC = () => {
  const [statusDone, setStatusDone] = useState(0);
  const [statusReschdule, setStatusReschdule] = useState(0);
  const [statusPending, setStatusPending] = useState(0);
  const [searchCount, setSearchCount] = useState(0);
  const demoPending = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/admin/getDemoRequest?page=1&sort=name`
      );
      setStatusPending(res.data.SearchCount);
    } catch (error) {
      console.log(error);
    }
  }, [statusPending]);
  const demoReschedule = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/admin/getDemoReschdule?page=1&sort=name`
      );
      setStatusReschdule(res.data.SearchCount);
    } catch (error) {
      console.log(error);
    }
  }, [statusPending]);
  const demoDone = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/admin/getDemoDone?page=1&sort=name`
      );
      setStatusDone(res.data.SearchCount);
    } catch (error) {
      console.log(error);
    }
  }, [statusDone]);
  const loadExpirePlans = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `admin/getAllAdminExpirePlan?page=1&sort=name`
      );

      setSearchCount(res.data.expirePlan[0].totalCount[0].count);
    } catch (error) {
      console.log(error);
    }
  }, [searchCount]);
  useEffect(() => {
    loadExpirePlans();
    demoReschedule();
    demoDone();
    socket.on("admin-count", (data) => {
      if (data === "demo-reschedule") {
        demoReschedule();
        demoDone();
      }
    });
  }, []);

  useEffect(() => {
    demoPending();
    socket.on("admin-count", (data) => {
      if (data === "demo-pending") {
        demoPending();
      }
    });
  }, []);

  return (
    <div className="row">
      <CardComponent
        count={statusPending}
        text="New Demo Request"
        background={memberWait}
      />
      <CardComponent
        count={statusReschdule}
        text="Demo Reschedule"
        background={memberWait}
      />{" "}
      <CardComponent
        count={statusDone}
        text="Demo Completed"
        background={memberWait}
      />{" "}
      <CardComponent
        count={searchCount}
        text="Reminder Plan"
        background={memberWait}
      />
      <NewCustomerWraper />
      <OrganizationDashboard />
    </div>
  );
};

export default memo(AdminDashBoardItem);
