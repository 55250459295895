import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import ShiftDataType from "../../../types/shiftType.types";
import ShiftTypeListItems from "./ShiftTypeListItems";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";
import { ConfigLocalType } from "components/SidebarMenu/sideBar";

type Props = {};

const ShiftTypeMasterList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [shifts, setShifts] = useState<ShiftDataType[]>([]);
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const x = localStorage.getItem("config") as string;
  const config: ConfigLocalType = JSON.parse(x);

  const loadShifts = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllShift?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newShiftList: ShiftDataType[] = [
          ...res.data.Shifts[0].paginatedResults,
        ];
        if (searchKeyword !== "" && pageNum === 1) {
          if (newShiftList.length === 0) {
            setNotFound(true);
            setShifts([]);
            setSearchCount(0);
          }
        }
        if (newShiftList.length > 0) {
          setSearchCount(res.data.Shifts[0].totalCount[0].count);
          if (pageNum === 1) {
            setShifts(newShiftList);
          } else {
            setShifts((prevList) => [...prevList, ...newShiftList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [shifts]
  );

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    loadShifts(1, sort, search);
  };

  useEffect(() => {
    loadShifts(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(shifts, setPage, page, searchCount);
  };
  return (
    <>
      {config.planType !== "BAC" && config.isMultiShift === true ? (
        <AddSearch
          addLink="/shiftTypeMaster/addForm"
          handleSearch={handleSearch}
          search={search}
          setSearch={setSearch}
          placeholder="Shift Type"
        />
      ) : (
        <AddSearch
          handleSearch={handleSearch}
          search={search}
          setSearch={setSearch}
          placeholder="Shift Type"
        />
      )}
      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setShifts, setPage);
              }}
            >
              Shift Type
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>

            <th
              id="inTime"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setShifts, setPage);
              }}
            >
              In-Time
              <span className="px-2">
                {sort === "inTime" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="outTime"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setShifts, setPage);
              }}
            >
              Out-Time
              <span className="px-2">
                {sort === "outTime" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="code"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setShifts, setPage);
              }}
            >
              Code
              <span className="px-2">
                {sort === "code" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th>Description</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {shifts?.map((item) => {
              return <ShiftTypeListItems item={item} key={item._id} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(ShiftTypeMasterList);
