import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "axiosInstance";
import { updateReduxData } from "constants/Constants";
import { DepartmentMasterDataType } from "types/departmentMasterType";

type State = {
  data: DepartmentMasterDataType[];
  error: string | null;
  loading: boolean;
};

const initialState: State = {
  data: [],
  error: null,
  loading: false,
};

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    fetchDepartmentStart: (state) => {
      state.loading = true;
    },
    fetchDepartmentSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchDepartmentFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    addDepartment: (state, action: PayloadAction<any>) => {
      state.data = [...state.data, { ...action.payload }];
    },
    updateDepartment: (state, action: PayloadAction<any>) => {
      updateReduxData(state, action);
    },
  },
});

export const {
  fetchDepartmentStart,
  fetchDepartmentSuccess,
  fetchDepartmentFailure,
  updateDepartment,
  addDepartment,
} = departmentSlice.actions;

export const fetchDepartment = () => async (dispatch: any) => {
  try {
    dispatch(fetchDepartmentStart());
    const response = await axiosInstance.get(`master/getallDepartment`);
    dispatch(fetchDepartmentSuccess(response.data.department));
  } catch (error: any) {
    dispatch(fetchDepartmentFailure(error.message));
  }
};

export default departmentSlice.reducer;
