import { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import { successToast, warnToast } from "constants/toastConfig";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const Usermngadd = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNo: "",
      password: "",
      confirm: "",
      roleCode: "",
      active: true,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Name is Too Short!")
        .max(30, "Name is Too Long!")
        .required("Name is Required"),
      email: Yup.string()
        .max(30, "Email is Too Long!")
        .required("Email is Required"),
      contactNo: Yup.string()
        .min(10, "Contact Number is not valid")
        .max(10, "Contact Number is not valid")
        .required("Contact Number is Required"),
      roleCode: Yup.string().required("Select the role"),
      password: Yup.string()
        .min(4, "Password is Too Short!")
        .max(30, "Password is Too Long!")
        .required("Password is Required"),
      confirm: Yup.string()
        .required("confirm password is Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      name,
      email,
      contactNo,
      password,
      roleCode,
      active,
    }) => {
      setLoading(true);
      try {
        const formData = {
          name: name,
          email: email,
          contactNo: String(contactNo),
          password: password,
          roleCode: roleCode,
          isVerified: true,
          isDeleted: false,
          isActive: active,
        };
        const res = await axiosInstance.post("/master/addUser", formData);
        const responseMessage = res.data;
        if (res.status === 202) {
          setLoading(false);
          warnToast(responseMessage);
        }
        if (responseMessage === "Ok") {
          successToast();
          nav("/usermanagement", {
            state: res.data?.resp,
          });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <span className="top-placeholder">Name*</span>
              <input
                className={`form-control ${
                  formik.errors.name && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Name"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="email">
              <span className="top-placeholder">Email*</span>
              <input
                className={`form-control ${
                  formik.errors.email && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="email"
                name="email"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="Email"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="contactNo">
              <span className="top-placeholder">Contact Number*</span>
              <input
                className={`form-control ${
                  formik.errors.contactNo && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="contactNo"
                name="contactNo"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contactNo}
                placeholder="Contact Number"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="roleCode">
              <span className="top-placeholder">Role*</span>
              <select
                className={`form-control ${
                  formik.errors.roleCode && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="roleCode"
                name="roleCode"
                onChange={(e) => {
                  formik.setFieldValue("roleCode", e.target.value);
                }}
                defaultValue="" // set the default value for the select tag here
              >
                <option value="">Select Roles</option>
                <option value="SEL">Sales Executive</option>
                <option value="ADM">Admin</option>
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="password">
              <span className="top-placeholder">Password*</span>
              <input
                className={`form-control ${
                  formik.errors.password && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="password"
                name="password"
                type="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                placeholder="Password"
                style={{ height: "50px" }}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="confirm">
              <span className="top-placeholder">Confirm Password*</span>
              <input
                className={`form-control ${
                  formik.errors.confirm && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="confirm"
                name="confirm"
                type="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirm}
                placeholder="Confirm Password"
                style={{ height: "50px" }}
              />
            </label>
            <div className="col-md-3 mb-3">
              <label className="custCheck" htmlFor="active">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            <CancelBtn path=".." />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(Usermngadd);
