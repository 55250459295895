import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import VehicleRegType from "types/VehicleRegTypes";
import { VehicleMasterDataType } from "types/vehicleMasterType";
import { useLoading } from "Context/LoadingContext";
import { formatDate, formatDateToAdd } from "constants/Constants";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { Button, Modal } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import VehiclePucList from "../VehiclePucMaster/vehiclePucList";
import VehiclePucEditForm from "../VehiclePucMaster/vehiclePucEditForm";
import VehicleInsuranceMasterList from "../VehicleInsuranceMaster/VehicleInsuranceMasterList";

type Props = {};

const VehicleRegEditForm = (props: Props) => {
  const nav = useNavigate();
  const { state, pathname } = useLocation();
  const segments = pathname.split("/");
  const segment = segments[segments.length - 1];
  const [param] = useSearchParams();
  const [index, setIndex] = useState(Number(param.get("index")));
  const [vehicleReg, setVehicleReg] = useState<VehicleRegType>();
  const [vehicleList, setVehicleList] = useState<VehicleMasterDataType[]>([]);
  const [vehId, setVehId] = useState<string | undefined>("");
  const { setLoading } = useLoading();
  const [show, setShow] = useState(false);

  const departmentList = useCallback(async () => {
    try {
      setLoading(true);
      const resVehReg = await axiosInstance.get(
        `/master/getSingleVehicleRegistrationById/${state._id}`
      );
      const vehicle = resVehReg.data.vehicles as VehicleRegType;
      const modelIdForVeh = vehicle.model;
      setVehicleReg(vehicle);
      const resVeh = await axiosInstance.get(`/dropdown/getVehicleType`);
      const vehList = resVeh.data.vehicleTypes as VehicleMasterDataType[];

      const newVehList = updateDlist(vehList, modelIdForVeh);
      setVehId(modelIdForVeh);
      setVehicleList(newVehList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    departmentList();
  }, [departmentList]);

  const updateDlist = (dList: VehicleMasterDataType[], categoryId: string) => {
    const newDep = [...dList];
    const record = newDep?.find(
      (x: VehicleMasterDataType) => x?._id === categoryId
    );
    const index = newDep.indexOf(record as VehicleMasterDataType);
    newDep.splice(index, 1);
    newDep.splice(0, 0, record as VehicleMasterDataType);
    return newDep;
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      registrationNo: vehicleReg?.registrationNo,
      registrationDate: formatDateToAdd(vehicleReg?.registrationDate),
      licensePlatNo: vehicleReg?.licensePlatNo,
      totalSeats: vehicleReg?.totalSeats,
      ownerName: vehicleReg?.ownerName,
      contactNo: vehicleReg?.contactNo,
      description: vehicleReg?.description,
      active: vehicleReg?.isActive,
      isDeleted: false,
      seatNoRequired:
        vehicleReg?.seatNoRequired !== undefined
          ? vehicleReg?.seatNoRequired
          : true,
      note: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      registrationNo: Yup.string()
        .min(1, "Registration Number is Too Short")
        .max(30, "Registration Number is Too Long")
        .required("Registration Number is Required"),
      registrationDate: Yup.string().required("Registration Date is Required"),
      licensePlatNo: Yup.string()
        .matches(
          /^[A-Z]{2}-\d{1,2}-[A-Z]{1,2}-\d{1,4}$/,
          "Please Enter a Valid License Plate Number"
        )
        .min(1, "License Plate Number is Too Short")
        .max(15, "License Plate Number is Too Long")
        .required("License Plate Number is Required"),
      totalSeats: Yup.string()
        .matches(/^[0-9]\d*$/, "enter a valid seat number")
        .max(10, "Total Seats is Too Long")
        .when("SeatNoRequired", {
          is: true,
          then: Yup.string().required("Seat No is Required"),
        }),
      contactNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!"),
      ownerName: Yup.string().max(30, "Owner Name is Too Long"),
      description: Yup.string().max(300, "Description is Too Long"),
    }),
    onSubmit: async ({
      description,
      active,
      registrationDate,
      registrationNo,
      licensePlatNo,
      totalSeats,
      ownerName,
      contactNo,
      seatNoRequired,
      isDeleted,
    }) => {
      try {
        setLoading(true);
        const formData = {
          model: vehId,
          registrationNo: registrationNo,
          registrationDate:
            formatDate(vehicleReg?.registrationDate) ===
            formik.values.registrationDate
              ? formatDateToAdd(vehicleReg?.registrationDate)
              : registrationDate,
          licensePlatNo: licensePlatNo,
          totalSeats: String(totalSeats),
          ownerName: ownerName,
          contactNo: String(contactNo),
          description: description,
          note: "",
          details: "",
          isActive: active,
          isDeleted,
          seatNoRequired,
        };
        const res = await axiosInstance.put(
          `/master/updateVehicleRegistrationDetails/${state._id}`,
          formData
        );
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          // nav("/vehicleRegistration");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      {pathname !== "/vehicleRegistration" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/vehicleRegistration" />
        </div>
      )}
      <Tabs
        selectedIndex={index}
        onSelect={(e) => {
          setIndex(e);
        }}
      >
        <TabList>
          <Tab>Vehicle Registration</Tab>
          <Tab>Vehicle PUC</Tab>
          <Tab>Vehicle Insurance</Tab>
        </TabList>

        <TabPanel>
          <div className="formBg">
            <form
              className="row align-items-center "
              onSubmit={formik.handleSubmit}
            >
              <label htmlFor="" className="col-md-6 mb-3">
                <span className="top-placeholder">Vehicle Type*</span>
                <select
                  className="form-control"
                  id="department"
                  onChange={(e) => {
                    setVehId(e.target.value);
                  }}
                >
                  {vehicleList.map((item) => {
                    return (
                      <option key={item?._id} value={item?._id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="col-md-6 mb-3" htmlFor="registrationNo">
                <input
                  className={`form-control ${
                    formik.errors.registrationNo ? "empty-form" : ""
                  }`}
                  id="registrationNo"
                  name="registrationNo"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.registrationNo}
                  placeholder="Registration Number"
                />

                <span className="top-placeholder">Registration Number*</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="registrationDate">
                <input
                  className={`form-control session-date ${
                    formik.errors.registrationDate ? "empty-form" : ""
                  }`}
                  id="registrationDate"
                  name="registrationDate"
                  type="date"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.registrationDate}
                  // onFocus={(e) => {
                  //   e.target.type = "date";
                  // }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />

                <span className="top-placeholder">Registration Date*</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="licensePlatNo">
                <input
                  className={`form-control ${
                    formik.errors.licensePlatNo ? "empty-form" : ""
                  }`}
                  id="licensePlatNo"
                  name="licensePlatNo"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.licensePlatNo}
                  placeholder="License Plate Number (eg.GJ-01-AA-5050)"
                />

                <span className="top-placeholder">
                  License Plate Number (eg.GJ-01-AA-5050)*
                </span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="totalSeats">
                <input
                  className={`form-control ${
                    formik.errors.totalSeats ? "empty-form" : ""
                  }`}
                  id="totalSeats"
                  name="totalSeats"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.totalSeats}
                  placeholder="Total Seat"
                />

                <span className="top-placeholder">Total Seat*</span>
              </label>
              <label
                htmlFor="seatNoRequired"
                className="custCheck col-md-6 mb-3"
              >
                Seat No Required?
                <input
                  type="checkbox"
                  id="seatNoRequired"
                  name="seatNoRequired"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.seatNoRequired}
                />
                <span className="checkmark"></span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="contactNo">
                <input
                  className="form-control"
                  id="contactNo"
                  name="contactNo"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.contactNo}
                  placeholder="Contact Number"
                />

                <span className="top-placeholder">Contact Number</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="ownerName">
                <input
                  className="form-control"
                  id="ownerName"
                  name="ownerName"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.ownerName}
                  placeholder="Owner Name"
                />

                <span className="top-placeholder">Owner Name</span>
              </label>
              <label className="col-md-12 mb-3" htmlFor="description">
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  placeholder="Description"
                />

                <span className="top-placeholder">Description</span>
              </label>
              <div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="active" className="custCheck">
                    Active
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      checked={formik.values.active}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="isDeleted" className="custCheck">
                    Delete
                    <input
                      type="checkbox"
                      id="isDeleted"
                      name="isDeleted"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.checked === true) {
                          setShow(true);
                        }
                      }}
                      checked={formik.values.isDeleted}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3 text-center">
                <SubmitBtn errors={formik.errors} />
                <CancelBtn path=".." />
              </div>
            </form>
          </div>
          <Modal show={show}>
            <Modal.Body>
              Are you sure you want to delete this record?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  setShow(false);
                }}
              >
                Ok
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  formik.setFieldValue("isDeleted", false);
                  setShow(false);
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </TabPanel>
        <TabPanel>
          {pathname === "/vehicleRegistration/editForm/editPuc" ? (
            <Outlet />
          ) : (
            <VehiclePucList state={state} />
          )}
        </TabPanel>
        <TabPanel>
          {pathname === "/vehicleRegistration/editForm/editInc" ? (
            <Outlet />
          ) : (
            <VehicleInsuranceMasterList state={state} />
          )}
        </TabPanel>
      </Tabs>
    </>
  );
};

export default memo(VehicleRegEditForm);
