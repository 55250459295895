import axios from "axios";
// import { API_BASE_URL } from './constants/envVariables'

// export const MAIN_URL =/ "http://13.210.144.95";
// export const MAIN_URL = "http://localhost";
export const MAIN_URL = "https://my.dropikme.com/";
export const bucketBaseUrl = "https://dropikme.s3.ap-south-1.amazonaws.com/";

export const API_BASE_URL = `https://api.dropikme.com`;
const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  timeout: 15000,
  timeoutErrorMessage: "Timeout",
});

export const axiosAuth = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  timeout: 15000,
  timeoutErrorMessage: "Timeout",
});

axiosInstance.interceptors.request.use(function (config) {
  if (!localStorage.getItem("token")) {
    window.location.href = `${MAIN_URL}login`;
  } else {
    config.headers.Authorization = localStorage.getItem("token");
  }
  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
