import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { AreaDDLType } from "types/areaMasterTypes";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import SubmitBtn from "components/Buttons/Submit";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";

type Props = {};

const FeesAreaAddForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [param] = useSearchParams();
  const { state } = useLocation();
  const [area, setArea] = useState<AreaDDLType[]>([]);
  const [areaId, setAreaId] = useState("");
  const [areaDivId, setAreaDivId] = useState("");
  const [areaDiv, setAreaDiv] = useState<AreaDivisionDDL[]>();

  const loadAreas = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getUnassignedArea/${state._id}`
      );
      setArea(res.data.Areas);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadAreaCode = useCallback(async (pinCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getAreaUnassignedDivision?pinCode=${pinCode}&configId=${state._id}`
      );
      setAreaDiv(res.data.division);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadAreas();
  }, [loadAreas]);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      amount: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required("Amount is Required"),
    }),
    onSubmit: async ({ amount }) => {
      try {
        if (areaId === "" || areaDivId === "") return;
        const formData = {
          areaId,
          areaDivId,
          amount: String(amount),
          feesConfigId: state._id,
          description: "",
          note: "",
          details: "",
        };

        setLoading(true);
        const res = await axiosInstance.post("/master/addFeesArea", formData);
        if (res.data === "Created") {
          successToast();
          setLoading(false);
          const item = state;
          if (Number(param.get("fromEdit")) === 1) {
            nav(
              {
                pathname: "/transportationFeesMain/feesSectionEdit",
                search: "?index=2",
              },
              { state: item }
            );
          } else {
            nav(
              {
                pathname: "/transportationFeesMain/feesSection/areaList",
                search: "?index=2",
              },
              { state: item }
            );
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="area">
              {areaId !== "" && <span className="top-placeholder">Area</span>}
              <select
                className="form-control"
                id="area"
                name="area"
                onChange={(e) => {
                  setAreaId(e.target.value);
                  setAreaDiv([]);
                  loadAreaCode(e.target.value);
                }}
              >
                <option value="">Select Area</option>
                {area.map(({ _id, areaName, pinCode }) => {
                  return (
                    <option key={_id} value={pinCode}>
                      {`${areaName} (${pinCode})`}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="areaCode">
              {areaDivId !== "" && (
                <span className="top-placeholder">Division</span>
              )}
              <select
                className="form-control"
                id="areaCode"
                name="areaCode"
                onChange={(e) => {
                  setAreaDivId(e.target.value);
                }}
              >
                <option value="">Select Division</option>
                {areaDiv?.map(({ _id, areaCode }) => {
                  return (
                    <option key={_id} value={_id}>
                      {areaCode}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="amount">
              {formik.values.amount && (
                <span className="top-placeholder">Amount</span>
              )}
              <input
                className="form-control"
                id="amount"
                name="amount"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.amount}
                placeholder="Amount"
              />
            </label>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                if (areaId === "") warnToast("Select Area");
                if (areaDivId === "") warnToast("Select Area Division");
                toastValidation(formik.errors);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                const item = state;
                if (Number(param.get("fromEdit")) === 1) {
                  nav(
                    {
                      pathname: "/transportationFeesMain/feesSectionEdit",
                      search: "?index=2",
                    },
                    { state: item }
                  );
                } else {
                  nav(
                    {
                      pathname: "/transportationFeesMain/feesSection/areaList",
                      search: "?index=2",
                    },
                    { state: item }
                  );
                }
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(FeesAreaAddForm);
