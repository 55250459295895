import React, { memo, useCallback, useEffect, useState } from "react";
import axiosInstance from "axiosInstance";
import socket from "socket";
import CardComponent from "../Dashboard/CardComponent";
import memberWait from "../../../assets/images/memberWait.png";
type Props = {};

const LoginDrivers: React.FC<{}> = (props: Props) => {
  const [drivers, setDrivers] = useState(0);
  const loadDriver = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/mobile/getdrivercount`);
      setDrivers(res.data.NoOfDriverLogin);
    } catch (error) {
      console.log(error);
    }
  }, [drivers]);
  useEffect(() => {
    loadDriver();
    socket.on("admin-count", (data) => {
      if (data === "driver-login") {
        loadDriver();
      }
    });
  }, []);
  return (
    <>
      <CardComponent
        count={drivers}
        text="Login Drivers"
        background={memberWait}
      />
    </>
  );
};
export default memo(LoginDrivers);
