import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { NotificationType } from "types/notificationTypes";
import NotificationListItem from "./notificationListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";

type Props = {};

const NotificationList = (props: Props): JSX.Element => {
  const [Notification, setNotification] = useState<NotificationType[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("-createdAt");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { notfound, setNotFound } = useNotFound();

  const loadNotification = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `master/getAllNotification?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newNotifications: NotificationType[] = [
          ...res.data.notification[0].paginatedResults,
        ];
        if (newNotifications.length === 0) {
          if (searchKeyword !== "" && pageNum === 1) {
            setNotFound(true);
            setNotification([]);
            setSearchCount(0);
          }
        }
        if (newNotifications.length > 0) {
          setSearchCount(res.data.notification[0].totalCount[0].count);
          if (pageNum === 1) {
            setNotification(newNotifications);
          } else {
            setNotification((prevNotifications) => [
              ...prevNotifications,
              ...newNotifications,
            ]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    []
  );

  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadNotification(1, sort, search); // Trigger API call with updated search keyword
  };
  useEffect(() => {
    loadNotification(page, sort, searchQuery);
  }, [page, sort, searchQuery]);
  window.onscroll = () => {
    infiniteScroll(Notification, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Title or User Name"
      />
      <table className="table">
        <thead>
          <tr>
            <th id="Photo">Photo</th>
            <th
              id="title"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setNotification,
                  setPage
                );
              }}
            >
              Title
              {sort === "title" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="userType"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setNotification,
                  setPage
                );
              }}
            >
              User Type
              {sort === "userType" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="username"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setNotification,
                  setPage
                );
              }}
            >
              User Name
              {sort === "username" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="message"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setNotification,
                  setPage
                );
              }}
            >
              Message
              {sort === "message" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setNotification,
                  setPage
                );
              }}
            >
              Create On
              {sort === "createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setNotification,
                  setPage
                );
              }}
            >
              Time
              {sort === "createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {Notification?.map((x, index) => {
              return <NotificationListItem x={x} key={index} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(NotificationList);
