import {
  convertTo12HourFormat,
  dateFormatForAdmin,
  formatTime,
} from "constants/Constants";
import { memo } from "react";
import { ErrorsLogsType, ErrorsType } from "types/errorTypes";

type Props = {
  x: ErrorsLogsType;
};

const ErrorListItem = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td>{x.module}</td>
      <td>{x.action}</td>
      <td>{x.status === 0 ? "New" : "Old"}</td>
      <td>{x.action !== "updateBasicProfileDetils" ? x.jsonBody : ""}</td>
      <td>{x.stack}</td>
      <td>{x.message}</td>
      <td>{x.deviceDetails}</td>
      <td>{dateFormatForAdmin(x.createdAt)}</td>
      <td>{formatTime(x.createdAt)}</td>
    </tr>
  );
};

export default memo(ErrorListItem);
