import axiosInstance from "axiosInstance";
import React, { useCallback, useState, memo, useMemo, useEffect } from "react";
import { useLoading } from "Context/LoadingContext";
import { useTable, usePagination } from "react-table";
import { Column } from "react-table";
import "../../../assets/styles/style.css";
import {
  downloadTableAsPDF,
  exportPDF,
  exportToExcel,
  formatDate,
  downloadTableAsPrint,
  formatDateWithNull,
  formatDateForFile,
} from "constants/Constants";
import NotFound from "components/NotFound";
import { downloadExcel } from "constants/ReportFun";

type Props = {};
type DataRow = {
  _id: string;
  name: string;
  memberContact: string;
  vehicleNo: string;
  shiftName: string;
  driverName: string;
  routeName: string;
  startDatePickoff: string;
  endDatePickoff: string;
  startDateDropoff: string;
  endDateDropoff: string;
  pickupoffStaus: boolean;
  dropoffStaus: boolean;
};

const BasicDriverPickupOffDropOffReport = (props: Props) => {
  const [data, setData] = useState([]);
  // const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [notFound, setNotFound] = useState<boolean>(false);
  const { setLoading } = useLoading();

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  const currentYear = currentDate.getFullYear();
  const year = Array.from({ length: 3 }, (_, i) => currentYear - i);

  const [selectedMonth, setSelectedMonth] = useState(currentMonth) as any;
  const [selectedYear, setSelectedYear] = useState(currentYear) as any;

  const loadReports = useCallback(
    async (month: number, year: number) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/report/GetBasicDriverPickupOffDropOffReport?month=${month}&year=${year}`
        );
        setData(res.data.resp);
        setLoading(false);
        setNotFound(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setNotFound(true);
      }
    },
    [data, selectedMonth, selectedYear]
  );

  const GetData = () => {
    loadReports(selectedMonth, selectedYear);
  };

  useEffect(() => {
    GetData();
  }, []);
  const columns: readonly Column<DataRow>[] = useMemo(
    () => [
      {
        Header: "Driver Name",
        accessor: "name",
      },
      {
        Header: "Shift",
        accessor: "shiftName",
      },

      {
        Header: "Status",
        accessor: (row: any) => {
          return row?.pickupoffStaus ? "Pickup Off" : "Drop Off";
        },
      },
      {
        Header: "Start From",
        accessor: (row: any) => {
          return formatDateWithNull(row?.startDate);
        },
      },
      {
        Header: "End To",
        accessor: (row: any) => {
          return formatDateWithNull(row?.endDate);
        },
      },
      {
        Header: "Created Date",
        accessor: (row: any) => formatDate(row.createdAt),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as any,
    },
    usePagination
  );

  const download = async () => {
    try {
      downloadExcel(
        [
          {
            id: "name",
            name: "Driver Name",
          },
          {
            id: "shiftName",
            name: "Shift",
          },

          {
            id: "pickupoffStaus",
            name: "Status",
            isBooleanWait: true,
          },
          {
            id: "startDate",
            name: "Start From",
            isDate: true,
          },
          {
            id: "endDate",
            name: "End To",
            isDate: true,
          },
          {
            id: "createdAt",
            name: "Created Date",
            isDate: true,
          },
        ],
        data === undefined ? [] : data,
        "Pickup_DropOff_Driver",
        "Pickup_DropOff_Driver"
      );
    } catch (error) {
      return {};
    }
  };
  const todayDate = new Date();
  const dateForFile = formatDateForFile(todayDate.toISOString());
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Driver Pickup/Drop Off</span>
      </div>
      <button
        onClick={() =>
          downloadTableAsPDF(
            columns,
            data,
            `Pickup_DropOff_Driver-${dateForFile}.pdf`
          )
        }
        className="btn btn-primary mb-4"
      >
        Export to PDF
      </button>
      <button
        onClick={() => {
          download();
        }}
        className="btn btn-primary mb-4"
      >
        Export to Excel
      </button>
      <button
        onClick={() => downloadTableAsPrint(columns, data)}
        className="btn btn-primary mb-4"
      >
        Print
      </button>
      <div className="row">
        <div className="formBg col-5 mb-6">
          <div className="mb-2 row align-items-center">
            <label className=" mb-3" htmlFor="year-dropdown">
              <select
                className="form-control"
                id="year-dropdown"
                name="year-dropdown"
                onChange={async (e) => {
                  const selectedYear = parseInt(e.target.value);
                  setSelectedYear(selectedYear);
                }}
                value={selectedYear}
              >
                {year.map((year) => (
                  <option key={year} value={year} label={year.toString()} />
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className="formBg col-5 mb-6">
          <div className="mb-2 row align-items-center">
            <label className="mb-3" htmlFor="month-dropdown">
              <select
                className="form-control"
                id="month-dropdown"
                name="month-dropdown"
                onChange={async (e) => {
                  const selectedMonth = parseInt(e.target.value);
                  setSelectedMonth(selectedMonth);
                }}
                value={selectedMonth}
              >
                {months.map((m) => {
                  return (
                    <option
                      key={m}
                      value={m}
                      label={new Date(2000, m - 1, 1).toLocaleString(
                        "default",
                        {
                          month: "long",
                        }
                      )}
                    />
                  );
                })}
              </select>
            </label>
          </div>
        </div>

        <div className="formBg col-2 mb-6">
          <div className="mb-2 row align-items-center">
            <button
              onClick={() => {
                setData([]);
                GetData();
              }}
              className="btn btn-primary mb-4"
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <table {...getTableProps()} id="report-table" className="tableForReport">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="trForReport">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="thForReport">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="trForReport">
                {row.cells.map((cell: any, cellIndex: number) => {
                  return (
                    <td
                      key={cellIndex}
                      {...cell.getCellProps()}
                      className="tdForReport"
                    >
                      {/* Check if it's the "Is PickupOff" or "Is DropOff" column */}
                      {cell.column.id === "pickupoffStaus" ||
                      cell.column.id === "dropoffStaus"
                        ? cell.value === true
                          ? "Yes"
                          : cell.value === false
                          ? "No"
                          : ""
                        : cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {notFound && (
        <div className="mt-4">
          {" "}
          <NotFound />
        </div>
      )}
      <div className="pagination m-4 gap-2">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">>"}
        </button>{" "}
        <span className="mx-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(BasicDriverPickupOffDropOffReport);
