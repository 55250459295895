import React from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { MemberDataType } from "types/MemberMaster.types";
import { DepartmentDDLType } from "../../../types/departmentMasterType";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { drvImage, memImage } from "constants/Constants";
type Props = {
  item: MemberDataType;
  department: DepartmentDDLType[];
};

const MemberListItems = ({ item, department }: Props) => {
  const nav = useNavigate();

  const handleEditClick = () => {
    nav("editForm", {
      state: { member: item },
    });
  };

  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  let userTypeImage = item.photo
    ? `${memImage}${item.photo}`
    : `${memImage}Default.png`;

  return (
    <tr key={item._id}>
      <td>
        <i className="userImg">
          <img src={userTypeImage} alt="MemberPic" />
        </i>
      </td>
      <td>{item.name}</td>
      <td>{item.memberId}</td>
      <td>{item.depName}</td>
      <td>{item.catName}</td>
      <td>{item.routeName}</td>

      <td>{item.locationName}</td>
      <td className="text-center">{item.isActive ? <Eye /> : <EyeOff />}</td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={item._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default MemberListItems;
