import React, { memo } from "react";
import { ConfigurationDataType } from "../../../types/configurationTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
type Props = {
  x: ConfigurationDataType;
};

const PlnReqCongItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();

  const tooltip = <Tooltip id={`tooltip-${x.id}`}>Edit </Tooltip>;
  return (
    <tr key={x.id}>
      <td>{x.key}</td>
      <td>{x.value}</td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            id={x.id}
            className="pointer"
            onClick={() => {
              nav("plnConfigurationEdit", {
                state: x,
              });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(PlnReqCongItem);
