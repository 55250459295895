import React, { useState, memo } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

type Props = {};
type ContextType = {
  setMemberLeaveId: (memberLeaveId: string) => void;
  memberLeaveId: string;
};

const MemberLeave: React.FC<{}> = (props: Props) => {
  const [memberLeaveId, setMemberLeaveId] = useState<string>("");

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Member Leave</span>
      </div>
      <Outlet context={{ memberLeaveId, setMemberLeaveId }} />
    </>
  );
};

export default memo(MemberLeave);

export function useMemberLeaveId() {
  return useOutletContext<ContextType>();
}
