import React from "react";
import Footer from "../Footer";
import SideBar from "../SidebarMenu/sideBar";
import { Outlet } from "react-router-dom";
import { FeesConfigProvider } from "Context/FeesConfigurationContext";

const Layout = () => {
  return (
    <>
      <FeesConfigProvider>
        <section id="wrapper">
          <SideBar />
          <section id="content">
            <section className="content">
              <Outlet />
            </section>
          </section>
        </section>
        <Footer />
      </FeesConfigProvider>
    </>
  );
};

export default Layout;
