import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { AdminClientDataType } from "../../../types/adminClientTypes";
import AdminClientListItem from "./clientListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { useLoading } from "Context/LoadingContext";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import AddSearch from "components/AddSearchComponent";

type Props = {};

const AdminClientList = (props: Props): JSX.Element => {
  const [clients, setClients] = useState<AdminClientDataType[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const loadClients = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `admin/getClientDetails?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newClient: AdminClientDataType[] =
          res.data.client[0].paginatedResults;

        if (searchKeyword !== "" && pageNum === 1) {
          if (newClient.length === 0) {
            setClients([]);
            setSearchCount(0);
          }
        }
        if (newClient.length > 0) {
          setSearchCount(res.data.client[0].totalCount[0].count);
          if (pageNum === 1) {
            setClients(newClient);
          } else {
            setClients((prevclient) => [...prevclient, ...newClient]);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [clients]
  );
  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadClients(1, sort, search); // Trigger API call with updated search keyword
  };
  useEffect(() => {
    loadClients(page, sort, searchQuery);
  }, [page, sort, ascDsc, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(clients, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Name or Plan Name"
      />
      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setClients, setPage);
              }}
            >
              Name
              {sort === "name" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="email"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setClients, setPage);
              }}
            >
              Email
              {sort === "email" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="contactNo"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setClients, setPage);
              }}
            >
              Contact No
              {sort === "contactNo" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="bussinessName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setClients, setPage);
              }}
            >
              Bussiness
              {sort === "bussinessName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="planName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setClients, setPage);
              }}
            >
              Plan
              {sort === "planName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th>PromoCode</th>
            <th>PromoCode used</th>
          </tr>
        </thead>
        <tbody>
          {clients?.map((x) => {
            return <AdminClientListItem x={x} key={x._id} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(AdminClientList);
