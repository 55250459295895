import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Polyline,
  Marker,
  useLoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import axiosInstance from "axiosInstance";
import NotFound from "components/NotFound";
import { containerStyle, center } from "types/driverTracking";
import { libraries } from "../../../constants/Constants";
import mapHome from "../../../assets/images/home-map.png";
import mapBus from "../../../assets/images/mapBus.png";
import mapSchool from "../../../assets/images/map_school.jpg";
import {
  Data,
  PickDropType,
  cenDuration,
  StopMarker,
} from "types/alltrackingTypes";

const TrialMapRoute: React.FC = () => {
  const [separatedLatLng, setSetseparatedLatLng] = useState<Data[]>(
    [] as Data[]
  );
  const [openInfoWindow, setOpenInfoWindow] = useState<Data | null>(null);
  const [selectedMarker, setSelectedMarker] = useState<PickDropType | null>(
    null
  );
  const [selectedStopMarker, setSelectedStopMarker] =
    useState<cenDuration | null>(null);
  const [infoWindoPosition, setInfoWindoPosition] = useState<center | null>(
    null
  );
  const [pickUpDropMarkers, setPickUpDropMarkers] = useState<JSX.Element[]>([]);
  const [stopMarker, setStopMarker] = useState<JSX.Element[]>([]);
  const [avglat, setAvglat] = useState<number>();
  const [avglng, setAvglng] = useState<number>();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries: libraries as (
      | "places"
      | "drawing"
      | "geometry"
      | "localContext"
      | "visualization"
    )[],
  });

  const handlePickDropClick = (item: PickDropType) => {
    setSelectedMarker(item);
  };

  const fetchPickUpDropLocation = async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getAllVehiclePickUpDropLatLong`
      );
      const data: PickDropType[] = res.data.pickupdroplatlong;
      const routeIds = separatedLatLng.map((vehicle) => vehicle.routeId);
      const filteredData = data.filter((item) =>
        routeIds.includes(item.routeId)
      );
      const markers = filteredData.map((item, index) => (
        <Marker
          key={index}
          position={{
            lat: Number(item.records[0].lat),
            lng: Number(item.records[0].lng),
          }}
          title={`Pick and Drop Location`}
          icon={{
            url: mapHome,
            scaledSize: new google.maps.Size(32, 32),
          }}
          onClick={() => {
            handlePickDropClick(item);
            setInfoWindoPosition(item.records[0]);
          }}
        />
      ));
      setPickUpDropMarkers(markers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPickUpDropLocation();
  }, [separatedLatLng]);

  const handleStopLocationClick = (item: cenDuration) => {
    setSelectedStopMarker(item);
  };

  const fetchStopLocation = async () => {
    try {
      const res = await axiosInstance.get(`/dropdown/getStopCount`);
      const data: StopMarker[] = res.data.result;
      const stopMarkers = data.map((item, index) =>
        item.records.map((record, recordIndex) => (
          <Marker
            key={`${index}-${recordIndex}`}
            position={{
              lat: record.lat,
              lng: record.lng,
            }}
            title={`Stop Location`}
            icon={{
              url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
              scaledSize: new google.maps.Size(32, 32),
            }}
            onClick={() => {
              handleStopLocationClick(record);
            }}
          />
        ))
      );
      setStopMarker(stopMarkers.flat());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchStopLocation();
  }, [separatedLatLng]);

  useEffect(() => {
    const getLatLng = async () => {
      try {
        const res = await axiosInstance.get("dropdown/getLatLngVeh");
        setSetseparatedLatLng(res.data.result);
      } catch (error) {}
    };

    const interval = setInterval(getLatLng, 60000);

    getLatLng();

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let totalLat = 0;
    let totalLng = 0;
    let totalRecords = 0;

    separatedLatLng.forEach((item: Data) => {
      item.records.forEach((record: center) => {
        totalLat += record.lat;
        totalLng += record.lng;
        totalRecords++;
      });
    });

    if (totalRecords > 0) {
      const avgLat = totalLat / totalRecords;
      const avgLng = totalLng / totalRecords;

      setAvglat(avgLat);
      setAvglng(avgLng);
    }
  }, [separatedLatLng]);

  if (!isLoaded) return null;

  const center: center = {
    lat: avglat || 0,
    lng: avglng || 0,
  };

  const handleMarkerClick = (vehicle: Data) => {
    setOpenInfoWindow(vehicle);
  };

  const infoWindowPosition = infoWindoPosition
    ? { lat: infoWindoPosition.lat, lng: infoWindoPosition.lng }
    : undefined;

  const renderMarkersAndPolylines = () => {
    return separatedLatLng.map((vehicle: Data, index: number) => (
      <React.Fragment key={index}>
        <Marker
          position={vehicle.records[0]}
          icon={{
            url: mapSchool,
            scaledSize: new google.maps.Size(32, 32),
          }}
          label={{
            text: vehicle.routeName,
            className: "map_title",
            color: "#ffffff",
          }}
          title={`Start Location`}
          onClick={() => {
            handleMarkerClick(vehicle);
            setInfoWindoPosition(vehicle.records[0]);
          }}
        />
        <Marker
          position={vehicle.records[vehicle.records.length - 1]}
          icon={{
            url: mapBus,
            scaledSize: new google.maps.Size(40, 40),
          }}
          title={`Current Location`}
          label={{
            text: vehicle.routeName,
            className: "map_title",
            color: "#ffffff",
          }}
          onClick={() => {
            handleMarkerClick(vehicle);
            setInfoWindoPosition(vehicle.records[vehicle.records.length - 1]);
          }}
        />
        {pickUpDropMarkers}
        {stopMarker}
        <Polyline
          key={`polyline-${vehicle?._id}`}
          path={vehicle?.records}
          options={{
            strokeColor: "#0066ff",
            strokeOpacity: 1,
            strokeWeight: 6,
            icons: [
              {
                icon: {
                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  scale: 3, // Adjust the scale value to make the icon smaller
                },
                offset: "100%",
              },
            ],
          }}
        />
        {openInfoWindow && openInfoWindow === vehicle && (
          <InfoWindow
            position={infoWindowPosition}
            onCloseClick={() => {
              setOpenInfoWindow(null);
              setInfoWindoPosition(null);
            }}
          >
            <div>
              <strong>DriverName:</strong>{" "}
              <span>{openInfoWindow.driverName}</span> <br />
              <strong>Contact:</strong> <span>{openInfoWindow.contactNo}</span>
              <br />
              <strong>Number Plat:</strong>{" "}
              <span>{openInfoWindow.licensePlatNo}</span>
            </div>
          </InfoWindow>
        )}
        {selectedMarker && (
          <InfoWindow
            position={{
              lat: Number(selectedMarker.records[0].lat),
              lng: Number(selectedMarker.records[0].lng),
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div>
              <strong>Area:</strong> <span>{selectedMarker.area}</span>
              <br />
              <strong>Division:</strong> <span>{selectedMarker.division}</span>
              <br />
              <strong>Location:</strong> <span>{selectedMarker.location}</span>
            </div>
          </InfoWindow>
        )}
        {selectedStopMarker && (
          <InfoWindow
            position={{
              lat: selectedStopMarker.lat,
              lng: selectedStopMarker.lng,
            }}
            onCloseClick={() => setSelectedStopMarker(null)}
          >
            <div>
              <span>{selectedStopMarker.duration}</span>
            </div>
          </InfoWindow>
        )}
      </React.Fragment>
    ));
  };
  return (
    <>
      <div className="col-lg-8">
        {separatedLatLng.length > 0 ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={15}
            center={center}
          >
            {renderMarkersAndPolylines()}
          </GoogleMap>
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
};

export default TrialMapRoute;
