import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { descSlice, drvImage } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AttendantMasterType } from "types/AttendantTypes";

type Props = {
  x: AttendantMasterType;
};

const AttendantListItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();

  const handleEditClick = () => {
    nav("attendantEdit", {
      state: { x },
    });
  };

  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit </Tooltip>;
  return (
    <tr key={x._id}>
      <td>
        <i className="userImg">
          <img
            src={`${drvImage}${x.photo !== "" ? x.photo : "Default.png"}`}
            alt="DriverPic"
          />
        </i>
      </td>
      <td>{x.name}</td>
      <td>{x.contactNo}</td>
      <td>{descSlice(x.description)}</td>
      <td className="text-center">
        {x.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={x._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(AttendantListItem);
