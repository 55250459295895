import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { VehiclePucMasterDataType } from "../../../types/vehiclePucTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { VehicleMasterDataType } from "types/vehicleMasterType";
import { formatDate } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: VehiclePucMasterDataType;
  state: any;
};

const VehiclePucMasterListItem = ({ x, state }: Props): JSX.Element => {
  const nav = useNavigate();

  const handleEditClick = () => {
    const item = { ...state, pucId: x._id };
    nav("/vehicleRegistration/editForm/editPuc", {
      state: item,
    });
  };

  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit </Tooltip>;

  return (
    <tr key={x._id}>
      <td>{x.result.licensePlatNo}</td>
      <td>{formatDate(x.pucDate)}</td>
      <td>{formatDate(x.validUptoDate)}</td>
      <td>{x.name}</td>
      <td className="text-center pointer">
        {/* <Edit
          id={x._id}
          onClick={() => {
            const item = { ...state, pucId: x._id };
            nav("/vehicleRegistration/editForm/editPuc", {
              state: item,
            });
          }}
        /> */}
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={x._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(VehiclePucMasterListItem);
