import TrialMapRoute from "../TrialMapRoute";
import MapLeftInfo from "./mapLeftinfo";
import MapRightInfo from "./mapRightInfo";

const CommonMap = () => {
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>All Tracking</span>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <MapLeftInfo />
            <TrialMapRoute />
            <MapRightInfo />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonMap;
