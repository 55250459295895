import { memo, useState } from "react";
import { useFormik } from "formik";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import * as Yup from "yup";
import { successToast } from "constants/toastConfig";
import { orgImage } from "constants/Constants";
type Props = {};

const ProfileEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: state.name,
      contactNo: state.contactNo,
      email: state.email,
      orgphoto: state?.photo,
      companyName: state?.businessName,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Name is Too Short!")
        .max(30, "Name is Too Long!")
        .required("Name is Required"),
      email: Yup.string().email().max(50, "Email is too long"),
      contactNo: Yup.string()
        .min(10, "Contact Number is too Short")
        .max(10, "Contact Number is Too Long!")
        .required("Contact Number is Required"),
      orgphoto: Yup.mixed()
        .test("fileSize", "photo size is too high", (value) => {
          if (!value) return true; // Skip the test if no file is selected
          if (typeof value === "string") return true; // Skip the test for default file string
          return value && value.size <= 52428800; // 50MB in bytes
        })
        .test(
          "fileType",
          "Please enter the photo in JPG, JPEG, PNG extension.",
          (value) => {
            if (!value) return true; // Skip the test if no file is selected
            if (typeof value === "string") return true; // Skip the test for default file string
            return value && /(\.jpg|\.jpeg|\.png)$/i.test(value.name); // Check if the file has a valid extension
          }
        ),
      companyName: Yup.string()
        .min(1, "Business Name is Too Short!")
        .max(30, "Business Name is Too Long!")
        .required("Business Name is Required"),
    }),
    onSubmit: async ({ name, contactNo, email, orgphoto, companyName }) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("name", name || "");
        formData.append("orgphoto", orgphoto || ""); // Assuming 'photo' is the selected file object
        formData.append("contactNo", String(contactNo) || "");
        formData.append("email", email || "");
        formData.append("companyName", companyName || "");
        const res = await axiosInstance.put(
          `/master/updateOrganizationById/${state._id}`,
          formData
        );
        if (res.data === "Ok") {
          successToast();
          nav("/profile");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <div className="profilePic mt-1">
          <div className="profilePicInner">
            <img
              src={
                imageUrl ||
                `${orgImage}${
                  state?.photo !== "" ? state.photo : "Default.png"
                }`
              }
              alt="organizer"
            />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <label className="col-md-6 mb-3 px-2" htmlFor="name">
            <input
              className={`form-control ${
                formik.errors.name ? "empty-form" : ""
              }`}
              id="name"
              name="name"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="Organizer Name"
            />
            <span className="top-placeholder">Name*</span>
          </label>
          <label className="col-md-6 mb-3 px-2" htmlFor="companyName">
            <input
              className={`form-control ${
                formik.errors.companyName ? "empty-form" : ""
              }`}
              id="companyName"
              name="companyName"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.companyName}
              placeholder="Business Name"
            />
            <span className="top-placeholder">Business Name*</span>
          </label>
          <label className="col-md-6 mb-3 px-2" htmlFor="contactNo">
            <input
              className={`form-control ${
                formik.errors.contactNo ? "empty-form" : ""
              }`}
              id="contactNo"
              name="contactNo"
              type="number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contactNo}
              placeholder="Contact Number"
            />
            <span className="top-placeholder">Contact*</span>
          </label>
          <label className="col-md-6 mb-3 px-2" htmlFor="email">
            <input
              className="form-control"
              id="email"
              name="email"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="Email"
            />
            <span className="top-placeholder">Email*</span>
          </label>
          <label className="col-md-6 mb-3 px-2" htmlFor="orgphoto">
            <input
              className="form-control uploadPhoto"
              id="orgphoto"
              name="orgphoto"
              type="file"
              onBlur={formik.handleBlur}
              onChange={(event) => {
                const file =
                  event.currentTarget.files && event.currentTarget.files[0];
                if (file) {
                  const url = URL.createObjectURL(file);
                  setImageUrl(url);
                }
                formik.setFieldValue("orgphoto", file || ""); // If file is null, set an empty string
              }}
              placeholder="Enter photo"
            />
          </label>
          <div className="text-center">
            <SubmitBtn errors={formik.errors} />
            <CancelBtn path=".." />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(ProfileEditForm);
