import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { FeesByVehicleList } from "types/feesAreaTypes";
import { currencySymbol } from "constants/Constants";

type Props = {
  x: FeesByVehicleList;
  state: any;
  segment: any;
};

const FeesByVehicleListItems = ({ x, state, segment }: Props) => {
  const nav = useNavigate();

  return (
    <tr key={x._id}>
      <td>{x.vehicleTyp}</td>
      <td>{x.licensePlatNo}</td>
      <td>{x.shift}</td>
      <td>{x.area}</td>
      <td>{x.division}</td>
      <td>
        {currencySymbol}
        {x.amount}
      </td>
      {state.configId === state._id && (
        <td className="text-center">
          {x.isActive === true ? <Eye /> : <EyeOff />}
        </td>
      )}
      {state.configId === state._id && state.isPaymentMade === false && (
        <td className="text-center pointer">
          <Edit
            id={x._id}
            onClick={(e) => {
              const item = {
                ...state,
                vehicleEditId: x._id,
                shift: x.shift,
                vehicleTyp: x.vehicleTyp,
                licensePlatNo: x.licensePlatNo,
                area: x.area,
                division: x.division,
              };
              if (segment === "feesSectionEdit") {
                nav(
                  {
                    pathname:
                      "/transportationFeesMain/feesSection/vehicleEditForm",
                    search: "?index=2&fromEdit=1",
                  },
                  {
                    state: item,
                  }
                );
              } else {
                nav(
                  {
                    pathname:
                      "/transportationFeesMain/feesSection/vehicleEditForm",
                    search: "?index=2",
                  },
                  {
                    state: item,
                  }
                );
              }
            }}
          />
        </td>
      )}
    </tr>
  );
};

export default memo(FeesByVehicleListItems);
