import React, { memo } from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReqForPlans } from "./List";
import { useNavigate } from "react-router-dom";
import { formatDate } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  item: ReqForPlans;
};

const PlanRequestsListItem = ({ item }: Props) => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  const ViewToolTip = <Tooltip id={`tooltip-${item._id}`}>View</Tooltip>;
  return (
    <tr key={item._id}>
      <td>{formatDate(item.createdAt)}</td>
      <td>{item.organizerName}</td>
      <td>{item.planType}</td>
      <td>{item.planName}</td>
      <td>{item.amount}</td>
      <td>{item.paymentCheck ? "Yes" : "No"}</td>
      <td>{item.isMultiShift ? "Yes" : "No"}</td>
      <td>{item.rfidCardCheck ? "Yes" : "No"}</td>
      <td className="text-center">{item.isVerified === true ? "Yes" : "No"}</td>
      <td className="text-center">
        {item.isActive === true ? <Eye /> : <EyeOff />}
      </td>

      <td className="text-center">
        <div className="d-flex align-items-center justify-content-between">
          <div className="tPaddings">
            <OverlayTrigger placement="right" overlay={ViewToolTip}>
              <Eye
                className="Approved_Size"
                role="button"
                id={`view-${item._id}`}
                onClick={() => {
                  nav("configurationDetails", {
                    state: item,
                  });
                }}
              />
            </OverlayTrigger>
          </div>
          <div className="tPaddings">
            <OverlayTrigger placement="right" overlay={tooltip}>
              <Edit
                role="button"
                id={`edit-${item._id}`}
                onClick={() => {
                  nav("/planRequests/editForm", { state: item });
                }}
              />
            </OverlayTrigger>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default memo(PlanRequestsListItem);
