import React, { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AreaMasterDataType } from "types/areaMasterTypes";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReactComponent as Location } from "assets/images/location-17.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { addLineBreaks } from "constants/Constants";
import axiosInstance from "axiosInstance";
import { useLoading } from "Context/LoadingContext";
import { AreaDivListType } from "types/areaDivisionTypes";
import { useNotFound } from "Context/NotFound";
import { debounce } from "lodash";
import { locationListDataType } from "types/locationMasterType";
import NotFound from "components/NotFound";

type Props = {
  item: AreaMasterDataType;
};

const AreaMasterListItem = ({ item }: Props) => {
  const nav = useNavigate();
  const [showPopover, setShowPopover] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { setLoading, loading } = useLoading();
  const [division, setDivision] = useState<any[]>([]);
  const [notfoundForModel, setNotFoundForModel] = useState(false);

  const handleEditClick = () => {
    nav("editForm", {
      state: { item },
    });
  };
  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  const tooltipDetail = <Tooltip id={`tooltip-${item._id}`}>Details</Tooltip>;

  const onSubmit = async (
    pageNum: number,
    sortParam: string,
    searchKeyword: string
  ) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getAllLocation?page=${pageNum}&sort=${sortParam}&pinCode=${item?.pinCode}&search=${searchKeyword}`
      );

      const newRouteAllMemberList: locationListDataType[] = [
        ...res.data.location[0].paginatedResults,
      ];

      if (newRouteAllMemberList.length === 0) {
        if (searchKeyword !== "" && pageNum === 1) {
          setNotFoundForModel(true);
          setDivision([]);
          setSearchCount(0);
        }
      } else {
        setSearchCount(res?.data?.location[0]?.SearchCount[0]?.count);
        if (pageNum === 1) {
          setDivision(newRouteAllMemberList);
        } else {
          setDivision((prevList) => [...prevList, ...newRouteAllMemberList]);
        }
        setNotFoundForModel(false);
      }

      setLoading(false);
    } catch (error) {
      setNotFoundForModel(true);
      console.log(error);
      setLoading(false);
    }
  };

  const openModal = () => {
    onSubmit(page, sort, "");
    setShowPopover(true);
    setNotFoundForModel(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShowPopover(false);
    }
  };

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    onSubmit(1, sort, search);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleScroll = debounce(() => {
    if (
      modalRef.current &&
      modalRef.current.scrollTop + modalRef.current.clientHeight >=
        modalRef.current.scrollHeight - 20 &&
      !loading &&
      division.length < searchCount
    ) {
      setLoading(true);
      const nextPage = page + 1;
      setPage(nextPage);
      onSubmit(nextPage, sort, searchQuery);
    }
  }, 300);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (modalRef.current) {
        modalRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [division]);

  return (
    <tr key={item._id}>
      <td>{item.areaName}</td>
      <td>{item.pinCode}</td>
      <td>
        {addLineBreaks(item.areaDivision.join(", "), 50)
          .split("\n")
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
      </td>
      <td>
        <div className="my-component">
          <OverlayTrigger placement="right" overlay={tooltipDetail}>
            <Location
              className="Location_img"
              onClick={() => {
                openModal();
              }}
            />
          </OverlayTrigger>

          {showPopover && (
            <>
              <div
                className="modal-overlay"
                style={{ left: "323px" }}
                ref={modalRef}
              >
                <div
                  className="close"
                  style={{
                    width: "100%",
                    textAlign: "end",
                    padding: "10px 10px 0px 0px",
                  }}
                >
                  <span
                    role="button"
                    onClick={() => {
                      setShowPopover(false);
                      setSearch("");
                    }}
                  >
                    Close
                  </span>
                </div>
                <div className="modal-title ">
                  <span>Division Details</span>
                </div>
                <div>
                  <div className="formBg mb-3" style={{ width: "980px" }}>
                    <form
                      action=""
                      onSubmit={(e: any) => {
                        e.preventDefault();
                        handleSearch();
                      }}
                    >
                      <div className="d-flex" style={{ width: "960px" }}>
                        <label className="flex-fill">
                          <input
                            className="form-control  "
                            type="text"
                            placeholder="Area Division or Location"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </label>
                        <div style={{ paddingRight: "15px" }}>
                          <button className="btn btn-primary" type="submit">
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <table
                  className="table"
                  style={{
                    padding: "10px",
                    marginTop: "15px",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Area Division</th>
                      <th>Location</th>
                    </tr>
                  </thead>
                  {!notfoundForModel && (
                    <tbody>
                      {division?.map((x: any) => {
                        return (
                          <tr key={Math.random()}>
                            <td>{x.division}</td>
                            <td>{x.name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                  {notfoundForModel && (
                    <tbody>
                      <tr>
                        <td colSpan={2} style={{ textAlign: "center" }}>
                          <NotFound />
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </>
          )}
        </div>
      </td>
      <td className="text-center">
        {item.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={item._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(AreaMasterListItem);
