import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import RouteMemberListType from "types/RouteMemberList";

type Props = {
  x: RouteMemberListType;
};

const RouteMemberListItem = ({ x }: Props) => {
  const {
    _id,
    depName,
    dropLocation,
    dropRoute,
    dropSeatNo,
    dropVehicle,
    isSame,
    memName,
    pickLocation,
    pickRoute,
    pickSeatNo,
    pickVehicle,
    shift,
    isActive,
  } = x;
  const nav = useNavigate();
  return (
    <tr key={_id}>
      <td>{shift}</td>
      <td>{depName}</td>
      <td>{memName}</td>
      <td>{pickRoute}</td>
      <td>{pickLocation}</td>
      <td>{pickVehicle}</td>
      <td>{pickSeatNo}</td>
      <td>{dropRoute}</td>
      <td>{dropLocation}</td>
      <td>{dropVehicle}</td>
      <td>{dropSeatNo}</td>
      <td>{isSame ? "Yes" : "No"}</td>
      <td className="text-center">
        {isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center">
        <Edit
          role="button"
          id={_id}
          onClick={(e) => {
            nav("editForm", {
              state: x,
            });
          }}
        />
      </td>
    </tr>
  );
};

export default memo(RouteMemberListItem);
