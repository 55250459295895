import axiosInstance from "axiosInstance";
import React, { useCallback, useState, memo, useMemo, useEffect } from "react";
import { useLoading } from "Context/LoadingContext";
import { useTable, usePagination } from "react-table";
import { Column } from "react-table";
import "../../../assets/styles/style.css";
import {
  downloadTableAsPDF,
  exportPDF,
  exportToExcel,
  formatDate,
  downloadTableAsPrint,
  formatDateWithNull,
  formatTime,
  formatDateToAdd,
  formatDateForFile,
} from "constants/Constants";
import NotFound from "components/NotFound";
import { convertTo12HourFormat } from "constants/Constants";
import { downloadExcel } from "constants/ReportFun";
type Props = {};
type DataRow = {
  _id: string;
  name: string;
  contactNo: string;
  vehicleNo: string;
  shiftName: string;
  routeName: string;
  date: string;
  createdAt: string;
  isOn: boolean;
  isOff: boolean;
};

const DriverOnOffReport = (props: Props) => {
  const [data, setData] = useState([]);
  const [notFound, setNotFound] = useState<boolean>(false);
  const { setLoading } = useLoading();
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const loadReports = useCallback(async (date: string) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/report/getNormalDriverONOffDetails?date=${date}`
      );
      setData(res.data.driverONOffReportDetails);
      setLoading(false);
      setNotFound(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setNotFound(true);
    }
  }, []);

  const handleDateChange = (e: any) => {
    setSelectedDate(e.target.value);
  };

  const handleSearchButtonClick = () => {
    loadReports(selectedDate);
    setData([]);
  };

  useEffect(() => {
    handleSearchButtonClick();
  }, []);

  // const handleDateChange = (e: any) => {
  //   const selectedDate = e.target.value;
  //   setSelectedDate(selectedDate);
  //   setData([]);
  // };
  // useEffect(() => {
  //   loadReports(selectedDate);
  // }, [selectedDate, loadReports]);

  const columns: readonly Column<DataRow>[] = useMemo(
    () => [
      {
        Header: "Driver Name",
        accessor: "name",
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
      },
      {
        Header: "Route",
        accessor: "routeName",
      },
      {
        Header: "Shift",
        accessor: "shiftName",
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
      },

      {
        Header: "Map Status",
        accessor: (row: any) => {
          return row?.isOn ? "ON" : "OFF";
        },
      },
      {
        Header: "Type",
        accessor: (row: any) => {
          return row?.isPickUpOff ? "Pickup Off" : "Drop Off";
        },
      },
      {
        Header: "Date",
        accessor: (row: any) => formatDate(row.createdAt),
      },
      {
        Header: "Time",
        accessor: (row: any) => formatTime(row.time),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as any,
    },
    usePagination
  );

  const download = async () => {
    try {
      downloadExcel(
        [
          {
            id: "name",
            name: "Driver Name",
          },
          {
            id: "contactNo",
            name: "Contact No",
          },
          {
            id: "routeName",
            name: "Route",
          },
          {
            id: "shiftName",
            name: "Shift",
          },
          {
            id: "vehicleNo",
            name: "Vehicle No",
          },

          {
            id: "isOn",
            name: "Map Status",
            isMapStatus: true,
          },
          {
            id: "isPickUpOff",
            name: "Type",
            isBooleanWait: true,
          },
          {
            id: "createdAt",
            name: "Date",
            isDate: true,
          },
          {
            id: "time",
            name: "Time",
            isLongDateWithTime: true,
          },
        ],
        data === undefined ? [] : data,
        "Driver_ON_OFF",
        "driver_ON_OFF"
      );
    } catch (error) {
      return {};
    }
  };
  const todayDate = new Date();
  const dateForFile = formatDateForFile(todayDate.toISOString());

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Driver ON/OFF</span>
      </div>
      <button
        onClick={() =>
          downloadTableAsPDF(columns, data, `Driver_ON_OFF-${dateForFile}.pdf`)
        }
        className="btn btn-primary mb-4"
      >
        Export to PDF
      </button>
      <button
        onClick={() => {
          download();
        }}
        className="btn btn-primary mb-4"
      >
        Export to Excel
      </button>
      <button
        onClick={() => downloadTableAsPrint(columns, data)}
        className="btn btn-primary mb-4"
      >
        Print
      </button>
      <div className="row">
        <div className="formBg col-5 mb-6">
          <div className="mb-2 row align-items-center">
            <label className="mb-3" htmlFor="date">
              <input
                className="form-control"
                id="date"
                name="date"
                type="date"
                onChange={handleDateChange}
                placeholder="Enter the date"
                defaultValue={new Date().toISOString().split("T")[0]}
              />
            </label>
          </div>
        </div>

        <div className="formBg col-2 mb-6">
          <div className="mb-2 row align-items-center">
            <button
              onClick={handleSearchButtonClick}
              className="btn btn-primary mb-4"
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <table {...getTableProps()} id="report-table" className="tableForReport">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="trForReport">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="thForReport">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="trForReport">
                {row.cells.map((cell: any, cellIndex: number) => {
                  return (
                    <td
                      key={cellIndex}
                      {...cell.getCellProps()}
                      className="tdForReport"
                    >
                      {cell.column.id === "isOn" || cell.column.id === "isOff"
                        ? cell.value === true
                          ? "Yes"
                          : cell.value === false
                          ? "No"
                          : ""
                        : cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {notFound && (
        <div className="mt-4">
          {" "}
          <NotFound />
        </div>
      )}
      <div className="pagination m-4 gap-2">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">>"}
        </button>{" "}
        <span className="mx-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(DriverOnOffReport);
