import { dateFormatForAdmin } from "constants/Constants";
import { memo } from "react";
import { ErrorsType } from "types/errorTypes";

type Props = {
  x: ErrorsType;
};

const ErrorListItem = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td>{dateFormatForAdmin(x.createdAt)}</td>
      <td>{x.api.split("?")[0]}</td>
      <td className="text-center py-3">
        {x.isFromMobile === true ? "Yes" : "No"}
      </td>
      <td className="py-3">{x.status === true ? "Yes" : "No"}</td>
      <td className="text-center">{x.assign === "" ? "__" : x.assign}</td>
      <td>{x.request.slice(0, 50)}...</td>
      <td>{x.response.slice(0, 50)}...</td>
      <td>{x.error}</td>
    </tr>
  );
};

export default memo(ErrorListItem);
