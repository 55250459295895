import { useLoading } from "Context/LoadingContext";
import axiosInstance from "axiosInstance";
import SubmitBtn from "components/Buttons/Submit";
import { warnToast } from "constants/toastConfig";
import { useFormik } from "formik";
import React, { FC, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import RenderHTMLContent from "./inv";

type InvoiceProps = {
  //   x: any;
  //   amount: any;
};

type State = {
  memberName: any;
  paidFees: any;
  payType: any;
  paymentStatus: any;
  referenceId: any;
  termName: any;
  startDate: any;
  startMonth: any;
  dueDate: any;
  termFees: any;
  endMonth: any;
};

const InvoiceTemplate: FC<InvoiceProps> = () => {
  // const { state } = useLocation();
  // const {
  //   memberName,
  //   paidFees,
  //   payType,
  //   paymentStatus,
  //   referenceId,
  //   termName,
  //   startDate,
  //   startMonth,
  //   dueDate,
  //   termFees,
  //   endMonth,
  // } = state as State;

  const [invoice, setInvoice] = useState<any>({});
  const [data, setData] = useState<any>("");

  const { setLoading } = useLoading();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      logoName: "",
      filename: "",
      invoiceNumber: "",
      details: "",
      isActive: true,
    },
    validationSchema: Yup.object().shape({
      invoiceNumber: Yup.number()
        .min(1, "Invoice Number is Too Short!")
        .max(10, "Invoice Number is Too Long!")
        .required("Invoice Number is Required"),
      details: Yup.string().max(300, "Details is Not Valid!"),
      logoName: Yup.mixed()
        .test("fileSize", "photo size is too high", (value) => {
          if (!value) return true;
          return value && value.size <= 52428800;
        })
        .test(
          "fileType",
          "Please Enter photo in jpg, jpeg, png Extention",
          (value) => {
            if (!value) return true;
            return value && /(\.jpg|\.jpeg|\.png)$/i.test(value.name);
          }
        ),
      filename: Yup.mixed()
        .test("fileSize", "Document size is too high", (value) => {
          if (!value) return true;
          return value && value.size <= 209715200;
        })
        .test("fileType", "Document in html extension", (value) => {
          if (!value) return true;
          return value && /\.(html?)$/i.test(value.name);
        })
        .required("Plz select template"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      logoName,
      filename,
      invoiceNumber,
      details,
      isActive,
    }) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("logoName", logoName);
        formData.append("filename", filename);
        formData.append("invoiceNumber", invoiceNumber);
        formData.append("details", details);
        formData.append("isActive", String(isActive));
        const res = await axiosInstance.post("/master/addInvoice", formData);
        const responseMessage = res.data;
        if (res.status === 200) {
          setLoading(false);
          warnToast(responseMessage);
          loadInvoice();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  const loadInvoice = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/master/getOneInvoice`);

      setInvoice(res.data.resp);
      const response = await axiosInstance.get(
        `/template/${res.data.resp.filename}`
      );

      const hasBodyTag = response.data.includes("<body>");

      if (hasBodyTag) {
        const start = response.data.indexOf("<body>");
        const end = response.data.indexOf("</body>");
        const bodyContent = response.data.slice(start + 6, end);
        setData(bodyContent);
      } else {
        setData(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    loadInvoice();
  }, []);

  const handleCopyClick = () => {
    const tempInput = document.createElement("input");
    tempInput.value = data;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  };

  return (
    <div className="formBg">
      <form onSubmit={formik.handleSubmit}>
        <div className=" row align-items-center">
          <label className="col-md-6 mb-3" htmlFor="invoiceNumber">
            <input
              className={`form-control ${
                formik.errors.invoiceNumber && formik.submitCount > 0
                  ? "empty-form"
                  : ""
              }`}
              id="invoiceNumber"
              name="invoiceNumber"
              type="number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.invoiceNumber}
              placeholder="Invoice Number"
            />

            <span className="top-placeholder">Invoice Number*</span>
          </label>
          <label className="col-md-6 mb-3" htmlFor="details">
            <input
              className={`form-control ${
                formik.errors.details && formik.submitCount > 0
                  ? "empty-form"
                  : ""
              }`}
              id="details"
              name="details"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.details}
              placeholder="Details"
            />

            <span className="top-placeholder">Details</span>
          </label>
          <label className="col-md-6 mb-3" htmlFor="logoName">
            <input
              className="form-control uploadLogo form-control-lg"
              id="logoName"
              name="logoName"
              type="file"
              onBlur={formik.handleBlur}
              onChange={(event) => {
                const file =
                  event.currentTarget.files && event.currentTarget.files[0];
                formik.setFieldValue("logoName", file || "");
              }}
              placeholder="Enter Photo"
            />
          </label>
          <label className="col-md-6 mb-3" htmlFor="filename">
            <input
              className={`form-control uploadTemplate ${
                formik.errors.filename && formik.submitCount > 0
                  ? "empty-form"
                  : ""
              }`}
              id="filename"
              name="filename"
              type="file"
              onBlur={formik.handleBlur}
              onChange={(event) => {
                const file =
                  event.currentTarget.files && event.currentTarget.files[0];
                formik.setFieldValue("filename", file || "");
              }}
              placeholder="Upload Template HTML Document"
            />
          </label>
        </div>
        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn errors={formik.errors} />
          {/* {(location.pathname === "/driver/driverForm" ||
              location.pathname === "/driver/driverEdit") && (
              <CancelBtn path=".." />
            )} */}
        </div>
      </form>
      <div className="justify-content-end align-items-center d-flex mb-5">
        <button onClick={handleCopyClick} className="btn btn-primary">
          Copy HTML
        </button>
      </div>
      <RenderHTMLContent htmlContent={data} />
    </div>
  );
};

export default InvoiceTemplate;
