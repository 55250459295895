import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReactComponent as Details } from "assets/images/details-19.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type FeesConfig = {
  _id: string;
  organizerId: string;
  annualRegistrationFees: string;
  startFeesYear: string;
  endFeesYear: string;
  createdAt: string;
  updatedAt: string;
};
type Props = {
  x: FeesConfig;
  prevConfigId: any;
};

function formatMonthYear(dateTimeString: any) {
  const dateTime = new Date(dateTimeString);
  const month = dateTime.toLocaleString("en-US", { month: "long" });
  const year = dateTime.getFullYear();
  return `${month} (${year})`;
}

const FeesConfigListItemBasic = ({ x, prevConfigId }: Props): JSX.Element => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit</Tooltip>;
  return (
    <tr key={x._id}>
      <td>{formatMonthYear(x.startFeesYear)}</td>
      <td>{formatMonthYear(x.endFeesYear)}</td>
      {prevConfigId !== x._id ? (
        <td className={"text-center pointer"}>
          <OverlayTrigger placement="right" overlay={tooltip}>
            <Edit
              id={x._id}
              onClick={() => {
                nav("edit", { state: x });
              }}
            />
          </OverlayTrigger>
        </td>
      ) : (
        <td className={"text-center"}>
          <OverlayTrigger placement="right" overlay={tooltip}>
            <Edit
              // id={x._id}
              // onClick={() => {
              //   nav("edit", { state: x });
              // }}
              className="disabled"
            />
          </OverlayTrigger>
        </td>
      )}
      <td className={"text-center pointer"}>
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id={`tooltip-${x._id}`}>Fees Add</Tooltip>}
        >
          <Details
            style={{ width: "25px", height: "25px" }}
            id={x._id}
            onClick={() => {
              nav("/basicMemberFeeList", { state: x });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(FeesConfigListItemBasic);
