import React from "react";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { formatDate, formatDateToAdd } from "constants/Constants";

type Props = {
  item: any;
  feesType: string;
};

const MemberListItems = ({ item, feesType }: Props) => {
  const nav = useNavigate();
  return (
    <tr key={Math.random()}>
      <td>{item.memberName}</td>
      <td>{item.division}</td>
      {feesType === "km" && <td>{item.location}</td>}
      {feesType === "vehicle" && <td>{item.vehicleNo}</td>}
      {feesType === "vehicle" && <td>{item.shift}</td>}
      <td>{item.message}</td>
    </tr>
  );
};

export default MemberListItems;
