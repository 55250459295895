import {
  convertTo12HourFormat,
  formatDate,
  memImage,
} from "constants/Constants";
import { memo } from "react";
import { memberDetailsType } from "types/memberDetailTypes";

type Props = {
  x: memberDetailsType;
};

const MemberDetail = ({ x }: Props): JSX.Element => {
  let userTypeImage = x.photo
    ? `${memImage}${x.photo}`
    : `${memImage}Default.png`;
  return (
    <tr key={x._id}>
      <td>
        <i className="userImg">
          <img src={userTypeImage} alt="MemberPic" />
        </i>
      </td>
      <td>{x.memberName}</td>
      <td>{x.memberId}</td>
      <td>{x.driverName}</td>
      <td>{x.routeName}</td>
      <td>{x.shift}</td>
      <td>{x.area}</td>
      <td>{x.location}</td>
      <td>{x.guestPerson}</td>
      <td>{x.remark}</td>
      <td>{formatDate(x.createdAt)}</td>
    </tr>
  );
};

export default memo(MemberDetail);
