import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Ico01 } from "../../assets/images/dashboard-14.svg";
import { ReactComponent as Ico02 } from "../../assets/images/ico02.svg";
import { ReactComponent as Ico03 } from "../../assets/images/master-13.svg";
import { ReactComponent as Ico04 } from "../../assets/images/ico04.svg";
import { ReactComponent as Ico05 } from "../../assets/images/vehicle-06.svg";
import { ReactComponent as Ico06 } from "../../assets/images/driver-11.svg";
import { ReactComponent as Ico07 } from "../../assets/images/member-01.svg";
import { ReactComponent as Ico08 } from "../../assets/images/area-12.svg";
import { ReactComponent as Ico09 } from "../../assets/images/route-05.svg";
// import { ReactComponent as Ico10 } from "../../assets/images/ico09.svg";
import { ReactComponent as Ico11 } from "../../assets/images/transportation-10.svg";
import { ReactComponent as Ico12 } from "../../assets/images/leave-02.svg";
import { ReactComponent as Ico13 } from "../../assets/images/complaint-09.svg";
import { ReactComponent as Ico14 } from "../../assets/images/notification-08.svg";
import { ReactComponent as Ico16 } from "../../assets/images/reports-04.svg";
import { ReactComponent as Ico15 } from "../../assets/images/logout-03.svg";
import { ReactComponent as UserManageMentIco } from "../../assets/images/user management.svg";
import { ReactComponent as ClientManageMentIco } from "../../assets/images/client management.svg";
import { ReactComponent as ReqForDemoIco } from "../../assets/images/request for demo.svg";
import { ReactComponent as MessageIco } from "../../assets/images/message.svg";
import { ReactComponent as ErrorManagementIco } from "../../assets/images/error management.svg";

import ProfileHeading from "components/ProfileEdit/ProfileHeading";
import { useFeesConfig } from "Context/FeesConfigurationContext";
import BasicSidebar from "./BasicSidebar";
import CustomNavLink from "components/NavLink";
import {
  areaItems,
  masterItems,
  reportItems,
  routeItems,
  vehicleItems,
} from "./data";

export type ConfigLocalType = {
  isMultiShift: boolean;
  paymentCheck: boolean;
  rfidCardCheck: boolean;
  planType: string;
};
type Props = {};

const SideBar = (props: Props) => {
  const { feesConfigType } = useFeesConfig();
  const [paymentRequired, setPaymentRequired] = useState(true);
  const [rfidCardRequired, setRfidCardRequired] = useState(true);
  const [planType, setPlanType] = useState<string>();
  const [driverAccounts, setDriverAccounts] = useState(false);
  const location = useLocation();

  const nav = useNavigate();
  const logout = () => {
    localStorage.clear();
    nav("/login");
  };

  const role = localStorage.getItem("role");
  const [openNav, setOpenNav] = useState(false);
  const [optionName, setOptionName] = useState("");

  const x = location.pathname.split("/");
  const isEdit =
    x[x.length - 1].includes("dit") ||
    x[x.length - 1].includes("vehicleLatLog");

  useEffect(() => {
    setOptionName("");
    const x = localStorage.getItem("config") as string;
    const y = localStorage.getItem("DriverAccountSetting");
    if (y === "false") {
      setDriverAccounts(false);
    } else {
      setDriverAccounts(true);
    }
    if (role === "ORG") {
      const config: ConfigLocalType = JSON.parse(x);
      setPaymentRequired(config.paymentCheck);
      setRfidCardRequired(config.rfidCardCheck);
      setPlanType(config.planType as string);
    }
  }, [planType]);

  return (
    <aside id="sidebar">
      <Link className="logo" to="/">
        <Logo />
      </Link>
      <ProfileHeading />
      {role === "ORG" && (planType === "ADV" || planType === "PRM") && (
        <ul className="mainNav">
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink to={"/dashboard"} text={"Dashboard"}>
              <Ico01 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          {/* <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink to={"/profile"} text={"Profile"}>
              <Ico02 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li> */}
          <li>
            <Link
              data-id="master"
              onClick={(e) => {
                const navOp = e.currentTarget.dataset.id;
                setOptionName(navOp as string);
                if (openNav === true && optionName === "master") {
                  setOpenNav(false);
                } else {
                  setOpenNav(true);
                }
              }}
              to={isEdit ? ".." : "#"}
              className={
                optionName === "master" && openNav ? "ddNav active" : "ddNav"
              }
            >
              <i>
                <Ico03 style={{ width: "20px", height: "20px" }} />
              </i>
              Masters
            </Link>
            <ul
              className={`${
                optionName === "master" && openNav ? "d-block" : "d-none  "
              } `}
            >
              <li>
                {masterItems.map(({ to, text }) => (
                  <CustomNavLink
                    key={Math.random()}
                    to={to}
                    text={text}
                    padding
                  />
                ))}

                {paymentRequired && (
                  <CustomNavLink
                    to={"/paymentConfig"}
                    text={"Payment Configuration"}
                    padding
                  />
                )}
                {paymentRequired && driverAccounts && (
                  <CustomNavLink
                    to={"/driverConfig"}
                    text={"Driver Configuration"}
                    padding
                  />
                )}
                {rfidCardRequired && (
                  <CustomNavLink text="RFID Card" to="/rfdiCard" padding />
                )}
              </li>
            </ul>
          </li>
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Vehicle Management" to="/vehicleRegistration">
              <Ico05 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Driver Management" to="/driver">
              <Ico06 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Attendant Management" to="/attendant">
              <Ico06 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Areas Management" to="/areaMaster">
              <Ico08 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Route Management" to="/routeMaster">
              <Ico09 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          {/* {paymentRequired && (
            <li>
              <Link
                data-id="fees"
                onClick={(e) => {
                  const navOp = e.currentTarget.dataset.id;
                  setOptionName(navOp as string);
                  if (openNav === true && optionName === "fees") {
                    setOpenNav(false);
                  } else {
                    setOpenNav(true);
                  }
                }}
                to={isEdit ? ".." : "#"}
                className={
                  optionName === "fees" && openNav ? "ddNav active" : "ddNav"
                }
              >
                <i>
                  <Ico09 style={{ width: "20px", height: "20px" }} />
                </i>
                Transportation fees
              </Link>
              <ul
                className={`${
                  optionName === "fees" && openNav ? "d-block" : "d-none  "
                } `}
              >
                <li>
                  <CustomNavLink
                    text="Fees Configuration"
                    to="/feesConfig"
                    padding
                  />
                  {feesConfigType === "area" && (
                    <CustomNavLink text="Fees By Area" to="/feesArea" padding />
                  )}
                  {feesConfigType === "km" && (
                    <CustomNavLink text="Fees By KMs" to="/feesKm" padding />
                  )}
                  {feesConfigType === "vehicle" && (
                    <CustomNavLink
                      text="Fees By Vehicle"
                      to="/feesByVehicle"
                      padding
                    />
                  )}
                  <CustomNavLink text="Member Fees" to="/memberFees" padding />
                  <CustomNavLink
                    text="Fees Structure Setting"
                    to="/feesStructure"
                    padding
                  />
                </li>
              </ul>
            </li>
          )} */}
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Member Management" to="/memberMaster">
              <Ico07 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          {paymentRequired && (
            <>
              <li onClick={(e) => setOpenNav(false)}>
                <CustomNavLink
                  text="Fees Management"
                  to="/transportationFeesMain"
                >
                  <Ico11 style={{ width: "20px", height: "20px" }} />
                </CustomNavLink>
              </li>
              {/* <li onClick={(e) => setOpenNav(false)}>
                <CustomNavLink text="Member Fees" to="/memberFees">
                  <Ico04 style={{ width: "20px", height: "20px" }} />
                </CustomNavLink>
              </li> */}
            </>
          )}
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Payment Management" to="/paymentManagement">
              <Ico07 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Leave Management" to="/LeaveTabs">
              <Ico12 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Gate Pass" to="/MemberGetPass">
              <Ico12 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Complaints" to="/complaints">
              <Ico13 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          <li onClick={(e) => setOpenNav(false)}>
            <CustomNavLink text="Notification" to="/notification">
              <Ico13 style={{ width: "20px", height: "20px" }} />
            </CustomNavLink>
          </li>
          <li>
            <Link
              data-id="report"
              onClick={(e) => {
                const navOp = e.currentTarget.dataset.id;
                setOptionName(navOp as string);
                if (openNav === true && optionName === "report") {
                  setOpenNav(false);
                } else {
                  setOpenNav(true);
                }
              }}
              to={isEdit ? ".." : "#"}
              className={
                optionName === "report" && openNav ? "ddNav active" : "ddNav"
              }
            >
              <i>
                <Ico16 style={{ width: "20px", height: "20px" }} />
              </i>
              Reports
            </Link>
            <ul
              className={`${
                optionName === "report" && openNav ? "d-block" : "d-none  "
              } `}
            >
              <li>
                {reportItems.map(({ text, to }) => (
                  <CustomNavLink
                    key={Math.random()}
                    text={text}
                    to={to}
                    padding
                  />
                ))}
              </li>
            </ul>
          </li>
          <li onClick={logout} className="logout">
            <Link to="/">
              <i>
                <Ico15 style={{ width: "20px", height: "20px" }} />
              </i>
              Logout
            </Link>
          </li>
        </ul>
      )}
      {role === "ORG" && planType === "BAC" && <BasicSidebar logout={logout} />}
      {role === "SUP" && (
        <ul className="mainNav">
          <li>
            <Link
              to="/dashboard"
              className={`${
                location.pathname === "/dashboard" ||
                location.pathname === "/dashboard/demoRequest" ||
                location.pathname === "/dashboard/demodone" ||
                location.pathname === "/dashboard/expireplan" ||
                location.pathname === "/dashboard/verifycustomer" ||
                location.pathname === "/dashboard/demoRequest/demoEditForm" ||
                location.pathname === "/dashboard/demoReschedule" ||
                location.pathname ===
                  "/dashboard/demoReschedule/demoEditForm" ||
                location.pathname === "/dashboard/newcustomer"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <Ico01 style={{ width: "20px", height: "20px" }} />
              </i>
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/usermanagement"
              className={`${
                location.pathname === "/usermanagement" ||
                location.pathname === "/usermanagement/addUserMng" ||
                location.pathname === "/usermanagement/userEdit"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <UserManageMentIco style={{ width: "20px", height: "20px" }} />
              </i>
              User Management
            </Link>
          </li>
          <li>
            <Link
              data-id="master"
              onClick={(e) => {
                const navOp = e.currentTarget.dataset.id;
                setOptionName(navOp as string);
                if (openNav === true && optionName === "master") {
                  setOpenNav(false);
                } else {
                  setOpenNav(true);
                }
              }}
              to={isEdit ? ".." : "#"}
              className={
                optionName === "master" && openNav ? "ddNav active" : "ddNav"
              }
            >
              <i>
                <Ico03 style={{ width: "20px", height: "20px" }} />
              </i>
              Master
            </Link>
            <ul
              className={`${
                optionName === "master" && openNav ? "d-block" : "d-none  "
              } `}
            >
              <li>
                <Link
                  to="/adminPlan"
                  className={`${
                    location.pathname === "/adminPlan" ||
                    location.pathname === "/adminPlan/adminPlanForm" ||
                    location.pathname === "/adminPlan/adminPlanEditForm"
                      ? "active"
                      : ""
                  }`}
                >
                  Plan
                </Link>
              </li>
              <li>
                <Link
                  to="/configuration"
                  className={`${
                    location.pathname === "/configuration" ||
                    location.pathname === "/configuration/configurationEdit"
                      ? "active"
                      : ""
                  }`}
                >
                  Configuration
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              data-id="clientManagement"
              onClick={(e) => {
                const navOp = e.currentTarget.dataset.id;
                setOptionName(navOp as string);
                if (openNav === true && optionName === "clientManagement") {
                  setOpenNav(false);
                } else {
                  setOpenNav(true);
                }
              }}
              to={isEdit ? ".." : "#"}
              className={
                optionName === "clientManagement" && openNav
                  ? "ddNav active"
                  : "ddNav"
              }
            >
              <i>
                <ClientManageMentIco
                  style={{ width: "20px", height: "20px" }}
                />
              </i>
              Client Management
            </Link>
            <ul
              className={`${
                optionName === "clientManagement" && openNav
                  ? "d-block"
                  : "d-none  "
              } `}
            >
              <li>
                <Link
                  to="/planRequests"
                  className={`${
                    location.pathname === "/planRequests" ||
                    location.pathname === "/planRequests/editForm" ||
                    location.pathname ===
                      "/planRequests/configurationDetails" ||
                    location.pathname ===
                      "/planRequests/configurationDetails/plnConfigurationEdit"
                      ? "active"
                      : ""
                  }`}
                >
                  Request For Plan
                </Link>
              </li>

              <li>
                <Link
                  to="/renewPlanRequest"
                  className={`${location.pathname === "/renewPlanRequest"}`}
                >
                  Request For Renew Plan
                </Link>
              </li>

              <li>
                <Link
                  to="/adminClient"
                  className={`${
                    location.pathname === "/adminClient" ||
                    location.pathname === "/adminClient/clientDetails" ||
                    location.pathname === "/adminClient/configurationDetails" ||
                    location.pathname === "/adminClient/clientEdit" ||
                    location.pathname ===
                      "/adminClient/configurationDetails/ClientConfigurationEdit"
                      ? "active"
                      : ""
                  }`}
                >
                  Clients
                </Link>
              </li>
              <li>
                <Link
                  to="/adminExpirePlan"
                  className={`${
                    location.pathname === "/adminExpirePlan" ? "active" : ""
                  }`}
                >
                  Client Reminder Plan
                </Link>
              </li>
              <li>
                <Link
                  to="/adminExpiredPlan"
                  className={`${
                    location.pathname === "/adminExpiredPlan" ? "active" : ""
                  }`}
                >
                  Client Expired Plan
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="/adminRequest"
              className={`${
                location.pathname === "/adminRequest" ||
                location.pathname === "/adminRequest/requestEditForm"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <ReqForDemoIco style={{ width: "20px", height: "20px" }} />
              </i>
              Request for Demo
            </Link>
          </li>
          <li>
            <Link
              to="/adminMessage"
              className={`${
                location.pathname === "/adminMessage" ||
                location.pathname === "/adminMessage/messageView"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <MessageIco style={{ width: "20px", height: "20px" }} />
              </i>
              Message
            </Link>
          </li>
          <li>
            <Link
              to="/errorhandling"
              className={`${
                location.pathname === "/errorhandling" ? "active" : ""
              }`}
            >
              <i>
                <ErrorManagementIco style={{ width: "20px", height: "20px" }} />
              </i>
              Error Management
            </Link>
          </li>
          <li>
            <Link
              to="/errorLogsForDriver"
              className={`${
                location.pathname === "/errorLogsForDriver" ? "active" : ""
              }`}
            >
              <i>
                <ErrorManagementIco style={{ width: "20px", height: "20px" }} />
              </i>
              Error From Driver App
            </Link>
          </li>
          <li>
            <Link
              to="/errorLogsForMember"
              className={`${
                location.pathname === "/errorLogsForMember" ? "active" : ""
              }`}
            >
              <i>
                <ErrorManagementIco style={{ width: "20px", height: "20px" }} />
              </i>
              Error From Member App
            </Link>
          </li>
          <li>
            <Link
              to="/promocode"
              className={`${
                location.pathname === "/promocode" ? "active" : ""
              }`}
            >
              <i>
                <ErrorManagementIco style={{ width: "20px", height: "20px" }} />
              </i>
              Promo Code
            </Link>
          </li>
          <li>
            <Link
              data-id="adminreport"
              onClick={(e) => {
                const navOp = e.currentTarget.dataset.id;
                setOptionName(navOp as string);
                if (openNav === true && optionName === "adminreport") {
                  setOpenNav(false);
                } else {
                  setOpenNav(true);
                }
              }}
              to={isEdit ? ".." : "#"}
              className={
                optionName === "adminreport" && openNav
                  ? "ddNav active"
                  : "ddNav"
              }
            >
              <i>
                <Ico16 style={{ width: "20px", height: "20px" }} />
              </i>
              Reports
            </Link>
            <ul
              className={`${
                optionName === "adminreport" && openNav ? "d-block" : "d-none  "
              } `}
            >
              <li>
                <Link
                  to="/clientReport"
                  className={`${
                    location.pathname === "/clientReport"
                      ? "pb-3 active"
                      : "pb-3"
                  }`}
                >
                  Client
                </Link>
                <Link
                  to="/clientDetailsReport"
                  className={`${
                    location.pathname === "/clientDetailsReport"
                      ? "pb-3 active"
                      : "pb-3"
                  }`}
                >
                  Client Details
                </Link>
                <Link
                  to="/reqPlnReport"
                  className={`${
                    location.pathname === "/reqPlnReport"
                      ? "pb-3 active"
                      : "pb-3"
                  }`}
                >
                  Requested plan
                </Link>

                <Link
                  to="/expiredPlanReport"
                  className={`${
                    location.pathname === "/expiredPlanReport" ? "active" : ""
                  }`}
                >
                  Expired Plan
                </Link>
              </li>
            </ul>
          </li>
          <li onClick={logout}>
            <Link to="/">
              <i>
                <Ico15 style={{ width: "20px", height: "20px" }} />
              </i>
              Log Out
            </Link>
          </li>
        </ul>
      )}
      {role === "SEL" && (
        <ul className="mainNav">
          <li>
            <Link
              to="/dashboard"
              className={`${
                location.pathname === "/dashboard" ||
                location.pathname === "/dashboard/demoRequest" ||
                location.pathname === "/dashboard/demodone" ||
                location.pathname === "/dashboard/expireplan" ||
                location.pathname === "/dashboard/verifycustomer" ||
                location.pathname === "/dashboard/demoRequest/demoEditForm" ||
                location.pathname === "/dashboard/demoReschedule" ||
                location.pathname ===
                  "/dashboard/demoReschedule/demoEditForm" ||
                location.pathname === "/dashboard/newcustomer"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/adminClient"
              className={`${
                location.pathname === "/adminClient" ||
                location.pathname === "/adminClient/clientDetails" ||
                location.pathname === "/adminClient/configurationDetails" ||
                location.pathname === "/adminClient/clientEdit" ||
                location.pathname ===
                  "/adminClient/configurationDetails/ClientConfigurationEdit"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Clients
            </Link>
          </li>
          <li>
            <Link
              to="/planRequests"
              className={`${
                location.pathname === "/planRequests" ||
                location.pathname === "/planRequests/editForm" ||
                location.pathname === "/planRequests/configurationDetails" ||
                location.pathname ===
                  "/planRequests/configurationDetails/plnConfigurationEdit"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Request For Plan
            </Link>
          </li>
          <li>
            <Link
              to="/adminRequest"
              className={`${
                location.pathname === "/adminRequest" ||
                location.pathname === "/adminRequest/requestEditForm"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Request For Demo
            </Link>
          </li>
          <li>
            <Link
              to="/adminExpirePlan"
              className={`${
                location.pathname === "/adminExpirePlan" ? "active" : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Client Reminder Plan
            </Link>
          </li>
          <li>
            <Link
              to="/adminExpiredPlan"
              className={`${
                location.pathname === "/adminExpiredPlan" ? "active" : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Client Expired Plan
            </Link>
          </li>
          <li>
            <Link
              to="/expiredPlanReport"
              className={`${
                location.pathname === "/expiredPlanReport" ? "active" : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Expired Plan Report
            </Link>
          </li>
          <li onClick={logout}>
            <Link to="/">
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Log Out
            </Link>
          </li>
        </ul>
      )}
      {role === "ADM" && (
        <ul className="mainNav">
          <li>
            <Link
              to="/dashboard"
              className={`${
                location.pathname === "/dashboard" ||
                location.pathname === "/dashboard/demoRequest" ||
                location.pathname === "/dashboard/demodone" ||
                location.pathname === "/dashboard/expireplan" ||
                location.pathname === "/dashboard/verifycustomer" ||
                location.pathname === "/dashboard/demoRequest/demoEditForm" ||
                location.pathname === "/dashboard/demoReschedule" ||
                location.pathname ===
                  "/dashboard/demoReschedule/demoEditForm" ||
                location.pathname === "/dashboard/newcustomer"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/adminRequest"
              className={`${
                location.pathname === "/adminRequest" ||
                location.pathname === "/adminRequest/requestEditForm"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Request For Demo
            </Link>
          </li>
          <li>
            <Link
              to="/adminClient"
              className={`${
                location.pathname === "/adminClient" ||
                location.pathname === "/adminClient/clientDetails" ||
                location.pathname === "/adminClient/configurationDetails" ||
                location.pathname === "/adminClient/clientEdit" ||
                location.pathname ===
                  "/adminClient/configurationDetails/ClientConfigurationEdit"
                  ? "active"
                  : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Clients
            </Link>
          </li>
          <li>
            <Link
              to="/adminExpirePlan"
              className={`${
                location.pathname === "/adminExpirePlan" ? "active" : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Client Remider Plan
            </Link>
          </li>
          <li>
            <Link
              to="/adminExpiredPlan"
              className={`${
                location.pathname === "/adminExpiredPlan" ? "active" : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Client Expired Plan
            </Link>
          </li>
          <li>
            <Link
              to="/errorhandling"
              className={`${
                location.pathname === "/errorhandling" ? "active" : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Error Management
            </Link>
          </li>
          <li>
            <Link
              to="/expiredPlanReport"
              className={`${
                location.pathname === "/expiredPlanReport" ? "active" : ""
              }`}
            >
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Expired Plan Report
            </Link>
          </li>
          <li onClick={logout}>
            <Link to="/">
              <i>
                <Ico02 style={{ width: "20px", height: "20px" }} />
              </i>
              Log Out
            </Link>
          </li>
        </ul>
      )}
    </aside>
  );
};

export default SideBar;
