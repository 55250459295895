import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { AttendantMasterType } from "types/AttendantTypes";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Password from "../Password";
import { drvImage } from "constants/Constants";
type Props = {};

const DepartmentEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [attendant, setAttendant] = useState<AttendantMasterType>();
  const { pathname } = useLocation();
  const [param] = useSearchParams();

  const getOneAttendant = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getSingleAttendantById/${state.x._id}`
      );
      setAttendant(res.data.attendant);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [state.x._id]);

  useEffect(() => {
    getOneAttendant();
  }, [getOneAttendant]);

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      name: attendant?.name,
      address: attendant?.address,
      contactNo: attendant?.contactNo,
      photo: attendant?.photo,
      description: attendant?.description,
      active: attendant?.isActive,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Attendant Name is Too Short!")
        .max(30, "Attendant Name is Too Long!")
        .required("Attendant Name is required"),

      contactNo: Yup.string()
        .min(10, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!")
        .required("Contact Number is Required"),
      address: Yup.string()
        .min(1, "Address is Too Short!")
        .max(300, "Address is Too Long!")
        .required("Address is Required"),
      description: Yup.string().max(300, "Description is Too Long!"),
      photo: Yup.mixed()
        .test("fileSize", "Photo Size is Too High", (value) => {
          if (!value) return true;
          if (typeof value === "string") return true;
          return value && value.size <= 52428800;
        })
        .test(
          "fileType",
          "Please Enter Photo in jpg, jpeg, png Extention",
          (value) => {
            if (!value) return true;
            if (typeof value === "string") return true;
            return value && /(\.jpg|\.jpeg|\.png)$/i.test(value.name);
          }
        ),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      name,
      address,
      contactNo,
      photo,
      description,
      active,
    }) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("name", name || "");
        formData.append("address", address || "");
        formData.append("contactNo", String(contactNo) || "");
        formData.append("photo", photo || "");
        formData.append("description", description || "");
        formData.append("isActive", String(active));

        const res = await axiosInstance.put(
          `/master/updateAttendant/${state.x._id}`,
          formData
        );
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setTimeout(() => {
            setLoading(false);
            nav("/attendant");
          }, 1500);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      {pathname !== "/attendant" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/attendant" />
        </div>
      )}
      <Tabs defaultIndex={Number(param.get("index"))}>
        <TabList>
          <Tab>Attendant</Tab>
          <Tab>Password</Tab>
        </TabList>
        <TabPanel>
          <div className="formBg">
            <form onSubmit={formik.handleSubmit}>
              <div className=" row align-items-center">
                <label className="col-md-6 mb-3" htmlFor="name">
                  <input
                    className={`form-control ${
                      formik.errors.name && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="name"
                    name="name"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    placeholder="Attendant Name"
                  />
                  <span className="top-placeholder">Attendant Name*</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="contactNo">
                  <input
                    className={`form-control ${
                      formik.errors.contactNo && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="contactNo"
                    name="contactNo"
                    type="number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.contactNo}
                    placeholder="Contact Number"
                  />
                  <span className="top-placeholder">Contact Number</span>
                </label>
                <label className="col-md-6 mb-3" htmlFor="address">
                  <input
                    className={`form-control ${
                      formik.errors.address && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="address"
                    name="address"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.address}
                    placeholder="Address"
                  />
                  <span className="top-placeholder">Address*</span>
                </label>
                <div className="col-md-1 text-center">
                  <i className="userImg">
                    <img
                      src={`${
                        attendant?.photo !== ""
                          ? drvImage + attendant?.photo
                          : drvImage + "Default.png"
                      }`}
                      alt="Attendant Pic"
                    />
                  </i>
                </div>
                <label className="col-md-5 mb-3" htmlFor="photo">
                  <input
                    className="form-control uploadPhoto form-control-lg"
                    id="photo"
                    name="photo"
                    type="file"
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      const file =
                        event.currentTarget.files &&
                        event.currentTarget.files[0];
                      formik.setFieldValue("photo", file || "");
                    }}
                    placeholder="Enter photo"
                  />
                </label>
                <label className="col-md-12 mb-3" htmlFor="description">
                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    placeholder="Attendant Description"
                  ></textarea>
                  <span className="top-placeholder">Attendant Description</span>
                </label>
                <div className="col-md-3 mb-3">
                  <label className="custCheck">
                    Active
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      checked={formik.values.active}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3 text-center">
                <SubmitBtn errors={formik.errors} />
                <CancelBtn path=".." />
              </div>
            </form>
          </div>
        </TabPanel>
        <TabPanel>
          <Password
            state={state.x}
            link={`/master/updateAttendant/${state.x._id}`}
          />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default memo(DepartmentEditForm);
