import axiosInstance from "axiosInstance";
import { useCallback, useState, useEffect, memo } from "react";
import { memberDetailsType } from "types/memberDetailTypes";
import { infiniteScroll, sortData } from "constants/Constants";
import MemberDetailsListItem from "./memberDetailListItem";
import { useLoading } from "Context/LoadingContext";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useNotFound } from "Context/NotFound";
import NotFound from "components/NotFound";
import AddSearch from "components/AddSearchComponent";
import { useSearchParams } from "react-router-dom";

type Props = {};

const MemberDetailsList = (props: Props): JSX.Element => {
  const [Member, setMember] = useState<memberDetailsType[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("memberName");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { notfound, setNotFound } = useNotFound();
  const [param] = useSearchParams();

  const loadMember = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `master/${String(
            param.get("text")
          )}?page=${pageNum}&sort=${sortParam}&pickup=${String(
            param.get("pickup")
          )}&search=${searchKeyword}`
        );
        const newmemberlist: memberDetailsType[] = [
          ...res.data.MemberDetail[0].paginatedResults,
        ];
        if (newmemberlist.length === 0) {
          setNotFound(true);
          if (searchKeyword !== "" && pageNum === 1) {
            setMember([]);
            setSearchCount(0);
          }
        }
        if (newmemberlist.length > 0) {
          setSearchCount(res.data.MemberDetail[0].totalCount[0].count);
          if (pageNum === 1) {
            setMember(newmemberlist);
          } else {
            setMember((prevList) => [...prevList, ...newmemberlist]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    []
  );
  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    loadMember(1, sort, search);
  };

  useEffect(() => {
    loadMember(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(Member, setPage, page, searchCount);
  };
  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Member or Driver"
      />
      <table className="table">
        <thead>
          <tr>
            <th id="memberPhoto">Photo</th>
            <th
              id="memberName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Member
              {sort === "memberName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="memberId"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Member Id
              {sort === "memberId" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="driverName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Driver
              {sort === "driverName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="routeName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Route
              {sort === "routeName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="shift"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Shift
              {sort === "shift" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="area"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Area
              {sort === "area" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="location"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Location
              {sort === "location" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="pickUpTime"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Pickup Time
              {sort === "pickUpTime" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="dropTime"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMember, setPage);
              }}
            >
              Drop Time
              {sort === "dropTime" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            {param.get("text") !== "getAllTodayPickupMembersList" && (
              <th>Time</th>
            )}
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {Member?.map((x, index) => {
              return <MemberDetailsListItem x={x} key={index} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(MemberDetailsList);
