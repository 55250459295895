import React, { memo } from "react";
import { useFormik } from "formik";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { ReqForRenewPlan } from "types/renewPlanTypes";
import { successToast } from "constants/toastConfig";
import * as Yup from "yup";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const PlanRenewEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const data = state as ReqForRenewPlan;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: data.amount,
      paymentCheck: data.paymentCheck,
      isMultiShift: data.isMultiShift,
      rfidCardCheck: data.rfidCardCheck,
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required("Amount is Required"),
      startDate: Yup.string().required("StartDate is Required"),
      endDate: Yup.string().required("EndDate is Required"),
    }),
    onSubmit: async ({
      amount,
      isMultiShift,
      paymentCheck,
      rfidCardCheck,
      startDate,
      endDate,
    }) => {
      setLoading(true);
      try {
        const formData = {
          reqForPlanId: data._id,
          organizationId: data.organizationId,
          planId: data.planId,
          isActive: true,
          isDeleted: false,
          amount,
          isMultiShift,
          paymentCheck,
          rfidCardCheck,
        };
        const paymentData = {
          organizationId: data.organizationId,
          planId: data.planId,
          amount,
          startDate,
          endDate,
          discount: "0",
          referenceNo: "123",
          status: "Success",
        };
        const updateData = {
          amount,
          isActive: true,
          isDeleted: false,
          isMultiShift,
          paymentCheck,
          rfidCardCheck,
        };
        const res = await axiosInstance.put(
          `/admin/updateReqRenewPlan/${data._id}`,
          updateData
        );
        const res2 = await axiosInstance.post(
          `/admin/addOrganizationPlan`,
          formData
        );
        const res4 = await axiosInstance.post(
          `/admin/addOrganizationPayment`,
          paymentData
        );
        if (
          res.data?.message === "Ok" &&
          res2.data?.message === "Ok" &&
          res4.data?.message === "Ok"
        ) {
          successToast();
          nav("/renewPlanRequest");
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <ul className="profileList">
              <li>
                <span>Organizer :</span>
                {data.organizerName}
              </li>
              <li>
                <span>Plan :</span>
                {data.planName}
              </li>
              <li>
                <span>Plan Type:</span>
                {data.planType}
              </li>
            </ul>
          </div>
          <label className="col-md-6 mb-3 px-2" htmlFor="startDate">
            <span className="top-placeholder">Start Date*</span>
            <input
              className={`form-control ${
                formik.errors.startDate && formik.submitCount > 1
                  ? "empty-form"
                  : ""
              }`}
              id="startDate"
              name="startDate"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.startDate}
              placeholder="StartDate"
              onFocus={(e) => (e.target.type = "date")}
            />
          </label>
          <label className="col-md-6 mb-3 px-2" htmlFor="endDate">
            <span className="top-placeholder">End Date*</span>
            <input
              className={`form-control ${
                formik.errors.endDate && formik.submitCount > 1
                  ? "empty-form"
                  : ""
              }`}
              id="endDate"
              name="endDate"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.endDate}
              placeholder="EndDate"
              onFocus={(e) => (e.target.type = "date")}
            />
          </label>
          <label className="col-md-6 mb-3 px-2" htmlFor="amount">
            <span className="top-placeholder">Amount*</span>
            <input
              className={`form-control ${
                formik.values.amount === "" ? "empty-form" : ""
              }`}
              id="amount"
              name="amount"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.amount}
              placeholder="Amount"
            />
          </label>
          <div className=" row align-items-center mb-5 ">
            <div className="col-md-3 mb-3">
              <label className="custCheck" htmlFor="paymentCheck">
                Payment
                <input
                  type="checkbox"
                  id="paymentCheck"
                  name="paymentCheck"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.paymentCheck}
                />
                <span className="checkmark"></span>
              </label>
            </div>

            {data.planCode === "PRM" && (
              <div className="col-md-3 mb-3">
                <label className="custCheck" htmlFor="isMultiShift">
                  Multi Shift
                  <input
                    type="checkbox"
                    id="isMultiShift"
                    name="isMultiShift"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={formik.values.isMultiShift}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
            {(data.planCode === "ADV" || data.planCode === "PRM") && (
              <div className="col-md-3 mb-3">
                <label className="custCheck" htmlFor="rfidCardCheck">
                  RFID Card
                  <input
                    type="checkbox"
                    id="rfidCardCheck"
                    name="rfidCardCheck"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={formik.values.rfidCardCheck}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            <CancelBtn path=".." />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(PlanRenewEditForm);
