import { memo, useCallback, useEffect, useState } from "react";
import DepartmentMasterListItem from "./departmentMasterListItem";
import { DepartmentMasterDataType } from "types/departmentMasterType";
import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";

type Props = {};
const DepartmentList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [department, setDepartment] = useState<DepartmentMasterDataType[]>([]);
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const LoadDepartments = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `/master/getAllDepartment?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newDepartmentList: DepartmentMasterDataType[] = [
          ...res.data.department[0].paginatedResults,
        ];
        if (searchKeyword !== "" && pageNum === 1) {
          if (newDepartmentList.length === 0) {
            setNotFound(true);
            setDepartment([]);
            setSearchCount(0);
          }
        }
        if (newDepartmentList.length > 0) {
          setSearchCount(res.data.department[0].totalCount[0].count);
          if (pageNum === 1) {
            setDepartment(newDepartmentList);
          } else {
            setDepartment((prevList) => [...prevList, ...newDepartmentList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [department]
  );

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    LoadDepartments(1, sort, search);
  };

  useEffect(() => {
    LoadDepartments(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(department, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        addLink="/departmentMaster/addForm"
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Department"
      />

      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setDepartment, setPage);
              }}
            >
              Department
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="code"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setDepartment, setPage);
              }}
            >
              Code
              <span className="px-2">
                {sort === "code" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th>Description</th>
            <th
              className="text-center"
              id="isActive"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setDepartment, setPage);
              }}
            >
              Active
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {department?.map((item: DepartmentMasterDataType) => {
              return <DepartmentMasterListItem item={item} key={item._id} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(DepartmentList);
