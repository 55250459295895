import React, { memo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

type Props = {};

const FeesArea: React.FC<{}> = (props: Props) => {
  const location = useLocation();
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Fees By Area</span>
        {location.pathname === "/feesArea" && (
          <Link to={"/feesArea/addForm"}>
            <button className="btn btn-primary" type="submit">
              Add
            </button>
          </Link>
        )}
      </div>
      <Outlet />
    </>
  );
};

export default memo(FeesArea);
