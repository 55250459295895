import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import VehicleRegAddForm from "./VehicleRegAddForm";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const VehicleTabsComponent = (props: Props) => {
  const { pathname } = useLocation();
  const segments = pathname.split("/");
  const segment = segments[segments.length - 1];
  return (
    <>
      {pathname !== "/vehicleRegistration" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/vehicleRegistration" />
        </div>
      )}
      <Tabs
        selectedIndex={
          (segment === "pucAdd" && 1) || (segment === "incAdd" && 2) || 0
        }
        onSelect={() => {}}
      >
        <TabList>
          <Tab disabled={pathname !== "/vehicleRegistration/addForm"}>
            Vehicle Registration
          </Tab>
          <Tab disabled={segment !== "pucAdd"}>Vehicle PUC</Tab>
          <Tab disabled={segment !== "incAdd"}>Vehicle Insurance</Tab>
        </TabList>

        <TabPanel>
          <VehicleRegAddForm />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default VehicleTabsComponent;
