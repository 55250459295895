import { memo, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLoading } from "Context/LoadingContext";
import Form from "react-bootstrap/Form";
import { useFeesConfig } from "Context/FeesConfigurationContext";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Outlet,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import CancelBtn from "components/Buttons/Cancel";
import BasicMemberFee from "./BasicMemberFee";
// import MemberFeesListFailed from "../FailedMemberFees/List";
// import MemberFeesList from "../MemberFees/list";

// type FeesConfigType = {
//   _id: string;
//   organizerId: string;
//   annualRegistrationFees: string;
//   startFeesYear: string;
//   endFeesYear: string;
// };

type Props = {};

type FeesConfig = {
  _id: string;
  organizerId: string;
  annualRegistrationFees: string;
  startFeesYear: string;
  endFeesYear: string;
  createdAt: string;
  updatedAt: string;
};

const BasicFeesConfig = (props: Props) => {
  //nav and state
  const nav = useNavigate();
  const { state } = useLocation();
  const { setLoading } = useLoading();

  //path setting
  const { pathname } = useLocation();
  // const segments = pathname.split("/");
  // const segment = segments[segments.length - 1];

  // parameter and index
  // const [param] = useSearchParams();
  // const [index, setIndex] = useState(0);
  // useEffect(() => {
  //   param.get("index") !== null && index <= 2
  //     ? setIndex(Number(param.get("index")))
  //     : setIndex(index);
  // });

  //   const loadLatestFeesConfig = useCallback(async () => {
  //     try {
  //       setLoading(true);
  //       const res = await axiosInstance.get(
  //         `/master/getallFeesConfig?page=1&sort=-createdAt`
  //       );
  //       const result = res.data.feesConfig;
  //       if (result.length >= 2 && result[0].feesType === result[1].feesType) {
  //         return { isClone: true, prevId: result[1]._id };
  //       } else {
  //         return { isClone: false, prevId: "" };
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.log(error);
  //     }
  //   }, []);

  //Add Config
  const formik = useFormik({
    initialValues: {
      startFeesYear: "",
      endFeesYear: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      startFeesYear: Yup.string().required("Fees Start Year is Required"),
      endFeesYear: Yup.string().required("Fees End Year is Required"),
    }),
    onSubmit: async () => {
      setLoading(true);
      try {
        const formData = {
          annualRegistrationFees: "1000",
          startFeesYear: formik.values.startFeesYear,
          endFeesYear: formik.values.endFeesYear,
        };
        console.log(formData, "jjj");

        setLoading(true);
        const res = await axiosInstance.post(
          `/master/addBasicFeesConfig`,
          formData
        );
        // await checkValidDateToupdate();
        if (res.data.message === "Created") {
          //   const isCloneX = await loadLatestFeesConfig();
          nav("..", {
            state: { x: res.data.result },
          });
          successToast();
          setLoading(false);
          //   setIndex(1);
          //   if (isCloneX?.isClone as boolean) {
          //     state.isClone = true;
          //     state.prevConfigId = isCloneX?.prevId;
          //     const item = { ...state, ...res.data.result };
          //     nav(
          //       {
          //         pathname: `/transportationFeesMain/feesSection/structureAdd`,
          //         search: `?index=1`,
          //       },
          //       { state: item }
          //     );
          //   } else {
          //     const item = { ...state, ...res.data.result };
          //     nav(
          //       {
          //         pathname: `/transportationFeesMain/feesSection/structureAdd`,
          //         search: `?index=1`,
          //       },
          //       { state: item }
          //     );
          //   }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  const checkValidDateToupdate = async () => {
    const res = await axiosInstance.get(`/master/getallFeesConfigNoLimit`);
    const result = res.data.feesConfig;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    result.forEach((x: FeesConfig) => {
      const startFeesYear = new Date(x.startFeesYear);
      const endFeesYear = new Date(x.endFeesYear);
      const startFeesMonth = startFeesYear.getMonth();
      const startFeesYearValue = startFeesYear.getFullYear();
      const endFeesMonth = endFeesYear.getMonth();
      const endFeesYearValue = endFeesYear.getFullYear();

      if (
        (currentYear > startFeesYearValue && currentYear < endFeesYearValue) ||
        (currentYear === startFeesYearValue &&
          currentYear === endFeesYearValue &&
          currentMonth >= startFeesMonth &&
          currentMonth <= endFeesMonth) ||
        (currentYear === startFeesYearValue &&
          currentMonth >= startFeesMonth) ||
        (currentYear === endFeesYearValue && currentMonth <= endFeesMonth)
      ) {
        state.configId = x._id;
      }
    });
  };

  const handleChange = (e: any, formik: any) => {
    const selectedDate = new Date(e.target.value + "-01");
    const formattedDate = `${("0" + (selectedDate.getMonth() + 1)).slice(
      -2
    )}-01-${selectedDate.getFullYear()}`;
    console.log(formattedDate);
    formik.setFieldValue("startFeesYear", formattedDate);
  };

  const handleMonthChange = (e: any, formik: any) => {
    const selectedDate = new Date(e.target.value + "-01");
    const lastDayOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0
    ).getDate();
    const formattedDate = `${
      selectedDate.getMonth() + 1
    }-${lastDayOfMonth}-${selectedDate.getFullYear()}`;

    console.log(formattedDate, "hhh");

    formik.setFieldValue("endFeesYear", formattedDate);
  };

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="startFeesYear">
              <input
                className={`form-control session-date ${
                  formik.errors.startFeesYear && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="startFeesYear"
                name="startFeesYear"
                type="month"
                onBlur={formik.handleBlur}
                onChange={(e) => handleChange(e, formik)}
                // value={formik.values.startFeesYear as string}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <span className="top-placeholder">Fees Start Year*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="endFeesYear">
              <input
                className={`form-control session-date ${
                  formik.errors.endFeesYear && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="endFeesYear"
                name="endFeesYear"
                type="month"
                onBlur={formik.handleBlur}
                onChange={(e) => handleMonthChange(e, formik)}
                // value={formik.values.endFeesYear as string}
                placeholder="End Fees Year"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <span className="top-placeholder">Fees End Year*</span>
            </label>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                toastValidation(formik.errors);
              }}
            />
            <CancelBtn btnTxt="Cancel" path="/paymentMasterBasic" />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(BasicFeesConfig);
