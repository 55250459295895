import axiosInstance from "axiosInstance";
import React, { useEffect, useState } from "react";
import memberPickupImg from "../../../assets/images/pickupCount.svg";
import memberInImg from "../../../assets/images/inCount-02.png";
import memberOut from "../../../assets/images/outCount.svg";
import memberWait from "../../../assets/images/waitCount-04.png";
import socket from "socket";

const MapRightInfo = () => {
  const [memberPickupCount, setMemberPickupCount] = useState<number>(0);
  const [memberInToday, setMemberInToday] = useState<number>(0);
  const [memberOutToday, setMemberOutToday] = useState<number>(0);
  const [memberWaitToday, setMemberWaitToday] = useState<number>(0);

  const loadMemberDashDetails = async () => {
    try {
      const res = await axiosInstance.get(`/master/getAllDriverDetailsDash`);
      const data = res.data;

      setMemberPickupCount(data.PickUpDetails);
      setMemberInToday(data.inMemberDetails);
      setMemberOutToday(data.outMemberDetails);
      setMemberWaitToday(data.waitMemberDetails);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadMemberDashDetails();

    socket.on("update-count", (data) => {
      if (
        data === "inMember" ||
        data === "outMember" ||
        data === "waitMember" ||
        data === "memberPick" ||
        data === "driverLeave" ||
        data === "memberLeave"
      ) {
        loadMemberDashDetails();
      }
    });
  }, []);

  return (
    <div className="col-lg-2">
      <CardComponent
        count={memberPickupCount}
        text="PickUp"
        background={memberPickupImg}
      />
      <CardComponent
        count={memberInToday}
        text="In count"
        background={memberInImg}
      />
      <CardComponent
        count={memberOutToday}
        text="Out count"
        background={memberOut}
      />
      <CardComponent
        count={memberWaitToday}
        text="Wait count"
        background={memberWait}
      />
    </div>
  );
};
export default MapRightInfo;
const CardComponent = ({
  count,
  text,
  background,
}: {
  count: number;
  text: string;
  background: string;
}) => {
  return (
    <>
      <div
        className="infoBox mb-4"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "contain",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="infoLeft">
          <span>{text}</span>
          {count}
        </div>
      </div>
    </>
  );
};
