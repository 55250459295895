import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { RouteLocationListType } from "types/routeLocationTypes";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { convertTo12HourFormat } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: RouteLocationListType;
  state: any;
};

const RouteLocationListItem = ({ x, state }: Props) => {
  const nav = useNavigate();

  const handleEditClick = () => {
    const item = { ...state, routeLocId: x._id };
    nav("/routeMaster/editForm/editLoc", {
      state: item,
    });
  };

  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit </Tooltip>;

  return (
    <tr key={x._id}>
      <td>{`${x.area} (${x.pinCode})`}</td>
      <td>{x.areadivision}</td>
      <td>{x.locationname}</td>
      <td>{convertTo12HourFormat(x.pickupTime)}</td>
      <td>{convertTo12HourFormat(x.dropTime)}</td>
      <td className="text-center">
        {x.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center pointer">
        {/* <Edit
          id={x._id}
          onClick={(e) => {
            const item = { ...state, routeLocId: x._id };
            nav("/routeMaster/editForm/editLoc", {
              state: item,
            });
          }}
        /> */}
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={x._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(RouteLocationListItem);
