import React, { memo, useState } from "react";
import { AttendantLeaveDataType } from "../../../types/attendantLeaveTypes";
import axiosInstance from "axiosInstance";
import { Button, Modal } from "react-bootstrap";
import { drvImage, formatDate } from "constants/Constants";
import { ReactComponent as Approved } from "assets/images/approve-20.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: AttendantLeaveDataType;
  loadAttendantLeave: (
    pageNum: number,
    sortParam: string,
    searchKeyword: string
  ) => any;
};

const AttendantLeaveItem = ({ x, loadAttendantLeave }: Props): JSX.Element => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const onSubmit = async (id: string) => {
    try {
      const formData = {
        isApproved: true,
        approvedDate: new Date(),
      };
      const res = await axiosInstance.patch(
        `/master/updateAttendantLeave/${id}`,
        formData
      );
      if (res) {
        loadAttendantLeave(1, "attendantName", "");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Approved</Tooltip>;
  return (
    <>
      <tr key={`${x._id}${Math.random()}`}>
        <td>
          <i className="userImg">
            <img
              src={`${drvImage}${x.photo !== "" ? x.photo : "Default.png"}`}
              alt="DriverPic"
            />
          </i>
        </td>
        <td>{x.attendantName}</td>
        <td>{x.vehicleName}</td>
        <td>{x.routeName}</td>
        <td>{formatDate(x.startDate)}</td>
        <td>{formatDate(x.endDate)}</td>
        <td>{x.isApproved === false ? "No" : "Yes"}</td>
        <td>{formatDate(x.createdAt)}</td>
        <td className="text-center pointer">
          {x.isApproved === true ? (
            <Approved className="Approved_Size" type="button" aria-disabled />
          ) : (
            <OverlayTrigger placement="bottom" overlay={tooltip}>
              <Approved
                className="Approved_Size"
                id={x._id}
                type="button"
                onClick={(e) => {
                  setShow(true);
                }}
              />
            </OverlayTrigger>
          )}
        </td>
      </tr>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Are You Sure For Leave Confirmation?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              onSubmit(x._id);
              // window.location.reload();
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(AttendantLeaveItem);
