import { dateFormatForAdmin } from "constants/Constants";
import { memo } from "react";
import { OrganizationType } from "types/organizationTypes";

type Props = {
  x: OrganizationType;
};

const VerifyCustomerItem = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x.name}>
      <td className="py-4">{x.name}</td>
      <td className="py-4">{x.businessType}</td>
      <td className="py-4">{x.planName}</td>
      <td className="text-center py-4">{dateFormatForAdmin(x.startDate)}</td>
      <td className="text-center py-4">{dateFormatForAdmin(x.endDate)}</td>
      <td className="text-center py-4">
        {x.paymentCheck === true ? "Yes" : "No"}
      </td>
      <td className="text-center py-4">
        {x.rfidCardCheck === true ? "Yes" : "No"}
      </td>
      <td className="text-center py-4">
        {x.isMultiShift === true ? "Yes" : "No"}
      </td>
    </tr>
  );
};

export default memo(VerifyCustomerItem);
