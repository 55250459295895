import React, { memo, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};
export type PlanType = {
  _id: string;
  planType: string;
  code: string;
};

const AdminPlanForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [planTypes, setPlanTypes] = useState<PlanType[]>();
  const [planTypeId, setPlanTypeId] = useState("");
  const [border, setBorder] = useState<boolean>(false);
  const fetchPlanTypes = async () => {
    try {
      const res = await axiosInstance.get("/master/getAllPlanType");
      setPlanTypes(res.data?.response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPlanTypes();
  }, []);

  const formik = useFormik({
    initialValues: {
      plan: "",
      code: "",
      price: "",
      discount: "",
      startDate: "",
      endDate: "",
      active: true,
    },
    validationSchema: Yup.object().shape({
      plan: Yup.string().required("Plan is Required"),
      code: Yup.string(),
      price: Yup.string(),
      discount: Yup.string(),
      startDate: Yup.string().required("StartDate is Required"),
      endDate: Yup.string().required("EndDate is Required"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      plan,
      code,
      price,
      discount,
      startDate,
      endDate,
      active,
    }) => {
      setLoading(true);
      try {
        const formData = {
          plan: plan,
          code: code,
          price: price,
          planTypeId,
          discount: discount,
          startDate: startDate,
          endDate: endDate,
          isActive: active,
        };
        const res = await axiosInstance.post("/admin/addAdminPlan", formData);
        const responseMessage = res.data;
        if (res.status === 202) {
          setLoading(false);
          warnToast(responseMessage);
        }
        if (responseMessage === "Ok") {
          successToast();
          nav("/adminPlan", {
            state: res.data?.resp,
          });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="plan">
              <span className="top-placeholder">Plan*</span>
              <input
                className={`form-control ${
                  formik.errors.plan && border ? "empty-form" : ""
                }`}
                id="plan"
                name="plan"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.plan}
                placeholder="Plan"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="code">
              <span className="top-placeholder">Code</span>
              <input
                className="form-control"
                id="code"
                name="code"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                placeholder="Code"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="price">
              <span className="top-placeholder">Price</span>
              <input
                className="form-control"
                placeholder="Price"
                id="price"
                name="price"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.price}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="discount">
              <span className="top-placeholder">Discount</span>
              <input
                className="form-control"
                id="discount"
                name="discount"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.discount}
                placeholder="Discount"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="planType">
              <span className="top-placeholder">PlanType*</span>
              <select
                className={`form-control ${
                  planTypeId === "" && border ? "empty-form" : ""
                }`}
                id="planType"
                name="planType"
                onChange={(e) => {
                  setPlanTypeId(e.target.value);
                }}
              >
                <option value="" selected>
                  Select Plan Type
                </option>
                {planTypes?.map(({ _id, code, planType }) => {
                  return (
                    <option key={_id} value={_id}>
                      {planType}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="startDate">
              <span className="top-placeholder">Start Date*</span>
              <input
                className={`form-control ${
                  formik.errors.startDate && border ? "empty-form" : ""
                }`}
                id="startDate"
                name="startDate"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.startDate}
                placeholder="StartDate"
                onFocus={(e) => (e.target.type = "date")}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="endDate">
              <span className="top-placeholder">End Date*</span>
              <input
                className={`form-control ${
                  formik.errors.endDate && border ? "empty-form" : ""
                }`}
                id="endDate"
                name="endDate"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.endDate}
                placeholder="EndDate"
                onFocus={(e) => (e.target.type = "date")}
              />
            </label>
            <div className="col-md-6 mb-3">
              <label htmlFor="active" className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="text-center col-md-12 mb-3">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                setBorder(true);
                if (planTypeId === "") {
                  warnToast("Select Plan Type");
                }
                toastValidation(formik.errors);
              }}
            />
            <CancelBtn path=".." />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(AdminPlanForm);
