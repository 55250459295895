import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { useLoading } from "Context/LoadingContext";
import { MemberFeesType } from "types/memberFeesTypes";
import { useLocation } from "react-router-dom";
import MemberFeesDetailsListItems from "./detailsListItem";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";

type Props = {
  state: any;
};

const MemberDetailsFees = ({ state }: Props): JSX.Element => {
  // const { state } = useLocation();
  const [memberFees, setMemberFees] = useState<MemberFeesType[]>([]);
  const { notfound, setNotFound } = useNotFound();
  const { setLoading } = useLoading();

  const loadMemberFeesList = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getOneMemberFees/${state.member._id}`
      );
      setMemberFees(res.data.result.memberTerms);
      setNotFound(false);
    } catch (error) {
      setNotFound(true);
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  }, [memberFees]);

  useEffect(() => {
    loadMemberFeesList();
  }, []);

  return (
    <>
      {/* <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Member Fees Details</span>
      </div> */}
      <table className="table">
        <thead>
          <tr>
            <th>Member Name</th>
            <th>Term</th>
            <th>Start Month - End Month</th>
            <th>Term Date - Due Date</th>
            <th>Fees</th>
            <th>Paid Fees</th>
            <th>Pay Type</th>
            <th>Payment Reference ID</th>
            <th>Payment</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {memberFees?.map((item) => {
              return (
                <MemberFeesDetailsListItems item={item} key={Math.random()} />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(MemberDetailsFees);
