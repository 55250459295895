import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

type Props = {
  infoMsg: string;
};

const InfoToast = ({ infoMsg }: Props) => {
  const [toast, setToast] = useState(true);
  const [info, setInfo] = useState(infoMsg);
  return (
    <ToastContainer position="top-end">
      <Toast
        show={toast}
        onClose={() => {
          setToast(!toast);
          setInfo("");
        }}
        bg="primary"
        delay={3000}
        autohide
      >
        <Toast.Body className="text-white">{info}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default InfoToast;
