import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { successToast, warnToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Outlet, useLocation } from "react-router-dom";

type Props = {};

const RouteAddForm = (props: Props) => {
  const { pathname } = useLocation();
  const segments = pathname.split("/");
  const segment = segments[segments.length - 1];
  const nav = useNavigate();
  const { setLoading } = useLoading();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      code: "",
      description: "",
      note: "",
      details: "",
      active: true,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Route Name is Too Short!")
        .max(30, "Route Name is Too Long!")
        .required("Route Name is Required"),
      code: Yup.string().max(10, "Code is Too Long!"),
      description: Yup.string().max(300, "Description is Too Long!"),
    }),
    onSubmit: async ({ name, code, description, active }) => {
      try {
        setLoading(true);
        const formData = {
          name: name,
          code: code,
          description: description,
          note: "",
          details: "",
          isActive: active,
        };

        const res = await axiosInstance.post("/master/addRoute", formData);
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          const item = res.data?.Route;
          nav("/routeMaster/addForm/routeVehicleAdd", {
            state: item,
          });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  return (
    <>
      {pathname !== "/routeMaster" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/routeMaster" />
        </div>
      )}
      <Tabs
        selectedIndex={
          (segment === "routeVehicleAdd" && 1) ||
          (segment === "routeLocationAdd" && 2) ||
          // (segment === "cardAdd" && 3) ||
          0
        }
        onSelect={() => {}}
      >
        <TabList>
          <Tab disabled={pathname !== "/routeMaster/addForm"}>Route</Tab>
          <Tab disabled={segment !== "routeVehicleAdd"}>Route Vehicle</Tab>
          <Tab disabled={segment !== "routeLocation"}>Route Location</Tab>
        </TabList>

        <TabPanel>
          <div className="formBg">
            <form
              className="row align-items-center "
              onSubmit={formik.handleSubmit}
            >
              <label className="col-md-6 mb-3" htmlFor="name">
                <input
                  className={`form-control ${
                    formik.errors.name && formik.submitCount > 0
                      ? "empty-form"
                      : ""
                  }`}
                  id="name"
                  name="name"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  placeholder="Route Name"
                />

                <span className="top-placeholder">Route Name*</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="code">
                <input
                  className="form-control"
                  id="code"
                  name="code"
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.code}
                  placeholder="Code"
                />

                <span className="top-placeholder">Code</span>
              </label>
              <label className="col-md-12 mb-3" htmlFor="description">
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  placeholder="Description"
                />

                <span className="top-placeholder">Description</span>
              </label>
              <div className="col-md-3 mb-3">
                <label htmlFor="active" className="custCheck">
                  Active
                  <input
                    type="checkbox"
                    id="active"
                    name="active"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={formik.values.active}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="col-md-12 mb-3 text-center">
                <SubmitBtn errors={formik.errors} />
                <CancelBtn path="/routeMaster" />
              </div>
            </form>
          </div>
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default memo(RouteAddForm);
