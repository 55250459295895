export const masterItems = [
  {
    to: "departmentMaster",
    text: "Department",
  },
  {
    to: "category",
    text: "Category",
  },
  {
    to: "typeMaster",
    text: "Sub Category",
  },
  {
    to: "shiftTypeMaster",
    text: "Shift Type",
  },
  {
    to: "vehicleMaster",
    text: "Vehicle Type",
  },
  // {
  //   to: "holidayMaster",
  //   text: "Holiday",
  // },
  {
    to: "configuration",
    text: "Configuration",
  },
  // {
  //   to: "paymentConfig",
  //   text: "Payment Configuration",
  // },
];
export const routeItems = [
  {
    to: "routeMaster",
    text: "Route",
  },
  // {
  //   to: "routeVehicle",
  //   text: "Route Vehicle",
  // },
  // {
  //   to: "routeLocation",
  //   text: "Route Location",
  // },
  // {
  //   to: "routeMember",
  //   text: "Route Member",
  // },
];
export const reportItems = [
  {
    to: "drivertracking",
    text: "Driver Tracking",
  },
  {
    to: "report",
    text: "Driver Details",
  },
  // {
  //   to: "memberCountByLoc",
  //   text: "Member Count By Location",
  // },
  {
    to: "routeDetailsReport",
    text: "Route Details",
  },
  {
    to: "areaDetailsReport",
    text: "Area Details",
  },
  {
    to: "driverComplaint",
    text: "Driver Complaint",
  },
  {
    to: "vehiclePucReport",
    text: "Expired PUC Report",
  },
  {
    to: "vehicleInsuranceReport",
    text: "Expired Insurance Report",
  },
  {
    to: "waitReport",
    text: "Wait Report",
  },
  {
    to: "memGetPassReport",
    text: "Member Get pass Report",
  },
  // {
  //   to: "memberPickUpDropLocationTime",
  //   text: "Member PickUpDrop Location & Time Report",
  // },
  {
    to: "driverLeaveReport",
    text: "Driver Leave",
  },
  {
    to: "sosReport",
    text: "SOS Report",
  },
  {
    to: "speedReports",
    text: "Driver Speed Report",
  },
  {
    to: "paymentReport",
    text: "Payment Report",
  },
  {
    to: "driverPickupOffDropOffReport",
    text: "Driver Pickup/Drop Off",
  },
  {
    to: "memberPickupOffDropOffReport",
    text: "Member Pickup/Drop Off",
  },
  {
    to: "driverOnOffReport",
    text: "Driver ON/OFF",
  },
];
export const vehicleItems = [
  {
    to: "vehicleRegistration",
    text: "Vehicle Registration",
  },
];
export const areaItems = [
  {
    to: "areaMaster",
    text: "Area",
  },
  {
    to: "areaDivision",
    text: "Area Division",
  },
  {
    to: "locationMaster",
    text: "Location",
  },
];
export const basicSideBarItems = [
  {
    to: "basiclocationmaster",
    text: "Location",
    icon: 1,
  },
  {
    to: "basicmember",
    text: "Members",
    icon: 1,
  },
  {
    to: "configuration",
    text: "Configuration",
    icon: 2,
  },
  {
    to: "shiftTypeMaster",
    text: "Shift",
    icon: 3,
  },
  {
    to: "basicvehicle",
    text: "Vehicle",
    icon: 4,
  },
  {
    to: "paymentMasterBasic",
    text: "Payment Management",
    icon: 5,
  },
  {
    to: "memberLeaves",
    text: "Member Leaves",
    icon: 2,
  },
  {
    to: "basicMemberWaitList",
    text: "Member Wait List",
    icon: 6,
  },
  {
    to: "notification",
    text: "Notification",
    icon: 7,
  },
];
export const BasicreportItems = [
  {
    to: "memberDetails",
    text: "Member Details",
  },
  {
    to: "paymentReport",
    text: "Payment Report",
  },
  {
    to: "memberWaitReport",
    text: "Member Wait Report",
  },
  {
    to: "basicMemberPickupOffDropOffReport",
    text: "Member Pickup/Drop Off",
  },
  {
    to: "basicDriverPickupOffDropOffReport",
    text: "Driver Pickup/Drop Off",
  },
];
