import React, { useState, memo } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

type Props = {};
type ContextType = {
  setTypeId: (typeId: string) => void;
  typeId: string;
};

const TypeMaster: React.FC<{}> = (props: Props) => {
  const [typeId, setTypeId] = useState<string>("");

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Sub Category</span>
      </div>
      <Outlet context={{ typeId, setTypeId }} />
    </>
  );
};

export default memo(TypeMaster);

export function useTypeId() {
  return useOutletContext<ContextType>();
}
