import React from "react";

type RenderHTMLContentProps = {
  htmlContent: any;
};

const RenderHTMLContent: React.FC<RenderHTMLContentProps> = ({
  htmlContent,
}) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default RenderHTMLContent;
