import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { locationListDataType } from "types/locationMasterType";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: locationListDataType;
};

const LocationMasterListItem = ({ x }: Props) => {
  const nav = useNavigate();

  const handleEditClick = () => {
    nav("locEdit", {
      state: { x },
    });
  };

  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit </Tooltip>;

  return (
    <tr key={x._id}>
      <td>{x.area}</td>
      <td>{x.division}</td>
      <td>{x.name}</td>
      <td>{x.code}</td>
      <td>{x.address1}</td>
      <td>{x.pinCode}</td>
      <td className="text-center">
        {x.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center pointer">
        {/* <Edit
          id={x._id}
          onClick={(e) => {
            nav("locEdit", {
              state: { x },
            });
          }}
        /> */}
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={x._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(LocationMasterListItem);
