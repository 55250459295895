import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/font/font.css";
import "assets/styles/style.css";
import App from "app/app";
import { Provider } from "react-redux";
import store from "store";
import { LoadingProvider } from "Context/LoadingContext";
import { ToastContainer } from "react-toastify";
import { NotFoundProvider } from "Context/NotFound";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <LoadingProvider>
      <NotFoundProvider>
        <BrowserRouter>
          <Provider store={store}>
            <App />
            <ToastContainer
              position="top-right"
              autoClose={1500}
              pauseOnFocusLoss={false}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              theme="light"
            />
          </Provider>
        </BrowserRouter>
      </NotFoundProvider>
    </LoadingProvider>
  </>
);
