import { Link } from "react-router-dom";

type CardComponentProps = {
  count: number;
  text: string;
  background: string;
  className?: string;
};

const CardComponent = ({
  count,
  text,
  background,
  className = "col-lg-3 mb-3",
}: CardComponentProps) => {
  let linkTo: string = "";

  if (text === "New Demo Request") {
    linkTo = "/dashboard/demoRequest";
  } else if (text === "Demo Reschedule") {
    linkTo = "/dashboard/demoReschedule";
  } else if (text === "Demo Completed") {
    linkTo = "/dashboard/demodone";
  } else if (text === "Reminder Plan") {
    linkTo = "/dashboard/expireplan";
  } else if (text === "New Customer") {
    linkTo = "/dashboard/newcustomer";
  } else if (text === "Verified Customer") {
    linkTo = "/dashboard/verifycustomer";
  } else if (text === "Sos") {
    linkTo = "/dashboard/SosList";
  } else if (text === "Need Approval") {
    linkTo = "/leaveTabs?index=0&today=todayPendingApproval";
  } else if (text === "Payment Overdue") {
    linkTo = "/paymentManagement";
  }
  // else if (text === "Member Leaves") {
  //   linkTo = `/leaveTabs?index=1&today=today`;
  // }
  else if (text === "Driver Complaints") {
    linkTo = `/complaints?today=true`;
  } else if (text === "Driver Leaves") {
    linkTo = `/leaveTabs?index=0&today=today`;
  } else if (text === "Pickup" || text === "Dropup") {
    linkTo = `/memberDetails?text=getAllTodayPickupMembersList&pickup=${
      text === "Pickup" ? "true" : "false"
    }`;
  } else if (text === "Pickup In" || text === "Drop In") {
    linkTo = `/memberDetails?text=getAllMemberInDetail&pickup=${
      text === "Pickup In" ? "true" : "false"
    }`;
  } else if (text === "Pickup Out" || text === "Drop Out") {
    linkTo = `/memberDetails?text=getAllMemberOutDetails&pickup=${
      text === "Pickup Out" ? "true" : "false"
    }`;
  } else if (text === "Pickup Wait" || text === "Drop Wait") {
    linkTo = `/memberDetails?text=getAllMemberWaitCountDetails&pickup=${
      text === "Pickup Wait" ? "true" : "false"
    }`;
  }
  return (
    <>
      <div className={className}>
        {linkTo ? (
          <Link to={linkTo} className="card-link">
            <div
              className="infoBox"
              style={{
                backgroundImage: `url(${background})`,
                backgroundSize: "contain",
                backgroundPosition: "right",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="infoLeft">
                <span>{text}</span>
                {count}
              </div>
            </div>
          </Link>
        ) : (
          <div
            className="infoBox"
            style={{
              backgroundImage: `url(${background})`,
              backgroundSize: "contain",
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="infoLeft">
              <span>{text}</span>
              {count}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default CardComponent;
