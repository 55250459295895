import { memo, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLoading } from "Context/LoadingContext";
import Form from "react-bootstrap/Form";
import { useFeesConfig } from "Context/FeesConfigurationContext";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import { Button, Modal } from "react-bootstrap";
import { convertToYYYYMMFormat } from "constants/Constants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import FeesStructure from "../FeesStructure/EditForm";
import CancelBtn from "components/Buttons/Cancel";
import FeesAreaList from "app/organizer/FeesByArea/List";
import FeesKmList from "app/organizer/FeesByKm/List";
import FeesByVehicleList from "../FeesByVehicle/MasterList";
import MemberFeesList from "../MemberFees/list";
import MemberFeesListFailed from "../FailedMemberFees/List";
import InvoiceTemplate from "components/InvoiceTemplate";

type FeesConfigType = {
  _id: string;
  organizerId: string;
  feesType: string;
  annualRegistrationFees: string;
  startFeesYear: string;
  endFeesYear: string;
};

const feesTypeData = ["area", "km", "vehicle"];

type Props = {};

const FeesConfig = (props: Props) => {
  //nav and state
  const nav = useNavigate();
  const { state } = useLocation();
  const { setLoading } = useLoading();

  //data storing
  const [feesType, setFeesType] = useState<string>("");
  const [data, setData] = useState<FeesConfigType>();
  const [show, setShow] = useState(false);

  //path setting
  const { pathname } = useLocation();

  // parameter and index
  const [param] = useSearchParams();
  const [index, setIndex] = useState(
    param.get("index") !== null ? Number(param.get("index")) : 0
  );
  //Clone is available or not
  const loadLatestFeesConfig = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getallFeesConfig?page=1&sort=-createdAt`
      );
      console.log(res);
      const result = res.data.feesConfig;
      if (result.length >= 2 && result[0].feesType === result[1].feesType) {
        return { isClone: true, prevId: result[1]._id };
      } else {
        return { isClone: false, prevId: "" };
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);
  //update Config functionality
  const fetchConfig = async () => {
    try {
      const resp = await axiosInstance.get(
        `/master/getOneFeesConfig/${state._id}`
      );
      setData(resp.data?.result);
      if (resp.status === 200) {
        setFeesType(resp.data?.result?.feesType);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => setShow(false);

  const onSubmit = async () => {
    try {
      if (feesType === "") return;
      const formData = {
        feesType,
        annualRegistrationFees: "1000",
        startFeesYear: formik.values.startFeesYear,
        endFeesYear: formik.values.endFeesYear,
      };
      setLoading(true);
      const res = await axiosInstance.put(
        `/master/updateFeesConfig/${state._id}`,
        formData
      );
      if (res.data.message === "Ok") {
        const isCloneX = await loadLatestFeesConfig();
        console.log(isCloneX);
        successToast();
        setLoading(false);
        fetchConfig();
        state.isClone = isCloneX?.isClone;
        state.prevConfigId = isCloneX?.prevId;
        const item = { ...state, ...res.data.result };
        nav(
          {
            pathname: `/transportationFeesMain/feesSectionEdit`,
            search: "?index=0",
          },
          { state: item }
        );
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const formik = useFormik({
    initialValues: {
      annualRegistrationFees: data?.annualRegistrationFees,
      startFeesYear: convertToYYYYMMFormat(data?.startFeesYear),
      endFeesYear: convertToYYYYMMFormat(data?.endFeesYear),
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      // annualRegistrationFees: Yup.number().required(
      //   "Annual Registration Fees is Required"
      // ),
      startFeesYear: Yup.string().required("startFeesYear is Required"),
      endFeesYear: Yup.string().required("endFeesYear is Required"),
    }),
    onSubmit: async () => {
      setShow(true);
    },
  });

  return (
    <>
      {pathname !== "/transportationFeesMain" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/transportationFeesMain" />
        </div>
      )}
      <Tabs
        selectedIndex={index}
        onSelect={(e) => {
          setIndex(e);
        }}
      >
        <TabList>
          <Tab>Fees Configuration</Tab>
          <Tab>Fees Structure Setting</Tab>
          {state.feesType === "km" && <Tab>Fees By Km</Tab>}
          {state.feesType === "area" && <Tab>Fees By Area</Tab>}
          {state.feesType === "vehicle" && <Tab>Fees By Vehicle</Tab>}
          <Tab>Member Fees</Tab>
          {state.configId === state._id && <Tab>Pending Fees</Tab>}
          <Tab>Invoice</Tab>
        </TabList>

        <TabPanel>
          <>
            <div className="formBg">
              <form onSubmit={formik.handleSubmit}>
                <div className="row align-items-center">
                  <label
                    className="col-md-12 mb-3 d-flex gap-5 p-3"
                    htmlFor="feesType"
                  >
                    <span>Configure Your Fees Type :</span>

                    <div>
                      {feesTypeData.map((x) => {
                        return (
                          <Form.Check
                            checked={x === feesType}
                            type="radio"
                            label={`${x.charAt(0).toUpperCase()}${x.slice(1)}`}
                            id={x}
                            key={x}
                            className="pb-3"
                            name="feesType"
                            onChange={(e) => {
                              setFeesType(e.target.id);
                            }}
                          />
                        );
                      })}
                    </div>
                  </label>
                  <label className="col-md-6 mb-3" htmlFor="startFeesYear">
                    <input
                      className={`form-control session-date ${
                        formik.errors.startFeesYear && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="startFeesYear"
                      name="startFeesYear"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.startFeesYear as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Start Fees Year</span>
                  </label>
                  <label className="col-md-6 mb-3" htmlFor="endFeesYear">
                    <input
                      className={`form-control session-date ${
                        formik.errors.endFeesYear && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="endFeesYear"
                      name="endFeesYear"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.endFeesYear as string}
                      placeholder="End Fees Year"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">End Fees Year</span>
                  </label>
                  {/* <label
                     className="col-md-6 mb-3 d-flex p-3 gap-5"
                     htmlFor="annualRegistrationFees"
                   >
                     <span>Annual Bus Registration Fees :</span>
                     <input
                       className="form-control"
                       id="annualRegistrationFees"
                       name="annualRegistrationFees"
                       type="number"
                       onBlur={formik.handleBlur}
                       onChange={formik.handleChange}
                       value={formik.values.annualRegistrationFees}
                       placeholder="Annual Bus Registration Fees"
                     />
                   </label> */}
                </div>
                <div className="col-md-12 mb-3 text-center">
                  {state.configId === state._id &&
                    state.isPaymentMade === false && (
                      <SubmitBtn
                        errors={formik.errors}
                        clickFunc={() => {
                          if (feesType === "") warnToast("Select Fees Type");
                          toastValidation(formik.errors);
                        }}
                      />
                    )}
                  {state.configId === state._id &&
                    state.isPaymentMade === false && (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          nav({
                            pathname: "/transportationFeesMain",
                          });
                        }}
                      >
                        Cancel
                      </button>
                    )}
                </div>
              </form>
            </div>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>Fees Configuration</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are You Sure You Want To Change The Fees Configuration Settings?
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShow(false);
                    onSubmit();
                  }}
                >
                  Submit
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </TabPanel>
        <TabPanel>
          <FeesStructure />
        </TabPanel>
        {state.feesType === "km" && (
          <TabPanel>
            <FeesKmList />
          </TabPanel>
        )}
        {state.feesType === "area" && (
          <TabPanel>
            <FeesAreaList />
          </TabPanel>
        )}
        {state.feesType === "vehicle" && (
          <TabPanel>
            <FeesByVehicleList />
          </TabPanel>
        )}
        <TabPanel>
          <MemberFeesList />
        </TabPanel>
        {state.configId === state._id && (
          <TabPanel>
            <MemberFeesListFailed />
          </TabPanel>
        )}
        <TabPanel>
          <InvoiceTemplate />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default memo(FeesConfig);
