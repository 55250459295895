import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryDataType from "../../../types/categoryTypes";
import { useLoading } from "Context/LoadingContext";
import { DepartmentDDLType } from "types/departmentMasterType";
import CancelBtn from "components/Buttons/Cancel";
import SubmitBtn from "components/Buttons/Submit";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";

type Props = {};

const TypeMasterForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const location = useLocation();
  const [categories, setCategories] = useState<CategoryDataType[]>([]);
  const [categoryId, setCategoryId] = useState("default");
  const [departmentId, setDepartmentId] = useState("default");
  const [departments, setDepartments] = useState<DepartmentDDLType[]>();
  const [border, setBorder] = useState<boolean>(false);

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const department = res.data.Departments;
      setDepartments(department);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const categoryList = useCallback(async (depId: string) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/dropDown/getCategory?depId=${depId}`
      );
      const categories = res.data.categories;
      setCategories(categories);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadDepartments();
  }, [loadDepartments]);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      code: "",
      description: "",
      active: true,
      note: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Sub Category Name is too Short!")
        .max(30, "Sub Category Name is tooLong!")
        .required("Sub Category Name is Required"),
      code: Yup.string().max(10, "Sub Category Code is too Long!"),
      description: Yup.string().max(
        300,
        "Sub Category Description is too Long!"
      ),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({ name, code, description, active }) => {
      try {
        setLoading(true);
        const formData = {
          categoryId: categoryId,
          departmentId: departmentId,
          name: name,
          code: code,
          description: description,
          isActive: active,
          note: "",
          details: "",
        };

        const res = await axiosInstance.post("/master/addType", formData);

        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          nav("/typeMaster");
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="department">
              <span className="top-placeholder">Department*</span>

              <select
                className={`form-control ${
                  departmentId === "default" && border ? "empty-form" : ""
                }`}
                id="department"
                onChange={(e) => {
                  setDepartmentId(e.target.value);
                  setCategories([]);
                  categoryList(e.target.value);
                }}
              >
                <option value="default">Select Department</option>
                {departments?.map(({ _id, departmentName }) => {
                  return (
                    <option key={_id} value={_id}>
                      {departmentName}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="category">
              <span className="top-placeholder">Category*</span>

              <select
                className={`form-control ${
                  categoryId === "default" && border ? "empty-form" : ""
                }`}
                id="category"
                onChange={(e) => {
                  setCategoryId(e.target.value);
                }}
              >
                <option value="default">Select Category</option>
                {categories?.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name && border ? "empty-form" : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Sub Category Name"
              />

              <span className="top-placeholder">Sub Category Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="code">
              <input
                className="form-control"
                id="code"
                name="code"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                placeholder="Sub Category Code"
              />

              <span className="top-placeholder">Sub Category Code</span>
            </label>
            <label className="col-md-12 mb-3" htmlFor="description">
              <textarea
                className="form-control"
                id="description"
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                placeholder="Sub Category Description"
              ></textarea>

              <span className="top-placeholder">Sub Category Description</span>
            </label>
            <div className="col-md-3 mb-3">
              <label className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                setBorder(true);
                if (departmentId === "default") {
                  warnToast("Select Department");
                }
                if (categoryId === "default") {
                  warnToast("Select Category");
                }
                toastValidation(formik.errors);
              }}
            />
            {(location.pathname === "/typeMaster/typeMasterForm" ||
              location.pathname === "/typeMaster/typeMasterEditForm") && (
              <CancelBtn path="/typeMaster" />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(TypeMasterForm);
