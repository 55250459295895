import React, { memo } from "react";
import { useFormik } from "formik";
import axiosInstance from "axiosInstance";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { ReqForPlans } from "./List";
import { successToast, warnToast } from "constants/toastConfig";
import * as Yup from "yup";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { dateFormatForAdmin } from "constants/Constants";
type Props = {};

const PlanRequestEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const data = state as ReqForPlans;
  console.log(data, "dd");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: data.amount,
      paymentCheck: data.paymentCheck,
      isMultiShift: data.isMultiShift,
      rfidCardCheck: data.rfidCardCheck,
      routeOptimization: data.routeOptimization,
      startDate: "",
      endDate: "",
    },
    validationSchema: Yup.object().shape({
      startDate: Yup.string().required("StartDate is Required"),
      endDate: Yup.string().required("EndDate is Required"),
    }),
    onSubmit: async ({
      amount,
      isMultiShift,
      paymentCheck,
      rfidCardCheck,
      startDate,
      endDate,
      routeOptimization,
    }) => {
      setLoading(true);
      try {
        if (amount === "") {
          warnToast("Enter amount");
        } else {
          const formData = {
            reqForPlanId: data._id,
            organizationId: data.organizationId,
            planId: data.planId,
            amount,
            isMultiShift,
            paymentCheck,
            rfidCardCheck,
            routeOptimization,
          };
          let refferalcodeData = {
            organizerId: data.organizationId,
            referenceId: data.referanceId,
            referencecode: data.referralcode,
          };
          const paymentData = {
            organizationId: data.organizationId,
            planId: data.planId,
            startDate,
            endDate,
            amount,
            discount: "0",
            referenceNo: "123",
            status: "Success",
          };
          const updateData = {
            amount,
            isMultiShift,
            paymentCheck,
            rfidCardCheck,
            routeOptimization,
          };
          console.log(updateData, "updateData");
          let executeblock = true;
          let res3 = null;
          if (data.referanceId) {
            res3 = await axiosInstance.post(
              `/admin/addRefferalcode`,
              refferalcodeData
            );
            if (res3.status === 204) {
              warnToast("Multiple Time Refferal Code use");
              executeblock = false;
            }
          }
          if (executeblock === true) {
            const res = await axiosInstance.put(
              `/admin/updateReqPlan/${data._id}`,
              updateData
            );
            const res2 = await axiosInstance.post(
              `/admin/addOrganizationPlan`,
              formData
            );
            const res4 = await axiosInstance.post(
              `/admin/addOrganizationPayment`,
              paymentData
            );
            if (
              res.data?.message === "Ok" &&
              res2.data?.message === "Ok" &&
              res4.data?.message === "Ok" &&
              (data.referanceId ? res3?.data === "Ok" : true)
            ) {
              successToast();
              nav("/planRequests");
            }
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <ul className="profileList">
              <li>
                <span>Organizer :</span>
                {data.organizerName}
              </li>
              <li>
                <span>Plan :</span>
                {data.planName}
              </li>
              <li>
                <span>Plan Type:</span>
                {data.planType}
              </li>
              {data.discount !== "0" && (
                <>
                  <li>
                    <span>Discount:</span>₹ {data.discount}
                  </li>
                  <li>
                    <span>Offer Start Date:</span>
                    {dateFormatForAdmin(data.startDate)}
                  </li>
                  <li>
                    <span>Offer End Date:</span>
                    {dateFormatForAdmin(data.endDate)}
                  </li>
                </>
              )}
              {data.reference !== "" && (
                <>
                  <li>
                    <span>Reference:</span>
                    {data.reference}
                  </li>
                  <li>
                    <span></span>
                  </li>
                  <li>
                    <span>Reference Code:</span>
                    {data.referralcode}
                  </li>
                </>
              )}
            </ul>
          </div>
          <label className="col-md-6 mb-3 px-2" htmlFor="startDate">
            <span className="top-placeholder">Plan Start Date*</span>
            <input
              className={`form-control ${
                formik.errors.startDate && formik.submitCount > 1
                  ? "empty-form"
                  : ""
              }`}
              id="startDate"
              name="startDate"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.startDate}
              placeholder="Plan StartDate"
              onFocus={(e) => (e.target.type = "date")}
            />
          </label>
          <label className="col-md-6 mb-3 px-2" htmlFor="endDate">
            <span className="top-placeholder">Plan End Date*</span>
            <input
              className={`form-control ${
                formik.errors.endDate && formik.submitCount > 1
                  ? "empty-form"
                  : ""
              }`}
              id="endDate"
              name="endDate"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.endDate}
              placeholder="Plan EndDate"
              onFocus={(e) => (e.target.type = "date")}
            />
          </label>
          <label className="col-md-6 mb-3 px-2" htmlFor="amount">
            <span className="top-placeholder">Amount*</span>
            <input
              className={`form-control ${
                formik.values.amount === "" ? "empty-form" : ""
              }`}
              id="amount"
              name="amount"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.amount}
              placeholder="Amount"
            />
          </label>
          <div className=" row align-items-center mb-5 ">
            <div className="col-md-3 mb-3">
              <label className="custCheck" htmlFor="paymentCheck">
                Payment
                <input
                  type="checkbox"
                  id="paymentCheck"
                  name="paymentCheck"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.paymentCheck}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {data.planCode === "PRM" && (
              <div className="col-md-3 mb-3">
                <label className="custCheck" htmlFor="isMultiShift">
                  Multi Shift
                  <input
                    type="checkbox"
                    id="isMultiShift"
                    name="isMultiShift"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={formik.values.isMultiShift}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
            {(data.planCode === "ADV" || data.planCode === "PRM") && (
              <div className="col-md-3 mb-3">
                <label className="custCheck" htmlFor="rfidCardCheck">
                  RFID Card
                  <input
                    type="checkbox"
                    id="rfidCardCheck"
                    name="rfidCardCheck"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    checked={formik.values.rfidCardCheck}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            )}
            <div className="col-md-3 mb-3">
              <label className="custCheck" htmlFor="routeOptimization">
                Route Optimization
                <input
                  type="checkbox"
                  id="routeOptimization"
                  name="routeOptimization"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.routeOptimization}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            <CancelBtn path=".." />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(PlanRequestEditForm);
