import React, { memo } from "react";
import OrgDashBoardLayout from "./OrgDashBoardLayout";
type Props = {};

const OrganizationDashboard: React.FC<{}> = (props: Props) => {
  return (
    <>
      <div className="sup_heading">
        <span className="mx-2">Organization</span>
      </div>
      <OrgDashBoardLayout />
    </>
  );
};
export default memo(OrganizationDashboard);
