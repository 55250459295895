import React, { useState, memo, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { DepartmentDDLType } from "types/departmentMasterType";
import { useLoading } from "Context/LoadingContext";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const CategoryAddForm = (props: Props) => {
  const { setLoading } = useLoading();

  const nav = useNavigate();
  const [depId, setDepId] = useState("default");
  const [border, setBorder] = useState<boolean>(false);
  const [department, setDepartment] = useState<DepartmentDDLType[]>([]);

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const department = res.data.Departments;
      setDepartment(department);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadDepartments();
  }, [loadDepartments]);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      code: "",
      description: "",
      active: true,
      note: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Category name is too short")
        .max(30, "Category name is too long")
        .required("Category name is Required"),
      code: Yup.string().max(10, "Category code is too long"),
      description: Yup.string().max(300, "Description is too long"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({ name, code, description, active }) => {
      setLoading(true);
      try {
        const formData = {
          departmentId: depId,
          name: name,
          code: code,
          description: description,
          isActive: active,
          note: "",
          details: "",
        };
        const res = await axiosInstance.post("/master/addCategory", formData);
        const responseMessage = res.data;
        if (responseMessage === "Created") {
          successToast();
          setTimeout(() => {
            setLoading(false);
            nav("/category");
          }, 1000);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <div className="formBg">
      <form className="row align-items-center" onSubmit={formik.handleSubmit}>
        <label htmlFor="department" className="col-md-6 mb-3">
          <span className="top-placeholder">Department*</span>

          <select
            className={`form-control ${
              depId === "default" && border ? "empty-form" : ""
            }`}
            id="department"
            name="department"
            onChange={(e) => {
              setDepId(e.target.value);
            }}
          >
            <option value="default">Select Department</option>
            {department.map(({ _id, departmentName }) => {
              return (
                <option key={_id} value={_id}>
                  {departmentName}
                </option>
              );
            })}
          </select>
        </label>
        <label className="col-md-6 mb-3" htmlFor="name">
          <input
            className={`form-control ${
              formik.errors.name && border ? "empty-form" : ""
            }`}
            id="name"
            name="name"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="Category Name"
          />

          <span className="top-placeholder">Category Name*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="code">
          <input
            className="form-control"
            id="code"
            name="code"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.code}
            placeholder="Category Code"
          />

          <span className="top-placeholder">Category Code</span>
        </label>
        <label className="col-md-12 mb-3" htmlFor="description">
          <textarea
            className="form-control"
            id="description"
            name="description"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.description}
            placeholder="Category Description"
          />

          <span className="top-placeholder">Category Description</span>
        </label>
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn
            errors={formik.errors}
            clickFunc={() => {
              setBorder(true);
              if (depId === "default") warnToast("Select Department");
              toastValidation(formik.errors);
            }}
          />
          <CancelBtn path="/category" />
        </div>
      </form>
    </div>
  );
};

export default memo(CategoryAddForm);
