import axiosInstance from "axiosInstance";
import * as Yup from "yup";
import React, { useCallback, useState, useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RouteMasterType from "types/route.types";
import { useLoading } from "Context/LoadingContext";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import { LocationDataTypeDDL } from "types/locationMasterType";
import { AreaDDLType } from "types/areaMasterTypes";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const RouteLocationAddForm = (props: Props) => {
  const nav = useNavigate();
  const { setLoading } = useLoading();
  const { state } = useLocation();
  const [param] = useSearchParams();
  const [area, setArea] = useState<AreaDDLType[]>([]);
  const [pin, setPin] = useState("");
  const [areaName, setAreaName] = useState("");

  const [division, setDivision] = useState<AreaDivisionDDL[]>([]);
  const [divId, setDivId] = useState("");

  const [location, setLocation] = useState<LocationDataTypeDDL[]>([]);
  const [locId, setLocId] = useState("");
  const [border, setBorder] = useState<boolean>(false);

  const loadAreas = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getArea`);
      setArea(res.data.Areas);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadAreaCode = useCallback(async (pinCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getAreaDivision?pinCode=${pinCode}`
      );
      setLoading(false);
      return res.data.division;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadLocation = useCallback(async (areaCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getLocationByDivision?areaCode=${areaCode}`
      );
      setLoading(false);
      return res.data.locationNames;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadAreas();
  }, [loadAreas]);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      pickupTime: "",
      dropTime: "",
      active: true,
    },

    validationSchema: Yup.object().shape({
      pickupTime: Yup.string().required("Pickup Time is Required"),
      dropTime: Yup.string().required("Drop Time is Required"),
    }),

    onSubmit: async ({ pickupTime, dropTime, active }) => {
      setLoading(true);
      try {
        const formData = {
          routeId: state._id,
          divisionId: divId,
          area: areaName,
          pinCode: pin,
          locationId: locId,
          pickupTime: pickupTime,
          dropTime: dropTime,
          isActive: active,
        };
        const res = await axiosInstance.post(
          "/master/addRouteLocation",
          formData
        );
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          if (Number(param.get("fromEdit")) !== 1) {
            nav("../..");
          } else {
            const item = state;
            nav(
              {
                pathname: "/routeMaster/editForm",
                search: "?index=2",
              },
              { state: item }
            );
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  const areaDropDown = async (m: string) => {
    if (m !== "") {
      setPin(m);
      const div = await loadAreaCode(m);
      if (div !== undefined) {
        setDivision(div);
      } else {
        setDivision([]);
      }
    } else {
      setDivision([]);
      setLocation([]);
    }
  };
  const divisionDropDown = async (n: string) => {
    if (n !== "") {
      setDivId(n);
      const loc = await loadLocation(n);
      if (loc !== undefined) {
        setLocation(loc);
      } else {
        setLocation([]);
      }
    } else {
      setLocation([]);
    }
  };
  const settingAreaName = async (pincode: string) => {
    const name = area.find((x) => {
      return x.pinCode === pincode;
    })?.areaName;
    setAreaName(name as string);
  };

  return (
    <>
      <div className="formBg">
        <form className="row align-items-center" onSubmit={formik.handleSubmit}>
          <label htmlFor="area" className="col-md-6 mb-3">
            <span className="top-placeholder">Area*</span>
            <select
              className={`form-control ${
                pin === "" && border ? "empty-form" : ""
              }`}
              id="area"
              onChange={async (e) => {
                areaDropDown(e.target.value);
                settingAreaName(e.target.value);
              }}
            >
              <option value="" selected>
                Select Area
              </option>
              {area.map(({ _id, areaName, pinCode }) => {
                return (
                  <option key={_id} value={pinCode}>
                    {`${areaName} (${pinCode})`}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="areaCode">
            <span className="top-placeholder">Division*</span>
            <select
              className={`form-control ${
                divId === "" && border ? "empty-form" : ""
              }`}
              id="areaCode"
              name="areaCode"
              onChange={async (e) => {
                divisionDropDown(e.target.value);
              }}
            >
              <option value="" selected>
                Select Division
              </option>
              {division.map(({ _id, areaCode }) => {
                return (
                  <option key={_id} value={_id}>
                    {areaCode}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="location">
            <span className="top-placeholder">Location*</span>

            <select
              className={`form-control ${
                locId === "" && border ? "empty-form" : ""
              }`}
              id="location"
              name="location"
              onChange={async (e) => {
                setLocId(e.target.value);
              }}
            >
              <option value="" selected>
                Select Location
              </option>
              {location.map(({ locationId, locationName }) => {
                return (
                  <option key={locationId} value={locationId}>
                    {locationName}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-3 mb-3" htmlFor="pickupTime">
            <input
              // className="form-control session-time"
              className={`form-control session-time ${
                formik.errors.pickupTime && formik.submitCount > 0
                  ? "empty-form"
                  : ""
              }`}
              id="pickupTime"
              name="pickupTime"
              type="time"
              // placeholder="Pickup Time"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.pickupTime}
              // onFocus={(e) => (e.target.type = "time")}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            {/* {formik.values.pickupTime && ( */}
            <span className="top-placeholder">Pickup Time*</span>
            {/* )} */}
          </label>
          <label className="col-md-3 mb-3" htmlFor="dropTime">
            <input
              className={`form-control session-time ${
                formik.errors.dropTime && formik.submitCount > 0
                  ? "empty-form"
                  : ""
              }`}
              id="dropTime"
              name="dropTime"
              type="time"
              // placeholder="Drop Time"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.dropTime}
              // onFocus={(e) => (e.target.type = "time")}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            <span className="top-placeholder">Drop Time*</span>
          </label>

          <div className="col-md-6 mb-3">
            <label htmlFor="active" className="custCheck">
              Active
              <input
                type="checkbox"
                id="active"
                name="active"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                checked={formik.values.active}
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                setBorder(true);
                if (pin === "") warnToast("Select Area");
                if (divId === "") warnToast("Select Division");
                if (locId === "") warnToast("Select Location");
                toastValidation(formik.errors);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                if (Number(param.get("fromEdit")) !== 1) {
                  nav("../..");
                } else {
                  const item = state;
                  nav(
                    {
                      pathname: "/routeMaster/editForm",
                      search: "?index=2",
                    },
                    { state: item }
                  );
                }
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default RouteLocationAddForm;
