import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect } from "react";
import { MemberFeesType } from "../../../types/memberFeesTypes";
import MemberFeesListFailedItems from "./ListItems";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";
import NotFound from "components/NotFound";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { successToast } from "constants/toastConfig";

type Props = {};

const MemberFeesList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const { state } = useLocation();
  const [param] = useSearchParams();
  const nav = useNavigate();
  const [memberFees, setMemberFees] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("memberName");
  const [searchCount, setSearchCount] = useState(0);
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [feesType, setFeesType] = useState("");
  //Creating Fees
  const createFees = async () => {
    try {
      const data = {
        feesConfigId: state._id,
      };
      setLoading(true);
      const res = await axiosInstance.post(
        "/master/addMemberFeesAssignment",
        data
      );
      if (res.data === "Ok") {
        successToast();
        setLoading(false);
        const item = state;
        if (Number(param.get("fromEdit")) === 1) {
          nav(
            {
              pathname: `/transportationFeesMain/feesSectionEdit`,
              search: `?index=4`,
            },
            { state: item }
          );
        }
        loadMemberList(1, "memberName", "");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  //List of fees
  const loadMemberList = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `/master/getAllMemberFeesAssignmentFailed?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}&configId=${state._id}`
        );
        console.log(
          res.data.result.allMemberFees.getMemberFeesXXX[0].paginatedResults
        );
        const newMemberFeesList: MemberFeesType[] = [
          ...res.data.result.allMemberFees.getMemberFeesXXX[0].paginatedResults,
        ];
        setFeesType(res.data.result.allMemberFees.type);
        if (newMemberFeesList.length === 0) {
          setNotFound(true);
          setMemberFees([]);
          setSearchCount(0);
        }
        if (newMemberFeesList.length > 0) {
          setSearchCount(
            res.data.result.allMemberFees.getMemberFeesXXX[0].totalCount[0]
              .count
          );
          if (pageNum === 1) {
            setMemberFees(newMemberFeesList);
          } else {
            setMemberFees((prevList) => [...prevList, ...newMemberFeesList]);
          }
          setNotFound(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
    [memberFees]
  );

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    loadMemberList(1, sort, search);
  };

  useEffect(() => {
    loadMemberList(page, sort, searchQuery);
  }, [page, sort, searchQuery]);
  window.onscroll = () => {
    infiniteScroll(memberFees, setPage, page, searchCount);
  };

  return (
    <>
      <div>
        <div className="formBg mb-3">
          <form
            action=""
            onSubmit={(e: any) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <div className="d-flex">
              <label className="flex-fill">
                <input
                  className="form-control  "
                  type="text"
                  placeholder="Member Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
              <div style={{ paddingRight: "15px" }}>
                <button className="btn btn-primary" type="submit">
                  Search
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => createFees()}
                >
                  Generate Fees
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="memberName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMemberFees, setPage);
              }}
            >
              Member Name
              <span className="px-2">
                {!ascDsc ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th>Division</th>
            {feesType === "km" && <th>Location</th>}
            {feesType === "vehicle" && <th>Vehicle No</th>}
            {feesType === "vehicle" && <th>Shift</th>}
            <th>Message</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {memberFees?.map((item) => {
              return (
                <MemberFeesListFailedItems
                  item={item}
                  key={Math.random()}
                  feesType={feesType}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default MemberFeesList;
