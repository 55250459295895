import React, { memo } from "react";
import { formatDate, formatTime } from "constants/Constants";
import { MemberWaitListTypes } from "types/basicMemberWaitListTypes";

type Props = {
  x: MemberWaitListTypes;
};

const BasicMemberWaitListItem = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td className="pb-4">{x.memberName}</td>
      <td className="pb-4">{x.memberContact}</td>
      <td className="pb-4">{x.memberPickUpLocation}</td>
      <td className="pb-4">{formatDate(x.createdAt)}</td>
      <td className="pb-4">{formatTime(x.createdAt)}</td>
    </tr>
  );
};

export default memo(BasicMemberWaitListItem);
