import React, { memo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
type Props = {};

const Dashboard: React.FC<{}> = (props: Props) => {
  const location = useLocation();

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>
          {location.pathname === "/dashboard/SosList" ? "SosList" : "Dashboard"}
        </span>
        {location.pathname !== "/dashboard" && (
          <Link className="d-flex justify-content-end" to="/dashboard">
            <button className="btn btn-primary">Back</button>
          </Link>
        )}

        {location.pathname === "/dashboard"}
      </div>
      <Outlet />
    </>
  );
};
export default memo(Dashboard);
