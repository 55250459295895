import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { ProfileType, UpcomingPlanType } from "types/profileTypes";
import { useLoading } from "Context/LoadingContext";
import {
  currencySymbol,
  dateFormatForAdmin,
  formatDate,
} from "constants/Constants";
import verifiedImg from "../../assets/images/verified.svg";
import circleDone from "../../assets/images/circleDone.svg";
import ProfileMap from "./ProfileMap";
import { orgImage } from "constants/Constants";
import { useNavigate } from "react-router-dom";
import CancelBtn from "components/Buttons/Cancel";
import DangerIcon from "../../assets/images/exclamation-triangle.svg";

type Props = {};

const ProfileList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [profile, setProfile] = useState<ProfileType[]>([]);
  const [pastPlan, setPastPlan] = useState<UpcomingPlanType[]>([]);
  const [upcomingplan, setUpcomingplan] = useState<UpcomingPlanType[]>([]);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const nav = useNavigate();
  const ProfileData = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axiosInstance.get(`/master/getProfile`);
      setButtonDisable(res.data.ButtonDisable);
      setUpcomingplan(res.data.upcominplan);
      const profData: ProfileType[] = [...profile, ...res.data.Profile];
      setProfile(profData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [profile]);
  const PastPlan = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/master/getPastPlan`);
      setPastPlan(res?.data?.Plans);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    ProfileData();
    PastPlan();
  }, []);
  const isBefore7DaysFromNow = (dateString: string) => {
    const date = new Date(dateString);
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() + 7);
    return date < sevenDaysFromNow;
  };
  return (
    <>
      <div className="row">
        {profile.map((profile, index) => (
          <React.Fragment key={index}>
            <div className="row">
              <div className="col-md-6">
                {profile?.planType === undefined && (
                  <>
                    <div className="main d-flex align-items-center justify-content-space-around">
                      <h6 className="text-danger shadow-sm">
                        Don't miss out! Your plan has expired. Renew for
                        uninterrupted benefits.
                      </h6>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="formBg" key={index}>
                <div className="profilePic mt-1">
                  <div className="profilePicInner">
                    <img
                      src={`${orgImage}${
                        profile?.photo !== "" ? profile.photo : "Default.png"
                      }`}
                      alt="organizer"
                    />
                    {profile.planType !== "Basic" &&
                      profile?.planType !== undefined && (
                        <span
                          className="editPhoto pointer"
                          onClick={() => {
                            nav("profileEdit", {
                              state: profile,
                            });
                          }}
                        >
                          <img src="img/edit-ico.svg" alt="edit icon" />
                        </span>
                      )}
                  </div>
                </div>
                <div className="profileCont">
                  <div className="profileName">
                    {profile.name}
                    <span>
                      <img src={verifiedImg} alt="verified" />
                    </span>
                  </div>
                  <div className="profilePhone">+91 {profile.contactNo}</div>
                  <div className="profileEmail">{profile.email}</div>
                </div>
                <ul className="profileList">
                  <li>
                    <span>Business Name :</span>
                    {profile.businessName}
                  </li>
                  <li>
                    <span>Business Type :</span>
                    {profile.businessType}
                  </li>
                  {profile.planName && (
                    <>
                      <li>
                        <span>Plan Name :</span>
                        {profile.planName}
                      </li>
                      <li>
                        <span>Plan Type :</span>
                        {profile.planType}
                      </li>
                      <li>
                        <span>Plan Amount :</span>
                        {currencySymbol} {profile.amount}
                      </li>
                    </>
                  )}

                  {profile.planType !== "Basic" &&
                    profile.isMultiShift === true && (
                      <li>
                        <span>
                          <b>Multi Shifts :</b>{" "}
                          <img src={circleDone} alt="verified" />
                        </span>
                      </li>
                    )}
                  {profile.activeDate && (
                    <>
                      <li>
                        <span>Plan Activation Date :</span>
                        {formatDate(profile.activeDate)}
                      </li>
                      {profile.planType !== "Basic" &&
                        profile.rfidCardCheck === true && (
                          <li>
                            <span>
                              <b>RFID card:</b>{" "}
                              <img src={circleDone} alt="verified" />
                            </span>
                          </li>
                        )}
                      <li>
                        <span>Plan End Date :</span>
                        {formatDate(profile.endDate)}
                      </li>
                    </>
                  )}

                  {profile.paymentCheck === true && (
                    <li>
                      <span>
                        <b>Payment:</b> <img src={circleDone} alt="verified" />
                      </span>
                    </li>
                  )}
                  <li>
                    {isBefore7DaysFromNow(profile?.endDate || "2022-12-10") &&
                    !buttonDisable ? (
                      <CancelBtn btnTxt="Renew" path="/RenewUi" />
                    ) : (
                      <CancelBtn btnTxt="Renew" path="" disabled />
                    )}
                  </li>
                </ul>
              </div>
              {profile.promocode !== null && (
                <div className="formBg">
                  <ul className="profileList">
                    <li>
                      <span>Promo Code:</span>
                      {profile.promocode}
                    </li>
                    <li>
                      <span></span>
                    </li>
                    {profile.promocodeUseBy &&
                      profile.promocodeUseBy[0] !== null && (
                        <React.Fragment>
                          <li style={{ width: "100%" }}>
                            <span>Promo Used:</span>
                            <br />
                            {profile.promocodeUseBy.map((item) => {
                              const [name, dateString] = item.split(", ");
                              const formattedDate = formatDate(dateString);
                              return (
                                <React.Fragment key={item}>
                                  {name}, {formattedDate}
                                  <br />
                                </React.Fragment>
                              );
                            })}
                          </li>
                        </React.Fragment>
                      )}
                    {upcomingplan.length > 0 && (
                      <React.Fragment>
                        <li style={{ width: "100%" }}>
                          <span>Upcoming Plan:</span>
                          {upcomingplan[0].plan} From{" "}
                          {dateFormatForAdmin(upcomingplan[0].startDate)} to{" "}
                          {dateFormatForAdmin(upcomingplan[0].endDate)}
                        </li>
                      </React.Fragment>
                    )}
                  </ul>
                </div>
              )}
              {pastPlan.length > 0 && (
                <div className="container profileList">
                  Your Past Plan
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Plan</th>
                        <th scope="col">Start</th>
                        <th scope="col">End</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Payment Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pastPlan.map((x) => (
                        <tr key={x._id}>
                          <td>{x.plan}</td>
                          <td>{dateFormatForAdmin(x.startDate)}</td>
                          <td>{dateFormatForAdmin(x.endDate)}</td>
                          <td>
                            {currencySymbol}
                            {x.amount}
                          </td>
                          <td>{formatDate(x.createdAt)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <ProfileMap
                orgId={profile._id}
                address={profile.address}
                lat={profile.latitude}
                lng={profile.longitude}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default memo(ProfileList);
