import React from "react";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import {
  currencySymbol,
  formatDate,
  formatDateToAdd,
} from "constants/Constants";

type Props = {
  item: any;
};

const MemberListItems = ({ item }: Props) => {
  const nav = useNavigate();
  return (
    <tr key={Math.random()}>
      <td>{item.memberName}</td>
      <td>
        {currencySymbol}
        {item.totalPendingFees.toFixed(2)}
      </td>
      <td>
        {currencySymbol}
        {item.totalPaidFees.toFixed(2)}
      </td>
      <td>{formatDate(item.createdAt)}</td>
      {/* <td className="text-center pointer">
        <Edit
          id={item.memberId}
          onClick={() => {
            nav("editForm", {
              state: { memId: item.memberId },
            });
          }}
        />
      </td> */}
    </tr>
  );
};

export default MemberListItems;
