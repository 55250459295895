import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import { useLoading } from "Context/LoadingContext";
import { useCallback, useState, useEffect, memo } from "react";
import RouteMemberListItem from "./ListItem";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import RouteMemberListType from "types/RouteMemberList";
import { DepartmentDDLType } from "types/departmentMasterType";
import { CategoryDDL } from "types/categoryTypes";
import { TypeDDL } from "types/typeMasterTypes";
import { ShiftDDL } from "types/shiftType.types";
import { useNotFound } from "Context/NotFound";
import NotFound from "components/NotFound";

type Props = {};

const RouteMemberList = (props: Props): JSX.Element => {
  const [routeMember, setRouteMember] = useState<RouteMemberListType[]>([]);

  const { setLoading } = useLoading();

  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("shift");
  const [searchCount, setSearchCount] = useState(0);

  const [shiftTypeId, setShiftTypeId] = useState("");
  const [shiftData, setShiftData] = useState<ShiftDDL[]>();
  const [departmentId, setDepartmentId] = useState("");
  const [depData, setDepData] = useState<DepartmentDDLType[]>();
  const [categoryId, setCategoryId] = useState("");
  const [catData, setCatData] = useState<CategoryDDL[]>();
  const [typeId, setTypeId] = useState("");
  const [typeData, setTypeData] = useState<TypeDDL[]>();
  const [shiftCount, setShiftCount] = useState<boolean>();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { notfound, setNotFound } = useNotFound();

  const loadRouteMember = useCallback(
    async (
      pageNum: number,
      sortParam: string,
      shift: string,
      dep: string,
      cat: string,
      type: string,
      searchKeyword: string
    ) => {
      try {
        setLoading(true);
        let res: any;
        if (shift !== "" && shiftCount) {
          res = await axiosInstance.get(
            `/master/getAllRouteMember?page=${pageNum}&sort=${sortParam}&shiftTypeId=${shiftTypeId}&departmentId=${dep}&categoryId=${cat}&typeId=${type}&search=${searchKeyword}`
          );
        } else {
          res = await axiosInstance.get(
            `/master/getAllRouteMember?page=${pageNum}&sort=${sortParam}&shiftTypeId=${shift}&departmentId=${dep}&categoryId=${cat}&typeId=${type}&search=${searchKeyword}`
          );
        }
        const newlist: RouteMemberListType[] = [
          ...res.data.result[0].paginatedResults,
        ];
        if (searchKeyword !== "" && pageNum === 1) {
          if (newlist.length === 0) {
            setRouteMember([]);
            setSearchCount(0);
          }
        }
        if (newlist.length > 0) {
          setSearchCount(res.data.result[0].totalCount[0].count);
          if (pageNum === 1) {
            setRouteMember(newlist);
          } else {
            setRouteMember((prevList) => [...prevList, ...newlist]);
          }
        }
        setSearchCount(res.data.searchCount);
        setLoading(false);
        setNotFound(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [routeMember]
  );

  const loadShift = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropdown/getShiftType`);
      const shiftCount = res.data.shiftTypes.length === 1;
      setShiftCount(shiftCount);
      // if (shiftCount) {
      //   setShiftTypeId(res.data.shiftTypes[0]._id);
      // }
      setShiftData(res.data.shiftTypes);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const departmentList = res.data.Departments;
      setDepData(departmentList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadCategories = useCallback(async (depId: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getCategory?depId=${depId}`
      );
      const categories = res.data.categories as CategoryDDL[];
      setLoading(false);
      return categories;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadTypes = useCallback(async (catId: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getType?catId=${catId}`);
      const typeList = res.data.types;
      setLoading(false);
      return typeList;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);
  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadRouteMember(
      1,
      sort,
      shiftTypeId,
      departmentId,
      categoryId,
      typeId,
      searchQuery
    ); // Trigger API call with updated search keyword
  };
  useEffect(() => {
    loadShift();
    loadDepartments();
  }, []);
  useEffect(() => {
    loadRouteMember(
      page,
      sort,
      shiftTypeId,
      departmentId,
      categoryId,
      typeId,
      searchQuery
    );
  }, [page, sort, shiftTypeId, departmentId, categoryId, typeId, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(routeMember, setPage, page, searchCount);
  };
  return (
    <>
      <label className="searchBar">
        <input
          className="searchInput"
          type="text"
          placeholder="Search Member or pickLocation or PickUp Vehicle"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleSearch}>
          Search
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            setSearch("");
            setSearchQuery("");
          }}
        >
          Clear
        </button>
      </label>
      <div className="formBg mb-3">
        <div className="mb-2 row align-items-center">
          <label className="col-md-6 mb-3" htmlFor="shift">
            <select
              className="form-control"
              id="shift"
              name="shift"
              onChange={async (e) => {
                setShiftTypeId(e.target.value);
                setRouteMember([]);
              }}
            >
              <option value="">Select Shift</option>
              {shiftData?.map((item) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </label>
          <label htmlFor="department" className="col-md-6 mb-3">
            <select
              className="form-control"
              id="department"
              onChange={async (e) => {
                setDepartmentId(e.target.value);
                setRouteMember([]);
                setTypeData([]);
                if (e.target.value !== "") {
                  const res = await loadCategories(e.target.value);
                  if (res !== undefined) setCatData(res);
                  else setCatData([]);
                }
                //   depDropDown(e.target.value);
              }}
            >
              <option value="">Select Department</option>
              {depData?.map(({ _id, departmentName }) => {
                return (
                  <option key={_id} value={_id}>
                    {departmentName}
                  </option>
                );
              })}
            </select>
          </label>
          <label htmlFor="category" className="col-md-6 mb-3">
            <select
              className="form-control"
              id="category"
              onChange={async (e) => {
                setCategoryId(e.target.value);
                setRouteMember([]);
                if (e.target.value !== "") {
                  const res = await loadTypes(e.target.value);
                  if (res !== undefined) setTypeData(res);
                  else setTypeData([]);
                }
              }}
            >
              <option value="">Select Category</option>
              {catData?.length !== 0 &&
                catData?.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  );
                })}
            </select>
          </label>
          <label htmlFor="type" className="col-md-6 mb-3">
            <select
              className="form-control"
              id="type"
              onChange={(e) => {
                setTypeId(e.target.value);
                setRouteMember([]);
              }}
            >
              <option value="">Select Sub Category</option>
              {typeData?.length !== 0 &&
                typeData?.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  );
                })}
            </select>
          </label>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="shift"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteMember,
                  setPage
                );
              }}
            >
              Shift
              {sort === "shift" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="depName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteMember,
                  setPage
                );
              }}
            >
              Department
              {sort === "depName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="memName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteMember,
                  setPage
                );
              }}
            >
              Member
              {sort === "memName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="pickRoute"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteMember,
                  setPage
                );
              }}
            >
              PickUp Route
              {sort === "pickRoute" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="pickLocation"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteMember,
                  setPage
                );
              }}
            >
              PickUp Location
              {sort === "pickLocation" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th>PickUp Vehicle</th>
            <th>PickUp Seat</th>

            <th
              id="dropRoute"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteMember,
                  setPage
                );
              }}
            >
              Drop Route
              {sort === "dropRoute" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="dropLocation"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setRouteMember,
                  setPage
                );
              }}
            >
              Drop Location
              {sort === "dropLocation" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th>Drop Vehicle</th>
            <th>Drop Seat</th>

            <th className="text-center">IsSame</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {routeMember.map((item) => {
              return <RouteMemberListItem x={item} key={item._id} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(RouteMemberList);
