import React, { useEffect, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import ErrorToast from "components/ValidationToast/errorToast";
import InfoToast from "components/ValidationToast/infoToast";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DivisionList from "../AreaDivision/DivisionList";
import LocationMasterList from "../locationMaster/locationMasterList";
import { useAreaEdit } from "Context/AreaEditContext";
import AddSearch from "components/AddSearchComponent";
import CancelBtn from "components/Buttons/Cancel";
import SubmitBtn from "components/Buttons/Submit";

type Props = {};

const AreaEditForm = (props: Props) => {
  const { area, setArea, editIndex, setEditIndex } = useAreaEdit();
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  useEffect(() => {
    setSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  const getOneArea = async () => {
    setLoading(true);
    try {
      // const res = await axiosInstance.get(
      //   `/master/getOneArea/${state.item._id}`
      // );
      setArea(state.item);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOneArea();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      areaName: area?.areaName,
      pinCode: area?.pinCode,
      active: area?.isActive,
    },
    validationSchema: Yup.object().shape({
      areaName: Yup.string().min(1).max(30).required("Area Name is Required"),
      pinCode: Yup.string().min(6).max(6).required("PIN Code is Required"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({ areaName, pinCode, active }) => {
      setLoading(true);
      try {
        const formData = {
          areaName: areaName,
          pinCode: String(pinCode),
          isActive: active,
        };
        const res = await axiosInstance.put(
          `/master/updateArea/${state.item._id}`,
          formData
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          setLoading(false);
          nav("/areaMaster");
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      {location.pathname !== "/areaMaster" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/areaMaster" />
        </div>
      )}
      <Tabs
        selectedIndex={editIndex ?? 0}
        onSelect={(index) => {
          setEditIndex(index);
          nav("/areaMaster/editForm");
        }}
      >
        <TabList>
          <Tab>Area</Tab>
          <Tab>Division</Tab>
          <Tab>Location</Tab>
        </TabList>
        <TabPanel>
          <div className="formBg">
            <form onSubmit={formik.handleSubmit}>
              <div className="row align-items-center">
                <label className="col-md-6 mb-3" htmlFor="areaName">
                  <span className="top-placeholder">Area Name*</span>

                  <input
                    className={`form-control ${
                      formik.errors.areaName && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="areaName"
                    name="areaName"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.areaName}
                    placeholder="Area Name"
                  />
                </label>
                <label className="col-md-6 mb-3" htmlFor="pinCode">
                  <span className="top-placeholder">PIN Code*</span>

                  <input
                    className={`form-control ${
                      formik.errors.pinCode && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="pinCode"
                    name="pinCode"
                    type="number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.pinCode}
                    placeholder="PIN Code"
                  />
                </label>
                <div className="col-md-3 mb-3">
                  <label className="custCheck" htmlFor="active">
                    Active
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      checked={formik.values.active}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3 text-center">
                <SubmitBtn errors={formik.errors} />
                <CancelBtn path=".." />
              </div>
            </form>
          </div>
        </TabPanel>

        <TabPanel>
          <>
            {(location.pathname === "/areaMaster/editForm" ||
              location.pathname === "/areaMaster/editForm/locEdit") && (
              <Link
                to={"/areaMaster/addForm"}
                state={{ index: 1 }}
                style={{ float: "right" }}
              >
                <button className="btn btn-primary" type="button">
                  Add
                </button>
              </Link>
            )}
            {location.pathname === "/areaMaster/editForm/divEdit" ? (
              <Outlet />
            ) : (
              <DivisionList />
            )}
          </>
        </TabPanel>
        <TabPanel>
          <>
            {(location.pathname === "/areaMaster/editForm" ||
              location.pathname === "/areaMaster/editForm/divEdit") && (
              <Link
                to={"/areaMaster/addForm"}
                state={{ index: 2 }}
                style={{ float: "right" }}
              >
                <button className="btn btn-primary" type="button">
                  Add
                </button>
              </Link>
            )}
            {location.pathname === "/areaMaster/editForm/locEdit" ? (
              <Outlet />
            ) : (
              <LocationMasterList />
            )}
          </>
          {/* <LocationMasterList /> */}
        </TabPanel>
      </Tabs>
    </>
  );
};

export default memo(AreaEditForm);
