import React, { useState, memo } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";

type Props = {};
type ContextType = {
  setAdminMessageId: (adminMessageId: string) => void;
  adminMessageId: string;
};

const AdminMessage: React.FC<{}> = (props: Props) => {
  const location = useLocation();
  const [adminMessageId, setAdminMessageId] = useState<string>("");
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Message</span>
        {location.pathname === "/adminMessage"}
      </div>
      <Outlet context={{ adminMessageId, setAdminMessageId }} />
    </>
  );
};

export default memo(AdminMessage);

export function useAdminMessageId() {
  return useOutletContext<ContextType>();
}
