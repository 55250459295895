import toastValidation from "constants/toastConfig";
import React from "react";

type Props = {
  errors: any;
  clickFunc?: (e?: any) => void;
  btnText?: string;
  type?: "button" | "reset" | "submit" | undefined;
  ref?: any;
};

const SubmitBtn = ({ errors, clickFunc, btnText, type, ref }: Props) => {
  return (
    <button
      type={type ? type : "submit"}
      onClick={clickFunc ? clickFunc : () => {
        toastValidation(errors);
      }}
      className="btn btn-primary inline-block"
      ref={ref ? ref : null}
    >
      {btnText ?? "Submit"}
    </button>
  );
};

export default SubmitBtn;
