import React, { memo } from "react";
import { Outlet, useLocation } from "react-router-dom";
type Props = {};

const Errors: React.FC<{}> = (props: Props) => {
  const location = useLocation();

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Error Log From Member </span>
        {location.pathname === "/errorLogsForMember"}
      </div>
      <Outlet />
    </>
  );
};
export default memo(Errors);
