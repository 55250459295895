import { convertTo12HourFormat, memImage } from "constants/Constants";
import { memo } from "react";
import { memberDetailsType } from "types/memberDetailTypes";

type Props = {
  x: memberDetailsType;
};

const MemberDetailsListItem = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td>
        <i className="userImg">
          <img
            src={`${memImage}${x.photo !== "" ? x.photo : "Default.png"}`}
            alt="MemberPic"
          />
        </i>
      </td>
      <td>{x.memberName}</td>
      <td>{x.memberId}</td>
      <td>{x.driverName}</td>
      <td>{x.routeName}</td>
      <td>{x.shift}</td>
      <td>{x.area}</td>
      <td>{x.location}</td>
      <td>{convertTo12HourFormat(x.pickUpTime)}</td>
      <td>{convertTo12HourFormat(x.dropTime)}</td>
      <td>{x.time}</td>
    </tr>
  );
};

export default memo(MemberDetailsListItem);
