import axiosInstance from "axiosInstance";
import { convertTo12HourFormat, memImage } from "constants/Constants";
import { memo, useState } from "react";
import { memberDetailsType } from "types/memberDetailTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { useLoading } from "Context/LoadingContext";
import { successToast } from "constants/toastConfig";

type Props = {
  x: memberDetailsType;
  loadMember: (
    pageNum: number,
    sortParam: string,
    searchKeyword: string
  ) => void;
};

const MemberDetailsListItem = ({ x, loadMember }: Props): JSX.Element => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { setLoading } = useLoading();
  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Create</Tooltip>;
  const { handleBlur, handleChange, handleSubmit, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      remark: "",
      guestPerson: "",
    },
    onSubmit: async ({ remark, guestPerson }) => {
      try {
        setLoading(true);
        const formData = {
          remark,
          guestPerson,
          memberId: x._id,
          driverId: x.driverId,
          routeId: x.routeId,
          areaId: x.areaId,
          locationId: x.locationId,
          routelocationId: x.routelocationId,
          shiftId: x.shiftId,
        };
        const res = await axiosInstance.post(
          `/master/addMemberGetPass`,
          formData
        );
        if (res.data?.message === "Ok") {
          successToast();
          setShow(false);
          loadMember(1, "memberName", "");
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
  });
  let userTypeImage = x.photo
    ? `${memImage}${x.photo}`
    : `${memImage}Default.png`;
  return (
    <tr key={x._id}>
      <td>
        <i className="userImg">
          <img src={userTypeImage} alt="MemberPic" />
        </i>
      </td>
      <td>{x.memberName}</td>
      <td>{x.memberId}</td>
      <td>{x.driverName}</td>
      <td>{x.routeName}</td>
      <td>{x.shift}</td>
      <td>{x.area}</td>
      <td>{x.location}</td>
      <td>{convertTo12HourFormat(x.pickUpTime)}</td>
      <td>{convertTo12HourFormat(x.dropTime)}</td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit onClick={() => setShow(true)} />
        </OverlayTrigger>
      </td>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>Get Pass Details</Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <p>
              <strong>Member :</strong> {x.memberName}
            </p>
            <label className="col-md-12 mb-3" htmlFor="plan">
              <span className="top-placeholder">Guest Person</span>
              <input
                className={`form-control`}
                id="guestPerson"
                name="guestPerson"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.guestPerson}
                placeholder="Guest Person"
              />
            </label>
            <textarea
              className="form-control"
              id="remark"
              name="remark"
              placeholder="Remark"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.remark}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Submit
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </tr>
  );
};

export default memo(MemberDetailsListItem);
