import { memo, SetStateAction } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLoading } from "Context/LoadingContext";
import { AreaDDLType } from "types/areaMasterTypes";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { useAreaEdit } from "Context/AreaEditContext";
import { useNavigate } from "react-router-dom";

type Props = {
  setSelectedIndex: React.Dispatch<SetStateAction<number>>;
  editIndex?: number | undefined;
};

const DivisionAddForm = ({ setSelectedIndex, editIndex }: Props) => {
  const { area, setDivision } = useAreaEdit();

  const nav = useNavigate();
  const { setLoading } = useLoading();
  const { setEditIndex } = useAreaEdit();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      areaCode: "",
      active: true,
    },
    validationSchema: Yup.object().shape({
      areaCode: Yup.string()
        .min(1, "Division is too short")
        .max(30, "Division Name is too long")
        .required("Division Name is Required"),
    }),
    onSubmit: async ({ areaCode, active }) => {
      try {
        setLoading(true);
        const formData = {
          areaId: area?._id,
          areaCode: areaCode,
          pinCode: area?.pinCode,
          description: "",
          note: "",
          details: "",
          isActive: active,
        };
        const res = await axiosInstance.post(
          "/master/addAreaDivision",
          formData
        );
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          setDivision(res.data.division);
          if (editIndex) {
            nav("/areaMaster/editForm", { state: { item: area } });
            setEditIndex(editIndex);
          } else setSelectedIndex(editIndex ?? 2);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <div className="formBg">
      <form className="row align-items-center" onSubmit={formik.handleSubmit}>
        <label className="col-md-6 mb-3" htmlFor="areaCode">
          <span className="top-placeholder">Division*</span>

          <input
            className={`form-control ${
              formik.errors.areaCode && formik.submitCount > 0
                ? "empty-form"
                : ""
            }`}
            id="areaCode"
            name="areaCode"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.areaCode}
            placeholder="Division"
          />
        </label>
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn errors={formik.errors} />
          <CancelBtn
            path={editIndex ? "/areaMaster/editForm" : ".."}
            state={editIndex ? { item: area } : null}
          />
        </div>
      </form>
    </div>
  );
};

export default memo(DivisionAddForm);
