import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
const containerStyle = {
  width: "100%",
  height: "100%",
  // margin-bottom: "20px",
  // display:"flex",
  // align-items:"center"
};

type Props = {
  form: any;
  latX?: number;
  lngX?: number;
};

const MapForm = ({ form, latX, lngX }: Props) => {
  const location = useLocation();
  const center: google.maps.LatLng | google.maps.LatLngLiteral | undefined = {
    lat: 37.7749,
    lng: -122.4194,
  };
  const centerX: google.maps.LatLng | google.maps.LatLngLiteral | undefined = {
    lat: latX as number,
    lng: lngX as number,
  };
  const MY_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const [coordinates, setCoordinates] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >(location.pathname === "/locationMaster/editForm" ? centerX : center);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${MY_API_KEY}`,
  });

  const handleSearch = useCallback(
    async (loc: string) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            loc
          )}&key=${MY_API_KEY}`
        );
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          form.setFieldValue("latitude", lat.toString());
          form.setFieldValue("longitude", lng.toString());
          setCoordinates({ lat, lng });
        }
      } catch (error) {
        console.error("Error geocoding location:", error);
      }
    },
    [form.values.searchLocation]
  );

  useEffect(() => {
    handleSearch(form.values.searchLocation);
  }, [form.values.searchLocation]);

  // useEffect(() => {
  //   if (form.values.longitude !== "" && form.values.latitude !== "") {
  //     const lngX = Number(form.values.longitude);
  //     const latX = Number(form.values.latitude);
  //     setCoordinates({ lat: latX, lng: lngX });
  //   }
  // }, []);

  const handleMarkerDragEnd = async (e: any) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    form.setFieldValue("latitude", lat.toString());
    form.setFieldValue("longitude", lng.toString());
    setCoordinates({ lat, lng });
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MY_API_KEY}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const address = data.results[0].formatted_address;
        console.log(address);
        form.setFieldValue("address1", data.results[0].formatted_address);
      }
    } catch (error) {
      console.error("Error reverse geocoding:", error);
    }
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <div>
      {isLoaded ? (
        <div style={{ width: "100%", height: "80vh", marginBottom: "20px" }}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={coordinates}
            zoom={15}
          >
            {form.values.longitude !== "" && form.values.latitude !== "" && (
              <Marker
                position={coordinates}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
          </GoogleMap>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default MapForm;
