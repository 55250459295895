import React, { memo } from "react";
import { AdminClientDataType } from "types/adminClientTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Detail } from "assets/images/details-19.svg";
import { ReactComponent as View } from "assets/images/view-16.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { dateFormatForAdmin, formatDate } from "constants/Constants";
type Props = {
  x: AdminClientDataType;
};

const AdminClientListItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();
  const DetailsToolTip = <Tooltip id={`tooltip-${x._id}`}>Details</Tooltip>;
  const ViewToolTip = <Tooltip id={`tooltip-${x._id}`}>View</Tooltip>;
  const EditToolTip = <Tooltip id={`tooltip-${x._id}`}>Edit </Tooltip>;
  return (
    <tr key={x._id}>
      <td>{x.name}</td>
      <td>{x.email}</td>
      <td>{x.contactNo}</td>
      <td>{x.bussinessName}</td>
      <td>{x.planName}</td>
      <td>{x.paymentAmount}</td>
      <td> {formatDate(x.paymentDate)}</td>
      <td> {dateFormatForAdmin(x.planExpired)}</td>
      <td className="text-center">
        {x.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td>
        <div className="d-flex  align-items-center justify-content-between">
          <div className="tPaddings">
            <OverlayTrigger placement="right" overlay={DetailsToolTip}>
              <Detail
                className="pointer Approved_Size"
                id={x._id}
                onClick={() => {
                  nav("clientDetails", {
                    state: x,
                  });
                }}
              />
            </OverlayTrigger>
          </div>
          <div className="tPaddings">
            <OverlayTrigger placement="right" overlay={ViewToolTip}>
              <View
                className="pointer Approved_Size"
                id={x._id}
                onClick={() => {
                  nav("configurationDetails", {
                    state: x,
                  });
                }}
              />
            </OverlayTrigger>
          </div>
          <div className="tPaddings">
            <OverlayTrigger placement="right" overlay={EditToolTip}>
              <Edit
                className="pointer"
                id={x._id}
                onClick={() => {
                  nav("clientEdit", {
                    state: x,
                  });
                }}
              />
            </OverlayTrigger>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default memo(AdminClientListItem);
