import { ConfigProvider } from "Context/ConfigContext";
import React, { memo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

type Props = {};

const BasicLocation: React.FC<{}> = (props: Props) => {
  const location = useLocation();
  return (
    <>
      <ConfigProvider>
        <div className="pageTitle justify-content-between align-items-center d-flex">
          <span>Location Master</span>
          {location.pathname === "/basiclocationmaster" && (
            <Link to={"/basiclocationmaster/add"}>
              <button className="btn btn-primary" type="submit">
                Add
              </button>
            </Link>
          )}
        </div>
        <Outlet />
      </ConfigProvider>
    </>
  );
};

export default memo(BasicLocation);
