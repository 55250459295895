import { memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { successToast, warnToast } from "constants/toastConfig";

type Props = {};

const PlnConfigurationEdit = (props: Props) => {
  const location = useLocation();
  const { state } = useLocation();
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const formik = useFormik({
    initialValues: {
      value: state.value,
    },
    validationSchema: Yup.object().shape({
      value: Yup.string()
        .required("Value is Required")
        .max(6, "Value is Too Long!"),
    }),
    onSubmit: () => {
      setShow(true);
    },
  });
  const submit = async () => {
    try {
      const formData = {
        value: formik.values.value,
      };
      const res = await axiosInstance.put(
        `/master/updateConfig/${state._id}`,
        formData
      );
      successToast();
      const responseMessage = res.data;
      if (responseMessage === "Ok") {
        setLoading(false);
        nav("/planRequests");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="key">
              <span className="form-control sp_1">{state.key}</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="value">
              {state.value === "true" || state.value === "false" ? (
                <label className="custCheck" htmlFor="value">
                  Active Driver Account
                  <input
                    type="checkbox"
                    id="value"
                    name="value"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "value",
                        e.target.checked ? "true" : "false"
                      )
                    }
                    checked={formik.values.value === "true" ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>
              ) : (
                <input
                  className={`form-control email-control ${
                    formik.values.value === "" ? "empty-form" : ""
                  }`}
                  id="value"
                  name="value"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.value}
                  placeholder="Enter the value"
                />
              )}
            </label>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() =>
                formik.errors.value && warnToast("Enter valid value")
              }
            >
              Submit
            </button>
            {location.pathname ===
              "/planRequests/configurationDetails/plnConfigurationEdit" && (
              <Link to={"/planRequests"}>
                <button className="btn btn-primary" type="submit">
                  Cancel
                </button>
              </Link>
            )}
          </div>
        </form>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Are You Sure You want to change?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              submit();
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(PlnConfigurationEdit);
