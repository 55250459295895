import { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MemberAddForm from "./MemberAddForm";
import { useConfig } from "Context/ConfigContext";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const TabsComponent = (props: Props) => {
  const [index, setIndex] = useState(0);
  const { rfidCardCheck } = useConfig();
  const location = useLocation();

  const pathname = location.pathname;
  const [param] = useSearchParams();
  const isEdit = param.has("edit");
  const nav = useNavigate();
  const segments = pathname.split("/");
  const segment = segments[segments.length - 1];
  useEffect(() => {
    if (isEdit) setIndex(Number(param.get("edit")));
  }, [isEdit]);

  return (
    <>
      {pathname !== "/memberMaster" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/memberMaster" />
        </div>
      )}
      <Tabs
        disableUpDownKeys
        selectedIndex={
          isEdit
            ? index
            : (segment === "assignmentAdd" && 1) ||
              (segment === "routeAdd" && 2) ||
              (segment === "cardAdd" && 3) ||
              0
        }
        onSelect={(i) => {
          setIndex(i);
          let state = null;
          state = location.state;

          if (isEdit) {
            nav(`/memberMaster/editForm?index=${i}`, { state: { ...state } });
          }
        }}
      >
        <TabList>
          <Tab disabled={pathname !== "/memberMaster/addForm" && !isEdit}>
            Member
          </Tab>
          <Tab disabled={segment !== "assignmentAdd"}>Pickup Assignment</Tab>
          <Tab disabled={segment !== "routeAdd"}>Route Assignment</Tab>
          {rfidCardCheck && (
            <Tab disabled={segment !== "cardAdd"}>RFID Card</Tab>
          )}
        </TabList>

        <TabPanel>
          <>
            <MemberAddForm />
          </>
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        {rfidCardCheck && (
          <TabPanel>
            <Outlet />
          </TabPanel>
        )}
      </Tabs>
    </>
  );
};

export default TabsComponent;
