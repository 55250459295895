import React, { useCallback, useState, useEffect } from "react";
import axiosInstance from "axiosInstance";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLoading } from "Context/LoadingContext";
import { useNavigate } from "react-router-dom";
import { CategoryDDL } from "../../../types/categoryTypes";
import { TypeDDL } from "../../../types/typeMasterTypes";
import { DepartmentDDLType } from "types/departmentMasterType";
import { AreaDDLType } from "types/areaMasterTypes";
import SubmitBtn from "components/Buttons/Submit";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const MemberAddForm = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const nav = useNavigate();

  const [department, setDepartment] = useState<DepartmentDDLType[]>([]);
  const [depId, setDepId] = useState("");

  const [category, setCategory] = useState<CategoryDDL[]>([]);
  const [catId, setCatId] = useState("");

  const [type, setType] = useState<TypeDDL[]>([]);
  const [typeId, setTypeId] = useState("");

  const [area, setArea] = useState<AreaDDLType[]>([]);
  const [areaName, setAreaName] = useState("");
  const [pin, setPin] = useState("");

  const [border, setBorder] = useState<boolean>(false);

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const departmentList = res.data.Departments;
      setDepartment(departmentList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadCategories = useCallback(async (depId: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getCategory?depId=${depId}`
      );
      const categories = res.data.categories as CategoryDDL[];
      setLoading(false);
      return categories;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadTypes = useCallback(async (catId: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getType?catId=${catId}`);
      const typeList = res.data.types;
      setLoading(false);
      return typeList;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadAreas = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getArea`);
      setArea(res.data.Areas);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadDepartments();
    loadAreas();
  }, [loadDepartments, loadAreas]);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      memberphoto: "",
      memberId: "",
      dob: "",
      contactNo: "",
      emergencyNo: "",
      email: "",
      address: "",
      bloodGroup: "",
      note: "",
      details: "",
      active: true,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Name is Too Short!")
        .max(30, "Name is Too Long!")
        .required("Member Name is Required"),
      memberId: Yup.string().min(1).max(30).required("Member ID is Required"),
      dob: Yup.string().required("Date Of Birth is Required"),
      contactNo: Yup.string()
        .min(10, "Contact Number is not valid")
        .max(10, "Contact Number is not valid")
        .required("Contact Number is Required"),
      emergencyNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Please enter a valid Emergency Number")
        .min(10, "Emergency Number is not valid")
        .max(10, "Emergency Number is too long"),
      email: Yup.string().email().max(50, "Email is too long"),
      bloodGroup: Yup.string().matches(
        /^(A|B|AB|O)[-+]$/,
        "Blood Group Must Be A Valid Blood Group"
      ),
      address: Yup.string()
        .min(1, "Address is too Short!")
        .max(300, "Address is too Long!")
        .required("Address is Required"),
      memberphoto: Yup.mixed()
        .test("fileSize", "photo size is too high", (value) => {
          if (!value) return true; // Skip the test if no file is selected
          return value && value.size <= 52428800; // 50MB in bytes
        })
        .test(
          "fileType",
          "Please enter the photo in JPG, JPEG, PNG extension.",
          (value) => {
            if (!value) return true; // Skip the test if no file is selected
            return value && /(\.jpg|\.jpeg|\.png)$/i.test(value.name); // Check if the file has a valid extension
          }
        ),
    }),
    onSubmit: async ({
      name,
      memberphoto,
      memberId,
      dob,
      contactNo,
      emergencyNo,
      email,
      address,
      bloodGroup,
      active,
    }) => {
      try {
        if (
          depId === "" ||
          (category.length !== 0 && catId === "") ||
          (type.length !== 0 && typeId === "") ||
          pin === ""
        ) {
          return;
        }
        setLoading(true);
        const formData = new FormData();
        formData.append("departmentId", depId);
        formData.append("categoryId", catId);
        formData.append("typeId", typeId);
        formData.append("name", name);
        formData.append("memberphoto", memberphoto); // Assuming 'photo' is the selected file object
        formData.append("memberId", memberId);
        formData.append("dob", dob);
        formData.append("contactNo", String(contactNo));
        formData.append("emergencyNo", String(emergencyNo));
        formData.append("email", email);
        formData.append("address", address);
        formData.append("bloodGroup", bloodGroup);
        formData.append("isActive", String(active));
        formData.append("pinCode", pin);
        formData.append("area", areaName);
        formData.append("description", "");
        formData.append("note", "");
        formData.append("details", "");
        const res = await axiosInstance.post("/master/addMember", formData);
        if (res.status === 202) {
          setLoading(false);
          warnToast(res.data);
        }
        console.log(res);
        if (res.data?.message === "Ok") {
          successToast();
          nav("/memberMaster/addForm/assignmentAdd", {
            state: { member: res.data?.resp },
          });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  const depDropDown = async (y: string) => {
    setDepId(y);
    setCatId("");
    setTypeId("");
    if (y !== "") {
      const cat = await loadCategories(y);
      if (cat === undefined) {
        setCategory([]);
        setType([]);
      } else {
        setCategory(cat as CategoryDDL[]);
      }
    } else {
      setCategory([]);
      setType([]);
    }
  };
  const catDropDown = async (x: string) => {
    setCatId(x);
    setTypeId("");
    if (x !== "") {
      const typ = await loadTypes(x);
      if (typ === undefined) {
        setType([]);
      } else {
        setType(typ as TypeDDL[]);
      }
    } else {
      setType([]);
    }
  };
  const settingAreaName = async (pincode: string) => {
    const name = area.find((x) => {
      return x.pinCode === pincode;
    })?.areaName;
    setAreaName(name as string);
  };
  return (
    <div className="formBg">
      <form className="row align-items-center " onSubmit={formik.handleSubmit}>
        <label htmlFor="department" className="col-md-6 mb-3">
          <span className="top-placeholder">Department*</span>

          <select
            className={`form-control ${
              depId === "" && border ? "empty-form" : ""
            }`}
            id="department"
            onChange={(e) => {
              depDropDown(e.target.value);
            }}
          >
            <option value="" selected>
              Select Department
            </option>
            {department.map(({ _id, departmentName }) => {
              return (
                <option key={_id} value={_id}>
                  {departmentName}
                </option>
              );
            })}
          </select>
        </label>
        <label htmlFor="category" className="col-md-6 mb-3">
          <span className="top-placeholder">
            {category.length !== 0 && catId === "" ? "Category*" : "Category"}
          </span>

          <select
            className={`form-control ${
              category.length !== 0 && catId === "" && border
                ? "empty-form"
                : ""
            }`}
            id="category"
            onChange={(e) => {
              catDropDown(e.target.value);
            }}
          >
            <option value="" selected>
              Select Category
            </option>
            {category.length !== 0 &&
              category.map(({ _id, name }) => {
                return (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                );
              })}
          </select>
        </label>
        <label htmlFor="type" className="col-md-6 mb-3">
          <span className="top-placeholder">
            {type.length !== 0 && typeId === ""
              ? "Sub Category*"
              : "Sub Category"}
          </span>

          <select
            className={`form-control ${
              type.length !== 0 && typeId === "" && border ? "empty-form" : ""
            }`}
            id="type"
            onChange={(e) => {
              setTypeId(e.target.value);
            }}
          >
            <option value="" selected>
              Select Sub Category
            </option>
            {type.length !== 0 &&
              type.map(({ _id, name }) => {
                return (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                );
              })}
          </select>
        </label>
        <label htmlFor="area" className="col-md-6 mb-3">
          <span className="top-placeholder">Area*</span>

          <select
            className={`form-control ${
              pin === "" && border ? "empty-form" : ""
            }`}
            onChange={async (e) => {
              setPin(e.target.value);
              settingAreaName(e.target.value);
            }}
          >
            <option value="" selected>
              Select Area
            </option>
            {area.map(({ _id, areaName, pinCode }) => {
              return (
                <option key={_id} value={pinCode} id={areaName}>
                  {`${areaName} (${pinCode})`}
                </option>
              );
            })}
          </select>
        </label>

        <label className="col-md-6 mb-3" htmlFor="name">
          <input
            className={`form-control ${
              formik.errors.name && border ? "empty-form" : ""
            }`}
            id="name"
            name="name"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="Member Name"
          />

          <span className="top-placeholder">Member Name*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="memberId">
          <input
            className={`form-control ${
              formik.errors.memberId && border ? "empty-form" : ""
            }`}
            id="memberId"
            name="memberId"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.memberId}
            placeholder="Member ID"
          />

          <span className="top-placeholder">Member ID*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="dob">
          <input
            className={`form-control session-date ${
              formik.errors.dob && border ? "empty-form" : ""
            }`}
            id="dob"
            name="dob"
            type="date"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.dob}
            // onFocus={(e) => {
            //   e.target.type = "date";
            // }}
            placeholder="Date of Birth"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
          {/* {formik.values.dob && ( */}
          <span className="top-placeholder">Date of Birth*</span>
          {/* )} */}
        </label>
        <label className="col-md-6 mb-3" htmlFor="contactNo">
          <input
            className={`form-control ${
              formik.errors.contactNo && border ? "empty-form" : ""
            }`}
            id="contactNo"
            name="contactNo"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.contactNo}
            placeholder="Contact Number"
          />

          <span className="top-placeholder">Contact Number*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="emergencyNo">
          <input
            className="form-control"
            id="emergencyNo"
            name="emergencyNo"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.emergencyNo}
            placeholder="Emergency Number"
          />

          <span className="top-placeholder">Emergency Number</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="email">
          <input
            className="form-control"
            id="email"
            name="email"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Email"
          />

          <span className="top-placeholder">Email</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="bloodGroup">
          <input
            className="form-control"
            id="bloodGroup"
            name="bloodGroup"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bloodGroup}
            placeholder="Blood Group"
          />

          <span className="top-placeholder">Blood Group</span>
        </label>
        <label className="col-md-12 mb-3" htmlFor="address">
          <textarea
            className={`form-control ${
              formik.errors.address && border ? "empty-form" : ""
            }`}
            id="address"
            name="address"
            cols={60}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.address}
            placeholder="Address"
          />

          <span className="top-placeholder">Address*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="memberphoto">
          <input
            className="form-control uploadPhoto form-control-lg"
            id="memberphoto"
            name="memberphoto"
            type="file"
            onBlur={formik.handleBlur}
            onChange={(event) => {
              const file =
                event.currentTarget.files && event.currentTarget.files[0];
              formik.setFieldValue("memberphoto", file || ""); // If file is null, set an empty string
            }}
            placeholder="Enter photo"
          />
          {/* <span className="top-placeholder">Photo</span> */}
        </label>

        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn
            errors={formik.errors}
            clickFunc={() => {
              setBorder(true);
              if (depId === "") {
                warnToast("Select Department");
              }
              if (category.length !== 0 && catId === "") {
                warnToast("Select Category");
              }
              if (type.length !== 0 && typeId === "") {
                warnToast("Select Sub category");
              }
              if (pin === "") {
                warnToast("Select Area");
              }
              toastValidation(formik.errors);
            }}
          />
          <CancelBtn path=".." />
        </div>
      </form>
    </div>
  );
};

export default MemberAddForm;
