import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

type Props = {
  error: string;
};

const ErrorToast = ({ error }: Props) => {
  const [toast, setToast] = useState(true);
  const [err, setErr] = useState(error);
  return (
    <ToastContainer position="top-end">
      <Toast
        show={toast}
        onClose={() => {
          setToast(!toast);
          setErr("");
        }}
        bg="danger"
        delay={3000}
        autohide
      >
        <Toast.Body className="text-white">{err}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ErrorToast;
