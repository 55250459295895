import React, { memo } from "react";
import NoOfVehicle from "./noofVehicle";
import NoOfMember from "./noofmembers";
import NoOfDriver from "./noofDriver";
import LoginHistory from "./loginHistoryWraper";
import NoOfAttendant from "./noofAtteandant";
const OrgDashBoardLayout: React.FC = () => {
  return (
    <div className="row">
      <NoOfVehicle />
      <NoOfMember />
      <NoOfDriver />
      <NoOfAttendant />
      <br />
      <LoginHistory />
    </div>
  );
};

export default memo(OrgDashBoardLayout);
