import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReactComponent as Delete } from "assets/images/delete.svg";
import { convertTo12HourFormat, descSlice } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import axiosInstance from "axiosInstance";
import { successToast, warnToast } from "constants/toastConfig";
import { useLoading } from "Context/LoadingContext";
import { basicLocationMaster } from "types/basicLocationMasterTypes";

type Props = {
  item: basicLocationMaster;
  LoadData: (pageNum: number, sortParam: string) => any;
};

const BasicLocationListItem = ({ item, LoadData }: Props) => {
  const [show, setShow] = useState(false);
  const { setLoading } = useLoading();
  const nav = useNavigate();

  const onSubmit = async (id: string) => {
    setLoading(true);
    try {
      let formData = {};
      formData = {
        isActive: false,
        isDeleted: true,
      };
      const res = await axiosInstance.put(
        `/master/updateBasicLocationMaster/${id}`,
        formData
      );

      if (res.status === 202) {
        warnToast(res.data);
        setLoading(false);
        return;
      }
      if (res.data === "Ok") {
        await LoadData(1, "order");
        // successToast();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  const tooltip2 = <Tooltip id={`tooltip-${item._id}`}>Delete </Tooltip>;

  return (
    <>
      <tr key={item._id}>
        <td>{item.name}</td>
        <td>{descSlice(item?.address)}</td>
        <td>{item.order}</td>
        {item.pickUpTime ? (
          <td>{convertTo12HourFormat(item.pickUpTime)}</td>
        ) : (
          <td></td>
        )}
        {item.dropTime ? (
          <td>{convertTo12HourFormat(item.dropTime)}</td>
        ) : (
          <td></td>
        )}
        <td className="text-center">
          {item.isActive === true ? <Eye /> : <EyeOff />}
        </td>
        <td className="text-center">
          <OverlayTrigger placement="bottom" overlay={tooltip}>
            <Edit
              style={{ cursor: "pointer" }}
              id={item._id}
              onClick={() => {
                nav("edit", {
                  state: item,
                });
              }}
            />
          </OverlayTrigger>
          <OverlayTrigger placement="bottom" overlay={tooltip2}>
            <Delete
              style={{ cursor: "pointer" }}
              id={item._id}
              onClick={() => {
                setShow(true);
              }}
            />
          </OverlayTrigger>
        </td>
      </tr>
      <Modal show={show}>
        <Modal.Body>Are you sure you want to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onSubmit(item._id);
              setShow(false);
            }}
          >
            Ok
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(BasicLocationListItem);
