import { useLoading } from "Context/LoadingContext";
import { useNotFound } from "Context/NotFound";
import axiosInstance from "axiosInstance";
import NotFound from "components/NotFound";
import { infiniteScroll, sortData } from "constants/Constants";
import { useState, useEffect, useCallback } from "react";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import FeesConfigListItemBasic from "../BasicPayment/listItem";
import { warnToast } from "constants/toastConfig";
import { useNavigate } from "react-router-dom";

type Props = {};

type FeesConfig = {
  _id: string;
  organizerId: string;
  feesType: string;
  annualRegistrationFees: string;
  startFeesYear: string;
  endFeesYear: string;
  createdAt: string;
  updatedAt: string;
};

const FeesConfigListBasic = (props: Props) => {
  const [feesConfig, setFeesConfig] = useState<FeesConfig[]>([]);
  const [sort, setSort] = useState<string>("-createdAt");
  const [searchCount, setSearchCount] = useState(0);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const { notfound, setNotFound } = useNotFound();
  const [configId, setConfigId] = useState("");
  const [isPaymentMade, setIsPaymentMade] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const [prevConfigId, setPrevConfigId] = useState("");

  const nav = useNavigate();

  const loadFeesConfig = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getallBasicFeesConfig?page=${pageNum}&sort=${sortParam}`
        );
        const result = res.data.feesConfig;
        const isPaymentMade = res.data.isPaymentMade;
        setIsPaymentMade(isPaymentMade);
        const feesCongs: FeesConfig[] = [...feesConfig, ...result];
        setFeesConfig(feesCongs);
        checkValidDateToupdate(feesCongs);
        setSearchCount(res.data.TotalCount);
        setLoading(false);
        setNotFound(false);
      } catch (error) {
        setLoading(false);
        setNotFound(true);
        console.log(error);
      }
    },
    [feesConfig]
  );

  const loadLatestFeesConfig = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getallBasicFeesConfig?page=1&sort=-createdAt`
      );
      const result = res.data.feesConfig;
      if (result.length >= 2 && result[0].feesType === result[1].feesType) {
        // setIsClone(true);
        setPrevConfigId(result[1]._id);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    loadFeesConfig(page, sort);
  }, [page, sort]);

  useEffect(() => {
    loadLatestFeesConfig();
  }, []);

  const checkValidDateToupdate = (feesCongs: FeesConfig[]) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    feesCongs.forEach((x) => {
      const endFeesYear = new Date(x.endFeesYear);
      const endFeesMonth = endFeesYear.getMonth() + 1;
      const endFeesYearValue = endFeesYear.getFullYear();
      if (currentYear < endFeesYearValue) {
        setConfigId(x._id);
      } else if (
        currentYear === endFeesYearValue &&
        currentMonth <= endFeesMonth
      ) {
        setConfigId(x._id);
      }
    });
  };
  window.onscroll = () => {
    infiniteScroll(feesConfig, setPage, page, searchCount);
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary mb-3"
          onClick={() => {
            if (configId !== "") {
              warnToast("You have already done this years setup ");
            } else {
              //   const item = {
              //     configId: configId,
              //     isPaymentMade: isPaymentMade,
              //     isClone: false,
              //     prevConfigId: "",
              //   };
              nav(
                {
                  pathname: "/paymentMasterBasic/add",
                }
                // { state: item }
              );
            }
          }}
        >
          Add
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="startFeesYear"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setFeesConfig, setPage);
              }}
            >
              Start Year
              <span className="px-2">
                {sort === "startFeesYear" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="endFeesYear"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setFeesConfig, setPage);
              }}
            >
              End Year
              <span className="px-2">
                {sort === "endFeesYear" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Action</th>
            <th className="text-center">Member Fees</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {feesConfig?.map((x) => {
              return (
                <FeesConfigListItemBasic
                  x={x}
                  key={x._id}
                  //   configId={configId}
                  //   isPaymentMade={isPaymentMade}
                  //   isClone={isClone}
                  prevConfigId={prevConfigId}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default FeesConfigListBasic;
