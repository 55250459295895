import { memo, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLoading } from "Context/LoadingContext";
import toastValidation, { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import { convertToYYYYMMFormat } from "constants/Constants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import CancelBtn from "components/Buttons/Cancel";
import MemberFeesList from "app/organizer/MemberFees/list";
import BasicMemberFeeList from "./BasicMemberFeeList";

type FeesConfigType = {
  _id: string;
  organizerId: string;
  annualRegistrationFees: string;
  startFeesYear: string;
  endFeesYear: string;
};

type Props = {};

const BasicFeesConfigEdit = (props: Props) => {
  //nav and state
  const nav = useNavigate();
  const { state } = useLocation();
  const { setLoading } = useLoading();

  //data storing
  const [data, setData] = useState<FeesConfigType>();
  const [show, setShow] = useState(false);

  //path setting
  const { pathname } = useLocation();

  // parameter and index
  const [param] = useSearchParams();
  const [index, setIndex] = useState(
    param.get("index") !== null ? Number(param.get("index")) : 0
  );
  //Clone is available or not
  const loadLatestFeesConfig = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getallFeesConfig?page=1&sort=-createdAt`
      );
      const result = res.data.feesConfig;
      if (result.length >= 2 && result[0].feesType === result[1].feesType) {
        return { isClone: true, prevId: result[1]._id };
      } else {
        return { isClone: false, prevId: "" };
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  //update Config functionality
  const fetchConfig = async () => {
    try {
      const resp = await axiosInstance.get(
        `/master/getOneBasicFeesConfig/${state._id}`
      );
      setData(resp.data?.result);
      //   if (resp.status === 200) {
      //     setFeesType(resp.data?.result?.feesType);
      //   }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    try {
      const formData = {
        annualRegistrationFees: "1000",
        startFeesYear: formik.values.startFeesYear,
        endFeesYear: formik.values.endFeesYear,
      };
      setLoading(true);
      const res = await axiosInstance.put(
        `/master/updateBasicFeesConfig/${state._id}`,
        formData
      );
      if (res.data.message === "Ok") {
        // const isCloneX = await loadLatestFeesConfig();
        // console.log(isCloneX);
        successToast();
        setLoading(false);
        fetchConfig();
        nav("..");
        // state.isClone = isCloneX?.isClone;
        // state.prevConfigId = isCloneX?.prevId;
        // const item = { ...state, ...res.data.result };
        // nav(
        //   {
        //     pathname: `/transportationFeesMain/feesSectionEdit`,
        //     search: "?index=0",
        //   },
        //   { state: item }
        // );
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const formik = useFormik({
    initialValues: {
      annualRegistrationFees: data?.annualRegistrationFees,
      startFeesYear: convertToYYYYMMFormat(data?.startFeesYear),
      endFeesYear: convertToYYYYMMFormat(data?.endFeesYear),
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      startFeesYear: Yup.string().required("startFeesYear is Required"),
      endFeesYear: Yup.string().required("endFeesYear is Required"),
    }),
    onSubmit: async () => {
      setShow(true);
    },
  });

  return (
    <>
      {/* {pathname !== "/paymentMasterBasic" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/paymentMasterBasic" />
        </div>
      )} */}
      {/* <Tabs
        selectedIndex={index}
        onSelect={(e) => {
          setIndex(e);
        }}
      > */}
      {/* <TabList>
          <Tab>Fees structure setting</Tab>
          <Tab>Member Fee</Tab>
        </TabList> */}

      {/* <TabPanel> */}
      <>
        <div className="formBg">
          <form onSubmit={formik.handleSubmit}>
            <div className="row align-items-center">
              <label className="col-md-6 mb-3" htmlFor="startFeesYear">
                <input
                  className={`form-control session-date ${
                    formik.errors.startFeesYear && formik.submitCount
                      ? "empty-form"
                      : ""
                  }`}
                  id="startFeesYear"
                  name="startFeesYear"
                  type="month"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.startFeesYear as string}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <span className="top-placeholder">Start Fees Year*</span>
              </label>
              <label className="col-md-6 mb-3" htmlFor="endFeesYear">
                <input
                  className={`form-control session-date ${
                    formik.errors.endFeesYear && formik.submitCount
                      ? "empty-form"
                      : ""
                  }`}
                  id="endFeesYear"
                  name="endFeesYear"
                  type="month"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.endFeesYear as string}
                  placeholder="End Fees Year"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                <span className="top-placeholder">End Fees Year*</span>
              </label>
            </div>
            <div className="col-md-12 mb-3 text-center">
              {/* {state.configId === state._id &&
                    state.isPaymentMade === false && ( */}
              <SubmitBtn
                errors={formik.errors}
                clickFunc={() => {
                  toastValidation(formik.errors);
                  onSubmit();
                }}
              />
              {/* )} */}
              {/* {state.configId === state._id &&
                state.isPaymentMade === false && ( */}
              <button
                className="btn btn-primary"
                onClick={() => {
                  nav({
                    pathname: "/paymentMasterBasic",
                  });
                }}
              >
                Cancel
              </button>
              {/* )} */}
            </div>
          </form>
        </div>
      </>
      {/* </TabPanel> */}
      {/* <TabPanel>
          <BasicMemberFeeList state={state} />
        </TabPanel>
      </Tabs> */}
    </>
  );
};

export default memo(BasicFeesConfigEdit);
