import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import ShiftDataType from "../../../types/shiftType.types";
import { useShiftType } from "./index";
import { useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { successToast } from "constants/toastConfig";
type Props = {};

const ShiftTypeEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { shiftId } = useShiftType();
  const [shift, setShift] = useState<ShiftDataType>();
  const findOneShift = useCallback(async () => {
    try {
      const resShift = await axiosInstance.get(
        `/master/getOneShiftById/${shiftId}`
      );
      console.log(resShift.data.Shift);

      const shift = resShift.data.Shift as ShiftDataType;
      setShift(shift);
    } catch (error) {}
  }, [shiftId]);
  useEffect(() => {
    findOneShift();
  }, [findOneShift]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: shift?.name,
      code: shift?.code,
      inTime: shift?.inTime,
      outTime: shift?.outTime,
      description: shift?.description,
      active: shift?.isActive,
      note: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Shift Name Too Short!")
        .max(30, "Shift Name Too Long!")
        .required("Shift Name is Required"),
      code: Yup.string().max(10, "Shift Code is too long"),
      inTime: Yup.string().required("Shift InTime is Required"),
      outTime: Yup.string().required("Shift OutTime is Required"),
      description: Yup.string().max(300, "Shift Description is too long"),
    }),
    onSubmit: async ({ name, code, description, active, inTime, outTime }) => {
      setLoading(true);
      try {
        const formData = {
          name: name,
          code: code,
          inTime: inTime,
          outTime: outTime,
          description: description,
          note: "",
          details: "",
          isActive: active,
        };
        const res = await axiosInstance.put(
          `/master/updateShiftById/${shiftId}`,
          formData
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("/shiftTypeMaster");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <div className="formBg">
      <form className="row align-items-center " onSubmit={formik.handleSubmit}>
        <label className="col-md-6 mb-3" htmlFor="name">
          <input
            className={`form-control ${formik.errors.name ? "empty-form" : ""}`}
            id="name"
            name="name"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="Shift Name"
          />

          <span className="top-placeholder">Shift Name*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="code">
          <input
            className="form-control"
            id="code"
            name="code"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.code}
            placeholder="Shift Code"
          />

          <span className="top-placeholder">Shift Code</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="inTime">
          <input
            className="form-control timePickerInTime session-time"
            id="inTime"
            name="inTime"
            type="time"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.inTime}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />

          <span className="top-placeholder">Shift In-Time*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="outTime">
          <input
            className="form-control timePickerOutTime session-time"
            id="outTime"
            name="outTime"
            type="time"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.outTime}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />

          <span className="top-placeholder">Shift Out-Time*</span>
        </label>
        <label className="col-md-12 mb-3" htmlFor="description">
          <textarea
            className="form-control"
            id="description"
            name="description"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.description}
            placeholder="Shift Description"
          />

          <span className="top-placeholder">Shift Descriptionn</span>
        </label>
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn errors={formik.errors} />
          <CancelBtn path="/shiftTypeMaster" />
        </div>
      </form>
    </div>
  );
};

export default memo(ShiftTypeEditForm);
