import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { successToast } from "constants/toastConfig";

type Props = {};

const FeesKmAddForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [param] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      isActive: true,
      minRange: "",
      maxRange: "",
      amount: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      minRange: Yup.number().required("Minimum Range is Required"),
      maxRange: Yup.number().required("Maximum Range is Required"),
      amount: Yup.number().min(0).required("Amount is Required"),
    }),
    onSubmit: async ({ amount, maxRange, minRange, isActive }) => {
      try {
        const formData = {
          feesConfigId: state._id,
          minRange: String(minRange),
          maxRange: String(maxRange),
          amount: String(amount),
          description: "",
          note: "",
          details: "",
          isActive,
        };

        setLoading(true);

        const res = await axiosInstance.post("/master/addFeesKm", formData);
        if (res.data === "Created") {
          successToast();
          setLoading(false);
          const item = state;
          if (Number(param.get("fromEdit")) === 1) {
            nav(
              {
                pathname: "/transportationFeesMain/feesSectionEdit",
                search: "?index=2",
              },
              { state: item }
            );
          } else {
            nav(
              {
                pathname: "/transportationFeesMain/feesSection/kmList",
                search: "?index=2",
              },
              { state: item }
            );
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="minRange">
              {formik.values.minRange && (
                <span className="top-placeholder">{`Minimum Range(Km)`}</span>
              )}
              <input
                className="form-control"
                id="minRange"
                name="minRange"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.minRange}
                placeholder="Minimum Range(Km)"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="maxRange">
              {formik.values.maxRange && (
                <span className="top-placeholder">{`Maximum Range(Km)`}</span>
              )}
              <input
                className="form-control"
                id="maxRange"
                name="maxRange"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.maxRange}
                placeholder="Maximum Range(Km)"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="amount">
              {formik.values.amount && (
                <span className="top-placeholder">Amount</span>
              )}
              <input
                className="form-control"
                id="amount"
                name="amount"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.amount}
                placeholder="Amount"
              />
            </label>
          </div>

          <div className="col-md-3 mb-3">
            <label className="custCheck" htmlFor="isActive">
              Active
              <input
                type="checkbox"
                id="isActive"
                name="isActive"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                checked={formik.values.isActive}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            <button
              className="btn btn-primary"
              onClick={() => {
                const item = state;
                if (Number(param.get("fromEdit")) === 1) {
                  nav(
                    {
                      pathname: "/transportationFeesMain/feesSectionEdit",
                      search: "?index=2",
                    },
                    { state: item }
                  );
                } else {
                  nav(
                    {
                      pathname: "/transportationFeesMain/feesSection/kmList",
                      search: "?index=2",
                    },
                    { state: item }
                  );
                }
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(FeesKmAddForm);
