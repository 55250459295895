import React, { memo } from "react";
import { AdminClientDataType } from "types/adminClientTypes";
type Props = {
  x: AdminClientDataType;
};

const AdminClientListItem = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td className="pb-4">{x.name}</td>
      <td className="pb-4">{x.email}</td>
      <td className="pb-4">{x.contactNo}</td>
      <td className="pb-4">{x.bussinessName}</td>
      <td className="pb-4">{x.planName}</td>
      <td className="pb-4">{x.promocode === null ? "" : x.promocode}</td>
      <td className="pb-4">
        {x.promocodeUseBy === null ? "" : x.promocodeUseBy.join(", ")}
      </td>
    </tr>
  );
};

export default memo(AdminClientListItem);
