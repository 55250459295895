import axiosInstance from "axiosInstance";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import { ShiftDDL } from "types/shiftType.types";
import { VehicleMasterDataType } from "types/vehicleMasterType";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";

type Props = {};

type RegisteredVehicleType = {
  vehicleNo: string;
  vehicleRegId: string;
};

type AreaDDLType = {
  areaName: string;
  _id: string;
};

const AddForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [param] = useSearchParams();

  const [shift, setShift] = useState<ShiftDDL[]>();
  const [shiftId, setShiftId] = useState("");

  const [vehTyp, setVehTyp] = useState<VehicleMasterDataType[]>();
  const [vehTypId, setVehTypId] = useState("");

  const [registeredVeh, setRegisteredVeh] = useState<RegisteredVehicleType[]>();
  const [vehRegId, setVehRegId] = useState("");

  const [area, setArea] = useState<AreaDDLType[]>();
  const [pin, setPin] = useState("");

  const [division, setDivision] = useState<AreaDivisionDDL[]>();
  const [divId, setDivId] = useState("");

  const [border, setBorder] = useState<boolean>(false);

  const loadShifts = useCallback(async () => {
    try {
      const res = await axiosInstance.get("/dropDown/getShiftType");
      setShift(res.data.shiftTypes);
    } catch (error) {}
  }, []);

  const loadVehicleTypes = useCallback(async () => {
    try {
      const res = await axiosInstance.get("/dropDown/getVehicleType");
      setVehTyp(res.data.vehicleTypes);
    } catch (error) {}
  }, []);

  const loadAssignedVehicle = useCallback(
    async (sId: string, vehId: string) => {
      try {
        const res = await axiosInstance.get(
          `/dropDown/getAllAssignedVehicle?shiftId=${sId}&vehicleId=${vehId}`
        );
        setRegisteredVeh(res.data.result.AssignedVehicle);
      } catch (error) {}
    },
    []
  );

  const loadAssignedAreas = useCallback(
    async (sId: string, vehId: string, regId: string) => {
      try {
        const res = await axiosInstance.get(
          `/dropDown/getAllocatedAreas?shiftId=${sId}&vehicleId=${vehId}&vehicleRegistrationId=${regId}&configId=${state._id}`
        );
        setArea(res.data.result.AllocatedAreas);
      } catch (error) {}
    },
    []
  );

  const loadAssignedDivisions = useCallback(
    async (sId: string, vehId: string, regId: string, pinCode: string) => {
      try {
        const res = await axiosInstance.get(
          `/dropDown/getAllocatedDivisions?shiftId=${sId}&vehicleId=${vehId}&vehicleRegistrationId=${regId}&pinCode=${pinCode}&configId=${state._id}`
        );
        setDivision(res.data.result.AllocatedDivisions);
      } catch (error) {}
    },
    []
  );

  useEffect(() => {
    loadShifts();
  }, []);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      amount: "",
      active: true,
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required("Amount is Required"),
    }),
    onSubmit: async ({ amount, active }) => {
      try {
        if (
          shiftId === "" ||
          vehTypId === "" ||
          vehRegId === "" ||
          pin === "" ||
          divId === ""
        )
          return;
        setLoading(true);
        const formData = {
          shiftTypeId: shiftId,
          vehicleTypeId: vehTypId,
          feesConfigId: state._id,
          vehicleRegistrationId: vehRegId,
          pinCode: pin,
          divisionId: divId,
          amount: String(amount),
          description: "",
          note: "",
          details: "",
          isActive: active,
          isDeleted: false,
        };
        const res = await axiosInstance.post(
          "/master/addFeesVehicle",
          formData
        );
        const statusCode = res.data.statusCode;
        if (statusCode === 201) {
          successToast();
          const item = state;
          if (Number(param.get("fromEdit")) === 1) {
            nav(
              {
                pathname: "/transportationFeesMain/feesSectionEdit",
                search: "?index=2",
              },
              { state: item }
            );
          } else {
            nav(
              {
                pathname: "/transportationFeesMain/feesSection/vehicleList",
                search: "?index=2",
              },
              { state: item }
            );
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <div className="formBg">
      <form
        action=""
        className="row align-items-center "
        onSubmit={formik.handleSubmit}
      >
        <label htmlFor="shift" className="col-md-6 mb-3">
          {shiftId !== "" && <span className="top-placeholder">shift</span>}
          <select
            className={`form-control ${
              shiftId === "" && border ? "empty-form" : ""
            }`}
            id="shift"
            onChange={(e) => {
              setShiftId(e.target.value);
              if (e.target.value !== "") {
                setVehTyp([]);
                setRegisteredVeh([]);
                setArea([]);
                setDivision([]);
                loadVehicleTypes();
              } else {
                setVehTyp([]);
                setRegisteredVeh([]);
                setArea([]);
                setDivision([]);
              }
            }}
          >
            <option value="">Select Shift</option>
            {shift?.map(({ _id, name }) => {
              return (
                <option key={_id} value={_id}>
                  {name}
                </option>
              );
            })}
          </select>
        </label>
        <label htmlFor="vehicleType" className="col-md-6 mb-3">
          {vehTypId !== "" && (
            <span className="top-placeholder">Vehicle type</span>
          )}
          <select
            className={`form-control ${
              vehTypId === "" && border ? "empty-form" : ""
            }`}
            id="vehicleType"
            onChange={(e) => {
              setVehTypId(e.target.value);
              if (shiftId !== "" && e.target.value !== "") {
                setRegisteredVeh([]);
                setArea([]);
                setDivision([]);
                loadAssignedVehicle(shiftId, e.target.value);
              } else {
                setRegisteredVeh([]);
                setArea([]);
                setDivision([]);
              }
            }}
          >
            <option value="">Select Vehicle Type</option>
            {vehTyp?.map(({ _id, name }) => {
              return (
                <option key={_id} value={_id}>
                  {name}
                </option>
              );
            })}
          </select>
        </label>
        <label htmlFor="vehReg" className="col-md-6 mb-3">
          {vehRegId !== "" && (
            <span className="top-placeholder">Vehicle Number</span>
          )}
          <select
            className={`form-control ${
              vehRegId === "" && border ? "empty-form" : ""
            }`}
            id="vehReg"
            onChange={(e) => {
              setVehRegId(e.target.value);
              if (shiftId !== "" && vehTypId !== "" && e.target.value !== "") {
                setArea([]);
                setDivision([]);
                loadAssignedAreas(shiftId, vehTypId, e.target.value);
              } else {
                setArea([]);
                setDivision([]);
              }
            }}
          >
            <option value="">Select Vehicle Number</option>
            {registeredVeh?.map(({ vehicleNo, vehicleRegId }) => {
              return (
                <option key={vehicleRegId} value={vehicleRegId}>
                  {vehicleNo}
                </option>
              );
            })}
          </select>
        </label>
        <label htmlFor="area" className="col-md-6 mb-3">
          {pin !== "" && <span className="top-placeholder">Area</span>}
          <select
            className={`form-control ${
              pin === "" && border ? "empty-form" : ""
            }`}
            id="area"
            onChange={(e) => {
              setPin(e.target.value);
              if (
                shiftId !== "" &&
                vehTypId !== "" &&
                vehRegId !== "" &&
                e.target.value !== ""
              ) {
                setDivision([]);
                loadAssignedDivisions(
                  shiftId,
                  vehTypId,
                  vehRegId,
                  e.target.value
                );
              } else {
                setDivision([]);
              }
            }}
          >
            <option value="">Select Area</option>
            {area?.map(({ areaName, _id }) => {
              return (
                <option key={_id} value={_id}>
                  {areaName}
                </option>
              );
            })}
          </select>
        </label>
        <label htmlFor="division" className="col-md-6 mb-3">
          {divId !== "" && <span className="top-placeholder">Division</span>}
          <select
            className={`form-control ${
              divId === "" && border ? "empty-form" : ""
            }`}
            id="division"
            onChange={(e) => {
              setDivId(e.target.value);
            }}
          >
            <option value="">Select Division</option>
            {division?.map((x: any) => {
              return (
                <option key={x?._id} value={x?._id}>
                  {x?.areaCode}
                </option>
              );
            })}
          </select>
        </label>
        <label className="col-md-6 mb-3" htmlFor="amount">
          {formik.values.amount && (
            <span className="top-placeholder">Amount</span>
          )}
          <input
            className={`form-control ${
              formik.errors.amount && border ? "empty-form" : ""
            }`}
            id="amount"
            name="amount"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.amount}
            placeholder="amount"
          />
        </label>
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn
            errors={formik.errors}
            clickFunc={() => {
              setBorder(true);
              if (shiftId === "") warnToast("Select Shift");
              if (vehTypId === "") warnToast("Select Vehicle Type");
              if (vehRegId === "") warnToast("Select Vehicle");
              if (pin === "") warnToast("Select Area");
              if (divId === "") warnToast("Select Area Division");
              toastValidation(formik.errors);
            }}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              const item = state;
              if (Number(param.get("fromEdit")) === 1) {
                nav(
                  {
                    pathname: "/transportationFeesMain/feesSectionEdit",
                    search: "?index=2",
                  },
                  { state: item }
                );
              } else {
                nav(
                  {
                    pathname: "/transportationFeesMain/feesSection/vehicleList",
                    search: "?index=2",
                  },
                  { state: item }
                );
              }
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddForm;
