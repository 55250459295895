import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo, useMemo } from "react";
import { useLoading } from "Context/LoadingContext";
import { useTable, usePagination } from "react-table";
import "../../../assets/styles/style.css";
import {
  downloadTableAsPDF,
  downloadTableAsPrint,
  exportToExcel,
  formatDateForFile,
} from "constants/Constants";
import NotFound from "components/NotFound";
import { downloadExcel } from "constants/ReportFun";

type Props = {};

const PaymentReportList = (props: Props): JSX.Element => {
  const [data, setData] = useState([]);
  const [ddlData, setDdlData] = useState([]);
  const [fdId, setFdId] = useState("");
  const [ft, setFt] = useState("");
  const [notFound, setNotFound] = useState<boolean>(false);
  const { setLoading } = useLoading();

  const loadDDL = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/report/fDateDDL`);
      setDdlData(res.data.resp);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [ddlData]);

  const loadReports = useCallback(
    async (fdId: string, ft: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/report/getMemberPaymentReport?fId=${fdId}&feeType=${ft}`
        );
        setData(res.data.resp);
        setLoading(false);
        setNotFound(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        setNotFound(true);
      }
    },
    [data]
  );

  useEffect(() => {
    loadDDL();
    loadReports(fdId, ft);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Member Name",
        accessor: "name",
      },
      {
        Header: "Member ID",
        accessor: "memberId",
      },
      {
        Header: "Route Name",
        accessor: "routeName",
      },
      {
        Header: "Term Name",
        accessor: "termName",
      },
      {
        Header: "Fees Date",
        accessor: "tStartDate",
      },
      {
        Header: "Due Date",
        accessor: "tDueDate",
      },
      {
        Header: "Term Fees",
        accessor: "termFee",
      },
      {
        Header: "Paid Fees",
        accessor: "paidF",
        // accessor: (row: any) => {
        //   if (row.paidF !== "") {
        //     return row.paidF;
        //   } else if (row.cPaid !== "") {
        //     return row.cPaid;
        //   } else {
        //     return "";
        //   }
        // },
      },
      {
        Header: "Paid Date",
        accessor: "paidD",
        // accessor: (row: any) => {
        //   if (row.paidDate !== "") {
        //     return row.paidDate;
        //   } else if (row.cDate !== "") {
        //     return row.cDate;
        //   } else {
        //     return " ";
        //   }
        // },
      },
      {
        Header: "Paid Type",
        accessor: "paidType",
        // accessor: (row: any) => {
        //   if (row.paidFees !== "") {
        //     return "Cash";
        //   } else if (row.cPaid !== "") {
        //     return "Cheque";
        //   } else {
        //     return "";
        //   }
        // },
      },
    ],
    []
  );

  const download = async () => {
    try {
      downloadExcel(
        [
          {
            id: "name",
            name: "Member Name",
          },
          {
            id: "memberId",
            name: "Member ID",
          },
          {
            id: "routeName",
            name: "Route Name",
          },
          {
            id: "termName",
            name: "Term Name",
          },
          {
            id: "tStartDate",
            name: "Fees Date",
          },
          {
            id: "tDueDate",
            name: "Due Date",
          },
          {
            id: "termFee",
            name: "Term Fees",
          },
          {
            id: "paidF",
            name: "Paid Fees",
          },
          {
            id: "paidD",
            name: "Paid Date",
          },
          {
            id: "paidType",
            name: "Paid Type",
          },
        ],
        data === undefined ? [] : data,
        "Payment",
        "Payment"
      );
    } catch (error) {
      return {};
    }
  };
  const todayDate = new Date();
  const dateForFile = formatDateForFile(todayDate.toISOString());

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as any,
    },
    usePagination
  );

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Member Payment Report</span>
      </div>
      <button
        onClick={() =>
          downloadTableAsPDF(columns, data, `Payment-${dateForFile}.pdf`)
        }
        className="btn btn-primary mb-4"
      >
        Export to PDF
      </button>
      <button
        onClick={() => {
          download();
        }}
        className="btn btn-primary mb-4"
      >
        Export to Excel
      </button>
      <button
        onClick={() => downloadTableAsPrint(columns, data)}
        className="btn btn-primary mb-4"
      >
        Print
      </button>
      <div className="formBg mb-3 row align-items-center">
        <label className="col-md-6 mb-3" htmlFor="month-dropdown">
          <select
            className="form-control"
            id="month-dropdown"
            name="month-dropdown"
            value={fdId}
            onChange={async (e) => {
              setFdId(e.target.value);
              setData([]);
              // loadReports(e.target.value);
            }}
          >
            <option value="">Select Financial Year</option>
            {ddlData.map((m: any) => {
              return (
                <option key={Math.random()} value={m._id}>
                  {m.sDate} To {m.eDate}
                </option>
              );
            })}
          </select>
        </label>
        <label className="col-md-6 mb-3" htmlFor="dropdown">
          <select
            className="form-control"
            id="dropdown"
            name="dropdown"
            value={ft}
            onChange={async (e) => {
              setFt(e.target.value);
              setData([]);
              loadReports(fdId, e.target.value);
            }}
          >
            <option value="">Select Type</option>
            <option key={Math.random()}>All</option>
            <option key={Math.random()}>Due</option>
          </select>
        </label>
      </div>
      <table {...getTableProps()} id="report-table" className="tableForReport">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="trForReport">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="thForReport">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="trForReport">
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()} className="tdForReport">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {notFound && (
        <div className="mt-4">
          {" "}
          <NotFound />
        </div>
      )}
      <div className="pagination m-4 gap-2">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">>"}
        </button>{" "}
        <span className="mx-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(PaymentReportList);
