import React, { memo } from 'react'
import LoginOrganizers from './loginOrganizers'
import LoginDrivers from './driverLogin'
import LoginMembers from './memberLogin'
const LoginHistoryLayout: React.FC = () => {
  return (
    <div className="row" >
      <LoginOrganizers />
      <LoginDrivers />
      <LoginMembers />
    </div>
  );
};

export default memo(LoginHistoryLayout);