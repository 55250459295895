import axiosInstance from "axiosInstance";
import { useCallback, useState, useEffect, memo } from "react";
import { locationListDataType } from "types/locationMasterType";
import LocationMasterListItem from "./locationMasterListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { useAreaEdit } from "Context/AreaEditContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";

type Props = {};

const LocationList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("area");
  const [searchCount, setSearchCount] = useState(0);
  const [location, setLocation] = useState<locationListDataType[]>([]);
  const { area } = useAreaEdit();
  // const [pin, setPin] = useState('')
  // const [area, setArea] = useState<AreaDDLType[]>([])
  // const [division, setDivision] = useState<AreaDivisionDDL[]>([])
  // const [divId, setDivId] = useState('')
  const { notfound, setNotFound } = useNotFound();

  const loadLocation = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllLocation?page=${pageNum}&sort=${sortParam}&pinCode=${area?.pinCode}&areaCode=`
        );
        const loc = res.data.location[0].paginatedResults;
        const locations: locationListDataType[] = [...location, ...loc];
        setLocation(locations);
        setSearchCount(res.data.location[0].SearchResult);

        setLoading(false);
        setNotFound(false);
      } catch (error) {
        setNotFound(true);
        setLoading(false);
        console.log(error);
      }
    },
    [location]
  );

  useEffect(() => {
    loadLocation(page, sort);
  }, [page, sort, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(location, setPage, page, searchCount);
  };

  return (
    <>
      {/* {pathname === "/areaMaster/editForm/locEdit" ? (
        <Outlet />
      ) : ( */}
      <>
        <table className="table">
          <thead>
            <tr>
              <th
                id="area"
                role="button"
                onClick={(e) => {
                  sortData(ascDsc, setAscDsc, e, setSort, setLocation, setPage);
                }}
              >
                Area
                <span className="px-2">
                  {sort === "area" ? (
                    <UpArr height="15px" width="20px" />
                  ) : (
                    <DownArr height="15px" width="20px" />
                  )}
                </span>
              </th>
              <th
                id="division"
                role="button"
                onClick={(e) => {
                  sortData(ascDsc, setAscDsc, e, setSort, setLocation, setPage);
                }}
              >
                Division
                <span className="px-2">
                  {sort === "division" ? (
                    <UpArr height="15px" width="20px" />
                  ) : (
                    <DownArr height="15px" width="20px" />
                  )}
                </span>
              </th>
              <th
                id="name"
                role="button"
                onClick={(e) => {
                  sortData(ascDsc, setAscDsc, e, setSort, setLocation, setPage);
                }}
              >
                Name
                <span className="px-2">
                  {sort === "name" ? (
                    <UpArr height="15px" width="20px" />
                  ) : (
                    <DownArr height="15px" width="20px" />
                  )}
                </span>
              </th>
              <th
                id="code"
                role="button"
                onClick={(e) => {
                  sortData(ascDsc, setAscDsc, e, setSort, setLocation, setPage);
                }}
              >
                Code
                <span className="px-2">
                  {sort === "code" ? (
                    <UpArr height="15px" width="20px" />
                  ) : (
                    <DownArr height="15px" width="20px" />
                  )}
                </span>
              </th>
              <th
                id="address1"
                role="button"
                onClick={(e) => {
                  sortData(ascDsc, setAscDsc, e, setSort, setLocation, setPage);
                }}
              >
                Address
                <span className="px-2">
                  {sort === "address1" ? (
                    <UpArr height="15px" width="20px" />
                  ) : (
                    <DownArr height="15px" width="20px" />
                  )}
                </span>
              </th>
              <th
                id="pinCode"
                role="button"
                onClick={(e) => {
                  sortData(ascDsc, setAscDsc, e, setSort, setLocation, setPage);
                }}
              >
                PIN Code
                <span className="px-2">
                  {sort === "pinCode" ? (
                    <UpArr height="15px" width="20px" />
                  ) : (
                    <DownArr height="15px" width="20px" />
                  )}
                </span>
              </th>
              <th className="text-center">Active</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          {!notfound && (
            <tbody>
              {location?.map((x) => {
                return <LocationMasterListItem x={x} key={x._id} />;
              })}
            </tbody>
          )}
        </table>
        {notfound && <NotFound />}
      </>
      {/* )} */}
    </>
  );
};

export default memo(LocationList);
