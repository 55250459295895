import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Layout from "../components/Layout/layout";
import ProfileList from "components/ProfileEdit/ProfileList";
import ComingSoon from "components/Dashboard";
import OrganizerLogin from "components/OrganizerLogin";
import Category from "./organizer/CategoryMaster";
import CategoryEditForm from "./organizer/CategoryMaster/CategoryEditForm";
import CategoryAddForm from "./organizer/CategoryMaster/CategoryAddForm";
import CategoryMasterList from "./organizer/CategoryMaster/CategoryMasterList";
import VehicleMaster from "./organizer/vehicleMaster";
import VehicleMasterForm from "./organizer/vehicleMaster/vehicleMasterForm";
import VehicleMasterEdit from "./organizer/vehicleMaster/vehicleMasterEdit";
import VehicleMasterList from "./organizer/vehicleMaster/vehicleMasterList";
import Department from "./organizer/department/index";
import DepartmentMasterList from "./organizer/department/departmentMasterList";
import Location from "./organizer/locationMaster/index";
import LocationMasterList from "./organizer/locationMaster/locationMasterList";
import LocationForm from "./organizer/locationMaster/locationMasterAddForm";
import LocationEdit from "./organizer/locationMaster/locationMasterEditForm";
import Insurance from "./organizer/VehicleInsuranceMaster/index";
import InsuranceMasterList from "./organizer/VehicleInsuranceMaster/VehicleInsuranceMasterList";
import InsuranceAddForm from "./organizer/VehicleInsuranceMaster/VehicleInsuranceMasterAddForm";
import InsuranceEdit from "./organizer/VehicleInsuranceMaster/VehicleInsuranceMasterEditForm";
import SignUp from "components/SignUp";
import ShiftTypeMaster from "./organizer/ShiftTypeMaster";
import ShiftTypeMasterList from "./organizer/ShiftTypeMaster/ShiftTypeMasterList";
import ShiftTypeAddForm from "./organizer/ShiftTypeMaster/ShiftTypeAddForm";
import ShiftTypeEditForm from "./organizer/ShiftTypeMaster/ShiftTypeEditForm";
import HolidayMaster from "./organizer/HolidayMaster";
import HolidayMasterList from "./organizer/HolidayMaster/holidayMasterList";
import HolidayMasterForm from "./organizer/HolidayMaster/holidayMasterForm";
import HolidayMasterEditForm from "./organizer/HolidayMaster/holidayMasterEditForm";
import VehicleRegistration from "./organizer/VehicleRegistrationMaster";
import VehicleRegAddForm from "./organizer/VehicleRegistrationMaster/VehicleRegAddForm";
import VehicleRegEditForm from "./organizer/VehicleRegistrationMaster/VehicleRegEditForm";
import VehicleRegMasterList from "./organizer/VehicleRegistrationMaster/VehicleRegMasterList";
import RequireAuth from "./RequireAuth";
import DriverMaster from "./organizer/DriverMaster";
import DriverList from "./organizer/DriverMaster/DriverList";
import DriverForm from "./organizer/DriverMaster/DriverForm";
import DriverEdit from "./organizer/DriverMaster/DriverEdit";
import TypeMaster from "./organizer/TypeMaster";
import TypeMasterList from "./organizer/TypeMaster/typeMasterList";
import TypeMasterEditForm from "./organizer/TypeMaster/typeMasterEditForm";
import TypeMasterForm from "./organizer/TypeMaster/typeMasterForm";
import VehiclePucMaster from "./organizer/VehiclePucMaster";
import VehiclePucList from "./organizer/VehiclePucMaster/vehiclePucList";
import VehiclePucEditForm from "./organizer/VehiclePucMaster/vehiclePucEditForm";
import VehiclePucMasterForm from "./organizer/VehiclePucMaster/vehiclePucForm";
import MemberMaster from "./organizer/MemberMaster";
import MemberMasterList from "./organizer/MemberMaster/MemberList";
import MemberAddForm from "./organizer/MemberMaster/MemberAddForm";
import MemberEditForm from "./organizer/MemberMaster/MemberEditForm";
import SupLayout from "components/Layout/supLayout";
import RouteMaster from "./organizer/RouteMaster";
import RouteList from "./organizer/RouteMaster/RouteList";
import RouteAddForm from "./organizer/RouteMaster/RouteAddForm";
import RouteEditForm from "./organizer/RouteMaster/RouteEditForm";
import AdminPlan from "./admin/Plan/index";
import AdminPlanList from "./admin/Plan/planList";
import AdminPlanForm from "./admin/Plan/planForm";
import AdminPlanEditForm from "./admin/Plan/planEditForm";
import RFDICard from "./organizer/RFDICard";
import RfdiCardForm from "./organizer/RFDICard/rfdiCardForm";
import RfdiCardList from "./organizer/RFDICard/rfdiCardList";
import RfdiCardFormEdit from "./organizer/RFDICard/rfdiCardFormEdit";
import RouteVehicle from "./organizer/RouteVehicle";
import RouteVehicleList from "./organizer/RouteVehicle/routeVehicleList";
import RouteVehicleAddForm from "./organizer/RouteVehicle/routeVehicleAddForm";
import RouteLocation from "./organizer/RouteLocation";
import RouteLocationAddForm from "./organizer/RouteLocation/RouteLocationAddForm";
import RouteLocationList from "./organizer/RouteLocation/RouteLocationList";
import RouteLocationEditForm from "./organizer/RouteLocation/RouteLocationEditForm";
import DriverLeave from "./organizer/DriverLeave";
import DriverLeaveList from "./organizer/DriverLeave/driverLeaveList";
import MemberLeave from "./organizer/MemberLeave";
import MemberLeaveList from "./organizer/MemberLeave/memberLeaveList";
import AdminRequest from "./admin/RequestDemo/index";
import AdminRequestList from "./admin/RequestDemo/requestList";
import AdminRequestForm from "./admin/RequestDemo/requestForm";
import AdminRequestEditForm from "./admin/RequestDemo/requestEditForm";
import AdminMessage from "./admin/MessageTable/index";
import AdminMessageList from "./admin/MessageTable/messageList";
import AdminMessageForm from "./admin/MessageTable/messageForm";
import AdminMessageView from "./admin/MessageTable/messageView";
import DepartmentAddForm from "./organizer/department/departmentAddForm";
import DepartmentEditForm from "./organizer/department/departmentEditForm";
import CardMemberMapping from "./organizer/CardMemberMapping";
import CardMemberMappingAddForm from "./organizer/CardMemberMapping/CardMemberMappingAddForm";
import CardMemberMappingList from "./organizer/CardMemberMapping/CardMemberMappingList";
import CardMemberMappingEditForm from "./organizer/CardMemberMapping/CardMemberMappingEditForm";
import AdminClient from "./admin/Client/index";
import AdminClientList from "./admin/Client/clientList";
import AdminExpirePlan from "./admin/planExpire/index";
import AdminExpirePlanListItem from "./admin/planExpire/expirePlanList";
import Area from "./organizer/Area";
import AreaAddForm from "./organizer/Area/areaAddForm";
import AreaMasterList from "./organizer/Area/areaMasterList";
import AreaEditForm from "./organizer/Area/areaEditForm";
import AreaDivision from "./organizer/AreaDivision";
import DivisionAddForm from "./organizer/AreaDivision/DivisionAddForm";
import DivisionList from "./organizer/AreaDivision/DivisionList";
import DivisionEditForm from "./organizer/AreaDivision/DivisionEditForm";
import ReqForCab from "./organizer/ReqForCab";
import ReqForCabList from "./organizer/ReqForCab/reqForCabList";
import MemberAssignment from "./organizer/MemberAssignment";
import AssignmentAddForm from "./organizer/MemberAssignment/AssignmentAddForm";
import AssignmentList from "./organizer/MemberAssignment/AssignmentList";
import ForgotPassword from "components/OrganizerLogin/ForgotPassword";
import ResetPassword from "components/OrganizerLogin/ResetPassword";
import FeesByArea from "./organizer/FeesByArea";
import FeesAreaAdd from "./organizer/FeesByArea/AddForm";
import FeesAreaEdit from "./organizer/FeesByArea/EditForm";
import FeesAreaList from "./organizer/FeesByArea/List";
import FeesByKm from "./organizer/FeesByKm";
import FeesKmAdd from "./organizer/FeesByKm/AddForm";
import FeesKmEdit from "./organizer/FeesByKm/EditForm";
import FeesKmList from "./organizer/FeesByKm/List";
import FeesConfiguration from "./organizer/FeesConfiguration/AddForm";
import FeesConfigurationEdit from "./organizer/FeesConfiguration/EditForm";
import PaymentSetting from "./organizer/PaymentSetting";
import FeesStructureEdit from "./organizer/FeesStructure/AddForm";
import AssignmentEditForm from "./organizer/MemberAssignment/AssignmentEditForm";
import RouteMember from "./organizer/RouteMember";
import RouteMemberAddForm from "./organizer/RouteMember/RouteMemberAddForm";
import Dashboard from "./admin/Dashboard/index";
import AdminDashBoardItem from "./admin/Dashboard/dashboardlistitem";
import DemoRequest from "./admin/Dashboard/demoRequest";
import DemoDone from "./admin/Dashboard/demoDone";
import DemoRescheduleList from "./admin/Dashboard/demoReschedule";
import DashBoardExpirePlan from "./admin/Dashboard/expireplanList";
import NewCustomerDash from "./admin/OrganizationDashboard/newCustomerDash";
import VerifiedCustomerDash from "./admin/OrganizationDashboard/verifiedCustomer";
import Errors from "./admin/ErrorHandling/index";
import UserManagement from "./admin/User Management/index";
import ErrorList from "./admin/ErrorHandling/errorList";
import ClientDetails from "./admin/Client/clientDetails";
import Usermngadd from "./admin/User Management/userAdd";
import UserMngList from "./admin/User Management/usermanList";
import UsermngUpdate from "./admin/User Management/userUpdate";
import DemoEditForm from "./admin/Dashboard/dashboardDemoEditForm";
import RouteMemberList from "./organizer/RouteMember/List";
import RouteMemberEditForm from "./organizer/RouteMember/EditForm";
import RouteOptimization from "./organizer/RouteOptimization/index";
import PlanRequests from "./admin/PlanRequests";
import PlanRequestList from "./admin/PlanRequests/List";
import PlanRequestEdit from "./admin/PlanRequests/EditForm";
import DriverInOutReportList from "./organizer/DriverInOutReport/reportList";
import MemberCountByLocationReportList from "./organizer/MemberCountByLocation/memberCountByLoc";
import MemberRouteDetailsReportList from "./organizer/MemberRouteDetailsReport/memberRouteDetails";
import DriverPickupDropCountReportList from "./organizer/DriverPickupDropCountReport/driverPickupDropCount";
import AdminClientReport from "./admin/report/clientReport";
import AdminClientDetailsReport from "./admin/report/clientDetailsReport";
import RequestPlanReport from "./admin/report/requestPlanReport";
import DriverComplaintReportList from "./organizer/DriverComplaintReport/driverComplaintReport";
import VehiclePucReport from "./organizer/VehiclePucReport/vehclePucReport";
import MemberPickUpDropLocationTimeList from "./organizer/MemberPickUpDropLocTime/memberPickUpDropLocTimeReport";
import PaymentConfiguration from "./organizer/PaymentConfiguration";
import PaymentConfigList from "./organizer/PaymentConfiguration/PaymentConfigList";
import PaymentConfigEdit from "./organizer/PaymentConfiguration/PaymentConfigEditForm";
import PaymentConfigAdd from "./organizer/PaymentConfiguration/PaymentConfigAddForm";
import Cofiguration from "./organizer/configuration/index";
import ConfigurationList from "./organizer/configuration/ConfigurationList";
import ClientConfig from "./admin/Client/clientConfiguration";
import ClientConfigurationEdit from "./admin/Client/clientConfEdit";
import PlnReqConfig from "./admin/PlanRequests/plnReqConfig";
import PlnConfigurationEdit from "./admin/PlanRequests/plnConfigEdit";
import AdminExpirePlanList from "./admin/PlanExpired/expirePlanList";
import AdminExpired from "./admin/PlanExpired/index";
import AdminExpiredPlanReport from "./admin/report/adminExpiredReport";
import DriverConfiguration from "./organizer/DriverConfiguration";
import AddForm from "./organizer/DriverConfiguration/AddForm";
import MasterList from "./organizer/DriverConfiguration/MasterList";
import EditForm from "./organizer/DriverConfiguration/EditForm";
import FeesByVehicle from "./organizer/FeesByVehicle";
import FeesByVehAddForm from "./organizer/FeesByVehicle/AddForm";
import FeesByVehMasterList from "./organizer/FeesByVehicle/MasterList";
import FeesByVehEditForm from "./organizer/FeesByVehicle/EditForm";
import MemberFeesList from "./organizer/MemberFees/list";
import MemberFees from "./organizer/MemberFees/index";
import MemberFeesEditForm from "./organizer/MemberFees/EditForm";
import { ConfigLocalType } from "components/SidebarMenu/sideBar";
import BasicMemberConfig from "./Basic/BasicMember";
import BasicMemberConfigAdd from "./Basic/BasicMember/AddForm";
import BasicMemberConfigList from "./Basic/BasicMember/List";
import BasicMemberConfigEdit from "./Basic/BasicMember/EditForm";
import Complaint from "./organizer/Complaint";
import ComplaintList from "./organizer/Complaint/List";
import Notification from "./organizer/Notification/index";
import NotificationList from "./organizer/Notification/notificationList";
import VehicleLatLog from "./organizer/VehicleLetLogMap/VehicleLetLogMap";
import DriverTracking from "./organizer/DriverTracking/index";
import DriverTrackingList from "./organizer/DriverTracking/drivertracking";
import ConfigurationEdit from "./organizer/configuration/configurationEdit";
import SosList from "components/Dashboard/SosList";
import TrialMap from "./organizer/TrialMap";
import DriverAttendanceReport from "./organizer/driverAttendanceReport/driverAttendanceReport";
import ClientEditForm from "./admin/Client/clientEditDetail";
import TabsComponent from "./organizer/MemberMaster/TabsComponent";
import VehicleTabsComponent from "./organizer/VehicleRegistrationMaster/VehicleRegistrationTabs";
import VehicleInsuranceMasterAddForm from "./organizer/VehicleInsuranceMaster/VehicleInsuranceMasterAddForm";
import { ConfigProvider } from "Context/ConfigContext";
import Leave from "../app/organizer/Leave";
import { AreaEditProvider } from "Context/AreaEditContext";
import LocationMasterEditForm from "./organizer/locationMaster/locationMasterEditForm";
import TrialMapRoute from "./organizer/TrialMapRoute";
import FeesConfigMain from "./organizer/FeesConfiguration/main";
import FeesConfigList from "./organizer/FeesConfiguration/List";
import CommonMap from "./organizer/commonMap";
import Configuration from "./organizer/configuration/index";
import ProfileEdit from "components/ProfileEdit/ProfileList";
import AreaDetailsReportList from "./organizer/AreaDetailsReport/report";
import VehicleInsuranceReport from "./organizer/VehicleInsuranceExpiredReport/report";
import WaitReport from "./organizer/WaitReport/report";
import BasicMemberWaitList from "./Basic/BasicMemberWaitList/basicMemberWaitList";
import SosListReport from "./organizer/SosReport/reportList";
import MemberDetails from "./organizer/MemberDashboardDetails";
import MemberDetailsList from "./organizer/MemberDashboardDetails/memberDetailsList";
import Attendant from "./organizer/Attendant";
import AttendantMasterList from "./organizer/Attendant/attendantMasterList";
import AttendantMasterAddForm from "./organizer/Attendant/attendantMasterAddForm";
import AttendantMasterEditForm from "./organizer/Attendant/attendantMasterEditForm";
import PaymentTabs from "./organizer/Payment";
import MemberDetailsFees from "./organizer/PaymentManagement/detailsList";
import PaymentMemberDetails from "./organizer/MemberFees/EditForm";
import DriverSpeedReport from "./organizer/SpeedReport/reportList";
import InvoiceTemplate from "components/InvoiceTemplate";
import PaymentReport from "./organizer/PaymentReport/index";
import Promocode from "./admin/promocode/index";
import Promocodelist from "./admin/promocode/clientList";
import InvGen from "./organizer/MemberFees/invGen";
import ReNewUi from "../components/RenewUi/index";
import RenewPlanRequest from "./admin/RenewPlanRequest";
import RenewPlanRequestList from "./admin/RenewPlanRequest/List";
import BasicPayment from "./Basic/BasicPayment";
import BasicFeesConfig from "./Basic/BasicPayment/AddForm";
import FeesConfigListBasic from "./Basic/BasicPayment/list";
import BasicFeesConfigEdit from "./Basic/BasicPayment/EditForm";
import BasicMemberFee from "./Basic/BasicPayment/BasicMemberFee";
import BasicMemberFeeList from "./Basic/BasicPayment/BasicMemberFeeList";
import PlanRenewEditForm from "./admin/RenewPlanRequest/EditForm";
import GetPassTabs from "./organizer/membergetpass";
import MembergetPassReport from "./organizer/memberGetPassReport/memberGetPassReport";
import BasicMemberFeeDetailsList from "./Basic/BasicFeeDetails/BasicMemberFeeDetailsList";
import BasicVehicle from "./Basic/BasicVehicleMaster";
import BasicVehicleList from "./Basic/BasicVehicleMaster/List";
import BasicVehicleAdd from "./Basic/BasicVehicleMaster/AddForm";
import BasicVehicleEdit from "./Basic/BasicVehicleMaster/EditForm";
import BasicMemberFeeEdit from "./Basic/BasicPayment/BasicMemberFeeEdit";
import Profileedit from "components/ProfileEdit/ProfileEdit";
import Profile from "components/ProfileEdit/index";
import BasicMemberreport from "./organizer/BasicMemberReport/basicMemberreport";
import BasicMemberWaitReport from "./organizer/BasicMemberWaitReport/report";
import BasicPaymentReport from "./organizer/BasicMemberPaymentReport/report";
import DriverPickupOffDropOff from "./organizer/driverPickup0ffDropoffReport/DriverPickupOffDropOff";
import MemberPickupOffDropOff from "./organizer/memberPickup0ffDropoffReport/MemberPickupOffDropOff";
import BasicMemberPickupOffDropOff from "./organizer/BasicMemberPickupOffDropOff/BasicMemberPickupOffDropOff";
import BasicDriverPickupOffDropOffReport from "./organizer/BasicDriverPickupOffDropOffReport/BasicDriverPickupOffDropOffReport";
import BasicLocationMaster from "./Basic/BasicLocationMaster";
import BasicLocationMasterAdd from "./Basic/BasicLocationMaster/form";
import BasicLocationMasterList from "./Basic/BasicLocationMaster/list";
import BasicLocationMasterEdit from "./Basic/BasicLocationMaster/edit";
import ErrorLogsForDriver from "./admin/ErrorLogsForDriver/index";
import ErrorLogsListForDriver from "./admin/ErrorLogsForDriver/errorList";
import ErrorLogsForMember from "./admin/ErrorLogsForMember/index";
import ErrorLogsListForMember from "./admin/ErrorLogsForMember/errorList";
import DriverOnOff from "./organizer/driverOnOffReport/DriverOnOff";

type Props = {};

const AppRoutes = (props: Props) => {
  const location = useLocation();

  const role = localStorage.getItem("role");
  const x = localStorage.getItem("config") as string;
  const config: ConfigLocalType = JSON.parse(x);

  return (
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<OrganizerLogin />} />
      <Route path="/resetPass/:id" element={<ResetPassword />} />
      <Route path="/forgotPass" element={<ForgotPassword />} />

      <Route
        path="/"
        // action={() => nav("/dashboard")}
        element={<RequireAuth />}
      >
        {role === "SUP" && (
          <Route path="/" element={<SupLayout />}>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<AdminDashBoardItem />} />
              <Route path="demoRequest" element={<DemoRequest />} />
              <Route path="demodone" element={<DemoDone />} />
              <Route
                path="demoRequest/demoEditForm"
                element={<DemoEditForm />}
              />
              <Route
                path="demoReschedule/demoEditForm"
                element={<DemoEditForm />}
              />
              <Route path="demoReschedule" element={<DemoRescheduleList />} />
              <Route path="expireplan" element={<DashBoardExpirePlan />} />
              <Route path="newcustomer" element={<NewCustomerDash />} />
              <Route path="verifycustomer" element={<VerifiedCustomerDash />} />
            </Route>
            <Route path="adminPlan" element={<AdminPlan />}>
              <Route path="" element={<AdminPlanList />} />
              <Route path="adminPlanEditForm" element={<AdminPlanEditForm />} />
              <Route path="adminPlanForm" element={<AdminPlanForm />} />
            </Route>
            <Route path="adminRequest" element={<AdminRequest />}>
              <Route path="" element={<AdminRequestList />} />
              <Route
                path="requestEditForm"
                element={<AdminRequestEditForm />}
              />
              <Route path="requestForm" element={<AdminRequestForm />} />
            </Route>
            <Route path="adminExpirePlan" element={<AdminExpirePlan />}>
              <Route path="" element={<AdminExpirePlanListItem />} />
            </Route>
            <Route path="notification" element={<Notification />}>
              <Route path="" element={<AdminExpirePlanListItem />} />
            </Route>
            <Route path="promocode" element={<Promocode />}>
              <Route path="" element={<Promocodelist />} />
            </Route>
            <Route path="adminExpiredPlan" element={<AdminExpired />}>
              <Route path="" element={<AdminExpirePlanList />} />
            </Route>
            <Route path="errorhandling" element={<Errors />}>
              <Route path="" element={<ErrorList />} />
            </Route>
            <Route path="errorLogsForDriver" element={<ErrorLogsForDriver />}>
              <Route path="" element={<ErrorLogsListForDriver />} />
            </Route>
            <Route path="errorLogsForMember" element={<ErrorLogsForMember />}>
              <Route path="" element={<ErrorLogsListForMember />} />
            </Route>
            <Route path="adminClient" element={<AdminClient />}>
              <Route path="" element={<AdminClientList />} />
              <Route path="clientDetails" element={<ClientDetails />} />
              <Route path="clientEdit" element={<ClientEditForm />} />
              <Route path="configurationDetails" element={<ClientConfig />} />
              <Route
                path="configurationDetails/ClientConfigurationEdit"
                element={<ClientConfigurationEdit />}
              />
            </Route>
            <Route path="usermanagement" element={<UserManagement />}>
              <Route path="" element={<UserMngList />} />
              <Route path="addUserMng" element={<Usermngadd />} />
              <Route path="userEdit" element={<UsermngUpdate />} />
            </Route>
            <Route path="adminMessage" element={<AdminMessage />}>
              <Route path="" element={<AdminMessageList />} />
              <Route path="messageView" element={<AdminMessageView />} />
              <Route path="messageForm" element={<AdminMessageForm />} />
            </Route>
            <Route path="planRequests" element={<PlanRequests />}>
              <Route path="" element={<PlanRequestList />} />
              <Route path="editForm" element={<PlanRequestEdit />} />
              <Route path="configurationDetails" element={<PlnReqConfig />} />
              <Route
                path="configurationDetails/plnConfigurationEdit"
                element={<PlnConfigurationEdit />}
              />
            </Route>

            <Route path="renewPlanRequest" element={<RenewPlanRequest />}>
              <Route path="" element={<RenewPlanRequestList />} />
              <Route path="planrenew" element={<PlanRenewEditForm />} />
            </Route>

            <Route path="clientReport" element={<AdminClientReport />} />
            <Route
              path="clientDetailsReport"
              element={<AdminClientDetailsReport />}
            />
            <Route path="reqPlnReport" element={<RequestPlanReport />} />
            <Route
              path="expiredPlanReport"
              element={<AdminExpiredPlanReport />}
            />
            <Route path="configuration" element={<Configuration />}>
              <Route path="" element={<ConfigurationList />} />
              <Route path="configurationEdit" element={<ConfigurationEdit />} />
            </Route>
          </Route>
        )}
        {role === "ORG" &&
          (config?.planType === "ADV" || config?.planType === "PRM") && (
            <Route
              path=""
              element={
                <ConfigProvider>
                  {" "}
                  <Layout />
                </ConfigProvider>
              }
            >
              <Route path="profile" element={<Profile />}>
                <Route path="" element={<ProfileList />} />
                <Route path="profileEdit" element={<Profileedit />} />
              </Route>
              <Route path="MemberGetPass" element={<GetPassTabs />} />
              <Route path="ReNewUi" element={<ReNewUi />} />
              <Route path="dashboard" element={<Dashboard />}>
                <Route path="SosList" element={<SosList />} />
                <Route path="" element={<ComingSoon />} />
                <Route path="vehicleLatLog" element={<VehicleLatLog />} />
              </Route>
              <Route path="category" element={<Category />}>
                <Route path="" element={<CategoryMasterList />} />
                <Route path="editForm" element={<CategoryEditForm />} />
                <Route path="addForm" element={<CategoryAddForm />} />
                <Route />
              </Route>
              <Route path="trialMapRoute" element={<CommonMap />} />
              <Route path="vehicleMaster" element={<VehicleMaster />}>
                <Route path="" element={<VehicleMasterList />} />
                <Route
                  path="vehicleMasterEdit"
                  element={<VehicleMasterEdit />}
                />
                <Route
                  path="vehicleMasterForm"
                  element={<VehicleMasterForm />}
                />
              </Route>
              <Route path="departmentMaster" element={<Department />}>
                <Route path="" element={<DepartmentMasterList />} />
                <Route path="addForm" element={<DepartmentAddForm />} />
                <Route path="editForm" element={<DepartmentEditForm />} />
              </Route>
              <Route path="shiftTypeMaster" element={<ShiftTypeMaster />}>
                <Route path="" element={<ShiftTypeMasterList />} />
                <Route path="addForm" element={<ShiftTypeAddForm />} />
                <Route path="editForm" element={<ShiftTypeEditForm />} />
              </Route>
              {/* <Route path="holidayMaster" element={<HolidayMaster />}>
                <Route path="" element={<HolidayMasterList />} />
                <Route
                  path="holidayMasterEditForm"
                  element={<HolidayMasterEditForm />}
                />
                <Route
                  path="holidayMasterForm"
                  element={<HolidayMasterForm />}
                />
              </Route> */}
              <Route
                path="vehicleRegistration"
                element={<VehicleRegistration />}
              >
                <Route path="" element={<VehicleRegMasterList />} />
                {/* <Route path="addForm" element={<VehicleRegAddForm />} /> */}
                <Route path="addForm" element={<VehicleTabsComponent />}>
                  <Route path="pucAdd" element={<VehiclePucMasterForm />} />
                  <Route
                    path="incAdd"
                    element={<VehicleInsuranceMasterAddForm />}
                  />
                </Route>
                <Route path="editForm" element={<VehicleRegEditForm />}>
                  <Route path="editPuc" element={<VehiclePucEditForm />} />
                  <Route path="editInc" element={<InsuranceEdit />} />
                </Route>
              </Route>
              <Route path="driver" element={<DriverMaster />}>
                <Route path="" element={<DriverList />} />
                <Route path="driverForm" element={<DriverForm />} />
                <Route path="driverEdit" element={<DriverEdit />} />
              </Route>
              <Route path="attendant" element={<Attendant />}>
                <Route path="" element={<AttendantMasterList />} />
                <Route
                  path="attendantForm"
                  element={<AttendantMasterAddForm />}
                />
                <Route
                  path="attendantEdit"
                  element={<AttendantMasterEditForm />}
                />
              </Route>
              <Route path="typeMaster" element={<TypeMaster />}>
                <Route path="" element={<TypeMasterList />} />
                <Route
                  path="typeMasterEditForm"
                  element={<TypeMasterEditForm />}
                />
                <Route path="typeMasterForm" element={<TypeMasterForm />} />
              </Route>
              {/* <Route path="locationMaster" element={<Location />}>
                <Route path="" element={<LocationMasterList />} />
                <Route path="addForm" element={<LocationForm />} />
                <Route path="editForm" element={<LocationEdit />} />
              </Route> */}
              <Route path="memberMaster" element={<MemberMaster />}>
                <Route path="" element={<MemberMasterList />} />
                {/* <Route path="assignmentEdit" element={<AssignmentEditForm />} /> */}
                <Route path="addForm" element={<TabsComponent />}>
                  <Route path="assignmentAdd" element={<AssignmentAddForm />} />
                  <Route path="routeAdd" element={<RouteMemberAddForm />} />
                  <Route
                    path="cardAdd"
                    element={<CardMemberMappingAddForm />}
                  />
                </Route>
                <Route path="editForm" element={<MemberEditForm />} />
              </Route>
              <Route path="routeMaster" element={<RouteMaster />}>
                <Route path="" element={<RouteList />} />
                <Route path="addForm" element={<RouteAddForm />}>
                  <Route
                    path="routeVehicleAdd"
                    element={<RouteVehicleAddForm />}
                  />
                  <Route
                    path="routeLocationAdd"
                    element={<RouteLocationAddForm />}
                  />
                </Route>
                <Route path="editForm" element={<RouteEditForm />}>
                  <Route path="editLoc" element={<RouteLocationEditForm />} />
                </Route>
              </Route>
              <Route path="rfdiCard" element={<RFDICard />}>
                <Route path="" element={<RfdiCardList />} />
                <Route path="addForm" element={<RfdiCardForm />} />
                <Route path="editForm" element={<RfdiCardFormEdit />} />
              </Route>
              <Route path="cardMemberMapping" element={<CardMemberMapping />}>
                {/* <Route path="" element={<CardMemberMappingList />} /> */}
                <Route path="addForm" element={<CardMemberMappingAddForm />} />
                <Route
                  path="editForm"
                  element={<CardMemberMappingEditForm />}
                />
              </Route>
              {/* <Route path="areaDivision" element={<AreaDivision />}>
                <Route path="" element={<DivisionList />} />
                <Route path="addForm" element={<DivisionAddForm />} />
                <Route path="editForm" element={<DivisionEditForm />} />
              </Route> */}
              <Route
                path="areaMaster"
                element={
                  <AreaEditProvider>
                    <Area />
                  </AreaEditProvider>
                }
              >
                <Route path="" element={<AreaMasterList />} />
                <Route path="addForm" element={<AreaAddForm />} />
                <Route path="editForm" element={<AreaEditForm />}>
                  <Route path="divEdit" element={<DivisionEditForm />} />
                  <Route path="locEdit" element={<LocationMasterEditForm />} />
                </Route>
              </Route>
              <Route path="memberAssign" element={<MemberAssignment />}>
                <Route path="" element={<AssignmentList />} />
                <Route path="addForm" element={<AssignmentAddForm />} />
                {/* <Route path="editForm" element={<AssignmentEditForm />} /> */}
              </Route>
              <Route path="routeMember" element={<RouteMember />}>
                <Route path="" element={<RouteMemberList />} />
                <Route path="addForm" element={<RouteMemberAddForm />} />
                {/* <Route path="editForm" element={<RouteMemberEditForm />} /> */}
              </Route>
              <Route path="leave" element={<DriverLeave />}>
                {/* <Route path="" element={<DriverLeaveList />} /> */}
              </Route>
              {/* <Route path="detailFees" element={<MemberDetailsFees />} /> */}
              <Route path="invoice" element={<InvoiceTemplate />} />
              <Route path="invoiceGen" element={<InvGen />} />
              <Route path="detailFees" element={<PaymentMemberDetails />} />
              <Route path="paymentManagement" element={<PaymentTabs />} />
              <Route path="LeaveTabs" element={<Leave />} />
              <Route path="reqForCab" element={<ReqForCab />}>
                <Route path="" element={<ReqForCabList />} />
              </Route>
              <Route path="feesArea" element={<FeesByArea />}>
                <Route path="" element={<FeesAreaList />} />
                <Route path="addForm" element={<FeesAreaAdd />} />
                <Route path="editForm" element={<FeesAreaEdit />} />
              </Route>
              <Route path="feesKm" element={<FeesByKm />}>
                <Route path="" element={<FeesKmList />} />
                <Route path="addForm" element={<FeesKmAdd />} />
                <Route path="editForm" element={<FeesKmEdit />} />
              </Route>
              <Route path="paymentSetting" element={<PaymentSetting />} />
              {/* <Route path="feesStructure" element={<FeesStructure />} /> */}
              <Route path="routeOptimization" element={<RouteOptimization />} />
              <Route path="report" element={<DriverInOutReportList />} />
              <Route path="sosReport" element={<SosListReport />} />
              <Route
                path="memGetPassReport"
                element={<MembergetPassReport />}
              />
              <Route path="speedReports" element={<DriverSpeedReport />} />
              <Route path="transportationFeesMain" element={<FeesConfigMain />}>
                <Route path="" element={<FeesConfigList />} />
                <Route path="feesSection" element={<FeesConfiguration />}>
                  <Route path="structureAdd" element={<FeesStructureEdit />} />
                  <Route path="kmAddForm" element={<FeesKmAdd />} />
                  <Route path="kmEditForm" element={<FeesKmEdit />} />
                  <Route path="kmList" element={<FeesKmList />} />
                  <Route path="areaAddForm" element={<FeesAreaAdd />} />
                  <Route path="areaEditForm" element={<FeesAreaEdit />} />
                  <Route path="areaList" element={<FeesAreaList />} />
                  <Route path="vehicleList" element={<FeesByVehMasterList />} />
                  <Route path="vehicleAddForm" element={<FeesByVehAddForm />} />
                  <Route
                    path="vehicleEditForm"
                    element={<FeesByVehEditForm />}
                  />
                </Route>
                <Route
                  path="feesSectionEdit"
                  element={<FeesConfigurationEdit />}
                />
              </Route>
              <Route
                path="memberCountByLoc"
                element={<MemberCountByLocationReportList />}
              />
              <Route
                path="routeDetailsReport"
                element={<MemberRouteDetailsReportList />}
              />
              <Route
                path="driverPickupDropCount"
                element={<DriverPickupDropCountReportList />}
              />
              <Route
                path="areaDetailsReport"
                element={<AreaDetailsReportList />}
              />
              <Route
                path="driverComplaint"
                element={<DriverComplaintReportList />}
              />
              <Route
                path="driverLeaveReport"
                element={<DriverAttendanceReport />}
              />
              <Route path="vehiclePucReport" element={<VehiclePucReport />} />
              <Route
                path="vehicleInsuranceReport"
                element={<VehicleInsuranceReport />}
              />
              <Route path="waitReport" element={<WaitReport />} />
              <Route path="paymentReport" element={<PaymentReport />} />
              <Route
                path="driverPickupOffDropOffReport"
                element={<DriverPickupOffDropOff />}
              />
              <Route
                path="memberPickupOffDropOffReport"
                element={<MemberPickupOffDropOff />}
              />
              <Route path="driverOnOffReport" element={<DriverOnOff />} />
              <Route
                path="memberPickUpDropLocationTime"
                element={<MemberPickUpDropLocationTimeList />}
              />
              <Route path="configuration" element={<Cofiguration />}>
                <Route path="" element={<ConfigurationList />} />
                <Route
                  path="configurationEdit"
                  element={<ConfigurationEdit />}
                />
              </Route>
              <Route path="paymentConfig" element={<PaymentConfiguration />}>
                <Route path="" element={<PaymentConfigList />} />
                <Route path="editForm" element={<PaymentConfigEdit />} />
                <Route path="addForm" element={<PaymentConfigAdd />} />
              </Route>
              <Route path="driverConfig" element={<DriverConfiguration />}>
                <Route path="" element={<MasterList />} />
                <Route path="addForm" element={<AddForm />} />
                <Route path="editForm" element={<EditForm />} />
              </Route>
              <Route path="feesByVehicle" element={<FeesByVehicle />}>
                <Route path="" element={<FeesByVehMasterList />} />
                <Route path="addForm" element={<FeesByVehAddForm />} />
                <Route path="editForm" element={<FeesByVehEditForm />} />
              </Route>
              <Route path="complaints" element={<Complaint />}>
                <Route path="" element={<ComplaintList />} />
              </Route>
              <Route path="notification" element={<Notification />}>
                <Route path="" element={<NotificationList />} />
              </Route>
              {/* <Route path="memberFees" element={<MemberFees />}>
                <Route path="" element={<MemberFeesList />} />
                <Route path="editForm" element={<MemberFeesEditForm />} />
              </Route> */}
              <Route path="drivertracking" element={<DriverTracking />}>
                <Route path="" element={<DriverTrackingList />} />
              </Route>
              <Route path="memberDetails" element={<MemberDetails />}>
                <Route path="" element={<MemberDetailsList />} />
              </Route>
              <Route path="mapTrial" element={<TrialMap />}></Route>
            </Route>
          )}

        {role === "ORG" && config.planType === "BAC" && (
          <Route path="" element={<Layout />}>
            <Route path="profile" element={<Profile />}>
              <Route path="" element={<ProfileList />} />
            </Route>
            <Route path="memberLeaves" element={<MemberLeave />}>
              <Route path="" element={<MemberLeaveList />} />
            </Route>
            <Route path="ReNewUi" element={<ReNewUi />} />
            <Route path="dashboard" element={<></>} />
            <Route path="basiclocationmaster" element={<BasicLocationMaster />}>
              <Route index element={<BasicLocationMasterList />} />
              <Route path="add" element={<BasicLocationMasterAdd />} />
              <Route path="edit" element={<BasicLocationMasterEdit />} />
            </Route>
            <Route path="basicmember" element={<BasicMemberConfig />}>
              <Route index element={<BasicMemberConfigList />} />
              <Route path="add" element={<BasicMemberConfigAdd />} />
              <Route path="edit" element={<BasicMemberConfigEdit />} />
            </Route>
            <Route path="configuration" element={<Cofiguration />}>
              <Route index element={<ConfigurationList type="basic" />} />
              <Route path="configurationEdit" element={<ConfigurationEdit />} />
            </Route>
            <Route path="shiftTypeMaster" element={<ShiftTypeMaster />}>
              <Route path="" element={<ShiftTypeMasterList />} />
              <Route path="editForm" element={<ShiftTypeEditForm />} />
            </Route>
            <Route path="basicvehicle" element={<BasicVehicle />}>
              <Route index element={<BasicVehicleList />} />
              <Route path="add" element={<BasicVehicleAdd />} />
              <Route path="edit" element={<BasicVehicleEdit />} />
            </Route>

            <Route path="basicMemberWaitList" element={<BasicMemberWaitList />}>
              {/* <Route path="" element={<ShiftTypeMasterList />} />
              <Route path="editForm" element={<ShiftTypeEditForm />} /> */}
            </Route>
            <Route path="vehicleMaster" element={<VehicleMaster />}>
              <Route path="" element={<VehicleMasterList />} />
              <Route path="vehicleMasterEdit" element={<VehicleMasterEdit />} />
              <Route path="vehicleMasterForm" element={<VehicleMasterForm />} />
            </Route>
            <Route path="vehicleRegistration" element={<VehicleRegistration />}>
              <Route path="" element={<VehicleRegMasterList />} />
              <Route path="addForm" element={<VehicleRegAddForm />} />
              <Route path="editForm" element={<VehicleRegEditForm />} />
            </Route>
            <Route path="driver" element={<DriverMaster />}>
              <Route path="" element={<DriverList />} />
              <Route path="driverForm" element={<DriverForm />} />
              <Route path="driverEdit" element={<DriverEdit />} />
            </Route>

            <Route path="notification" element={<Notification />}>
              <Route path="" element={<NotificationList />} />
            </Route>
            <Route path="paymentMasterBasic" element={<BasicPayment />}>
              <Route index element={<FeesConfigListBasic />} />
              <Route path="add" element={<BasicFeesConfig />} />
              <Route path="edit" element={<BasicFeesConfigEdit />} />
            </Route>
            <Route path="basicMemberFeeList" element={<BasicMemberFeeList />} />
            <Route path="basicMemberFee" element={<BasicMemberFee />} />
            <Route path="memberDetails" element={<BasicMemberreport />} />
            <Route
              path="memberWaitReport"
              element={<BasicMemberWaitReport />}
            />
            <Route path="paymentReport" element={<BasicPaymentReport />} />
            <Route
              path="basicMemberFeeDetailsList"
              element={<BasicMemberFeeDetailsList />}
            />
            <Route path="BasicMemberFeeEdit" element={<BasicMemberFeeEdit />} />
            <Route
              path="basicMemberPickupOffDropOffReport"
              element={<BasicMemberPickupOffDropOff />}
            />
            <Route
              path="basicDriverPickupOffDropOffReport"
              element={<BasicDriverPickupOffDropOffReport />}
            />
          </Route>
        )}

        {role === "SEL" && (
          <Route path="/" element={<SupLayout />}>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<AdminDashBoardItem />} />
              <Route path="demoRequest" element={<DemoRequest />} />
              <Route path="demodone" element={<DemoDone />} />
              <Route
                path="demoRequest/demoEditForm"
                element={<DemoEditForm />}
              />
              <Route
                path="demoReschedule/demoEditForm"
                element={<DemoEditForm />}
              />
              <Route path="demoReschedule" element={<DemoRescheduleList />} />
              <Route path="expireplan" element={<DashBoardExpirePlan />} />
              <Route path="newcustomer" element={<NewCustomerDash />} />
              <Route path="verifycustomer" element={<VerifiedCustomerDash />} />
            </Route>
            <Route path="adminRequest" element={<AdminRequest />}>
              <Route path="" element={<AdminRequestList />} />
              <Route
                path="requestEditForm"
                element={<AdminRequestEditForm />}
              />
              <Route path="requestForm" element={<AdminRequestForm />} />
            </Route>
            <Route path="adminClient" element={<AdminClient />}>
              <Route path="" element={<AdminClientList />} />
              <Route path="clientDetails" element={<ClientDetails />} />
              <Route path="clientEdit" element={<ClientEditForm />} />
              <Route path="configurationDetails" element={<ClientConfig />} />
              <Route
                path="configurationDetails/ClientConfigurationEdit"
                element={<ClientConfigurationEdit />}
              />
            </Route>
            <Route path="planRequests" element={<PlanRequests />}>
              <Route path="" element={<PlanRequestList />} />
              <Route path="editForm" element={<PlanRequestEdit />} />
              <Route path="configurationDetails" element={<PlnReqConfig />} />
              <Route
                path="configurationDetails/plnConfigurationEdit"
                element={<PlnConfigurationEdit />}
              />
            </Route>
            <Route path="adminExpiredPlan" element={<AdminExpired />}>
              <Route path="" element={<AdminExpirePlanList />} />
            </Route>
            <Route path="adminExpirePlan" element={<AdminExpirePlan />}>
              <Route path="" element={<AdminExpirePlanListItem />} />
            </Route>
            <Route
              path="expiredPlanReport"
              element={<AdminExpiredPlanReport />}
            />
          </Route>
        )}
        {role === "ADM" && (
          <Route path="/" element={<SupLayout />}>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<AdminDashBoardItem />} />
              <Route path="demoRequest" element={<DemoRequest />} />
              <Route path="demodone" element={<DemoDone />} />
              <Route
                path="demoRequest/demoEditForm"
                element={<DemoEditForm />}
              />
              <Route
                path="demoReschedule/demoEditForm"
                element={<DemoEditForm />}
              />
              <Route path="demoReschedule" element={<DemoRescheduleList />} />
              <Route path="expireplan" element={<DashBoardExpirePlan />} />
              <Route path="newcustomer" element={<NewCustomerDash />} />
              <Route path="verifycustomer" element={<VerifiedCustomerDash />} />
            </Route>
            <Route path="adminRequest" element={<AdminRequest />}>
              <Route path="" element={<AdminRequestList />} />
              <Route
                path="requestEditForm"
                element={<AdminRequestEditForm />}
              />
              <Route path="requestForm" element={<AdminRequestForm />} />
            </Route>
            <Route path="errorhandling" element={<Errors />}>
              <Route path="" element={<ErrorList />} />
            </Route>
            <Route path="adminExpirePlan" element={<AdminExpirePlan />}>
              <Route path="" element={<AdminExpirePlanListItem />} />
            </Route>
            <Route path="adminClient" element={<AdminClient />}>
              <Route path="" element={<AdminClientList />} />
              <Route path="clientDetails" element={<ClientDetails />} />
              <Route path="clientEdit" element={<ClientEditForm />} />
              <Route path="configurationDetails" element={<ClientConfig />} />
              <Route
                path="configurationDetails/ClientConfigurationEdit"
                element={<ClientConfigurationEdit />}
              />
            </Route>
            <Route path="adminExpiredPlan" element={<AdminExpired />}>
              <Route path="" element={<AdminExpirePlanList />} />
            </Route>
            <Route
              path="expiredPlanReport"
              element={<AdminExpiredPlanReport />}
            />
          </Route>
        )}
      </Route>
      <Route
        path="*"
        element={<Navigate to="/" state={{ from: location }} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
