import React from "react";
import { axiosAuth } from "axiosInstance";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoading } from "Context/LoadingContext";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import toastValidation, {
  errorToast,
  successToast,
} from "constants/toastConfig";
import { Link, useNavigate } from "react-router-dom";

type Props = {};

const ForgotPassword = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const formik = useFormik({
    validateOnMount: true,

    initialValues: {
      username: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Please enter your username"),
    }),
    onSubmit: ({ username }) => {
      const formData = {
        username,
      };
      setLoading(true);

      axiosAuth
        .post("/master/getOneByUsername", formData)
        .then(() => {
          successToast("An email has been sent to your address...");
          setTimeout(() => {
            nav("/login");
            setLoading(false);
          }, 1500);
        })
        .catch((error: any) => {
          if (error.response.status === 404) {
            errorToast("Email is not registered with us yet!");
          }
          if (error.response.status) {
            errorToast("Server error!");
          }
          setLoading(false);
        });
    },
  });
  return (
    <>
      <section className="login" style={{ height: "100vh" }}>
        <div className="loginBox">
          <Link to="/" className="logowrap mb-4">
            <Logo height={80} width={200} />
          </Link>
          <div className="login-text">Forgot Password</div>
          <div className="welcome-text">Welcome to the DropikMe</div>
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="username">
              <span className="sign-up-placeholder">Username*</span>

              <input
                className={`form-control ${
                  formik.errors.username && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="username"
                name="username"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.username}
                placeholder="Enter Username"
              />
            </label>
            <label className="text-center">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => toastValidation(formik.errors)}
              >
                Submit
              </button>
            </label>
          </form>
          <Link to={"/login"}>Back to Login</Link>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
