import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from "react";

type FeesConfigContextType = {
  feesConfigType: string;
  setFeesConfigType: Dispatch<SetStateAction<string>>;
};

type Props = {
  children: ReactNode;
};

const FeesConfigContext = createContext<FeesConfigContextType>({
  feesConfigType: "",
  setFeesConfigType: () => {},
});

function FeesConfigProvider({ children }: Props) {
  const [feesConfigType, setFeesConfigType] = useState("");
  const value = { feesConfigType, setFeesConfigType };
  return (
    <FeesConfigContext.Provider value={value}>
      {children}
    </FeesConfigContext.Provider>
  );
}

function useFeesConfig() {
  const context = useContext(FeesConfigContext);
  if (!context) {
    throw new Error("useLoading must be used within FeesConfigProvider");
  }
  return context;
}

export { FeesConfigProvider, useFeesConfig };
