import React, { memo } from "react";
import LoginHistoryLayout from "./LoginHistoryLayout";
type Props = {};

const LoginHistory: React.FC<{}> = (props: Props) => {
  return (
    <>
      <div className="sup_heading">
        <span className="mx-2">LoginHistory</span>
      </div>
      <LoginHistoryLayout />
    </>
  );
};
export default memo(LoginHistory);
