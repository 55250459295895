import React, { useEffect, useState, useCallback } from "react";
import {
  GoogleMap,
  Polyline,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import axiosInstance from "axiosInstance";
import NotFound from "components/NotFound";
import { containerStyle } from "types/driverTracking";
import { libraries } from "../../../constants/Constants";
import mapHome from "../../../assets/images/home-map.png";
import mapBus from "../../../assets/images/mapBus.png";
import mapSchool from "../../../assets/images/map_school.jpg";
import { Data, PickDropType } from "types/alltrackingTypes";
import { VehicleMasterDataType } from "types/vehicleMasterType";
import ShiftTypeMasterType from "../../../types/shiftType.types";
import VehicleRegType from "types/VehicleRegTypes";
import { useLoading } from "Context/LoadingContext";
import * as Yup from "yup";
import { useFormik } from "formik";
import SubmitBtn from "components/Buttons/Submit";
import toastValidation, { warnToast } from "constants/toastConfig";

const DriverTrackingList: React.FC = () => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [separatedLatLng, setSetseparatedLatLng] = useState<Data[]>(
    [] as Data[]
  );
  const [pickUpDropMarkers, setPickUpDropMarkers] = useState<JSX.Element[]>([]);
  const [shiftId, setShiftId] = useState<string>("");
  const [shift, setShift] = useState<ShiftTypeMasterType[]>([]);
  const [shiftCount, setShiftCount] = useState<boolean>();
  const [vehTyp, setVehTyp] = useState<VehicleMasterDataType[]>();
  const [vehiclTypeId, setVehicleTypeId] = useState<string>("");
  const [licensePlateNumbers, setLicensePlateNumbers] =
    useState<VehicleRegType[]>();
  const [vehRegId, setVehRegId] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { setLoading } = useLoading();
  const [border, setBorder] = useState<boolean>(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries: libraries as (
      | "places"
      | "drawing"
      | "geometry"
      | "localContext"
      | "visualization"
    )[],
  });
  const loadShifts = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropdown/getShiftType`);
      const shifts = res.data.shiftTypes;
      const shiftCount = res.data.shiftTypes.length === 1;
      setShiftCount(shiftCount);
      if (shiftCount) {
        setShiftId(res.data.shiftTypes[0]._id);
      }
      setShift(shifts);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadVehicleTypes = useCallback(async () => {
    try {
      const res = await axiosInstance.get("/dropDown/getVehicleType");
      setVehTyp(res.data.vehicleTypes);
    } catch (error) {}
  }, []);

  const loadLicensePlateNo = useCallback(async (vehTypeId: string) => {
    setVehicleTypeId(vehTypeId);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getLicensePlateNo?vehicleTypeId=${vehTypeId}`
      );
      setLicensePlateNumbers(res.data.result.VehicleLicensePlate);
    } catch (error) {}
  }, []);

  useEffect(() => {
    loadShifts();
    loadVehicleTypes();
  }, [loadShifts, loadVehicleTypes]);

  const formik = useFormik({
    initialValues: {
      date: "",
    },
    validationSchema: Yup.object().shape({
      date: Yup.string().required("Date is Required"),
    }),

    onSubmit: async () => {
      if (vehRegId === "" || shiftId === "" || vehiclTypeId === "") {
        return;
      }
      setIsSubmitted(true);
    },
  });
  const fetchPickUpDropLocation = async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getAllPickUpDropLatLong?vehicleId=${vehRegId}&shiftTypeId=${shiftId}`
      );
      const data: PickDropType[] = res.data.pickupdroplatlong;
      const routeIds = separatedLatLng.map((vehicle) => vehicle.routeId);
      const filteredData = data.filter((item) => routeIds.includes(item._id));
      const markers = filteredData.map((item, index) => (
        <Marker
          key={index}
          position={{
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          }}
          title={`Pick and Drop Location`}
          icon={{
            url: mapHome,
            scaledSize: new google.maps.Size(32, 32),
          }}
        />
      ));
      setPickUpDropMarkers(markers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPickUpDropLocation();
  }, [separatedLatLng]);

  const getData = async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getAllLatLog?vehicleId=${vehRegId}&shiftTypeId=${shiftId}&date=${formik.values.date}`
      );
      if (res.data.message === "Ok") {
        const latlogData = res.data.latlog;
        setSetseparatedLatLng(latlogData);
        if (latlogData.length > 0) {
          const lastRecord =
            latlogData[0].records[latlogData[0].records.length - 1];
          setCenter({ lat: lastRecord.lat, lng: lastRecord.lng });
        }
      } else {
        setSetseparatedLatLng([]);
      }
    } catch (error) {
      setSetseparatedLatLng([]);
    }
  };
  useEffect(() => {
    if (formik.submitCount > 0) {
      getData();
    }
  }, [formik.submitCount]);

  if (!isLoaded) return null;
  const renderMarkersAndPolylines = () => {
    return separatedLatLng.map((vehicle: Data, index: number) => (
      <React.Fragment key={index}>
        <Marker
          position={vehicle.records[0]}
          icon={{
            url: mapSchool,
            scaledSize: new google.maps.Size(32, 32),
          }}
          title={`Start Location`}
        />
        <Marker
          position={vehicle.records[vehicle.records.length - 1]}
          icon={{
            url: mapBus,
            scaledSize: new google.maps.Size(40, 40),
          }}
          title={`Current Location`}
        />
        {pickUpDropMarkers}
        <Polyline
          key={`polyline-${vehicle?._id}`}
          path={vehicle?.records}
          options={{
            strokeColor: "#0066ff",
            strokeOpacity: 1,
            strokeWeight: 6,
            icons: [
              {
                icon: {
                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  scale: 3,
                },
                offset: "100%",
              },
            ],
          }}
        />
      </React.Fragment>
    ));
  };
  return (
    <>
      <div className="formBg mb-3">
        <div className=" mb-3 row align-items-center">
          <form
            className="row align-items-center "
            onSubmit={formik.handleSubmit}
          >
            <label htmlFor="shift" className="col-md-6 mb-3">
              <select
                className={`form-control ${
                  shiftId === "" && border ? "empty-form" : ""
                }`}
                id="shift"
                name="shift"
                onChange={(e) => {
                  setShiftId(e.target.value);
                  setIsSubmitted(false);
                }}
              >
                {!shiftCount && <option value="">Select Shift</option>}
                {shift?.map(({ _id, name, isActive }) => {
                  if (isActive) {
                    return (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    );
                  }
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="vehicleType">
              <select
                className={`form-control ${
                  vehiclTypeId === "" && border ? "empty-form" : ""
                }`}
                id="vehicleType"
                name="vehicleType"
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setLicensePlateNumbers([]);
                    setVehicleTypeId("");
                    loadLicensePlateNo(e.target.value);
                    setIsSubmitted(false);
                  } else {
                    setLicensePlateNumbers([]);
                    setVehicleTypeId("");
                    setVehRegId("");
                  }
                }}
              >
                <option value="">Select vehicle Type</option>
                {vehTyp?.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </label>
            <label htmlFor="vehReg" className="col-md-6 mb-3">
              <select
                className={`form-control ${
                  vehRegId === "" && border ? "empty-form" : ""
                }`}
                id="vehReg"
                onChange={(e) => {
                  setVehRegId(e.target.value);
                  setIsSubmitted(false);
                }}
              >
                <option value="">Select LicensePlate No</option>
                {licensePlateNumbers?.map(({ licensePlatNo, _id }) => {
                  return (
                    <option key={_id} value={_id}>
                      {licensePlatNo}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="date">
              <input
                className={`form-control ${
                  formik.errors.date && border ? "empty-form" : ""
                }`}
                id="date"
                name="date"
                type="date"
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  setIsSubmitted(false);
                }}
                value={formik.values.date}
                placeholder="Enter the date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </label>
            <div className="col-md-12 mb-3 text-center">
              <SubmitBtn
                errors={formik.errors}
                clickFunc={() => {
                  setBorder(true);
                  if (shiftId === "") {
                    warnToast("Select Shift");
                  }
                  if (vehiclTypeId === "") {
                    warnToast("Select VehicleType");
                  }
                  if (vehRegId === "") {
                    warnToast("Select LicencePlat number");
                  }
                  toastValidation(formik.errors);
                }}
                btnText="Search"
              />
            </div>
          </form>
        </div>
      </div>
      {separatedLatLng.length > 0 && isSubmitted ? (
        <GoogleMap mapContainerStyle={containerStyle} zoom={15} center={center}>
          {renderMarkersAndPolylines()}
        </GoogleMap>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default DriverTrackingList;
