import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect } from "react";
import { MemberFeesType } from "../../../types/memberFeesTypes";
import MemberFeesListItems from "./listitems";
import { exportToExcel, infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";
import NotFound from "components/NotFound";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { successToast } from "constants/toastConfig";
import jsPDF from "jspdf";
import "jspdf-autotable";

type Props = {};

const MemberFeesList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [param] = useSearchParams();
  const { state } = useLocation();
  const nav = useNavigate();
  const [memberFees, setMemberFees] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("memberName");
  const [searchCount, setSearchCount] = useState(0);
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  //Creating Fees
  const createFees = async () => {
    try {
      const data = {
        feesConfigId: state._id,
      };
      setLoading(true);
      const res = await axiosInstance.post(
        "/master/addMemberFeesAssignment",
        data
      );
      if (res.data === "Ok") {
        successToast();
        setLoading(false);
        const item = state;
        if (Number(param.get("fromEdit")) === 1) {
          nav(
            {
              pathname: `/transportationFeesMain/feesSectionEdit`,
              search: `?index=3`,
            },
            { state: item }
          );
        }

        loadMemberList(1, "memberName", "");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  //List of fees
  const loadMemberList = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `/master/getAllMemberFeesAssignment?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}&configId=${state._id}`
        );
        const newMemberFeesList: MemberFeesType[] = [
          ...res.data.result.allMemberFees[0].paginatedResults,
        ];
        if (searchKeyword !== "" && pageNum === 1) {
          if (newMemberFeesList.length === 0) {
            setNotFound(true);
            setMemberFees([]);
            setSearchCount(0);
          }
        }
        if (newMemberFeesList.length > 0) {
          setSearchCount(res.data.result.allMemberFees[0].totalCount[0].count);
          if (pageNum === 1) {
            setMemberFees(newMemberFeesList);
          } else {
            setMemberFees((prevList) => [...prevList, ...newMemberFeesList]);
          }
          setNotFound(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    },
    [memberFees]
  );

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    loadMemberList(1, sort, search);
  };

  useEffect(() => {
    loadMemberList(page, sort, searchQuery);
  }, [page, sort, searchQuery]);
  window.onscroll = () => {
    infiniteScroll(memberFees, setPage, page, searchCount);
  };

  const downloadPDF = () => {
    const doc: any = new jsPDF();

    const headers = [
      { id: "memberName", name: "Member Name" },
      { id: "pendingFees", name: "Pending Fees" },
      { id: "paidFees", name: "Paid Fees" },
      { id: "createdAt", name: "Created Date" },
    ];

    const data = memberFees.map((item: any) => ({
      memberName: item.memberName,
      pendingFees: item.totalPendingFees.toFixed(2),
      paidFees: item.totalPaidFees.toFixed(2),
      createdAt: item.createdAt,
    }));

    doc.autoTable({
      head: [headers.map((header: any) => header.name)],
      body: data.map((row: any) => headers.map((header) => row[header.id])),
    });

    doc.save("table_data.pdf");
  };

  const downloadPDFPrint = () => {
    const doc: any = new jsPDF();

    const headers = [
      { id: "memberName", name: "Member Name" },
      { id: "pendingFees", name: "Pending Fees" },
      { id: "paidFees", name: "Paid Fees" },
      { id: "createdAt", name: "Created Date" },
    ];

    const data = memberFees.map((item: any) => ({
      memberName: item.memberName,
      pendingFees: item.totalPendingFees.toFixed(2),
      paidFees: item.totalPaidFees.toFixed(2),
      createdAt: item.createdAt,
    }));

    doc.autoTable({
      head: [headers.map((header: any) => header.name)],
      body: data.map((row: any) => headers.map((header) => row[header.id])),
    });

    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <button onClick={downloadPDF} className="btn btn-primary mb-4">
          Export to PDF
        </button>
        <button
          onClick={() => {
            exportToExcel(memberFees);
          }}
          className="btn btn-primary mb-4"
        >
          Export to Excel
        </button>
        <button onClick={downloadPDFPrint} className="btn btn-primary mb-4">
          Print
        </button>
      </div>
      <div>
        <div className="formBg mb-3">
          <form
            action=""
            onSubmit={(e: any) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <div className="d-flex">
              <label className="flex-fill">
                <input
                  className="form-control  "
                  type="text"
                  placeholder="Member Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
              <div style={{ paddingRight: "15px" }}>
                <button className="btn btn-primary" type="submit">
                  Search
                </button>
                {state.configId === state._id && (
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => createFees()}
                  >
                    Generate Fees
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="memberName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMemberFees, setPage);
              }}
            >
              Member Name
              <span className="px-2">
                {!ascDsc ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th>Pending Fees</th>
            <th>Paid Fees</th>
            <th>Created Date</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {memberFees?.map((item) => {
              return <MemberFeesListItems item={item} key={Math.random()} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default MemberFeesList;
