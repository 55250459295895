import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import { useLoading } from "Context/LoadingContext";
import React, { useCallback, useState, useEffect, memo } from "react";
import { VehicleMasterDataType } from "types/vehicleMasterType";
import VehicleRegType from "types/VehicleRegTypes";
import VehicleRegListItems from "./VehicleRegListItems";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";

type Props = {};

const VehicleRegMasterList = (props: Props): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("vehName");
  const [searchCount, setSearchCount] = useState(0);
  const [vehicleReg, setVehicleReg] = useState<VehicleRegType[]>([]);
  const [vehicles, setVehicles] = useState<VehicleMasterDataType[]>([]);
  const { setLoading } = useLoading();
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const vehicleList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/dropdown/getVehicleType`);
      const vehicleList = res.data.vehicleTypes;
      setVehicles(vehicleList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadVehicleRegList = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllVehicleRegistrationListByOrganizerId?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );

        const newVehRegList: VehicleRegType[] = [
          ...res.data.vehicles[0].paginatedResults,
        ];
        if (searchKeyword !== "" && pageNum === 1) {
          if (newVehRegList.length === 0) {
            setNotFound(true);
            setVehicleReg([]);
            setSearchCount(0);
          }
        }
        if (newVehRegList.length > 0) {
          setSearchCount(res.data.vehicles[0].totalCount[0].count);
          if (pageNum === 1) {
            setVehicleReg(newVehRegList);
          } else {
            setVehicleReg((prevList) => [...prevList, ...newVehRegList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [vehicleReg]
  );

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    loadVehicleRegList(1, sort, search);
  };

  useEffect(() => {
    loadVehicleRegList(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(vehicleReg, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        addLink="/vehicleRegistration/addForm"
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Vehicle Type or License Plate No"
      />
      <table className="table">
        <thead>
          <tr>
            <th
              id="vehName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setVehicleReg, setPage);
              }}
            >
              Vehicle Type
              <span className="px-2">
                {sort === "vehName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="licensePlatNo"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setVehicleReg, setPage);
              }}
            >
              License Plate No
              <span className="px-2">
                {sort === "licensePlatNo" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="totalSeats"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setVehicleReg, setPage);
              }}
            >
              Total Seat
              <span className="px-2">
                {sort === "totalSeats" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="ownerName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setVehicleReg, setPage);
              }}
            >
              Owner Name
              <span className="px-2">
                {sort === "ownerName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th>Contact No</th>
            <th>Seat No</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {vehicleReg?.map((item) => {
              return (
                <VehicleRegListItems
                  item={item}
                  key={item._id}
                  vehicles={vehicles}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(VehicleRegMasterList);
