import axiosInstance from "axiosInstance";
import { useCallback, useState, useEffect, memo } from "react";
import { FeesKmType } from "types/feesKmTypes";
import FeesKmListItem from "./ListItem";
import { exportToExcel, infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";

type Props = {};

const FeesKmList = (props: Props): JSX.Element => {
  const nav = useNavigate();
  const { setLoading } = useLoading();
  const { state } = useLocation();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("amount");
  const [searchCount, setSearchCount] = useState(0);
  const [feesKm, setFeesKm] = useState<FeesKmType[]>([]);
  const { pathname } = useLocation();
  const segments = pathname.split("/");
  const segment = segments[segments.length - 1];
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const loadKmFees = useCallback(
    async (pageNum: number, sortParam: string, reload?: boolean) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getallFeesKm?page=${pageNum}&sort=${sortParam}&configId=${state._id}`
        );
        const result = res.data.feesKm;
        if (!reload) {
          const feesKms: FeesKmType[] = [...feesKm, ...result];
          setFeesKm(feesKms);
          setSearchCount(res.data.TotalCount);
          setLoading(false);
        } else {
          setFeesKm(result);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [feesKm]
  );

  const cloneFees = async () => {
    try {
      const res = await axiosInstance.put(
        `/master/cloneFees?configId=${state.configId}&prevConfigId=${state.prevConfigId}`
      );
      await loadKmFees(1, "area", true);
    } catch (error) {}
  };

  useEffect(() => {
    loadKmFees(page, sort, false);
  }, [page, sort, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(feesKm, setPage, page, searchCount);
  };

  const downloadPDF = () => {
    const doc: any = new jsPDF();

    const headers = [
      { id: "minRange", name: "Min Range" },
      { id: "maxRange", name: "Max Range" },
      { id: "amount", name: "Amount" },
      // { id: "isActive", name: "Active" },
    ];

    const data = feesKm.map((item: any) => ({
      minRange: item.minRange,
      maxRange: item.maxRange,
      amount: item.amount,
    }));

    doc.autoTable({
      head: [headers.map((header: any) => header.name)],
      body: data.map((row: any) => headers.map((header) => row[header.id])),
    });

    doc.save("table_data.pdf");
  };

  const downloadPDFPrint = () => {
    const doc: any = new jsPDF();

    const headers = [
      { id: "minRange", name: "Min Range" },
      { id: "maxRange", name: "Max Range" },
      { id: "amount", name: "Amount" },
      // { id: "isActive", name: "Active" },
    ];

    const data = feesKm.map((item: any) => ({
      minRange: item.minRange,
      maxRange: item.maxRange,
      amount: item.amount,
    }));

    doc.autoTable({
      head: [headers.map((header: any) => header.name)],
      body: data.map((row: any) => headers.map((header) => row[header.id])),
    });

    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  return (
    <>
      {state.configId === state._id && (
        <div className="d-flex align-items-end justify-content-end my-3">
          {state.isClone && !state.isPaymentMade && (
            <button className="btn btn-primary" onClick={() => setShow(true)}>
              Clone
            </button>
          )}
          <button onClick={downloadPDF} className="btn btn-primary">
            Export to PDF
          </button>
          <button
            onClick={() => {
              exportToExcel(feesKm);
            }}
            className="btn btn-primary"
          >
            Export to Excel
          </button>
          <button onClick={downloadPDFPrint} className="btn btn-primary">
            Print
          </button>
          <button
            className="btn btn-primary "
            type="submit"
            onClick={() => {
              const item = state;
              if (segment === "feesSectionEdit") {
                nav(
                  {
                    pathname: "/transportationFeesMain/feesSection/kmAddForm",
                    search: "?index=2&fromEdit=1",
                  },
                  { state: item }
                );
              } else {
                nav(
                  {
                    pathname: "/transportationFeesMain/feesSection/kmAddForm",
                    search: "?index=2",
                  },
                  { state: item }
                );
              }
            }}
          >
            Add
          </button>
        </div>
      )}
      {state.configId !== state._id && (
        <div className="d-flex align-items-end justify-content-end my-3">
          <button onClick={downloadPDF} className="btn btn-primary mb-4">
            Export to PDF
          </button>
          <button
            onClick={() => {
              exportToExcel(feesKm);
            }}
            className="btn btn-primary mb-4"
          >
            Export to Excel
          </button>
          <button onClick={downloadPDFPrint} className="btn btn-primary mb-4">
            Print
          </button>
        </div>
      )}
      <table className="table">
        <thead>
          <tr>
            <th id="range">Range(Km)</th>

            <th
              id="amount"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setFeesKm, setPage);
              }}
            >
              Amount
              <span className="px-2">
                {sort === "amount" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            {state.configId === state._id && (
              <th className="text-center">Active</th>
            )}
            {state.configId === state._id && state.isPaymentMade === false && (
              <th className="text-center">Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {feesKm?.map((x) => {
            return (
              <FeesKmListItem
                x={x}
                key={x._id}
                state={state}
                segment={segment}
              />
            );
          })}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          Are you sure you want to clone previous year's fees?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={async () => {
              setShow(false);
              cloneFees();
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(FeesKmList);
