import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MemberList from "./MemberList";
import MemberGetPassList from "./MemberGetPassList";
type Props = {};

const GetPassTabs = (props: Props) => {
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Member Gate Pass</span>
      </div>
      <Tabs>
        <TabList>
          <Tab>MemberList</Tab>
          <Tab>Member Gate Pass List</Tab>
        </TabList>
        <TabPanel>
          <MemberList />
        </TabPanel>
        <TabPanel>
          <MemberGetPassList />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default GetPassTabs;
