import React, { useState, memo } from "react";
import { Link, Outlet, useLocation, useOutletContext } from "react-router-dom";

type Props = {};
type ContextType = {
  setCardMemberMappingId: (cardMemberMappingId: string) => void;
  cardMemberMappingId: string;
};

const CardMemberMapping: React.FC<{}> = (props: Props) => {
  const location = useLocation();
  const [cardMemberMappingId, setCardMemberMappingId] = useState<string>("");

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Card Member Mapping</span>

        {location.pathname === "/cardMemberMapping" && (
          <Link to={"/cardMemberMapping/addForm"}>
            <button className="btn btn-primary" type="submit">
              Add
            </button>
          </Link>
        )}
      </div>
      <Outlet context={{ cardMemberMappingId, setCardMemberMappingId }} />
    </>
  );
};

export default memo(CardMemberMapping);

export function useCardMemberMappingId() {
  return useOutletContext<ContextType>();
}
