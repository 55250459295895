import React, { memo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
type Props = {};

const MemberAssignment: React.FC<{}> = (props: Props) => {
  const location = useLocation();

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Member Assignment</span>
        {location.pathname === "/memberAssign" && (
          <Link to={"/memberAssign/addForm"}>
            <button className="btn btn-primary" type="submit">
              Add
            </button>
          </Link>
        )}
      </div>
      <Outlet />
    </>
  );
};

export default memo(MemberAssignment);
