import axiosInstance from "axiosInstance";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
// import { AreaDivisionDDL } from "types/areaDivisionTypes";
// import { ShiftDDL } from "types/shiftType.types";
// import { VehicleMasterDataType } from "types/vehicleMasterType";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { FeesByVehicle } from "types/feesAreaTypes";
import SubmitBtn from "components/Buttons/Submit";
// import CancelBtn from "components/Buttons/Cancel";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";

type Props = {};

// type RegisteredVehicleType = {
//   vehicleNo: string;
//   vehicleRegId: string;
// };

// type AreaDDLType = {
//   area: string;
//   pinCode: string;
// };

const EditForm = (props: Props) => {
  const { state } = useLocation();
  const [param] = useSearchParams();

  const { setLoading } = useLoading();
  const nav = useNavigate();

  const [feesVehicle, setFeesVehicle] = useState<FeesByVehicle>();

  const settingFeesByVehicle = async () => {
    // Previous Fee Config
    const res = await axiosInstance(
      `/master/getOneFeesVehicle/${state.vehicleEditId}`
    );
    setFeesVehicle(res.data.result);
    // const feesByVeh = res.data.result;

    // // Shift DropDown
    // const shifts = await loadShifts();
    // const prevShift = updateList(shifts, feesByVeh.shiftTypeId);
    // setShift(prevShift as ShiftDDL[]);
    // setShiftId(feesByVeh.shiftTypeId);

    // // Vehicle Type DropDown
    // const veh = await loadVehicleTypes();
    // const prevVeh = updateList(veh, feesByVeh.vehicleTypeId);
    // setVehTyp(prevVeh as VehicleMasterDataType[]);
    // setVehTypId(feesByVeh.vehicleTypeId);

    // // Vehicle Registration DropDown
    // const regVeh = await loadAssignedVehicle(
    //   feesByVeh.shiftTypeId,
    //   feesByVeh.vehicleTypeId
    // );
    // const prevRegVeh = updateRegisteredVehicleList(
    //   regVeh,
    //   feesByVeh.vehicleRegistrationId
    // );
    // setRegisteredVeh(prevRegVeh);
    // setVehRegId(feesByVeh.vehicleRegistrationId);

    // // Area DropDown
    // const ara = await loadAssignedAreas(
    //   feesByVeh.shiftTypeId,
    //   feesByVeh.vehicleTypeId,
    //   feesByVeh.vehicleRegistrationId
    // );
    // const prevArea = updateAreaList(ara, feesByVeh.pinCode);
    // setArea(prevArea as AreaDDLType[]);
    // setPin(feesByVeh.pinCode);

    // // Division DropDown
    // const div = await loadAssignedDivisions(
    //   feesByVeh.shiftTypeId,
    //   feesByVeh.vehicleTypeId,
    //   feesByVeh.vehicleRegistrationId,
    //   feesByVeh.pinCode
    // );
    // console.log(div);
    // const prevDiv = updateList(div, feesByVeh.divisionId);
    // console.log(prevDiv);
    // setDivision(prevDiv as AreaDivisionDDL[]);
    // setDivId(feesByVeh.divisionId);
  };

  // const loadShifts = useCallback(async () => {
  //   try {
  //     const res = await axiosInstance.get("/dropDown/getShiftType");
  //     // setShift(res.data.shiftTypes)
  //     return res.data.shiftTypes;
  //   } catch (error) {}
  // }, []);

  // const loadVehicleTypes = useCallback(async () => {
  //   try {
  //     const res = await axiosInstance.get("/dropDown/getVehicleType");
  //     return res.data.vehicleTypes;
  //   } catch (error) {}
  // }, []);

  // const loadAssignedVehicle = useCallback(
  //   async (sId: string, vehId: string) => {
  //     try {
  //       const res = await axiosInstance.get(
  //         `/dropDown/getAllAssignedVehicle?shiftId=${sId}&vehicleId=${vehId}`
  //       );
  //       return res.data.result.AssignedVehicle;
  //     } catch (error) {}
  //   },
  //   []
  // );

  // const loadAssignedAreas = useCallback(
  //   async (sId: string, vehId: string, regId: string) => {
  //     try {
  //       const res = await axiosInstance.get(
  //         `/dropDown/getAllocatedAreas?shiftId=${sId}&vehicleId=${vehId}&vehicleRegistrationId=${regId}`
  //       );
  //       return res.data.result.AllocatedAreas;
  //     } catch (error) {}
  //   },
  //   []
  // );

  // const loadAssignedDivisions = useCallback(
  //   async (sId: string, vehId: string, regId: string, pinCode: string) => {
  //     try {
  //       const res = await axiosInstance.get(
  //         `/dropDown/getAllocatedDivisions?shiftId=${sId}&vehicleId=${vehId}&vehicleRegistrationId=${regId}&pinCode=${pinCode}`
  //       );
  //       // setDivision(res.data.result.AllocatedDivisions)
  //       return res.data.result.AllocatedDivisions;
  //     } catch (error) {}
  //   },
  //   []
  // );

  useEffect(() => {
    settingFeesByVehicle();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: feesVehicle?.amount,
      active: feesVehicle?.isActive,
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required("Amount is Required"),
    }),
    onSubmit: async ({ amount, active }) => {
      try {
        // if (
        //   shiftId === "" ||
        //   vehTypId === "" ||
        //   vehRegId === "" ||
        //   pin === "" ||
        //   divId === ""
        // )
        // return;
        setLoading(true);
        const formData = {
          amount: amount,
          description: "",
          note: "",
          details: "",
          isActive: active,
          isDeleted: false,
        };
        const res = await axiosInstance.put(
          `/master/updateFeesVehicle/${state.vehicleEditId}`,
          formData
        );
        const statusCode = res.data.statusCode;
        if (statusCode === 200) {
          successToast();
          setLoading(false);
          const item = state;
          if (Number(param.get("fromEdit")) === 1) {
            nav(
              {
                pathname: "/transportationFeesMain/feesSectionEdit",
                search: "?index=2",
              },
              { state: item }
            );
          } else {
            nav(
              {
                pathname: "/transportationFeesMain/feesSection/vehicleList",
                search: "?index=2",
              },
              { state: item }
            );
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  // const updateAreaList = (list: AreaDDLType[], id: string) => {
  //   const newList = [...list];
  //   const record = newList?.find((x: AreaDDLType) => x?.pinCode === id);
  //   const index = newList.indexOf(record as AreaDDLType);
  //   newList.splice(index, 1);
  //   newList.splice(0, 0, record as AreaDDLType);
  //   return newList;
  // };

  // const updateRegisteredVehicleList = (
  //   list: RegisteredVehicleType[],
  //   id: string
  // ) => {
  //   const newList = [...list];
  //   const record = newList?.find(
  //     (x: RegisteredVehicleType) => x?.vehicleRegId === id
  //   );
  //   const index = newList.indexOf(record as RegisteredVehicleType);
  //   newList.splice(index, 1);
  //   newList.splice(0, 0, record as RegisteredVehicleType);
  //   return newList;
  // };

  // const updateList = (
  //   list:
  //     | AreaDivisionDDL[]
  //     | ShiftDDL[]
  //     | VehicleMasterDataType[]
  //     | AreaDivisionDDL[],
  //   id: string
  // ) => {
  //   const newList = [...list];
  //   const record = newList?.find(
  //     (
  //       x: AreaDivisionDDL | ShiftDDL | VehicleMasterDataType | AreaDivisionDDL
  //     ) => x?._id === id
  //   );
  //   const index = newList.indexOf(
  //     record as
  //       | AreaDivisionDDL
  //       | ShiftDDL
  //       | VehicleMasterDataType
  //       | AreaDivisionDDL
  //   );
  //   newList.splice(index, 1);
  //   newList.splice(
  //     0,
  //     0,
  //     record as
  //       | AreaDivisionDDL
  //       | ShiftDDL
  //       | VehicleMasterDataType
  //       | AreaDivisionDDL
  //   );
  //   return newList;
  // };

  return (
    <div className="formBg">
      <form
        action=""
        className="row align-items-center "
        onSubmit={formik.handleSubmit}
      >
        <ul className="profileList">
          <li>
            <div className="d-flex align-items-center ">
              <span>Shift :</span>
              <p className="m-0"> {state.shift}</p>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center ">
              <span>Vehicle No :</span>
              <p className="m-0"> {state.licensePlatNo}</p>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center ">
              <span>vehicle :</span>
              <p className="m-0"> {state.vehicleTyp}</p>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center ">
              <span>Area :</span>
              <p className="m-0"> {state.area}</p>
            </div>
          </li>
          <li>
            <div className="d-flex align-items-center ">
              <span>Division :</span>
              <p className="m-0">{state.division}</p>
            </div>
          </li>
        </ul>
        {/* <label htmlFor="shift" className="col-md-6 mb-3">
          <select
            className="form-control"
            id="shift"
            onChange={async (e) => {
              setShiftId(e.target.value);
              setVehTypId("");
              setVehTyp([]);
              setVehRegId("");
              setRegisteredVeh([]);
              setPin("");
              setArea([]);
              setDivId("");
              setDivision([]);
              const veh = await loadVehicleTypes();
              setVehTyp(veh);
            }}
          >
            {shift?.map(({ _id, name }) => {
              return (
                <option key={_id} value={_id}>
                  {name}
                </option>
              );
            })}
          </select>
        </label>
        <label htmlFor="vehicleType" className="col-md-6 mb-3">
          <select
            className="form-control"
            id="vehicleType"
            onChange={async (e) => {
              setVehTypId(e.target.value);
              if (shiftId !== "" && e.target.value !== "") {
                setVehRegId("");
                setRegisteredVeh([]);
                setPin("");
                setArea([]);
                setDivId("");
                setDivision([]);
                const regVeh = await loadAssignedVehicle(
                  shiftId,
                  e.target.value
                );
                setRegisteredVeh(regVeh);
              }
            }}
          >
            {vehTypId === "" && <option value="">Select Vehicle Type</option>}
            {vehTyp?.map(({ _id, name }) => {
              return (
                <option key={_id} value={_id}>
                  {name}
                </option>
              );
            })}
          </select>
        </label>
        <label htmlFor="vehReg" className="col-md-6 mb-3">
          <select
            className="form-control"
            id="vehReg"
            onChange={async (e) => {
              setVehRegId(e.target.value);
              if (shiftId !== "" && vehTypId !== "" && e.target.value !== "") {
                setPin("");
                setArea([]);
                setDivId("");
                setDivision([]);
                const area = await loadAssignedAreas(
                  shiftId,
                  vehTypId,
                  e.target.value
                );
                setArea(area);
              }
            }}
          >
            {vehRegId === "" && (
              <option value="">Select LicensePlate No</option>
            )}
            {registeredVeh?.map(({ vehicleNo, vehicleRegId }) => {
              return (
                <option key={vehicleRegId} value={vehicleRegId}>
                  {vehicleNo}
                </option>
              );
            })}
          </select>
        </label>
        <label htmlFor="area" className="col-md-6 mb-3">
          <select
            className="form-control"
            id="area"
            onChange={async (e) => {
              setPin(e.target.value);
              if (
                shiftId !== "" &&
                vehTypId !== "" &&
                vehRegId !== "" &&
                e.target.value !== ""
              ) {
                setDivId("");
                setDivision([]);
                const div = await loadAssignedDivisions(
                  shiftId,
                  vehTypId,
                  vehRegId,
                  e.target.value
                );
                setDivision(div);
              }
            }}
          >
            {pin === "" && <option value="">Select Area</option>}
            {area?.map(({ area, pinCode }) => {
              return (
                <option key={pinCode} value={pinCode}>
                  {area}
                </option>
              );
            })}
          </select>
        </label>
        <label htmlFor="division" className="col-md-6 mb-3">
          <select
            className="form-control"
            id="division"
            onChange={(e) => {
              setDivId(e.target.value);
            }}
          >
            {divId === "" && <option value="">Select Division</option>}
            {division?.map(({ _id, areaCode }) => {
              return (
                <option key={_id} value={_id}>
                  {areaCode}
                </option>
              );
            })}
          </select>
        </label> */}
        <label className="col-md-6 mb-3" htmlFor="amount">
          {formik.values.amount && (
            <span className="top-placeholder">Amount</span>
          )}
          <input
            className={`form-control ${
              formik.errors.amount ? "empty-form" : ""
            }`}
            id="amount"
            name="amount"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.amount}
            placeholder="amount"
          />
        </label>
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn
            errors={formik.errors}
            clickFunc={() => {
              // if (shiftId === "") warnToast("Select Shift");
              // if (vehTypId === "") warnToast("Select Vehicle Type");
              // if (vehRegId === "") warnToast("Select Vehicle");
              // if (pin === "") warnToast("Select Area");
              // if (divId === "") warnToast("Select Area Division");
              toastValidation(formik.errors);
            }}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              const item = state;
              if (Number(param.get("fromEdit")) === 1) {
                nav(
                  {
                    pathname: "/transportationFeesMain/feesSectionEdit",
                    search: "?index=2",
                  },
                  { state: item }
                );
              } else {
                nav(
                  {
                    pathname: "/transportationFeesMain/feesSection/vehicleList",
                    search: "?index=2",
                  },
                  { state: item }
                );
              }
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditForm;
