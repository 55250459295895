import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate, useLocation, Link } from "react-router-dom";

type Props = {};

const AdminMessageForm = (props: Props) => {
  const nav = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      ContactNumber: "",
      CompanyName: "",
      MessageFrom: "",
      Message: "",
    },
    validationSchema: Yup.object().shape({
      Name: Yup.string(),
      Email: Yup.string(),
      ContactNumber: Yup.string(),
      CompanyName: Yup.string(),
      MessageFrom: Yup.string(),
      Message: Yup.string(),
    }),
    onSubmit: async ({
      Name,
      Email,
      ContactNumber,
      CompanyName,
      MessageFrom,
      Message,
    }) => {
      try {
        const formData = {
          Name: Name,
          Email: Email,
          ContactNumber: ContactNumber,
          CompanyName: CompanyName,
          MessageFrom: MessageFrom,
          Message: Message,
        };

        const res = await axiosInstance.post(
          "/admin/addAdminMessage",
          formData,
          {}
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          nav("/adminMessage");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="Name">
              <input
                className="form-control"
                id="Name"
                name="Name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.Name}
                placeholder="Name"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="Email">
              <input
                className="form-control"
                id="Email"
                name="Email"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.Email}
                placeholder="Email"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="ContactNumber">
              <input
                className="form-control"
                placeholder="ContactNumber"
                id="ContactNumber"
                name="ContactNumber"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.ContactNumber}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="CompanyName">
              <input
                className="form-control"
                placeholder="CompanyName"
                id="CompanyName"
                name="CompanyName"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.CompanyName}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="MessageFrom">
              <input
                className="form-control"
                id="MessageFrom"
                name="MessageFrom"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.MessageFrom}
                placeholder="MessageFrom"
                onFocus={(e) => (e.target.type = "date")}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="Message">
              <input
                className="form-control"
                id="Message"
                name="Message"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.Message}
                placeholder="Message"
                onFocus={(e) => (e.target.type = "date")}
              />
            </label>
          </div>
          <div className="text-center col-md-12 mb-3">
            {(location.pathname === "/adminMessage/adminMessageForm" ||
              location.pathname === "/adminMessage/adminMessageView") && (
                <Link to={"/adminMessage"}>
                  <button className="btn btn-primary" type="submit">
                    Cancel
                  </button>
                </Link>
              )}
          </div>
        </form>
      </div>

    </>
  );
};

export default memo(AdminMessageForm);
