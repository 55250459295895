import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { DriverDataType } from "./DriverList";
import { descSlice, drvImage } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: DriverDataType;
};

const DriverListItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();

  const handleEditClick = () => {
    nav("driverEdit", {
      state: { x },
    });
  };

  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit </Tooltip>;
  let userTypeImage = x.photo
    ? `${drvImage}${x.photo}`
    : `${drvImage}Default.png`;
  return (
    <tr key={x._id}>
      <td>
        <i className="userImg">
          <img src={userTypeImage} alt="DriverPic" />
        </i>
      </td>
      <td>{x.name}</td>
      <td>{x.contactNo}</td>
      <td className="text-center">
        {x.drivingLicenceDoc === "" ? "No" : "Yes"}
      </td>
      <td className="text-center">{x.adharCardDoc === "" ? "No" : "Yes"}</td>
      <td>{descSlice(x.description)}</td>
      <td className="text-center">
        {x.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center">
        {/* <OverlayTrigger placement="right" overlay={tooltip}>
          <Link to={"/driver/driverEdit"}>
            <Edit
              id={x._id}
              onClick={(e) => {
                setDriverId(e.currentTarget.id);
              }}
            />
          </Link>
        </OverlayTrigger> */}
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={x._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(DriverListItem);
