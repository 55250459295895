import React, { useEffect, useCallback } from "react";
import { axiosAuth } from "axiosInstance";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoading } from "Context/LoadingContext";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import toastValidation, {
  errorToast,
  successToast,
  warnToast,
} from "constants/toastConfig";

type Props = {};

const ResetPassword = (props: Props) => {
  const { id } = useParams();
  const nav = useNavigate();
  const { setLoading } = useLoading();

  const pageLoad = useCallback(async () => {
    await axiosAuth.put(`/master/updateForgotPassword/${id}`, {
      isVerified: 1,
    });
  }, [id]);

  useEffect(() => {
    pageLoad();
  }, [pageLoad]);

  const orgId = id?.slice(3, -3);

  const formik = useFormik({
    validateOnMount: true,

    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required!"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Please enter password again!"),
    }),
    onSubmit: ({ password }) => {
      setLoading(true);
      const formData = {
        password,
      };
      axiosAuth
        .put(`/master/changePassword/${orgId}`, formData)
        .then(async (res) => {
          if (res.status === 202) {
            warnToast(res.data);
            setLoading(false);
            return;
          }
          setLoading(false);
          successToast("Password changed successfully, Redirecting to login.");

          await axiosAuth.put(`/master/updateForgotPassword/${id}`, {
            isVerified: 2,
          });
          nav("/login");
        })
        .catch((error: any) => {
          setLoading(false);
          errorToast("Something's wrong, Contact admin!");
        });
    },
  });
  return (
    <>
      <section className="login" style={{ height: "100vh" }}>
        <div className="loginBox">
          <Link to="/" className="logowrap mb-4">
            <Logo height={80} width={200} />
          </Link>
          <div className="login-text">Reset Password</div>
          <div className="welcome-text">Welcome to the DropikMe</div>
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="password">
              <span className="top-placeholder">Password</span>

              <input
                className={`form-control email-control ${
                  formik.errors.password && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                // className="form-control email-control"
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                placeholder="Enter password"
              />
            </label>
            <label htmlFor="confirmPassword">
              <span className="sign-up-placeholder">Confirm Password</span>

              <input
                className={`form-control email-control ${
                  formik.errors.confirmPassword && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                // className="form-control email-control"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                onChange={formik.handleChange}
                placeholder="Confirm Password"
              />
            </label>
            <label className="text-center">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => toastValidation(formik.errors)}
              >
                Submit
              </button>
            </label>
          </form>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
