import { validPaths } from "constants/ValidePathsForInactiveUser";
import { NavLink } from "react-router-dom";
import { warnToast } from "constants/toastConfig";
type Props = {
  children?: any;
  to: string;
  text: string;
  padding?: boolean;
};

type isActive = {
  isActive: boolean;
};
const CustomNavLink = ({ children, to, text, padding }: Props) => {
  const isActive: isActive = JSON.parse(
    localStorage.getItem("isActive") || "true"
  );
  const navLink = isActive ? to : validPaths.includes(to) ? to : "profile";
  if (navLink === "profile" && !isActive) {
    warnToast("Your plan has been expired");
  }
  return (
    <NavLink to={navLink} className={padding ? "pb-3" : ""}>
      {children && <i>{children}</i>}
      {text}
    </NavLink>
  );
};

export default CustomNavLink;
