import React, { useState, memo } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

type Props = {};

const DriverLeave: React.FC<{}> = (props: Props) => {
  const [driverLeaveId, setDriverLeaveId] = useState<string>("");

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Driver Leave</span>
      </div>
      <Outlet />
    </>
  );
};

export default memo(DriverLeave);
