import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { TypeMasterDataType } from "../../../types/typeMasterTypes";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useTypeId } from ".";
import { descSlice } from "constants/Constants";
import { DepartmentDDLType } from "types/departmentMasterType";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: TypeMasterDataType;
  departments: DepartmentDDLType[] | undefined;
};

const TypeMasterListItem = ({ x, departments }: Props): JSX.Element => {
  const nav = useNavigate();
  const { setTypeId } = useTypeId();
  const dName = departments?.find((item) => {
    return item._id === x.departmentId;
  });

  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit</Tooltip>;
  return (
    <tr key={x._id}>
      <td>{x.dName}</td>
      <td>{x.cName}</td>
      <td>{x.name}</td>
      <td>{x.code}</td>
      <td>{descSlice(x.description)}</td>
      <td className="text-center">
        {x.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            id={x._id}
            onClick={(e) => {
              nav("/typeMaster/typeMasterEditForm", { state: x._id });
              setTypeId(e.currentTarget.id);
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(TypeMasterListItem);
