import axiosInstance from "axiosInstance";
import { useCallback, useState, useEffect, memo } from "react";
import { ErrorsLogsType, ErrorsType } from "types/errorTypes";
import { infiniteScroll, sortData } from "constants/Constants";
import ErrorListItem from "./errorListItem";
import { useLoading } from "Context/LoadingContext";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useNotFound } from "Context/NotFound";
import NotFound from "components/NotFound";
type Props = {};

const ErrorList = (props: Props): JSX.Element => {
  const [Errors, setErrors] = useState<ErrorsLogsType[]>([]);
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("-createdAt");
  const [searchCount, setSearchCount] = useState(0);
  const { notfound, setNotFound } = useNotFound();

  const currentDate = new Date();
  const months = Array.from(
    { length: currentDate.getMonth() + 1 },
    (_, i) => i + 1
  );

  const loadErrors = useCallback(
    async (selectedMonth: number, pageNum: number, sortParam: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/admin/GetBasicErrorLogListForMember?month=${selectedMonth}&page=${pageNum}&sort=${sortParam}`
        );
        console.log(res, "data");
        const ErrorList: ErrorsLogsType[] = [
          ...res.data.result[0].paginatedResults,
        ];
        if (ErrorList.length === 0 && pageNum === 1) {
          setNotFound(true);
          setErrors([]);
          setSearchCount(0);
        }
        if (ErrorList.length > 0) {
          setSearchCount(res.data.result[0].totalCount[0].count);
          if (pageNum === 1) {
            setErrors(ErrorList);
          } else {
            setErrors((prevList) => [...prevList, ...ErrorList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [Errors]
  );

  useEffect(() => {
    loadErrors(month, page, sort);
  }, [month, page, sort]);

  window.onscroll = () => {
    infiniteScroll(Errors, setPage, page, searchCount);
  };
  return (
    <>
      <div className="formBg mb-3">
        <div className="mb-2 row align-items-center">
          <label className="col-md-12 mb-3" htmlFor="month-dropdown">
            <select
              className="form-control"
              id="month-dropdown"
              name="month-dropdown"
              value={month}
              onChange={(e) => {
                const selectedMonth = parseInt(e.target.value);
                setPage(1);
                setMonth(selectedMonth);
                setErrors([]);
              }}
            >
              {months.map((m) => (
                <option
                  key={m}
                  value={m}
                  label={new Date(2000, m - 1, 1).toLocaleString("default", {
                    month: "long",
                  })}
                />
              ))}
            </select>
          </label>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="module"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              Module
              {sort === "module" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>

            <th
              id="action"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              Action
              {sort === "action" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="status"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              Status
              {sort === "status" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th>Json Body</th>
            <th>Stack</th>
            <th>Message</th>
            <th>Device Details</th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              Date
              {sort === "-createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              Time
              {sort === "-createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {Errors?.map((x) => {
              return <ErrorListItem x={x} key={x._id} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(ErrorList);
