import React, { useState } from "react";
import axiosInstance from "axiosInstance";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLoading } from "Context/LoadingContext";
import { useNavigate } from "react-router-dom";
import SubmitBtn from "components/Buttons/Submit";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";

type Props = {
  state: any;
};

const PasswordBasic = ({ state }: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [border, setBorder] = useState<boolean>(false);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(1, "Password is Too Short!")
        .max(30, "Password is Too Long!")
        .required("Password is Required"),
    }),
    onSubmit: async ({ password }) => {
      try {
        setLoading(true);
        const formData = { password };
        const res = await axiosInstance.put(
          `/master/updateOrgBasicSetup/${state._id}`,
          formData
        );
        if (res.status === 202) {
          setLoading(false);
          warnToast(res.data);
        }
        if (res.data === "Ok") {
          successToast();
          nav("..");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <div className="formBg">
      <form className="row align-items-center " onSubmit={formik.handleSubmit}>
        <span className="mb-5">
          Your Current Password is{" "}
          <span style={{ fontWeight: "900" }}>{state.password}</span>
        </span>
        <label className="col-md-6 mb-3" htmlFor="password">
          <input
            className={`form-control ${
              formik.errors.password && border ? "empty-form" : ""
            }`}
            id="password"
            name="password"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            placeholder="Enter Password"
          />

          <span className="top-placeholder">Password*</span>
        </label>
        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn
            errors={formik.errors}
            clickFunc={() => {
              setBorder(true);
              toastValidation(formik.errors);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default PasswordBasic;
