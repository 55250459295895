import * as Yup from "yup";
import { useLoading } from "Context/LoadingContext";
import axiosInstance from "axiosInstance";
import CancelBtn from "components/Buttons/Cancel";
import React, { useCallback, useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useSearchParams,
} from "react-router-dom";
import { MemberFeesEditType } from "types/memberFeesTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReactComponent as Remove } from "assets/images/remove.svg";
import { useFormik } from "formik";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import {
  convertToMonthYearFormat,
  currencySymbol,
  formatDate,
} from "constants/Constants";
import { Form, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import socket from "socket";
import InvoiceTemplate from "components/InvoiceTemplate";

type Props = {
  // state: any;
};

const EditForm = (props: Props) => {
  const nav = useNavigate();
  const { state } = useLocation();
  const { setLoading } = useLoading();
  const [show, setShow] = useState(false);
  const [param] = useSearchParams();
  const handleClose = () => setShow(false);
  const [memberTerms, setMemberTerms] = useState<MemberFeesEditType[]>([]);
  const [showPay, setShowPay] = useState(false);
  const [lastTermleft, setLastTermleft] = useState(0);
  const [termId, setTermId] = useState("");
  const [payType, setPayType] = useState("cash");
  const [fees, setFees] = useState("");
  const [payDetails, setPayDetails] = useState({
    refId: "",
    Bank: "",
  });
  const [pendingFees, setPendingFees] = useState(false);
  const [border, setBorder] = useState<boolean>(false);

  const handleClosePay = () => setShowPay(false);

  const validationObjCheque = {
    amount: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/, "Only numbers are allowed")
      .required("Amount is required"),
    chequeRefId: Yup.string().required("please enter cheque reference id"),
    bankName: Yup.string().required("please enter bank name"),
  };
  const validationObjCash = {
    amount: Yup.string()
      .matches(/^(0|[1-9][0-9]*)$/, "Only numbers are allowed")
      .required("Amount is required"),
  };

  const getMembersTerms = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getOneMemberFeesAssignment/${state}`
      );
      const termsLeft = res.data.result.memberTerms.filter((x: any) => {
        return (
          x.feesStatus === 0 &&
          x.paymentStatus === 0 &&
          x.isPaymentDoneForYear === false
        );
      });
      if (termsLeft.length === 1) {
        setLastTermleft(1);
      } else if (termsLeft.length === 0) {
        setLastTermleft(2);
      }
      setMemberTerms(res.data.result.memberTerms);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const formikPay = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: Number(fees),
      chequeRefId: pendingFees ? "" : payDetails.refId,
      bankName: pendingFees ? "" : payDetails.Bank,
    },
    validationSchema: Yup.object().shape(
      payType === "cheque" ? validationObjCheque : validationObjCash
    ),
    onSubmit: async ({ amount, chequeRefId, bankName }) => {
      setLoading(true);
      try {
        if (payType === "cheque" && pendingFees) {
          const formData = {
            amount: Number(amount).toFixed(2),
            memberTermId: termId,
            payType: payType,
            chequeReferenceId: chequeRefId,
            bankName: bankName,
            description: "",
            note: "",
            details: "",
            isActive: true,
            isDeleted: false,
          };
          const res = await axiosInstance.post(
            `/master/addCashChequePayment`,
            formData
          );
          const responseMessage = res.data.statusCode;
          if (responseMessage === 200) {
            successToast();
            setShowPay(false);
            setLoading(false);
            getMembersTerms();
          }
        } else if (payType === "cash" && pendingFees) {
          const formData = {
            amount: Number(amount).toFixed(2),
            payType: payType,
            memberTermId: termId,
            chequeReferenceId: "",
            bankName: "",
            description: "",
            note: "",
            details: "",
            isActive: true,
            isDeleted: false,
          };
          const res = await axiosInstance.post(
            `/master/addCashChequePayment`,
            formData
          );
          const responseMessage = res.data.statusCode;
          if (responseMessage === 200) {
            successToast();
            setShowPay(false);
            setLoading(false);
            getMembersTerms();
          }
        } else if (payType === "cash" && !pendingFees) {
          const formData = {
            amount: Number(amount).toFixed(2),
            payType: payType,
            chequeReferenceId: "",
            bankName: "",
          };
          const res = await axiosInstance.put(
            `/master/updateCashChequePayment/${termId}`,
            formData
          );
          const responseMessage = res.data.statusCode;
          if (responseMessage === 200) {
            successToast();
            setShowPay(false);
            setLoading(false);
            getMembersTerms();
          }
        } else {
          const formData = {
            amount: Number(amount).toFixed(2),
            chequeReferenceId: chequeRefId,
            bankName: bankName,
            payType: payType,
          };
          const res = await axiosInstance.put(
            `/master/updateCashChequePayment/${termId}`,
            formData
          );
          const responseMessage = res.data.statusCode;
          if (responseMessage === 200) {
            successToast();
            setShowPay(false);
            setLoading(false);
            getMembersTerms();
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });
  function toggleCheckbox(extSetting: string) {
    if (extSetting !== null) {
      const type = extSetting;
      const checkbox: any = document.getElementById(type);
      checkbox.checked = true;
    }
  }
  const handleDeleteTerm = async (id: string) => {
    try {
      const res = await axiosInstance.put(`/master/deleteChequePayment/${id}`);
      console.log(res);
      getMembersTerms();
    } catch (error) {}
  };
  useEffect(() => {
    getMembersTerms();
    socket.on("fees", (data) => {
      console.log(data);
      if (data === "memberFees") {
        getMembersTerms();
      }
    });
  }, []);

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Payment Management</span>
      </div>
      {/* <div className="col-md-12 mb-3 text-center">
        <CancelBtn path={`/paymentManagement?index=${param.get("index")}`} />
      </div> */}
      <div className="d-flex justify-content-end">
        <CancelBtn btnTxt="Back" path="/paymentManagement" />
      </div>
      <div className="formBg">
        {/* <div className="fs-4 fw-semibold mb-5">
          <span className="me-3">Member Name :-</span>
          {memberTerms[0]?.memberName}
        </div> */}
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Term</th>
              <th className="text-center">{`Start Month - End Month`}</th>
              <th className="text-center">{`Fees Date - Due Date`}</th>
              <th className="text-center">Pending Fees</th>
              <th className="text-center">Paid Fees</th>
              <th className="text-center">Pay Type</th>
              <th className="text-center">Payment Reference ID</th>
              <th className="text-center">Payment</th>
              <th className="text-center">Action</th>
              <th className="text-center">Action</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            {memberTerms.map((x) => {
              const pending =
                x.feesStatus === 0 &&
                x.paymentStatus === 0 &&
                x.isPaymentDoneForYear === false;
              return (
                <tr
                  key={x._id}
                  // style={
                  //   pending
                  //     ? {}
                  //     : {
                  //         color: "#000000",
                  //         backgroundColor: "#cfdaf0",
                  //       }
                  // }
                >
                  <td className="text-center">
                    {x.termName.charAt(0).toUpperCase() + x.termName.slice(1)}
                  </td>
                  <td className="text-center">{`${convertToMonthYearFormat(
                    x.startMonth
                  )} - ${convertToMonthYearFormat(x.endMonth)}`}</td>
                  <td className="text-center">{`${formatDate(
                    x.startDate
                  )} - ${formatDate(x.dueDate)}`}</td>
                  <td className="text-center">
                    {currencySymbol}
                    {pending ? `${Number(x.termFees).toFixed(2)}` : "00.00"}
                  </td>
                  <td className="text-center">
                    {currencySymbol}
                    {x.paidFees !== undefined
                      ? `${Number(x.paidFees).toFixed(2)}`
                      : "00.00"}
                  </td>
                  {/* <td className="text-center">
                    {x.payType !== undefined ? x.payType : ""}
                  </td> */}
                  <td className="text-center">
                    {x.payType !== undefined
                      ? x.payType.charAt(0).toUpperCase() + x.payType.slice(1)
                      : "-"}
                  </td>
                  <td className="text-center">
                    {x.referenceId !== undefined && x.referenceId}
                  </td>
                  <td className="text-center ">
                    <span
                      role="button"
                      className={
                        pending
                          ? "px-4 py-2 badge badgeCol"
                          : "px-4 py-2 badge badgeFontCol"
                      }
                      onClick={() => {
                        if (!pending) {
                          warnToast("This payment is done");
                        } else {
                          setPendingFees(pending);
                          setShowPay(true);
                          setTermId(x._id);
                          setFees(x.termFees);
                        }
                      }}
                    >
                      {pending ? "Pay" : "Paid"}
                    </span>
                  </td>
                  <td>
                    <div className="text-center pointer">
                      {/* <Edit
                        id={x._id}
                        onClick={() => {
                          if (x.payType === "online" && !pending) {
                            warnToast("You cannot update the online terms");
                          } else if (pending) {
                            warnToast(
                              "Inorder to update.You must pay first !!"
                            );
                          } else {
                            setPendingFees(pending);
                            setShowPay(true);
                            setTermId(x._id);
                            setFees(x.paidFees.split(".")[0]);
                            setPayType(x.payType);
                            if (x.payType === "cheque") {
                              setPayDetails({
                                refId: x.referenceId,
                                Bank: x.bank,
                              });
                            } else {
                              setPayDetails({
                                refId: "",
                                Bank: "",
                              });
                            }
                          }
                        }}
                      /> */}
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-${x._id}`}>Edit</Tooltip>
                        }
                      >
                        <Edit
                          id={x._id}
                          onClick={() => {
                            if (x.payType === "online" && !pending) {
                              warnToast("You cannot update the online terms");
                            } else if (pending) {
                              warnToast(
                                "Inorder to update.You must pay first !!"
                              );
                            } else {
                              setPendingFees(pending);
                              setShowPay(true);
                              setTermId(x._id);
                              setFees(x.paidFees.split(".")[0]);
                              setPayType(x.payType);
                              if (x.payType === "cheque") {
                                setPayDetails({
                                  refId: x.referenceId,
                                  Bank: x.bank,
                                });
                              } else {
                                setPayDetails({
                                  refId: "",
                                  Bank: "",
                                });
                              }
                            }
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td className="text-center">
                    {/* {pending ? ( */}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-${x._id}`}>Delete</Tooltip>
                      }
                    >
                      {pending ? (
                        <Remove
                          fill="red"
                          onClick={() => {
                            setShow(true);
                            setTermId(x._id);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Remove
                          fill="red"
                          // onClick={() => {
                          //   setShow(true);
                          //   setTermId(x._id);
                          // }}
                          // style={{ disabled }}
                          className="disabled"
                        />
                      )}
                    </OverlayTrigger>
                    {/* ) : ( */}
                    {/* "" */}
                    {/* )} */}
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-${x._id}`}>Invoice</Tooltip>
                      }
                    >
                      <Edit
                        onClick={() => {
                          nav("/invoiceGen", {
                            state: x,
                          });
                        }}
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div className="col-md-12 mb-3 text-center">
          <CancelBtn path={`/paymentManagement?index=${param.get("index")}`} />
        </div> */}
      </div>
      <Modal
        show={showPay}
        onHide={handleClosePay}
        onShow={() => toggleCheckbox(payType)}
      >
        <Modal.Body>
          {pendingFees
            ? "Fill the details for payment."
            : "Fill the details you want to update."}
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <form action="" onSubmit={formikPay.handleSubmit}>
            <label className="col-md-12 mb-3" htmlFor="amount">
              <input
                className={`form-control ${
                  formikPay.errors.amount && border ? "empty-form" : ""
                }`}
                id="amount"
                name="amount"
                type="text"
                onBlur={formikPay.handleBlur}
                onChange={(e) => {
                  if (lastTermleft === 0) {
                    toastValidation(formikPay.errors);
                    formikPay.handleChange(e);
                  }
                }}
                value={formikPay.values.amount}
                placeholder="Enter Amount"
              />
              <span className="top-placeholder">Amount*</span>
            </label>

            {payType === "cheque" && (
              <label className="col-md-12 mb-3" htmlFor="chequeRefId">
                <input
                  className={`form-control ${
                    formikPay.errors.chequeRefId && border ? "empty-form" : ""
                  }`}
                  id="chequeRefId"
                  name="chequeRefId"
                  type="text"
                  onBlur={formikPay.handleBlur}
                  onChange={(e) => {
                    toastValidation(formikPay.errors);
                    formikPay.handleChange(e);
                  }}
                  value={formikPay.values.chequeRefId}
                  placeholder="Cheque Reference ID"
                />
                <span className="top-placeholder">Cheque Reference ID*</span>
              </label>
            )}
            {payType === "cheque" && (
              <label className="col-md-12 mb-3" htmlFor="bankName">
                <input
                  className={`form-control ${
                    formikPay.errors.bankName && border ? "empty-form" : ""
                  }`}
                  id="bankName"
                  name="bankName"
                  type="text"
                  onBlur={formikPay.handleBlur}
                  onChange={(e) => {
                    toastValidation(formikPay.errors);
                    formikPay.handleChange(e);
                  }}
                  value={formikPay.values.bankName}
                  placeholder="Bank Name"
                />
                <span className="top-placeholder">Bank Name*</span>
              </label>
            )}
            <Form.Check
              type="radio"
              label="Cash"
              id="cash"
              className="mb-3"
              style={{
                cursor: "pointer",
              }}
              name="payType"
              onChange={(e: any) => {
                if (e.target.id === "cash") {
                  setPayDetails({
                    refId: "",
                    Bank: "",
                  });
                }
                setPayType(e.target.id);
              }}
            />
            <Form.Check
              type="radio"
              label="Cheque"
              id="cheque"
              className="mb-3"
              style={{
                cursor: "pointer",
              }}
              name="payType"
              onChange={(e: any) => {
                setPayType(e.target.id);
              }}
            />
            <div className="text-center">
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  if (formikPay.errors) {
                    setBorder(true);
                    toastValidation(formikPay.errors);
                  } else {
                    setShowPay(false);
                  }
                }}
              >
                Submit
              </Button>
              <Button variant="secondary" onClick={handleClosePay}>
                Close
              </Button>
            </div>
          </form>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Are you sure for delete this term?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              handleDeleteTerm(termId);
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditForm;
