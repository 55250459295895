import React, { memo } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

type Props = {}

const RouteMember = (props: Props) => {
  const location = useLocation()

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Route Member</span>
        {location.pathname === '/routeMember' && (
          <Link to={'/routeMember/addForm'}>
            <button className="btn btn-primary" type="submit">
              Add
            </button>
          </Link>
        )}
      </div>
      <Outlet />
    </>
  )
}
export default memo(RouteMember)
