import React, { memo, useCallback, useEffect, useState } from "react";
import axiosInstance from "axiosInstance";
import socket from "socket";
import CardComponent from "../Dashboard/CardComponent";
import memberWait from "../../../assets/images/memberWait.png";
type Props = {};

const NoOfDriver: React.FC<{}> = (props: Props) => {
  const [clients, setClients] = useState(0);
  const loadClients = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/master/getDriverCount`);
      setClients(res.data.NoOfDriver);
    } catch (error) {
      console.log(error);
    }
  }, [clients]);
  useEffect(() => {
    loadClients();
    socket.on("admin-count", (data) => {
      if (data === "driver-count") {
        loadClients();
      }
    });
  }, []);
  return (
    <>
      <CardComponent
        count={clients}
        text="Number Of Drivers"
        background={memberWait}
      />
    </>
  );
};
export default memo(NoOfDriver);
