import { ConfigLocalType } from "components/SidebarMenu/sideBar";
import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";

type ConfigContextType = {
  isMultiShift: boolean;
  paymentCheck: boolean;
  rfidCardCheck: boolean;
  planType: string;
};

type Props = {
  children: ReactNode;
};

const ConfigContext = createContext<ConfigContextType>({
  isMultiShift: false,
  paymentCheck: false,
  rfidCardCheck: false,
  planType: "BAC",
});

function ConfigProvider({ children }: Props) {
  const [isMultiShift, setIsMultiShift] = useState(false);
  const [paymentCheck, setPaymentCheck] = useState(false);
  const [rfidCardCheck, setRfidCardCheck] = useState(false);
  const [planType, setPlanType] = useState("");
  const role = localStorage.getItem("role");
  useEffect(() => {
    const x = localStorage.getItem("config") as string;
    if (role === "ORG") {
      const config: ConfigLocalType = JSON.parse(x);
      setIsMultiShift(config.isMultiShift);
      setPaymentCheck(config.paymentCheck);
      setRfidCardCheck(config.rfidCardCheck);
      setPlanType(config.planType as string);
    }
  }, []);

  const value = { isMultiShift, paymentCheck, rfidCardCheck, planType };
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
}

function useConfig() {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within ConfigProvider");
  }
  return context;
}

export { ConfigProvider, useConfig };
