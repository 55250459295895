import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type FeesConfig = {
  _id: string;
  organizerId: string;
  feesType: string;
  annualRegistrationFees: string;
  startFeesYear: string;
  endFeesYear: string;
  createdAt: string;
  updatedAt: string;
};
type Props = {
  x: FeesConfig;
  configId: string;
  isPaymentMade: boolean;
  isClone: boolean;
  prevConfigId: string;
};

function formatMonthYear(dateTimeString: any) {
  const dateTime = new Date(dateTimeString);
  const month = dateTime.toLocaleString("en-US", { month: "long" });
  const year = dateTime.getFullYear();
  return `${month} (${year})`;
}

const FeesConfigListItem = ({
  x,
  configId,
  isPaymentMade,
  isClone,
  prevConfigId,
}: Props): JSX.Element => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit</Tooltip>;
  return (
    <tr key={x._id}>
      <td>{formatMonthYear(x.startFeesYear)}</td>
      <td>{formatMonthYear(x.endFeesYear)}</td>
      <td>{x.feesType.toUpperCase()}</td>
      <td className={"text-center pointer"}>
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            id={x._id}
            onClick={() => {
              const item = {
                ...x,
                configId: configId,
                isPaymentMade: isPaymentMade,
                isClone: isClone,
                prevConfigId: prevConfigId,
              };
              nav(
                {
                  pathname: "/transportationFeesMain/feesSectionEdit",
                },
                { state: item }
              );
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(FeesConfigListItem);
