import React, { memo } from "react";
import { ConfigurationDataType } from "../../../types/configurationTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: ConfigurationDataType;
};
type Config = {
  routeOptimization: boolean;
};

const ConfigurationListItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${x.id}`}>Edit </Tooltip>;
  const RouteOptimizationValue: Config | null = JSON.parse(
    localStorage.getItem("config") || "null"
  );
  const isRouteOptimization = RouteOptimizationValue?.routeOptimization;
  return (
    <>
      {isRouteOptimization ? (
        <tr key={x.id}>
          <td>{x.description}</td>
          <td>
            {typeof x.value === "boolean"
              ? `${x.value === true ? `true` : `false`}`
              : `${
                  x.value === "true" || x.value === "false"
                    ? x.value === "true"
                      ? `Yes`
                      : `No`
                    : x.value
                }`}
          </td>
          <td className="text-center">
            <OverlayTrigger placement="right" overlay={tooltip}>
              <Edit
                id={x.id}
                className="pointer"
                onClick={() => {
                  nav("configurationEdit", {
                    state: x,
                  });
                }}
              />
            </OverlayTrigger>
          </td>
        </tr>
      ) : (
        <>
          {x.key !== "manualAutoLocation" && (
            <tr key={x.id}>
              <td>{x.description}</td>
              <td>
                {typeof x.value === "boolean"
                  ? `${x.value === true ? `true` : `false`}`
                  : `${
                      x.value === "true" || x.value === "false"
                        ? x.value === "true"
                          ? `Yes`
                          : `No`
                        : x.value
                    }`}
              </td>
              <td className="text-center">
                <OverlayTrigger placement="right" overlay={tooltip}>
                  <Edit
                    id={x.id}
                    className="pointer"
                    onClick={() => {
                      nav("configurationEdit", {
                        state: x,
                      });
                    }}
                  />
                </OverlayTrigger>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

export default memo(ConfigurationListItem);
