import { memo, useCallback, useEffect, useState } from "react";
import ListItem, { ComplaintsType } from "./ListItem";
import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";
import { useSearchParams } from "react-router-dom";

type Props = {};
const ComplaintsList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [param] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("-createdAt");
  const [searchCount, setSearchCount] = useState(0);
  const [listData, setListData] = useState<ComplaintsType[]>([]);
  const { notfound, setNotFound } = useNotFound();
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const LoadData = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(
          `/master/getAllDriverComplaintsForOrg?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}&today=${String(
            param.get("today")
          )}`
        );
        // setSearchCount(res.data.count);
        // const data: ComplaintsType[] = [...listData, ...res.data.result];
        // setListData(data);
        const newComplaintList: ComplaintsType[] = [...res.data.result];
        if (searchKeyword !== "" && pageNum === 1) {
          if (newComplaintList.length === 0) {
            setNotFound(true);
            setListData([]);
            setSearchCount(0);
          }
        }
        if (newComplaintList.length > 0) {
          setSearchCount(res.data.count);
          if (pageNum === 1) {
            setListData(newComplaintList);
          } else {
            setListData((prevList) => [...prevList, ...newComplaintList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [listData]
  );

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    LoadData(1, sort, search);
  };

  useEffect(() => {
    LoadData(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(listData, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Member or Driver"
      />
      <table className="table">
        <thead>
          <tr>
            <th id="memberPhoto">Photo</th>

            <th
              id="memberName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Member
              <span className="px-2">
                {sort === "memberName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th id="driverPhoto">Photo</th>
            <th
              id="driverName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Driver
              <span className="px-2">
                {sort === "driverName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Complaint Date
              <span className="px-2">
                {sort === "createdAt" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th id="complaintMessage">Complaint</th>
            <th id="complaintAnswer">Answer</th>

            <th
              id="complaintStatus"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Status
              <span className="px-2">
                {sort === "complaintStatus" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>

            {/* <th
              className="text-center"
              id="isActive"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Active
            </th> */}
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {listData?.map((item: ComplaintsType) => {
              return <ListItem item={item} key={item._id} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(ComplaintsList);
