import React from "react";
import { useLocation, Link } from "react-router-dom";

type Props = {};

const AdminMessageEditForm = (props: Props) => {
  const location = useLocation();
  const { state } = useLocation();
  return (
    <>
      <div className="formBg">
        <form>
          <div className="row align-items-center">
            <ul className="profileList">
              <li>
                <span>Name :</span>
                {state.name}
              </li>
              <li>
                <span>Email:</span>
                {state.email}
              </li>
              <li>
                <span>Contact No :</span>
                {state.contactNo}
              </li>
            </ul>
            <ul className="profileList">
              <li>
                <span>Message : </span> <br />
                <br /> {state.message}
              </li>
            </ul>
          </div>
          <div className="text-center col-md-12 mb-3">
            {(location.pathname === "/adminMessage/messageForm" ||
              location.pathname === "/adminMessage/messageView") && (
              <Link to={"/adminMessage"}>
                <button className="btn btn-primary" type="submit">
                  Cancel
                </button>
              </Link>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminMessageEditForm;
