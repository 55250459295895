import React, { useState, memo } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";

type Props = {};
type ContextType = {
  setAdminRequestId: (adminRequestId: string) => void;
  adminRequestId: string;
};

const AdminRequest: React.FC<{}> = (props: Props) => {
  const location = useLocation();
  const [adminRequestId, setAdminRequestId] = useState<string>("");
  return (
    <>
      {location.pathname === '/adminRequest' ? <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Request For Demo</span>
        {location.pathname === "/adminRequest"}
      </div> : ''}
      <Outlet context={{ adminRequestId, setAdminRequestId }} />
    </>
  );
};

export default memo(AdminRequest);
export function useAdminRequestId() {
  return useOutletContext<ContextType>();
}
