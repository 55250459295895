import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { DriverLeaveDataType } from "../../../types/driverLeaveTypes";
import DriverLeaveListItem from "./driverLeaveListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import AddSearch from "components/AddSearchComponent";
import { useSearchParams } from "react-router-dom";

type Props = {
  setIndex: (index: number) => void;
};

const DriverLeaveList = ({ setIndex }: Props): JSX.Element => {
  const [param] = useSearchParams();
  const [driverLeave, setDriverLeave] = useState<DriverLeaveDataType[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("createdAt");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { notfound, setNotFound } = useNotFound();

  const loadDriverLeave = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllDriverLeave?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}${
            param.get("today")
              ? `&today=${String(param.get("today"))}`
              : "&today="
          }`
        );
        const driverLv: DriverLeaveDataType[] = [
          ...res.data.DriverLeaves[0]?.paginatedResults,
        ];
        if (driverLv.length === 0) {
          setNotFound(true);
          if (searchKeyword !== "" && pageNum === 1) {
            setDriverLeave([]);
            setSearchCount(0);
          }
        }
        if (driverLv.length > 0) {
          setSearchCount(res.data.DriverLeaves[0]?.totalCount[0]?.count);
          if (pageNum === 1) {
            console.log("IN page 1");
            setDriverLeave(driverLv);
          } else {
            console.log("IN page 2");
            setDriverLeave((prevList) => [...prevList, ...driverLv]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [driverLeave]
  );
  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadDriverLeave(1, sort, searchQuery); // Trigger API call with updated search keyword
  };

  useEffect(() => {
    loadDriverLeave(page, sort, searchQuery);
  }, [page, sort, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(driverLeave, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Driver Name"
      />
      <table className="table">
        <thead>
          <tr>
            <th id="driverPhoto">Photo</th>
            <th
              id="driverName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setDriverLeave,
                  setPage
                );
              }}
            >
              Driver Name
              <span className="px-2">
                {sort === "driverName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="vehicleName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setDriverLeave,
                  setPage
                );
              }}
            >
              Vehicle Name
              <span className="px-2">
                {sort === "vehicleName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="routeName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setDriverLeave,
                  setPage
                );
              }}
            >
              Route Name
              <span className="px-2">
                {sort === "routeName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="startDate"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setDriverLeave,
                  setPage
                );
              }}
            >
              Leave From
              <span className="px-2">
                {sort === "startDate" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="endDate"
              role="button"
              onClick={(e) => {
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setDriverLeave,
                  setPage
                );
              }}
            >
              Leave Upto
              <span className="px-2">
                {sort === "endDate" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th>Leave Type</th>
            <th>Approved</th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setDriverLeave,
                  setPage
                );
              }}
            >
              Issued on
              <span className="px-2">
                {sort === "createdAt" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {driverLeave.map((x) => {
              return (
                <DriverLeaveListItem
                  x={x}
                  key={x._id}
                  setIndex={setIndex}
                  loadDriverLeave={loadDriverLeave}
                />
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(DriverLeaveList);
