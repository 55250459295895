import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { successToast, warnToast } from "constants/toastConfig";

type Props = {};

const DriverForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const location = useLocation();
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      address: "",
      email: "",
      contactNo: "",
      pinCode: "",
      photo: "",
      drivingLicenceDoc: "",
      adharCardDoc: "",
      description: "",
      isActive: true,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Driver Name is Too Short!")
        .max(30, "Driver Name is Too Long!")
        .required("Driver Name is Required"),
      contactNo: Yup.string()
        .min(10, "Contact Number is Not Valid!")
        .max(10, "Contact Number is Not Valid!")
        .required("Contact Number is Required"),
      address: Yup.string()
        .min(1, "Address is Too Short!")
        .max(300, "Address is Too Long!")
        .required("Address is Required"),
      email: Yup.string().email().max(50),
      pinCode: Yup.string()
        .min(6, "PIN Code is Not Valid!")
        .max(6, "PIN Code is Not Valid!")
        .required("PIN Code is Required"),
      description: Yup.string().max(300, "Description is too Long!"),
      photo: Yup.mixed()
        .test("fileSize", "photo size is too high", (value) => {
          if (!value) return true; // Skip the test if no file is selected
          return value && value.size <= 52428800; // 50MB in bytes
        })
        .test(
          "fileType",
          "Please Enter photo in jpg, jpeg, png Extention",
          (value) => {
            if (!value) return true; // Skip the test if no file is selected
            return value && /(\.jpg|\.jpeg|\.png)$/i.test(value.name); // Check if the file has a valid extension
          }
        ),

      drivingLicenceDoc: Yup.mixed()
        .test(
          "fileSize",
          "Driving Licence Document size is too high",
          (value) => {
            if (!value) return true; // Skip the test if no file is selected
            return value && value.size <= 209715200; // 200MB in bytes
          }
        )
        .test(
          "fileType",
          "Please Enter Driving Licence Document in jpg,jpeg,png,pdf,docx extension",
          (value) => {
            if (!value) return true; // Skip the test if no file is selected
            return value && /\.(jpg|jpeg|png|pdf|docx?)$/i.test(value.name); // Check if the file has a valid extension
          }
        ),

      adharCardDoc: Yup.mixed()
        .test("fileSize", "AdharCard Document size is too high", (value) => {
          if (!value) return true; // Skip the test if no file is selected
          return value && value.size <= 209715200; // 200MB in bytes
        })
        .test(
          "fileType",
          "Please Enter AdharCard Document in jpg,jpeg,png,pdf,docx extension",
          (value) => {
            if (!value) return true; // Skip the test if no file is selected
            return value && /\.(jpg|jpeg|png|pdf|docx?)$/i.test(value.name); // Check if the file has a valid extension
          }
        ),

      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      name,
      photo,
      drivingLicenceDoc,
      adharCardDoc,
      description,
      isActive,
      address,
      contactNo,
      email,
      pinCode,
    }) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("photo", photo);
        formData.append("drivingLicenceDoc", drivingLicenceDoc);
        formData.append("adharCardDoc", adharCardDoc);
        formData.append("description", description);
        formData.append("isActive", String(isActive));
        formData.append("address", address);
        formData.append("contactNo", String(contactNo)); // Assuming 'photo' is the selected file object
        formData.append("email", email);
        formData.append("pinCode", String(pinCode));
        formData.append("bloodGroup", "");
        formData.append("dob", "");
        formData.append("note", "");
        formData.append("details", "");
        const res = await axiosInstance.post("/master/addDriver", formData);
        const responseMessage = res.data;
        if (res.status === 202) {
          setLoading(false);
          warnToast(responseMessage);
        }
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          nav("/driver");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className=" row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Driver Name"
              />

              <span className="top-placeholder">Driver Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="contactNo">
              <input
                className={`form-control ${
                  formik.errors.contactNo && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="contactNo"
                name="contactNo"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.contactNo}
                placeholder="Contact Number"
              />

              <span className="top-placeholder">Contact Number*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="address">
              <input
                className={`form-control ${
                  formik.errors.address && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="address"
                name="address"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address}
                placeholder="Address"
              />

              <span className="top-placeholder">Address*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="email">
              <input
                className="form-control"
                id="email"
                name="email"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="Email Address"
              />

              <span className="top-placeholder">Email Address</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="pinCode">
              <input
                className={`form-control ${
                  formik.errors.pinCode && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="pinCode"
                name="pinCode"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.pinCode}
                placeholder="PIN Code"
              />

              <span className="top-placeholder">PIN Code*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="photo">
              <input
                className="form-control uploadPhoto form-control-lg"
                id="photo"
                name="photo"
                type="file"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const file =
                    event.currentTarget.files && event.currentTarget.files[0];
                  formik.setFieldValue("photo", file || ""); // If file is null, set an empty string
                }}
                placeholder="Enter photo"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="drivingLicenceDoc">
              <input
                className="form-control uploadDrivingLicence form-control-lg"
                id="drivingLicenceDoc"
                name="drivingLicenceDoc"
                type="file"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const file =
                    event.currentTarget.files && event.currentTarget.files[0];
                  formik.setFieldValue("drivingLicenceDoc", file || ""); // If file is null, set an empty string
                }}
                placeholder="Upload Licence Document"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="adharCardDoc">
              <input
                className="form-control adharCard form-control-lg"
                id="adharCardDoc"
                name="adharCardDoc"
                type="file"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const file =
                    event.currentTarget.files && event.currentTarget.files[0];
                  formik.setFieldValue("adharCardDoc", file || ""); // If file is null, set an empty string
                }}
                placeholder="Upload AdharCard Document"
              />
            </label>
            <label className="col-md-12 mb-3" htmlFor="description">
              <textarea
                className="form-control"
                id="description"
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                placeholder="Driver Description"
              ></textarea>

              <span className="top-placeholder">Driver Description</span>
            </label>
            <div className="col-md-3 mb-3">
              <label className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.isActive}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            {(location.pathname === "/driver/driverForm" ||
              location.pathname === "/driver/driverEdit") && (
              <CancelBtn path=".." />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(DriverForm);
