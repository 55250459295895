import { memo, useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import MapForm from "components/MapTrial";
import { useFeesConfig } from "Context/FeesConfigurationContext";
import { useAreaEdit } from "Context/AreaEditContext";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import { LatLongObj } from "constants/Constants";

type Props = {
  editIndex?: number;
};
type Config = {
  routeOptimization: boolean;
};
const LocationAddForm = ({ editIndex }: Props) => {
  const nav = useNavigate();
  const { area } = useAreaEdit();
  const [division, setDivision] = useState<AreaDivisionDDL[]>();
  const [divId, setDivId] = useState("");
  const { feesConfigType } = useFeesConfig();
  const { setLoading } = useLoading();
  const { setEditIndex } = useAreaEdit();
  const [border, setBorder] = useState<boolean>(false);
  const RouteOptimizationValue: Config | null = JSON.parse(
    localStorage.getItem("config") || "null"
  );
  const isRouteOptimization = RouteOptimizationValue?.routeOptimization;
  const settingMarker = (
    nameX: string,
    areaX: string | undefined,
    addressX?: string
  ) => {
    if (
      (typeof areaX === "string" && areaX === "") ||
      (typeof areaX === "undefined" && nameX !== "")
    ) {
      const value = `${nameX},${addressX}`;
      return value;
    } else {
      const value = `${nameX},${addressX},${areaX}`;
      return value;
    }
  };

  const loadAreaCode = useCallback(async (pinCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getAreaDivision?pinCode=${pinCode}`
      );
      setDivision(res.data.division);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      code: "",
      address1: "",
      address2: "",
      latitude: "",
      longitude: "",
      kilometer: "",
      description: "",
      note: "",
      details: "",
      active: true,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Location Name is too Short!")
        .max(30, "Location Name is too Long!")
        .required("Location Name is Required"),
      code: Yup.string()
        .min(1, "Code is too Short!")
        .max(10, "Code is too Long!"),
      address1: Yup.string()
        .min(1, "Address 1 is too Short!")
        .max(300, "Address 1 is too Long!")
        .required("Address1 is Required"),
      address2: Yup.string().max(300, "Address2 is too Long!"),
      // latitude: Yup.string()
      //   .min(1)
      //   // .matches(latitudeRegex, 'Invalid latitude.')
      //   .required("Latitude is Required"),
      // longitude: Yup.string()
      //   .min(1)
      //   // .matches(longitudeRegex, 'Invalid longitude.')
      //   .required("Longitude is Required"),
      kilometer:
        feesConfigType === "km"
          ? Yup.string()
              .min(1, "kilometer is too Short!")
              .max(30, "kilometer is too Long!")
              .required("kilometer is Required")
          : Yup.string().nullable(true),
      description: Yup.string().max(300, "Description is too long!"),
      note: Yup.string().max(300, "Note is too long!"),
      details: Yup.string().max(300, "Details is too long!"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({
      name,
      code,
      address1,
      address2,
      latitude,
      longitude,
      kilometer,
      description,
      active,
    }) => {
      if (divId === "") {
        return;
      }

      setLoading(true);
      try {
        const formData = {
          name: name,
          code: code,
          areaCode: divId,
          address1: address1,
          address2: address2,
          pinCode: String(area?.pinCode),
          latitude: String(latitude) || LatLongObj.latitude,
          longitude: String(longitude) || LatLongObj.longitude,
          kilometer: String(kilometer),
          description: "",
          isActive: active,
          note: "",
          details: "",
        };
        const res = await axiosInstance.post("/master/addLocation", formData);
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          if (editIndex) {
            nav("/areaMaster/editForm", { state: { item: area } });
            setEditIndex(editIndex);
          } else nav("..");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  useEffect(() => {
    loadAreaCode(area?.pinCode);
  }, [loadAreaCode]);

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="areaCode">
              <span className="top-placeholder">Division*</span>

              <select
                className={`form-control ${
                  divId === "" && border ? "empty-form" : ""
                }`}
                id="areaCode"
                name="areaCode"
                onChange={(e) => {
                  setDivId(e.target.value);
                }}
              >
                <option value="default">Select Division</option>
                {division?.map(({ _id, areaCode }) => {
                  return (
                    <option key={_id} value={_id}>
                      {areaCode}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="name">
              <span className="top-placeholder">Location Name*</span>

              <input
                className={`form-control ${
                  formik.errors.name && border ? "empty-form" : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={(e) => {
                  formik.handleBlur(e);
                  const loc = settingMarker(
                    e.target.value,
                    area?.areaName,
                    formik.values.address1
                  );
                  formik.setFieldValue("searchLocation", loc);
                }}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Location Name"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="code">
              <span className="top-placeholder">Code</span>

              <input
                className="form-control"
                id="code"
                name="code"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                placeholder="Code"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="address1">
              <span className="top-placeholder">Address1*</span>

              <input
                className={`form-control ${
                  formik.errors.address1 && border ? "empty-form" : ""
                }`}
                id="address1"
                name="address1"
                type="text"
                onBlur={(e) => {
                  formik.handleBlur(e);
                  const loc = settingMarker(
                    formik.values.name,
                    area?.areaName,
                    e.target.value
                  );
                  formik.setFieldValue("searchLocation", loc);
                }}
                onChange={formik.handleChange}
                placeholder="Address1"
                value={formik.values.address1}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="address2">
              <span className="top-placeholder">Address2</span>

              <input
                className="form-control"
                id="address2"
                name="address2"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address2}
                placeholder="Address2"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="latitude">
              <span className="top-placeholder">Latitude</span>

              <input
                className="form-control"
                id="latitude"
                name="latitude"
                type="text"
                onBlur={formik.handleBlur}
                onChange={() => {}}
                value={formik.values.latitude}
                placeholder="Latitude"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="longitude">
              <span className="top-placeholder">Longitude</span>

              <input
                className="form-control"
                id="longitude"
                name="longitude"
                type="text"
                onBlur={formik.handleBlur}
                onChange={() => {}}
                value={formik.values.longitude}
                placeholder="Longitude"
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="kilometer">
              <span className="top-placeholder">Kilometer</span>

              <input
                className="form-control"
                id="kilometer"
                name="kilometer"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.kilometer}
                placeholder="Kilometer"
              />
            </label>

            <div className="col-md-3 mb-3">
              <label className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          {isRouteOptimization && <MapForm form={formik} />}
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                setBorder(true);
                if (divId === "") {
                  warnToast("Select division");
                }
                toastValidation(formik.errors);
              }}
            />
            <CancelBtn
              path={editIndex ? "/areaMaster/editForm" : ".."}
              state={editIndex ? { item: area } : null}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(LocationAddForm);
