import axiosInstance from "axiosInstance";
import React, { useEffect, useState } from "react";
import { StopMarker } from "types/alltrackingTypes";
import lowtemp from "../../../assets/images/lowTemp.png";

const StopDriverList = () => {
  const [stopDriverData, setStopDriverData] = useState<StopMarker[]>([]);

  const stopDriver = async () => {
    try {
      const res = await axiosInstance.get(`/dropdown/getStopCount`);
      const spdData = res.data.result;
      setStopDriverData(spdData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    stopDriver(); // Initial call to fetch data

    const intervalId = setInterval(() => {
      stopDriver(); // Call the function every 1 minute
    }, 60000); // 60000 milliseconds = 1 minute

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, []);

  return (
    <>
      {stopDriverData.length > 0 &&
        stopDriverData.map((item, index) => {
          const lastRecord = item.records[item.records.length - 1];
          return (
            <div
              className="infoBox mb-4"
              key={index}
              style={{
                backgroundImage: `url(${lowtemp})`,
                backgroundSize: "contain",
                backgroundPosition: "right",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="infoLeft">
                <span>{item.driverName}</span>
                {lastRecord.duration}
                <span>{item.routeName}</span>
              </div>
              {/* <i className="infoRgt"><img src={image} alt="Logo" /></i> */}
            </div>
          );
        })}
    </>
  );
};
export default StopDriverList;
