import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect } from "react";
import { CategoryDDL } from "../../../types/categoryTypes";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { TypeDDL } from "../../../types/typeMasterTypes";
import { DepartmentDDLType } from "types/departmentMasterType";
import { useLoading } from "Context/LoadingContext";
import { MemberDataType } from "types/MemberMaster.types";
import { AreaDDLType } from "types/areaMasterTypes";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import { LocationDataTypeDDL } from "types/locationMasterType";
import { MemberAssignmentDataType } from "types/memberAssignment";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import CancelBtn from "components/Buttons/Cancel";
import SubmitBtn from "components/Buttons/Submit";
import AssignmentAddForm from "./AssignmentAddForm";

type Props = {
  state: any;
};

const AssignmentEditForm = ({ state }: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [memAssign, setmemAssign] = useState<MemberAssignmentDataType>();
  const pathState = useLocation();
  // const [memberDetail, setMemberDetail] = useState<MemberDataType>();
  // const [memberId, setMemberId] = useState("");
  const [isAddForm, setIsAddForm] = useState(false);
  /*pickUp states */
  const [area, setArea] = useState<AreaDDLType[]>([]);
  const [pin, setPin] = useState("");

  const [division, setDivision] = useState<AreaDivisionDDL[]>([]);
  const [divId, setDivId] = useState("");

  const [location, setLocation] = useState<LocationDataTypeDDL[]>([]);
  const [locId, setLocId] = useState("");

  /*drop states */
  // const [dropArea, setDropArea] = useState<AreaDDLType[]>([]);
  // const [dropPin, setDropPin] = useState("");

  // const [dropDivision, setDropDivision] = useState<AreaDivisionDDL[]>([]);
  // const [dropDivId, setDropDivId] = useState("");

  // const [dropLocation, setDropLocation] = useState<LocationDataTypeDDL[]>([]);
  // const [dropLocId, setDropLocId] = useState("");

  const loadAreaCode = useCallback(async (pinCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getAreaDivision?pinCode=${pinCode}`
      );
      setLoading(false);
      return res.data.division;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadLocation = useCallback(async (areaCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getLocationByDivision?areaCode=${areaCode}`
      );
      setLoading(false);
      const location = res.data.locationNames;
      return location;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  /* pickUp Dropdowns */
  const loadAreas = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getArea`);
      //   setArea(res.data.Areas)
      const areas = res.data.Areas;
      setLoading(false);
      return areas;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const getOneMemAssign = async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getOneMemberAssignmentByMem/${state?.member?._id}`
      );

      if (state?.memAssign === undefined && !res.data.AssignedMember) {
        // nav("../addForm/assignmentAdd?edit=1", { state: state });
        setIsAddForm(true);
        return;
      }
      setIsAddForm(false);
      const memAssigned = res.data.AssignedMember;
      setmemAssign(memAssigned);
      pathState.state.memAssign = memAssigned;
      const area = await loadAreas();
      if (area === undefined) {
        setArea([]);
        // setDropArea([]);
        // setDropPin("");
        setPin("");
      } else {
        const newpickUpAreaList = updateArealist(area, memAssigned.pickUpPin);
        setArea(newpickUpAreaList);
        setPin(memAssigned.pickUpPin);

        // const newAreaDropList = updateArealist(area, memAssigned.dropPin);
        // setDropArea(newAreaDropList);
        // setDropPin(memAssigned.dropPin);
      }

      //division
      const pickDiv = await loadAreaCode(memAssigned.pickUpPin);
      const dropDiv = await loadAreaCode(memAssigned.dropPin);
      if (pickDiv === undefined) {
        setDivision([]);
        setDivId("");
      } else if (dropDiv === undefined) {
        // setDropDivision([]);
        // setDropDivId("");
      } else {
        const newPickUpDivList = updateList(pickDiv, memAssigned?.pickUpDiv);
        setDivision(newPickUpDivList as AreaDivisionDDL[]);
        setDivId(newPickUpDivList[0]._id);

        // const newDropDivList = updateList(dropDiv, memAssigned?.dropDiv);
        // setDropDivision(newDropDivList as AreaDivisionDDL[]);
        // setDropDivId(newDropDivList[0]._id);
      }

      //location
      const pickLoc = await loadLocation(memAssigned.pickUpDiv);
      const dropLoc = await loadLocation(memAssigned.dropDiv);
      if (pickLoc === undefined) {
        setLocation([]);
        setLocId("");
      } else if (dropLoc === undefined) {
        // setDropLocation([]);
        // setDropLocId("");
      } else {
        const newPickUpLocList = updateLocList(pickLoc, memAssigned?.pickUpId);
        setLocation(newPickUpLocList as LocationDataTypeDDL[]);
        setLocId(newPickUpLocList[0].locationId);

        // const newDropLocList = updateLocList(dropLoc, memAssigned?.dropId);
        // setDropLocation(newDropLocList as LocationDataTypeDDL[]);
        // setDropLocId(newDropLocList[0].locationId);
      }
    } catch (error) {
      console.log(error);
      setIsAddForm(true);
      // nav("../addForm/assignmentAdd", { state: state.member });
    }
  };

  const updateList = (
    list:
      | DepartmentDDLType[]
      | CategoryDDL[]
      | TypeDDL[]
      | MemberDataType[]
      | AreaDivisionDDL[],
    id: string | undefined
  ) => {
    const newList = [...list];
    const record = newList?.find(
      (
        x:
          | DepartmentDDLType
          | CategoryDDL
          | TypeDDL
          | MemberDataType
          | AreaDivisionDDL
      ) => x?._id === id
    );
    const index = newList.indexOf(
      record as
        | DepartmentDDLType
        | CategoryDDL
        | TypeDDL
        | MemberDataType
        | AreaDivisionDDL
    );
    newList.splice(index, 1);
    newList.splice(
      0,
      0,
      record as
        | DepartmentDDLType
        | CategoryDDL
        | TypeDDL
        | MemberDataType
        | AreaDivisionDDL
    );
    return newList;
  };
  const updateLocList = (
    list: LocationDataTypeDDL[],
    id: string | undefined
  ) => {
    const newList = [...list];
    const record = newList?.find(
      (x: LocationDataTypeDDL) => x?.locationId === id
    );
    const index = newList.indexOf(record as LocationDataTypeDDL);
    newList.splice(index, 1);
    newList.splice(0, 0, record as LocationDataTypeDDL);
    return newList;
  };
  const updateArealist = (list: AreaDDLType[], id: string) => {
    const newList = [...list];
    const record = newList?.find((x: AreaDDLType) => x?.pinCode === id);
    const index = newList.indexOf(record as AreaDDLType);
    newList.splice(index, 1);
    newList.splice(0, 0, record as AreaDDLType);
    return newList;
  };

  useEffect(() => {
    getOneMemAssign();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: "",
      details: "",
      active: memAssign?.isActive,
      isSame: memAssign?.isSame,
    },
    onSubmit: async ({ active, isSame }) => {
      try {
        // if (isSame) {
        if (locId === "" || pin === "" || divId === "") {
          return;
        }
        setLoading(true);
        const formData = {
          memberId: state._id,
          pickUpId: locId,
          pickUpPin: pin,
          pickUpDiv: divId,
          dropId: locId,
          dropPin: pin,
          dropDiv: divId,
          description: "",
          note: "",
          details: "",
          isSame: true,
          isActive: active,
        };
        const res = await axiosInstance.put(
          `/master/updateMemberAssignment/${memAssign?._id}`,
          formData
        );
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          pathState.state.memAssign = res.data.result;

          successToast();
          setLoading(false);
        }
        // } else {
        //   if (
        //     memberId === "" ||
        //     locId === "" ||
        //     pin === "" ||
        //     divId === "" ||
        //     dropLocId === "" ||
        //     dropPin === "" ||
        //     dropDivId === ""
        //   ) {
        //     return;
        //   }
        //   setLoading(true);
        //   const formData = {
        //     memberId: memberId,
        //     pickUpId: locId,
        //     pickUpPin: pin,
        //     pickUpDiv: divId,
        //     dropId: dropLocId,
        //     dropPin: dropPin,
        //     dropDiv: dropDivId,
        //     description: "",
        //     note: "",
        //     details: "",
        //     isSame: isSame,
        //     isActive: active,
        //   };
        //   const res = await axiosInstance.put(
        //     `/master/updateMemberAssignment/${x._id}`,
        //     formData
        //   );
        //   const responseMessage = res.data;
        //   if (responseMessage === "Ok") {
        //     successToast();
        //     setLoading(false);
        //     nav("/memberAssign");
        //   }
        // }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  // const catDropDown = async (x: string) => {
  //   setCatId(x);
  //   setTypeId("");
  //   setMemberId("");
  //   setMemberDetail({} as MemberDataType);
  //   if (x !== "") {
  //     const typ = await loadTypes(x);
  //     if (typ === undefined) {
  //       setType([]);
  //     } else {
  //       setType(typ as TypeDDL[]);
  //     }
  //     const mem = await loadMembers(depId, x);
  //     if (mem === undefined) {
  //       setMember([]);
  //     } else {
  //       setMember(mem);
  //     }
  //   } else {
  //     const mem = await loadMembers(depId);
  //     if (mem === undefined) {
  //       setMember([]);
  //     } else {
  //       setMember(mem);
  //     }
  //     setType([]);
  //   }
  // };
  // const depDropDown = async (y: string) => {
  //   setDepId(y);
  //   setCatId("");
  //   setTypeId("");
  //   setMemberId("");
  //   setMemberDetail({} as MemberDataType);
  //   if (y !== "") {
  //     const cat = await loadCategories(y);
  //     if (cat === undefined) {
  //       setCategory([]);
  //       setType([]);
  //     } else {
  //       setCategory(cat as CategoryDDL[]);
  //     }
  //     const mem = await loadMembers(y);
  //     if (mem === undefined) {
  //       setMember([]);
  //     } else {
  //       setMember(mem);
  //     }
  //   } else {
  //     setCategory([]);
  //     setType([]);
  //     setMember([]);
  //   }
  // };
  // const typeDropDown = async (z: string) => {
  //   setTypeId(z);
  //   setMemberId("");
  //   setMemberDetail({} as MemberDataType);
  //   if (z !== "") {
  //     const mem = await loadMembers(depId, catId, z);
  //     if (mem === undefined) {
  //       setMember([]);
  //     } else {
  //       setMember(mem);
  //     }
  //   } else {
  //     const mem = await loadMembers(depId, catId);
  //     if (mem === undefined) {
  //       setMember([]);
  //     } else {
  //       setMember(mem);
  //     }
  //   }
  // };

  /* Pick Up dropdowns */
  const areaDropDown = async (m: string) => {
    setPin(m);
    setDivId("");
    setLocId("");
    if (m !== "") {
      const div = await loadAreaCode(m);
      if (div !== undefined) {
        setDivision(div);
      } else {
        setDivision([]);
      }
    } else {
      setDivision([]);
      setLocation([]);
    }
  };
  const divisionDropDown = async (n: string) => {
    setDivId(n);
    setLocId("");
    if (n !== "") {
      const loc = await loadLocation(n);
      if (loc !== undefined) {
        setLocation(loc);
      } else {
        setLocation([]);
      }
    } else {
      setLocation([]);
    }
  };

  /* Drop location dropdowns */
  // const dropAreaDropDown = async (m: string) => {
  //   setDropPin(m);
  //   setDropDivId("");
  //   setDropLocId("");
  //   if (m !== "") {
  //     const div = await loadAreaCode(m);
  //     if (div !== undefined) {
  //       setDropDivision(div);
  //     } else {
  //       setDropDivision([]);
  //     }
  //   } else {
  //     setDropDivision([]);
  //     setDropLocation([]);
  //   }
  // };
  // const dropDivisionDropDown = async (n: string) => {
  //   setDropDivId(n);
  //   setDropLocId("");
  //   if (n !== "") {
  //     const loc = await loadLocation(n);
  //     if (loc !== undefined) {
  //       setDropLocation(loc);
  //     } else {
  //       setDropLocation([]);
  //     }
  //   } else {
  //     setDropLocation([]);
  //   }
  // };

  return (
    <>
      {isAddForm ? (
        <AssignmentAddForm isFromEdit={true} />
      ) : (
        <div className="formBg">
          <form
            className="row align-items-center "
            onSubmit={formik.handleSubmit}
          >
            <ul className="profileList">
              <li>
                <span>Member :</span>
                {state.member?.name}
              </li>
              <li>
                <span>Area :</span>
                {state.member?.area}
              </li>
              <li>
                <span>PIN Code :</span>
                {state.member?.pinCode}
              </li>
              <li>
                <span>Address :</span>
                {state.member?.address}
              </li>
            </ul>
            <div className="justify-content-between align-items-center d-flex my-3">
              <span>Pickup Details</span>
            </div>
            <div className="mb-2 row align-items-center">
              <label htmlFor="area" className="col-md-6 mb-3">
                <span className="top-placeholder">Area*</span>

                <select
                  className="form-control"
                  id="area"
                  onChange={async (e) => {
                    areaDropDown(e.target.value);
                  }}
                >
                  {pin === "" && <option value="">Select Area</option>}
                  {area.map(({ _id, areaName, pinCode }) => {
                    return (
                      <option key={_id} value={pinCode}>
                        {`${areaName} (${pinCode})`}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="col-md-6 mb-3" htmlFor="areaCode">
                <span className="top-placeholder">Division*</span>

                <select
                  className="form-control"
                  id="areaCode"
                  name="areaCode"
                  onChange={async (e) => {
                    divisionDropDown(e.target.value);
                  }}
                >
                  {divId === "" && <option value="">Select Division</option>}
                  {division.map(({ _id, areaCode }) => {
                    return (
                      <option key={_id} value={_id}>
                        {areaCode}
                      </option>
                    );
                  })}
                </select>
              </label>
              <label className="col-md-6 mb-3" htmlFor="location">
                <span className="top-placeholder">Location*</span>

                <select
                  className="form-control"
                  id="location"
                  name="location"
                  onChange={async (e) => {
                    setLocId(e.target.value);
                  }}
                >
                  {locId === "" && <option value="">Select Location</option>}
                  {location.map(({ locationId, locationName }) => {
                    return (
                      <option key={locationId} value={locationId}>
                        {locationName}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
            {/* <div className="col-md-12 mb-3 d-flex">
          <span className="mx-2">pickUp and drop location are same.</span>
          <div className="col-md-3 mb-3">
            <label className="custCheck">
              Yes
              <input
                type="checkbox"
                id="isSame"
                name="isSame"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                checked={formik.values.isSame}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        {!formik.values.isSame && (
          <div className="justify-content-between align-items-center d-flex my-3">
            <span>Drop Details</span>
          </div>
        )}
        {!formik.values.isSame && (
          <div className="mb-2 row align-items-center">
            <label htmlFor="area" className="col-md-6 mb-3">
              <select
                className="form-control"
                id="area"
                onChange={async (e) => {
                  dropAreaDropDown(e.target.value);
                }}
              >
                {dropPin === "" && <option value="">Select Area</option>}
                {dropArea.map(({ _id, areaName, pinCode }) => {
                  return (
                    <option key={_id} value={pinCode}>
                      {`${areaName} (${pinCode})`}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="areaCode">
              <select
                className="form-control"
                id="areaCode"
                name="areaCode"
                onChange={async (e) => {
                  dropDivisionDropDown(e.target.value);
                }}
              >
                {dropDivId === "" && <option value="">Select Division</option>}
                {dropDivision.map(({ _id, areaCode }) => {
                  return (
                    <option key={_id} value={_id}>
                      {areaCode}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="location">
              <select
                className="form-control"
                id="location"
                name="location"
                onChange={async (e) => {
                  setDropLocId(e.target.value);
                }}
              >
                {dropLocId === "" && <option value="">Select Location</option>}
                {dropLocation.map(({ locationId, locationName }) => {
                  return (
                    <option key={locationId} value={locationId}>
                      {locationName}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        )} */}
            <div className="col-md-6 mb-3">
              <label htmlFor="active" className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="col-md-12 mb-3 text-center">
              <SubmitBtn
                errors={formik.errors}
                clickFunc={() => {
                  // if (!formik.values.isSame) {
                  //   if (memberId === "") {
                  //     warnToast("Select member");
                  //   }
                  //   if (divId === "") {
                  //     warnToast("Select division");
                  //   }
                  //   if (locId === "") {
                  //     warnToast("Select location");
                  //   }
                  //   if (dropDivId === "") {
                  //     warnToast("Select Drop division");
                  //   }
                  //   if (dropLocId === "" || dropPin === "" || dropDivId === "") {
                  //     warnToast("Select Drop area");
                  //   }
                  // } else {

                  if (divId === "") {
                    warnToast("Select Division");
                  }
                  if (locId === "") {
                    warnToast("Select Location");
                  }
                  // }
                  toastValidation(formik.errors);
                }}
              />
              <CancelBtn path=".." />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AssignmentEditForm;
