import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import toastValidation, { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";

type Props = {};

const VehiclePucMasterForm = (props: Props) => {
  const nav = useNavigate();

  // state and loading
  const { state } = useLocation();
  const [param] = useSearchParams();
  const { setLoading } = useLoading();

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      pucDate: "",
      validUptoDate: "",
      pucDoc: "",
      vehicleNo: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1)
        .max(30)
        .required("PUC Company Name is Required"),
      pucDate: Yup.string().required("PUC Date is Required"),
      validUptoDate: Yup.string().required("Valid Upto Date is Required"),
      pucDoc: Yup.mixed()
        .test("fileSize", "PUC Document size is too high", (value) => {
          if (!value) return true; // Skip the test if no file is selected
          return value && value.size <= 209715200; // 200MB in bytes
        })
        .test(
          "fileType",
          "Please Enter PUC Document in jpg,jpeg,png,pdf,docx extension",
          (value) => {
            if (!value) return true; // Skip the test if no file is selected
            return value && /\.(jpg|jpeg|png|pdf|docx?)$/i.test(value.name); // Check if the file has a valid extension
          }
        ),
    }),
    onSubmit: async ({ name, pucDate, validUptoDate, pucDoc, vehicleNo }) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("model", state.model);
        formData.append("vehicleNo", state._id);
        formData.append("pucDate", pucDate);
        formData.append("validUptoDate", validUptoDate);
        formData.append("pucDoc", pucDoc);
        formData.append("description", "");
        formData.append("note", "");
        formData.append("details", "");
        const res = await axiosInstance.post(
          "/master/addVehiclePuc",
          formData,
          {}
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          if (Number(param.get("fromEdit")) !== 1) {
            const item = state;
            nav("../incAdd", { state: item });
          } else {
            const item = state;
            nav(
              {
                pathname: "/vehicleRegistration/editForm",
                search: "?index=1",
              },
              { state: item }
            );
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name && formik.submitCount ? "empty-form" : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="PUC Company Name"
              />

              <span className="top-placeholder">PUC Company Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="pucDate">
              <input
                className={`form-control session-date ${
                  formik.errors.pucDate && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="pucDate"
                name="pucDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.pucDate}
                // onFocus={(e) => (e.target.type = "date")}
                placeholder="PUC Date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              {/* {formik.values.pucDate && ( */}
              <span className="top-placeholder">PUC Date*</span>
              {/* )} */}
            </label>
            <label className="col-md-6 mb-3" htmlFor="validUptoDate">
              <input
                className={`form-control session-date ${
                  formik.errors.validUptoDate && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="validUptoDate"
                name="validUptoDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.validUptoDate}
                // onFocus={(e) => (e.target.type = "date")}
                placeholder="Valid Upto Date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              {/* {formik.values.validUptoDate && ( */}
              <span className="top-placeholder">Valid Upto Date*</span>
              {/* )} */}
            </label>
            <label className="col-md-6 mb-3" htmlFor="pucDoc">
              <input
                className="form-control pucDoc form-control-lg"
                id="pucDoc"
                name="pucDoc"
                type="file"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const file =
                    event.currentTarget.files && event.currentTarget.files[0];
                  formik.setFieldValue("pucDoc", file || ""); // If file is null, set an empty string
                }}
                placeholder="Enter document"
              />
            </label>
          </div>
          <div className="text-center col-md-12 mb-3">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                toastValidation(formik.errors);
              }}
            />

            <button
              className="btn btn-primary"
              onClick={() => {
                if (Number(param.get("fromEdit")) !== 1) {
                  nav("../..");
                } else {
                  const item = state;
                  nav(
                    {
                      pathname: "/vehicleRegistration/editForm",
                      search: "?index=1",
                    },
                    { state: item }
                  );
                }
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(VehiclePucMasterForm);
