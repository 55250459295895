import { memo, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLoading } from "Context/LoadingContext";
import Form from "react-bootstrap/Form";
import { useFeesConfig } from "Context/FeesConfigurationContext";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Outlet,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import MemberFeesListFailed from "../FailedMemberFees/List";
import CancelBtn from "components/Buttons/Cancel";
import MemberFeesList from "../MemberFees/list";

type FeesConfigType = {
  _id: string;
  organizerId: string;
  feesType: string;
  annualRegistrationFees: string;
  startFeesYear: string;
  endFeesYear: string;
};

const feesTypeData = ["area", "km", "vehicle"];

type Props = {};
type FeesConfig = {
  _id: string;
  organizerId: string;
  feesType: string;
  annualRegistrationFees: string;
  startFeesYear: string;
  endFeesYear: string;
  createdAt: string;
  updatedAt: string;
};

const FeesConfig = (props: Props) => {
  //nav and state
  const nav = useNavigate();
  const { state } = useLocation();
  // const { state } = useLocation();
  const { setLoading } = useLoading();

  //data storing
  const [feesType, setFeesType] = useState<string>("");

  //path setting
  const { pathname } = useLocation();
  const segments = pathname.split("/");
  const segment = segments[segments.length - 1];

  // parameter and index
  const [param] = useSearchParams();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    param.get("index") !== null && index <= 2
      ? setIndex(Number(param.get("index")))
      : setIndex(index);
  });

  const loadLatestFeesConfig = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getallFeesConfig?page=1&sort=-createdAt`
      );
      const result = res.data.feesConfig;
      if (result.length >= 2 && result[0].feesType === result[1].feesType) {
        return { isClone: true, prevId: result[1]._id };
      } else {
        return { isClone: false, prevId: "" };
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  //Add Config
  const formik = useFormik({
    initialValues: {
      annualRegistrationFees: "",
      startFeesYear: "",
      endFeesYear: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      // annualRegistrationFees: Yup.number().required(
      //   "Annual Registration Fees is Required"
      // ),
      startFeesYear: Yup.string().required("Fees Start Year is Required"),
      endFeesYear: Yup.string().required("Fees End Year is Required"),
    }),
    onSubmit: async () => {
      setLoading(true);
      try {
        if (feesType === "") return;
        const formData = {
          feesType,
          annualRegistrationFees: "1000",
          startFeesYear: formik.values.startFeesYear,
          endFeesYear: formik.values.endFeesYear,
        };
        setLoading(true);
        const res = await axiosInstance.post("/master/addFeesConfig", formData);
        await checkValidDateToupdate();
        if (res.data.message === "Created") {
          const isCloneX = await loadLatestFeesConfig();
          successToast();
          setLoading(false);
          setIndex(1);
          if (isCloneX?.isClone as boolean) {
            state.isClone = true;
            state.prevConfigId = isCloneX?.prevId;
            const item = { ...state, ...res.data.result };
            nav(
              {
                pathname: `/transportationFeesMain/feesSection/structureAdd`,
                search: `?index=1`,
              },
              { state: item }
            );
          } else {
            const item = { ...state, ...res.data.result };
            nav(
              {
                pathname: `/transportationFeesMain/feesSection/structureAdd`,
                search: `?index=1`,
              },
              { state: item }
            );
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  const checkValidDateToupdate = async () => {
    const res = await axiosInstance.get(`/master/getallFeesConfigNoLimit`);
    const result = res.data.feesConfig;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    result.forEach((x: FeesConfig) => {
      const startFeesYear = new Date(x.startFeesYear);
      const endFeesYear = new Date(x.endFeesYear);
      const startFeesMonth = startFeesYear.getMonth();
      const startFeesYearValue = startFeesYear.getFullYear();
      const endFeesMonth = endFeesYear.getMonth();
      const endFeesYearValue = endFeesYear.getFullYear();

      if (
        (currentYear > startFeesYearValue && currentYear < endFeesYearValue) ||
        (currentYear === startFeesYearValue &&
          currentYear === endFeesYearValue &&
          currentMonth >= startFeesMonth &&
          currentMonth <= endFeesMonth) ||
        (currentYear === startFeesYearValue &&
          currentMonth >= startFeesMonth) ||
        (currentYear === endFeesYearValue && currentMonth <= endFeesMonth)
      ) {
        state.configId = x._id;
      }
    });
  };
  return (
    <>
      {pathname !== "/transportationFeesMain" && (
        <div className="text-end">
          <CancelBtn
            btnTxt="Back"
            path="/transportationFeesMain"
            wrapperClass="d-flex justify-content-end"
          />
        </div>
      )}
      <Tabs
        selectedIndex={index}
        onSelect={(e) => {
          setIndex(e);
        }}
      >
        <TabList>
          <Tab disabled={segment !== "feesSection"}>Fees Configuration</Tab>
          <Tab disabled={segment !== "structureAdd"}>
            Fees structure setting
          </Tab>
          {(segment === "kmList" ||
            segment === "kmAddForm" ||
            segment === "kmEditForm") && <Tab>Fees By Km</Tab>}
          {(segment === "areaList" ||
            segment === "areaAddForm" ||
            segment === "areaEditForm") && <Tab>Fees By Area</Tab>}
          {(segment === "vehicleList" ||
            segment === "vehicleAddForm" ||
            segment === "vehicleEditForm") && <Tab>Fees By Vehicle</Tab>}
          <Tab
            disabled={segment === "structureAdd" || segment === "feesSection"}
          >
            Member Fees
          </Tab>
          <Tab
            disabled={segment === "structureAdd" || segment === "feesSection"}
          >
            Pending Fees
          </Tab>
        </TabList>

        <TabPanel>
          <>
            <div className="formBg">
              <form onSubmit={formik.handleSubmit}>
                <div className="row align-items-center">
                  <label
                    className="col-md-12 mb-3 d-flex gap-5 p-3"
                    htmlFor="feesType"
                  >
                    <span>Configure Your Fees Type :</span>

                    <div>
                      {feesTypeData.map((x) => {
                        return (
                          <Form.Check
                            checked={x === feesType}
                            type="radio"
                            label={`${x.charAt(0).toUpperCase()}${x.slice(1)}`}
                            id={x}
                            key={x}
                            className="pb-3"
                            name="feesType"
                            onChange={(e) => {
                              setFeesType(e.target.id);
                            }}
                          />
                        );
                      })}
                    </div>
                  </label>
                  <label className="col-md-6 mb-3" htmlFor="startFeesYear">
                    <input
                      className={`form-control session-date ${
                        formik.errors.startFeesYear && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="startFeesYear"
                      name="startFeesYear"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.startFeesYear as string}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees Start Year</span>
                  </label>
                  <label className="col-md-6 mb-3" htmlFor="endFeesYear">
                    <input
                      className={`form-control session-date ${
                        formik.errors.endFeesYear && formik.submitCount
                          ? "empty-form"
                          : ""
                      }`}
                      id="endFeesYear"
                      name="endFeesYear"
                      type="month"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.endFeesYear as string}
                      placeholder="End Fees Year"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <span className="top-placeholder">Fees End Year</span>
                  </label>
                  {/* <label
                     className="col-md-6 mb-3 d-flex p-3 gap-5"
                     htmlFor="annualRegistrationFees"
                   >
                     <span>Annual Bus Registration Fees :</span>
                     <input
                       className="form-control"
                       id="annualRegistrationFees"
                       name="annualRegistrationFees"
                       type="number"
                       onBlur={formik.handleBlur}
                       onChange={formik.handleChange}
                       value={formik.values.annualRegistrationFees}
                       placeholder="Annual Bus Registration Fees"
                     />
                   </label> */}
                </div>
                <div className="col-md-12 mb-3 text-center">
                  <SubmitBtn
                    errors={formik.errors}
                    clickFunc={() => {
                      if (feesType === "") warnToast("Select Fees Type");
                      toastValidation(formik.errors);
                    }}
                  />
                </div>
              </form>
            </div>
          </>
        </TabPanel>
        <TabPanel>
          <Outlet />
        </TabPanel>
        {(segment === "kmList" ||
          segment === "kmAddForm" ||
          segment === "kmEditForm") && (
          <TabPanel>
            <Outlet />
          </TabPanel>
        )}
        {(segment === "areaList" ||
          segment === "areaAddForm" ||
          segment === "areaEditForm") && (
          <TabPanel>
            <Outlet />
          </TabPanel>
        )}
        {(segment === "vehicleList" ||
          segment === "vehicleAddForm" ||
          segment === "vehicleEditForm") && (
          <TabPanel>
            <Outlet />
          </TabPanel>
        )}
        <TabPanel>
          <MemberFeesList />
        </TabPanel>
        <TabPanel>
          <MemberFeesListFailed />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default memo(FeesConfig);
