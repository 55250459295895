import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";

import { DepartmentMasterDataType } from "../../../types/departmentMasterType";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
type Props = {};

const DepartmentEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  const [department, setDepartment] = useState<DepartmentMasterDataType>();

  const getOneDepartment = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getOneDepartment/${state.item._id}`
      );
      setDepartment(res.data.department);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [state.item._id]);

  useEffect(() => {
    getOneDepartment();
  }, [getOneDepartment]);

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      name: department?.name,
      code: department?.code,
      description: department?.description,
      active: department?.isActive,
      note: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Department Name is too short!")
        .max(30, "Department Name is too long!")
        .required("Department Name is required"),
      code: Yup.string().max(10, "Department Code is too long!"),
      description: Yup.string().max(300, "Department Description is too long!"),
    }),
    onSubmit: async ({ name, code, description, active }) => {
      setLoading(true);
      try {
        const formData = {
          name: name,
          code: code,
          description: description,
          isActive: active,
          note: "",
          details: "",
        };
        const res = await axiosInstance.put(
          `/master/updateDepartment/${state.item._id}`,
          formData
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          setTimeout(() => {
            setLoading(false);
            nav("/departmentMaster");
          }, 1500);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name && formik.submitCount > 0
                    ? "empty-form"
                    : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Department Name"
              />

              <span className="top-placeholder">Department Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="code">
              <input
                className="form-control"
                id="code"
                name="code"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                placeholder="Department Code"
              />

              <span className="top-placeholder">Department Code*</span>
            </label>
            <label className="col-md-12 mb-3" htmlFor="description">
              <textarea
                className="form-control"
                id="description"
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                placeholder="Department Description"
              ></textarea>

              <span className="top-placeholder">Department Description</span>
            </label>
            <div className="col-md-3 mb-3">
              <label className="custCheck" htmlFor="active">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            <CancelBtn path="/departmentMaster" />
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(DepartmentEditForm);
