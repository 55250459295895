import React, { useState, memo } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
type Props = {};
type ContextType = {
  setAdminExpirePlanId: (adminExpirePlanId: string) => void;
  adminExpirePlanId: string;
};

const AdminExpirePlan: React.FC<{}> = (props: Props) => {
  const [adminExpirePlanId, setAdminExpirePlanId] = useState<string>("");
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Client Expired Plan</span>
      </div>
      <Outlet context={{ adminExpirePlanId, setAdminExpirePlanId }} />
    </>
  );
};

export default memo(AdminExpirePlan);
export function useAdminExpirePlanId() {
  return useOutletContext<ContextType>();
}
