import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { memImage, currencySymbol } from "constants/Constants";

type Props = {
  item: any;
  type: number;
};

const MemberFeesListItems = ({ item, type }: Props) => {
  const nav = useNavigate();
  return (
    <tr key={Math.random()}>
      <td>
        <i className="userImg">
          <img
            src={`${memImage}${item.photo !== "" ? item.photo : "Default.png"}`}
            alt="MemberPic"
          />
        </i>
      </td>
      <td>{item.memberName}</td>
      <td>{item.memId}</td>
      <td>{item.routeName}</td>
      <td>
        {currencySymbol}
        {item.dueFees.toFixed(2)}
      </td>
      <td>
        {currencySymbol}
        {item.totalPaidFees.toFixed(2)}
      </td>
      <td className="text-center pointer">
        <Edit
          id={item.memberId}
          onClick={() => {
            nav(`/detailFees?index=${type - 1}`, {
              state: item.memberId,
            });
          }}
        />
      </td>
    </tr>
  );
};

export default MemberFeesListItems;
