import React, { memo } from "react";
import UserMngDataType from "../../../types/userManTypes";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  x: UserMngDataType;
};

const UserMngListItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();

  const tooltip = <Tooltip id={`tooltip-${x._id}`}>Edit </Tooltip>;

  return (
    <tr key={x._id}>
      <td>{x.name}</td>
      <td>{x.email}</td>
      <td>{x.contactNo}</td>
      <td>{x.role}</td>
      <td className="text-center">
        {x.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            id={x._id}
            className="pointer"
            onClick={() => {
              nav("userEdit", {
                state: x,
              });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(UserMngListItem);
