import React, { memo, useState, useEffect, useRef } from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
// import { ReactComponent as Route } from "assets/images/route-05.svg";
import { ReactComponent as DivisionImg } from "assets/images/division-06.svg";
import { ReactComponent as DetailsImg } from "assets/images/details-19.svg";
import RouteMasterType from "../../../types/route.types";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axiosInstance from "axiosInstance";
import { useLoading } from "Context/LoadingContext";
import { debounce } from "lodash";
import { convertTo12HourFormat } from "constants/Constants";
import { useNotFound } from "Context/NotFound";
import NotFound from "components/NotFound";
// import RouteMap from "./RouteMap";
type Props = {
  item: RouteMasterType;
};

const RouteListItems = ({ item }: Props) => {
  const nav = useNavigate();
  const [showPopover, setShowPopover] = useState(false);
  const [routes, setRoutes] = useState<RouteMasterType[]>([]);
  const [routesData, setRoutesData] = useState<RouteMasterType[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { setLoading, loading } = useLoading();
  const { notfound, setNotFound } = useNotFound();
  const [notfoundForModel, setNotFoundForModel] = useState(false);

  const handleEditClick = () => {
    nav("editForm", {
      state: item,
    });
  };

  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  const tooltipDetail = <Tooltip id={`tooltip-${item._id}`}>Details</Tooltip>;

  const onSubmit = async (
    pageNum: number,
    sortParam: string,
    searchKeyword: string
  ) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getAllRouteAllMembers/${item._id}?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
      );

      // setRoutes(res.data.Routes);
      const newRouteAllMemberList: RouteMasterType[] = [
        ...res.data.Routes[0].paginatedResults,
      ];
      if (newRouteAllMemberList.length === 0) {
        if (searchKeyword !== "" && pageNum === 1) {
          setNotFoundForModel(true);
          setRoutes([]);
          setSearchCount(0);
        }
      }
      if (newRouteAllMemberList.length > 0) {
        setSearchCount(res.data.Routes[0].totalCount[0].count);
        if (pageNum === 1) {
          setRoutes(newRouteAllMemberList);
        } else {
          setRoutes((prevList) => [...prevList, ...newRouteAllMemberList]);
        }
        setNotFoundForModel(false);
      } else {
        setNotFoundForModel(true);
      }
      setLoading(false);
    } catch (error) {
      setNotFoundForModel(true);
      console.log(error);
      setLoading(false);
    }
  };

  const onSubmit1 = async (
    pageNum: number,
    sortParam: string,
    searchKeyword: string
  ) => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getAllRouteAllAreaDivision/${item._id}?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
      );

      const newRouteAllMemberList: RouteMasterType[] = [
        ...res.data.Routes[0].paginatedResults,
      ];
      if (newRouteAllMemberList.length === 0) {
        if (searchKeyword !== "" && pageNum === 1) {
          setNotFoundForModel(true);
          setRoutesData([]);
          setSearchCount(0);
        }
      } else {
        setSearchCount(res.data.Routes[0].totalCount[0].count);
        if (pageNum === 1) {
          setRoutesData(newRouteAllMemberList);
        } else {
          setRoutesData((prevList) => [...prevList, ...newRouteAllMemberList]);
        }
        setNotFoundForModel(false);
      }
      setLoading(false);
    } catch (error) {
      setNotFoundForModel(true);
      console.log(error);
      setLoading(false);
    }
  };
  const openModal = () => {
    onSubmit(page, sort, "");
    setShowModal(true);
  };

  const openModal1 = () => {
    onSubmit1(page, sort, "");
    setShowModal1(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setShowModal(false);
      setShowModal1(false);
    }
  };

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
    onSubmit(1, sort, search);
  };

  const handleSearch1 = () => {
    setPage(1);
    setSearchQuery(search);
    onSubmit1(1, sort, search);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleScroll = debounce(() => {
    if (
      modalRef.current &&
      modalRef.current.scrollTop + modalRef.current.clientHeight >=
        modalRef.current.scrollHeight - 20 &&
      !loading &&
      routes.length < searchCount
    ) {
      setLoading(true);
      const nextPage = page + 1;
      setPage(nextPage);
      onSubmit(nextPage, sort, searchQuery);
      onSubmit1(nextPage, sort, searchQuery);
    }
  }, 300);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (modalRef.current) {
        modalRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [routes]);

  return (
    <>
      <tr key={item._id}>
        <td>{item.name}</td>
        <td>{item.code}</td>
        <td>{item.vehicleNo.join(", ")}</td>
        <td>{item.driverName.join(", ")}</td>
        <td>
          {[...(new Set(item.areaName.map((item: any) => item.area)) as any)]
            .map((area) => area)
            .join(", ")}
        </td>

        <td className="pointer">
          <OverlayTrigger placement="right" overlay={tooltipDetail}>
            <DivisionImg
              className="Approved_Size"
              type="button"
              onClick={() => {
                openModal1();
              }}
              style={{ cursor: "pointer" }}
            />
          </OverlayTrigger>
        </td>
        <td className="pointer">
          <OverlayTrigger placement="right" overlay={tooltipDetail}>
            <DetailsImg
              className="Approved_Size"
              type="button"
              onClick={() => {
                openModal();
              }}
              style={{ cursor: "pointer" }}
            />
          </OverlayTrigger>
        </td>

        <td className="text-center">{item.isActive ? <Eye /> : <EyeOff />}</td>
        <td className="text-center pointer">
          <OverlayTrigger placement="right" overlay={tooltip}>
            <span
              id={item._id}
              onClick={handleEditClick}
              style={{ cursor: "pointer" }}
            >
              <Edit />
            </span>
          </OverlayTrigger>
        </td>
      </tr>
      {showModal1 && (
        <>
          <div className="modal-overlay" ref={modalRef}>
            <div
              className="close"
              style={{
                width: "100%",
                textAlign: "end",
                padding: "10px 10px 0px 0px",
              }}
            >
              <span
                role="button"
                onClick={() => {
                  setShowModal1(false);
                  setSearch("");
                }}
              >
                Close
              </span>
            </div>
            <div className="modal-title">
              <span> Area Details </span>
            </div>
            <div>
              <div className="formBg mb-3" style={{ width: "980px" }}>
                <form
                  action=""
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    handleSearch1();
                  }}
                >
                  <div className="d-flex" style={{ width: "960px" }}>
                    <label className="flex-fill">
                      <input
                        className="form-control  "
                        type="text"
                        placeholder="Area or Division"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </label>
                    <div style={{ paddingRight: "15px" }}>
                      <button className="btn btn-primary" type="submit">
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <table
              className="table"
              style={{
                padding: "10px",
                marginTop: "15px",
              }}
            >
              <thead>
                <tr>
                  <th>Area</th>
                  <th>Division</th>
                </tr>
              </thead>
              {!notfoundForModel && (
                <tbody>
                  {routesData?.map((x: any) => {
                    return (
                      <tr key={Math.random()}>
                        <td>{x.areaName}</td>
                        <td>{x.areaDivision}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
              {notfoundForModel && (
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <NotFound />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </>
      )}

      {showModal && (
        <>
          <div className="modal-overlay" ref={modalRef}>
            <div
              className="close"
              style={{
                width: "100%",
                textAlign: "end",
                padding: "10px 10px 0px 0px",
              }}
            >
              <span
                role="button"
                onClick={() => {
                  setShowModal(false);
                  setSearch("");
                }}
              >
                Close
              </span>
            </div>
            <div className="modal-title">
              <span> Member Details </span>
            </div>
            <div>
              <div className="formBg mb-3" style={{ width: "980px" }}>
                <form
                  action=""
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                >
                  <div className="d-flex" style={{ width: "960px" }}>
                    <label className="flex-fill">
                      <input
                        className="form-control  "
                        type="text"
                        placeholder="Member Name or MemberId"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </label>
                    <div style={{ paddingRight: "15px" }}>
                      <button className="btn btn-primary" type="submit">
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <table
              className="table"
              style={{
                padding: "10px",
                marginTop: "15px",
              }}
            >
              <thead>
                <tr>
                  <th>Member</th>
                  <th>MemberId</th>
                  <th>Area</th>
                  <th>Division</th>
                  <th>PickUp Location</th>
                  <th>PickUp Time</th>
                  <th>Drop Time</th>
                </tr>
              </thead>
              {!notfoundForModel && (
                <tbody>
                  {routes?.map((x: any) => {
                    return (
                      <tr key={Math.random()}>
                        <td>{x.memberName}</td>
                        <td>{x.memberId}</td>
                        <td>{x.areaName}</td>
                        <td>{x.areaDivision}</td>
                        <td>{x.locationName}</td>
                        <td>{convertTo12HourFormat(x.pickUpTime)}</td>
                        <td>{convertTo12HourFormat(x.dropTime)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
              {notfoundForModel && (
                <tbody>
                  <tr>
                    <td colSpan={7} style={{ textAlign: "center" }}>
                      <NotFound />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default memo(RouteListItems);
