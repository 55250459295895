import React, { memo } from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import CategoryDataType from "../../../types/categoryTypes";
import { useNavigate } from "react-router-dom";
import { DepartmentDDLType } from "../../../types/departmentMasterType";
import { descSlice } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
type Props = {
  item: CategoryDataType;
  department: DepartmentDDLType[];
};

const CategoryListItems = ({ item, department }: Props) => {
  const nav = useNavigate();
  const handleEditClick = () => {
    nav("editForm", {
      state: { item, department },
    });
  };

  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  return (
    <tr key={item._id}>
      <td>{item.dName}</td>
      <td>{item.name}</td>
      <td>{item.code}</td>
      <td>{descSlice(item.description)}</td>
      <td className="text-center">{item.isActive ? <Eye /> : <EyeOff />}</td>
      <td className="text-center pointer">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <span
            id={item._id}
            onClick={handleEditClick}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(CategoryListItems);
