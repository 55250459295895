import { memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import { useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { successToast } from "constants/toastConfig";

type Props = {};

const ConfigurationEdit = (props: Props) => {
  const location = useLocation();
  const { state } = useLocation();
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const waitTimeSchemaObject = {
    value: Yup.number()
      .required("Value is Required")
      .min(1, "Please enter a value greater than 0 minutes")
      .max(60, "Please enter a value less than 60 minutes")
      .integer("Please enter a valid integer value"),
  };
  const trueFalseValidationObject = {
    value: Yup.string().max(5, "Please Select It"),
  };
  const defaultValidationObject = {
    value: Yup.number()
      .min(1, "Please enter a value greater than 0 minutes")
      .max(999, "Please enter a value less than 999")
      .integer("Please enter a valid integer value"),
  };
  let validation = {};
  if (state.key === "waitTime") {
    validation = waitTimeSchemaObject;
  } else if (state.value === "true" || state.value === "false") {
    validation = trueFalseValidationObject;
  } else {
    validation = defaultValidationObject;
  }
  const placeHolderText =
    state.key === "waitTime" ? "Enter the time" : "Enter the value";
  const formik = useFormik({
    initialValues: {
      value: state.value,
    },
    validationSchema: Yup.object().shape({
      ...validation,
    }),
    onSubmit: () => {
      setLoading(true);
      submit();
    },
  });
  const submit = async () => {
    try {
      const formData = {
        value: formik.values.value,
      };
      const res = await axiosInstance.put(
        `/master/updateConfig/${state._id}`,
        formData
      );
      const responseMessage = res.data;
      if (responseMessage === "Ok") {
        const driverSetting = await axiosInstance.get(
          "/master/getOneStaticConfig?key=driverAccountSetting"
        );
        // const isDriverAccounts =
        //   driverSetting.data.result.Config.value === "false" ? false : true;

        localStorage.setItem(
          "DriverAccountSetting",
          driverSetting.data.result.Config.value
        );
        setLoading(false);
        nav("/configuration");
        successToast();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 50);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="description">
              <span className="form-control sp_1">{state.description}</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="value">
              {state.value === "true" || state.value === "false" ? (
                <label className="custCheck" htmlFor="value">
                  {/* Active Driver Account */}

                  {state.key === "manualAutoLocation"
                    ? `Active ${state.description}`
                    : "Active Driver Account"}
                  <input
                    type="checkbox"
                    id="value"
                    name="value"
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "value",
                        e.target.checked ? "true" : "false"
                      )
                    }
                    checked={formik.values.value === "true" ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>
              ) : (
                <input
                  className={`form-control email-control ${
                    formik.errors.value ? "empty-form" : ""
                  }`}
                  id="value"
                  name="value"
                  type="number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.value}
                  placeholder={placeHolderText}
                />
              )}
            </label>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            {location.pathname === "/configuration/configurationEdit" && (
              <CancelBtn path="/configuration" />
            )}
          </div>
        </form>
      </div>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Body>Are You Sure You want to change?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShow(false);
              submit();
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default memo(ConfigurationEdit);
