import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import axiosInstance from "axiosInstance";
import ErrorToast from "components/ValidationToast/errorToast";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { TypeDDL } from "../../../types/typeMasterTypes";
import { CardMemberMappingType } from "types/CardMemberMappingTypes";
import { DepartmentDDLType } from "../../../types/departmentMasterType";
import { RFDICardDataType } from "types/rfdiCardTypes";
import InfoToast from "components/ValidationToast/infoToast";
import { MemberDataType } from "types/MemberMaster.types";
import { CategoryDDL } from "types/categoryTypes";
import { useLoading } from "Context/LoadingContext";

type Props = {};

const CardMemberMappingEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const location = useLocation();
  const nav = useNavigate();
  const { state } = useLocation();
  // console.log(state);

  const [cardMember, setCardMember] = useState<CardMemberMappingType[]>([]);
  // console.log(cardMember);
  const [card, setCard] = useState<RFDICardDataType[]>([]);
  const [cardId, setCardId] = useState("");

  const [department, setDepartment] = useState<DepartmentDDLType[]>([]);
  const [depId, setDepId] = useState("default");

  const [member, setMember] = useState<MemberDataType[]>([]);
  const [memberId, setMemberId] = useState("");

  const [cat, setCat] = useState<CategoryDDL[]>([]);
  const [catId, setCatId] = useState("default");

  const [type, setType] = useState<TypeDDL[]>([]);
  const [typeId, setTypeId] = useState("");

  const [isValid, setIsValid] = useState("");

  const RFDICardOneMaster = useCallback(async (cardId: String) => {
    setLoading(true);
    try {
      const resRfdi = await axiosInstance.get(
        `/master/getOneRFIDCard/${cardId}`
      );
      const rList = resRfdi.data.RFIDCard as RFDICardDataType[];
      setLoading(false);
      setCardId(rList[0]._id);
      return rList;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const RFDICardList = useCallback(async () => {
    setLoading(true);
    try {
      const resRfdi = await axiosInstance.get(`/dropDown/getCard`);
      const rList = resRfdi.data.Card as RFDICardDataType[];
      const oneCard = await RFDICardOneMaster(state.item.cardId);
      if (oneCard !== undefined) {
        setCard([{ ...oneCard[0] }, ...rList]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const getDepList = useCallback(async () => {
    setLoading(true);
    try {
      const resDep = await axiosInstance.get(`/dropDown/getDepartment`);
      const depList = resDep.data.Departments as DepartmentDDLType[];
      setLoading(false);
      return depList;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const CategoryList = useCallback(async (departmentId: string) => {
    setLoading(true);
    try {
      const resCat = await axiosInstance.get(
        `/dropDown/getCategory?depId=${departmentId}`
      );
      const catList = resCat.data.categories as CategoryDDL[];
      setLoading(false);
      return catList;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const TypeList = useCallback(async (categoryId: string) => {
    setLoading(true);
    try {
      const resType = await axiosInstance.get(
        `/dropDown/getType?catId=${categoryId}`
      );
      // console.log(resType);
      const tList = resType.data.types as TypeDDL[];
      setLoading(false);
      return tList;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const MemberListOneMaster = useCallback(async (memberId: string) => {
    try {
      const res = await axiosInstance.get(`/master/getOneMember/${memberId}`);
      // console.log(res.data, "memberMasterList");
      const mList = res.data.member as MemberDataType[];
      return mList;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const MemberList = useCallback(async (departmentId: string) => {
    try {
      const res = await axiosInstance.get(
        `/dropDown/getMemberWithOrganization?depId=${departmentId}`
      );
      // console.log(res);
      const mList = res.data.MemberNames as MemberDataType[];
      return mList;
      // const oneCard = await MemberListOneMaster(state.item.memberId);
      // setMemberId(state.item.memberId);
      // if (oneCard !== undefined) {
      //   setMember([{ ...oneCard[0] }, ...mList]);
      //   // console.log(card);
      // }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getOneCardMember = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getOneCardMember/${state.item._id}`
      );
      // console.log(res);
      const mem = res.data.cardMember[0] as CardMemberMappingType[];
      setCardMember(mem);
      RFDICardList();

      const dep = (await getDepList()) as DepartmentDDLType[];
      const newDepList = updateList(dep, state.item.departmentId);
      setDepartment(newDepList as DepartmentDDLType[]);
      setDepId(newDepList[0]._id);

      const cat = (await CategoryList(
        state.item.departmentId
      )) as CategoryDDL[];
      if (cat === undefined) {
        setCat([]);
        setCatId("");
      } else {
        if (state.item.categoryId !== null) {
          const newCatList = updateList(cat, state.item.categoryId);
          setCat(newCatList as CategoryDDL[]);
          setCatId(newCatList[0]._id);
        } else {
          setCat([]);
          setCatId("");
        }
      }

      const type = (await TypeList(state.item.categoryId)) as TypeDDL[];
      if (type === undefined) {
        setType([]);
        setTypeId("");
      } else {
        if (state.item.typeId !== null) {
          const newTypeList = updateList(type, state.item.typeId);
          setType(newTypeList as TypeDDL[]);
          setTypeId(newTypeList[0]._id);
        } else {
          setType([]);
          setTypeId("");
        }
      }

      const member = res.data.cardMember[0] as CardMemberMappingType[];
      setCardMember(member);
      const mList = await MemberList(state.item.departmentId);
      const oneCard = await MemberListOneMaster(state.item.memberId);
      setMemberId(state.item.memberId);
      if (oneCard !== undefined && mList !== undefined) {
        setMember([{ ...oneCard[0] }, ...mList]);
        // console.log(card);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getOneCardMember();
  }, [getOneCardMember]);

  const updateList = (
    list:
      | RFDICardDataType[]
      | DepartmentDDLType[]
      | CategoryDDL[]
      | TypeDDL[]
      | MemberDataType[],
    id: string | undefined
  ) => {
    const newList = [...list];
    const record = newList?.find(
      (
        x:
          | RFDICardDataType
          | DepartmentDDLType
          | CategoryDDL
          | TypeDDL
          | MemberDataType
      ) => x?._id === id
    );
    const index = newList.indexOf(
      record as
        | RFDICardDataType
        | DepartmentDDLType
        | CategoryDDL
        | TypeDDL
        | MemberDataType
    );
    newList.splice(index, 1);
    newList.splice(
      0,
      0,
      record as
        | RFDICardDataType
        | DepartmentDDLType
        | CategoryDDL
        | TypeDDL
        | MemberDataType
    );
    return newList;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cardId: cardId,
      memberId: memberId,
      departmentId: depId,
      typeId: typeId,
      categoryId: catId,
      active: true,
    },

    onSubmit: async ({
      cardId,
      memberId,
      departmentId,
      typeId,
      categoryId,
      active,
    }) => {
      try {
        const formData = {
          cardId: cardId,
          memberId: memberId,
          departmentId: departmentId,
          typeId: typeId,
          categoryId: categoryId,
          isActive: active,
        };
        // console.log("jsjsjsjsj");

        // console.log(formData, "edit");
        const res = await axiosInstance.put(
          `/master/updateCardMember/${state?.item?._id}`,
          formData
        );

        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          nav("/cardMemberMapping");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const validateForm = () => {
    if (
      cardId === "default" &&
      depId === "default" &&
      memberId === "default" &&
      Object.keys(formik.errors).length === 0
    ) {
      setIsValid("empty");
    } else if (cardId === "default") {
      setIsValid("selectCard");
    } else if (depId === "default") {
      setIsValid("selectDepartment");
    } else if (memberId === "default") {
      setIsValid("selectMember");
    } else if (Object.keys(formik.errors).length === 0) {
      setIsValid("submitted");
    }
  };
  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="cardId">
              {cardId !== "default" && (
                <span className="top-placeholder">Card</span>
              )}

              <select
                className="form-control"
                id="cardId"
                onChange={(e) => {
                  setCardId(e.target.value);
                  console.log(e.target.value);
                }}
              >
                {card?.map(({ _id, cardNumber }) => {
                  return (
                    <option key={_id} value={_id}>
                      {cardNumber}
                    </option>
                  );
                })}
              </select>
            </label>

            <label htmlFor="" className="col-md-6 mb-3">
              <select
                className="form-control"
                id="department"
                onChange={async (e) => {
                  setDepId(e.target.value);
                  setCatId("");
                  setCat([]);
                  const cat = await CategoryList(e.target.value);
                  if (cat === undefined) {
                    setCat([]);
                    setCatId("");
                  } else {
                    setCat(cat as CategoryDDL[]);
                    setCatId("default");
                  }
                  setMemberId("default");
                  setMember([]);
                  const mList = await MemberList(e.target.value);
                  if (mList !== undefined) {
                    setMember(mList);
                  }
                  setTypeId("");
                  setType([]);
                }}
              >
                {department.map((item) => {
                  return (
                    <option key={item._id} value={item._id}>
                      {item.departmentName}
                    </option>
                  );
                })}
              </select>
            </label>
            {cat.length !== 0 && (
              <label htmlFor="" className="col-md-6 mb-3">
                <select
                  className="form-control"
                  id="department"
                  onChange={async (e) => {
                    setCatId(e.target.value);
                    setTypeId("");
                    setType([]);
                    const typ = await TypeList(e.target.value);
                    if (typ === undefined) {
                      setType([]);
                      setTypeId("");
                    } else {
                      setType(typ as TypeDDL[]);
                      setTypeId("default");
                    }
                  }}
                >
                  {catId !== state.item.categoryId && (
                    <option value="default">Select Category</option>
                  )}
                  {cat.map((item) => {
                    return (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            )}
            {type.length !== 0 && (
              <label htmlFor="" className="col-md-6 mb-3">
                <select
                  className="form-control"
                  id="department"
                  onChange={(e) => {
                    setTypeId(e.target.value);
                  }}
                >
                  {typeId !== state.item.typeId && (
                    <option value="default">Select Type</option>
                  )}
                  {type?.map((item) => {
                    return (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            )}
            <label className="col-md-6 mb-3" htmlFor="memberId">
              <select
                className="form-control"
                id="memberId"
                onChange={(e) => {
                  setMemberId(e.target.value);
                }}
              >
                {memberId !== state.item.memberId && (
                  <option value="default">Select Member</option>
                )}
                {member?.map(({ _id, name }) => {
                  return (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </label>
            <div className="col-md-3 mb-3">
              <label className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          {isValid === "empty" && <ErrorToast error="Please fill out data" />}
          {isValid === "selectCard" && <ErrorToast error="Card is required" />}
          {isValid === "selectDepartment" && (
            <ErrorToast error="Department is required" />
          )}
          {isValid === "selectMember" && (
            <ErrorToast error="Member is required" />
          )}
          <div className="col-md-12 mb-3 text-center">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={validateForm}
            >
              Submit
            </button>
            {(location.pathname === "/cardMemberMapping/addForm" ||
              location.pathname === "/cardMemberMapping/editForm") && (
              <Link to={"/cardMemberMapping"}>
                <button className="btn btn-primary" type="submit">
                  Cancel
                </button>
              </Link>
            )}
          </div>
          {isValid === "submitted" && (
            <InfoToast infoMsg="Response Submitted" />
          )}
        </form>
      </div>
    </>
  );
};

export default memo(CardMemberMappingEditForm);
