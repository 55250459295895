import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import CategoryDataType from "../../../types/categoryTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { DepartmentDDLType } from "../../../types/departmentMasterType";
import { useLoading } from "Context/LoadingContext";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { successToast } from "constants/toastConfig";

type Props = {};

const CategoryEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const { state } = useLocation();
  const nav = useNavigate();
  const [category, setCategory] = useState<CategoryDataType>();
  const [department, setDepartment] = useState<DepartmentDDLType[]>([]);
  const [depId, setDepId] = useState<string | undefined>("");

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const department = res.data.Departments;
      const catIdForDep = state.item.departmentId;
      const newDepList = updateDlist(department, catIdForDep);
      setDepartment(newDepList as DepartmentDDLType[]);
      setDepId(catIdForDep);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [state.item.departmentId]);

  const getOneCategory = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/master/getOneCategory/${state.item._id}`
      );
      const category = res.data.Category as CategoryDataType;
      setCategory(category);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [state.item._id]);

  useEffect(() => {
    loadDepartments();
    getOneCategory();
  }, [getOneCategory, loadDepartments]);

  const updateDlist = (list: DepartmentDDLType[], id: string) => {
    const newList = [...list];
    const record = newList?.find((x: DepartmentDDLType) => x?._id === id);
    const index = newList.indexOf(record as DepartmentDDLType);
    newList.splice(index, 1);
    newList.splice(0, 0, record as DepartmentDDLType);
    return newList;
  };

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      name: category?.name,
      code: category?.code,
      description: category?.description,
      active: category?.isActive,
      note: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Category name is too short!")
        .max(30, "Category name is too long!")
        .required("Category name is Required"),
      code: Yup.string().max(10, "Category Code is too long"),
      description: Yup.string().max(300, "Category Description is too long"),
    }),
    onSubmit: async ({ name, code, description, active }) => {
      setLoading(true);
      try {
        const formData = {
          departmentId: depId,
          name: name,
          code: code,
          description: description,
          isActive: active,
          note: "",
          details: "",
        };
        const res = await axiosInstance.put(
          `/master/updateCategory/${state.item._id}`,
          formData
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          setTimeout(() => {
            setLoading(false);
            nav("/category");
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <div className="formBg">
      <form className="row align-items-center " onSubmit={formik.handleSubmit}>
        <label htmlFor="department" className="col-md-6 mb-3">
          <span className="top-placeholder">Department*</span>

          <select
            className="form-control"
            id="department"
            name="department"
            onChange={(e) => {
              setDepId(e.target.value);
            }}
          >
            {department.map(({ _id, departmentName }) => {
              return (
                <option key={_id} value={_id}>
                  {departmentName}
                </option>
              );
            })}
          </select>
        </label>
        <label className="col-md-6 mb-3" htmlFor="name">
          <input
            className={`form-control ${formik.errors.name ? "empty-form" : ""}`}
            id="name"
            name="name"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="Category Name"
          />

          <span className="top-placeholder">Category Name*</span>
        </label>
        <label className="col-md-6 mb-3" htmlFor="code">
          <input
            className="form-control"
            id="code"
            name="code"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.code}
            placeholder="Category Code"
          />

          <span className="top-placeholder">Category Code</span>
        </label>
        <label className="col-md-12 mb-3" htmlFor="description">
          <textarea
            className="form-control"
            id="description"
            name="description"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.description}
            placeholder="Category Description"
          />

          <span className="top-placeholder">Category Description</span>
        </label>
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn errors={formik.errors} />
          <CancelBtn path="/category" />
        </div>
      </form>
    </div>
  );
};

export default memo(CategoryEditForm);
