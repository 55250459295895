import { memo } from "react";
import { NotificationType } from "types/notificationTypes";
import {
  formatDate,
  formatTime,
  memImage,
  drvImage,
} from "constants/Constants";

type Props = {
  x: NotificationType;
};

const NotificationListItem = ({ x }: Props): JSX.Element => {
  let userTypeImage;
  if (x.userType === "DRV") {
    userTypeImage = x.photo
      ? `${drvImage}${x.photo}`
      : `${drvImage}Default.png`;
  } else {
    userTypeImage = x.photo
      ? `${memImage}${x.photo}`
      : `${memImage}Default.png`;
  }

  // console.log(drvImage, "driverImages.....");
  return (
    <tr key={x._id}>
      <td className="pb-4">
        <i className="userImg">
          <img
            src={userTypeImage}
            alt={x.userType === "DRV" ? "DriverPic" : "MemberPic"}
          />
        </i>
      </td>
      <td className="pb-4">{x.title}</td>
      <td className="pb-4">{x.userType === "DRV" ? "Driver" : "Member"}</td>
      <td className="pb-4">{x.username}</td>
      <td className="pb-4">{x.message}</td>
      <td className="pb-4">{formatDate(x.createdAt)}</td>
      <td className="pb-4">{formatTime(x.createdAt)}</td>
    </tr>
  );
};

export default memo(NotificationListItem);
