import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo, useMemo } from "react";
import { useLoading } from "Context/LoadingContext";
import { useTable, usePagination } from "react-table";
import "../../../assets/styles/style.css";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import { AreaDDLType } from "types/areaMasterTypes";
import RouteMasterType from "types/route.types";
import { exportPDF, exportToExcel } from "constants/Constants";

type Props = {};

const MemberCountByLocationReportList = (props: Props): JSX.Element => {
  const [data, setData] = useState([]);
  const [pin, setPin] = useState("");
  const [area, setArea] = useState<AreaDDLType[]>([]);
  const [division, setDivision] = useState<AreaDivisionDDL[]>([]);
  const [divId, setDivId] = useState("");
  const [route, setRoute] = useState<RouteMasterType[]>([]);
  const [rouId, setRouId] = useState("");
  const { setLoading } = useLoading();

  const loadAreas = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getArea`);
      setArea(res.data.Areas);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadAreaCode = useCallback(async (pinCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getAreaDivision?pinCode=${pinCode}`
      );
      setDivision(res.data.division);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const RouteList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/dropDown/getRoute`);
      const rList = res.data.Routes;
      setRoute(rList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadReports = useCallback(
    async (route: string, pinCode: string, divisionId: string) => {
      try {
        setLoading(true);

        if (pin !== "" && divId !== "" && route !== "") {
          const res = await axiosInstance.get(
            `/report/membersCountByLoc?pinCode=${pinCode}&divId=${divisionId}&route=${route}`
          );
          setData(res.data.resp);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [data]
  );

  useEffect(() => {
    loadAreas();
    RouteList();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "RouteName",
        accessor: "routeName",
      },
      {
        Header: "AreaName",
        accessor: "area",
      },
      {
        Header: "SubAreaName",
        accessor: "divName",
      },
      {
        Header: "LocationName",
        accessor: "locationName",
      },
      {
        Header: "PickCount",
        accessor: "pickCount",
      },
      {
        Header: "DropCount",
        accessor: "dropCount",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  }: any = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 } as any,
    },
    usePagination
  );

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Member Count By Location Report</span>
      </div>
      <button onClick={exportPDF} className="btn btn-primary mb-4">
        Export to PDF
      </button>
      <button
        onClick={() => {
          exportToExcel(data);
        }}
        className="btn btn-primary mb-4"
      >
        Export to Excel
      </button>
      <div className="formBg mb-3">
        <div className="mb-2 row align-items-center">
          <label className="col-md-6 mb-3" htmlFor="area">
            <select
              className="form-control"
              id="area"
              name="area"
              onChange={(e) => {
                setPin(e.target.value);
                setDivision([]);
                setDivId("");
                setData([]);
                loadAreaCode(e.target.value);
              }}
            >
              <option value="">Select Area</option>
              {area.map(({ _id, areaName, pinCode }) => {
                return (
                  <option key={_id} value={pinCode}>
                    {`${areaName} (${pinCode})`}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="areaCode">
            <select
              className="form-control"
              id="areaCode"
              name="areaCode"
              onChange={(e) => {
                setDivId(e.target.value);
                setData([]);
              }}
            >
              <option value="">Select Division</option>
              {division.map(({ _id, areaCode }) => {
                return (
                  <option key={_id} value={_id}>
                    {areaCode}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="route">
            <select
              className="form-control"
              id="route"
              name="route"
              onChange={(e) => {
                setRouId(e.target.value);
                setData([]);
                if (pin !== "" && divId !== "") {
                  loadReports(e.target.value, pin, divId);
                }
              }}
            >
              <option value="">Select Route</option>
              {route.map(({ _id, name }) => {
                return (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>
      <table {...getTableProps()} id="report-table" className="tableForReport">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="trForReport">
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()} className="thForReport">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="trForReport">
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()} className="tdForReport">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination m-4 gap-2">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<<"}
        </button>{" "}
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="btnBackg"
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">"}
        </button>{" "}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="btnBackg"
        >
          {">>"}
        </button>{" "}
        <span className="mx-3">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50, 60].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default memo(MemberCountByLocationReportList);
