import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import VehicleInsuranceDataTypes from "types/vehicleInsuranceDataTypes";
import toastValidation, { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import { useLoading } from "Context/LoadingContext";
import { formatDate, formatDateToAdd, orgDoc } from "constants/Constants";

type Props = {};

const VehiclePucMasterEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const location = useLocation();
  const [vehicleIns, setVehicleIns] = useState<VehicleInsuranceDataTypes>();
  const { state } = useLocation();
  console.log(state);

  const dropdown = useCallback(async () => {
    setLoading(true);
    try {
      const getOneVehicleIns = await axiosInstance.get(
        `/master/getOneVehicleInsurance/${state.incId}`
      );
      setVehicleIns(getOneVehicleIns?.data?.insurance);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    dropdown();
  }, [dropdown]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: vehicleIns?.name,
      insuranceDate: formatDateToAdd(vehicleIns?.insuranceDate),
      validUptoDate: formatDateToAdd(vehicleIns?.validUptoDate),
      amount: vehicleIns?.amount,
      insuranceNo: vehicleIns?.insuranceNo,
      insDoc: vehicleIns?.document,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Insurance Company Name Too Short!")
        .max(30, "Insurance Company Name Too Long!")
        .required("Insurance Company Name is Required"),
      insuranceNo: Yup.string()
        .min(1, "Insurance Number is Too Short!")
        .max(30, "Insurance Number Too Long!")
        .required("Insurance Number is Required"),
      insuranceDate: Yup.string().required(
        "Insurance Valid from Date  is Required"
      ),
      validUptoDate: Yup.string().required(
        "Insurance Valid Upto Date is Required"
      ),
      // document: Yup.string().required("Document is Required"),
      amount: Yup.string()
        .matches(/^[0-9]\d*$/, "Enter a valid Amount")
        .required("amount is Required"),
      insDoc: Yup.mixed()
        .test("fileSize", "Insurance Document size is too high", (value) => {
          if (typeof value === "string") return true; // Skip the test for default file string
          if (!value) return true; // Skip the test if no file is selected
          return value.size <= 209715200; // 200MB in bytes
        })
        .test(
          "fileType",
          "Please enter a Insurance Document with jpg, jpeg, png, pdf, or docx extension",
          (value) => {
            if (typeof value === "string") return true; // Skip the test for default file string
            if (!value) return true; // Skip the test if no file is selected
            return /\.(jpg|jpeg|png|pdf|docx?)$/i.test(value.name);
          }
        ),
    }),
    onSubmit: async ({
      name,
      insuranceDate,
      validUptoDate,
      amount,
      insuranceNo,
      insDoc,
    }) => {
      try {
        const formData = new FormData();
        formData.append("name", name || "");
        formData.append("amount", String(amount) || "");
        formData.append("insuranceNo", insuranceNo || "");
        formData.append(
          "insuranceDate",
          formatDate(vehicleIns?.insuranceDate) === formik.values.insuranceDate
            ? formatDateToAdd(vehicleIns?.insuranceDate)
            : insuranceDate
        );
        formData.append(
          "validUptoDate",
          formatDate(vehicleIns?.validUptoDate) === formik.values.validUptoDate
            ? formatDateToAdd(vehicleIns?.validUptoDate)
            : validUptoDate
        );
        formData.append("insDoc", insDoc || "");
        formData.append("description", "");
        formData.append("note", "");
        formData.append("details", "");

        const res = await axiosInstance.put(
          `/master/updateVehicleInsurance/${vehicleIns?._id}`,
          formData
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          const item = state;
          nav(
            {
              pathname: "/vehicleRegistration/editForm",
              search: "?index=2",
            },
            { state: item }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name ? "empty-form" : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Insurance Company Name"
              />

              <span className="top-placeholder">Insurance Company Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="insuranceNo">
              <input
                className={`form-control ${
                  formik.errors.insuranceNo ? "empty-form" : ""
                }`}
                id="insuranceNo"
                name="insuranceNo"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.insuranceNo}
                placeholder="Insurance Number"
              />

              <span className="top-placeholder">Insurance Number*</span>
            </label>

            <label className="col-md-6 mb-3" htmlFor="insuranceDate">
              <input
                className={`form-control session-date ${
                  formik.errors.insuranceDate ? "empty-form" : ""
                }`}
                id="insuranceDate"
                name="insuranceDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.insuranceDate}
                // onFocus={(e) => {
                //   e.target.type = "date";
                // }}
                placeholder="Insurance Date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />

              <span className="top-placeholder">Insurance Date*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="validUptoDate">
              <input
                className={`form-control session-date ${
                  formik.errors.validUptoDate ? "empty-form" : ""
                }`}
                id="validUptoDate"
                name="validUptoDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.validUptoDate}
                // onFocus={(e) => {
                //   e.target.type = "date";
                // }}
                placeholder="Valid Upto Date"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />

              <span className="top-placeholder">Valid Up To*</span>
            </label>

            <label className="col-md-6 mb-3" htmlFor="amount">
              <input
                className={`form-control ${
                  formik.errors.amount ? "empty-form" : ""
                }`}
                id="amount"
                name="amount"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.amount}
                placeholder="Amount"
              />
              <span className="top-placeholder">Amount*</span>
            </label>
            {(vehicleIns?.document as string) !== "" && (
              <div className="col-md-1 text-center">
                <a
                  href={`${orgDoc}${vehicleIns?.document}`}
                  download={`${orgDoc}${vehicleIns?.document}`}
                  rel="noopener noreferrer"
                >
                  Insurance
                </a>
              </div>
            )}
            <label
              className={`${
                vehicleIns?.document !== "" ? "col-md-5" : "col-md-6"
              } mb-3`}
              htmlFor="insDoc"
            >
              <input
                className="form-control insurance form-control-lg"
                id="insDoc"
                name="insDoc"
                type="file"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const file =
                    event.currentTarget.files && event.currentTarget.files[0];
                  formik.setFieldValue("insDoc", file || ""); // If file is null, set an empty string
                }}
                placeholder="Enter document"
              />

              {/* <span className="top-placeholder">Document</span> */}
            </label>
          </div>

          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                toastValidation(formik.errors);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                const item = state;
                nav(
                  {
                    pathname: "/vehicleRegistration/editForm",
                    search: "?index=2",
                  },
                  { state: item }
                );
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(VehiclePucMasterEditForm);
