import React, { memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import axiosInstance from "axiosInstance";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { useAreaEdit } from "Context/AreaEditContext";

type Props = {};

const DivisionEditForm = (props: Props) => {
  const { setLoading } = useLoading();
  const { state } = useLocation();
  const nav = useNavigate();

  const { area } = useAreaEdit();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      areaCode: state?.item?.areaCode,
      description: state?.item?.description,
      active: state?.item?.isActive,
      note: state?.item?.note,
      details: state?.item?.details,
    },
    validationSchema: Yup.object().shape({
      areaCode: Yup.string()
        .min(1, "Division Name is too short!")
        .max(30, "Division Name is too long!")
        .required("Division Name is Required"),
      code: Yup.string().max(30, "Division name is too long"),
      description: Yup.string().max(300, "Description is too long"),
    }),
    onSubmit: async ({ areaCode, active, description, details, note }) => {
      setLoading(true);
      try {
        const formData = {
          areaId: state?.item?.areaId,
          areaCode: areaCode,
          pinCode: state?.item?.pinCode,
          description: description,
          note: note,
          details: details,
          isActive: active,
        };
        const res = await axiosInstance.put(
          `/master/updateAreaDivision/${state.item._id}`,
          formData
        );
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          setLoading(false);
          nav("..", { state: { item: area } });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <div className="formBg">
      <form className="row align-items-center " onSubmit={formik.handleSubmit}>
        <label className="col-md-6 mb-3" htmlFor="areaCode">
          <span className="top-placeholder">Division*</span>

          <input
            className={`form-control ${
              formik.errors.areaCode && formik.submitCount > 0
                ? "empty-form"
                : ""
            }`}
            id="areaCode"
            name="areaCode"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.areaCode}
            placeholder="Division"
          />
        </label>
        <div className="col-md-6 mb-3">
          <label htmlFor="active" className="custCheck">
            Active
            <input
              type="checkbox"
              id="active"
              name="active"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              checked={formik.values.active}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="col-md-12 mb-3 text-center">
          <SubmitBtn errors={formik.errors} />
          <CancelBtn path=".." />
        </div>
      </form>
    </div>
  );
};

export default memo(DivisionEditForm);
