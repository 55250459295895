import { memo } from "react";
import { OrganizationType } from "types/organizationTypes";
import {formatDate} from 'constants/Constants'

type Props = {
  x: OrganizationType;
};

const NewCustomerItem = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td className="py-4">{x.name}</td>
      <td className="py-4">{x.ContactNo}</td>
      <td className="py-4">{x.Email}</td>
      <td className="py-4">{x.businessType}</td>
      <td className="py-4">{formatDate(x.createdAt)}</td>
     </tr>
  );
};

export default memo(NewCustomerItem);
