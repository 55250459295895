import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Polyline,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import axiosInstance from "axiosInstance";
import NotFound from "components/NotFound";
import { containerStyle } from "types/driverTracking";
import { libraries } from "../../../constants/Constants";
import mapHome from "../../../assets/images/home-map.png";
import mapBus from "../../../assets/images/mapBus.png";
import mapSchool from "../../../assets/images/map_school.jpg";
import { Data, PickDropType } from "types/alltrackingTypes";
import { useLocation } from "react-router-dom";
import { todayDate } from "constants/Constants";
import DriverDetails from "./driverDetails";

const VehicleLatLog: React.FC = () => {
  const { state } = useLocation();
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [apicallhitduration, setApicallhitduration] = useState("");
  const [separatedLatLng, setSetseparatedLatLng] = useState<Data[]>(
    [] as Data[]
  );
  const [pickUpDropMarkers, setPickUpDropMarkers] = useState<JSX.Element[]>([]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries: libraries as (
      | "places"
      | "drawing"
      | "geometry"
      | "localContext"
      | "visualization"
    )[],
  });
  const fetchPickUpDropLocation = async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getAllPickUpDropLatLong?vehicleId=${state.item.vehicleRegId}&shiftTypeId=${state.item.shiftTypeId}`
      );
      const data: PickDropType[] = res.data.pickupdroplatlong;
      const routeIds = separatedLatLng.map((vehicle) => vehicle.routeId);
      const filteredData = data.filter((item) => routeIds.includes(item._id));
      const markers = filteredData.map((item, index) => (
        <Marker
          key={index}
          position={{
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          }}
          title={`Pick and Drop Location`}
          icon={{
            url: mapHome,
            scaledSize: new google.maps.Size(32, 32),
          }}
        />
      ));
      setPickUpDropMarkers(markers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPickUpDropLocation();
  }, [separatedLatLng]);

  const getData = async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getAllLatLog?vehicleId=${state.item.vehicleRegId}&shiftTypeId=${state.item.shiftTypeId}&date=${todayDate}`
      );
      const latlogData = res.data.latlog;
      setSetseparatedLatLng(latlogData);
      setApicallhitduration(latlogData[0]?.apiHitDuration);
      if (latlogData.length > 0) {
        const lastRecord =
          latlogData[0].records[latlogData[0].records.length - 1];
        setCenter({ lat: lastRecord.lat, lng: lastRecord.lng });
      }
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (apicallhitduration !== "") {
      const interval = setInterval(getData, Number(apicallhitduration) * 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [apicallhitduration]);
  if (!isLoaded) return null;
  const renderMarkersAndPolylines = () => {
    return separatedLatLng.map((vehicle: Data, index: number) => (
      <React.Fragment key={index}>
        <Marker
          position={vehicle.records[0]}
          icon={{
            url: mapSchool,
            scaledSize: new google.maps.Size(32, 32),
          }}
          title={`Start Location`}
        />
        <Marker
          position={vehicle.records[vehicle.records.length - 1]}
          icon={{
            url: mapBus,
            scaledSize: new google.maps.Size(40, 40),
          }}
          title={`Current Location`}
        />
        {pickUpDropMarkers}
        <Polyline
          key={`polyline-${vehicle?._id}`}
          path={vehicle?.records}
          options={{
            strokeColor: "#0066ff",
            strokeOpacity: 1,
            strokeWeight: 6,
            icons: [
              {
                icon: {
                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  scale: 3,
                },
                offset: "100%",
              },
            ],
          }}
        />
      </React.Fragment>
    ));
  };
  return (
    <>
      <DriverDetails
        _id={state.item._id}
        shiftTypeId={state.item.shiftTypeId}
      />
      {separatedLatLng.length > 0 ? (
        <GoogleMap mapContainerStyle={containerStyle} zoom={15} center={center}>
          {renderMarkersAndPolylines()}
        </GoogleMap>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default VehicleLatLog;
