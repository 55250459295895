import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { FeesKmType } from "types/feesKmTypes";
import { currencySymbol } from "constants/Constants";

type Props = {
  x: FeesKmType;
  state: any;
  segment: any;
};

const FeesKmListItem = ({ x, state, segment }: Props) => {
  const nav = useNavigate();

  return (
    <tr key={x._id}>
      <td>
        {x.minRange} - {x.maxRange}
      </td>
      <td>
        {currencySymbol}
        {x.amount}
      </td>
      {state.configId === state._id && (
        <td className="text-center">
          {x.isActive === true ? <Eye /> : <EyeOff />}
        </td>
      )}
      {state.configId === state._id && state.isPaymentMade === false && (
        <td className="text-center pointer">
          <Edit
            id={x._id}
            onClick={(e) => {
              const item = { ...state, kmEditId: x._id };
              if (segment === "feesSectionEdit") {
                nav(
                  {
                    pathname: "/transportationFeesMain/feesSection/kmEditForm",
                    search: "?index=2&fromEdit=1",
                  },
                  {
                    state: item,
                  }
                );
              } else {
                nav(
                  {
                    pathname: "/transportationFeesMain/feesSection/kmEditForm",
                    search: "?index=2",
                  },
                  {
                    state: item,
                  }
                );
              }
            }}
          />
        </td>
      )}
    </tr>
  );
};

export default memo(FeesKmListItem);
