import { ConfigProvider } from "Context/ConfigContext";
import axiosInstance from "axiosInstance";
import React, { memo, useEffect, useCallback, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BasicVehicleMaster } from "./List";

type Props = {};

const Config: React.FC<{}> = (props: Props) => {
  const location = useLocation();

  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("VehicleType");
  const [searchCount, setSearchCount] = useState(0);
  const [listData, setListData] = useState<BasicVehicleMaster[]>([]);

  const LoadData = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        const res = await axiosInstance.get(
          `/master/getAllBasicVehicleMasters?page=${pageNum}&sort=${sortParam}`
        );
        // console.log(res);
        const vehicleList: BasicVehicleMaster[] = [
          ...res.data.allBasicVehicle[0].paginatedResults,
        ];
        if (vehicleList.length === 0) {
          setListData([]);
          setSearchCount(0);
        }
        if (vehicleList.length > 0) {
          setSearchCount(res.data.allBasicVehicle[0].totalCount[0].count);
          if (pageNum === 1) {
            setListData(vehicleList);
          } else {
            setListData((prevList) => [...prevList, ...vehicleList]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [listData]
  );

  useEffect(() => {
    LoadData(page, sort);
  }, [listData, page, sort, ascDsc]);

  return (
    <>
      <ConfigProvider>
        <div className="pageTitle justify-content-between align-items-center d-flex">
          <span>Vehicle</span>
          {location.pathname === "/basicvehicle" && listData.length === 0 && (
            <Link to={"/basicvehicle/add"}>
              <button className="btn btn-primary" type="submit">
                Add
              </button>
            </Link>
          )}
        </div>

        <Outlet />
      </ConfigProvider>
    </>
  );
};

export default memo(Config);
