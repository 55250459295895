/* eslint-disable array-callback-return */
import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { ReqForCabDataType } from "../../../types/reqForCabTypes";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import ReqForCabListItem from "./reqForCabListItem";

type Props = {};

const ReqForCabList = (props: Props): JSX.Element => {
  const [reqForCab, setReqForCab] = useState<ReqForCabDataType[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("memberName");
  const [searchCount, setSearchCount] = useState(0);

  const loadReqForCab = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getallReqForCab?page=${pageNum}&sort=${sortParam}`
        );
        setSearchCount(res.data.ReqForCabs[0]?.totalCount[0]?.count);
        const reqForCabs: ReqForCabDataType[] = [
          ...reqForCab,
          ...res.data.ReqForCabs[0]?.paginatedResults,
        ];
        setReqForCab(reqForCabs);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [reqForCab]
  );

  useEffect(() => {
    loadReqForCab(page, sort);
  }, [page, sort, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(reqForCab, setPage, page, searchCount);
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th
              id="memberName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setReqForCab, setPage);
              }}
            >
              Member Name
              <span className="px-2">
                {sort === "memberName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="pickUpTime"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setReqForCab, setPage);
              }}
            >
              PickUp Time
              <span className="px-2">
                {sort === "pickUpTime" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="dropLocation"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setReqForCab, setPage);
              }}
            >
              Drop Location
              <span className="px-2">
                {sort === "dropLocation" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="status"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setReqForCab, setPage);
              }}
            >
              Status
              <span className="px-2">
                {sort === "status" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="reqTime"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setReqForCab, setPage);
              }}
            >
              Request Time
              <span className="px-2">
                {sort === "reqTime" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {reqForCab.map((x) => {
            return <ReqForCabListItem x={x} key={x._id} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(ReqForCabList);
