import axiosInstance from "axiosInstance";
import React, { useCallback, useEffect, useState } from "react";
import socket from "socket";
import { driverVehicleDetails } from "types/driverVehicleTypes";
import memberPickupImg from "../../../assets/images/pickupCount.svg";
import memberInImg from "../../../assets/images/inCount-02.png";
import memberOut from "../../../assets/images/outCount.svg";
import memberWait from "../../../assets/images/waitCount-04.png";
import CardComponent from "app/admin/Dashboard/CardComponent";

type DriverDetailsProps = {
  _id: string;
  shiftTypeId: string;
};

type CardComponentProp = {
  count: string;
  text: string;
  background: string;
  className?: string;
};

const DriverDetails: React.FC<DriverDetailsProps> = ({ _id, shiftTypeId }) => {
  const [memberVehicleDetails, setMemberVehicleDetails] = useState<
    driverVehicleDetails[]
  >([]);
  const [rfidCardRequired, setRfidCardRequired] = useState(true);

  const loadMemberVehicleDetails = useCallback(async () => {
    try {
      const res = await axiosInstance.get(
        `/master/getAllVehicleMembersDetails`
      );
      const data = res.data.vehicleMemberToday;
      // Filter the data based on _id and shiftTypeId
      const filteredData = data.filter(
        (item: driverVehicleDetails) =>
          item._id === _id && item.shiftTypeId === shiftTypeId
      );

      setMemberVehicleDetails(filteredData);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    loadMemberVehicleDetails();

    socket.on("update-count", (data) => {
      if (
        data === "inMember" ||
        data === "outMember" ||
        data === "waitMember" ||
        data === "memberPick"
      ) {
        loadMemberVehicleDetails();
      }
    });
  }, []);

  useEffect(() => {
    const x = localStorage.getItem("config") as string;
    const config: any = JSON.parse(x);
    setRfidCardRequired(config.rfidCardCheck);
  }, []);

  return (
    <>
      {memberVehicleDetails.length > 0 && (
        <div className="container-fluid">
          {rfidCardRequired ? (
            <div className="row">
              <CardComponent
                count={Number(memberVehicleDetails[0].memberPickupInVehicle)}
                text="Pickup "
                background={memberPickupImg}
              />
              <CardComponent
                count={Number(memberVehicleDetails[0].inMember)}
                text="In count "
                background={memberInImg}
              />
              <CardComponent
                count={Number(memberVehicleDetails[0].outMember)}
                text="Out count "
                background={memberOut}
              />
              <CardComponent
                count={Number(memberVehicleDetails[0].waitMember)}
                text="Wait count "
                background={memberWait}
              />
            </div>
          ) : (
            <div className="row">
              <CardComponent
                count={Number(memberVehicleDetails[0].memberPickupInVehicle)}
                text="Pickup "
                background={memberPickupImg}
                className="col-lg-6 mb-3"
              />
              <CardComponent
                count={Number(memberVehicleDetails[0].waitMember)}
                text="Wait count "
                background={memberWait}
                className="col-lg-6 mb-3"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DriverDetails;
