import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { AdminExpiredPlanDataType } from "types/adminExpiredPlanTypes";
import { infiniteScroll, sortData } from "constants/Constants";
import AdminExpirePlanListItem from "./expirePlanListItem";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import AddSearch from "components/AddSearchComponent";

type Props = {};

const AdminExpirePlanList = (props: Props): JSX.Element => {
  const [ExpirePlans, setExpirePlans] = useState<AdminExpiredPlanDataType[]>(
    []
  );
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const loadPlans = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `admin/getAllAdminExpiredPlan?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newExp = res.data.expirePlan[0].paginatedResults;
        if (searchKeyword !== "" && pageNum === 1) {
          if (newExp.length === 0) {
            setExpirePlans([]);
            setSearchCount(0);
          }
        }
        if (newExp.length > 0) {
          setSearchCount(res.data.expirePlan[0].totalCount[0].count);
          if (pageNum === 1) {
            setExpirePlans(newExp);
          } else {
            setExpirePlans((prevPln) => [...prevPln, ...newExp]);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [ExpirePlans]
  );
  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadPlans(1, sort, search); // Trigger API call with updated search keyword
  };

  useEffect(() => {
    loadPlans(page, sort, searchQuery);
  }, [page, sort, ascDsc, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(ExpirePlans, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Name or Email"
      />
      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setExpirePlans,
                  setPage
                );
              }}
            >
              Name
              {sort === "name" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="bussinessName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setExpirePlans,
                  setPage
                );
              }}
            >
              Business Type
              {sort === "bussinessName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="planName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setExpirePlans,
                  setPage
                );
              }}
            >
              Plan Name
              {sort === "planName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="planStartDate"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setExpirePlans,
                  setPage
                );
              }}
            >
              Plan Start
              {sort === "planStartDate" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="planExpired"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(
                  ascDsc,
                  setAscDsc,
                  e,
                  setSort,
                  setExpirePlans,
                  setPage
                );
              }}
            >
              Plan Expired
              {sort === "planExpired" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {ExpirePlans?.map((x) => {
            return <AdminExpirePlanListItem x={x} key={x._id} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(AdminExpirePlanList);
