import React from "react";
import { Outlet } from "react-router-dom";

type Props = {};

const PaymentConfiguration = (props: Props) => {
  return (
    <div>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Payment Configuration</span>
      </div>
      <Outlet />
    </div>
  );
};

export default PaymentConfiguration;
