import { dismissToast } from "constants/toastConfig";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  path: string;
  btnTxt?: string;
  state?: any;
  wrapperClass?: string;
  onClick?: () => void;
  disabled?: boolean;
};

const CancelBtn = ({
  path,
  btnTxt,
  state,
  wrapperClass,
  onClick,
  disabled,
}: Props) => {
  return (
    <Link to={path} state={state} onClick={onClick ? onClick : () => {}}>
      <button
        className="btn btn-primary"
        onClick={dismissToast}
        disabled={disabled}
      >
        {btnTxt ?? "Cancel"}
      </button>
    </Link>
  );
};

export default CancelBtn;
