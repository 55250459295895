import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { AdminExpirePlanDataType } from "../../../types/adminExpirePlanTypes";
import { infiniteScroll, sortData } from "constants/Constants";
import AdminExpirePlanListItem from './expirePlanListItem';
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";

type Props = {};

const AdminExpirePlanList = (props: Props): JSX.Element => {
  const [ExpirePlans, setExpirePlans] = useState<AdminExpirePlanDataType[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);

  const loadExpirePlans = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        setLoading(true);

        const res = await axiosInstance.get(
          `admin/getAllAdminExpirePlan?page=${pageNum}&sort=${sortParam}`
        );

        setSearchCount(res.data.SearchCount);

        if (pageNum === 1) {
          setExpirePlans(res.data.expirePlan[0].paginatedResults);
        } else {
          setExpirePlans((prevExpirePlans) => [...prevExpirePlans, ...res.data.expirePlan[0].paginatedResults,
          ]);
        }


        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    []
  );

  const reminder = async (x: AdminExpirePlanDataType) => {
    setLoading(true);

    try {
      const reminderItem = {
        organizationplanId: x._id,
        name: x.name,
        bussinessName: x.bussinessName,
        planName: x.planName,
        planExpired: x.planExpired,
        day: x.day,
      };

      const res = await axiosInstance.post(
        "/admin/addAdminPlanReminder",
        reminderItem,
        {}
      );
      console.log(res)
      setLoading(false);
      loadExpirePlans(page, sort);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    loadExpirePlans(page, sort);
  }, [page, sort]);

  window.onscroll = () => {
    infiniteScroll(ExpirePlans, setPage, page, searchCount);
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setExpirePlans, setPage);
              }}
            >
              Name
              {sort === "name" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="bussinessName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setExpirePlans, setPage);
              }}
            >
              Business Type
              {sort === "bussinessName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="planName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setExpirePlans, setPage);
              }}
            >
              Plan Name
              {sort === "planName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="planExpired"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setExpirePlans, setPage);
              }}
            >
              Plan Expired
              {sort === "planExpired" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="day"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setExpirePlans, setPage);
              }}
            >
              Day
              {sort === "day" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="reminder"
              role="button"
              className="text-center"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setExpirePlans, setPage);
              }}
            >
              Reminder
              {sort === "reminder" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="lastRemindDate"
              role="button"
              className="text-center"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setExpirePlans, setPage);
              }}
            >
              Last Reminder Date
              {sort === "lastRemindDate" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {ExpirePlans?.map((x) => {
            return <AdminExpirePlanListItem x={x} key={x._id} reminder={reminder} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(AdminExpirePlanList);
