export type center = {
    lat: number;
    lng: number;
};
export type LocationItem = {
    latitude: number;
    longitude: number;
    createdAt: string;
};
export const containerStyle = {
    width: "100%",
    height: "100vh",
};
export type LatLng = google.maps.LatLng;

export type DirectionsResult = google.maps.DirectionsResult

export type DirectionsRendererType = google.maps.DirectionsRenderer

