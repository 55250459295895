import React, { memo, useState } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import axiosInstance from "axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { successToast, warnToast } from "constants/toastConfig";
import { useLoading } from "Context/LoadingContext";

type Props = {};

const AdminRequestEditForm = (props: Props) => {
  const { state } = useLocation();
  const location = useLocation();
  const nav = useNavigate();
  const { setLoading } = useLoading();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      demoDate: state.demoDate,
      Time: state.Time,
      status: state.status,
      Description: "",
    },
    validationSchema: Yup.object().shape({
      demoDate: Yup.string().required("DemoDate is Required"),
    }),
    onSubmit: async ({ demoDate, Time, status, Description }) => {
      setLoading(true);
      try {
        const formData = {
          demoDate: demoDate,
          Time: Time,
          status: status,
          Description: Description,
        };
        const res = await axiosInstance.post(
          `/admin/addAdminDemoReschedule/${state._id}`,
          formData
        );
        successToast();
        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          setLoading(false);
          nav("/adminRequest");
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Requested by: {state.name}</span>
      </div>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="demoDate">
              <span className="top-placeholder">Demo Date*</span>
              <input
                className={`form-control email-control ${
                  formik.values.demoDate === "" ? "empty-form" : ""
                }`}
                id="demoDate"
                name="demoDate"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.demoDate}
                placeholder="Demo Date"
                onFocus={(e) => (e.target.type = "date")}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="Time">
              <span className="top-placeholder">Time*</span>
              <input
                className="form-control email-control"
                id="Time"
                name="Time"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.Time}
                placeholder="Time"
                onFocus={(e) => (e.target.type = "time")}
              />
            </label>
            <label className="col-md-6 mb-3" htmlFor="status">
              <span className="top-placeholder">Status*</span>
              <select
                className="form-control"
                id="status"
                name="status"
                onChange={(e) => {
                  formik.setFieldValue("status", e.target.value);
                }}
                value={formik.values.status}
              >
                <option value="1">New Demo</option>
                <option value="2">Rescheduled Demo</option>
                <option value="3">Done Demo</option>
                <option value="4">Not Attended</option>
                <option value="5">Cancel Demo</option>
              </select>
            </label>
            <label className="col-md-12 mb-3" htmlFor="Description">
              <span className="top-placeholder">Description</span>
              <textarea
                className="form-control"
                id="Description"
                name="Description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.Description}
                placeholder="Description"
              ></textarea>
            </label>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() =>
                formik.errors.demoDate && warnToast("Enter valid Date")
              }
            >
              Submit
            </button>
            {location.pathname === "/adminRequest/requestEditForm" && (
              <Link to={"/adminRequest"}>
                <button className="btn btn-primary" type="submit">
                  Cancel
                </button>
              </Link>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(AdminRequestEditForm);
