import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { AdminPlanDataType } from "../../../types/adminPlanTypes";
import AdminPlanListItem from "./planListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import AddSearch from "components/AddSearchComponent";

type Props = {};

const AdminPlanList = (props: Props): JSX.Element => {
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("plan");
  const [searchCount, setSearchCount] = useState(0);
  const [plans, setPlans] = useState<AdminPlanDataType[]>([]);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { setLoading } = useLoading();
  const loadPlans = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/admin/getAllAdminPlanAuth?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newAdmPln = res.data.AdminPlan[0].paginatedResults;
        if (searchKeyword !== "" && pageNum === 1) {
          if (newAdmPln.length === 0) {
            setPlans([]);
            setSearchCount(0);
          }
        }
        if (newAdmPln.length > 0) {
          setSearchCount(res.data.SearchCount);
          if (pageNum === 1) {
            setPlans(newAdmPln);
          } else {
            setPlans((prevPln) => [...prevPln, ...newAdmPln]);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [plans]
  );
  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadPlans(1, sort, search); // Trigger API call with updated search keyword
  };
  useEffect(() => {
    loadPlans(page, sort, searchQuery);
  }, [page, sort, ascDsc, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(plans, setPage, page, searchCount);
  };

  return (
    <>
      <AddSearch
        addLink="/adminPlan/adminPlanForm"
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Plan or Code"
      />
      <table className="table">
        <thead>
          <tr>
            <th
              id="plan"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setPlans, setPage);
              }}
            >
              Plan
              <span className="px-2">
                {sort === "plan" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="code"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setPlans, setPage);
              }}
            >
              Code
              <span className="px-2">
                {sort === "code" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="price"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setPlans, setPage);
              }}
            >
              Price
              <span className="px-2">
                {sort === "price" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="discount"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setPlans, setPage);
              }}
            >
              Discount
              <span className="px-2">
                {sort === "discount" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="startDate"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setPlans, setPage);
              }}
            >
              Start Date
              <span className="px-2">
                {sort === "startDate" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="endDate"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setPlans, setPage);
              }}
            >
              End Date
              <span className="px-2">
                {sort === "endDate" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {plans?.map((x: AdminPlanDataType) => {
            return <AdminPlanListItem x={x} key={x._id} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(AdminPlanList);
