import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import { ConfigurationDataType } from "../../../types/configurationTypes";
import PlnReqCongItem from './plnReqConfigItem';
import { useLoading } from "Context/LoadingContext";
import { useLocation } from "react-router-dom";

type Props = {};

const PlnReqConfig = (props: Props): JSX.Element => {
  const { state } = useLocation();
  const { setLoading } = useLoading();
      const [config, setConfig] = useState<ConfigurationDataType[]>([]);
  const configData = useCallback(
            async () => {
                try {
                    setLoading(true);
                    const res = await axiosInstance.get(
                        `/master/getConfDetail/`+ state.organizationId)
                    const conData: ConfigurationDataType[] = [
                        ...config,
                        ...res.data.configuration,
                    ];
                    setConfig(conData);
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
            },
            [config]
        );
        useEffect(() => {
            configData();
        }, []);
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>
              Key
            </th>
            <th>
              Value
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {config.map((x,index) => {
            return <PlnReqCongItem x={x} key={index} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(PlnReqConfig);

