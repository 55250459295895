import React, { memo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
type Props = {}

const Errors: React.FC<{}> = (props: Props) => {
  const location = useLocation()

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Error Management </span>
        {location.pathname === "/errorhandling"}
      </div>
      <Outlet />
    </>
  )
}
export default memo(Errors)
