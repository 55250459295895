import React, { memo } from 'react'
import { Outlet } from 'react-router-dom'

type Props = {}
const Notification = (props: Props) => {

    return (
        <>
            <div className="pageTitle justify-content-between align-items-center d-flex">
                <span>Notification</span>
            </div>
            <Outlet />
        </>
    )
}

export default memo(Notification)
