import { JwtPayloadExtend } from "Context/UserContext";
import jwtDecode from "jwt-decode";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAuth = () => {
  const location = useLocation();
  const accessToken = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const auth = () => {
    if (accessToken === null) return false;
    const organization = jwtDecode(accessToken) as JwtPayloadExtend;
    if (organization.exp === undefined) return false;
    if (organization.exp > Math.floor(new Date().getTime() / 1000)) return true;
  };
  const authenticated = auth();

  return authenticated && role ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
export default RequireAuth;
