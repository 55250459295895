import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import BasicMemberWaitListItem from "./basicMemberWaitListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { useLoading } from "Context/LoadingContext";
import NotFound from "components/NotFound";
import { useNotFound } from "Context/NotFound";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { MemberWaitListTypes } from "types/basicMemberWaitListTypes";
type Props = {};

const BasicMemberWaitList = (props: Props): JSX.Element => {
  const [MemberWait, setMemberWait] = useState<MemberWaitListTypes[]>([]);
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("memberName");
  const [searchCount, setSearchCount] = useState(0);
  const { notfound, setNotFound } = useNotFound();

  const currentDate = new Date();
  const months = Array.from(
    { length: currentDate.getMonth() + 1 },
    (_, i) => i + 1
  );

  const loadWaitList = useCallback(
    async (selectedMonth: number, pageNum: number, sort: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `master/MemberWaitListDetails?month=${selectedMonth}&page=${pageNum}&sort=${sort}`
        );
        // console.log(res);
        const waitList: MemberWaitListTypes[] = [
          ...res.data.resp[0].paginatedResults,
        ];
        // console.log(waitList.length);
        if (pageNum === 1) {
          if (waitList.length === 0) {
            setNotFound(true);
            setMemberWait([]);
            setSearchCount(0);
          }
        }
        if (waitList.length > 0) {
          setSearchCount(res.data.resp[0].totalCount[0].count);
          if (pageNum === 1) {
            setMemberWait(waitList);
          } else {
            setMemberWait((prevList) => [...prevList, ...waitList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [MemberWait]
  );

  useEffect(() => {
    loadWaitList(month, page, sort);
  }, [month, page, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(MemberWait, setPage, page, searchCount);
  };

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Member Wait List</span>
      </div>
      <div className="formBg mb-3">
        <div className="mb-2 row align-items-center">
          <label className="col-md-12 mb-3" htmlFor="month-dropdown">
            <select
              className="form-control"
              id="month-dropdown"
              name="month-dropdown"
              value={month}
              onChange={(e) => {
                const selectedMonth = parseInt(e.target.value);
                setMonth(selectedMonth);
                setPage(1);
                setMemberWait([]);
              }}
            >
              {months.map((m) => (
                <option
                  key={m}
                  value={m}
                  label={new Date(2000, m - 1, 1).toLocaleString("default", {
                    month: "long",
                  })}
                />
              ))}
            </select>
          </label>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="memberName"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMemberWait, setPage);
              }}
            >
              Member Name
              {sort === "memberName" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>

            <th
              id="memberContact"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMemberWait, setPage);
              }}
            >
              Member Contact
              {sort === "memberContact" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="memberPickUpLocation"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMemberWait, setPage);
              }}
            >
              Pickup Location
              {sort === "memberPickUpLocation" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMemberWait, setPage);
              }}
            >
              Date
              {sort === "createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setMemberWait, setPage);
              }}
            >
              Time
              {sort === "createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {MemberWait?.map((x) => {
              return <BasicMemberWaitListItem x={x} key={x._id} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(BasicMemberWaitList);
