import axiosInstance from "axiosInstance";
import { useCallback, useState, useEffect, memo } from "react";
import { ErrorsType } from "types/errorTypes";
import { infiniteScroll, sortData } from "constants/Constants";
import ErrorListItem from "./errorListItem";
import { useLoading } from "Context/LoadingContext";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";

type Props = {};

const ErrorList = (props: Props): JSX.Element => {
  const [Errors, setErrors] = useState<ErrorsType[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("-createdAt");
  const [searchCount, setSearchCount] = useState(0);

  const loadErrors = useCallback(
    async (pageNum: number, sortParam: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `error/getErrorCollection?page=${pageNum}&sort=${sortParam}`
        );
        const ErrorList: ErrorsType[] = [...Errors, ...res.data.Errors];
        setErrors(ErrorList);
        setSearchCount(res.data.SearchCount);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [Errors]
  );

  useEffect(() => {
    loadErrors(page, sort);
  }, [page, sort]);

  window.onscroll = () => {
    infiniteScroll(Errors, setPage, page, searchCount);
  };
  return (
    <>
      <table className="table" id="dtHorizontalExample">
        <thead>
          <tr>
            <th
              id="createdAt"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              Created Date
              {sort === "createdAt" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="api"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              Api
              {sort === "api" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="isFromMobile"
              role="button"
              className="text-center"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              From Mobile
              {sort === "isFromMobile" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="status"
              role="button"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              Status
              {sort === "status" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th
              id="assign"
              role="button"
              className="text-center"
              onClick={(e) => {
                setPage(1);
                sortData(ascDsc, setAscDsc, e, setSort, setErrors, setPage);
              }}
            >
              Assign
              {sort === "assign" ? (
                <UpArr height="15px" width="20px" />
              ) : (
                <DownArr height="15px" width="20px" />
              )}
            </th>
            <th>Request</th>
            <th>Response</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          {Errors?.map((x, index) => {
            return <ErrorListItem x={x} key={index} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(ErrorList);
