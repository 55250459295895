import React, { memo, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLoading } from "Context/LoadingContext";
import { successToast, warnToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DivisionAddForm from "../AreaDivision/DivisionAddForm";
import LocationMasterAddForm from "../locationMaster/locationMasterAddForm";
import { useAreaEdit } from "Context/AreaEditContext";
import { useLocation } from "react-router-dom";

type Props = {};

const AreaAddForm = (props: Props) => {
  const { state, pathname } = useLocation();
  const { setArea } = useAreaEdit();
  const { setLoading } = useLoading();
  const [selectedIndex, setSelectedIndex] = useState<number>(
    state !== null ? state?.index : 0
  );

  useEffect(() => {
    setSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      areaName: "",
      pinCode: "",
      active: true,
    },
    validationSchema: Yup.object().shape({
      areaName: Yup.string().min(1).max(30).required("Area Name is Required"),
      pinCode: Yup.string().min(6).max(6).required("PinCode is Required"),
      isActive: Yup.boolean().default(true),
    }),
    onSubmit: async ({ areaName, pinCode, active }) => {
      setLoading(true);
      try {
        const formDataArea = {
          areaName: areaName,
          pinCode: String(pinCode),
          isActive: active,
        };
        // const formDataDivision = {
        //   areaCode: `${areaName}1`,
        //   pinCode: String(pinCode),
        //   description: "",
        //   note: "",
        //   details: "",
        //   isActive: active,
        // };

        const resArea = await axiosInstance.post(
          "/master/addArea",
          formDataArea
        );
        if (resArea.data.statusCode === 200) {
          setLoading(false);
          warnToast(resArea.data.message);
        }
        if (resArea.data.statusCode === 201) {
          setLoading(false);
          successToast();
          // const resDivision = await axiosInstance.post(
          //   "/master/addAreaDivision",
          //   formDataDivision
          // );
          // const responseMessageDivision = resDivision.data.message;
          // if (responseMessageDivision === "Ok") {
          //   successToast();
          //   setLoading(false);
          // }
          setArea(resArea.data.result);
          setSelectedIndex(1);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      {pathname !== "/areaMaster" && (
        <div className="d-flex justify-content-end">
          <CancelBtn btnTxt="Back" path="/areaMaster" />
        </div>
      )}
      <Tabs selectedIndex={selectedIndex} onSelect={() => {}}>
        <TabList>
          <Tab disabled={selectedIndex !== 0}>Area</Tab>
          <Tab disabled={selectedIndex !== 1}>Division</Tab>
          <Tab disabled={selectedIndex !== 2}>Location</Tab>
        </TabList>

        <TabPanel>
          <div className="formBg">
            <form onSubmit={formik.handleSubmit}>
              <div className="row align-items-center">
                <label className="col-md-6 mb-3" htmlFor="areaName">
                  <span className="top-placeholder">Area Name*</span>

                  <input
                    className={`form-control ${
                      formik.errors.areaName && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="areaName"
                    name="areaName"
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.areaName}
                    placeholder="Area Name"
                  />
                </label>
                <label className="col-md-6 mb-3" htmlFor="pinCode">
                  <span className="top-placeholder">PIN Code*</span>

                  <input
                    className={`form-control ${
                      formik.errors.pinCode && formik.submitCount > 0
                        ? "empty-form"
                        : ""
                    }`}
                    id="pinCode"
                    name="pinCode"
                    type="number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.pinCode}
                    placeholder="PIN Code"
                  />
                </label>
                <div className="col-md-3 mb-3">
                  <label className="custCheck" htmlFor="active">
                    Active
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      checked={formik.values.active}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="col-md-12 mb-3 text-center">
                <SubmitBtn errors={formik.errors} />
                <CancelBtn path=".." />
              </div>
            </form>
          </div>
        </TabPanel>
        <TabPanel>
          <DivisionAddForm
            setSelectedIndex={setSelectedIndex}
            editIndex={state !== null ? state.index : undefined}
          />
        </TabPanel>
        <TabPanel>
          <LocationMasterAddForm
            editIndex={state !== null ? state.index : undefined}
          />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default memo(AreaAddForm);
