import React, { memo, useCallback, useEffect, useState } from "react";
import { OrganizationType } from "../../../types/organizationTypes";
import axiosInstance from "axiosInstance";
import CardComponent from "../Dashboard/CardComponent";
import memberWait from "../../../assets/images/memberWait.png";
import socket from "socket";
type Props = {};

const NewCustomerWraper: React.FC<{}> = (props: Props) => {
  const [clients, setClients] = useState<OrganizationType[]>([]);

  const loadClients = useCallback(async () => {
    try {
      const res = await axiosInstance.get(`/master/getOrganizationCount`);
      const clientList: OrganizationType[] = [
        ...clients,
        ...res.data.Organizations,
      ];
      setClients(clientList);
    } catch (error) {
      console.log(error);
    }
  }, [clients]);
  useEffect(() => {
    loadClients();
    socket.on("admin-count", (data) => {
      if (data === "client-count") {
        loadClients();
      }
    });
  }, []);
  const newCustomerCount = clients.length > 0 ? clients[0].NewCustomer : 0;
  const verifiedCustomerCount =
    clients.length > 0 ? clients[0].verifiedCustomer : 0;
  return (
    <>
      <CardComponent
        count={newCustomerCount}
        text="New Customer"
        background={memberWait}
      />
      <CardComponent
        count={verifiedCustomerCount}
        text="Verified Customer"
        background={memberWait}
      />
    </>
  );
};
export default memo(NewCustomerWraper);
