import React, { useEffect, useState } from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import axiosInstance from "axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PaymentConfigList = () => {
  const nav = useNavigate();
  const [accountInformation, setAccountInformation] = useState<any>([]);
  const [search, setSearch] = useState<string>("");

  const accountDetails = async () => {
    try {
      const res = await axiosInstance.get("/master/getAccountDetails");
      const NotFound = res.data.statusCode === 404;
      if (NotFound) {
        setAccountInformation([]);
      } else {
        const data = res.data.result.AccountInformation;
        setAccountInformation(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    accountDetails();
  }, []);

  const handleSearch = () => {
    // setPage(1);
    // setSearchQuery(search);
    // LoadAreas(1, sort, search);
  };
  const tooltip = <Tooltip id={`tooltip-${Math.random()}`}>Edit </Tooltip>;

  return (
    <>
      <div className="d-flex justify-content-end  mb-3 ">
        <Link to={"/paymentConfig/addForm"}>
          <button className="btn btn-primary col-md-1" type="button">
            Add
          </button>
        </Link>
      </div>
      <table className="table">
        <thead key={Math.random()}>
          <tr>
            <th>Payment Type</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Primary</th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {accountInformation?.length !== 0 &&
          accountInformation?.map((item: any) => {
            return (
              <tbody key={item._id}>
                <tr key={item._id}>
                  <td>{item.settingType}</td>
                  <td>
                    {item?.settingType === "Company"
                      ? `${item.companyName}`
                      : `${item.name}`}
                  </td>
                  <td>{item.contact}</td>
                  <td>{item.isPrimary === true ? "Yes" : "No"}</td>
                  <td className="text-center">
                    {item.isActive ? <Eye /> : <EyeOff />}
                  </td>
                  <td className="text-center pointer">
                    <OverlayTrigger placement="right" overlay={tooltip}>
                      <Edit
                        id={item._id}
                        onClick={async () => {
                          console.log(item.settingType);
                          nav("/paymentConfig/editForm", {
                            state: {
                              settingType: item.settingType,
                              id: item._id,
                            },
                          });
                        }}
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
              </tbody>
            );
          })}
      </table>
    </>
  );
};

export default PaymentConfigList;
