import { useLoading } from "Context/LoadingContext";
import { useNotFound } from "Context/NotFound";
import axiosInstance from "axiosInstance";
import NotFound from "components/NotFound";
import {
  formatDate,
  infiniteScroll,
  sortData,
  currencySymbol,
} from "constants/Constants";
import { useState, useEffect, useCallback } from "react";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import toastValidation, {
  successToast,
  warnToast,
} from "constants/toastConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import CancelBtn from "components/Buttons/Cancel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Details } from "assets/images/details-19.svg";
import { memImage } from "constants/Constants";

type Props = {};

const BasicMemberFeeList = (props: Props) => {
  const [feesConfig, setFeesConfig] = useState<any>([]);
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const { state } = useLocation();
  // const [page, setPage] = useState<number>(1);
  // const [ascDsc, setAscDsc] = useState<boolean>(false);
  const { notfound, setNotFound } = useNotFound();
  const [configId, setConfigId] = useState("");
  const [paidFees, setPaidFees] = useState("");
  const [payType, setPayType] = useState("cash");
  const [fees, setFees] = useState("");
  const [payDetails, setPayDetails] = useState({
    refId: "",
    // Bank: "",
  });
  const [showPay, setShowPay] = useState(false);
  const [pendingFees, setPendingFees] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [border, setBorder] = useState<boolean>(false);

  const handleClosePay = () => setShowPay(false);

  const loadFeesConfig = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(
        `/master/getAllBasicFeeMember/${state._id}`
      );

      const result = res.data.result;
      setFeesConfig(result);
      // checkValidDateToupdate(feesCongs);
      setLoading(false);
      setNotFound(false);
    } catch (error) {
      setLoading(false);
      setNotFound(true);
      console.log(error);
    }
  }, [feesConfig]);

  const validationObjCheque = {
    amount: Yup.string()
      // .matches(/^(0|[1-9][0-9]*)$/, "Only numbers are allowed")
      .required("Amount is required"),
    chequeRefId: Yup.string().required("please enter cheque reference id"),
    // bankName: Yup.string().required("please enter bank name"),
  };
  const validationObjCash = {
    amount: Yup.string()
      // .matches(/^(0|[1-9][0-9]*)$/, "Only numbers are allowed")
      .required("Amount is required"),
  };

  const formikPay = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
      chequeRefId: pendingFees ? "" : payDetails.refId,
      // bankName: pendingFees ? "" : payDetails.Bank,
    },
    validationSchema: Yup.object().shape(
      payType === "cheque" ? validationObjCheque : validationObjCash
    ),
    onSubmit: async ({ amount, chequeRefId }) => {
      setLoading(true);
      try {
        if (payType === "cheque" && !pendingFees) {
          const formData = {
            memberFees: fees,
            feesConfigId: state._id,
            paidFees: Number(amount),
            memberId: memberId,
            paymentMode: "cheque",
            referenceNo: chequeRefId,
            paidStatus: "success",
            paymentErrorMsg: "",
          };
          const res = await axiosInstance.post(
            `/master/memberPayment`,
            formData
          );
          const responseMessage = res.data.message;
          if (responseMessage === "Created") {
            successToast();
            setShowPay(false);
            setLoading(false);
            loadFeesConfig();
          }
        } else if (payType === "cash" && !pendingFees) {
          const formData = {
            memberFees: fees,
            feesConfigId: state._id,
            paidFees: formikPay.values.amount,
            memberId: memberId,
            paymentMode: "cash",
            referenceNo: "",
            paidStatus: "success",
            paymentErrorMsg: "",
          };

          const res = await axiosInstance.post(
            `/master/memberPayment`,
            formData
          );
          const responseMessage = res.data.message;
          if (responseMessage === "Created") {
            successToast();
            setShowPay(false);
            setLoading(false);
            loadFeesConfig();
          }
        }
        // else if (payType === "cash" && !pendingFees) {
        //   const formData = {
        //     feesConfigId: state._id,
        //     memberId: memberId,
        //     paidFees: formikPay.values.amount,
        //     referenceNo: "",
        //   };

        //   const res = await axiosInstance.post(
        //     `/master/updateBasicPayment`,
        //     formData
        //   );
        //   const responseMessage = res.data.statusCode;
        //   if (responseMessage === 200) {
        //     successToast();
        //     setShowPay(false);
        //     setLoading(false);
        //   }
        // } else {
        //   const formData = {
        //     feesConfigId: state._id,
        //     memberId: memberId,
        //     paidFees: formikPay.values.amount,
        //     referenceNo: chequeRefId,
        //   };
        //   const res = await axiosInstance.put(
        //     `/master/updateCashChequePayment/${state._id}`,
        //     formData
        //   );
        //   const responseMessage = res.data.statusCode;
        //   if (responseMessage === 200) {
        //     successToast();
        //     setShowPay(false);
        //     setLoading(false);
        //   }
        // }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  function toggleCheckbox(extSetting: string) {
    if (extSetting !== null) {
      const type = extSetting;
      const checkbox: any = document.getElementById(type);
      checkbox.checked = true;
    }
  }

  useEffect(() => {
    loadFeesConfig();
  }, []);

  const checkValidDateToupdate = (feesCongs: any[]) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    feesCongs.forEach((x) => {
      const endFeesYear = new Date(x.endFeesYear);
      const endFeesMonth = endFeesYear.getMonth() + 1;
      const endFeesYearValue = endFeesYear.getFullYear();
      if (currentYear < endFeesYearValue) {
        setConfigId(x._id);
      } else if (
        currentYear === endFeesYearValue &&
        currentMonth <= endFeesMonth
      ) {
        setConfigId(x._id);
      }
    });
  };

  const handleFeesChange = (e: any) => {
    let value = e.target.value;
    value = value.replace(/[^0-9.]/g, "");
    value = value.replace(/(\..*?)\..*/g, "$1");
    value = value.replace(/^(\d*\.\d{0,2}).*/g, "$1");
    formikPay.setFieldValue("amount", value);
  };

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Payment</span>
      </div>
      <div className="text-end mb-3">
        <button
          className="btn btn-primary "
          onClick={() => {
            nav(
              {
                pathname: "/basicMemberFee",
              },
              { state: state }
            );
          }}
        >
          Add
        </button>
        <CancelBtn btnTxt="Back" path="/paymentMasterBasic" />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Photo</th>
            <th
              id="memberName"
              role="button"
              //   onClick={(e) => {
              //     sortData(ascDsc, setAscDsc, e, setSort, setFeesConfig, setPage);
              //   }}
            >
              Member Name
              <span className="px-2">
                {/* {sort === "memberName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )} */}
              </span>
            </th>
            <th id="fee" role="button">
              Fees
            </th>
            <th>Pending Fees</th>
            <th>Paid Fees</th>
            <th>Status</th>
            <th>Last Fee Paid Date</th>
            <th className="text-center">Action</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {feesConfig?.map((x: any) => {
              const paid = x?.pendingFees <= 0;
              // x.feesStatus === 0 &&
              // x.paymentStatus === 0 &&
              // x.isPaymentDoneForYear === false;
              return (
                <tr key={x._id}>
                  <td>
                    <i className="userImg">
                      <img
                        src={
                          x.result.photo
                            ? `${memImage}${x.result.photo}`
                            : `${memImage}Default.png`
                        }
                        alt="MemberPic"
                      />
                    </i>
                  </td>
                  <td>{x.result.memberName}</td>
                  <td>
                    {currencySymbol}
                    {x.fees}
                  </td>
                  <td>
                    {currencySymbol}
                    {x?.pendingFees}
                  </td>
                  <td>
                    {currencySymbol}
                    {x?.totalPaidFees}
                  </td>
                  <td>
                    {paid ? (
                      <span
                        role="button"
                        className={"px-4 py-2 badge badgeFontCol "}
                        onClick={() => {
                          if (paid) {
                            warnToast("This payment is done");
                          } else {
                            setPendingFees(paid);
                            // setShowPay(true);
                            setFees(x.fees);
                            setMemberId(x.memberId);
                          }
                        }}
                      >
                        Paid
                      </span>
                    ) : (
                      <span
                        role="button"
                        className={"px-4 py-2 badge badgeCol"}
                        onClick={() => {
                          if (paid) {
                            warnToast("This payment is done");
                          } else {
                            setPendingFees(paid);
                            setShowPay(true);
                            setFees(x.fees);
                            setMemberId(x.memberId);
                          }
                        }}
                      >
                        Pay
                      </span>
                    )}
                  </td>
                  <td>
                    {x?.memberPaidFees[0]?.createdAt
                      ? formatDate(x?.memberPaidFees[0]?.createdAt)
                      : ""}
                  </td>
                  {Number(x.totalPaidFees) === 0 ? (
                    <td className={"text-center pointer"}>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-${x._id}`}>Edit</Tooltip>
                        }
                      >
                        <Edit
                          id={x._id}
                          onClick={() => {
                            nav("/BasicMemberFeeEdit", {
                              state: x,
                            });
                          }}
                        />
                      </OverlayTrigger>
                    </td>
                  ) : (
                    <td className={"text-center"}>
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-${x._id}`}>Disable</Tooltip>
                        }
                      >
                        <Edit
                          className="disabled"
                          style={{ cursor: "not-allowed" }}
                        />
                      </OverlayTrigger>
                    </td>
                  )}
                  <td className={"text-center pointer"}>
                    <OverlayTrigger
                      placement="left"
                      overlay={
                        <Tooltip id={`tooltip-${x._id}`}>Details</Tooltip>
                      }
                    >
                      <Details
                        id={x._id}
                        style={{ width: "25px", height: "25px" }}
                        onClick={() => {
                          // if (x.payType === "online" && !pending) {
                          //   warnToast("You cannot update the online terms");
                          // } else if (pending) {
                          //   warnToast(
                          //     "Inorder to update.You must pay first !!"
                          //   );
                          // } else {
                          //   setMemberId(x.memberId);
                          //   setPendingFees(pending);
                          //   setShowPay(true);
                          //   setPaidFees(x?.result1.paidFees);
                          //   setFees(x.paidFees.split(".")[0]);
                          //   setPayType(x.payType);
                          //   if (x.payType === "cheque") {
                          //     setPayDetails({
                          //       refId: x.referenceId,
                          //       // Bank: x.bank,
                          //     });
                          //   } else {
                          //     setPayDetails({
                          //       refId: "",
                          //       // Bank: "",
                          //     });
                          //   }
                          // }
                          nav("/basicMemberFeeDetailsList", {
                            state: x,
                          });
                        }}
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
      <Modal
        show={showPay}
        onHide={handleClosePay}
        onShow={() => toggleCheckbox(payType)}
      >
        <Modal.Body>
          {pendingFees
            ? "Fill the details for payment."
            : "Fill the details you want to update."}
        </Modal.Body>
        <Modal.Footer
          style={{
            justifyContent: "center",
          }}
        >
          <form action="" onSubmit={formikPay.handleSubmit}>
            <label className="col-md-12 mb-3" htmlFor="amount">
              <input
                className={`form-control ${
                  formikPay.errors.amount && border ? "empty-form" : ""
                }`}
                id="amount"
                name="amount"
                type="number"
                onBlur={formikPay.handleBlur}
                onChange={handleFeesChange}
                value={formikPay.values.amount}
                placeholder="Enter Amount"
              />
              <span className="top-placeholder">Amount*</span>
            </label>

            {payType === "cheque" && (
              <label className="col-md-12 mb-3" htmlFor="chequeRefId">
                <input
                  className={`form-control ${
                    formikPay.errors.chequeRefId && border ? "empty-form" : ""
                  }`}
                  id="chequeRefId"
                  name="chequeRefId"
                  type="text"
                  onBlur={formikPay.handleBlur}
                  onChange={(e) => {
                    toastValidation(formikPay.errors);
                    formikPay.handleChange(e);
                  }}
                  value={formikPay.values.chequeRefId}
                  placeholder="Cheque Reference ID"
                />
                <span className="top-placeholder">Cheque Reference ID*</span>
              </label>
            )}
            <Form.Check
              type="radio"
              label="Cash"
              id="cash"
              className="mb-3"
              style={{
                cursor: "pointer",
              }}
              name="payType"
              onChange={(e: any) => {
                if (e.target.id === "cash") {
                  setPayDetails({
                    refId: "",
                    // Bank: "",
                  });
                }
                setPayType(e.target.id);
              }}
            />
            <Form.Check
              type="radio"
              label="Cheque"
              id="cheque"
              className="mb-3"
              style={{
                cursor: "pointer",
              }}
              name="payType"
              onChange={(e: any) => {
                setPayType(e.target.id);
              }}
            />
            <div className="text-center">
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  if (Object.keys(formikPay.errors).length !== 0) {
                    setBorder(true);
                    toastValidation(formikPay.errors);
                  } else {
                    setShowPay(false);
                  }
                  // window.location.reload();
                }}
              >
                Submit
              </Button>
              <Button variant="secondary" onClick={handleClosePay}>
                Close
              </Button>
            </div>
          </form>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BasicMemberFeeList;
