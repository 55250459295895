import React, { memo, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLoading } from "Context/LoadingContext";
import toastValidation, { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";

type Props = {};

const InsuranceAddForm = (props: Props) => {
  const { setLoading } = useLoading();
  const nav = useNavigate();
  const [param] = useSearchParams();

  const { state } = useLocation();

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      name: "",
      insuranceDate: "",
      validUptoDate: "",
      insDoc: "",
      vehicleNo: "",
      amount: "",
      insuranceNo: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Insurance Company Name Too Short!")
        .max(30, "Insurance Company Name Too Long!")
        .required("Insurance Company Name is Required"),
      insuranceNo: Yup.string()
        .min(1, "Insurance Number is Too Short!")
        .max(30, "Insurance Number Too Long!")
        .required("Insurance Number is Required"),
      insuranceDate: Yup.string().required(
        "Insurance Valid from Date  is Required"
      ),
      validUptoDate: Yup.string().required(
        "Insurance Valid Upto Date is Required"
      ),
      // document: Yup.string().required("Document is Required"),
      amount: Yup.string()
        .matches(/^[0-9]\d*$/, "Enter a valid Amount")
        .required("Amount is Required"),
      insDoc: Yup.mixed()
        .test("fileSize", "PUC Document size is too high", (value) => {
          if (!value) return true; // Skip the test if no file is selected
          return value && value.size <= 209715200; // 200MB in bytes
        })
        .test(
          "fileType",
          "Please Enter PUC Document in jpg,jpeg,png,pdf,docx extension",
          (value) => {
            if (!value) return true; // Skip the test if no file is selected
            return value && /\.(jpg|jpeg|png|pdf|docx?)$/i.test(value.name); // Check if the file has a valid extension
          }
        ),
    }),
    onSubmit: async ({
      name,
      insuranceDate,
      validUptoDate,
      insDoc,
      amount,
      insuranceNo,
      vehicleNo,
    }) => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("model", state.model);
        formData.append("amount", String(amount));
        formData.append("insuranceNo", insuranceNo);
        formData.append("vehicleNo", state._id);
        formData.append("insuranceDate", insuranceDate);
        formData.append("validUptoDate", validUptoDate);
        formData.append("insDoc", insDoc);
        formData.append("description", "");
        formData.append("note", "");
        formData.append("details", "");
        const res = await axiosInstance.post(
          "/master/addVehicleInsurance",
          formData
        );
        const responseMessage = res.data.message;
        if (responseMessage === "Ok") {
          successToast();
          setLoading(false);
          if (Number(param.get("fromEdit")) !== 1) {
            nav("../..");
          } else {
            const item = state;
            nav(
              {
                pathname: "/vehicleRegistration/editForm",
                search: "?index=2",
              },
              { state: item }
            );
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name && formik.submitCount ? "empty-form" : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Insurance Company Name"
              />

              <span className="top-placeholder">Insurance Company Name*</span>
            </label>

            <label className="col-md-6 mb-3" htmlFor="insuranceNo">
              <input
                className={`form-control ${
                  formik.errors.insuranceNo && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="insuranceNo"
                name="insuranceNo"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.insuranceNo}
                placeholder="Insurance Number"
              />

              <span className="top-placeholder">Insurance Number*</span>
            </label>

            <label className="col-md-6 mb-3" htmlFor="insuranceDate">
              <input
                className={`form-control session-date ${
                  formik.errors.insuranceDate && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="insuranceDate"
                name="insuranceDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.insuranceDate}
                placeholder="Insurance Date"
                // onFocus={(e) => (e.target.type = "date")}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              {/* {formik.values.insuranceDate && ( */}
              <span className="top-placeholder">Insurance Date*</span>
              {/* )} */}
            </label>
            <label className="col-md-6 mb-3" htmlFor="validUptoDate">
              <input
                className={`form-control session-date ${
                  formik.errors.validUptoDate && formik.submitCount
                    ? "empty-form"
                    : ""
                }`}
                id="validUptoDate"
                name="validUptoDate"
                type="date"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.validUptoDate}
                placeholder="Valid Upto Date"
                // onFocus={(e) => (e.target.type = "date")}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              {/* {formik.values.validUptoDate && ( */}
              <span className="top-placeholder">Valid Up To*</span>
              {/* )} */}
            </label>
            <label className="col-md-6 mb-3" htmlFor="amount">
              <input
                className={`form-control ${
                  formik.errors.amount && formik.submitCount ? "empty-form" : ""
                }`}
                id="amount"
                name="amount"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.amount}
                placeholder="Amount"
              />

              <span className="top-placeholder">Amount*</span>
            </label>

            <label className="col-md-6 mb-3" htmlFor="insDoc">
              <input
                className="form-control insurance form-control-lg"
                id="insDoc"
                name="insDoc"
                type="file"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  const file =
                    event.currentTarget.files && event.currentTarget.files[0];
                  formik.setFieldValue("insDoc", file || "");
                }}
                placeholder="Enter document"
              />
              {/* <span className="top-placeholder">Document</span> */}
            </label>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn
              errors={formik.errors}
              clickFunc={() => {
                toastValidation(formik.errors);
              }}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                if (Number(param.get("fromEdit")) !== 1) {
                  nav("../..");
                } else {
                  const item = state;
                  nav(
                    {
                      pathname: "/vehicleRegistration/editForm",
                      search: "?index=2",
                    },
                    { state: item }
                  );
                }
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(InsuranceAddForm);
