import React, { useState, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PaymentList from "../PaymentManagement/list";
import { useSearchParams } from "react-router-dom";

type Props = {};

const PaymentTabs = (props: Props) => {
  const [index, setIndex] = useState(0);
  const [param] = useSearchParams();

  const printRef = useRef<HTMLButtonElement | null>(null);
  const pdfRef = useRef<HTMLButtonElement | null>(null);
  const excelRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Payment Management</span>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary mb-4"
          onClick={() => {
            pdfRef?.current?.click();
          }}
        >
          Export to PDF
        </button>
        <button
          className="btn btn-primary mb-4"
          onClick={() => {
            excelRef?.current?.click();
          }}
        >
          Export to Excel
        </button>
        <button
          className="btn btn-primary mb-4"
          onClick={() => {
            printRef?.current?.click();
          }}
        >
          Print
        </button>
      </div>
      <Tabs
        selectedIndex={param.get("index") ? Number(param.get("index")) : index}
        onSelect={(e) => {
          setIndex(e);
        }}
      >
        <TabList>
          <Tab>Due Fees</Tab>
          <Tab>Pending Fees</Tab>
          <Tab>Fees</Tab>
        </TabList>

        <TabPanel>
          <PaymentList
            type={1}
            printRef={printRef}
            pdfRef={pdfRef}
            excelRef={excelRef}
          />
        </TabPanel>
        <TabPanel>
          <PaymentList
            type={2}
            printRef={printRef}
            pdfRef={pdfRef}
            excelRef={excelRef}
          />
        </TabPanel>
        <TabPanel>
          <PaymentList
            type={3}
            printRef={printRef}
            pdfRef={pdfRef}
            excelRef={excelRef}
          />
        </TabPanel>
      </Tabs>
    </>
  );
};

export default PaymentTabs;
