import { useState, memo } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

type Props = {};

type ContextType = {
  setRFDICardId: (RFDICardId: string) => void;
  RFDICardId: string;
};

const RFDICard: React.FC<{}> = (props: Props) => {
  const [RFDICardId, setRFDICardId] = useState<string>("");

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>RFID Card</span>
      </div>
      <Outlet context={{ RFDICardId, setRFDICardId }} />
    </>
  );
};

export default memo(RFDICard);
export function useRFDICardId() {
  return useOutletContext<ContextType>();
}
