import React, { useState, memo } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

type Props = {};
type ContextType = {
  setAdminPlanId: (adminPlanId: string) => void;
  adminPlanId: string;
};

const AdminPlan: React.FC<{}> = (props: Props) => {
  const [adminPlanId, setAdminPlanId] = useState<string>("");
  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Plan</span>
      </div>
      <Outlet context={{ adminPlanId, setAdminPlanId }} />
    </>
  );
};

export default memo(AdminPlan);

export function useAdminPlanId() {
  return useOutletContext<ContextType>();
}
