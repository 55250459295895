import React, { memo } from "react";
import { AdminRequestDataType } from "types/adminRequestTypes";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { convertTo12HourFormat, dateFormatForAdmin } from "constants/Constants";

type Props = {
  x: AdminRequestDataType;
};

const AdminRequestListItem = ({ x }: Props): JSX.Element => {
  const nav = useNavigate();
  const EditToolTip = <Tooltip id={`tooltip-${x._id}`}>Edit</Tooltip>;
  return (
    <tr key={x._id}>
      <td>{x.name}</td>
      <td>{x.email}</td>
      <td>{x.contactNo}</td>
      <td>{dateFormatForAdmin(x.demoDate)}</td>
      <td>{convertTo12HourFormat(x.Time)}</td>
      <td>
        {(() => {
          switch (x.status) {
            case "1":
              return "New Demo";
            case "2":
              return "Reschedule";
            case "3":
              return "Done";
            case "4":
              return "Not Attend";
            case "5":
              return "Cancel";
            default:
              return "";
          }
        })()}
      </td>
      <td>
        {x.latestCreatedAt === null
          ? "__"
          : dateFormatForAdmin(x.latestCreatedAt)}
      </td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={EditToolTip}>
          <Edit
            id={x._id}
            className="pointer"
            onClick={() => {
              nav("requestEditForm", {
                state: x,
              });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(AdminRequestListItem);
