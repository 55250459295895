import React, { memo } from "react";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReqForCabDataType } from "types/reqForCabTypes";

type Props = {
  x: ReqForCabDataType;
};

const ReqForCabList = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td>{x.memberName}</td>
      <td>{x.pickUpTime}</td>
      <td>{x.dropLocation}</td>
      <td>{x.status}</td>
      <td>{x.reqTime}</td>
      <td className="text-center">
        <Edit />
      </td>
    </tr>
  );
};

export default memo(ReqForCabList);
