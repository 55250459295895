import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect, memo } from "react";
import UserMngDataType from "../../../types/userManTypes";
import UserMngListItem from "./usermanListItem";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import AddSearch from "components/AddSearchComponent";

type Props = {};

const UserMngList = (props: Props): JSX.Element => {
  const [userMng, setUserMng] = useState<UserMngDataType[]>([]);
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("name");
  const [searchCount, setSearchCount] = useState(0);
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const loadUser = useCallback(
    async (pageNum: number, sortParam: string, searchKeyword: string) => {
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getUserDetails?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
        );
        const newUser = res.data.users[0].paginatedResults;
        if (pageNum === 1) {
          setUserMng(newUser);
        } else {
          setUserMng((prevUser) => [...prevUser, ...newUser]);
        }
        setSearchCount(res.data.users[0].totalCount[0].count);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [userMng]
  );
  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadUser(1, sort, search); // Trigger API call with updated search keyword
  };
  useEffect(() => {
    loadUser(page, sort, searchQuery);
  }, [page, sort, ascDsc, searchQuery]);

  window.onscroll = () => {
    infiniteScroll(userMng, setPage, page, searchCount);
  };
  return (
    <>
      <AddSearch
        addLink="/usermanagement/addUserMng"
        handleSearch={handleSearch}
        search={search}
        setSearch={setSearch}
        placeholder="Name or Role"
      />
      <table className="table">
        <thead>
          <tr>
            <th
              id="name"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setUserMng, setPage);
              }}
            >
              Name
              <span className="px-2">
                {sort === "name" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="email"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setUserMng, setPage);
              }}
            >
              Email
              <span className="px-2">
                {sort === "email" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="contactNo"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setUserMng, setPage);
              }}
            >
              Contact Number
              <span className="px-2">
                {sort === "contactNo" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="roleCode"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setUserMng, setPage);
              }}
            >
              Role
              <span className="px-2">
                {sort === "roleCode" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {userMng.map((x) => {
            return <UserMngListItem x={x} key={x._id} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default memo(UserMngList);
