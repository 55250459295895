import React, { useEffect, useCallback, useState, memo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "axiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryDataType, { CategoryDDL } from "../../../types/categoryTypes";
import {
  DepartmentDDLType,
  DepartmentMasterDataType,
} from "../../../types/departmentMasterType";
import { TypeMasterDataType } from "../../../types/typeMasterTypes";
import { useLoading } from "Context/LoadingContext";
import { successToast } from "constants/toastConfig";
import SubmitBtn from "components/Buttons/Submit";
import CancelBtn from "components/Buttons/Cancel";

type Props = {};

const TypeMasterEditForm = (props: Props) => {
  const nav = useNavigate();
  const { setLoading } = useLoading();
  const location = useLocation();
  const [categories, setCategories] = useState<CategoryDDL[]>([]);
  const [departments, setDepartments] = useState<DepartmentDDLType[]>([]);
  const [categoryId, setCategoryId] = useState<string | undefined>("");
  const [departmentId, setDepartmentId] = useState<string | undefined>("");
  const typeId = location.state;
  // const { typeId } = useTypeId();
  const [types, setTypes] = useState<TypeMasterDataType>();

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const department = res.data.Departments;
      setLoading(false);
      return department;
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadCategory = useCallback(async (depId: string | undefined) => {
    try {
      setLoading(true);
      const resCat = await axiosInstance.get(
        `/dropDown/getCategory?depId=${depId}`
      );
      setLoading(false);
      return resCat.data.categories;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const dropdown = useCallback(async () => {
    try {
      const dep = await loadDepartments();
      console.log(departments);
      setLoading(true);
      const resType = await axiosInstance.get(`/master/getOneType/${typeId}`);
      const typeDepId = resType.data.typeMasters.departmentId;
      const typeCatId = resType.data.typeMasters.categoryId;
      setTypes(resType.data.typeMasters);

      const newDepList = updateList(dep, typeDepId);
      setDepartmentId(typeDepId);
      setDepartments(newDepList);

      const catList = await loadCategory(departmentId);
      const newCatList = updateList(catList, typeCatId);
      setCategories(newCatList);
      setCategoryId(newCatList[0]._id);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [typeId]);

  const updateList = (list: any, id: string | undefined) => {
    const newList = [...list];
    const record = newList?.find(
      (x: DepartmentMasterDataType | CategoryDataType) => x?._id === id
    );
    const index = newList.indexOf(
      record as DepartmentMasterDataType | CategoryDataType
    );
    newList.splice(index, 1);
    newList.splice(0, 0, record as DepartmentMasterDataType | CategoryDataType);
    return newList;
  };

  useEffect(() => {
    dropdown();
  }, [dropdown]);

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      name: types?.name,
      code: types?.code,
      description: types?.description,
      active: types?.isActive,
      note: "",
      details: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(1, "Sub Category Name is too Short!")
        .max(30, "Sub Category Name is too Long!")
        .required("Sub Category Name is required"),
      code: Yup.string().max(10, "Sub Category Code is too Long!"),
      description: Yup.string().max(
        300,
        "Sub Category Description is too Long!"
      ),
    }),
    onSubmit: async ({ name, code, description, active }) => {
      try {
        setLoading(true);
        const formData = {
          categoryId: categoryId,
          departmentId: departmentId,
          name: name,
          code: code,
          description: description,
          isActive: active,
          note: "",
          details: "",
        };

        const res = await axiosInstance.put(
          `/master/updateType/${typeId}`,
          formData
        );

        const responseMessage = res.data;
        if (responseMessage === "Ok") {
          successToast();
          nav("/typeMaster");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="formBg">
        <form onSubmit={formik.handleSubmit}>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="department">
              <span className="top-placeholder">Department*</span>
              <select
                className="form-control"
                id="department"
                onChange={async (e) => {
                  setDepartmentId(e.target.value);
                  setCategoryId("");
                  setCategories([]);
                  const cat = await loadCategory(e.target.value);
                  setCategories(cat);
                }}
              >
                {departments.map(({ _id, departmentName }) => {
                  return (
                    <option key={_id} value={_id}>
                      {departmentName}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className="col-md-6 mb-3" htmlFor="category">
              <span className="top-placeholder">Category*</span>

              <select
                className="form-control"
                id="category"
                onChange={(e) => {
                  setCategoryId(e.target.value);
                }}
              >
                {categoryId === "" && <option value="">Select Category</option>}
                {categories
                  ?.filter((item) => {
                    return item.departmentId === departmentId;
                  })
                  ?.map(({ _id, name }) => {
                    return (
                      <option key={_id} value={_id}>
                        {name}
                      </option>
                    );
                  })}
              </select>
            </label>
          </div>
          <div className="row align-items-center">
            <label className="col-md-6 mb-3" htmlFor="name">
              <input
                className={`form-control ${
                  formik.errors.name ? "empty-form" : ""
                }`}
                id="name"
                name="name"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Sub Category Name"
              />

              <span className="top-placeholder">Sub Category Name*</span>
            </label>
            <label className="col-md-6 mb-3" htmlFor="code">
              <input
                className="form-control"
                id="code"
                name="code"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
                placeholder="Sub Category Code"
              />

              <span className="top-placeholder">Sub Category Code</span>
            </label>
            <label className="col-md-12 mb-3" htmlFor="description">
              <textarea
                className="form-control"
                id="description"
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
                placeholder="Sub Category Description"
              ></textarea>

              <span className="top-placeholder">Sub Category Description</span>
            </label>
            <div className="col-md-3 mb-3">
              <label className="custCheck">
                Active
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.active}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 mb-3 text-center">
            <SubmitBtn errors={formik.errors} />
            {(location.pathname === "/typeMaster/typeMasterForm" ||
              location.pathname === "/typeMaster/typeMasterEditForm") && (
              <CancelBtn path="/typeMaster" />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default memo(TypeMasterEditForm);
