import React, { memo } from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReactComponent as View } from "assets/images/view-16.svg";
import { ReqForRenewPlan } from "types/renewPlanTypes";
import { useNavigate } from "react-router-dom";
import { formatDate } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  item: ReqForRenewPlan;
};

const RenewPlanRequestsListItem = ({ item }: Props) => {
  const nav = useNavigate();
  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  const ViewToolTip = <Tooltip id={`tooltip-${item._id}`}>View</Tooltip>;
  return (
    <tr key={item._id}>
      <td>{formatDate(item.createdAt)}</td>
      <td>{item.organizerName}</td>
      <td>{item.planType}</td>
      <td>{item.planName}</td>
      <td>{item.amount}</td>
      <td>{item.paymentCheck ? "Yes" : "No"}</td>
      <td>{item.isMultiShift ? "Yes" : "No"}</td>
      <td>{item.rfidCardCheck ? "Yes" : "No"}</td>
      <td className="text-center">{item.isVerified === true ? "Yes" : "No"}</td>
      <td className="text-center">
        {item.isActive === true ? <Eye /> : <EyeOff />}
      </td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Edit
            role="button"
            id={item._id}
            onClick={() => {
              nav("/renewPlanRequest/planrenew", { state: item });
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(RenewPlanRequestsListItem);
