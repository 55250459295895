import React, { memo } from "react";
import { formatDate, formatTime } from "constants/Constants";
import { sosDataTypes } from "types/sosTypes";

type Props = {
  x: sosDataTypes;
};

const SosListitem = ({ x }: Props): JSX.Element => {
  return (
    <tr key={x._id}>
      <td className="pb-4">{x.memberName}</td>
      <td className="pb-4">{x.memberUsersName[0]?.name}</td>
      <td className="pb-4">{x.memberDriverName}</td>
      <td className="pb-4">{x.memberRouteName}</td>
      <td className="pb-4">{formatDate(x.createdAt)}</td>
      <td className="pb-4">{formatTime(x.createdAt)}</td>
    </tr>
  );
};

export default memo(SosListitem);
