import React, { memo, useCallback, useEffect, useState } from 'react';
import { ClientDetailsDataType } from '../../../types/clientOrgDetails';
import { useLocation } from 'react-router-dom';
import axiosInstance from 'axiosInstance';
import { useLoading } from "Context/LoadingContext";

type Props = {};

const ClientDetails: React.FC<{}> = (props: Props) => {
  const { state } = useLocation();
  const [clients, setClients] = useState<ClientDetailsDataType[]>([]);
  const { setLoading } = useLoading();

  const loadClients = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`/master/getorgDetails/` + state.organizationId);
      // console.log('listCLNT', res.data);
      const clientList: ClientDetailsDataType[] = [...res.data.orgDetails];
      setClients(clientList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [state.organizationId]);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  return (
    <>
      <div className="pageTitle justify-content-between align-items-center d-flex">
        <span>Client Name: {state.name}</span>
      </div>
      {clients.length > 0 && (
        <div className="row">
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].departmentCount}</h3>
                <br />
                <h3 className="card-text text-center">Department</h3>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].categorymasterCount}</h3>
                <br />
                <h3 className="card-text text-center">Category</h3>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].typesCount}</h3>
                <br />
                <h3 className="card-text text-center">Type</h3>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].locationCount}</h3>
                <br />
                <h3 className="card-text text-center">Location</h3>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].shifttypeCount}</h3>
                <br />
                <h3 className="card-text text-center">Shift Type</h3>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].memberCount}</h3>
                <br />
                <h3 className="card-text text-center">Member</h3>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].vehicleCount}</h3>
                <br />
                <h3 className="card-text text-center">Vehicle</h3>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].driverCount}</h3>
                <br />
                <h3 className="card-text text-center">Driver</h3>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].routeCount}</h3>
                <br />
                <h3 className="card-text text-center">Route</h3>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mb-3">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">{clients[0].cardCount}</h3>
                <br />
                <h3 className="card-text text-center">Card</h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(ClientDetails);
