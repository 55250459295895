import { memo, useCallback, useEffect, useState } from "react";
import ListItem from "./ListItem";
import axiosInstance from "axiosInstance";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { useConfig } from "Context/ConfigContext";
import { useNotFound } from "Context/NotFound";
import NotFound from "components/NotFound";

export type BasicVehicleMaster = {
  _id: string;
  organizerId: string;
  VehicleType: string;
  LPNumber: string;
  isActive: boolean;
  isDelete: boolean;
};

type Props = {};
const BasicVehicleList = (props: Props): JSX.Element => {
  const { setLoading } = useLoading();
  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("VehicleType");
  const [searchCount, setSearchCount] = useState(0);
  const [listData, setListData] = useState<BasicVehicleMaster[]>([]);
  const { notfound, setNotFound } = useNotFound();

  const LoadData = useCallback(
    async (pageNum: number, sortParam: string) => {
      setLoading(true);
      try {
        setLoading(true);
        const res = await axiosInstance.get(
          `/master/getAllBasicVehicleMasters?page=${pageNum}&sort=${sortParam}`
        );
        // console.log(res);
        const vehicleList: BasicVehicleMaster[] = [
          ...res.data.allBasicVehicle[0].paginatedResults,
        ];
        if (vehicleList.length === 0) {
          setNotFound(true);
          setListData([]);
          setSearchCount(0);
        }
        if (vehicleList.length > 0) {
          setSearchCount(res.data.allBasicVehicle[0].totalCount[0].count);
          if (pageNum === 1) {
            setListData(vehicleList);
          } else {
            setListData((prevList) => [...prevList, ...vehicleList]);
          }
          setNotFound(false);
        }
        setLoading(false);
      } catch (error) {
        setNotFound(true);
        console.log(error);
        setLoading(false);
      }
    },
    [listData]
  );

  useEffect(() => {
    LoadData(page, sort);
  }, [page, sort, ascDsc]);

  window.onscroll = () => {
    infiniteScroll(listData, setPage, page, searchCount);
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th
              id="VehicleType"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Vehicle Type
              <span className="px-2">
                {sort === "VehicleType" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="LPNumber"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Licence Plate Number
              <span className="px-2">
                {sort === "LPNumber" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>

            <th
              className="text-center"
              id="isActive"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setListData, setPage);
              }}
            >
              Active
            </th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {!notfound && (
          <tbody>
            {listData?.map((item: BasicVehicleMaster) => {
              return <ListItem item={item} key={item._id} />;
            })}
          </tbody>
        )}
      </table>
      {notfound && <NotFound />}
    </>
  );
};

export default memo(BasicVehicleList);
