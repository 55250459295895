import React, { memo } from "react";
import { ReactComponent as Eye } from "assets/images/eye.svg";
import { ReactComponent as EyeOff } from "assets/images/eye-off.svg";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import ShiftDataType from "../../../types/shiftType.types";
import { Link } from "react-router-dom";
import { useShiftType } from ".";
import "../../../assets/styles/input.css";
import { convertTo12HourFormat } from "constants/Constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = { item: ShiftDataType };

const ShiftTypeListItems = ({ item }: Props) => {
  const { setShiftId } = useShiftType();
  const tooltip = <Tooltip id={`tooltip-${item._id}`}>Edit </Tooltip>;
  return (
    <tr key={item._id}>
      <td>{item.name}</td>
      <td>{convertTo12HourFormat(item.inTime)}</td>
      <td>{convertTo12HourFormat(item.outTime)}</td>
      <td>{item.code}</td>
      <td>{item.description}</td>
      <td className="text-center">{item.isActive ? <Eye /> : <EyeOff />}</td>
      <td className="text-center">
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Link to="/shiftTypeMaster/editForm">
            <Edit
              id={item._id}
              onClick={(e) => {
                setShiftId(e.currentTarget.id);
              }}
            />
          </Link>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default memo(ShiftTypeListItems);
