import axiosInstance from "axiosInstance";
import React, { useCallback, useState, useEffect } from "react";
// import { MemberDataType } from 'types/MemberMaster.types'
import CategoryDataType from "../../../types/categoryTypes";
// import MemberListItems from './MemberListItems'
import { TypeMasterDataType } from "../../../types/typeMasterTypes";
import { DepartmentDDLType } from "types/departmentMasterType";
import { infiniteScroll, sortData } from "constants/Constants";
import { ReactComponent as UpArr } from "assets/images/upArrow.svg";
import { ReactComponent as DownArr } from "assets/images/downArrow.svg";
import { useLoading } from "Context/LoadingContext";
import { AreaDDLType } from "types/areaMasterTypes";
import { AreaDivisionDDL } from "types/areaDivisionTypes";
import { MemberAssignmentListType } from "types/memberAssignment";
import AssignmentListItems from "./AssignmentListItems";
type Props = {};

const AssignmentList = (props: Props) => {
  const { setLoading } = useLoading();

  const [page, setPage] = useState<number>(1);
  const [ascDsc, setAscDsc] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("memberName");
  const [searchCount, setSearchCount] = useState(0);

  const [department, setDepartment] = useState<DepartmentDDLType[]>([]);
  const [depId, setDepId] = useState("");
  const [category, setCategory] = useState<CategoryDataType[]>([]);
  const [catId, setCatId] = useState("");
  const [type, setType] = useState<TypeMasterDataType[]>([]);
  const [typeId, setTypeId] = useState("");

  const [pin, setPin] = useState("");
  const [area, setArea] = useState<AreaDDLType[]>([]);
  const [division, setDivision] = useState<AreaDivisionDDL[]>([]);
  const [divId, setDivId] = useState("");
  const [search, setSearch] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [memAssign, setMemAssign] = useState<MemberAssignmentListType[]>([]);

  const loadDepartments = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getDepartment`);
      const departmentList = res.data.Departments;
      setDepartment(departmentList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadCategories = useCallback(async (depId: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getCategory?depId=${depId}`
      );
      const categories = res.data.categories;
      setCategory(categories);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadTypes = useCallback(async (catId: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getType?catId=${catId}`);
      const typeList = res.data.types;
      setType(typeList);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadAreas = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(`/dropDown/getArea`);
      setArea(res.data.Areas);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadAreaCode = useCallback(async (pinCode: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `/dropDown/getAreaDivision?pinCode=${pinCode}`
      );
      setDivision(res.data.division);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const loadMemberAssignmentList = useCallback(
    async (
      pageNum: number,
      sortParam: string,
      pinCode: string,
      areaCode: string,
      departmentId: string,
      categoryId: string,
      typId: string,
      searchKeyword: string
    ) => {
      try {
        setLoading(true);
        if (pinCode === "" && departmentId === "") {
          const res = await axiosInstance.get(
            `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&search=${searchKeyword}`
          );
          const newmemberAssigned: MemberAssignmentListType[] = [
            ...res.data.AssignedMembers[0].paginatedResults,
          ];
          if (newmemberAssigned.length === 0) {
            setMemAssign([]);
            setSearchCount(0);
          }
          if (newmemberAssigned.length > 0) {
            setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
            if (pageNum === 1) {
              setMemAssign(newmemberAssigned);
            } else {
              setMemAssign((prevList) => [...prevList, ...newmemberAssigned]);
            }
          }
        } else if (pinCode !== "" && departmentId === "") {
          if (areaCode !== "") {
            const res = await axiosInstance.get(
              `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&pinCode=${pinCode}&divisionId=${areaCode}&search=${searchKeyword}`
            );
            const newmemberAssigned: MemberAssignmentListType[] = [
              ...res.data.AssignedMembers[0].paginatedResults,
            ];
            if (newmemberAssigned.length === 0) {
              setMemAssign([]);
              setSearchCount(0);
            }
            if (newmemberAssigned.length > 0) {
              setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
              if (pageNum === 1) {
                setMemAssign(newmemberAssigned);
              } else {
                setMemAssign((prevList) => [...prevList, ...newmemberAssigned]);
              }
            }
          } else {
            const res = await axiosInstance.get(
              `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&pinCode=${pinCode}&search=${searchKeyword}`
            );
            const newmemberAssigned: MemberAssignmentListType[] = [
              ...res.data.AssignedMembers[0].paginatedResults,
            ];
            if (newmemberAssigned.length === 0) {
              setMemAssign([]);
              setSearchCount(0);
            }
            if (newmemberAssigned.length > 0) {
              setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
              if (pageNum === 1) {
                setMemAssign(newmemberAssigned);
              } else {
                setMemAssign((prevList) => [...prevList, ...newmemberAssigned]);
              }
            }
          }
        } else if (pinCode === "" && departmentId !== "") {
          if (categoryId !== "" && typId === "") {
            const res = await axiosInstance.get(
              `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&departmentId=${departmentId}&categoryId=${categoryId}&search=${searchKeyword}`
            );
            const newmemberAssigned: MemberAssignmentListType[] = [
              ...res.data.AssignedMembers[0].paginatedResults,
            ];
            if (newmemberAssigned.length === 0) {
              setMemAssign([]);
              setSearchCount(0);
            }
            if (newmemberAssigned.length > 0) {
              setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
              if (pageNum === 1) {
                setMemAssign(newmemberAssigned);
              } else {
                setMemAssign((prevList) => [...prevList, ...newmemberAssigned]);
              }
            }
          } else if (categoryId !== "" && typId !== "") {
            const res = await axiosInstance.get(
              `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&departmentId=${departmentId}&categoryId=${categoryId}&typeId=${typId}&search=${searchKeyword}`
            );
            const newmemberAssigned: MemberAssignmentListType[] = [
              ...res.data.AssignedMembers[0].paginatedResults,
            ];
            if (newmemberAssigned.length === 0) {
              setMemAssign([]);
              setSearchCount(0);
            }
            if (newmemberAssigned.length > 0) {
              setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
              if (pageNum === 1) {
                setMemAssign(newmemberAssigned);
              } else {
                setMemAssign((prevList) => [...prevList, ...newmemberAssigned]);
              }
            }
          } else {
            const res = await axiosInstance.get(
              `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&departmentId=${departmentId}&search=${searchKeyword}`
            );
            const newmemberAssigned: MemberAssignmentListType[] = [
              ...res.data.AssignedMembers[0].paginatedResults,
            ];
            if (newmemberAssigned.length === 0) {
              setMemAssign([]);
              setSearchCount(0);
            }
            if (newmemberAssigned.length > 0) {
              setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
              if (pageNum === 1) {
                setMemAssign(newmemberAssigned);
              } else {
                setMemAssign((prevList) => [...prevList, ...newmemberAssigned]);
              }
            }
          }
        } else if (pinCode !== "" && departmentId !== "") {
          if (areaCode === "") {
            if (categoryId !== "" && typId === "") {
              const res = await axiosInstance.get(
                `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&pinCode=${pinCode}&departmentId=${departmentId}&categoryId=${categoryId}&search=${searchKeyword}`
              );
              const newmemberAssigned: MemberAssignmentListType[] = [
                ...res.data.AssignedMembers[0].paginatedResults,
              ];
              if (newmemberAssigned.length === 0) {
                setMemAssign([]);
                setSearchCount(0);
              }
              if (newmemberAssigned.length > 0) {
                setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
                if (pageNum === 1) {
                  setMemAssign(newmemberAssigned);
                } else {
                  setMemAssign((prevList) => [
                    ...prevList,
                    ...newmemberAssigned,
                  ]);
                }
              }
            } else if (categoryId !== "" && typId !== "") {
              const res = await axiosInstance.get(
                `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&pinCode=${pinCode}&departmentId=${departmentId}&categoryId=${categoryId}&typeId=${typId}&search=${searchKeyword}`
              );
              const newmemberAssigned: MemberAssignmentListType[] = [
                ...res.data.AssignedMembers[0].paginatedResults,
              ];
              if (newmemberAssigned.length === 0) {
                setMemAssign([]);
                setSearchCount(0);
              }
              if (newmemberAssigned.length > 0) {
                setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
                if (pageNum === 1) {
                  setMemAssign(newmemberAssigned);
                } else {
                  setMemAssign((prevList) => [
                    ...prevList,
                    ...newmemberAssigned,
                  ]);
                }
              }
            }
          } else if (areaCode !== "") {
            if (categoryId !== "" && typId === "") {
              const res = await axiosInstance.get(
                `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&pinCode=${pinCode}&divisionId=${areaCode}&departmentId=${departmentId}&categoryId=${categoryId}&search=${searchKeyword}`
              );
              const newmemberAssigned: MemberAssignmentListType[] = [
                ...res.data.AssignedMembers[0].paginatedResults,
              ];
              if (newmemberAssigned.length === 0) {
                setMemAssign([]);
                setSearchCount(0);
              }
              if (newmemberAssigned.length > 0) {
                setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
                if (pageNum === 1) {
                  setMemAssign(newmemberAssigned);
                } else {
                  setMemAssign((prevList) => [
                    ...prevList,
                    ...newmemberAssigned,
                  ]);
                }
              }
            } else if (categoryId !== "" && typId !== "") {
              const res = await axiosInstance.get(
                `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&pinCode=${pinCode}&divisionId=${areaCode}&departmentId=${departmentId}&categoryId=${categoryId}&typeId=${typId}&search=${searchKeyword}`
              );
              const newmemberAssigned: MemberAssignmentListType[] = [
                ...res.data.AssignedMembers[0].paginatedResults,
              ];
              if (newmemberAssigned.length === 0) {
                setMemAssign([]);
                setSearchCount(0);
              }
              if (newmemberAssigned.length > 0) {
                setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
                if (pageNum === 1) {
                  setMemAssign(newmemberAssigned);
                } else {
                  setMemAssign((prevList) => [
                    ...prevList,
                    ...newmemberAssigned,
                  ]);
                }
              }
            } else {
              const res = await axiosInstance.get(
                `/master/getAllMemberAssignment?page=${pageNum}&sort=${sortParam}&pinCode=${pinCode}&divisionId=${areaCode}&departmentId=${departmentId}&search=${searchKeyword}`
              );
              const newmemberAssigned: MemberAssignmentListType[] = [
                ...res.data.AssignedMembers[0].paginatedResults,
              ];
              if (newmemberAssigned.length === 0) {
                setMemAssign([]);
                setSearchCount(0);
              }
              if (newmemberAssigned.length > 0) {
                setSearchCount(res.data.AssignedMembers[0].totalCount[0].count);
                if (pageNum === 1) {
                  setMemAssign(newmemberAssigned);
                } else {
                  setMemAssign((prevList) => [
                    ...prevList,
                    ...newmemberAssigned,
                  ]);
                }
              }
            }
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [memAssign]
  );

  useEffect(() => {
    loadAreas();
    loadDepartments();
  }, [loadAreas, loadDepartments]);

  useEffect(() => {
    loadMemberAssignmentList(
      page,
      sort,
      pin,
      divId,
      depId,
      catId,
      typeId,
      searchQuery
    );
  }, [page, sort, pin, divId, depId, catId, typeId, searchQuery]);
  const handleSearch = () => {
    setSearchQuery(search);
    setPage(1); // Reset page count to 1
    loadMemberAssignmentList(
      1,
      sort,
      pin,
      divId,
      depId,
      catId,
      typeId,
      searchQuery
    ); // Trigger API call with updated search keyword
  };
  window.onscroll = () => {
    infiniteScroll(memAssign, setPage, page, searchCount);
  };

  return (
    <>
      <label className="searchBar">
        <input
          className="searchInput"
          type="text"
          placeholder="Member Name or Member ID"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleSearch}>
          Search
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            setSearch("");
            setSearchQuery("");
          }}
        >
          Clear
        </button>
      </label>
      <div className="formBg mb-3">
        <div className="mb-2 row align-items-center">
          <label htmlFor="" className="col-md-6 mb-3">
            <select
              className="form-control mt-3"
              id="department"
              onChange={(e) => {
                setDepId(e.target.value);
                loadCategories(e.target.value);
                setPage(1);
                setMemAssign([]);
                setCategory([]);
                setCatId("");
                setType([]);
                setTypeId("");
              }}
            >
              <option value="">Select Department</option>
              {department.map(({ _id, departmentName }) => {
                return (
                  <option key={_id} value={_id}>
                    {departmentName}
                  </option>
                );
              })}
            </select>
          </label>
          <label htmlFor="" className="col-md-6 mb-3">
            <select
              className="form-control mt-3"
              id="department"
              onChange={(e) => {
                setCatId(e.target.value);
                loadTypes(e.target.value);
                setPage(1);
                setMemAssign([]);
                setType([]);
                setTypeId("");
              }}
            >
              <option value="">Select Category</option>
              {category.map(({ _id, name }) => {
                return (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                );
              })}
            </select>
          </label>
          <label htmlFor="" className="col-md-6 mb-3">
            <select
              className="form-control"
              id="department"
              onChange={(e) => {
                setTypeId(e.target.value);
                setPage(1);
                setMemAssign([]);
              }}
            >
              <option value="">Select Type</option>
              {type.map(({ _id, name }) => {
                return (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="area">
            <select
              className="form-control"
              id="area"
              name="area"
              onChange={(e) => {
                setPin(e.target.value);
                setDivision([]);
                setDivId("");
                setPage(1);
                setMemAssign([]);
                loadAreaCode(e.target.value);
              }}
            >
              <option value="">Select Area</option>
              {area.map(({ _id, areaName, pinCode }) => {
                return (
                  <option key={_id} value={pinCode}>
                    {`${areaName} (${pinCode})`}
                  </option>
                );
              })}
            </select>
          </label>
          <label className="col-md-6 mb-3" htmlFor="areaCode">
            <select
              className="form-control"
              id="areaCode"
              name="areaCode"
              onChange={(e) => {
                setDivId(e.target.value);
                setPage(1);
                setMemAssign([]);
              }}
            >
              <option value="">Select Division</option>
              {division.map(({ _id, areaCode }) => {
                return (
                  <option key={_id} value={_id}>
                    {areaCode}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th
              id="memberName"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMemAssign, setPage);
              }}
            >
              Member Name
              <span className="px-2">
                {sort === "memberName" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="memberId"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMemAssign, setPage);
              }}
            >
              Member Id
              <span className="px-2">
                {sort === "memberId" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="memLoc"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMemAssign, setPage);
              }}
            >
              Member Location
              <span className="px-2">
                {sort === "memLoc" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="pickUpLoc"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMemAssign, setPage);
              }}
            >
              Pickup Location
              <span className="px-2">
                {sort === "pickUpLoc" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th
              id="dropLoc"
              role="button"
              onClick={(e) => {
                sortData(ascDsc, setAscDsc, e, setSort, setMemAssign, setPage);
              }}
            >
              Drop Location
              <span className="px-2">
                {sort === "dropLoc" ? (
                  <UpArr height="15px" width="20px" />
                ) : (
                  <DownArr height="15px" width="20px" />
                )}
              </span>
            </th>
            <th className="text-center">Active</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {memAssign?.map((x) => {
            return <AssignmentListItems x={x} key={x._id} />;
          })}
        </tbody>
      </table>
    </>
  );
};

export default AssignmentList;
